<div fxLayout="row wrap" class="all-chat">
  <div fxLayout="row wrap" fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start start" style="height: 100%;">
    <div class="search-header" fxFlex="100">
      <mat-form-field *ngIf="searchChat" appearance="outline">
        <textarea [(ngModel)]="searchText" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"
                  name="name" type="text" placeholder="Buscar chat"></textarea>
        <div (click)="searchChatOut()" class="mat-icon-button" matSuffix>
          <mat-icon>search_off</mat-icon>
        </div>
      </mat-form-field>
      <i *ngIf="!searchChat" (click)="searchChat = true" class="material-icons group-new" matTooltip="Buscar chat">search</i>
      <i *ngIf="!addContact" (click)="addContact = true"  class="material-icons group-new" matTooltip="Nuevo chat">add</i>
      <i *ngIf="addContact" (click)="addContact = false"  class="material-icons group-new" matTooltip="Cancelar">close</i>
    </div>

    <span *ngIf="!addContact" style="overflow: auto; height: calc(100% - 3.5em);" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
      <div fxFlex="100" *ngFor="let chat of chatService.PrivateChatRoomsMap | keyvalue: valueAscOrder | filter : searchText : 1; let i = index"
         fxLayout="row wrap" [ngClass]="{'chats-list':true, 'actual-chat':chatService.selectedChat.id == chat?.key}">
        <span class="chats-list-2" fxLayout="row wrap" (click)="getRoomInfo(chat?.key, chat.value.roomTypeId)" fxFlex="100" >
          <ng-container
            *ngIf="chat.value?.roomTypeId === 1"
            [ngTemplateOutlet]="userInfo"
            [ngTemplateOutletContext]="{roomId:chat?.key}">
          </ng-container>
          <ng-container
            *ngIf="chat.value?.roomTypeId === 2"
            [ngTemplateOutlet]="groupInfo"
            [ngTemplateOutletContext]="{roomId:chat?.key}">
          </ng-container>
          <ng-container
            *ngIf="chat.value?.roomTypeId === 3"
            [ngTemplateOutlet]="groupInfo"
            [ngTemplateOutletContext]="{roomId:chat?.key}">
          </ng-container>
        </span>
      </div>
    </span>

    <span *ngIf="addContact"  style="overflow: auto; height: calc(100% - 3.5em);" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
      <span (click)="openCreateGroupDialog()" class="new-group" fxFlex="100">
        <i class="material-icons group-new" matTooltip="Crear nuevo grupo">group_add</i>
        Crear grupo
      </span>

      <!--<span  fxFlex="100" (click)="openAddContactDialog()">
        <i class="material-icons group-new" matTooltip="Añadir nuevo contacto">person_add</i>
        Añadir contacto
      </span>-->
      <div fxFlex="100" *ngFor="let user of chatContacts | filter : searchText : 2; let i = index;"
               fxLayout="row wrap" class="chats-list" >
      <span (click)="openChatConversation(user)" class="chats-list-2" fxLayout="row wrap" fxFlex="100">
        
        <ng-container
          [ngTemplateOutlet]="newContact"
          [ngTemplateOutletContext]="{contact:user}">
        </ng-container>
      </span>
    </div>
    </span>
  </div>
  <div fxFlex="70" fxFlex.sm="100" fxFlex.xs="100" ngClass.sm="active-chat" ngClass.xs="active-chat" class="chat-container" *ngIf="chatService.selectedChat.id !== '-1'" style="height: 100%;">
    <div class="chat-header" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <span fxLayout="row wrap" class="chats-list">
        <span class="chats-list-2" fxLayout="row wrap" fxFlex="100">
          <ng-container
            *ngIf="chatService.selectedChat.chatType === 1"
            [ngTemplateOutlet]="userInfoChat"
            [ngTemplateOutletContext]="{roomId:chatService.selectedChat.id}">
          </ng-container>
          <ng-container
            *ngIf="chatService.selectedChat.chatType === 2"
            [ngTemplateOutlet]="groupInfoChat"
            [ngTemplateOutletContext]="{roomId:chatService.selectedChat.id}">
          </ng-container>
          <ng-container
            *ngIf="chatService.selectedChat.chatType === 3"
            [ngTemplateOutlet]="groupInfoChat"
            [ngTemplateOutletContext]="{roomId:chatService.selectedChat.id}">
          </ng-container>
        </span>
      </span>
      <!--
      <i *ngIf="chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).roomId === 2" (click)="openGroupInfo(chatService.selectedChat.id)" class="material-icons group-new" matTooltip="Crear grupo">info</i>
      -->
<!--      <i *ngIf="chatService.selectedChat.chatType === 2" (click)="openGroupInfo(chatService.selectedChat.id)" class="material-icons group-info" matTooltip="Información del grupo">info</i>-->
      <i *ngIf="chatService.selectedChat.chatType === 2 && !groupDetails" 
      (click)="groupDetails = true" class="material-icons group-info" matTooltip="Información del grupo">info</i>
      <i *ngIf="chatService.selectedChat.chatType === 2 && groupDetails"
       (click)="groupDetails = false" class="material-icons group-info" matTooltip="Cerrar información del grupo">close</i>
      <i *ngIf="chatService.selectedChat.chatType === 2 
      && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).isPublic != 1 && !privateGroupAccepted() && !privateGroupRejected()">       
        <button mat-button color="primary" style="background: #000;padding:5px;" (click)="accept(chatService.selectedChat.id, this.authService.user_token)">Aceptar invitación</button>
        <button mat-button color="accent" style="background: #000;padding:5px;"(click)="reject(chatService.selectedChat.id, this.authService.user_token)">Rechazar invitacion</button>
      </i>
      <i *ngIf="chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).isPublic != 2 && !belongsToPublicGroup()" >        
        <button mat-button color="primary" style="background: #000;padding:5px;"(click)="joinPublicGroup(chatService.selectedChat.id)"> Join</button>
      </i>
      <button mat-button color="primary" style="background: #000;padding:5px;" *ngIf="chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).isPublic != 2 && belongsToPublicGroup()" (click)="leaveGroup(chatService.selectedChat.id)"> Leave</button>
    </div>

    <!--
        <div class="chat-body" #scrollMe>
    <div class="chat-body" #scrollMe [scrollTop]="chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).messagesMap.size === 0 ? 0 : scrollMe.scrollHeight">
    -->

<!--    [scrollTop]="scrollBottom(scrollMe)"-->
    <div *ngIf="loaded && !groupDetails" [ngClass]="{'chat-body': true, 'chat-body-total':!(
      (chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).onlyAdminCanChat === true 
      && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).myInfo?.es_admin === true)
       ||
    (chatService.selectedChat.chatType === 3 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).myInfo?.es_admin === true) 
    || 
    (chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).isPublic == 2 && privateGroupAccepted()) 
    || (chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).isPublic == 1 && belongsToPublicGroup())
    || chatService.selectedChat.chatType === 1 )}"#scrollMe fxLayout="row wrap">
      <i *ngIf="!chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).allMessagesLoaded" fxFlex="100" class="material-icons refresh" matTooltip="Cargar mensajes anteriores" (click)="paginate()">refresh</i>
      <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end">
        <div fxFlex="100" *ngFor="let message of (chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).messages)" fxLayout="row wrap">
          <span fxFlex="100">
<!--          <span><{{message | json}}</span>-->
          <ng-container *ngIf="message.messageType === 1"
            [ngTemplateOutlet]="message.userId === authService.user_token ? selfMessage : otherMessage"
            [ngTemplateOutletContext]="{message:message}">
          </ng-container>
          <ng-container *ngIf="message.messageType === 5"
                        [ngTemplateOutlet]="systemMessage"
                        [ngTemplateOutletContext]="{message:message.message}">
          </ng-container>
        </span>
                <!--<p>{{message.value.message}}</p>-->
      </div>

      <div fxFlex="100" *ngFor="let message of (chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).newmessages)" fxLayout="row wrap">
        <span fxFlex="100">
<!--          <span><{{message | json}}</span>-->
        <ng-container *ngIf="message.messageType === 1"
          [ngTemplateOutlet]="message.userId === authService.user_token ? selfMessage : otherMessage"
          [ngTemplateOutletContext]="{message:message}">
        </ng-container>
        <ng-container *ngIf="message.messageType === 5"
                      [ngTemplateOutlet]="systemMessage"
                      [ngTemplateOutletContext]="{message:message.message}">
        </ng-container>
      </span>
              <!--<p>{{message.value.message}}</p>-->
    </div>
<!--        <div fxFlex="100" *ngFor="let message of (chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).messagesMap) | keyvalue: asIsOrder" fxLayout="row wrap">-->
<!--          <span fxFlex="100">-->
<!--            <ng-container *ngIf="message.value.messageType === 1"-->
<!--                          [ngTemplateOutlet]="message.value.userId === authService.user_token ? selfMessage : otherMessage"-->
<!--                          [ngTemplateOutletContext]="{message:message.value.message}">-->
<!--            </ng-container>-->
<!--            <ng-container *ngIf="message.value.messageType === 5"-->
<!--                          [ngTemplateOutlet]="systemMessage"-->
<!--                          [ngTemplateOutletContext]="{message:message.value.message}">-->
<!--            </ng-container>-->
<!--          </span>-->
<!--          &lt;!&ndash;<p>{{message.value.message}}</p>&ndash;&gt;-->
<!--      </div>-->
      </span>
    </div>
    <div *ngIf="loaded && groupDetails" class="chat-body-2" fxLayout="row wrap">
      <app-chat-group-info [group]="chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id)" [contacts]="chatContacts"></app-chat-group-info>
    </div>
    <div class="chat-body" *ngIf="!loaded">
      <span [ngClass]="{'loader-spinner0':true, 'loader-spinner0-out':loaded}">
        <mat-progress-spinner
          class="loader-spinner"
          [color]="'primary'"
          [value]="75"
          [mode]="'indeterminate'"
          [diameter]="120"
          [strokeWidth]="15">
        </mat-progress-spinner>
      </span>
    </div>
    <div class="chat-footer" *ngIf="loaded && !groupDetails &&
    (
      (chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).onlyAdminCanChat === true 
      && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).myInfo?.es_admin === true)
       ||
    (chatService.selectedChat.chatType === 3 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).myInfo?.es_admin === true) 
    || 
    (chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).isPublic == 2 && privateGroupAccepted()) 
    || (chatService.selectedChat.chatType === 2 && chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).isPublic == 1 && belongsToPublicGroup())
    || chatService.selectedChat.chatType === 1 )">
      <mat-form-field appearance="outline">
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
        (keydown.enter)="onKeydown($event)" (keyup.enter)="sendMessage(newChatComment, chatService.selectedChat.id, $event)"
        (keyup)="onTyping($event,chatService.selectedChat.id)" id="enviarMensajeChat"
        name="name" type="text" [(ngModel)]="newChatComment" placeholder="Escriba aquí su mensaje"></textarea>
        <div class="mat-icon-button" matSuffix>
          <mat-icon (click)="sendMessage(newChatComment, chatService.selectedChat.id, $event)" matTooltip="Enviar mensaje">send</mat-icon>
        </div>
      </mat-form-field>
    </div>
  </div>
  <div fxShow="true" fxShow.sm="false" fxShow.xs="false" fxFlex="70" fxFlex.sm="100" fxFlex.xs="100" class="chat-empty" style="height: 100%;" *ngIf="chatService.selectedChat.id === '-1'">
    <span class="marktDeco tittle-typing">
      <span class="effect">
        Bienvenido al chat de <span class="red">[</span>4dm<span class="yellow">o</span>vin<span class="blue">]</span>
      </span>
      <mat-icon matTooltip="Enviar mensaje">send</mat-icon>
    </span>
  </div>
</div>

<ng-template #selfMessage let-message='message'>
  <div class="message sent">
    <div class="msg-0" fxLayout="row" fxLayoutAlign="space-between start">
      <span class="msg">
        {{ message.message }}
      </span>
      <span matTooltip="Más acciones" [matMenuTriggerFor]="msgactions" class="material-icons">expand_more</span>
    </div>
    <div class="msgInfo" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <span>
        {{ message.date | date:'dd/MM/yyyy, HH:mm' }}
      </span>
      <span *ngIf="!message.isRead" matTooltip="Sin leer" class="material-icons">info</span>
      <span *ngIf="message.isRead" matTooltip="Leído ({{ message.lastSeen | date:'dd/MM/yyyy, HH:mm'}})" class="material-icons">info</span>
    </div>
  </div>
</ng-template>

<mat-menu #msgactions="matMenu">
  <button mat-menu-item>
    <i class="material-icons">content_copy</i>
    <span>Copiar mensaje</span>
  </button>
  <button mat-menu-item>
    <i class="material-icons">forward</i>
    <span>Reenviar mensaje</span>
  </button>
  <button mat-menu-item>
    <i class="material-icons">reply</i>
    <span>Responder mensaje</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="editmsgactions">
    <i class="material-icons">create</i>
    <span>Editar mensaje</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="deletemsgactions">
    <i class="material-icons">delete</i>
    <span>Eliminar mensaje</span>
  </button>
</mat-menu>

<mat-menu #editmsgactions="matMenu">
  <button mat-menu-item>
    <i class="material-icons">supervised_user_circle</i>
    <span>Editar para todos</span>
  </button>
  <button mat-menu-item>
    <i class="material-icons">account_circle</i>
    <span>Eliminar para mi</span>
  </button>
</mat-menu>

<mat-menu #deletemsgactions="matMenu">
  <button mat-menu-item>
    <i class="material-icons">supervised_user_circle</i>
    <span>Editar para todos</span>
  </button>
  <button mat-menu-item>
    <i class="material-icons">account_circle</i>
    <span>Editar para mi</span>
  </button>
</mat-menu>


<ng-template #otherMessage let-message='message'>
  <div class="message received">
    <div class="msg-0" fxLayout="row" fxLayoutAlign="space-between start">
      <span class="msg">
        {{ message.message }}
      </span>
      <span matTooltip="Más acciones" [matMenuTriggerFor]="othermsgactions" class="material-icons">expand_more</span>
    </div>
    <div class="msgInfo" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <span>
        {{ message.date | date:'dd/MM/yyyy, HH:mm' }}
      </span>
      <span *ngIf="message.new && !message.read" matTooltip="Mensaje nuevo" class="material-icons">fiber_new</span>
    </div>
  </div>
</ng-template>

<mat-menu #othermsgactions="matMenu">
  <button mat-menu-item>
    <i class="material-icons">content_copy</i>
    <span>Copiar mensaje</span>
  </button>
  <button mat-menu-item>
    <i class="material-icons">forward</i>
    <span>Reenviar mensaje</span>
  </button>
  <button mat-menu-item>
    <i class="material-icons">reply</i>
    <span>Responder mensaje</span>
  </button>
</mat-menu>

<ng-template #systemMessage let-message='message'>
  <div class="system-message">
    {{ message }}
  </div>
</ng-template>

<ng-template #otherMessageGroup let-message='message'>
  <div class="message received">
    {{chatService.PrivateChatRoomsMap.get(chatService.selectedChat.id).usersMap.get(message.value.userId)?.nombre}}: {{ message.value.message }}
    Visto?: {{ message.read }}
  </div>
</ng-template>

<ng-template #userInfo let-roomId='roomId'>
  <img class="fotoPerfil" src="{{chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.avatar}}" alt="" style="max-width:100%; max-height:100%;" >
  <span fxLayout="row wrap" class="user-info">
    <span fxFlex="60" class="user-name">{{chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.nombre}}
      <i *ngIf="chatService.users_online.get(chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.id)" class="material-icons" style="color:#42678f" matTooltip="Disponible">circle</i>
      <i *ngIf="!chatService.users_online.get(chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.id) || !chatService.users_online.get(chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.id)" class="material-icons" style="color:#882334" matTooltip="No Disponible">circle</i>
    </span>
    <span class="date" fxFlex="40" *ngIf="chatService.PrivateChatRoomsMap.get(roomId).lastMessage">{{ chatService.PrivateChatRoomsMap.get(roomId).lastMessage | date:'dd/MM/yyyy, HH:mm' }}</span>
    <span class="date" fxFlex="40" *ngIf="!chatService.PrivateChatRoomsMap.get(roomId).lastMessage">{{ chatService.PrivateChatRoomsMap.get(roomId).date | date:'dd/MM/yyyy, HH:mm' }}</span>
    <span class="user-inmo" fxFlex="100">{{chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.nombre_inmo}}</span>
    <span *ngIf="!chatService.PrivateChatRoomsMap.get(roomId).isTyping" class="last-message" fxFlex="100">{{ chatService.PrivateChatRoomsMap.get(roomId).message | slice:0:32 }}</span>
    <span *ngIf="chatService.PrivateChatRoomsMap.get(roomId).isTyping" class="typing" fxFlex="100">Escribiendo...</span>
  </span>
  <span *ngIf="chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages > 0 && roomId !== chatService.selectedChat.id" class="new-ms">{{chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages}}</span>
  <!--  <span [matBadge]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size" [matBadgeHidden]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size<1"></span>-->
</ng-template>

<ng-template #userInfoChat let-roomId='roomId'>
  <span fxShow="false" fxShow.sm="true" fxShow.xs="true" class="back-list-chats yellow">
    <span class="material-icons" (click)="chatService.selectedChat.id = '-1'">arrow_back</span>
  </span>
  <img class="fotoPerfil" src="{{chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.avatar}}" alt="" style="max-width:100%; max-height:100%;">
  <span fxLayout="row wrap" class="user-info" ngStyle.sm="width: calc(100% - 2.2em - 2.2em)" ngStyle.xs="width: calc(100% - 2.2em - 2.2em)">
    <span fxFlex="70" class="user-name">{{chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.nombre}}
      <i *ngIf="chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.online" class="material-icons" style="color:#42678f" matTooltip="Disponible">circle</i>
      <i *ngIf="!chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.online || !chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value" class="material-icons" style="color:#882334" matTooltip="No Disponible">circle</i>
    </span>
    <span *ngIf="!chatService.PrivateChatRoomsMap.get(roomId).isTyping" class="user-inmo" fxFlex="100">{{chatService.PrivateChatRoomsMap.get(roomId).usersMap.values().next().value?.nombre_inmo}}</span>
    <span *ngIf="chatService.PrivateChatRoomsMap.get(roomId).isTyping" class="typing" fxFlex="100">Escribiendo...</span>
  </span>
  <!--  <span *ngIf="chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages > 0 && roomId !== chatService.selectedChat.id" class="new-ms">{{chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages}}</span>-->
  <!--  <span [matBadge]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size" [matBadgeHidden]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size<1"></span>-->
</ng-template>

<ng-template #groupInfo let-roomId='roomId'>
  <img class="fotoPerfil" src="{{chatService.PrivateChatRoomsMap.get(roomId).groupPic}}" alt="" style="max-width:100%; max-height:100%;">
  <span fxLayout="row wrap" class="user-info">
    <span fxFlex="60" class="user-name">{{chatService.PrivateChatRoomsMap.get(roomId).groupName}}</span>
    <span class="date" fxFlex="40" *ngIf="chatService.PrivateChatRoomsMap.get(roomId).lastMessage">{{ chatService.PrivateChatRoomsMap.get(roomId).lastMessage | date:'dd/MM/yyyy, HH:mm' }}</span>
    <span class="date" fxFlex="40" *ngIf="!chatService.PrivateChatRoomsMap.get(roomId).lastMessage">{{ chatService.PrivateChatRoomsMap.get(roomId).date | date:'dd/MM/yyyy, HH:mm' }}</span>
    <span *ngIf="!chatService.PrivateChatRoomsMap.get(roomId).isTyping" class="last-message" fxFlex="100">{{ chatService.PrivateChatRoomsMap.get(roomId).message | slice:0:32 }}</span>
    <span *ngIf="chatService.PrivateChatRoomsMap.get(roomId).isTyping" class="typing" fxFlex="100">Escribiendo...</span>
  </span>
  <span *ngIf="chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages > 0 && roomId !== chatService.selectedChat.id" class="new-ms">{{chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages}}</span>
  <!--  <span [matBadge]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size" [matBadgeHidden]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size<1"></span>-->
</ng-template>


<ng-template #groupInfoChat let-roomId='roomId'>
  <span fxShow="false" fxShow.sm="true" fxShow.xs="true" class="back-list-chats yellow">
    <span class="material-icons" (click)="chatService.selectedChat.id = '-1'">arrow_back</span>
  </span>
  <img class="fotoPerfil" src="{{chatService.PrivateChatRoomsMap.get(roomId).groupPic}}" alt="" style="max-width:100%; max-height:100%;" >
  <span fxLayout="row wrap" class="user-info" ngStyle.sm="width: calc(100% - 2.2em - 2.2em)" ngStyle.xs="width: calc(100% - 2.2em - 2.2em)">
    <span class="user-name" fxFlex="100">{{chatService.PrivateChatRoomsMap.get(roomId).groupName}}</span>
    <span *ngIf="!chatService.PrivateChatRoomsMap.get(roomId).isTyping" class="last-message" fxFlex="100">{{ chatService.PrivateChatRoomsMap.get(roomId).message | slice:0:32 }}</span>
    <span class="typing" fxFlex="100" *ngIf="chatService.PrivateChatRoomsMap.get(roomId).isTyping">Escribiendo...</span>
  </span>
  <!--  <span *ngIf="chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages > 0 && roomId !== chatService.selectedChat.id" class="new-ms">{{chatService.PrivateChatRoomsMap.get(roomId).nonReadMessages}}</span>-->
  <!--  <span [matBadge]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size" [matBadgeHidden]="chatService.PrivateChatRoomsMap.get(roomId).messagesMap.size<1"></span>-->
</ng-template>  

<ng-template #newContact let-contact='contact'>
  <img class="fotoPerfil" src="{{contact.profilePictureURL}}" alt="" style="max-width:100%; max-height:100%;" >
  <span fxLayout="row wrap" class="user-info">
    <span class="user-name" fxFlex="100">{{contact.name}} {{contact.surname}}</span>
    <span class="user-inmo" fxFlex="100">{{contact.inmoName}}</span>
  </span>
<!--  <span>{{contact | json}}</span>-->
</ng-template>

