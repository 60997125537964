 <ng-container *ngIf="idQuery && loaded && structure[this.index]">
<!--    <div [ngClass]="'generic-taules generic-taules-' + structure[index]['id_functional_area']" [ngStyle]="{ 'height': this.getHeightV + 'px', 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 2.6em)' }">
        <div [ngClass]="'generic-taules-2 generic-taules-2-' + structure[index]['id_functional_area']" [ngStyle]="{ 'height': 'calc(100% - ' + this.getHeightTableV + 'px - 2.6em)', 'position': functionsService.downloadingStaticPage ? 'relative' : 'absolute', 'display': functionsService.downloadingStaticPage ? 'table' : 'contents' }">

        </div>
    </div> -->

    <!-- <table [ngClass]="'mat-elevation-z3 mat-elevation-z3-' + structure[index]['id_functional_area']" mat-table matSort multiTemplateDataRows matTableExporter #exporter="matTableExporter">
        <ng-container *ngFor="let item of structure[this.index]['child']">
            <th>
                <span>{{item['label']}}</span>
            </th>
        </ng-container>
    </table> -->

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Weight </th>
          <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef> Symbol </th>
          <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

</ng-container>
