import {Component, OnInit, Input, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';

//SERVICES
import { FunctionsService} from "../../../services/functions/functions.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, OnDestroy {
  public index;

  public formId: string;

  @Input() structure = null;
  @Input() idQuery : number;

  private formSubscription : Subscription;

  public formAttributes = [];

  constructor(public functionsService: FunctionsService,
              private genericService: GenericService,
              private changeDetectorRef:ChangeDetectorRef) {}

  ngOnInit(): void {
    this.formId = 'form' + this.structure['id_functional_parent_initial'];
    this.formSubscription = this.structure['form']?.valueChanges.subscribe(newToogleValue=> {
      if(this.genericService){
        this.genericService.dirtyForm = this.structure['form'].dirty;
      }
      this.changeDetectorRef.detectChanges();
    });
    console.log('structure132', this.structure)
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe()
  }

  onRefreshForm() {
  }
}
