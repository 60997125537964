export class VariableMatching {
  public static checkNumberGreatEqual(variable: number, value: number) {
    if (
      value != null &&
      value !== undefined) {
      if (variable != null && variable >= value) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public static checkNumberLessEqual(variable: number, value: number) {
    if (
      value != null &&
      value !== undefined) {
      if (variable != null && variable <= value) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public static checkString(variable: string, value: string) {
    if (value == null || value === undefined || value.length === 0) {
      return true;
    }
    if (variable != null && variable !== undefined && variable.length > 0) {
      const v = this.clearStringForMatching(value);
      const v2 = this.clearStringForMatching(variable);
      const regValue = new RegExp('.*' + v + '.*', 'i');
      if ((v2 != null && v2.match(regValue))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public static checkEnum(variable: number, value: number) {
    if (value != null && value !== undefined && value > -1) {
      if ((variable === value)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public static checkMultiValue(variable: number, value: Array<any>) {
    if (value != null && value !== undefined && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (variable === value[i]) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  public static checkMultipleMultipleValue(variable: Array<number>, value: Array<number>): boolean {
    if (value != null && value !== undefined && value.length > 0) {
      const v2 = new Set(value);
      const intersect = new Set(
        [...variable].filter(x => v2.has(x)));
      if (intersect.size > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public static checkMultiEnum(variable: Array<any>, value: Array<any>) {
    if (value != null && value !== undefined && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (variable.includes(value[i])) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  public static checkDateGreatEqual(variable: any, value: any) {
    let flag = true;
    if (value != null && value !== undefined &&
      value['day'] != null && value['day'] !== undefined &&
      value['month'] != null && value['month'] !== undefined &&
      value['year'] != null && value['year'] !== undefined) {
      if (variable['year'] > value['year']) {
        flag = true;
      } else if (variable['year'] === value['year']) {
        if (variable['month'] > value['month']) {
          flag = true;
        } else if (variable['month'] === value['month']) {
          if (variable['day'] >= value['day']) {
            flag = true;
          } else {
            flag = false;
          }
        } else {
          flag = false;
        }
      } else {
        flag = false;
      }
    }
    return flag;
  }

  public static checkDateLessEqual(variable: any, value: any) {
    let flag = true;
    if (value != null && value !== undefined &&
      value['day'] != null && value['day'] !== undefined &&
      value['month'] != null && value['month'] !== undefined &&
      value['year'] != null && value['year'] !== undefined) {
      if (variable['year'] < value['year']) {
        flag = true;
      } else if (variable['year'] === value['year']) {
        if (variable['month'] < value['month']) {
          flag = true;
        } else if (variable['month'] === value['month']) {
          if (variable['day'] <= value['day']) {
            flag = true;
          } else {
            flag = false;
          }
        } else {
          flag = false;
        }
      } else {
        flag = false;
      }
    }
    return flag;
  }

  public static checkIfHaveValue(variable: any, value: boolean) {
    if (value != null && value !== undefined) {
      if (value) {
        if (variable > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (variable > 0) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  public static checkIfHaveString(variable: any, value: boolean) {
    if (value != null && value !== undefined) {
      if (value) {
        if (variable != null && variable !== undefined && variable.length > 0) return true;
        else return false;
      } else {
        if (variable != null && variable !== undefined && variable.length > 0) return false;
        else return true;
      }
    } else {
      return true;
    }
  }

  public static checkIfNotHaveValue(variable: any, value: boolean) {
    if (value != null && value !== undefined) {
      if (value) {
        if (variable == null || variable === undefined) return true;
        else return false;
      }
    } else {
      return true;
    }
  }

  public static checkBoolean(variable, value) {
    if (value != null && value !== undefined) {
      if (value === variable) return true;
      else return false;
    }
    else return true;
  }

  public static checkInterests(variable, minPrice, maxPrice, city, cityZone, cityZoneSub) {
    if (minPrice != null && minPrice !== undefined ||
      maxPrice != null && maxPrice !== undefined ||
      city != null && city !== undefined ||
      cityZone != null && cityZone !== undefined ||
      cityZoneSub != null && cityZoneSub !== undefined) {

      if (variable == null || variable === undefined || variable.length === 0) return true;
      for (let i = 0; i < variable.length; i++) {
        const interest = variable[i];
        if (this.checkNumberGreatEqual(interest.precio_min, minPrice) &&
          this.checkNumberLessEqual(interest.precio_max, maxPrice) &&
          this.checkNumberGreatEqual(interest.precio_max, minPrice) && this.checkNumberLessEqual(interest.precio_min, maxPrice) &&
          this.checkMultiValue(interest.id_poblacion, city) &&
          this.checkMultiValue(interest.id_zona, cityZone) &&
          this.checkMultiValue(interest.id_subzona, cityZoneSub)) return true;
      }
      return false;
    }
    else return true;
  }

  public static clearStringForMatching(text: string): string {
    return text.replace(/[ÀÁÂÃÄÅ]/g, 'A')
      .replace(/[àáâä]/g, 'a')
      .replace(/[ÈÉÊË]/g, 'E')
      .replace(/[èéêë]/g, 'e')
      .replace(/[ÌÍÎÏ]/g, 'I')
      .replace(/[ìíîï]/g, 'i')
      .replace(/[ÒÓÔÖ]/g, 'O')
      .replace(/[òóôö]/g, 'o')
      .replace(/[ÙÚÛÜ]/g, 'U')
      .replace(/[ùúûü]/g, 'u')
      .replace(/[Ç]/g, 'C')
      .replace(/[\W][\s]*/g, '');
  }

  static checkMultiString(variable: Array<string>, value: string) {
    let flag = false;
    if (value == null || value === undefined || value.length === 0) {
      return true;
    }
    variable.forEach(element => {
      if (VariableMatching.checkString(element, value)) {
        flag = true;
      }
    });
    return flag;
  }

}

