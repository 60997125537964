export class SimpleProperty {
	reference: string;
	id: number;
	price: string;
	internalName: string;

	constructor(id?: number, reference?: string, price?: string, internalName?: string) {
		this.reference = (reference == null) ? '' : reference || '';
		this.id = id || -1;
		this.price = (price == null) ? '' : price || '';
		this.internalName = (internalName == null) ? '' : internalName || '';
	}
}
