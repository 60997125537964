<div fxFlex="100" fxLayout="row wrap">
  <div fxFlex="100" *ngFor="let element of this.carritoCompra" fxLayout="row wrap">
    <div fxFlex="100" fxLayout="row">
      <div style="background: red">
        <img style="width: 600px; height: 60px;" *ngIf="element.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(element.imagen)">
      </div>

      <div fxLayout="row wrap">
        <div style="background: dodgerblue">
          {{element.nombre}}
        </div>
        <div style="background: blue">
          {{element.descripcion}}
        </div>
      </div>

      <div style="background: yellow">
        {{element.precio}}
      </div>
    </div>
  </div>

  <div fxFlex="100">
    <span fxFlex="20" fxLayoutAlign="right">Total: {{totalCompra}}</span>
  </div>

  <div *ngIf="showMore" fxFlex="100" style="justify-content: center;">
    <div fxFlex="50" fxLayoutAlign="center" fxLayout="row wrap" style="text-align: center;">
      <em fxFlex="100">*Para que sólo paguéis por lo que usáis, los precios son variables cada mes.</em>
      <em fxFlex="100" style="color: lightskyblue; cursor: pointer" (click)="saberMas()">Saber más</em>
    </div>
  </div>

  <div *ngIf="!showMore" fxFlex="100" style="justify-content: center;">
    <div fxFlex="50" fxLayoutAlign="center" fxLayout="row wrap" style="text-align: center;">
      <em fxFlex="100">*Para que sólo paguéis por lo que usáis, los precios son variables cada mes.</em>
      <em fxFlex="100" style="color: lightskyblue; cursor: pointer" (click)="saberMas()">Saber más</em>
    </div>
  </div>
</div>

