<!--
<form *ngIf="loaded" fxLayout="row wrap" fxFlex="100" #scrollEl cdk-scrollable class="init-sort-images">
    <div class="example-list" cdkDropListGroup  fxLayout="row wrap">
        <div
            cdkDropList
            [cdkDropListEnterPredicate]="enter"
            (cdkDropListDropped)="drop()"
            #placeholder
        ></div>
        <div
            cdkDropList
            *ngFor="let image of functionsService.arrayImagenes"
            [cdkDropListEnterPredicate]="enter"
            (cdkDropListDropped)="drop()"
            class="box-image" fxLayout="row wrap" fxFlex="32" fxFlex.sm="49" fxFlex.xs="99"
        >
            <div cdkDrag fxLayout="row" fxFlex="100" class="box-image-1">
                <img class="image-box" fxFlex="40"
                    src="https://movin.cloud/empresas/{{image.id_empresa}}/imagenes/{{image.id_vivienda}}/{{image.nombre_fichero}}">
    
                <div fxLayout="column" fxLayoutAlign="space-around left" class="publicar">
                    <mat-checkbox (change)="updatePublish($event.checked, image.id)" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" [checked]="image.selected" name="publicar">Publicada
                    </mat-checkbox>
                    <mat-checkbox (change)="updatePlano($event.checked, image.id)" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" [checked]="image.plano" name="plano">Plano
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</form>
-->

<form *ngIf="loaded" #scrollEl cdk-scrollable class="init-sort-images" fxLayout="row wrap" fxFlex="100">
    <div cdkDropListGroup fxLayout="row wrap">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div cdkDrag class="example-box" *ngFor="let image of functionsService.arrayImagenes">
                <div *cdkDragPlaceholder class="example-custom-placeholder"></div>
                <div fxLayout="row" fxFlex="100" class="box-image-1">
                    <img class="image-box" fxFlex="40"
                        src="https://movin.cloud/empresas/{{image.id_empresa}}/imagenes/{{image.id_vivienda}}/{{image.nombre_fichero}}">
        
                    <div fxLayout="column" fxLayoutAlign="space-around left" class="publicar">
                        <mat-checkbox (change)="updatePublish($event.checked, image.id)" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" [checked]="image.selected == 1" name="publicar">Publicada
                        </mat-checkbox>
                        <mat-checkbox (change)="updatePlano($event.checked, image.id)" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" [checked]="image.plano == 1" name="plano">Plano
                        </mat-checkbox>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</form>

<span [ngClass]="{'loader-spinner0-out':loaded}">
    <mat-progress-spinner
      class="loader-spinner"
      [color]="'primary'"
      [value]="75"
      [mode]="'indeterminate'"
      [diameter]="120"
      [strokeWidth]="15">
    </mat-progress-spinner>
  </span>