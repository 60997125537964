import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'bottom-sheet-duplicate',
  templateUrl: 'bottom-sheet-duplicate.html',
})
export class BottomSheetDuplicate {

  event: any;

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetDuplicate>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.event = data.event;
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  closeBottomSheet(){
    this._bottomSheetRef.dismiss();

  }

  onClickDuplicateRenting (){
    this.event.reportBody.operationTypes[0] == 1 ? this.event.onClickDuplicateRenting(this.event.idProperty,1) : this.event.onClickDuplicateRenting(this.event.idProperty,0);
  }

  onClickDuplicateSale (){
    this.event.reportBody.operationTypes[0] == 1 ? this.event.onClickDuplicateSale(this.event.idProperty,1) : this.event.onClickDuplicateSale(this.event.idProperty,0);
  }


}
