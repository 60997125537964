<ng-container *ngIf="idQuery && loaded">

  <div fxLayout="row wrap" *ngIf="(search == true || paginatorOptions !== null) && tableData.length > 1" [ngClass]="'bottom-table-generic bottom-table-generic-' + structure[index]['id_functional_area']" ngClass.sm="bottom-table-generic-mb" ngClass.xs="bottom-table-generic-mb">
    <div fxFlex.sm="100" fxFlex.xs="100" *ngIf="search == true" class="bottom-table2">
      <mat-form-field appearance="outline" *ngIf="search" [ngClass]="{'value-filter': filter.value !== '', 'input-filter': true}">
        <input #filter placeholder="Buscar" matInput type="text" (keyup)="doFilter($event.target.value)" value="{{prevFilter !== null ? prevFilter: ''}}">
        <button *ngIf="dataSource.filter != ''" matSuffix mat-icon-button aria-label="Clear" (click)="doFilter(''); filter.value=''">
          <mat-icon style="color: #000;">search_off</mat-icon>
        </button>
        <button *ngIf="loadedFilters && arrayFiltersFields.length > 0" [matBadge]="filtersCount" [matBadgeHidden]="filtersCount < 1" matSuffix mat-icon-button aria-label="Filter" (click)="openFilterTable()" matTooltip="Filtrar resultados">
          <mat-icon style="color: #000;">tune</mat-icon>
        </button>
        <button *ngIf="structure[index]['canExportTables']" matSuffix mat-icon-button aria-label="Download" (click)="exporter.exportTable('xlsx', {fileName:'table-data-4dmovin'})" matTooltip="Descargar contenido">
          <mat-icon style="color: #000;">download</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <mat-paginator [ngClass]="{'hidden': (dataSource !== undefined && dataSource.filteredData.length == 0) || paginatorOptions === null}" fxFlex.sm="100" fxFlex.xs="100" style="width: 50%;" [pageSizeOptions]="paginatorOptions" [pageIndex]="page"></mat-paginator>
  </div>
  <div class="generic-taules" [ngClass]="{'no-click-row': !structure[index]['id_function']}" [ngStyle]="{ 'height': this.getHeightV + 'px', 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 2.6em - 3px)' }">
    <div [ngClass]="'generic-taules-sub generic-taules-sub-' + structure[index]['id_functional_area']" [ngStyle]="{ 'height': 'calc(100% - ' + this.getHeightTableV + 'px - 2.6em - 3px)', 'position': functionsService.downloadingStaticPage ? 'relative' : 'absolute', 'display': functionsService.downloadingStaticPage ? 'table' : 'contents' }">
      <table [ngClass]="'mat-elevation-z2 mat-elevation-z3-' + structure[index]['id_functional_area']" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows matTableExporter #exporter="matTableExporter">
        <ng-container *ngFor="let key of displayedColumns; let i = index" matColumnDef="{{ key }}" >
          <ng-container *ngIf="!aux[i].Sort; else elseBlock">
            <th mat-header-cell *matHeaderCellDef [matTooltip]="aux[i].Tooltipp" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}">
              <ng-container *ngIf="!structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] != 'nextStep' && structure[index]['child'][aux[i].Id]['type'] != 'prevStep'">
                  <button mat-button (click)="iconClick(aux[i].Id); $event.stopPropagation()" style="width: auto;">
                    <i class="{{aux[i].class}}"
                    [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"
                    > {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'nextStep'">
                  <button mat-button matStepperNext (click)="$event.stopPropagation()" style="width: auto;">
                    <i class="{{aux[i].class}}"
                        [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"
                    > {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'prevStep'">
                  <button mat-button matStepperPrevious (click)="$event.stopPropagation()" style="width: auto;">
                    <i class="{{aux[i].class}}"
                        [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"
                    > {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
              </ng-container>
              <span *ngIf="this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
            </th>
          </ng-container>
          <ng-template #elseBlock>
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!aux[i].Sort" [matTooltip]="aux[i].Tooltipp" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}">
              <ng-container *ngIf="!structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] != 'nextStep' && structure[index]['child'][aux[i].Id]['type'] != 'prevStep'">
                  <button mat-button (click)="iconClick(aux[i].Id); $event.stopPropagation()">
                    <i class="{{aux[i].class}}"
                    [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"
                    > {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'nextStep'">
                  <button mat-button matStepperNext (click)="$event.stopPropagation()">
                    <i class="{{aux[i].class}}"
                    [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"
                    > {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'prevStep'">
                  <button mat-button matStepperPrevious (click)="$event.stopPropagation()">
                    <i class="{{aux[i].class}}"
                    [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"
                    > {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
              </ng-container>
              <span *ngIf="this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
            </th>
          </ng-template>
          <ng-container *ngIf="structure[index]['child'][aux[i].Id]['bd_field']">
            <td mat-cell *matCellDef="let row" (click)="rowClick($event, row, getRowId(dataSource.data.indexOf(row), row.originalRowID))" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(dataSource.data.indexOf(row), row.originalRowID)) }">
              <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'image'; else notImage">
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['id_function'] > 0; else notFunctionImage">
                  <img (click)="rowClickImage($event, row, getRowId(dataSource.data.indexOf(row), row.originalRowID), aux[i].Id); $event.stopPropagation()" class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" mat-card-image [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                </ng-container>
                <ng-template #notFunctionImage>
                  <img class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" mat-card-image [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                </ng-template>
              </ng-container>
              <ng-template #notImage>
                <ng-container *ngIf="checkArray(row[key])">
                  <div *ngFor="let x of row[key]">{{ x === null ? "N/A" : x }}</div>
                </ng-container>
                <ng-container *ngIf="!checkArray(row[key])">
                  <ng-container *ngIf="row[key] == 'isbooleantrue'">
                    <div><span class="material-icons checked-generic">check_circle</span></div>
                  </ng-container>
                  <ng-container *ngIf="row[key] == 'isbooleanfalse'">
                    <div><span class="material-icons denied-generic">cancel</span></div>
                  </ng-container>
                  <ng-container *ngIf="row[key] != 'isbooleantrue' && row[key] != 'isbooleanfalse'">
                    <div class="text-column"><span *ngIf="row[key]" [innerHTML]="row[key] | safeHtml"></span></div>
                  </ng-container>
                </ng-container>
              </ng-template>
  
            </td>
          </ng-container>
          <ng-container *ngIf="!structure[index]['child'][aux[i].Id]['bd_field']">
            <td mat-cell *matCellDef="let row; let t = dataIndex" (click)="$event.stopPropagation()" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(dataSource.data.indexOf(row), row.originalRowID)) }">
              <ng-container *ngIf="appearProfilePermission(structure[index]['child'][aux[i].Id]['id_functional_area'], dataSource.data[t])">
                <app-generic [structure]="structure[index]['child'][aux[i].Id]" [param]="'' + structure[index]['id_functional_area'] + '.' + getRowId(dataSource.data.indexOf(row), row.originalRowID)"></app-generic>
            </ng-container>
            </td>
          </ng-container>
        </ng-container>
  
        <ng-container *ngIf="expandedColumn()"  matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row; let di = dataIndex" (click)="rowClick($event, row, getRowId(dataSource.data.indexOf(row), row.originalRowID))" [attr.colspan]="displayedColumns.length">
            <div *ngIf="row == this.structure[this.index]['expandedElement']" class="element-detail" style="display: flex;" [@detailExpand]="row == this.structure[this.index]['expandedElement'] ? 'expanded' : 'collapsed'">
              <ng-container *ngIf="structure[index]['expandedElement'] !== null && structure[index]['expandedElement'] !== undefined">
                <app-generic [structure]="expandedCol" [param]="'' + structure[index]['id_functional_area'] + '.' + getRowId(dataSource.data.indexOf(row), row.originalRowID)"></app-generic>
              </ng-container>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky"></tr>
  
        <ng-container *ngIf="expandedCol === null">
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </ng-container>
  
        <ng-container *ngIf="expandedCol !== null">
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [class.example-expanded-row]="this.structure[this.index]['expandedElement'] === row" style="cursor: pointer;" ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </ng-container>
        
        <!--<tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            <ng-container *ngIf="structure[index]['text'] !== null && structure[index]['text'] !== undefined; else noText"> 
              {{structure[index]['text']}}
            </ng-container>
            <ng-template #noText>
              No se han encontrado resultados.
            </ng-template>
          </td>
        </tr>-->
  
      </table>
      <p *ngIf="loadedAfterView && dataSource.filteredData.length == 0" [ngClass]="'noRowsGeneric noRowsGeneric-' + structure[index]['id_functional_area']">
        <ng-container *ngIf="structure[index]['text'] !== null && structure[index]['text'] !== undefined; else noText"> 
          <span [innerHTML]="this.structure[this.index]['text'] | safeHtml"></span>
        </ng-container>
        <ng-template #noText>
          No se han encontrado resultados.
        </ng-template>
      </p>
    </div>
  </div>

</ng-container>

<div *ngIf="!loadedFilter" fxLayoutAlign="center center" fxFlex="100">
  <mat-spinner></mat-spinner>
</div>