<mat-form-field appearance="{{ this.structure[this.index]['class'] }}" [formGroup]="genericForm" [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">

  <mat-label  [innerHTML]="label | safeHtml"></mat-label>
  <input matInput
         type="text"
         style="text-transform: capitalize;"
         id="{{this.structure[this.index]['bd_field']}}"
         formControlName="{{this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']}}"
         [required]="this.structure[this.index]['id_functional_status_general'] === 5"
         [attr.disabled]="this.structure[this.index]['id_functional_status_general'] === 3 ? '' : null"
         #locationSearchInput
         [matAutocomplete]="autoGroup"
         autocomplete="off">

  <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="optionSelected($event)">
    <mat-option *ngIf="isSearching || apiResponse['errorCode'] == -1; else elseTemplate" [disabled]="this.structure[this.index]['id_functional_status_general'] === 3">
      <ng-container *ngIf="isSearching; else notFound">
        <mat-spinner class="yellowSpinner" [diameter]="25" [strokeWidth]="2"></mat-spinner>
      </ng-container>
      <ng-template #notFound>
        {{ 'form.error.notFound' | translate}}
      </ng-template>
    </mat-option>

    <ng-template #elseTemplate>
      <mat-optgroup *ngFor="let group of apiResponse['response']" [label]="group.country">
        <mat-option *ngFor="let location of group.locations" [value]="location" [disabled]="this.structure[this.index]['id_functional_status_general'] === 3">
          {{deleteCountryCode(location.fullname)}}
        </mat-option>
      </mat-optgroup>
    </ng-template>
  </mat-autocomplete>
  <span
    *ngIf="inputValueLabel !== null && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
    matSuffix aria-label="Clear" class="clear-input" (click)="clear(); $event.stopPropagation()">
      <mat-icon>close</mat-icon>
  </span>

</mat-form-field>
