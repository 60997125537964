import {Component, Inject, OnInit} from "@angular/core";

@Component({
  selector: 'auto-sidenav',
  templateUrl: './auto-sidenav.component.html',
  styleUrls: ['./auto-sidenav.component.css']
})

export class AutoSidenavComponent implements OnInit{

  ngOnInit(): void {

  }

}
