<ng-container *ngIf="loaded">
  <div fxLayout="row wrap">
    <div *ngIf="templateService.plantillaInfo" fxFlex="50" fxFlex.xs="100" style="padding-right: 30px;" fxLayout="row wrap">
      <mat-form-field fxFlex="100" class="example-full-width" appearance="outline" *ngIf="templateService.plantillaInfo['nombre'] !== undefined">
        <mat-label>Nombre *</mat-label>
        <input matInput type="text" value="name" [(ngModel)]="templateService.plantillaInfo['nombre']" />
      </mat-form-field>
      <mat-form-field fxFlex="100" class="example-full-width" appearance="outline" *ngIf="templateService.plantillaInfo['descripcion'] !== undefined">
        <mat-label>Descripción</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3" [(ngModel)]="templateService.plantillaInfo['descripcion']"></textarea>
      </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>Estado *</mat-label>
        <mat-select matNativeControl [(ngModel)]="templateService.plantillaInfo['estado']">
          <mat-option *ngFor="let s of status" [value]="s.id">
            {{ s.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="50" fxFlex.xs="100" class="subject" *ngIf="templateService.plantillaInfo.id_tipo == 1 && subjectObject && subjectObject.asunto !== undefined">
      <!-- <div [innerHTML]="subjectObject.asunto"></div> -->
      <app-chip-creator-custom (newChipEvent)="addSubjectChip($event)" [htmlContent]="subjectObject.asunto" [label]="'Asunto del email'">
      </app-chip-creator-custom>
    </div>
  </div>
  <div fxLayout="row wrap" style="min-width: 60vw; padding-top: 20px;" *ngIf="loaded" [ngClass]="{ previewMode: checkedPreview }">
    <div fxFlex="100" fxLayout="row wrap" style="align-items: end; margin-bottom: 13px">
      <span fxLayout="row wrap" fxFlex="50" fxFlex.xs="100">
        <!--<div fxFlex="100" fxLayout="row" class="options-generals-custom">
          <div class="generic-buttons-red">
            <button mat-raised-button (click)="deleteElementGrid()">
              <mat-icon class="material-icons">refresh</mat-icon>Borrar todo
            </button>
          </div>
          <div *ngIf="templateService.currentRecordIndex > 0" class="generic-buttons-blue">
            <button mat-raised-button matTooltip="Undo" (click)="redoUndo('undo')">
              <mat-icon class="material-icons">undo</mat-icon>
            </button>
          </div>
          <div *ngIf="templateService.arrayElementsPlantillaRecord.length > templateService.currentRecordIndex + 1" class="generic-buttons-blue">
            <button mat-raised-button matTooltip="Redo" (click)="redoUndo('redo')">
              <mat-icon class="material-icons">redo</mat-icon>
            </button>
          </div>
        </div>-->
        <div class="generic-buttons-blue" *ngIf="this.templateService.plantillaInfo['id_tipo'] != 4">
          <button mat-raised-button (click)="templateService.generateHTMLResult()">
            <mat-icon class="material-icons vertical-align-button-icon">code</mat-icon> Generar HTML del Correo
          </button>
        </div>
        <mat-checkbox *ngIf="filterArrayTemplateElements()" [(ngModel)]="checkedPreview" fxFlex="100"
          class="check-preview">
          Vista previa de la plantilla
        </mat-checkbox>
      </span>
      <mat-form-field fxFlex="50" fxFlex.xs="100" appearance="outline">
        <mat-label>Seleccionar Idioma de visualización</mat-label>
        <mat-select (selectionChange)="selectLanguage()" matNativeControl #language [(ngModel)]="this.templateService.currentLanguage">
          <mat-option *ngFor="let language of languagesList" [value]="language.id">
            {{ language.nombre }}
          </mat-option>
        </mat-select>
        <mat-hint>Selecciona diferentes idiomas para traducir la plantilla</mat-hint>
      </mat-form-field>
    </div>
    <div class="containerList">
      <ng-container>
        <app-events-template-custom-loop class="component-class" style="width: 100%" [templateElementsArray]="this.templateService.arrayElementsPlantilla[0].children" [idPlantilla]="idPlantillaReference" [idParent]="this.templateService.arrayElementsPlantilla[0].idTmp"
          [parentItem]="this.templateService.arrayElementsPlantilla[0]">
        </app-events-template-custom-loop>
      </ng-container>
    </div>
  
    <div class="rightSide">
  
      <div *ngIf="templateService.saveReuse" style="padding: 30px;">
        <h5>Añadir elemento reutilizable</h5>
        <mat-form-field appearance="outline">
          <mat-label>Nombre *</mat-label>
          <input matInput type="text" value="name" [(ngModel)]="templateService.name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Descripción</mat-label>
          <input matInput type="text" value="description" [(ngModel)]="templateService.description" />
        </mat-form-field>
        <div class="generic-buttons-blue-revert" style="text-align: right;">
          <button mat-raised-button (click)="saveElementReuse(templateService.currentElement)">
            <mat-icon class="material-icons vertical-align-button-icon">save</mat-icon> Guardar
          </button>
        </div>
      </div>

      <div *ngIf="templateService.selectReuse" style="padding: 30px;">
        <h5>Seleccionar elemento reutilizable a insertar</h5>
        <mat-form-field appearance="outline">
          <mat-label>Elementos reutilizables</mat-label>
          <input type="text" matInput [formControl]="reuseElementControl" [matAutocomplete]="auto" />
          <mat-autocomplete (optionSelected)="saveValueInsertReuseElement($event.option.value)" autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option.nombre }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="generic-buttons-blue-revert" style="text-align: right;" *ngIf="selectedElementToReuse">
          <button mat-raised-button (click)="insertReuseElement(selectedElementToReuse)">
            <mat-icon class="material-icons vertical-align-button-icon">save</mat-icon> Seleccionar
          </button>
        </div>
      </div>

      <div *ngIf="templateService.showResult" style="padding: 30px;">
        <h5>Este es el HTML que se enviará por email para el idioma seleccionado</h5>
        <div class="generic-buttons-blue-revert" style="text-align: center; padding: 10px 0;">
          <button [cdkCopyToClipboard]="subjectObject.html_result" mat-raised-button><mat-icon class="material-icons vertical-align-button-icon">content_copy</mat-icon> Copiar HTML Resultado</button>
        </div>
        <div *ngIf="templateService.plantillaInfo.id_tipo == 1 && subjectObject && subjectObject.html_result !== undefined">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>HTML Resultado</mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20" [(ngModel)]="subjectObject.html_result"></textarea>
          </mat-form-field>
        </div>
      </div>

      <ng-container *ngIf="!templateService.saveReuse && !templateService.selectReuse && !templateService.showResult">

        <ng-container *ngIf="templateService.valueEmittedFromChild == 0">
          <!-- No element selected -->
        </ng-container>

        <mat-accordion *ngIf="templateService.currentElement && templateService.valueEmittedFromChild !== 3">
  
          <ng-container *ngIf="templateService.valueEmittedFromChild == 5">
              <app-chip-creator-custom (newChipEvent)="addChipCode($event, 0)" [htmlContent]="templateService.currentElement['text']" [label]="'Html code'">
              </app-chip-creator-custom>
          </ng-container>
    
          <!-- Time Selector -->
          <ng-container *ngIf="templateService.valueEmittedFromChild == 9">
            <!-- <mat-form-field appearance="outline">
              <mat-label>Date & Time</mat-label>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  [min]="minDate"
                  (input)="modifyElement('date')"
                  [matDatepicker]="picker"
                  [(ngModel)]="templateService.date"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Choose a time</mat-label>
                <input
                  matInput
                  type="time"
                  (input)="modifyElement('time')"
                  [(ngModel)]="templateService.time"
                />
                <mat-hint>MM:HH</mat-hint>
              </mat-form-field>
              <button
                *ngIf="templateService.date"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="templateService.date = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
          </ng-container>
    
          <!-- Text -->
          <mat-expansion-panel expanded *ngIf="templateService.valueEmittedFromChild == 1 || templateService.valueEmittedFromChild == 2 || templateService.valueEmittedFromChild == 4 || templateService.valueEmittedFromChild == 6 || templateService.valueEmittedFromChild == 10">
            <mat-expansion-panel-header>
              <mat-panel-title> General </mat-panel-title>
            </mat-expansion-panel-header>
  
            <ng-container *ngIf="templateService.valueEmittedFromChild == 1 || templateService.valueEmittedFromChild == 2 || templateService.valueEmittedFromChild == 6 || templateService.valueEmittedFromChild == 10">
              <app-chip-creator-custom (newChipEvent)="addChipCode($event, 0)" [htmlContent]="templateService.currentElement['text']" [label]="'TEXTO'">
              </app-chip-creator-custom>
            </ng-container>
    
            <ng-container *ngIf="templateService.valueEmittedFromChild == 1 || templateService.valueEmittedFromChild == 4 || templateService.valueEmittedFromChild == 10">
              <app-chip-creator-custom (newChipEvent)="addChipCode($event, 1)" [htmlContent]="templateService.currentElement['url']" [label]="'URL'" [showToolBar]="false" [showIcons]="false">
              </app-chip-creator-custom>
            </ng-container>
  
            <ng-container *ngIf="templateService.valueEmittedFromChild == 1 || templateService.valueEmittedFromChild == 2 || templateService.valueEmittedFromChild == 4 || templateService.valueEmittedFromChild == 6">
              <span fxLayout="row">
                <mat-button-toggle-group [(ngModel)]="templateService.currentElement['text_align']" name="fontStyle" aria-label="Font Style" style="margin: 1em auto" (change)="modifyElement('text_align')">
                  <mat-button-toggle value="left" style="padding: 0.5em">
                    <mat-icon> format_align_left</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="center" style="padding: 0.5em">
                    <mat-icon> format_align_center</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="right" style="padding: 0.5em">
                    <mat-icon> format_align_right</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="justify" style="padding: 0.5em">
                    <mat-icon> format_align_justify</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </span>
            </ng-container>
            
            <div class="generic-buttons-blue-revert" *ngIf="templateService.valueEmittedFromChild == 4">
              <button mat-raised-button (click)="openSelectImage()">
                <mat-icon class="material-icons vertical-align-button-icon">image</mat-icon> Mi librería
              </button>
            </div>
    
            <ng-container *ngIf="templateService.valueEmittedFromChild == 1 || templateService.valueEmittedFromChild == 2 || templateService.valueEmittedFromChild == 6">
              <mat-form-field appearance="outline">
                <mat-label>Font Size</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['font_size']" min="0" max="99" (change)="modifyElement('font_size')" />
              </mat-form-field>
    
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Font Weight</mat-label>
                <mat-select [(ngModel)]="templateService.currentElement['font_weight']" matNativeControl (selectionChange)="modifyElement('font_weight')">
                  <mat-option value="100">100</mat-option>
                  <mat-option value="200">200</mat-option>
                  <mat-option value="300">300</mat-option>
                  <mat-option value="400">400</mat-option>
                  <mat-option value="500">500</mat-option>
                  <mat-option value="600">600</mat-option>
                  <mat-option value="700">700</mat-option>
                  <mat-option value="800">800</mat-option>
                  <mat-option value="900">900</mat-option>
                </mat-select>
              </mat-form-field>
    
              <mat-form-field appearance="outline">
                <mat-label>Font Family</mat-label>
                <input matInput type="text" [(ngModel)]="templateService.currentElement['font_family']" (change)="modifyElement('font_family')" />
                <button *ngIf="templateService.currentElement['font_family']" matSuffix mat-icon-button aria-label="Clear" (click)="templateService.currentElement['font_family'] = ''; modifyElement('font_family')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
    
            <ng-container *ngIf="templateService.valueEmittedFromChild == 6">
              <mat-form-field appearance="outline">
                <mat-label>Heading</mat-label>
                <mat-select [(ngModel)]="templateService.currentElement['heading']" matNativeControl (selectionChange)="modifyElement('heading')">
                  <mat-option [value]="1">H1</mat-option>
                  <mat-option [value]="2">H2</mat-option>
                  <mat-option [value]="3">H3</mat-option>
                  <mat-option [value]="4">H4</mat-option>
                  <mat-option [value]="5">H5</mat-option>
                  <mat-option [value]="6">H6</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </mat-expansion-panel>
    
          <!-- Colors -->
          <mat-expansion-panel hideToggle *ngIf="templateService.valueEmittedFromChild == 1 || templateService.valueEmittedFromChild == 2 || templateService.valueEmittedFromChild == 3 || templateService.valueEmittedFromChild == 6 || templateService.valueEmittedFromChild == 8">
            <mat-expansion-panel-header>
              <mat-panel-title> Colors </mat-panel-title>
            </mat-expansion-panel-header>
    
            <mat-form-field *ngIf="templateService.valueEmittedFromChild != 8">
              <mat-label>Color</mat-label>
              <input matInput type="color" [(ngModel)]="templateService.currentElement['color']" (change)="modifyElement('color')"/>
            </mat-form-field>
    
            <mat-form-field>
              <mat-label>Background Color</mat-label>
              <input matInput type="color" [(ngModel)]="templateService.currentElement['background_color']" (change)="modifyElement('background_color')"/>
            </mat-form-field>
          </mat-expansion-panel>
    
          <!-- Others -->
          <mat-expansion-panel hideToggle *ngIf="templateService.valueEmittedFromChild == 1 || templateService.valueEmittedFromChild == 2 || templateService.valueEmittedFromChild == 4 || templateService.valueEmittedFromChild == 6 || templateService.valueEmittedFromChild == 7 || templateService.valueEmittedFromChild == 8">
            <mat-expansion-panel-header>
              <mat-panel-title> Border </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>
              <mat-form-field appearance="outline">
                <mat-label>Radius</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['border_radius']" min="0" (change)="modifyElement('border_radius')" />
              </mat-form-field>
            </ng-container>
            <mat-form-field>
              <mat-label>Color</mat-label>
              <input matInput type="color" [(ngModel)]="templateService.currentElement['border_color']" (change)="modifyElement('border_color')"/>
            </mat-form-field>
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Size</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['border_size']" min="0" max="999" (change)="modifyElement('border_size')"/>
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_border']" matNativeControl (selectionChange)="modifyElement('measurement_border')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
  
            <mat-form-field appearance="outline" class="first-child">
              <mat-label>Style</mat-label>
              <mat-select [(ngModel)]="templateService.currentElement['border_style']" matNativeControl (selectionChange)="modifyElement('border_style')">
                <mat-option value="solid">Solid</mat-option>
                <mat-option value="dotted">Dotted</mat-option>
                <mat-option value="dashed">Dashed</mat-option>
                <mat-option value="double">Double</mat-option>
                <mat-option value="groove">Groove</mat-option>
                <mat-option value="ridge">Ridge</mat-option>
                <mat-option value="inset">Inset</mat-option>
                <mat-option value="outset">Outset</mat-option>
                <mat-option value="None">None</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
    
          <!-- Padding -->
          <mat-expansion-panel hideToggle *ngIf="templateService.valueEmittedFromChild !== 0 && templateService.valueEmittedFromChild !== 10 && templateService.valueEmittedFromChild !== undefined && templateService.valueEmittedFromChild !== 8">
            <mat-expansion-panel-header>
              <mat-panel-title> Padding </mat-panel-title>
            </mat-expansion-panel-header>
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Padding Top</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['padding_top']" min="0" max="999" (change)="modifyElement('padding_top')"/>
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_padding_top']" matNativeControl (selectionChange)="modifyElement('measurement_padding_top')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
    
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Padding Right</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['padding_right']" min="0" max="999" (change)="modifyElement('padding_right')" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_padding_right']" matNativeControl (selectionChange)="modifyElement('measurement_padding_right')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
    
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Padding Bottom</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['padding_bottom']" min="0" max="999" (change)="modifyElement('padding_bottom')" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_padding_bottom']" matNativeControl (selectionChange)="modifyElement('measurement_padding_bottom')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
    
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Padding Left</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['padding_left']" min="0" max="999" (change)="modifyElement('padding_left')" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_padding_left']" matNativeControl (selectionChange)="modifyElement('measurement_padding_left')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </mat-expansion-panel>
    
          <!-- Margin -->
          <mat-expansion-panel hideToggle *ngIf="templateService.valueEmittedFromChild !== 0 && templateService.valueEmittedFromChild !== 10 && templateService.valueEmittedFromChild !== undefined">
            <mat-expansion-panel-header>
              <mat-panel-title> Margin </mat-panel-title>
            </mat-expansion-panel-header>
    
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Margin Top</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['margin_top']" min="0" max="999" (change)="modifyElement('margin_top')" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_margin_top']" matNativeControl (selectionChange)="modifyElement('measurement_margin_top')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
    
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Margin Right</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['margin_right']" min="0" max="999" (change)="modifyElement('margin_right')" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_margin_right']" matNativeControl (selectionChange)="modifyElement('measurement_margin_right')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
    
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Margin Bottom</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['margin_bottom']" min="0" max="999" (change)="modifyElement('margin_bottom')" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_margin_bottom']" matNativeControl (selectionChange)="modifyElement('measurement_margin_bottom')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
    
            <span class="option-with-size-selection">
              <mat-form-field appearance="outline" class="first-child">
                <mat-label>Margin Left</mat-label>
                <input matInput type="number" [(ngModel)]="templateService.currentElement['margin_left']" min="0" max="999" (change)="modifyElement('margin_left')" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="second-child">
                <mat-select [(ngModel)]="templateService.currentElement['measurement_margin_left']" matNativeControl (selectionChange)="modifyElement('measurement_margin_left')">
                  <mat-option [value]="'px'">px</mat-option>
                  <mat-option [value]="'em'">em</mat-option>
                  <mat-option [value]="'%'">%</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </mat-expansion-panel>
    
          <!-- Width & Height -->
          <mat-expansion-panel hideToggle *ngIf="templateService.valueEmittedFromChild !== 0 && templateService.valueEmittedFromChild !== 10 && templateService.valueEmittedFromChild !== 1 && templateService.valueEmittedFromChild !== 2 && templateService.valueEmittedFromChild !== 6 && templateService.valueEmittedFromChild !== undefined">
            <mat-expansion-panel-header>
              <mat-panel-title *ngIf="templateService.valueEmittedFromChild == 8">
                Height
              </mat-panel-title>
              <mat-panel-title *ngIf="templateService.valueEmittedFromChild !== 8">
                Height & Width
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="templateService.valueEmittedFromChild !== 7">
              <span class="option-with-size-selection">
                <mat-form-field appearance="outline" class="first-child">
                  <mat-label>Height</mat-label>
                  <input matInput type="number" [(ngModel)]="templateService.currentElement['height']" min="0" max="999" (change)="modifyElement('height')" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="second-child">
                  <mat-select [(ngModel)]="templateService.currentElement['measurement_height']" matNativeControl (selectionChange)="modifyElement('measurement_height')">
                    <mat-option [value]="'px'">px</mat-option>
                    <mat-option [value]="'em'">em</mat-option>
                    <mat-option [value]="'%'">%</mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </ng-container>
            <br/>
    
            <ng-container *ngIf="templateService.valueEmittedFromChild !== 8 && templateService.valueEmittedFromChild != 4 && templateService.valueEmittedFromChild != 2">
              <span class="option-with-size-selection">
                <mat-form-field appearance="outline" class="first-child">
                  <mat-label>Width</mat-label>
                  <input matInput type="number" [(ngModel)]="templateService.currentElement['width']" min="0" max="999" (change)="modifyElement('width')" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="second-child">
                  <mat-select [(ngModel)]="templateService.currentElement['measurement_width']" matNativeControl (selectionChange)="modifyElement('measurement_width')">
                    <mat-option [value]="'px'">px</mat-option>
                    <mat-option [value]="'em'">em</mat-option>
                    <mat-option [value]="'%'">%</mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </ng-container>
          </mat-expansion-panel>
  
          <!-- Class & Css -->
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Additional styles </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Styles</mat-label>
              <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="15" [(ngModel)]="templateService.currentElement['style']" (change)="modifyElement('style')"></textarea>
              <button *ngIf="templateService.currentElement['style']" matSuffix mat-icon-button aria-label="Clear" (click)="templateService.currentElement['style'] = ''; modifyElement('style')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
              <mat-label>Class</mat-label>
              <input matInput type="text" [(ngModel)]="templateService.currentElement['class']" (change)="modifyElement('class')"/>
              <button *ngIf="templateService.currentElement['class']" matSuffix mat-icon-button aria-label="Clear" (click)="templateService.currentElement['class'] = ''; modifyElement('class')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-hint align="start"><strong>Separar las diferentes clases con espacios</strong></mat-hint>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 1em;">
              <mat-label>Definición de clases (CSS ADICIONAL)</mat-label>
              <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="15" [(ngModel)]="templateService.plantillaInfo['class_codes']"></textarea>
              <button *ngIf="templateService.plantillaInfo['class_codes']" matSuffix mat-icon-button aria-label="Clear" (click)="templateService.plantillaInfo['class_codes'] = ''">
                <mat-icon>close</mat-icon>
              </button>
              <mat-hint align="start"><strong>Debes definir las clases cómo sería en un fichero CSS</strong><br><strong>Las clases se aplicará al enviar el mail</strong></mat-hint>
            </mat-form-field>

          </mat-expansion-panel>
        </mat-accordion>
        
        <div *ngIf="templateService.showGridTypes == 1"style="padding: 30px;">
          <h5>Seleccionar tipo de columnas</h5>
          <span fxLayout="row wrap" style="justify-content: space-around; margin-top: 15px;">
            <button class="button-columns-custom" value="100" (click)="addGridElement('100')">
              <div style="border: 2px solid #882334; border-radius: 5px; min-width: 40px; height: 28px;" fxLayout="row"></div>
              <p style="text-align: center; margin-top: 4px">100</p>
            </button>
            <button class="button-columns-custom" value="5050" (click)="addGridElement('5050')">
              <div style="border: 2px solid #882334; border-radius: 5px; min-width: 40px; height: 28px;" fxLayout="row">
                <div style="margin-left: calc(50% - 1px); width: 2px; background: #882334; height: 100%;"></div>
              </div>
              <p style="text-align: center; margin-top: 4px">50/50</p>
            </button>
            <button class="button-columns-custom" value="3070" (click)="addGridElement('3070')">
              <div style="border: 2px solid #882334; border-radius: 5px; min-width: 40px; height: 28px;" fxLayout="row">
                <div style="margin-left: calc(30% - 1px); width: 2px; background: #882334; height: 100%;"></div>
              </div>
              <p style="text-align: center; margin-top: 4px">30/70</p>
            </button>
            <button class="button-columns-custom" value="7030" (click)="addGridElement('7030')">
              <div style="border: 2px solid #882334; border-radius: 5px; min-width: 40px; height: 28px;" fxLayout="row">
                <div style="margin-left: calc(70% - 1px); width: 2px; background: #882334; height: 100%;"></div>
              </div>
              <p style="text-align: center; margin-top: 4px">70/30</p>
            </button>
            <button class="button-columns-custom" value="333333" (click)="addGridElement('333333')">
              <div style="border: 2px solid #882334; border-radius: 5px; min-width: 40px; height: 28px;" fxLayout="row">
                <div style="margin-left: calc(33.33% - 1px); width: 2px; background: #882334; height: 100%;"></div>
                <div style="margin-left: calc(33.33% - 1px); width: 2px; background: #882334; height: 100%;"></div>
              </div>
              <p style="text-align: center; margin-top: 4px">33/33/33</p>
            </button>
            <button class="button-columns-custom" value="255025" (click)="addGridElement('255025')">
              <div style="border: 2px solid #882334; border-radius: 5px; min-width: 40px; height: 28px;" fxLayout="row">
                <div style="margin-left: calc(25% - 1px); width: 2px; background: #882334; height: 100%;"></div>
                <div style="margin-left: calc(50% - 1px); width: 2px; background: #882334; height: 100%;"></div>
              </div>
              <p style="text-align: center; margin-top: 4px">25/50/25</p>
            </button>
          </span>
        </div>
      </ng-container>

    </div>
  </div>
</ng-container>