import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JourneyService } from 'app/services/journey/journey.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from "lodash";
import { FunctionsService } from 'app/services/functions/functions.service';

@Component({
  selector: 'app-add-journey-element-dialog',
  templateUrl: './add-journey-element-dialog.component.html',
  styleUrls: ['./add-journey-element-dialog.component.css']
})
export class AddJourneyElementDialogComponent implements OnInit {
  public actualElement: any;

  addNodesForm: FormGroup;
  items: FormArray;
  nodeSelected = false;
  totalNodes = "";
  popup = 'trigger';
  selectedOption: number;
  triggerOptions = ['Update', 'Add Tag', 'Remove tag', 'Email', 'New data', 'Journey child'];
  typeOptions: Observable<any[]>;
  ifElseNotAllowd;
  keepOnlyNo = true;
  keepOnlyYes = true;
  autoComplete = false;
  autoCompleteTitle: string;
  autoCompletePlaceholder: string;
  typeDataForm: FormGroup;
  autocompleteData: Observable<any[]>;
  nameActualAutocomplete = "";
  showExtraInfo = false;
  type: any;
  valid = false;

  constructor(
    private formBuilder: FormBuilder, 
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialog: MatDialog,
    private journeyService: JourneyService,
    private functionsService: FunctionsService,
    ) { }

  ngOnInit(): void {
    this.journeyService.applyChanges = true;
    this.popup = this.data.nodes;
    this.ifElseNotAllowd = this.data.ifElse;
    this.keepOnlyNo = this.data?.deleteType?.keepOnlyNo;
    this.keepOnlyYes = this.data?.deleteType?.keepOnlyYes;
    this.journeyService.optionAutocompleteSelected = false;
    
    console.log(this.data, "datadatadata");
    if(this.data['nodes'] == "addNodes") {
      this.actualElement = _.cloneDeep(this.journeyService.journeyActionDefault);
    } else if(this.data['nodes'] == "editNodes") {
      this.actualElement = this.data['element'];
    } else if(this.data['nodes'] == "editTrigger") {
      this.actualElement = this.data['element'];
    }
    this.actualElement['formCreator'] = _.cloneDeep(this.journeyService.formBuilderCustom(this.actualElement));
    this.valueChangesElement(this.actualElement['formCreator'].value, true);
    this.actualElement['formCreator'].valueChanges.subscribe(value => {
      this.valueChangesElement(value, false);
    });
  }

  createItem() {
    return this.formBuilder.group({
      name: '',
      width: '',
      height: '',
      parent: ''
    });
  }

  addItems(nodes): void {
    if (nodes > 0) {
      this.nodeSelected = true;
    }
    this.items = this.addNodesForm.get('items') as FormArray;
    while (this.items.length !== 0) {
      this.items.removeAt(0);
    }
    for (let i =0; i<nodes; i++) {
      this.items.push(this.createItem());
    }
  }

  closePopup() {
    console.log("feaffer", this.actualElement)
    this.journeyService.actualElement = this.actualElement;
    this.dialog.closeAll();
  }

  changeType(value, refresh = true) {
    console.log(this.actualElement, value['id_type_action'], "changeType");
    this.showExtraInfo = false;
    this.type = value['id_type_action'];
    this.autoComplete = true;
    
    if(refresh) {
      this.journeyService.optionAutocompleteSelected = false;
      this.actualElement['id_template'] = this.journeyService.journeyActionDefault['id_template'];
      this.actualElement['id_temporality'] = this.journeyService.journeyActionDefault['id_temporality'];
      this.actualElement['num_temporality'] = this.journeyService.journeyActionDefault['num_temporality'];
      this.actualElement['id_tag'] = this.journeyService.journeyActionDefault['id_tag'];
      this.actualElement['id_action_new'] = this.journeyService.journeyActionDefault['id_action_new'];
      this.refreshValues();
    }

    if (this.type == 1 || this.type == 2 || this.type == 3) {
      this.autoCompleteTitle = "Seleccionar plantilla";
      this.nameActualAutocomplete = "id_template";
    } else if (this.type == 4 || this.type == 8 || this.type == 9 || this.type == 11 || this.type == 12) {
      this.autoCompleteTitle = "Seleccionar tag";
      this.nameActualAutocomplete = "id_tag";
    } else if (this.type == 6) {
      this.autoCompleteTitle = "Seleccionar Journey";
      this.nameActualAutocomplete = "id_action_new";
    } else if (this.type == 7) {
      this.autoCompleteTitle = "Seleccionar Acción";
      this.nameActualAutocomplete = "id_action_new";
    }
    this.initialize();
    this.showExtraInfo = true;
  }
  
  initialize() {
    if (this.type == 1) {
      this.autocompleteData = this.actualElement['formCreator'].controls["id_template"].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.journeyService._filterEmailTemplates(value || ''))
      );
    } else if (this.type == 2) {
      this.autocompleteData = this.actualElement['formCreator'].controls["id_template"].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.journeyService._filterSmsTemplates(value || ''))
      );
    } else if (this.type == 3) {
      this.autocompleteData = this.actualElement['formCreator'].controls["id_template"].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.journeyService._filterWhatappTemplates(value || ''))
      );
    } else if (this.type == 4 || this.type == 8 || this.type == 9 || this.type == 11 || this.type == 12) {
      this.autocompleteData = this.actualElement['formCreator'].controls["id_tag"].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.journeyService._filterTagsTemplates(value || ''))
      );
    } else if (this.type == 6) {
      this.autocompleteData = this.actualElement['formCreator'].controls["id_action_new"].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.journeyService._filterJourneys(value || ''))
      );
    } else if (this.type == 7) {
      this.autocompleteData = this.actualElement['formCreator'].controls["id_action_new"].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.journeyService._filterJourneyAction(value || ''))
      );
    }
  }

  submitTypeData() {
    this.valid = !this.typeDataForm.valid;
    console.log("type data form ===>> ", this.typeDataForm.valid);    
  }

  cancelElement() {
    this.journeyService.applyChanges = false;
    this.closePopup();
  }

  submitElement() {
    console.log("this.actualElement", this.actualElement);
    if(!this.journeyService.isLastElement && this.actualElement['id_type_action'] == 16) {
      this.functionsService.openSnackBar("No puedes añadir la acción 'FINISH JOURNEY' si el elemento no es el último.", 7000, [
        "red-snackbar",
      ]);
      return;
    }

    console.log(this.actualElement['formCreator']);
    if(this.actualElement['formCreator'].valid) {
      this.closePopup();
    } else {
      this.functionsService.openSnackBar("¡Vaya, parece que no has completado los campos obligatorios!", 7000, [
        "red-snackbar",
      ]);
    }
  }

  refreshValues() {
    if(this.type == 1 || this.type == 2 || this.type == 3) {
      this.actualElement['formCreator'].get('id_tag').setValidators(null);
      this.actualElement['formCreator'].get('id_tag').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_temporality').setValidators(null);
      this.actualElement['formCreator'].get('id_temporality').updateValueAndValidity();
      this.actualElement['formCreator'].get('num_temporality').setValidators(null);
      this.actualElement['formCreator'].get('num_temporality').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_action_new').setValidators(null);
      this.actualElement['formCreator'].get('id_action_new').updateValueAndValidity();
    } else if(this.type == 4 || this.type == 8 || this.type == 9 || this.type == 11 || this.type == 12) {
      this.actualElement['formCreator'].get('id_template').setValidators(null);
      this.actualElement['formCreator'].get('id_template').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_temporality').setValidators(null);
      this.actualElement['formCreator'].get('id_temporality').updateValueAndValidity();
      this.actualElement['formCreator'].get('num_temporality').setValidators(null);
      this.actualElement['formCreator'].get('num_temporality').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_action_new').setValidators(null);
      this.actualElement['formCreator'].get('id_action_new').updateValueAndValidity();
    } else if(this.type == 5) {
      this.actualElement['formCreator'].get('id_template').setValidators(null);
      this.actualElement['formCreator'].get('id_template').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_tag').setValidators(null);
      this.actualElement['formCreator'].get('id_tag').updateValueAndValidity();

      this.actualElement['formCreator'].get('id_action_new').setValidators(null);
      this.actualElement['formCreator'].get('id_action_new').updateValueAndValidity();

    } else if(this.type == 6 || this.type == 7) {
      this.actualElement['formCreator'].get('id_template').setValidators(null);
      this.actualElement['formCreator'].get('id_template').updateValueAndValidity();

      this.actualElement['formCreator'].get('id_temporality').setValidators(null);
      this.actualElement['formCreator'].get('id_temporality').updateValueAndValidity();
      this.actualElement['formCreator'].get('num_temporality').setValidators(null);
      this.actualElement['formCreator'].get('num_temporality').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_tag').setValidators(null);
      this.actualElement['formCreator'].get('id_tag').updateValueAndValidity();
    } else {
      this.actualElement['formCreator'].get('id_template').setValidators(null);
      this.actualElement['formCreator'].get('id_template').updateValueAndValidity();

      this.actualElement['formCreator'].get('id_temporality').setValidators(null);
      this.actualElement['formCreator'].get('id_temporality').updateValueAndValidity();
      this.actualElement['formCreator'].get('num_temporality').setValidators(null);
      this.actualElement['formCreator'].get('num_temporality').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_tag').setValidators(null);
      this.actualElement['formCreator'].get('id_tag').updateValueAndValidity();
      
      this.actualElement['formCreator'].get('id_action_new').setValidators(null);
      this.actualElement['formCreator'].get('id_action_new').updateValueAndValidity();

      this.showExtraInfo = false;
      this.autoComplete = false;
    }

    this.actualElement['formCreator'].patchValue({
      id_template: this.journeyService.journeyActionDefault['id_template'],
      id_tag: this.journeyService.journeyActionDefault['id_tag'],
      id_temporality: this.journeyService.journeyActionDefault['id_temporality'],
      num_temporality: this.journeyService.journeyActionDefault['num_temporality'],
      id_action_new: this.journeyService.journeyActionDefault['id_action_new'],
    });

    this.actualElement['id_template'] = this.journeyService.journeyActionDefault['id_template'];
    this.actualElement['id_tag'] = this.journeyService.journeyActionDefault['id_tag'];
    this.actualElement['id_temporality'] = this.journeyService.journeyActionDefault['id_temporality'];
    this.actualElement['num_temporality'] = this.journeyService.journeyActionDefault['num_temporality'];
    this.actualElement['id_action_new'] = this.journeyService.journeyActionDefault['id_action_new'];
  }

  clickClose() {
    this.refreshValues();
    this.journeyService.optionAutocompleteSelected = false;
  }


  valueChangesElement(value, first) {
    if(value['id_type_action'] && value['id_type_action'] != 0) {
      this.actualElement['name'] = value['name'];
      this.actualElement['notes'] = value['notes'];
      this.actualElement['id_type_action'] = value['id_type_action'];
      let arrayShearchIcon = this.journeyService.actionTypes;
      if(this.actualElement['id_category'] == 2) arrayShearchIcon = this.journeyService.triggerTypes;
      for(let i in arrayShearchIcon) {
        if(this.actualElement['id_type_action'] == arrayShearchIcon[i]['id']) {
          this.actualElement['icon'] = arrayShearchIcon[i]['icon'];
          break;
        }
      }

      if(first) {
        if(this.actualElement['id_type_action']) {
          this.changeType(value, false);
          this.journeyService.optionAutocompleteSelected = true;
        }
      } else {
        if(value['id_type_action'] && value['id_type_action'] != this.type) {
          this.changeType(value);
        } else {
          if(value['id_type_action'] == 1) {
            for(let i in this.journeyService.emailTemplates) {
              if(this.journeyService.emailTemplates[i]['nombre'] == value['id_template']) {
                this.actualElement['id_template'] = this.journeyService.emailTemplates[i]['id'];
                this.journeyService.optionAutocompleteSelected = true;
                break;
              }
            }
          } else if(value['id_type_action'] == 2) {
            for(let i in this.journeyService.smsTemplates) {
              if(this.journeyService.smsTemplates[i]['nombre'] == value['id_template']) {
                this.actualElement['id_template'] = this.journeyService.smsTemplates[i]['id'];
                this.journeyService.optionAutocompleteSelected = true;
                break;
              }
            }
          } else if(value['id_type_action'] == 3) {
            for(let i in this.journeyService.whatappTemplates) {
              if(this.journeyService.whatappTemplates[i]['nombre'] == value['id_template']) {
                this.actualElement['id_template'] = this.journeyService.whatappTemplates[i]['id'];
                this.journeyService.optionAutocompleteSelected = true;
                break;
              }
            }
          } else if(value['id_type_action'] == 4 || value['id_type_action'] == 8 || value['id_type_action'] == 9 || value['id_type_action'] == 11 || value['id_type_action'] == 12) {
            for(let i in this.journeyService.tagsTemplates) {
              if(this.journeyService.tagsTemplates[i]['nombre'] == value['id_tag']) {
                this.actualElement['id_tag'] = this.journeyService.tagsTemplates[i]['id'];
                this.journeyService.optionAutocompleteSelected = true;
                break;
              }
            }
          } else if(value['id_type_action'] == 5) {
            this.actualElement['num_temporality'] = value['num_temporality'];
            for(let i in this.journeyService.temporalities) {
              if(this.journeyService.temporalities[i]['id_temporality'] == value['id_temporality']) {
                this.actualElement['id_temporality'] = this.journeyService.temporalities[i]['id_temporality'];
                break;
              }
            }
          } else if(value['id_type_action'] == 6) {
            for(let i in this.journeyService.journeys) {
              if(this.journeyService.journeys[i]['name'] == value['id_action_new']) {
                this.actualElement['id_action_new'] = this.journeyService.journeys[i]['id'];
                this.journeyService.optionAutocompleteSelected = true;
                break;
              }
            }
          } else if(value['id_type_action'] == 7) {
            for(let i in this.journeyService.journeyAction) {
              if(this.journeyService.journeyAction[i]['name'] == value['id_action_new']) {
                this.actualElement['id_action_new'] = this.journeyService.journeyAction[i]['id'];
                this.journeyService.optionAutocompleteSelected = true;
                break;
              }
            }
          }
        }
      }
    }
    else this.autoComplete = false;
  }
}
