import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef, AfterContentChecked, OnDestroy, AfterViewInit } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { of } from "rxjs";
//import { Pipe, PipeTransform } from '@angular/core';

//SERVICES
import { EndpointService } from "./../../../services/endpoint/endpoint.service";
import { GenericService } from "../../../services/generic/generic.service";
import { Observable } from "rxjs";
import { UntypedFormControl, Validators } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatRadioChange } from "@angular/material/radio";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { Dispatcher } from "app/services/dispatcher";
import { FunctionsService } from "../../../services/functions/functions.service";

@Component({
  selector: "app-selection",
  templateUrl: "./selection.component.html",
  styleUrls: ["./selection.component.css"],
})
export class SelectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() structure: any;
  @Input() index: number;
  genericForm: any;

  public options: any;
  public idQuery: number
  public option: any;
  public optionsAuto: string[] = [];
  public optionsAutoMap: Map<string, number>;
  public chipsList: string[] = [];
  public bdField: string;
  public filteredOptions: Observable<string[]>;
  public myControl = new UntypedFormControl();
  public multiple: boolean;
  public selectable: boolean;
  public removable: boolean;
  public separatorKeysCodes: number[];
  //public showSuffix = false;
  //private ogdata;
  public value: any;
  public isEmptyValue: boolean;
  public loadedValue;
  public loaded = false;
  private subscribeArray = [];
  public inputValueLabel: string;

  @Output() notifyOthers = new EventEmitter<{}>();

  @ViewChild("chipInput") chipInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  constructor(
    public genericService: GenericService,
    private endpointService: EndpointService,
    protected dispatcher: Dispatcher,
    private functionsService: FunctionsService
  ) {}


  ngOnInit(): void {

    let sbscr = this.dispatcher.subscribe(this);
    this.subscribeArray.push(sbscr);

    if(this.structure[this.index]['tmp_value'] === null || this.structure[this.index]['tmp_value'] === undefined) this.structure[this.index]['tmp_value'] = '';
    this.loadedValue = this.structure[this.index]['tmp_value'];
    //this.option.value = this.structure[this.index]['tmp_value'];
    this.bdField = this.structure[this.index]["bd_field"];
    this.genericForm = this.structure[this.index]["form"];
    this.multiple = this.structure[this.index]["multiple"] == 1;

    if(this.multiple && !Array.isArray(this.structure[this.index]["tmp_value"])){
      this.value = [this.structure[this.index]["tmp_value"]];
      if(!this.genericService.selectOptions[this.structure[this.index]['id_query']].includes(this.structure[this.index]['tmp_value'])) this.value = null;
      this.isEmptyValue = false;
    }else{
      this.value = this.structure[this.index]["tmp_value"];
      if(this.value === '') this.value = null; 
      this.isEmptyValue = false;
    }

    this.selectable = true;
    this.removable = true;
    this.separatorKeysCodes = [ENTER, COMMA];
    this.optionsAutoMap = new Map();
    this.option = this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + this.bdField];

    if (this.structure[this.index]["type"] == "radio" || this.structure[this.index]["type"] == "toggle") {
      if (this.structure[this.index]["id_functional_status_general"] === 5) {
        this.structure[this.index].form.controls[this.structure[this.index]['id_functional_area'] + '-' + this.bdField].setValidators([
          Validators.required,
        ]);
      }
      this.structure[this.index].invalid = false;
    }
    if(this.multiple) {
      if(this.structure[this.index]['tmp_value'] !== undefined && this.structure[this.index]['tmp_value'] !== null) this.structure[this.index].originalValue = JSON.parse(JSON.stringify(this.structure[this.index]['tmp_value']))
      if(!Array.isArray(this.structure[this.index]["originalValue"])) this.structure[this.index]["originalValue"] = [this.structure[this.index]["originalValue"]]
      if (this.structure[this.index]['tmp_value'] === undefined && this.structure[this.index]["type"] == "autocomplete") {
        this.structure[this.index]['tmp_value'] = [];
      }
      if (!Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = [];
    }

    if (this.value !== undefined && this.value !== null) {
      this.genericForm.patchValue({
        [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value,
      });
    }
    let susbcr = this.genericService.structureUpdated.subscribe(
      (strc) => {
        for(let key in strc){
          const elem = this.genericService.findElementWithIdLoop(strc[key], this.structure[this.index]['id_functional_area']);
          if(elem){
            this.value = elem['child']['tmp_value'];
            if(this.value === '') this.value = null;
            if (this.value) {
              this.genericForm.patchValue({
                [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value
              });
            }

            if(this.structure[this.index]["type"] == "autocomplete") {
              this.myControl.setValue(Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v === this.value).map(([k]) => k));
              //this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + this.bdField] = Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v === this.value).map(([k]) => k)
            }
          }
        }
      }
    );
    this.subscribeArray.push(susbcr);

    let parent = this.structure[this.index]['id_functional_parent_initial'];
    let vcsbcr = this.genericForm.valueChanges.subscribe((newToogleValue) => {
      let v = null;
      let type = '';
      if(this.structure[this.index]["type"] == "autocomplete") {
        this.optionsAutoMap.forEach((key, value) => {
          if(key == newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]) v = key;
          if(value == newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]) v = key;
        });
        type = 'autocomplete_';
      } else if(this.structure[this.index]["id_functional_type"] != 15) {
        if(newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField] !== undefined) v = newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField];
        else v = undefined;
        type = 'selection_';
      }

      let valueInit = "";
      if(this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField] !== undefined && this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'] !== undefined) valueInit = this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'];
      else valueInit = this.value;

      if((v || v == 0) && v !== "" && (valueInit !== v)) {
        if(!this.genericService.formsChanged[parent]) {
          this.genericService.formsChanged[parent] = {};
        }
        let vold = null, vnew = null, voriginal = null;
        if((this.genericService.selectOptions[this.idQuery]) && type != 'autocomplete_') {
          let arrayResults = [];
          arrayResults = this.genericService.selectOptions[this.idQuery];
          if(this.structure[this.index]["type"] === 'radio') {
            type = "radio_";
          }
          if(this.structure[this.index]["type"] === 'toggle') {
            type = "toggle_";
          }

          if(!this.multiple) {
            for(let o of arrayResults){
              if (o['value'] === valueInit) vold = o['text'];
              if (o['value'] === v && v !== valueInit) vnew = o['text'];
              if (vold != null && vnew != null) break;
            }
          } else {
            vold = "";
            vnew = "";
            let oldCount = valueInit !== null ? valueInit.length- 1 : 0;
            let newCount = v.length - 1;
            for(let o of this.genericService.selectOptions[this.idQuery]){
              if(valueInit !== null) {
                for (let g of valueInit) {
                  if (o['value'] === valueInit) {
                    vold = vold + o['text'];
                    if (oldCount > 0) vold = vold + ", ";
                    oldCount--;
                  }
                }
              }
              for(let t of v) {
                if (o['value'] === t) {
                  vnew = vnew + o['text'];
                  if(newCount > 0) vnew = vnew + ", ";
                  newCount--;
                }
              }
            }
          }
        } else {
          type = 'autocomplete_';
          voriginal = valueInit;
          if(!this.multiple) {
            vnew = newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField];
            this.optionsAutoMap.forEach((key, value) => {
              if(key == vnew || value == vnew){
                vnew = value;
              }
              if(key == voriginal || value == voriginal){
                vold = value;
              }
            });
          } else {
            let vnuevo = this.structure[this.index]['tmp_value'];
            vold = "";
            vnew = "";
            let oldCount = voriginal !== null ? voriginal.length- 1 : 0;
            let newCount = vnuevo.length - 1;

            this.optionsAutoMap.forEach((key, value) => {
              if(voriginal !== null) {   
                for(let vo of voriginal) {
                  if(key == vo || value == vo){
                    vold = vold + value;
                    if (oldCount > 0) vold = vold + ", ";
                    oldCount--;
                  }
                }             
              }
              for(let t of vnuevo) {
                if(key == t || value == t) {
                  vnew = vnew + value;
                  if(newCount > 0) vnew = vnew + ", ";
                  newCount--;
                }
              }
            });
          }
        }
        if(vnew === vold){
          if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
          if(this.genericService.formsChanged[parent]){
            if(this.structure[this.index]["type"] === "radio") type = "radio_";
            if(this.structure[this.index]["type"] === "toggle") type = "toggle_";
            delete this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']];
            // EJECUTAMOS FUNCIÓN
            let lastValueOnExecuteFunction = undefined;
            if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
            if(this.structure[this.index]["id_function"] > 0 && (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction !== vnew)) this.functionsService.getFunctionFormField(this.structure[this.index]);
          }
        } else {
          let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
          if(id_parent === undefined || id_parent === null) id_parent = this.structure[this.index]['id_functional_parent'];
          this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], type, vold, vnew, vold, v, this.structure[this.index]['label']);
        }
      } else if(valueInit === v) {
        if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
        if(this.genericService.formsChanged[parent]){
          if(this.structure[this.index]["type"] === "radio") type = "radio_";
          if(this.structure[this.index]["type"] === "toggle") type = "toggle_";
          delete this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']];
          // EJECUTAMOS FUNCIÓN
          let lastValueOnExecuteFunction = undefined;
          if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
          if(this.structure[this.index]["id_function"] > 0 && lastValueOnExecuteFunction !== v) this.functionsService.getFunctionFormField(this.structure[this.index]);
        }
      } else if(this.genericService.selectOptions[this.idQuery]) {
        this.clear(this.bdField, 1);
      }

      if(this.structure[this.index]["type"] != "autocomplete") {
        this.structure[this.index]["tmp_value"] = newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']];
      } else {
        if(!this.multiple) {
          let valueNewToggle = newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]
          this.optionsAutoMap.forEach((key, value) => {
            if(key == valueNewToggle || value == valueNewToggle){
              valueNewToggle = key;
            }
          });

          this.structure[this.index]["tmp_value"] = valueNewToggle;
        }
      }
      this.isEmptyValue = false;
    });
    this.subscribeArray.push(vcsbcr);

    let vcfcsbcr = this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']].valueChanges.subscribe((newValue) => {
      this.updateDataValidity(newValue);
    })
    this.subscribeArray.push(vcfcsbcr);

    this.getDisplayedOptions();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((chip: string | null) =>
        chip ? this._filter(chip) : this.optionsAuto.slice()
      )
    );

    if(this.structure[this.index]["type"] == "autocomplete") {
      this.myControl.setValue(Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v === this.value).map(([k]) => k));
      //this.genericForm.value[this.index]["bd_field"] = Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v === this.value).map(([k]) => k)
      if(this.multiple && Array.isArray(this.structure[this.index]['tmp_value'])) {
        for(let t in this.structure[this.index]['tmp_value']){
          let val = Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v === this.structure[this.index]['tmp_value'][t]).map(([k]) => k)[0];
          
          this.chipsList.push(val);
          let index = this.optionsAuto.findIndex(opt => opt == val)
          this.optionsAuto.splice(index, 1);
        }
      }
    }

    //this.myControl.setValidators(Validators.required)
    this.structure[this.index]['tmp_value'] = this.loadedValue;
    /*if(this.structure[this.index]["tmp_value"] == null) {
      this.myControl.setValue('');
    }*/


    this.loaded = true;

    // Inicialitzem els valors originals
    this.genericService.setOriginalInputsValues(this.structure, this.index);

    this.genericService.selectOptionsChangeEvent.subscribe(() => this.getDisplayedOptions())

    let formFieldsUpdateSubscription = this.genericService.updateFormFields.subscribe((change) => {
      if(change) this.value = this.structure[this.index]['tmp_value'];
    });
    this.subscribeArray.push(formFieldsUpdateSubscription);
  }

  ngAfterViewInit() {
    //this.updateDataValidity(this.loadedValue, true)
  }

  private updateDataValidity(v, init = false) {
    if(this.structure[this.index]["type"] == 'autocomplete' && !init) v = this.optionsAutoMap.get(v);
    let data_validity_value = v;
    if (v === undefined || v === null) {
      if (this.structure[this.index]['tmp_value'] === undefined || this.structure[this.index]['tmp_value'] == '' || this.structure[this.index]['tmp_value'] === null) data_validity_value = -1;
      else data_validity_value = this.structure[this.index]['tmp_value'];
    } else if (v == -999) {
      data_validity_value = -1;
    }
    this.genericService.updateStatus(data_validity_value, this.structure[this.index])
  }

  chooseEmptySelection(bool){
    //this.showSuffix = bool;
    this.isEmptyValue = !bool;
  }

  searchGetCall(term: string) {
    if (term === '') {
      return of([]);
    }
    const arrayTerm = term.split(' ');
    return this.endpointService.getLocations(arrayTerm, false);
  }

  ngOnDestroy() {
    this.subscribeArray.forEach((subscription) => {
      if(subscription !== undefined) subscription.unsubscribe()
    });
  }

  getDisplayedOptions(): void {
    let filter = {
      bd_table: this.structure[this.index]["bd_table"],
      bd_field: this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]["bd_field"],
      id_db: this.structure[this.index]["id_db"],
      id_functional_area: this.structure[this.index]["id_functional_area"],
      window: this.structure[this.index]["window"],
    };
    let filter_data = this.genericService.findFormControl(filter);
    this.idQuery = +this.structure[this.index]["id_query"];

    if (this.idQuery !== 0) {
      let data = this.genericService.selectOptions[this.idQuery];

      if (this.structure[this.index]["primary_key"] == 1) {
        data = data.filter((elem) => {
          for (let i = 0; i < filter_data.length; ++i) {
            for (let j = 0; j < filter_data[i].length; ++j) {
                if (elem["value"] == filter_data[i][j]) return false;
                if (Array.isArray(filter_data[i][j])) {
                  if (elem["value"] == filter_data[i][j][0]) return false;
                }
              }
          }

          return true;
        });

      }
      if (this.structure[this.index]["type"] === "autocomplete") {
        if(data){
          this.optionsAutoMap = new Map;
          this.optionsAuto = [];
          data.forEach((element) => {
            if(element.text != ""){
              // eliminem la empty option de les que es mostren
              this.optionsAuto.push(element.text);
              this.optionsAutoMap.set(element.text, element.value);
            }
          });
          this.genericForm.patchValue({
            refresh: null
          })
        }
      } else if (this.structure[this.index]["type"] === "radio") {
      } else if (this.structure[this.index]["type"] === "toggle") {
      } else {
        if(this.value == '???'){
          if(this.multiple){
            this.value = [-1];
          }else{
            this.value = -1;
          }

          this.genericForm.patchValue({
            [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value,
          });

        }
      }
    }
  }

  // Filtrem els arrays per que no ens mostri la empty option
  filterFunction(arrayToFilter): any[] {
    if(arrayToFilter == undefined) return arrayToFilter;
    return arrayToFilter.filter(i => i.value !== "");
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.value = this.optionsAuto.filter((option) =>
      option.toLowerCase().includes(filterValue))
    return this.optionsAuto.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      if(!Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = []
      this.chipsList.push(value.trim());
      this.structure[this.index]['tmp_value'].push(this.optionsAutoMap.get(value))
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.myControl.setValue(null);
  }

  remove(chip: string): void {
    const index = this.chipsList.indexOf(chip);
    if (index >= 0) {
      this.structure[this.index]['tmp_value'].splice(index, 1)
      this.chipsList.splice(index, 1);
      this.optionsAuto.unshift(chip)
      this.myControl.setValue('');

      let parent = this.structure[this.index]['id_functional_parent_initial'];
      let vnuevo = this.structure[this.index]['tmp_value'];
      let voriginal = this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'];
      let vold = "";
      let vnew = "";
      let oldCount = voriginal !== null ? voriginal.length- 1 : 0;
      let newCount = vnuevo.length - 1;

      this.optionsAutoMap.forEach((key, value) => {
        if(voriginal !== null) {   
          for(let vo of voriginal) {
            if(key == vo || value == vo){
              vold = vold + value;
              if (oldCount > 0) vold = vold + ", ";
              oldCount--;
            }
          }             
        }
        for(let t of vnuevo) {
          if(key == t || value == t) {
            vnew = vnew + value;
            if(newCount > 0) vnew = vnew + ", ";
            newCount--;
          }
        }
      });
            
      let type = 'autocomplete_';
      if(vnew === vold){
        if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
        if(this.genericService.formsChanged[parent]) {
          delete this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']];
          // EJECUTAMOS FUNCIÓN
          let lastValueOnExecuteFunction = undefined;
          if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
          if(this.structure[this.index]["id_function"] > 0 && (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction !== vnew)) this.functionsService.getFunctionFormField(this.structure[this.index]);
        }
      } else {
        let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
        if(id_parent === undefined || id_parent === null) id_parent = this.structure[this.index]['id_functional_parent'];
        this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], type, vold, vnew, vold, voriginal, this.structure[this.index]['label']);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.multiple) {
      if(!Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = []
      this.chipsList.push(event.option.viewValue);
      this.structure[this.index]['tmp_value'].push(this.optionsAutoMap.get(event.option.viewValue))
      let index = this.optionsAuto.findIndex(opt => opt == event.option.viewValue)
      this.optionsAuto.splice(index, 1);
      this.myControl.setValue('');
      let element = <HTMLInputElement>document.getElementById(this.structure[this.index]['bd_field']);
      element.value = '';
      element.blur();
      let auto = <HTMLInputElement>document.getElementById('autocomplete');
      auto.blur();
    }

    this.genericForm.patchValue({ [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: event.option.viewValue });
    //this.updateStatus(this.optionsAutoMap.get(event.option.viewValue));
  }

  changeRadioValue(event: MatRadioChange) {
    this.structure[this.index]["tmp_value"] = event.value;
    this.structure[this.index].form.controls[this.structure[this.index]['id_functional_area'] + '-' + this.bdField].setValue(
      event.value
    );
    this.structure[this.index]["invalid"] = false;
  }

  changeToggleValue(event: MatButtonToggleChange) {
    this.structure[this.index]["tmp_value"] = event.value;
    this.structure[this.index].form.controls[this.structure[this.index]['id_functional_area'] + '-' + this.bdField].setValue(
      event.value
    );
    this.structure[this.index]["invalid"] = false;
  }

  clear(field, from = 0){
    let find = false;
    // He de crear l'etiqueta
    // Opcio 1: tenia empty option, havia canviat a alguna cosa i torno a tenir empty option
    for(let elem in this.genericService.formsChanged[this.structure[this.index]['id_functional_parent_initial']]){
      // He d'eliminar l'etiqueta
      // Busco si en el formsChanged es el id que he borrat
      let type = this.structure[this.index]['type'] + "_";
      if(elem == type + this.structure[this.index]['id_functional_area']){
        find = true;
        // Miro si el original era null o era una altra cosa
        if(this.genericService.formsChanged[this.structure[this.index]['id_functional_parent_initial']][elem]['old'] === '-')  {
          let parent = this.structure[this.index]['id_functional_parent_initial'];
          if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
          delete this.genericService.formsChanged[parent][elem];
          // EJECUTAMOS FUNCIÓN
          let lastValueOnExecuteFunction = undefined;
          if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
          if(this.structure[this.index]["id_function"] > 0 && (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction !== "-")) this.functionsService.getFunctionFormField(this.structure[this.index]);
        } else this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], this.structure[this.index]['id_functional_parent_initial'], this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], this.structure[this.index]['type'] + "_", this.genericService.formsChanged[this.structure[this.index]['id_functional_parent_initial']][elem]['old'], '-', this.value, '-', this.structure[this.index]['label']);
      }
    }
    // Opcio 2: tenia alguna cosa i ara no tinc empty option
    if(!find){
      // Creo l'etiqueta
      setTimeout(() => {       
        let vold, voriginal = this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'];
        if(from == 1) vold = this.value;
        this.optionsAutoMap.forEach((key, value) => {
          if(key == voriginal || value == voriginal){
            vold = value;
          }
        });
        this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], this.structure[this.index]['id_functional_parent_initial'], this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], this.structure[this.index]['type'] + "_", vold, '-', this.value, '-', this.structure[this.index]['label']);
      });
    }

    if(from == 0) {
      this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + field] = ('');
      this.myControl.setValue('');
      this.myControl.touched;
      this.value = '';
      if(!this.multiple) this.structure[this.index]['tmp_value'] = '';
      this.structure[this.index]['form']['controls'][this.structure[this.index]['id_functional_area'] + '-' + field].setValue('');
      let element = <HTMLInputElement>document.getElementById(field);
      element.value = '';
    }
  }

  public notify(data) {
    const id_functional_area = data["id_functional_area"];
    if (
      id_functional_area != this.structure[this.index]["id_functional_area"] &&
      data["field"] == this.bdField
    ) {
      this.getDisplayedOptions();
    }
  }

  public saveFirstOption(event, auto, bdField){
    let firstOption = auto['options']['first'];
    if(firstOption !== undefined) {
      if(event.target.value != "" && !this.optionsAuto.includes(event.target.value)) this.genericForm.patchValue({ [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: firstOption['value'] });
    } else {
      this.clear(bdField);
    }
  }

  public onClick(str: any, event) {
    event.stopPropagation();
    this.functionsService.getFunction(str)
  }
  
  public checkIfHasResults(array) {
    return array && array !== undefined && Array.isArray(array) && array.length > 0;
  }

}
