import { Component, OnInit, Input } from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-tools-custom',
  templateUrl: './tools-custom.component.html',
  styleUrls: ['./tools-custom.component.css']
})
export class ToolsCustomComponent implements OnInit {
  public opened = true;
  @Input() structure;

  constructor(public genericService: GenericService) { }

  ngOnInit(): void {

    console.log('TOOLS', this.structure);
  }

  getNumChanges() {
    return Object.keys(this.genericService.formsChanged).length;
  }

  toggleIcon() {
    this.opened = !this.opened;
  }
}
