import { Component, Input, OnInit } from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-table-generic-creator',
  templateUrl: './table-generic-creator.component.html',
  styleUrls: ['./table-generic-creator.component.css']
})
export class TableGenericCreatorComponent implements OnInit {
  
  @Input() structure = null;
  @Input() index: number;

  public idQuery: number;
  public loaded = false;
  displayedColumns = [];
  dataSource = [];

  constructor(public genericService: GenericService,) { }

  ngOnInit(): void {
    console.log('structure table', this.structure);
    console.log('structure table', this.structure[this.index]['child']);
    this.idQuery = this.structure[this.index]['id_query'];
    this.displayedColumns = ['position', 'name', 'weight', 'symbol'];
    this.dataSource = [
      {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
      {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
      {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
      {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
      {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
      {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
      {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
      {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
      {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
      {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
    ];
    this.loaded = true;
  }

}
