import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { RoutingService } from 'app/services/routing/routing.service';
import { Location } from '@angular/common';
import { GenericService } from 'app/services/generic/generic.service';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent implements OnInit, OnDestroy {
  @Input() structure = null;
  private monitoreo;
  public loaded = false;

  constructor(
      private routingService: RoutingService,
      private location: Location,
      public genericService: GenericService,
      private endPointService: EndpointService,
      private authService: AuthService) { }

  ngOnInit(): void {
    const id = this.routingService.urlHashtag;
    let finded = false;
    let count = -1;
    if(this.routingService.urlHashtag != null){
      for(let i in this.structure){
        if(this.structure[i]['id_functional_type'] == 4 && this.structure[i]['child'] && this.genericService.evaluateIfs(this.structure[i]['id_condition'], this.structure[i]['id_functional_area'], this.structure[i]) && this.structure[i]['id_functional_status_general'] !== 2) {
          ++count;
          if(this.structure[i]['id_functional_area'] == id) {
            this.structure[i]['tabActiveNow'] = true;
            this.structure[0]['tabActive'] = count;
            finded = true;
            break;
          }
        }
      }  
    } else {
      this.structure[0]['tabActive'] = 0;
      this.structure[0]['tabActiveNow'] = true;
    }
    if(!finded) {
      this.structure[0]['tabActive'] = 0;
      this.structure[0]['tabActiveNow'] = true;
    }
    this.endPointService.insertMonitoringInformation(
      this.authService.getLoggedInUserId(), this.structure[this.structure[0]['tabActive']]['id_functional_area'], this.structure[this.structure[0]['tabActive']]['id_functional_area'], this.genericService.staticHTML).subscribe(data => {
      this.monitoreo = data['response'];
    });
    this.loaded = true;
  }

  ngOnDestroy() {
    this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }

  getIdByIndex(index) : string{
    let count = -1;
    for(let i in this.structure) {
      if(this.structure[i]['id_functional_type'] == 4 && this.structure[i]['child'] && this.genericService.evaluateIfs(this.structure[i]['id_condition'], this.structure[i]['id_functional_area'], this.structure[i]) && this.structure[i]['id_functional_status_general'] !== 2) {
        ++count;
        if(index == count) {
          if(this.structure[i]['creatingMode']){
            return this.structure[i]['idTmp'];
          } else {
            return this.structure[i]['id_functional_area'];
          }
        }
      }
    }
  }

  onTabChange(event){
    let id = this.getIdByIndex(event['index']);
    if(id){
      for(let i in this.structure){
        if(this.structure[i]['creatingMode']){
          this.structure[i]['tabActiveNow'] = (id == this.structure[i]['idTmp']);
        } else {
          this.structure[i]['tabActiveNow'] = (id == this.structure[i]['id_functional_area']);
        }
      }

      if(this.structure[event['index']]['monitoreo'] != 0) {
        const url = this.routingService.urlWithoutHashtag;
        this.location.replaceState(url + '#' + id);
        this.endPointService.insertMonitoringInformation(
          this.authService.getLoggedInUserId(), id, id, this.genericService.staticHTML).subscribe(data => {
          this.monitoreo = data['response'];
        });
        if(this.monitoreo != id){
          this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
          });
        }
      }
    }
  }
}
