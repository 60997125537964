import { Injectable } from "@angular/core";
import { AuthService } from "../../auth/auth.service";
import { RoutingService } from "../../routing/routing.service";
import { ChatService } from "../../chat.service";
import { isArray } from "ngx-bootstrap";
import * as _ from 'lodash';

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  public idPlantilla: number;
  public saveReuse: boolean = false;
  public selectReuse: boolean = false;
  public showResult: boolean = false;
  public idParentElementReuseSelected = 0;
  public templateElementsArrayElementReuseSelected = [];
  public oldElementReuseSelected = {};
  public selectedElementToReuse = {};
  public tmpId = 0;

  public htmlResult = "";

  public arrayCopyElement: any = null;

  public arrayElementsPlantilla = [];
  public arrayElementsTipo = [];
  public arrayElementsPlantillaSubject = [];
  public plantillaInfo = {};

  public arrayElementsPlantillaRecord = [];
  public currentRecordIndex: number;

  public name: string;
  public description: string;

  public currentElement: object;
  public valueEmittedFromChild: number;
  public orden: number;
  public currentIndex: number;

  public showGridTypes: number = 0;
  public orderGrid: number;

  public currentTemplateElementsArray;
  public indexToInsertGrid: number;
  public currentTmpParent: number;

  public newArrayOrder;
  
  public currentLanguage: number;


  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private chatService: ChatService
  ) {}

  substituteTagsHTML(code, currentElement = this.currentElement) {
    let hrefCode: string = "";
    let dateCode: string = "";
    console.log(currentElement, "currentElementcurrentElementcurrentElement")

    if (typeof currentElement['url'] === "string" && currentElement['url'].length === 0) {
      hrefCode = "";
    } else {
      if(currentElement['id_tipo'] == 4) hrefCode = currentElement['url'];
      else hrefCode = hrefCode.concat(hrefCode, "href=" + '"' + currentElement['url'] + '"');;
    }
    code = code.replaceAll("{{href}}", hrefCode);

    // console.log("HEADING", this.heading);
    var replacer = function (key, value) {
      if (this[key] instanceof Date) {
        return this[key].toUTCString();
      }

      return value;
    };
    if (currentElement['date'] !== null && currentElement['date'] !== undefined) {
      dateCode = JSON.stringify(currentElement['date'], replacer);
      let arr = dateCode.split(" ");
      const arr1 = arr[0].split('"');
      dateCode = "";
      dateCode = dateCode.concat(
        arr1[1] + " " + arr[1] + " " + arr[2] + " " + arr[3]
      );
    }
    code = code.replaceAll("{{date}}", dateCode);
    if (currentElement['time'] !== null) code = code.replaceAll("{{time}}", currentElement['time']);

    if (currentElement['text'] !== null) code = code.replaceAll("{{text}}", currentElement['text']);
    if (currentElement['text_align'] !== null) code = code.replaceAll("{{tAlign}}", currentElement['text_align']);
    if (currentElement['font_size'] !== null)
      code = code.replaceAll("{{fontSize}}", "font-size: " + currentElement['font_size'] + "px");
    if (currentElement['font_weight'] !== null)
      code = code.replaceAll("{{fontWeight}}", "font-weight: " + currentElement['font_weight']);
    if (currentElement['font_family'] !== null)
      code = code.replaceAll("{{fontFamily}}", "font-family: " + currentElement['font_family'] + " !important");
    if (currentElement['color'] !== null)
      code = code.replaceAll("{{color}}", "color: " + currentElement['color']);
    if (currentElement['background_color'] !== null)
      code = code.replaceAll("{{bColor}}", "background: " + currentElement['background_color']);
    if (currentElement['border_color'] !== null)
      code = code.replaceAll("{{borderColor}}", "border-color: " + currentElement['border_color']);
    if (currentElement['border_radius'] !== null)
      code = code.replaceAll("{{bRadius}}", "border-radius: " + currentElement['border_radius'] + "px");
    if (currentElement['border_size'] !== null)
      code = code.replaceAll("{{bSize}}", currentElement['border_size']);
    if (currentElement['border_style'] !== null)
    code = code.replaceAll("{{bStyle}}", "border-style: " + currentElement['border_style']);

    if (currentElement['measurement_border'] !== null) 
      code = code.replaceAll("{{bMeasurement}}", currentElement['measurement_border']);

    if (currentElement['padding_top'] !== null)
      code = code.replaceAll("{{pTop}}", "padding-top: " + currentElement['padding_top']);

    if(currentElement['padding_top'] && currentElement['padding_top'] !== null && currentElement['measurement_padding_top'] && currentElement['measurement_padding_top'] !== null)
      code = code.replaceAll("{{mExternalTop}}", "margin-top: " + currentElement['padding_top'] + currentElement['measurement_padding_top']);
      
    if (currentElement['measurement_padding_top'] !== null) {
      code = code.replaceAll("{{pTopMeasurement}}", currentElement['measurement_padding_top']);
    }

    if (currentElement['padding_right'] !== null)
      code = code.replaceAll("{{pRight}}", "padding-right: " + currentElement['padding_right']);
    if (currentElement['measurement_padding_right'] !== null) {
      code = code.replaceAll("{{pRightMeasurement}}", currentElement['measurement_padding_right']);
    }
    if (currentElement['padding_bottom'] !== null)
      code = code.replaceAll("{{pBottom}}", "padding-bottom: " + currentElement['padding_bottom']);
    if (currentElement['measurement_padding_bottom'] !== null) {
      code = code.replaceAll("{{pBottomMeasurement}}", currentElement['measurement_padding_bottom']);
    }
    if (currentElement['padding_left'] !== null)
      code = code.replaceAll("{{pLeft}}", "padding-left: " + currentElement['padding_left']);
    if (currentElement['measurement_padding_left'] !== null) {
      code = code.replaceAll("{{pLeftMeasurement}}", currentElement['measurement_padding_left']);
    }

    if (currentElement['margin_top'] !== null)
      code = code.replaceAll("{{mTop}}", "margin-top: " + currentElement['margin_top']);
    if (currentElement['measurement_margin_top'] !== null) {
      code = code.replaceAll("{{mTopMeasurement}}", currentElement['measurement_margin_top']);
    }
    if (currentElement['margin_right'] !== null)
      code = code.replaceAll("{{mRight}}", currentElement['margin_right']);
    if (currentElement['measurement_margin_right'] !== null) {
      code = code.replaceAll("{{mRightMeasurement}}", currentElement['measurement_margin_right']);
    }
    if (currentElement['margin_bottom'] !== null)
      code = code.replaceAll("{{mBottom}}", "margin-bottom: " + currentElement['margin_bottom']);
    if (currentElement['measurement_margin_bottom'] !== null) {
      code = code.replaceAll("{{mBottomMeasurement}}", currentElement['measurement_margin_bottom']);
    }
    if (currentElement['margin_left'] !== null)
      code = code.replaceAll("{{mLeft}}", currentElement['margin_left']);
    if (currentElement['measurement_margin_left'] !== null) {
      code = code.replaceAll("{{mLeftMeasurement}}", currentElement['measurement_margin_left']);
    }

    if (currentElement['width'] !== null)
      code = code.replaceAll("{{width}}", "width: " + currentElement['width']);
    if (currentElement['measurement_width'] !== null) {
      code = code.replaceAll("{{widthMeasurement}}", currentElement['measurement_width']);
    }
    if (currentElement['height'] !== null)
      code = code.replaceAll("{{height}}", "height: " + currentElement['height']);
    if (currentElement['measurement_height'] !== null) {
      code = code.replaceAll("{{heightMeasurement}}", currentElement['measurement_height']);
    }

    if (currentElement['heading'] !== null) code = code.replaceAll("{{heading}}", currentElement['heading']);

    if (currentElement['style'] !== null) code = code.replaceAll("{{style}}", currentElement['style']);
    if (currentElement['class'] !== null) code = code.replaceAll("{{class}}", currentElement['class']);

    if(currentElement['id_parent'] == null) console.log(code, "cooooooode");
    currentElement["html_result"] = code;
    if(currentElement["emails_elements_idioma"]) {
      for(let i in currentElement["emails_elements_idioma"]) {
        if(currentElement["emails_elements_idioma"][i]["id_idioma"] == this.currentLanguage) {
          currentElement["emails_elements_idioma"][i]["html_result"] = code;
        }
      }
    }
  }

  public modifyElement(field) {
    let code;
    if (field !== "columns") code = this.arrayElementsTipo[this.currentElement["id_tipo"] - 1]["code"];

    if (code !== null) this.substituteTagsHTML(code);
    this.updateLanguages(code, field);

    if (this.currentRecordIndex !== this.arrayElementsPlantillaRecord.length - 1) {
      this.arrayElementsPlantillaRecord.length = this.currentRecordIndex;
    }
    this.currentRecordIndex = this.arrayElementsPlantillaRecord.push(JSON.parse(JSON.stringify(this.arrayElementsPlantilla))) - 1;
  }

  public updateLanguages(code, field) {
    if (field == "text" || field == "url") {
      console.log(code, this.currentElement, "this.currentElement");
      let changed = false;
      if (this.currentElement["emails_elements_idioma"] != undefined) {
        for (let key in this.currentElement["emails_elements_idioma"]) {
          if (this.currentElement["emails_elements_idioma"][key]["id_idioma"] == this.currentLanguage) {
            this.currentElement["emails_elements_idioma"][key][field] = this.currentElement[field];
            if(field == 'html_result') this.currentElement["emails_elements_idioma"][key][field] = code;
            if (this.currentElement["emails_elements_idioma"][key]["mode_type"] == "insert") {
              this.currentElement["emails_elements_idioma"][key]["mode_type"] = "insert";
            } else {
              this.currentElement["emails_elements_idioma"][key]["mode_type"] = "update";
            }
            changed = true;
          }
        }
      }
      if (!changed) {
        let url = "";
        let text = "";
        if (field == "url") url = this.currentElement['url'];
        else text = this.currentElement['text'];
        let languageData = {
          id_element: this.currentElement["id"],
          id_idioma: this.currentLanguage,
          url: url,
          text: text,
          html_result: code,
          mode_type: "insert",
        };
        if (this.currentElement["emails_elements_idioma"] != undefined) {
          this.currentElement["emails_elements_idioma"] = [languageData];
        }
      }
    }
    console.log(this.arrayElementsPlantilla, "arrayElementsPlantilla");
  }

  setCopiedElement(tipo, value, orden, idParent) {
    console.log(tipo, value, orden, idParent, "Set copy element");
    let push = this.addCopiedElement(tipo, orden, idParent);
    return push;
  }

  addCopiedElement(tipo, orden, idParent) {
    console.log(tipo, orden, idParent, "Copied Element add");
    ++this.tmpId;
    return {
      id_parent: idParent,
      id_plantilla: this.idPlantilla,
      id_tipo: tipo?.id_tipo,
      element_info: tipo?.element_info,
      borrado: 0,
      orden: orden,
      url: tipo?.url,
      text_align: tipo?.text_align,
      text: tipo?.text,
      bold: tipo?.bold,
      italic: tipo?.italic,
      underlined: tipo?.underlined,
      children: tipo?.children,
      font_size: tipo?.font_size,
      font_weight: tipo?.font_weight,
      font_family: tipo?.font_family ? tipo?.font_family : 'Montserrat, sans-serif',
      color: tipo?.color,
      background_color: tipo?.background_color,
      border_color: tipo?.border_color,
      border_radius: tipo?.border_radius,
      border_size: tipo?.border_size,
      border_style: tipo?.border_style,
      measurement_border: tipo?.measurement_border ? tipo?.measurement_border : "px",
      padding_top: tipo?.padding_top,
      measurement_padding_top: tipo?.measurement_padding_top ? tipo?.measurement_padding_top : "px",
      padding_right: tipo?.padding_right,
      measurement_padding_right: tipo?.measurement_padding_right ? tipo?.measurement_padding_right : "px",
      padding_bottom: tipo?.padding_bottom,
      measurement_padding_bottom: tipo?.measurement_padding_bottom ? tipo?.measurement_padding_bottom : "px",
      padding_left: tipo?.padding_left,
      measurement_padding_left: tipo?.measurement_padding_left ? tipo?.measurement_padding_left : "px",
      margin_top: tipo?.margin_top,
      measurement_margin_top: tipo?.measurement_margin_top ? tipo?.measurement_margin_top : "px",
      margin_right: tipo?.margin_right,
      measurement_margin_right: tipo?.measurement_margin_right ? tipo?.measurement_margin_right : "px",
      margin_bottom: tipo?.margin_bottom,
      measurement_margin_bottom: tipo?.measurement_margin_bottom ? tipo?.measurement_margin_bottom : "px",
      margin_left: tipo?.margin_left,
      measurement_margin_left: tipo?.measurement_margin_left ? tipo?.measurement_margin_left : "px",
      height: tipo?.height,
      measurement_height: tipo?.measurement_height ? tipo?.measurement_height : "px",
      width: tipo?.width,
      measurement_width: tipo?.measurement_width ? tipo?.measurement_width : "px",
      columns: tipo?.columns,
      heading: tipo?.heading,
      idTmp: this.tmpId,
      idTmpParent: idParent,
      date: tipo?.date,
      time: tipo?.time,
      style: tipo?.style ? tipo?.style : "",
      class: tipo?.class ? tipo?.class : "",
      emails_elements_idioma: tipo?.emails_elements_idioma
    };
  }

  setDefaultValues(tipo, value, orden, idParent, columnsType = null, firstElement = false) {
    // Element push, all set to NULL
    let push = this.addDefaultElement(tipo, orden, idParent);

    if (value == "button") {
      push.text_align = "center";
      push.font_size = 11;
      push.font_weight = "900";
      push.text = "BUTTON";
      push.url = "https://movin.cloud/";
      push.border_radius = 3;
      push.color = "#fddd00";
      push.background_color = "#000000";
      push.padding_right = 50;
      push.padding_left = 50;
      push.padding_bottom = 15;
      push.padding_top = 15;
    } else if (value == "text") {
      push.text_align = "left";
      push.font_size = 12;
      push.font_weight = "500";
      push.text = "Sample Text";
      push.color = "#000000";
      push.padding_right = 5;
      push.padding_bottom = 5;
      push.padding_left = 5;
      push.padding_top = 5;
      push.height = 20;
    } else if (value == "grid") {
      push.columns = columnsType;
      if (firstElement == false) {
        push.children.push(this.addDefaultElement(tipo, 1, push.idTmp));
        if (columnsType.length > 3)
          push.children.push(this.addDefaultElement(tipo, 2, push.idTmp));
        if (columnsType.length > 5)
          push.children.push(this.addDefaultElement(tipo, 3, push.idTmp));
      }
    } else if (value == "image") {
      push.text_align = "center";
      push.border_radius = 5;
      push.height = 200;
      push.url = "https://movin.cloud/assets/img/no-image.png";
      push.border_size = 1;
    } else if (value == "html") {
    } else if (value == "heading") {
      push.text_align = "center";
      push.text = "TÍTULO";
      push.padding_right = 5;
      push.padding_bottom = 5;
      push.padding_left = 5;
      push.padding_top = 5;
      push.heading = 1;
      push.font_size = 25;
      push.font_weight = "700";
    } else if (value == "divider") {
      push.margin_top = 10;
      push.margin_bottom = 10;
      push.width = 100;
      push.height = 20;
      push.border_size = 1;
    } else if (value == "spacer") {
      push.height = 20;
    } else if (value == "video") {
      push.url = "";
    }
    if(push.text || push.url) push.emails_elements_idioma = [{id_element: null, id_idioma: this.currentLanguage, text: push.text, url: push.url, html_result: null}];
    return push;
  }

  addDefaultElementForReuse(tipo, value, idParent) {
    console.log(tipo[0], "Tipo in service");
    ++this.tmpId;
    return {
      id_parent: idParent,
      id_plantilla: this.idPlantilla,
      id_tipo: tipo[0]?.id_tipo,
      element_info: { nombre: value, code: tipo[0]["html_result"] },
      borrado: 0,
      orden: this.orden,
      url: tipo[0]?.url,
      text_align: tipo[0]?.text_align,
      text: tipo[0]?.text,
      bold: tipo[0]?.bold,
      italic: tipo[0]?.italic,
      underlined: tipo[0]?.underlined,
      children: tipo[0]?.children,
      font_size: tipo[0]?.font_size,
      font_weight: tipo[0]?.font_weight,
      font_family: tipo[0]?.font_family ? tipo[0]?.font_family : 'Montserrat, sans-serif',
      color: tipo[0]?.color,
      background_color: tipo[0]?.background_color,
      border_color: tipo[0]?.border_color,
      border_radius: tipo[0]?.border_radius,
      border_size: tipo[0]?.border_size,
      border_style: tipo[0]?.border_style,
      measurement_border: tipo[0]?.measurement_border ? tipo[0]?.measurement_border : "px",
      padding_top: tipo[0]?.padding_top,
      measurement_padding_top: tipo[0]?.measurement_padding_top ? tipo[0]?.measurement_padding_top : "px",
      padding_right: tipo[0]?.padding_right,
      measurement_padding_right: tipo[0]?.measurement_padding_right ? tipo[0]?.measurement_padding_right : "px",
      padding_bottom: tipo[0]?.padding_bottom,
      measurement_padding_bottom: tipo[0]?.measurement_padding_bottom ? tipo[0]?.measurement_padding_bottom : "px",
      padding_left: tipo[0]?.padding_left,
      measurement_padding_left: tipo[0]?.measurement_padding_left ? tipo[0]?.measurement_padding_left : "px",
      margin_top: tipo[0]?.margin_top,
      measurement_margin_top: tipo[0]?.measurement_margin_top ? tipo[0]?.measurement_margin_top : "px",
      margin_right: tipo[0]?.margin_right,
      measurement_margin_right: tipo[0]?.measurement_margin_right ? tipo[0]?.measurement_margin_right : "px",
      margin_bottom: tipo[0]?.margin_bottom,
      measurement_margin_bottom: tipo[0]?.measurement_margin_bottom ? tipo[0]?.measurement_margin_bottom : "px",
      margin_left: tipo[0]?.margin_left,
      measurement_margin_left: tipo[0]?.measurement_margin_left ? tipo[0]?.measurement_margin_left : "px",
      height: tipo[0]?.height,
      measurement_height: tipo[0]?.measurement_height ? tipo[0]?.measurement_height : "px",
      width: tipo[0]?.width,
      measurement_width: tipo[0]?.measurement_width ? tipo[0]?.measurement_width : "px",
      columns: tipo[0]?.columns,
      heading: tipo[0]?.heading,
      idTmp: this.tmpId,
      idTmpParent: idParent,
      date: tipo[0]?.date,
      time: tipo[0]?.time,
      style: tipo[0]?.style ? tipo[0]?.style : "",
      class: tipo[0]?.class ? tipo[0]?.class : "",
      plantillas_emails_elements_idioma:
        tipo[0]?.plantillas_emails_elements_idioma,
    };
  }

  addDefaultElement(tipo, orden, idParent) {
    ++this.tmpId;
    return {
      id_parent: null,
      id_plantilla: this.idPlantilla,
      id_tipo: tipo["id"],
      element_info: { nombre: tipo["nombre"], code: tipo["code"] },
      borrado: 0,
      orden: orden,
      url: null,
      text_align: "left",
      text: null,
      bold: null,
      italic: null,
      underlined: null,
      children: [],
      font_size: null,
      font_weight: "400",
      font_family: 'Montserrat, sans-serif',
      color: null,
      background_color: "#fff",
      border_color: "#000000",
      border_radius: 0,
      border_size: 0,
      border_style: "solid",
      measurement_border: "px",
      padding_top: 0,
      measurement_padding_top: "px",
      padding_right: 0,
      measurement_padding_right: "px",
      padding_bottom: 0,
      measurement_padding_bottom: "px",
      padding_left: 0,
      measurement_padding_left: "px",
      margin_top: 0,
      measurement_margin_top: "px",
      margin_right: 0,
      measurement_margin_right: "px",
      margin_bottom: 0,
      measurement_margin_bottom: "px",
      margin_left: 0,
      measurement_margin_left: "px",
      height: null,
      measurement_height: "px",
      width: null,
      measurement_width: "px",
      columns: 100,
      heading: 1,
      idTmp: this.tmpId,
      idTmpParent: idParent,
      date: null,
      time: null,
      emails_elements_idioma: [],
      style: "",
      class: ""
    };
  }

  addElementIdsTMP() {
    this.addElementIdsTMPLoop(this.arrayElementsPlantilla);
  }

  addElementIdsTMPLoop(array, idParent = 0) {
    for (let i in array) {
      array[i]["idTmp"] = this.tmpId;
      ++this.tmpId;
      array[i]["idTmpParent"] = idParent;
      if (array[i]["children"] && array[i]["children"].length > 0)
        this.addElementIdsTMPLoop(array[i]["children"], array[i]["idTmp"]);
    }
  }

  pushElement(push, idParent) {
    let founded = false;
    this.pushElementLoop(this.arrayElementsPlantilla, push, idParent, founded);
  }

  pushElementLoop(array, push, idParent, founded) {
    if (founded) return;
    for (let i in array) {
      if (array[i]["idTmp"] == idParent) {
        if (array[i]["children"] && isArray(array[i]["children"]))
          array[i]["children"].push(push);
        else array[i]["children"][0] = push;
        founded = true;
      } else if (array[i]["children"] && array[i]["children"].length > 0)
        this.pushElementLoop(array[i]["children"], push, idParent, founded);
    }
  }

  orderChange() {
    this.orderChangeTree(this.arrayElementsPlantilla);
  }
  
  orderChangeTree(arrayElements) {
    for (let i in arrayElements) {
      arrayElements[i]["orden"] = +i;
      if(arrayElements[i]["children"] && arrayElements[i]["children"].length && arrayElements[i]["children"].length > 0) this.orderChangeTree(arrayElements[i]["children"]);
    }
  }

  addElement(element, tipo, first, copy, idParent, arrayActualLevel, reusedElement = false, reusedElementBuccle = false) {
    let value = tipo.nombre;

    if(tipo.id == 12) {
      this.idParentElementReuseSelected = idParent;
      this.templateElementsArrayElementReuseSelected = arrayActualLevel;
      this.oldElementReuseSelected = element;
      console.log(this.oldElementReuseSelected);
      this.selectReuse = true;
      this.showResult = false;
      this.saveReuse = false;
      this.currentElement = undefined;
      return;
    }
  
    let currentIndex, orden, code, push;
    orden = element.orden;
    if (!first) {
      currentIndex = arrayActualLevel.indexOf(element) + 1;
      ++orden;
    }

    for (let tipo of this.arrayElementsTipo) {
      if (tipo["nombre"] == value) {
        this.valueEmittedFromChild = tipo["id"];
        if ((tipo["id"] == 3 || tipo["id"] == 11) && !copy) {
          this.showGridTypes = 1;
          this.orderGrid = orden;
          this.currentTemplateElementsArray = arrayActualLevel;
          this.indexToInsertGrid = currentIndex;
          this.currentTmpParent = idParent;
          return;
        }

        if(!copy) {
          push = this.setDefaultValues(tipo, value, orden, idParent);
          code = tipo["code"];
        } else {      
          code = false;
          ++this.tmpId;
          if(!reusedElement) push =  _.cloneDeep(this.arrayCopyElement);
          else {
            if(reusedElementBuccle) {
              push = _.cloneDeep(this.selectedElementToReuse);
            } else {
              let selectedElementToReuseArrat = _.cloneDeep(this.selectedElementToReuse);
              selectedElementToReuseArrat = selectedElementToReuseArrat.reverse()
              for(let i in selectedElementToReuseArrat) {
                this.selectedElementToReuse = selectedElementToReuseArrat[i];
                this.addElement(element, tipo, first, copy, idParent, arrayActualLevel, reusedElement, true)
              }
            }
          }
          push['idTmp'] = this.tmpId;
          push['id'] = null;
          push['id_parent'] = null;
          push['id_plantilla'] = this.idPlantilla;
          push['idTmpParent'] = idParent;
          push['orden'] = orden;
          if(push['emails_elements_idioma']) {
            for(let lang in push['emails_elements_idioma']) {
              push['emails_elements_idioma'][lang]['id_element'] = null;
            }
          }
        }
        console.log(push, "pushpush");

        this.changeTmpIdsChilds(push);
        arrayActualLevel.splice(currentIndex, 0, push);
        break;
      }
    }

    this.currentElement = push;

    if (code) this.substituteTagsHTML(code);
    
    if (this.currentRecordIndex !== this.arrayElementsPlantillaRecord.length - 1) {
      this.arrayElementsPlantillaRecord.length = this.currentRecordIndex;
    }
    this.currentRecordIndex = this.arrayElementsPlantillaRecord.push(JSON.parse(JSON.stringify(this.arrayElementsPlantilla))) - 1;
    
    this.orderChange();
    console.log(this.arrayElementsPlantilla,"arrayElementsPlantilla");
    this.selectReuse = false;
  }

  public changeTmpIdsChilds(element) {
    if(element['children'] && element['children'].length && element['children'].length > 0) {
      for(let i in element['children']) {
        ++this.tmpId;
        element['children'][i]['idTmp'] = this.tmpId;
        element['children'][i]['id'] = null;
        element['children'][i]['id_parent'] = null;
        element['children'][i]['id_plantilla'] = this.idPlantilla;
        element['children'][i]['idTmpParent'] = element['idTmp'];
        if(element['children'][i]['emails_elements_idioma']) {
          for(let lang in element['children'][i]['emails_elements_idioma']) {
            element['children'][i]['emails_elements_idioma'][lang]['id_element'] = null;
          }
        }
        this.changeTmpIdsChilds(element['children'][i]);
      }
    }
  }


  generateHTMLResult() {
    for(let i in this.arrayElementsPlantillaSubject) {
      this.generateHTMLSingleLanguage(this.arrayElementsPlantillaSubject[i]['id_idioma']);
      this.arrayElementsPlantillaSubject[i]['html_result'] = this.htmlResult;
      this.htmlResult = "";
    }
    if(this.plantillaInfo['id_tipo'] != 4) this.showResult = true;
    console.log(this.arrayElementsPlantillaSubject);

  }
  
  generateHTMLSingleLanguage(idIdioma) {
    let header = '<!DOCTYPE html PUBLIC http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office"><head><!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]--><title>GT360</title><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/><meta http-equiv="X-UA-Compatible" content="IE=edge"/><meta name="viewport" content="width=device-width, initial-scale=1.0 "/><meta name="format-detection" content="telephone=no"/><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,700,700i,900,900i" rel="stylesheet"/><!--<![endif]--><style type="text/css">body {margin: 0;padding: 0;-webkit-text-size-adjust: 100% !important;-ms-text-size-adjust: 100% !important;-webkit-font-smoothing: antialiased !important;}.w100 {Width: 100%;}.w50 {Width: 50%;}.w25 {Width: 25%;}.w70 {Width: 70%;}.w30 {Width: 30%;}.w33 {Width: 33.33%;}table {border-collapse: collapse;mso-table-lspace: 0px;mso-table-rspace: 0px;}td, a, span {border-collapse: collapse;mso-line-height-rule: exactly;}@media onlyscreen (min-width: 549px) and (max-width: 549px) {.w100,.w50,.w25,.w70,.w30,.w33 {width: 100%;}} {{class_code}}</style></head><body class="em_body" style="margin:0px auto; padding:0px;" bgcolor="#ffffff">';
    this.htmlResult = header + '<table width="100%" cellpadding="0" cellspacing="0"><tbody><tr style="width: 100%;">';
    this.htmlResult = this.htmlResult.replace("{{class_code}}", this.plantillaInfo['class_codes']);
    this.generateHTMLTree(this.arrayElementsPlantilla[0]["children"], this.arrayElementsPlantilla[0]['columns'], idIdioma);
    this.htmlResult += '</tr></tbody></table></body></html>';
  }

  generateHTMLTree(array, columnsType, idIdioma) {
    for(let i in array) {

      let html_result = array[i]['html_result'];
      if(array[i]['emails_elements_idioma'] && array[i]['emails_elements_idioma'].length && array[i]['emails_elements_idioma'].length > 0) {
        for(let j in array[i]['emails_elements_idioma']) {
          if(idIdioma == array[i]['emails_elements_idioma'][j]['id_idioma']) {
            html_result = array[i]['emails_elements_idioma'][j]['html_result'];
            break;
          }
        }
      }

      if(columnsType && columnsType != 100) {
        html_result = '<div class="w100">';
        let newWidth = "100";
        if(columnsType == "5050") {
          newWidth = "50";
        } else if(columnsType == "255025") {
          if(i == "0" || i == "2") newWidth = "25";
          else newWidth = "50";
        } else if(columnsType == "3070") {
          if(i == "0") newWidth = "30";
          else newWidth = "70";
        } else if(columnsType == "7030") {
          if(i == "0") newWidth = "70";
          else newWidth = "30";
        } else if(columnsType == "333333") {
          newWidth = "33";
        }
        html_result = html_result.replaceAll("w100", "w" + newWidth);
      }

      this.htmlResult += html_result;
      if(array[i]['children'] && array[i]['children'].length && array[i]['children'].length > 0) this.generateHTMLTree(array[i]['children'], array[i]['columns'], idIdioma);
      if(array[i]['id_tipo'] == 3) this.htmlResult += '</div>';
    }
  }
}
