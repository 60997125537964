<div *ngIf="moduloId === 0">
  <p>Configuración del módulo 0</p>
</div>
<div *ngIf="moduloId === 1">
  <p>Configuración del módulo 1</p>
</div>
<div *ngIf="moduloId === 2">
  <p>Configuración del módulo 2</p>
</div>
<div *ngIf="moduloId === 3">
  <p>Configuración del módulo 3</p>
</div>
<div *ngIf="moduloId === 4">
  <p>Configuración del módulo 4</p>
</div>
<div *ngIf="moduloId === 5">
  <p>Configuración del módulo 5</p>
</div>
<div *ngIf="moduloId === 6">
  <p>Configuración del módulo 6</p>
</div>
<div *ngIf="moduloId === 7">
  <p>Configuración del módulo 7</p>
</div>
<div *ngIf="moduloId === 8">
  <p>Configuración del módulo 8</p>
</div>
<div *ngIf="moduloId === 9">
  <p>Configuración del módulo 9</p>
</div>
