<div fxLayout="row wrap" [ngClass]="{'previewMode' : isPreview}" style="padding: 3vh 3vw;">
  <div fxFlex="100" fxLayout="row wrap" style="align-items: end; margin-bottom: 13px">
    <span fxLayout="row wrap" fxFlex="50" fxFlex.xs="100">
      <div fxFlex="100" fxLayout="row" class="options-generals-custom">
        <div class="generic-buttons-red">
          <button mat-raised-button (click)="deleteElementGrid()">
            <mat-icon class="material-icons">refresh</mat-icon>Borrar todo
          </button>
        </div>
        <div class="generic-buttons-blue" *ngIf="genericCreatorService.currentRecordIndex > 0">
          <button mat-raised-button (click)="redoUndo('undo')">
            <mat-icon class="material-icons">undo</mat-icon>
          </button>
        </div>
        <div class="generic-buttons-blue"
          *ngIf="genericCreatorService.arrayElementsGenericCreatorRecord.length > genericCreatorService.currentRecordIndex + 1">
          <button mat-raised-button (click)="redoUndo('redo')">
            <mat-icon class="material-icons">redo</mat-icon>
          </button>
        </div>
        <div class="generic-buttons-blue">
          <button style="height: 31px;" mat-raised-button (click)="genericCreatorService.updateStructure()">
            Save
          </button>
        </div>
        <!-- <div
          *ngIf="filterArrayTemplateElements()"
          class="generic-buttons-black"
        >
          <button
            mat-raised-button
            (click)="
              orderAllElements(this.templateService.arrayElementsPlantilla[0])
            "
          >
            <mat-icon class="material-icons">reorder</mat-icon>
          </button>
        </div> -->
      </div>
      <mat-checkbox [(ngModel)]="isPreview" fxFlex="100" class="check-preview">
        Vista previa
      </mat-checkbox>
    </span>
    <mat-form-field fxFlex="50" fxFlex.xs="100" appearance="outline">
      <mat-label>Idioma</mat-label>
      <mat-select (selectionChange)="genericCreatorService.selectLanguage(language.value)" matNativeControl #language
        [(ngModel)]="genericCreatorService.currentLanguage">
        <mat-option *ngFor="let language of genericCreatorService.languages" [value]="language.id_language">
          {{ language.internal_name }}
        </mat-option>
      </mat-select>
      <mat-hint>Selecciona diferentes idiomas para traducir la pantalla</mat-hint>
    </mat-form-field>
  </div>



  <div class="firstHalf">
    <div style="flex-direction: column;">
      <ng-container *ngIf="genericCreatorService.expandedFloatingElement">
        <div fxLayout="row wrap" fxFlex="100" style="padding: 0 15px 15px 15px;">
          <div fxFlex="50">
            <div>You are editing a floating element</div>
          </div>
          <div fxFlex="50">
            <button style="height: 31px;" mat-raised-button (click)="saveFloatingElement()">
              Finish editing actual Floating element
            </button>
          </div>
        </div>
        <div fxLayout="row wrap" fxFlex="100">
          <div fxFlex="100" class="mat-dialog-container"
            *ngIf="genericCreatorService.actualSelectedElement['id_functional_type'] == 85">
            <app-dialog style="width: 100%;"
              [structure]="genericCreatorService.arrayFloatingElements[genericCreatorService.expandedFloatingIndex]"
              [type]="1"
              [dialogId]="genericCreatorService.arrayFloatingElements[genericCreatorService.expandedFloatingIndex]['id_functional_parent_initial_dsb']">
            </app-dialog>
          </div>
          <div fxFlex="100" class="mat-dialog-container"
            *ngIf="genericCreatorService.actualSelectedElement['id_functional_type'] == 98">
            <app-bottom-sheet style="width: 100%;" class="component-class"
              [structure]="genericCreatorService.actualSelectedElement"></app-bottom-sheet>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="genericCreatorService.arrayElementsGenericCreator[0] === null || genericCreatorService.arrayElementsGenericCreator[0] == undefined || !loadedGenericStructure">
        <mat-progress-bar style="position: absolute !important; top: 0;"
          [ngClass]="{'loading-window':true, 'loading-window-top-0':this.genericService.staticHTML}"
          mode="indeterminate"></mat-progress-bar>
      </ng-container>
      <span
        *ngIf="genericCreatorService.arrayElementsGenericCreator[0] !== null && genericCreatorService.arrayElementsGenericCreator[0] != undefined && loadedGenericStructure && genericCreatorService.expandedFloatingElement == null">
        <app-generic style="width: 100%;" [structure]="genericCreatorService.arrayElementsGenericCreator[0]">
        </app-generic>
      </span>
    </div>
  </div>
  <div style="border: 1px solid #999; width: 35%; height: 90vh; overflow: auto;" class="optionTabsContainer">
    <mat-tab-group class="generic-tab-full-width-2" *ngIf="loadedGenericStructure"
      [(selectedIndex)]="genericCreatorService.activeTabIndex">
      <mat-tab label="DETALLES">
        <app-generic-custom-creator-generaldetails></app-generic-custom-creator-generaldetails>
      </mat-tab>
      <mat-tab label="ELEMENTO"
        *ngIf="genericCreatorService.actualSelectedElement !== undefined && genericCreatorService.actualSelectedElement !== null && !genericCreatorService.expandedFloatingElement">
        <app-generic-custom-creator-options [actualElement]="genericCreatorService.actualSelectedElement">
        </app-generic-custom-creator-options>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>