<div>
  <div class="row-expansion2">

    <mat-form-field appearance="outline">
      <mat-label>¿Le ha gustado?</mat-label>
      <mat-select [(ngModel)]="event.signLike">
        <mat-option [value]="1">Sí</mat-option>
        <mat-option [value]="0">No</mat-option>
        <mat-option [value]="2">Indiferente</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>¿Quiere recibir alertas?</mat-label>
      <mat-select [(ngModel)]="event.signNotify">
        <mat-option [value]="1">Sí</mat-option>
        <mat-option [value]="0">No</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="row-expansion1">
    <mat-form-field appearance="outline">
      <mat-label>DNI</mat-label>
      <input matInput type="text" [(ngModel)]="event.signDNI">
    </mat-form-field>
  </div>

  <div class="row-expansion1">
    <mat-form-field appearance="outline">
      <mat-label>Observaciones </mat-label>
      <textarea matInput type="text" [(ngModel)]="event.signObservation"></textarea>
    </mat-form-field>
  
  </div>


  <div class="row-expansion1">
    <label>Firma del cliente</label>
  </div>
  
  <div class="row-expansion1">
    <div style="border-style: solid">
      <canvas #sPad width="500" height="250" style="touch-action: none;"></canvas>
      <mat-icon (click)="onCleanSign()" class="removeit">delete</mat-icon>
    </div>
  </div>
  

</div>

<div class="dialog-footer-3">
  <button type="button" (click)="onNoClick()">Cancelar</button>
  <button type="button" (click)="onClickAcceptSignActivity()">Aceptar</button>
</div>
