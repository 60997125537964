import { Injectable } from '@angular/core';

// Serivce
import { RoutingService } from "../routing/routing.service";
import { GenericService } from "../generic/generic.service";
import { ClientsFunctionsService } from './clients-functions.service';
import { EndpointService} from "../endpoint/endpoint.service";

// Material
import {MatDialog} from "@angular/material/dialog";

// Component
import {DialogComponent} from "../../generic/components/dialog/dialog.component";
import { MessageDialogComponent } from 'app/utils/message-dialog/message-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource} from '@angular/material/table';

//import { map, startWith } from 'rxjs/operators';
//import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class PortalFunctionsService {

  public dialogRef = null;

  constructor(
    private routingService : RoutingService,
    public dialog : MatDialog,
    public clientsFunctionsService: ClientsFunctionsService,
    private endpointService: EndpointService,
    private genericService: GenericService,
    public snackBar: MatSnackBar) { }

  getFunction(structure, param = null) {

    console.log(structure);
    console.log(param);

    let id = structure['id_function'];
    let route = structure['internal_routing'];
    /*
    if (param != null) {
      if ("id" in param) {
        this.saveIdInLocalStorage(param["id"]);
      }
    }
     */

    switch (id) {
            case 230:
                return (JSON.stringify(param.inmoPortal) !== JSON.stringify(param.inmoPortalInicial));

            case 231:
                param.that.dialogRef.close(param.that.inmoPortal);
                break;

            case 232:
                param.that.dialogRef.close();
                break;

    }
  }

  goRouting(route) {
    this.routingService.go(route);
  }

  openDialog(structure){
    console.log('open...');

    const child = structure['child'] && structure['child'][Object.keys(structure['child'])[0]];

    if (child) {

      const child = Object.keys(structure['child'])[0];
      let panelClass = [structure['child'][child]['class']]
      if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
      this.dialogRef = this.dialog.open(DialogComponent,{
        width: 'auto',
        height: 'auto',
        panelClass: panelClass,
        data: {
          structure: structure['child'],
          key: child
        }
      });

      this.dialogRef.afterClosed().subscribe( data=>{
        console.log('close...');
        console.log(data);
      });
    }
  }

  saveIdInLocalStorage(id : any) {
    localStorage.setItem("movin1", id);
  }

  private openSnackBar(msg, duration, panelClass = ['lightgreen-snackbar']){

    this.snackBar.open(msg, 'X', {
      duration: duration,
      panelClass: panelClass
    });
  }
}
