import {Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked, OnDestroy} from '@angular/core';
import { EndpointService } from "../../../services/endpoint/endpoint.service";
import { RoutingService } from "../../../services/routing/routing.service";
import { GenericService } from 'app/services/generic/generic.service';
import { Location } from '@angular/common';
import { AuthService } from 'app/services/auth/auth.service';
import { FunctionsService } from 'app/services/functions/functions.service';

@Component({
  selector: 'app-expansion',
  templateUrl: './expansion.component.html',
  styleUrls: ['./expansion.component.css']
})
export class ExpansionComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() structure = null;

  public child: any;
  public array: any;
  public ids: any;
  private monitoreo;
  public loaded = false;
  public openedexpansion = 0;

  constructor(private endPointService: EndpointService,
    private routingService: RoutingService,
    private changeDetector: ChangeDetectorRef,
    public genericService: GenericService,
    private location: Location,
    private authService: AuthService,
    private functionsService: FunctionsService) { }

  ngOnInit(): void {
    if(this.structure['id_functional_parent_initial_dsb'] == 0) {
      if (this.routingService.urlHashtag != null) {
        const id = this.routingService.urlHashtag;
        for (const key in this.structure) {
          if (this.structure[key]['id_functional_area'] == id) {
            this.closeAllExpansions();
            this.structure[key]['expansion_start_opened'] = 1;
            this.structure[key]['expansionActiveNow'] = true;
            this.openedexpansion = +key;
            this.location.replaceState(this.routingService.urlWithoutHashtag + '#' + this.structure[key]['id_functional_area']);
            break;
          }
          const element = this.genericService.findElementWithIdLoop(this.structure[key], id);
          if (element) {
            this.closeAllExpansions();
            this.structure[key]['expansion_start_opened'] = 1;
            this.structure[key]['expansionActiveNow'] = true;
            this.openedexpansion = +key;
            this.location.replaceState(this.routingService.urlWithoutHashtag + '#' + id);
            break;
          }
        }
      }
    }
    this.endPointService.insertMonitoringInformation(
      this.authService.getLoggedInUserId(), this.structure[this.openedexpansion]['id_functional_area'], this.structure[this.openedexpansion]['id_functional_area'], this.genericService.staticHTML).subscribe(data => {
      this.monitoreo = data['response'];
    });
    this.loaded = true;
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
      this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }

  setOpened(index: number) {
    //if(this.structure[0]['id_functional_parent_initial_dsb'] == 0) {
      for (const i in this.structure) {
        if(index == +i) {
          this.endPointService.insertMonitoringInformation(
            this.authService.getLoggedInUserId(), this.structure[i]['id_functional_area'], this.structure[i]['id_functional_area'], this.genericService.staticHTML).subscribe(data => {
            this.monitoreo = data['response'];
          });

          const url = this.routingService.urlWithoutHashtag;
          this.structure[i]['expansion_start_opened'] = 1;
          this.structure[i]['expansionActiveNow'] = true;
          this.location.replaceState(url + '#' + this.structure[i]['id_functional_area']);
        } else {
          this.structure[i]['expansion_start_opened'] = 0;
          this.structure[i]['expansionActiveNow'] = false;
        }
      }
    //}
  }

  setClosed(index: number) {
    for (const i in this.structure) {
      if(index == +i) {
        this.structure[i]['expansion_start_opened'] = 0;
        this.structure[i]['expansionActiveNow'] = false;

        if(this.structure[i]['monitoreo'] != 0) {
          this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
          });
        }
      }
    }
  }

  closeAllExpansions() {
    for (const i in this.structure) {
      this.structure[i]['expansion_start_opened'] = 0;
      this.structure[i]['expansionActiveNow'] = false;
    }
  }

  iconClick(id) {
    this.functionsService.getFunction(this.structure[id['key']]);
  }
}
