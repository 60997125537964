import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {EndpointService} from '../../services/endpoint/endpoint.service';
import {FileHolder} from 'angular2-image-upload';

@Component({
  selector: 'bottom-sheet-upload-image',
  templateUrl: 'bottom-sheet-upload-image.html',
})
export class BottomSheetUploadImage{

  public backendRequest: string;
  public imageName: string;

  public imgURL: string;
  public imagePath: FileList;
  public file: File;
  public formData: FormData;
  public codigo_video = new Array();
  public url = '';

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetUploadImage>,
              private endpointService: EndpointService,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.backendRequest = data.backendRequest;

  }

  ngOnInit(){

  }

  onUploadFinished(file: FileHolder) {
    this.imageName = file.file.name;
  }

  closeBottomSheet(buttonOption: number){
    this._bottomSheetRef.dismiss(buttonOption);
  }
}
