<ng-container *ngIf="actualElement !== undefined && actualElement !== null && this.actualElement['formCreator'] !== undefined">
    <mat-accordion>
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 1" (opened)="genericCreatorService.expandedIndex = 1" (closed)="genericCreatorService.checkIndexClose(1)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Main Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Functional type</mat-label>
                        <input type="text"
                                placeholder="Functional type"
                                aria-label="internal_name"
                                matInput
                                formControlName="id_functional_type"
                                [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let item of genericCreatorService.filteredOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div> -->
                <div class="fix-class-slide-toggle" *ngIf="actualElement.id_functional_type == 105 || actualElement.id_functional_type == 141">
                    <mat-slide-toggle [(ngModel)]="genericCreatorService.listElementOptionToggle" (change)="onToggle($event)" [ngModelOptions]="{standalone: true}"><span style="font-weight: 500; white-space: break-spaces;">¿Quieres añadir subelementos? En caso contrario podrás añadir texto.</span></mat-slide-toggle>
                </div>
                <div class="form-group" *ngIf="!genericCreatorService.actualSelectedSubElement || genericCreatorService.actualSelectedSubElement['form_field'] !== 1">
                    <mat-form-field appearance="outline">
                        <mat-label>Id functional type</mat-label>
                        <mat-select formControlName="id_functional_type" required disabled>
                            <mat-option *ngFor="let item of genericCreatorService.arrayElementsTipo" [value]="item.id_functional_type">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.actualSelectedSubElement?.['form_field'] === 1">
                    <mat-form-field appearance="outline">
                        <mat-label>Id functional type</mat-label>
                        <mat-select formControlName="id_functional_type" required>
                            <mat-option *ngFor="let item of genericCreatorService.filteredFunctionalTypes" [value]="item.id_functional_type">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('internal_name', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Internal name</mat-label>
                        <input matInput placeholder="Label" formControlName="internal_name" #internal_name autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{internal_name.value?.length || 0}}/1500</mat-hint>                        
                    </mat-form-field>
                </div>
                <!-- *ngIf="actualElement?.id_functional_type && genericCreatorService.functionalSubtypes?.length > 0" -->
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('type', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Subtipo</mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let item of genericCreatorService.functionalSubtypes" [value]="item.internal_name">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-option *ngFor="let item of genericCreatorService.functional_subtype" [value]="item.internal_name">{{item.internal_name}}</mat-option> -->
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('label', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Label</mat-label>
                        <textarea matInput
                            formControlName="label"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('text', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Text</mat-label>
                        <textarea matInput
                            formControlName="text"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('description', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput
                            formControlName="description"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('tooltip', actualElement['id_functional_type'])">
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Tooltip</mat-label>
                        <input matInput placeholder="Tooltip" formControlName="tooltip" #tooltip autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{tooltip.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field> -->
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Tooltip</mat-label>
                        <textarea matInput
                            formControlName="tooltip"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div> 
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('url', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Url</mat-label>
                        <input matInput placeholder="Url" formControlName="url" #url autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{url.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_functional_status_general', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="id_functional_status_general" required>
                            <mat-option *ngFor="let item of genericCreatorService.filteredFunctionalStatus" [value]="item.id_functional_status">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('class_custom', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Custom class</mat-label>
                        <input matInput placeholder="Label" formControlName="class_custom" #class_custom autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{class_custom.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('style', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Style</mat-label>
                        <input matInput placeholder="Label" formControlName="style" #style autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{style.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <!-- start for hide -->
                <ng-container *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('hide', actualElement['id_functional_type'])">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Hide</mat-label>
                            <mat-select formControlName="hide">
                                <mat-option *ngFor="let item of genericCreatorService.hideOptions" [value]="item.value">{{item.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="this.actualElement['formCreator'].controls['hide'].value === '2'">
                        <mat-checkbox class="p-r-5" formControlName="hide_xs">Hide xs</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_s">Hide s</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_m">Hide m</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_l">Hide l</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_xl">Hide xl</mat-checkbox>
                    </div>
                </ng-container>
                <!-- end for hide -->
                <!-- start for width -->
                <ng-container *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('width', actualElement['id_functional_type'])">
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Width</mat-label>
                            <input matInput type="number" placeholder="width" formControlName="width_general" autocomplete="off" min="0" max="100">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                    <mat-checkbox formControlName="isAutomaticResponsive">Automatic responsive</mat-checkbox>
                    <ng-container *ngIf="this.actualElement['formCreator'].controls['isAutomaticResponsive'].value == true">
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width xs</mat-label>
                                <input matInput type="number" placeholder="Width xs" formControlName="width_xs" autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width s</mat-label>
                                <input matInput type="number" placeholder="Width s" formControlName="width_s" autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width m</mat-label>
                                <input matInput type="number" placeholder="Width m" formControlName="width_m" autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width l</mat-label>
                                <input matInput type="number" placeholder="Width l" formControlName="width_l" autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width xl</mat-label>
                                <input matInput type="number" placeholder="Width xl" formControlName="width_xl" autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- end for width -->

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_product_mkt', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Id product mkt</mat-label>
                        <input type="text"
                                placeholder="Id product mkt"
                                aria-label="internal_name"
                                matInput
                                formControlName="id_product_mkt"
                                [matAutocomplete]="id_product_mktAuto">
                        <mat-autocomplete autoActiveFirstOption #id_product_mktAuto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let item of genericCreatorService.idProductMktOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_product_mkt_no', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Id product mkt no</mat-label>
                        <input type="text"
                                placeholder="Id product mkt no"
                                aria-label="internal_name"
                                matInput
                                formControlName="id_product_mkt_no"
                                [matAutocomplete]="id_product_mkt_noAuto">
                        <mat-autocomplete autoActiveFirstOption #id_product_mkt_noAuto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let item of genericCreatorService.idProductMktNoOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_condition', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Id condition</mat-label>
                        <input type="text"
                                placeholder="Id condition"
                                aria-label="internal_name"
                                matInput
                                formControlName="id_condition"
                                [matAutocomplete]="id_conditionAuto">
                        <mat-autocomplete autoActiveFirstOption #id_conditionAuto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let item of genericCreatorService.idConditionOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('condition_internal_routing', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Condition internal routing</mat-label>
                        <input type="text"
                                placeholder="Condition internal routing"
                                aria-label="internal_routing"
                                matInput
                                formControlName="condition_internal_routing"
                                [matAutocomplete]="condition_internal_routingAuto">
                        <mat-autocomplete autoActiveFirstOption #condition_internal_routingAuto="matAutocomplete" [displayWith]="displayRoutingFn">
                            <mat-option *ngFor="let item of genericCreatorService.conditionInternalRoutingOptions | async" [value]="item">{{item.internal_routing}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('condition_session_active', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Condition session active</mat-label>
                        <mat-select formControlName="condition_session_active">
                            <mat-option *ngFor="let item of genericCreatorService.condition_session_active" [value]="item.id_session_active">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('dev', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="dev">Dev</mat-checkbox>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('buttonType', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Button types</mat-label>
                        <mat-select formControlName="buttonType" (selectionChange)="genericCreatorService.setValueForClass($event, this.actualElement['formCreator'])">
                            <mat-option *ngFor="let item of genericCreatorService.class_custom" [value]="item.internal_name">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('bd_field', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>bd_field</mat-label>
                        <input matInput placeholder="bd_field" formControlName="bd_field" #bd_field autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{bd_field.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_table_relations', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>id_table_relations</mat-label>
                        <input matInput placeholder="id_table_relations" formControlName="id_table_relations" #id_table_relations autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{id_table_relations.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('min_rows', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Min rows</mat-label>
                        <input matInput placeholder="min_rows" formControlName="min_rows" #min_rows autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{min_rows.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('max_rows', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Max rows</mat-label>
                        <input matInput placeholder="max_rows" formControlName="max_rows" #max_rows autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{max_rows.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('max_lengths', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Max lengths</mat-label>
                        <input matInput placeholder="max_lengths" formControlName="max_lengths" #max_lengths autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{max_lengths.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                
                <!-- Start for Badge -->
                <ng-container *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('badge', actualElement['id_functional_type'])">
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Badge</mat-label>
                            <input matInput placeholder="Badge" formControlName="badge" #badge autocomplete="off" maxlength="1500">
                            <mat-hint align="end">{{badge.value?.length || 0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="this.actualElement['formCreator'].controls['icon'].value != null">
                        <mat-form-field appearance="outline">
                            <mat-label>Id condition badge</mat-label>
                            <input type="text"
                                    placeholder="Id condition badge"
                                    aria-label="internal_name"
                                    matInput
                                    formControlName="id_condition_badge"
                                    [matAutocomplete]="autoConditionBadge">
                            <mat-autocomplete autoActiveFirstOption #autoConditionBadge="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let item of genericCreatorService.idConditionBadgeOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- End for Badge -->
                <!-- Start for id_function -->
                <ng-container *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_function', actualElement['id_functional_type'])">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Id function</mat-label>
                            <input type="text"
                                    placeholder="Id function"
                                    aria-label="internal_name"
                                    matInput
                                    formControlName="id_function"
                                    [matAutocomplete]="autoIdFunction">
                            <mat-autocomplete autoActiveFirstOption #autoIdFunction="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let item of genericCreatorService.idFunctionOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Duplicate id functional area</mat-label>
                            <input matInput placeholder="Duplicate id functional area" formControlName="duplicate_id_functional_area" #duplicate_id_functional_area autocomplete="off" maxlength="1500">
                            <mat-hint align="end">{{duplicate_id_functional_area.value?.length || 0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Internal routing</mat-label>
                            <input matInput placeholder="Internal routing" formControlName="internal_routing" #internal_routing autocomplete="off" maxlength="1500">
                            <mat-hint align="end">{{internal_routing.value?.length || 0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Internal routing id functional area</mat-label>
                            <input matInput placeholder="Internal routing id functional area" formControlName="internal_routing_id_functional_area" #internal_routing_id_functional_area autocomplete="off" maxlength="1500">
                            <mat-hint align="end">{{internal_routing_id_functional_area.value?.length || 0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- End for id_function -->
                <!-- Start for icon -->
                <ng-container *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('icon', actualElement['id_functional_type'])">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Icon</mat-label>
                            <input matInput #iconInput formControlName="icon" maxlength="1500" placeholder="Icon">
                            <mat-hint align="start"><strong><a href="https://fonts.google.com/icons" target="_blank">https://fonts.google.com/icons</a></strong> </mat-hint>
                            <mat-hint align="end">{{iconInput.value.length}} / 1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="this.actualElement['formCreator'].controls['icon'].value != null">
                        <mat-form-field appearance="outline">
                            <mat-label>Icon type</mat-label>
                            <mat-select formControlName="icon_type">
                                <mat-option *ngFor="let item of genericCreatorService.class_angular_icon" [value]="item.internal_name">{{item.internal_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- End for icon -->

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('profiles_permissions', actualElement['id_functional_type'])">
                    <mat-form-field class="example-chip-list" appearance="outline">
                        <mat-label>Profiles permissions</mat-label>
                        <mat-chip-list #chipListProfilePermission>
                            <mat-chip *ngFor="let item of actualElement['formCreator'].controls['permissions_group_functional_area'].value"
                                (removed)="removeProfilePermission(item, i)">
                                {{item}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                        <input type="text" matInput
                            placeholder="Profiles permissions"
                            formControlName="profiles_permissions"
                            #profilePermissionInput
                            [matAutocomplete]="autoProfilePermission"
                            [matChipInputFor]="chipListProfilePermission" [matChipInputSeparatorKeyCodes]="genericCreatorService.separatorKeysCodes">
                        <mat-autocomplete #autoProfilePermission="matAutocomplete" (optionSelected)="selectedProfilePermission($event, i)">
                            <mat-option *ngFor="let item of genericCreatorService.profilePermissionOptions | async" [value]="item">
                            {{item.internal_name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('bd_table', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Bd table</mat-label>
                        <input matInput placeholder="Bd Table" formControlName="bd_table" autocomplete="off">
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('close_dialog_out', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="close_dialog_out">Close dialog out</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('iframe_fullscreen', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="iframe_fullscreen">Iframe fullscreen</mat-checkbox>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('expansion_start_opened', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="expansion_start_opened">Expansion start opened</mat-checkbox>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_query', actualElement['id_functional_type'])">
                    <mat-form-field appearance="outline">
                        <mat-label>Id query</mat-label>
                        <input type="text"
                                placeholder="Id query"
                                aria-label="internal_name"
                                matInput
                                formControlName="id_query"
                                [matAutocomplete]="autoIdQuery">
                        <mat-autocomplete autoActiveFirstOption #autoIdQuery="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let item of genericCreatorService.idQueryOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('show_total_graphs', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="show_total_graphs">Show total graphs</mat-checkbox>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('carousel_num_visible', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num visible</mat-label>
                        <input matInput placeholder="Carousel num visible" formControlName="carousel_num_visible" #carousel_num_visible autocomplete="off" maxlength="1500" reuired>
                        <mat-hint align="end">{{carousel_num_visible.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('carousel_num_scroll', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num scroll</mat-label>
                        <input matInput placeholder="Carousel num scroll" formControlName="carousel_num_scroll" #carousel_num_scroll autocomplete="off" maxlength="1500" reuired>
                        <mat-hint align="end">{{carousel_num_scroll.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('carousel_circular', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="carousel_circular">Carousel circular</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_db', actualElement['id_functional_type'])">
                    <mat-form-field appearance="fill" appearance="outline">
                        <mat-label>Id DB</mat-label>
                        <mat-select formControlName="id_db">
                            <mat-option *ngFor="let item of genericCreatorService.db" [value]="item.id_db">{{item.db_name}}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('id_table', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Id table</mat-label>
                        <input matInput placeholder="Id table" formControlName="id_table" autocomplete="off">
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('search', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="search">Search</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('pagination', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Pagination</mat-label>
                        <input matInput placeholder="Pagination" formControlName="pagination" autocomplete="off">
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('carousel_num_scroll_s', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num scroll s</mat-label>
                        <input matInput placeholder="carousel_num_scroll_s" formControlName="carousel_num_scroll_s" #carousel_num_scroll_s autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_scroll_s.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('carousel_num_scroll_xs', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num scroll xs</mat-label>
                        <input matInput placeholder="carousel_num_scroll_xs" formControlName="carousel_num_scroll_xs" #carousel_num_scroll_xs autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_scroll_xs.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('carousel_num_visible_s', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num visible s</mat-label>
                        <input matInput placeholder="carousel_num_visible_s" formControlName="carousel_num_visible_s" #carousel_num_visible_s autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_visible_s.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('carousel_num_visible_xs', actualElement['id_functional_type'])">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num visible xs</mat-label>
                        <input matInput placeholder="carousel_num_visible_xs" formControlName="carousel_num_visible_xs" #carousel_num_visible_xs autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_visible_xs.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('canExportTables', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="canExportTables">Can Export Tables</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('param', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="param">Param</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('param_intern', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="param_intern">Param intern</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('multiple', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="genericCreatorService.conditionDependingOfFuntionalTypes('form_field_empty', actualElement['id_functional_type'])">
                    <mat-checkbox formControlName="form_field_empty">Form field empty</mat-checkbox>
                </div>

            </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="actualElement.hasSubEle" [expanded]="genericCreatorService.expandedIndex === 2" (opened)="genericCreatorService.expandedIndex = 2" (closed)="genericCreatorService.checkIndexClose(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Sub Elements
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row wrap" fxFlex="100" cdkDropList [cdkDropListData]="actualElement.child" (cdkDropListDropped)="drop($event)">
                <!-- <div class="fix-class-slide-toggle" *ngIf="actualElement.id_functional_type == 99">
                    <mat-slide-toggle [(ngModel)]="genericCreatorService.listElementOptionToggle"><span style="font-weight: 500; white-space: break-spaces;">¿Quieres añadir subelementos en cada elemento de la lista? En caso contrario podrás añadir texto para cada elemento de lista.</span></mat-slide-toggle>
                </div> -->
                <div *ngFor="let child of actualElement.child | keyvalue : genericService.returnZero" cdkDrag fxFlex="100">

                    <ng-container *ngIf="actualElement['child'][child.key] && genericService.evaluateIfs(actualElement['child'][child.key]['id_condition'], actualElement['child'][child.key]['id_functional_area'], actualElement['child'][child.key]) && actualElement['child'][child.key]['id_functional_status_general'] !== 2">
                        <ng-container *ngIf="!genericCreatorService.listElementOptionToggle">
                            <div fxLayout="row" class="options-subelements" [cdkDragData]="element" fxFlex="100">
                                <span class="option-buttons">
                                    <span class="generic-buttons-black">
                                        <button mat-raised-button (click)="openSettingPopup(actualElement['child'][child.key])">
                                            <i class="material-icons">edit</i>
                                        </button>
                                    </span>
                                    <span class="generic-buttons-black">
                                        <button mat-raised-button>
                                            <i class="material-icons">open_with</i>
                                        </button>
                                    </span>
                                    <span class="generic-buttons-red">
                                        <button mat-raised-button (click)="genericCreatorService.deleteElement(actualElement['child'][child.key])">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </span>                                    
                                </span>
                                <div style="margin-left: 1em;" *ngIf="actualElement['child'][child.key].label; else elseBlock">{{actualElement['child'][child.key].label}}</div>
                                <ng-template #elseBlock>
                                    <div style="margin-left: 1em;">{{actualElement['child'][child.key].internal_name}}</div>
                                </ng-template>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="genericCreatorService.listElementOptionToggle">
                            <span fxLayout="row" class="options-subelements" [cdkDragData]="element" cdkDrag fxFlex="100">Element {{keyvalue}}</span>
                        </ng-container>
                    </ng-container>
                </div>
                <div fxLayout="row wrap" fxFlex="100" style="text-align: center; margin-bottom: 1.5em" *ngIf="!genericCreatorService.isAddingElement && actualElement['id_functional_type'] !== 100">
                    <span class="generic-buttons-blue generic-creator-add">
                        <button mat-raised-button (click)="addNewItem()">
                          <i class="material-icons">add</i>
                        </button>
                    </span>
                </div>
                <div fxLayout="row wrap" fxFlex="100" style="text-align: center; margin-bottom: 1.5em" *ngIf="!genericCreatorService.isAddingElement && actualElement['id_functional_type'] == 100">
                    <span class="generic-buttons-blue generic-creator-add">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
                            <i class="material-icons">add</i>
                        </button>
                    </span>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="addNewCardItem('header')" *ngIf="!isHeaderAdded">
                            <span>Header</span>
                        </button>
                        <button mat-menu-item (click)="addNewCardItem('content')" *ngIf="!isContentAdded">
                            <span>Content</span>
                        </button>
                        <button mat-menu-item (click)="addNewCardItem('action')" *ngIf="!isActionAdded">
                            <span>Actions</span>
                        </button>
                        <button mat-menu-item (click)="addNewCardItem('dynamicStructure')" *ngIf="!isDynamicAdded">
                            <span>Dynamic structure</span>
                        </button>
                    </mat-menu>
                </div>
                <div fxLayout="row wrap" fxFlex="100" *ngIf="genericCreatorService.isAddingElement">
                    <span style="color: #882334;">
                        You can not add sub element now, first create an element and later you can add it.
                    </span>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- Start Data validity Expansion -->
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 3" (opened)="genericCreatorService.expandedIndex = 3" (closed)="genericCreatorService.checkIndexClose(3)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Data validity
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-top: 0.5em" fxLayout="row wrap">
                <mat-accordion>
                    <ng-container *ngFor="let item of actualElement['excluding_data_type_fa_restrictions'] | keyvalue : genericService.returnZero let i = index;">
                        <mat-expansion-panel *ngIf="actualElement['excluding_data_type_fa_restrictions'][i]['mode_type'] !== 'delete'">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Data validity {{i+1}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    <span class="generic-buttons-red">
                                        <button mat-raised-button (click)="removeExcludingFaRestrictionObj(i)" matTooltip="Delete">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <form [formGroup]="actualElement['excluding_data_type_fa_restrictions'][item.key]['excludingFaRestrictionForm']" fxLayout="row wrap" fxFlex="100">
                                <mat-form-field appearance="outline">
                                    <mat-label>Data type</mat-label>
                                    <input type="text"
                                            placeholder="Data type"
                                            aria-label="internal_name"
                                            matInput
                                            formControlName="id_data_type"
                                            [matAutocomplete]="auto">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                                        <mat-option *ngFor="let item of genericCreatorService.filteredOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Admin area</mat-label>
                                    <mat-select formControlName="id_admin_area">
                                        <mat-option *ngFor="let item of genericCreatorService.admin_area" [value]="item.id_admin_area">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="example-chip-list" appearance="outline">
                                    <mat-label>Company type</mat-label>
                                    <mat-chip-list #chipList>
                                        <mat-chip *ngFor="let item of actualElement['excluding_data_type_fa_restrictions'][item.key]['excludingFaRestrictionForm'].controls['id_company_type'].value"
                                            (removed)="removeCompany(item, i)">
                                            {{item}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                    <input type="text" matInput
                                        placeholder="Company type"
                                        formControlName="companyTypes"
                                        #companyTypeInput
                                        [matAutocomplete]="autoCompanyType"
                                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="genericCreatorService.separatorKeysCodes"
                                        ><!-- (matChipInputTokenEnd)="addComapny($event, [item.key])" -->
                                    <mat-autocomplete #autoCompanyType="matAutocomplete" (optionSelected)="selectedCompany($event, i)">
                                        <mat-option *ngFor="let item of genericCreatorService.companyTypeOptions | async" [value]="item">
                                        {{item.internal_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Functional status general</mat-label>
                                    <mat-select formControlName="id_functional_status_general">
                                        <mat-option *ngFor="let item of genericCreatorService.functionalStatus" [value]="item.id_functional_status">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
                <span class="generic-buttons-blue generic-creator-add" fxFlex="100" style="text-align: center; margin-top: 1em">
                    <button mat-raised-button (click)="addNewExcludingFaRestrictionObj()">
                    <i class="material-icons">add</i>
                    </button>
                </span>
            </div>
        </mat-expansion-panel>
        <!-- End Data validity Expansion -->

        <!-- Start Restrictions Expansion -->
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 4" (opened)="genericCreatorService.expandedIndex = 4" (closed)="genericCreatorService.checkIndexClose(4)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Restrictions
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-top: 0.5em" fxLayout="row wrap">
                <mat-accordion>
                    <ng-container *ngFor="let item of actualElement['functional_area_restrictions'] | keyvalue : genericService.returnZero let i = index;">
                        <mat-expansion-panel *ngIf="actualElement['functional_area_restrictions'][i]['mode_type'] !== 'delete'">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Restrictions {{i+1}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    <span class="generic-buttons-red">
                                        <button mat-raised-button (click)="removeRestrictionObj(i)" matTooltip="Delete">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <form [formGroup]="actualElement['functional_area_restrictions'][item.key]['restrictionForm']" fxLayout="row wrap" fxFlex="100">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Activation date</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="activation_date" (click)="picker.open()">
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Admin area</mat-label>
                                    <mat-select formControlName="id_admin_area">
                                        <mat-option *ngFor="let item of genericCreatorService.admin_area" [value]="item.id_admin_area">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="outline">
                                    <mat-label>Company type</mat-label>
                                    <mat-select formControlName="id_company_type">
                                        <mat-option *ngFor="let item of genericCreatorService.company_type" [value]="item.id_company_type">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>  -->
                                <mat-form-field class="example-chip-list" appearance="outline">
                                    <mat-label>Company type</mat-label>
                                    <mat-chip-list #chipList>
                                        <mat-chip *ngFor="let item of actualElement['functional_area_restrictions'][item.key]['restrictionForm'].controls['id_company_type'].value"
                                            (removed)="removeCompanyRestriction(item, i)">
                                            {{item}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                    <input type="text" matInput
                                        placeholder="Company type"
                                        formControlName="companyTypes"
                                        #companyTypeInputRestriction
                                        [matAutocomplete]="autoCompanyTypeRestriction"
                                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="genericCreatorService.separatorKeysCodes">
                                    <mat-autocomplete #autoCompanyTypeRestriction="matAutocomplete" (optionSelected)="selectedCompanyRestriction($event, i)">
                                        <mat-option *ngFor="let item of genericCreatorService.companyTypeOptionsRestriction | async" [value]="item">
                                          {{item.internal_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Functional status general</mat-label>
                                    <mat-select formControlName="id_functional_status_general">
                                        <mat-option *ngFor="let item of genericCreatorService.functionalStatus" [value]="item.id_functional_status">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
                <span class="generic-buttons-blue generic-creator-add" fxFlex="100" style="text-align: center; margin-top: 1em">
                    <button mat-raised-button (click)="addNewRestriction()">
                    <i class="material-icons">add</i>
                    </button>
                </span>
            </div>
        </mat-expansion-panel>
        <!-- End Restrictions Expansion -->
    </mat-accordion>
</ng-container>
