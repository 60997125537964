<div style="margin: 0 1em">
  <h3><b>{{ 'web.cookies.politica-cookies' | translate}}</b></h3>

  <h4><b>{{ 'web.cookies.que-son-cookies' | translate}}</b></h4>

  <p>
    {{ 'web.cookies.cookies-p1' | translate}}
  </p>

  <p>
    {{ 'web.cookies.cookies-p2' | translate}}
  </p>

  <h4><b>{{ 'web.cookies.tipos-cookies' | translate}}</b></h4>

  <p>
    {{ 'web.cookies.cookies-p3' | translate}}
  </p>

  <p>
    {{ 'web.cookies.cookies-p4' | translate}}
  </p>

  <h4><b>{{ 'web.cookies.como-desactivar' | translate}}</b></h4>

  <p>
    {{ 'web.cookies.cookies-p5' | translate}}
    <br>{{ 'web.cookies.como-configurar' | translate}} <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11" target="_blank" class="link">Internet Explorer</a>.
    <br>{{ 'web.cookies.como-configurar' | translate}} <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" class="link">Google Chrome</a>.
    <br>{{ 'web.cookies.como-configurar' | translate}} <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" class="link">Firefox</a>.
  </p>

  <div align="end">
    <button mat-button [mat-dialog-close]="true" style="opacity: 0.9; background: #FFF; color: #000 !important;">{{ 'web.cookies.cerrar' | translate}}</button>
  </div>
  <br>
</div>
