import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';
import {Subscription } from "rxjs";
import { FunctionsService } from "../../../services/functions/functions.service";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit, OnDestroy {

  @Input() structure : any;
  @Input() index : number;
  genericForm : any;

  public value : any;
  public valueInit : any;
  private structureSubscription : Subscription;
  private formFieldUpdateSubscription: Subscription;
  public notOwner = false;

  constructor(private genericService: GenericService,
              private functionsService: FunctionsService) { }

  ngOnInit(): void {
    this.genericForm = this.structure[this.index]['form'];
    this.structure[this.index].invalid = false;

    if(this.structure[this.index]['tmp_value'] === null || this.structure[this.index]['tmp_value'] === undefined)
      this.structure[this.index]['tmp_value'] = 0;

    if(this.structure[this.index]['tmp_value'] == "isbooleantrue") this.structure[this.index]['tmp_value'] = 1
    if(this.structure[this.index]['tmp_value'] == "isbooleanfalse") this.structure[this.index]['tmp_value'] = 0

    this.value = this.structure[this.index]['tmp_value'];
    this.value = !(this.value == '0' || this.value == "'0'" || this.value == 'false');

    if (this.value !== undefined && this.value !== null) {
      if(this.value == '???'){
        this.value = false;
        this.notOwner = true;
      }

      this.genericForm.patchValue({
        [this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]: this.value
      });
    }
    this.genericService.updateValueCheckBox(this.structure, this.index, this.value);
    this.structure[this.index]['original_value'] = JSON.parse(JSON.stringify(this.value))

    this.structureSubscription = this.genericService.structureUpdated.subscribe(
      (strc) => {
        for(let key in strc){
          const elem = this.genericService.findElementWithIdLoop(strc[key], this.structure[this.index]['id_functional_area']);
          if(elem){
            this.value = elem['child']['tmp_value'];
            if(this.value == '0' || this.value == "'0'" || this.value == 'false') this.value = false;
            if (this.value) {
              if(this.value == '???'){
                this.value = false;
                this.notOwner = true;
              }
              this.genericForm.patchValue({
                [this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]: this.value
              });
            }
          }
        }
      }
    );
    if(this.structure[this.index]['tmp_value'] === false) this.structure[this.index]['tmp_value'] = 0;
    if(this.structure[this.index]['tmp_value'] === "'0'") this.structure[this.index]['tmp_value'] = 0;

    this.formFieldUpdateSubscription = this.genericService.updateFormFields.subscribe((change) => {
      if(change) this.value = this.structure[this.index]['tmp_value'];
    });

    this.valueInit = this.value;
  }

  ngOnDestroy() {
    this.structureSubscription.unsubscribe()
    this.formFieldUpdateSubscription.unsubscribe()
  }

  // el valor del boolean es el valor al que s'ha canviat
  updateValue(val : boolean) {
    let parent = this.structure[this.index]['id_functional_parent_initial'];
    let type = this.structure[this.index]['type'] + '_';
    if(this.valueInit === undefined) this.valueInit = this.value;
    let vnew = val, vold = this.valueInit;

    let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
    if(id_parent === undefined || id_parent === null) id_parent = this.structure[this.index]['id_functional_parent'];
    
    let vsnew = 0;
    let vsold = 0;
    if(vnew) vsnew = 1;
    if(vold) vsold = 1;
    if(vsnew !== vsold) this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], type, vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else {
      if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
      delete this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']];
      // EJECUTAMOS FUNCIÓN
      let lastValueOnExecuteFunction = undefined;
      if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
      if(this.structure[this.index]["id_function"] > 0 && (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction !== vnew)) this.functionsService.getFunctionFormField(this.structure[this.index]);
    }
    /*if((this.structure[this.index]['tmp_value'] == 1 && !value) || (this.structure[this.index]['tmp_value'] == 0 && value)){
      this.genericService.formsChanged[parent]['checkbox_' + this.structure[this.index]['id_functional_area']] = {old: this.value == 1 , new: value};
    } else{
      delete this.genericService.formsChanged[parent]['checkbox_' + this.structure[this.index]['id_functional_area']];
    }*/

    this.genericService.updateValueCheckBox(this.structure, this.index, val);    
  }

  iconClick() {
    this.functionsService.getFunction(this.structure[this.index]);
  }

}
