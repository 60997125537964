<mat-tab-group class="two-tabs">
  <mat-tab label="TAB 1">
    <ng-template mat-tab-label>
      <span>Todos los Módulos</span>
    </ng-template>
    <ng-template matTabContent>
      <div class="modulos" fxFlex="100" *ngFor="let modulo of this.modulosOferta">
        <app-marketplace-card class="row-expansion4" [modulo]="modulo" [isOferta]="true"></app-marketplace-card>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="TAB 2">
    <ng-template mat-tab-label>
      <span>Ver mis Módulos</span>
    </ng-template>
    <ng-template matTabContent>
      <div class="modulos" fxFlex="100" *ngFor="let modulo of this.misModulos">
        <app-marketplace-card class="row-expansion4" [modulo]="modulo" [isOferta]="false"></app-marketplace-card>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
