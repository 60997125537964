import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Values} from 'values/values';
import {CustomEvent} from './../objects/custom-event.component';
import {SimpleClient} from './../objects/simple-client';
import {EndpointService} from 'app/services/endpoint/endpoint.service';
import {FunctionsService} from 'app/services/functions/functions.service';
import {Subject} from 'rxjs';
import {Strings} from 'strings/strings';
import {UntypedFormControl, UntypedFormGroup, NgModel, ValidatorFn, Validators} from '@angular/forms';
import {categoryTypes, EventType, setCategories} from './../objects/category';
import {UserBody} from '../../users/objects/user-body';
import {SimpleProperty} from '../objects/simple-property';
import {AuthService} from '../../services/auth/auth.service';
import {ColorPickerDialog} from '../subcomponents/color-picker-dialog/color-picker-dialog';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Spanish} from 'flatpickr/dist/l10n/es.js'
import flatpickr from 'flatpickr';
import {SignActivityDialog} from "../sign-activity-dialog/sign-activity-dialog";
import {ViewSignDialog} from "../view-sign-dialog/view-sign-dialog";
import {MessageDialogComponent} from "../../utils/message-dialog/message-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Estado, Notifications, Periods} from "../../home/home.component";
import {SimpleUser} from "../objects/simple-user";
import {SimpleOffer} from "../objects/simple-offer";
import {DatePipe} from "@angular/common";
import { RRule } from 'rrule';
import {AddElementEventComponent} from "./add-element-event/add-element-event.component";
import {FlatpickrDefaultsInterface} from 'angularx-flatpickr/flatpickr-defaults.service';
import {GenericService} from "../../services/generic/generic.service";
import * as _ from 'lodash';
import {SimpleEncargo} from '../objects/simple-encargo';
import {ActivitiesService} from '../../services/activities/activities.service';


flatpickr.localize(Spanish); // default locale is now Russian
flatpickr(".flatpickr", {
  disable: [
    function(dateObject){
        let enabledDates = ["2016/07/16", "2016/08/22"]; // Gabri comments -> que es esto? repasar
        for(let i = 0; i < enabledDates.length; i++)
            if(dateObject.getTime() === new Date(enabledDates[i]).getTime())
                return false;
        return true;
    }
  ]
});


@Component({
	selector: 'edit-event-dialog',
	templateUrl: 'edit-event-dialog.html',
	styleUrls: ['./edit-event-dialog.scss'],
  providers: [DatePipe]
})
export class EditEventDialog implements OnInit {


	public routes = Values.ROUTES;
	public strings = Strings.CALENDAR;

	event: CustomEvent;
  allEvents: CustomEvent[] = [];

  periodEnd: Date;
  interval: number = 1;

	staticOffer: boolean;

	usersArray: SimpleUser[] = [];
	clientsArray: SimpleClient[] = [];
	propertiesArray: SimpleProperty[] = [];
	offersArray: SimpleOffer[] = [];
  industrialsArray: SimpleClient[] = [];
  encargoArray: SimpleEncargo[] = [];

	currentOffer: SimpleOffer;
	currentUser: SimpleUser;
  dia_inicio: Date;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public idProperty = null;

  public selectedDate = '2021-04-28';

  tempTitle: string;
	tempStart: Date;
	tempEnd: Date;
	tempType: EventType;
	tempComment: string;
  tempLocation: string;
	tempMotivoCancelacion: string = '';
	tempMember: UserBody;
	tempClient: SimpleClient;
	tempProperty: SimpleProperty;
  tempUser: SimpleUser;
  tempOffer: SimpleOffer[];
  tempAllDay = false;
  tempIdSerie: number;
  setNotification = false;
  isPeriod: boolean = false;
  creatingEvent: boolean;
  isForeign: boolean = true;
  tempAccountId: number;
  tempAccountType: number;
  propertiesControl = new UntypedFormControl();
  clientsControl = new UntypedFormControl();
  usersControl = new UntypedFormControl();
  offersControl = new UntypedFormControl();
  industrialsControl = new UntypedFormControl();
  encargosControl = new UntypedFormControl();
  categories: [];

	refresh: Subject<any> = new Subject();

  selectedNotification1: Notifications;
  selectedNotification2: Notifications;
  selectedNotification3: Notifications;
  notifications: Notifications[] = [];

  originalClients: SimpleClient[];
  originalUsers: SimpleUser[];
  originalProperties: SimpleProperty[];
  originalOffers: SimpleOffer[];
  originalEncargos: SimpleEncargo[];
  originalIndustriales: SimpleClient[];


  fechaInicioOriginal: any;
  fechaFinalOriginal: any;

  idAccount: number;
  accountType: number;
  account: any;

  showOferta: boolean = (this.activitiesService.controlsItemsActivities[7129] !== undefined && this.activitiesService.controlsItemsActivities[7129]['id_functional_status_mkt'] === 1);
  showCliente: boolean = (this.activitiesService.controlsItemsActivities[7125] !== undefined && this.activitiesService.controlsItemsActivities[7125]['id_functional_status_mkt'] === 1);
  showEncargo: boolean = (this.activitiesService.controlsItemsActivities[7126] !== undefined && this.activitiesService.controlsItemsActivities[7126]['id_functional_status_mkt'] === 1);
  showInmueble: boolean = (this.activitiesService.controlsItemsActivities[7124] !== undefined && this.activitiesService.controlsItemsActivities[7124]['id_functional_status_mkt'] === 1);
  showIndustrial: boolean = (this.activitiesService.controlsItemsActivities[7127] !== undefined && this.activitiesService.controlsItemsActivities[7127]['id_functional_status_mkt'] === 1);
  showParticipante: boolean = (this.activitiesService.controlsItemsActivities[7128] !== undefined && this.activitiesService.controlsItemsActivities[7128]['id_functional_status_mkt'] === 1);
  showFirma: boolean = (this.activitiesService.controlsItemsActivities[7130] !== undefined && this.activitiesService.controlsItemsActivities[7130]['id_functional_status_mkt'] === 1);
  showRemember: boolean = (this.activitiesService.controlsItemsActivities[7131] !== undefined && this.activitiesService.controlsItemsActivities[7131]['id_functional_status_mkt'] === 1);

  estados: Estado[] = [
    {id: 1, estado: 'Abierta'},
    {id: 2, estado: 'Terminada'},
    {id: 3, estado: 'Cancelada'},
  ];

  periods: Periods[] = [
    {value: 'per_day', viewValue: 'Cada dia'},
    {value: 'per_week', viewValue: 'Cada semana'},
    {value: 'per_month', viewValue: 'Cada mes'},
    {value: 'per_year', viewValue: 'Cada año'}
  ];

  editEventForm = new UntypedFormGroup({
    eventTitle: new UntypedFormControl({ value: "", disabled: false }, Validators.required),
    eventAccount: new UntypedFormControl({ value: "", disabled: false }, [this.accountValidator()]),
    eventCategory: new UntypedFormControl({ value: "", disabled: false }, Validators.required),
    eventEndDate:  new UntypedFormControl({ value: "", disabled: false }),
    eventAllDay: new UntypedFormControl({ value: "", disabled: false }),
    eventIsPeriod: new UntypedFormControl({ value: "", disabled: false }),
    eventFrecuency: new UntypedFormControl({ value: "", disabled: false }),
    eventPeriod: new UntypedFormControl({ value: "", disabled: false }),
    eventEndPeriod: new UntypedFormControl({ value: "", disabled: false }),
    eventSearchProperty: new UntypedFormControl({ value: "", disabled: true }),
    eventSearchClient: new UntypedFormControl({ value: "", disabled: true }),
    eventSearchOffer: new UntypedFormControl({ value: "", disabled: true }),
    eventSearchUser: new UntypedFormControl({ value: "", disabled: true }),
    eventSearchIndustriales: new UntypedFormControl({ value: "", disabled: true }),
    eventSearchEncargos: new UntypedFormControl({ value: "", disabled: true }),
    eventRecordatorio: new UntypedFormControl({ value: "", disabled: false }),
    eventRecordatorio1: new UntypedFormControl({ value: "", disabled: false }, [this.notification1TimeValidator()]),
    eventRecordatorio2: new UntypedFormControl({ value: "", disabled: false }, [this.notification2TimeValidator()]),
    eventRecordatorio3: new UntypedFormControl({ value: "", disabled: false }, [this.notification3TimeValidator()]),
    eventComentario: new UntypedFormControl({ value: "", disabled: false }),
    eventLocation: new UntypedFormControl({ value: "", disabled: false }),
    eventState: new UntypedFormControl({ value: "", disabled: true }, [this.stateValidator()]),
    eventCanMotivo: new UntypedFormControl({ value: "", disabled: false }, [this.canMotivoValidator()]),
  });

  @ViewChild('fechaFinModel') fechaFinModel: NgModel;

  selectedValue: String;
  constructor(private endpointService: EndpointService,
		public _bottomSheetRef: MatBottomSheetRef<EditEventDialog>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public authService: AuthService,
    public router: Router,
    public genericService: GenericService,
    public activitiesService: ActivitiesService,
    public functionsService: FunctionsService)
	{
    if(this.data !== null && this.data !== undefined && this.data.event !== undefined && this.data.event !== null && this.data.event.id !== null && this.data.event.id !== 0 && this.data.event.id !== undefined) {
      this.creatingEvent = false;
    } else this.creatingEvent = true;
    if(!this.creatingEvent) {
      this.event = data.event;
      this.idAccount = this.event.accountId;
      this.account = this.event.account;
      this.accountType = this.event.accountType;
      this.staticOffer = data.staticOffer;
      this.isForeign = this.event.idCreator !== this.authService.userId;
      if(!this.isForeign) this.editEventForm.enable();
      this.tempTitle = this.event.title;
      this.tempStart = this.event.start;
      this.tempEnd = this.event.end;
      this.tempType = this.event.type;
      this.tempComment = this.event.comment;
      this.tempLocation = this.event.location;
      this.tempMember = this.event.member;
      this.tempProperty = this.event.property;
      this.tempClient = this.event.client;
      this.tempUser = this.event.user;
      this.tempOffer = this.event.offer;
      this.tempAllDay = this.event.allDay;
      this.tempMotivoCancelacion = (this.event.can_motivo !== null ? this.event.can_motivo : '');
      this.tempIdSerie = this.event.idSerie;
      this.dia_inicio = this.data.event.start;
      this.propertiesControl.setValue(this.event.properties);
      this.clientsControl.setValue(this.event.clients);
      this.usersControl.setValue(this.event.users);
      this.offersControl.setValue(this.event.offer);
      this.industrialsControl.setValue(this.event.industriales);
      this.encargosControl.setValue(this.event.encargos);
      this.originalClients = this.event.clients;
      this.originalUsers = this.event.users;
      this.originalProperties = this.event.properties;
      this.originalOffers = this.event.offer;
      this.originalEncargos = this.event.encargos;
      this.originalIndustriales = this.event.clients;
      this.tempAccountId = this.event.accountId;
      this.tempAccountType = this.event.accountType;
      this.setNotification = (this.event.idAviso1 != null);
      if(Object.keys(this.activitiesService.categoriesByAccount).length !== 0) setCategories(this.activitiesService.categoriesByAccount[data.event.account.nombre].data);   
      else this.setAllCategoriesAndFilters();
    } else {
      this.event = new CustomEvent();
      this.tempType = new EventType(0,null,null,1);
      //Comentario
      this.tempComment = "";
      this.tempLocation = "";
      this.tempTitle = "Nueva actividad";
      // Per un new Event sempre es true
      this.isForeign = false;
      authService.returnDefaultAccount().then((res: any) => {
        this.event.setAccount(res);
        this.account = this.event.account;
        this.event.idCreator = this.authService.userId;
        this.event.creatorName = this.authService.userFullName;
        this.idAccount = res.id;
        this.accountType = res.type_account;
        if(Object.keys(this.activitiesService.categoriesByAccount).length !== 0) setCategories(this.activitiesService.categoriesByAccount[this.account.nombre].data);
        else this.setAllCategoriesAndFilters();
      });

      if(this.data.simpleOffer != null){
        let array = [];
        if(Array.isArray(this.data.simpleOffer)) array = this.data.simpleOffer;
        else array.push(this.data.simpleOffer);
        this.event.offer = array;
        this.offersControl.setValue(array);
      }

      if (this.data.simpleClient != null) {
        let array = [];
        if(Array.isArray(this.data.simpleClient)) array = this.data.simpleClient;
        else array.push(this.data.simpleClient);
        this.event.clients = array;
        this.clientsControl.setValue(array);
      }

      if (this.data.simpleProperty != null) {
        let array = [];
        if(Array.isArray(this.data.simpleProperty)) array = this.data.simpleProperty;
        else array.push(this.data.simpleProperty);
        this.event.properties = array;
        this.propertiesControl.setValue(array);
      }

      if (this.data.simpleEncargo != null) {
        let array = [];
        if(Array.isArray(this.data.simpleEncargo)) array = this.data.simpleEncargo;
        else array.push(this.data.simpleEncargo);
        this.event.encargos = array;
        this.encargosControl.setValue(array);
      }

      if (this.data.simpleIndustriales != null) {
        let array = [];
        if(Array.isArray(this.data.simpleIndustriales)) array = this.data.simpleIndustriales;
        else array.push(this.data.simpleIndustriales);
        this.event.industriales = array;
        this.industrialsControl.setValue(array);
      }

      if (this.data.idDemandaRelated != null) {
        this.event.idDemandaRelated = this.data.idDemandaRelated;
      }

      if(this.data.date) {
        this.tempStart = this.data.date;
        this.event.start = this.tempStart;

        this.tempEnd = _.cloneDeep(this.data.date);
        this.tempEnd.setHours(this.event.start.getHours() + 1);
        this.event.end = this.tempEnd;
      } else {
        this.tempStart = null;
        this.tempEnd = null;
      }
      this.event.setType(this.tempType);
    }
	}

	ngOnInit() {
    
    // Notifications
    if(this.activitiesService.notifications == null){
      this.endpointService.getActivitiesNotificationTiming().subscribe(data => {
        Object.keys(data['response']).forEach(timing => {
          this.notifications.push({id: data['response'][timing]['id'], tiempo: data['response'][timing]['tiempo'], timing: data['response'][timing]['timing']});
          this.asignNotificationSelected();
        });
        this.activitiesService.notifications = this.notifications;
      });
    } else{
      this.notifications = this.activitiesService.notifications;
      this.asignNotificationSelected();
    }

    // Properties
    if(this.data.event != undefined && this.data.event.properties !== undefined && this.data.event.properties !== null){
      Object.keys(this.data.event.properties).forEach(property => {
        let simpleProperty = new SimpleProperty(this.data.event.properties[property]['id'], this.data.event.properties[property]['reference'], this.data.event.properties[property]['price'], this.data.event.properties[property]['internalName']);
        this.propertiesArray.push(simpleProperty);
      })
    }

    // Users
    if(this.data.event != undefined && this.data.event.users !== undefined && this.data.event.users !== null){
      Object.keys(this.data.event.users).forEach(user => {
        let simpleUser = new SimpleUser(this.data.event.users[user]['id'], this.data.event.users[user]['user']);
        this.usersArray.push(simpleUser);
      });
    }

    // Offers
    console.log(this.data.event, "LA OFERTA");
    if(this.data.event != undefined && this.data.event.offer !== undefined && this.data.event.offer !== null) {
      let simpleOffer = new SimpleOffer(this.data.event.offer['id'], this.data.event.offer['offer']);
      this.offersArray.push(simpleOffer);
      this.event.id_oferta = this.data.event.offer['id'];
    }

    // Clients
    if(this.data.event != undefined && this.data.event.clients !== undefined && this.data.event.clients !== null){
      Object.keys(this.data.event.clients).forEach(client => {
        let simpleClient = new SimpleClient(this.data.event.clients[client]['id'], this.data.event.clients[client]['fullname']);
        this.clientsArray.push(simpleClient);
      });
    }

    // Encargos
    if(this.data.event != undefined && this.data.event.encargos !== undefined && this.data.event.encargos !== null){
      Object.keys(this.data.event.encargos).forEach(encargo => {
        let simpleEncargo = new SimpleEncargo(this.data.event.encargos[encargo]['id'], this.data.event.encargos[encargo]['reference']);
        this.encargoArray.push(simpleEncargo);
      });
    }

    
    if(this.event != undefined && this.event != null && this.event.start != undefined && this.event.start != null){
      this.fechaInicioOriginal = _.cloneDeep(this.event.start);
      this.fechaInicioOriginal.setHours(this.event.start.getHours() + 1);
      this.fechaFinalOriginal = _.cloneDeep(this.event.end);
      if(this.event.end != undefined && this.event.end != null) this.fechaFinalOriginal.setHours(this.event.end.getHours() + 1);
    }
    this._changeDetectorRef.detectChanges();

    this.editEventForm.get('eventIsPeriod').valueChanges.subscribe(val => {
      console.log(this.editEventForm, "events form")
      if (val) {
        this.editEventForm.controls['eventPeriod'].setValidators([Validators.required]);
        this.editEventForm.controls['eventFrecuency'].setValidators([Validators.required]);
        this.editEventForm.controls['eventEndPeriod'].setValidators([Validators.required]);
      } else {
        this.editEventForm.controls['eventPeriod'].clearValidators();
        this.editEventForm.controls['eventFrecuency'].clearValidators();
        this.editEventForm.controls['eventEndPeriod'].clearValidators();
      }
      this.editEventForm.controls['eventPeriod'].updateValueAndValidity();
      this.editEventForm.controls['eventFrecuency'].updateValueAndValidity();
      this.editEventForm.controls['eventEndPeriod'].updateValueAndValidity();
      console.log(this.editEventForm, "events form")
    });
  }

  asignNotificationSelected(){
    if (this.creatingEvent) {
      this.selectedNotification1 = this.notifications.filter(n => n.id === this.authService.idAviso1)[0];
      this.selectedNotification2 = this.notifications.filter(n => n.id === this.authService.idAviso2)[0];
      this.selectedNotification3 = this.notifications.filter(n => n.id === this.authService.idAviso3)[0];
    } else {
      this.notifications.forEach(aviso => {
        if (aviso.id === this.event.idAviso1) { this.selectedNotification1 = aviso; }
        if (aviso.id === this.event.idAviso2) { this.selectedNotification2 = aviso; }
        if (aviso.id === this.event.idAviso3) { this.selectedNotification3 = aviso; }
      });
    }    
  }

	removeProperty(property) {
    console.log("PROOOOOP", property);
    let index = this.propertiesControl.value.indexOf(property);
    if (index > -1) { this.propertiesControl.value.splice(index, 1); }
    this.propertiesControl.setValue(this.propertiesControl.value);
	}

	removeClient(client) {
    let index = this.clientsControl.value.indexOf(client);
    if (index > -1) { this.clientsControl.value.splice(index, 1); }
    this.clientsControl.setValue(this.clientsControl.value);
	}

	removeUser(user) {
    let index = this.usersControl.value.indexOf(user);
    if (index > -1) { this.usersControl.value.splice(index, 1); }
    this.usersControl.setValue(this.usersControl.value);
	}

	removeOffer(offer) {
    this.offersControl.reset();
	}

	removeIndustriales(industrial) {
    let index = this.industrialsControl.value.indexOf(industrial);
    if (index > -1) { this.industrialsControl.value.splice(index, 1); }
    this.industrialsControl.setValue(this.industrialsControl.value);
	}

  removeEncargos(encargo) {
    let index = this.encargosControl.value.indexOf(encargo);
    if (index > -1) { this.encargosControl.value.splice(index, 1); }
    this.encargosControl.setValue(this.encargosControl.value);
	}

	displayMemberFn(member: UserBody): string {
		if (member != null) {
			return member.name;
		}
		else { return ''; }
	}

	displayPropertyFn(property: SimpleProperty): string {
    return property != null ? property.reference : '';
  }

  displayClientFn(client: SimpleClient): string {
    return client != null ? client.fullName : '';
  }

  displayUserFn(value?: any): string | undefined{
    return value ? value.user : undefined;
  }

  displayOfferFn(offer?: any): string | undefined{
    return offer ? offer.offer : undefined;
  }

  setAllCategoriesAndFilters() {
    this.authService.accountsGeneric.forEach(account => {
      this.endpointService.getCategories(this.authService.getLoggedInUserId(), account.id, this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data => {
        if((account.id == this.authService.returnActiveAccountId())) setCategories(data);  
        this.activitiesService.setCategories(account.nombre, data);
      });
    });
  }


  onNoClick(): void {
    if(!this.creatingEvent) {
      this.event.clients = this.originalClients;
      this.event.users = this.originalUsers;
      this.event.properties = this.originalProperties;
      this.event.offer = this.originalOffers;
      this.event.industriales = this.originalIndustriales;
      this.event.encargos = this.originalEncargos;
    }
    this._bottomSheetRef.dismiss();
  }

  getStartDateErrorMessage() {
    return this.strings.START_DATE_ERROR_MESSAGE;
  }

  getErrorMessage(value : number){
    switch (value){
      case 1:
        return this.strings.END_DATE_ERROR_MESSAGE;
    }
  }

  endDateIsInvalid() {
    return !(this.tempStart <= this.tempEnd || this.tempEnd === null || this.tempEnd === undefined || ((this.tempStart === null || this.tempStart === undefined) && false));
    // return !(this.tempEnd === null || this.tempStart <= this.tempEnd || (((this.tempStart === undefined) || (this.tempStart === null)) && this.tempEnd ));
  }

  activityDateIsInvalid() {
    // Comprobaremos si la actividad acaba antes que empieza o no tiene fecha inicio
    // Si no hay fecha fin es correcto, si la hay ha de ser mayor a la fecha inicio
    return ((this.event.start > this.event.end && (this.event.end !== null && this.event.end !== undefined)) || 
              (this.tempStart > this.tempEnd) && (this.tempEnd !== null && this.tempEnd !== undefined));
  }

  periodDateIsInvalid() {
    // Comprobaremos si la actividad periodica acaba antes que empieza o no tiene fechas definidas
    return (this.event.start > this.periodEnd || this.tempStart > this.periodEnd
              || this.tempStart === null || this.tempStart === undefined 
              || this.periodEnd === null || this.periodEnd === undefined);
  }

	get categoryTypes() {
		let parent = categoryTypes.filter(obj => {
			obj.types = obj.types.filter(el => el.active == 1 && el.visible == 1);
			return obj.types.length > 0;
		});
		return parent;
	}

  startDateUpdatedToNull(){
    if(!this.isForeign){
      this.tempStart = null;
      this.tempEnd = null;
      this.isPeriod = false;
    }
  }

	editClick(event: any) {
    let arrayEventsPeriodic: CustomEvent[] = [];

    if (!this.editEventForm.valid || this.tempType == null || this.tempType.active == 0) {
      this.snackBar.open('Faltan campos por rellenar', 'X', {
        duration: 6000,
        panelClass: ['lightred-snackbar']
      });
      return;
    } else if(this.activityDateIsInvalid()) {
      this.snackBar.open('La fecha es inválida', 'X', {
        duration: 6000,
        panelClass: ['lightred-snackbar']
      });
      return;
    }

    // Diferenciamos en cambios que se aplican a un solo evento o a un grupo de eventos periodicos
    this.event.member = this.tempMember;
		this.event.property = this.tempProperty;
		this.event.client = this.tempClient;
		this.event.user = this.tempUser;
		this.event.offer = this.tempOffer;
		this.event.title = this.tempTitle;
		this.event.start = this.tempStart;
		this.event.end = this.tempEnd;
		this.event.comment = this.tempComment;
    this.event.location = this.tempLocation;
		this.event.allDay = this.tempAllDay;
		this.event.idSerie = this.tempIdSerie;
		this.event.can_motivo = (this.event && this.event.estado &&  this.event.estado === 3 ? this.tempMotivoCancelacion : null);
    this.event.idAviso1 = (this.selectedNotification1 && this.setNotification ? this.selectedNotification1.id : null);
		this.event.idAviso2 = (this.selectedNotification2 && this.setNotification ? this.selectedNotification2.id : null);
		this.event.idAviso3 = (this.selectedNotification3 && this.setNotification ? this.selectedNotification3.id : null);
		this.event.id_oferta = (this.offersControl.value ? this.offersControl.value[0].id : null);
		this.event.accountId = this.tempAccountId;
		this.event.accountType = this.tempAccountType;
    this.event.cssClass = (this.event.estado == 2 || this.event.estado == 3 ? 'customEventClass' : '');
		categoryTypes.forEach(category => category.types.forEach(type => { if (type.id === this.tempType.id) { this.tempType = type; } }));
    if (this.tempType != null) this.event.setType(this.tempType);
    
    if(this.event.idParentPeriodicity !== null){
      let message = this.strings.UPDATE_ACTIVITY_PERIOD_MESSAGE;
      let panelClass = [];
      if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: panelClass,
        data: {
          message: message,
          labelButton1: 'Cancelar',
          labelButton2: 'Editar solo este',
          labelButton3: 'Editar todos a partir de este'
        }
      });
      
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          let fecha_inicio = null;
          let fecha_fin = null;

          if (this.event.start !== null) {
            let fi = new Date(this.event.start)
            fecha_inicio = this.activitiesService.changeDateFormat(fi, 2);
          }

          if ( this.event.end !== null ) {
            let ff = new Date(this.event.end);
            fecha_fin = this.activitiesService.changeDateFormat(ff, 2);
          }

          const body = {
            title: this.event.title, 
            comments: this.event.comment, 
            location: this.event.location,
            creator_id: this.authService.getLoggedInUserId(),
            category_tipe: this.event.type.id, property_id: (this.event.property != null) ? this.event.property.id : null,
            property_reference: (this.event.property !== null) ? this.event.property.reference : null,
            client_id: (this.event.client !== null) ? this.event.client.id : null,
            start_date: fecha_inicio,
            end_date: fecha_fin,
            all_day: (this.event.allDay ? 1 : 0), id_serie: (this.event.idSerie !== null ? this.event.idSerie : null), id_aviso1: (this.event.idAviso1 !== null ? this.event.idAviso1 : null),
            id_aviso2: (this.event.idAviso2  !== null ? this.event.idAviso2 : null), id_aviso3: (this.event.idAviso3 !== null ? this.event.idAviso3 : null),
            estado: this.event.estado, can_motivo: (this.event && this.event.estado &&  this.event.estado === 3 ? this.event.can_motivo : null),
            clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
            usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
            id_oferta: this.event.id_oferta,
            offer: (this.event.offer ? this.event.offer : []),
            id_cuenta: this.event.accountId,
            cuenta_tipo: this.event.accountType,
            fechaInicioOriginal: this.fechaInicioOriginal,
            fechaFinalOriginal: this.fechaFinalOriginal,
            id_empresa: this.event.account.id
          };

          if(data === 2){
            // Se edita solamente este, se rompe su relacion de periodicidad con los otros eventos
            this.endpointService.updateCalendarActivity(body, this.event.id).subscribe(data => {
              if (data['errorCode'] === 0) {
                body['idParentPeriodicity'] = null;
                body['categories'] = this.categoryTypes;
                this._bottomSheetRef.dismiss(body);
              }
            });
          } else if (data === 3){
            this.endpointService.updatePeriodicCalendarActivity(body, this.event).subscribe(data => {
              if (data['errorCode'] === 0) {
                // Recogemos los eventos modificados de esta accion
                let eventsModified = data['response'];

                eventsModified[0]['categories'] = this.categoryTypes;
                this._bottomSheetRef.dismiss([{all: true, option: "update", event: eventsModified}]);
              }
            });
          }
        }
      });
    } else {
      let fecha_inicio = null;
      let fecha_fin = null;

      if (this.event.start !== null) {
        let fi = new Date(this.event.start)
        fecha_inicio = this.activitiesService.changeDateFormat(fi, 2);
      }

      if ( this.event.end !== null ) {
        let ff = new Date(this.event.end);
        fecha_fin = this.activitiesService.changeDateFormat(ff, 2);
      }
      // He de comprobar si en los cambios se ha cambiado el modo del evento, y ahora es peridoico
      if(this.isPeriod && this.event.idParentPeriodicity == null){
        // Ha cambiado su estado, ahora pasa a ser periodico
        this.endpointService.getActivitySerieMaxId().subscribe(data_n => {
          let maxId = data_n['response'][0]['maxId'];
          if (maxId === null) { maxId = 1; }
          else { maxId += 1; }

          let fecha_inicio = null;
          let fecha_fin = null;
    
          if (this.event.start !== null) {
            let fi = new Date(this.event.start)
            fecha_inicio = this.activitiesService.changeDateFormat(fi, 2);
          }
    
          if ( this.event.end !== null ) {
            let ff = new Date(this.event.end);
            fecha_fin = this.activitiesService.changeDateFormat(ff, 2);
          }

          // Elimino la actividad ya que va a tener nuevas propiedades
          this.endpointService.deleteCalendarActivity(this.event.id).subscribe(data1 => {
            if (data1['errorCode'] === 0) {

              let arrayEventsPeriodic: CustomEvent[] = [];
              this.makePeriodicActivities(arrayEventsPeriodic, maxId);
            }
          });
        });
      } else{
        // Sigue siendo no periodico
        const body = {
          title: this.event.title, 
          comments: this.event.comment, 
          location: this.event.location,
          creator_id: this.authService.getLoggedInUserId(),
          category_tipe: this.event.type.id, property_id: (this.event.property != null) ? this.event.property.id : null,
          property_reference: (this.event.property !== null) ? this.event.property.reference : null,
          client_id: (this.event.client !== null) ? this.event.client.id : null,
          start_date: fecha_inicio,
          end_date: fecha_fin,
          all_day: (this.event.allDay ? 1 : 0), id_serie: (this.event.idSerie !== null ? this.event.idSerie : null), id_aviso1: (this.event.idAviso1 !== null ? this.event.idAviso1 : null),
          id_aviso2: (this.event.idAviso2  !== null ? this.event.idAviso2 : null), id_aviso3: (this.event.idAviso3 !== null ? this.event.idAviso3 : null),
          estado: this.event.estado, can_motivo: (this.event && this.event.estado &&  this.event.estado === 3 ? this.event.can_motivo : null),
          clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
          usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
          id_oferta: this.event.id_oferta, id_cuenta: this.event.accountId,
          cuenta_tipo: this.event.accountType,
          id_empresa: this.event.account.id
        };
        this.event.title = this.event.title;
        this.event['categories'] = this.categoryTypes;
        this.endpointService.updateCalendarActivity(body, this.event.id).subscribe(data => {
          if (data['errorCode'] === 0) {
            this._bottomSheetRef.dismiss(this.event);
          }
        });
      }

    }
  }

  public datePickerOptions : FlatpickrDefaultsInterface = {
    allowInput : true,
    enableTime : true,
    time24hr: true,
    mode : 'single',
    dateFormat : "Y-m-d H:i",
    // this:
    enable : [{from : new Date(0, 1), to : new Date(new Date().getFullYear() + 200, 12)}]
  }

  newEventClick() {
    if (!this.editEventForm.valid) {
      this.snackBar.open('Faltan campos por rellenar', 'X', {
        duration: 6000,
        panelClass: ['lightred-snackbar']
      });
      return;
    } else if(this.activityDateIsInvalid()) {
      this.snackBar.open('La fecha es inválida', 'X', {
        duration: 6000,
        panelClass: ['lightred-snackbar']
      });
      return;
    } else if(this.periodDateIsInvalid() && this.isPeriod) {
      this.snackBar.open('La fecha de la periodicidad no és válida. Debe ser posterior a la fecha de inicio de la actividad.', 'X', {
        duration: 12000,
        panelClass: ['lightred-snackbar']
      });
      return;
    }
    // Gabri Comment -> definir frase roja conforme la categoria es vacia y debe estar llena???
    this.event.member = this.tempMember;
		/*this.event.property = this.tempProperty;
		this.event.client = this.tempClient;
		this.event.user = this.tempUser;
		this.event.offer = this.tempOffer;*/
		this.event.title = this.tempTitle;
		this.event.start = this.tempStart;
		this.event.end = this.tempEnd;
		this.event.comment = this.tempComment;
    this.event.location = this.tempLocation;
		this.event.allDay = this.tempAllDay;
		this.event.idSerie = this.tempIdSerie;
		this.event.can_motivo = (this.event && this.event.estado &&  this.event.estado === 3 ? this.tempMotivoCancelacion : null);
		this.event.idAviso1 = (this.selectedNotification1 && this.setNotification ? this.selectedNotification1.id : null);
		this.event.idAviso2 = (this.selectedNotification2 && this.setNotification ? this.selectedNotification2.id : null);
		this.event.idAviso3 = (this.selectedNotification3 && this.setNotification ? this.selectedNotification3.id : null);
		this.event.id_oferta = (this.offersControl.value ? this.offersControl.value[0].id : null);
		this.event.accountId = this.tempAccountId;  
		this.event.accountType = this.tempAccountType;
		
    categoryTypes.forEach(category => category.types.forEach(type => {
      if (type.id === this.tempType.id) { this.tempType = type; } 
    }));
    if (this.tempType != null) this.event.setType(this.tempType);


    if (!this.creatingEvent) {
      this.event.accountId = this.tempAccountId;
      this.event.accountType = this.tempAccountType;
    }

    let arrayEventsPeriodic: CustomEvent[] = [];

    this.endpointService.getActivitySerieMaxId().subscribe(data_n => {
      let maxId = data_n['response'][0]['maxId'];
      if (maxId === null) { maxId = 1; }
      else { maxId += 1; }

      let fecha_inicio = null;
      let fecha_fin = null;

      if (this.event.start !== null) {
        let fi = new Date(this.event.start)
        fecha_inicio = this.activitiesService.changeDateFormat(fi, 2);
      }

      if ( this.event.end !== null ) {
        let ff = new Date(this.event.end);
        fecha_fin = this.activitiesService.changeDateFormat(ff, 2);
      }
      if (!this.isPeriod) {
        if (this.idProperty === null) {
          let body = {
            title: this.event.title, 
            comments: this.event.comment, 
            location: this.event.location,
            creator_id: this.authService.getLoggedInUserId(),
            category_tipe: this.event.type.id, property_reference: (this.event.property !== null) ? this.event.property.reference : null,
            creation_date: new Date().toISOString().slice(0,10) + ' ' + new Date().toString().slice(16,24),
            client_id: (this.event.client !== null) ? this.event.client.id : null, property_id: (this.event.property !== null) ? this.event.property.id : null,
            start_date: fecha_inicio,
            end_date: fecha_fin,
            all_day: (this.tempAllDay ? 1 : 0), id_serie: null, id_aviso1: this.event.idAviso1,
            id_aviso2: this.event.idAviso2, id_aviso3: this.event.idAviso3,
            clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
            usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
            id_oferta: this.event.id_oferta, id_cuenta: this.event.accountId,
            offer: (this.event.offer ? this.event.offer : []),
            cuenta_tipo: this.event.accountType,
            idEmpresa: this.event.account.idEmpresa,
            idDemandaRelated: this.event.idDemandaRelated
          };
          this.endpointService.addNewCalendarActivity(body).subscribe(data => {
            if (data['errorCode'] === 0) {
              // Asigno el id del event insertat
              this.event['AddedMessageBackend'] = data['errorMessage'];
              this.event['id'] = data['response'];
              this.event['actions'] = this.activitiesService.imageUserActive;
              this.event['categories'] = this.categoryTypes;

              this._bottomSheetRef.dismiss(this.event);
            }
          });
        } else {
          this.endpointService.checkIfDuplicarVivienda(this.idProperty, this.authService.inmoId).subscribe(data => {
            if (data['errorCode'] === 0) {
              if (data['response'] === null) {
                // No se ha duplicado la vivienda
                let body = {
                  title: this.event.title, 
                  comments: this.event.comment, 
                  location: this.event.location,
                  creator_id: this.authService.getLoggedInUserId(),
                  category_tipe: this.event.type.id, property_reference: (this.event.property !== null) ? this.event.property.reference : null,
                  creation_date: new Date().toISOString().slice(0,10) + ' ' + new Date().toString().slice(16,24),
                  client_id: (this.event.client !== null) ? this.event.client.id : null, property_id: (this.event.property !== null) ? this.event.property.id : null,
                  start_date: fecha_inicio,
                  end_date: fecha_fin,
                  all_day: (this.tempAllDay ? 1 : 0), id_serie: null, id_aviso1: this.event.idAviso1,
                  id_aviso2: this.event.idAviso2, id_aviso3: this.event.idAviso3,
                  clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
                  usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
                  id_oferta: this.event.id_oferta, id_cuenta: this.event.accountId,
                  offer: (this.event.offer ? this.event.offer : []),
                  cuenta_tipo: this.event.accountType,
                  idEmpresa: this.event.account.idEmpresa,
                  idDemandaRelated: this.event.idDemandaRelated
                };
                this.endpointService.addNewCalendarActivity(body).subscribe(data => {
                  if (data['errorCode'] === 0) {
                    // Asigno el id del event insertat
                    this.event['AddedMessageBackend'] = data['errorMessage'];
                    this.event['id'] = data['response'];
                    this.event['actions'] = this.activitiesService.imageUserActive;
                    this.event['categories'] = this.categoryTypes;
                    this._bottomSheetRef.dismiss(this.event);
                  }
                });
              } else {
                // Se ha duplicado la vivienda
                const oldIdProperty = this.idProperty;
                this.idProperty = data['response'];
                this.event.properties.forEach((element, index) => {
                  if (element.id === oldIdProperty) this.event.properties[index].id = this.idProperty;
                });
                let body = {
                  title: this.event.title, comments: this.event.comment, creator_id: this.authService.getLoggedInUserId(),
                  location: this.event.location,
                  category_tipe: this.event.type.id, property_reference: (this.event.property !== null) ? this.event.property.reference : null,
                  creation_date: new Date().toISOString().slice(0,10) + ' ' + new Date().toString().slice(16,24),
                  client_id: (this.event.client !== null) ? this.event.client.id : null, property_id: (this.event.property !== null) ? this.event.property.id : null,
                  start_date: fecha_inicio,
                  end_date: fecha_fin,
                  all_day: (this.tempAllDay ? 1 : 0), id_serie: null, id_aviso1: this.event.idAviso1,
                  id_aviso2: this.event.idAviso2, id_aviso3: this.event.idAviso3,
                  clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
                  usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
                  id_oferta: this.event.id_oferta, id_cuenta: this.event.accountId,
                  cuenta_tipo: this.event.accountType,
                  idEmpresa: this.event.account.idEmpresa,
                  idDemandaRelated: this.event.idDemandaRelated
                };
                this.endpointService.addNewCalendarActivity(body).subscribe(data => {
                  if (data['errorCode'] === 0) {
                    // Asigno el id del event insertat
                    this.event['AddedMessageBackend'] = data['errorMessage'];
                    this.event['id'] = data['response'];
                    this.event['actions'] = this.activitiesService.imageUserActive;
                    this.event['categories'] = this.categoryTypes;
                    this._bottomSheetRef.dismiss(this.event);
                    localStorage.setItem("movin2", this.idProperty.toString());
                    this.genericService.refreshStructure(2);
                  }
                });
              }
            }
          });
        }
      } else {
        this.makePeriodicActivities(arrayEventsPeriodic, maxId);
      }
    });
    this._bottomSheetRef.dismiss(this.event);
  }

  private makePeriodicActivities(arrayEventsPeriodic, maxId){
    let eventsPeriod = [];
    switch (this.selectedValue) {
      case 'per_day':
        const rule_day = new RRule({
          freq: RRule.DAILY,
          interval: this.interval,
          dtstart: this.event.start,
          until: this.periodEnd
        });
        let rule_day_end = null;
        if (this.event.end !== null && this.event.end !== undefined) {
          rule_day_end = new RRule({
            freq: RRule.DAILY,
            interval: this.interval,
            dtstart: this.event.end,
            until: this.periodEnd
          });
        }
        rule_day.all().forEach((date, index) => {
          let body = {
            title: this.event.title, comments: this.event.comment, creator_id: this.authService.getLoggedInUserId(),
            location: this.event.location,
            category_tipe: this.event.type.id, property_reference: (this.event.property !== null) ? this.event.property.reference : null,
            client_id: (this.event.client !== null) ? this.event.client.id : null, property_id: (this.event.property !== null) ? this.event.property.id : null,
            creation_date: new Date().toISOString().slice(0,10) + ' ' + new Date().toString().slice(16,24),
            start_date: date.toISOString().slice(0,10) + ' ' + date.toString().slice(16,24),
            end_date: (rule_day_end === null || rule_day_end === undefined) ? null : rule_day_end.all()[index].toISOString().slice(0,10) + ' ' + rule_day_end.all()[index].toString().slice(16,24),
            all_day: (this.tempAllDay ? 1 : 0), id_serie: maxId, id_aviso1: this.event.idAviso1,
            id_aviso2: this.event.idAviso2, id_aviso3: this.event.idAviso3,
            clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
            usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
            id_oferta: this.event.id_oferta, 
            id_cuenta: this.event.accountId,
            cuenta_tipo: this.event.accountType,
            idEmpresa: this.event.account.idEmpresa,
            recurrence: rule_day.toString()
          };
          eventsPeriod.push(body);
        });
        // Ordeno los events en función del dia de cada uno de ellos
        eventsPeriod.sort(function(a,b){return a['start_date'].localeCompare(b['start_date']);});
        this.endpointService.addNewCalendarPeriodActivities(eventsPeriod).subscribe(data=>{
          // He insertado todos los events en backend, ahora creare los CustomEvents para que se vea en FrontEnd
          // Los ids ya vienen ordenados
          for(let i = 0; i < eventsPeriod.length; ++i){
            let tempEvent = new CustomEvent(eventsPeriod[i].title, eventsPeriod[i].comments, data['response'][i], eventsPeriod[i].creator_id, new Date(eventsPeriod[i].start_date), new Date(eventsPeriod[i].end_date), this.event.idCalendarioGoogle, this.event.type, this.event.signLike,
            this.event.signNotify, this.event.signDNI, this.event.signImgURL, this.event.signObservation, this.tempAllDay, this.event.idSerie, eventsPeriod[i].id_aviso1, eventsPeriod[i].id_aviso2, eventsPeriod[i].id_aviso3, this.event.estado, this.event.can_motivo,
            this.event.properties, this.event.clients, this.event.users, this.event.industriales, this.event.encargos, [], eventsPeriod[i].id_oferta, this.event.creatorName, undefined, undefined, this.event.accountId, this.event.accountType, data['response'][0], eventsPeriod[i].location);
              
            tempEvent['account'] = this.event.account;
            tempEvent['actions'] = this.activitiesService.imageUserActive;
            tempEvent['categories'] = this.categoryTypes;
            tempEvent['accountId'] = this.idAccount;  
            arrayEventsPeriodic.push(tempEvent);
          }
        });
        break;
      case 'per_week':
        const rule_week = new RRule({
          freq: RRule.WEEKLY,
          interval: this.interval,
          dtstart: this.tempStart,
          until: this.periodEnd
        });
        let rule_week_end = null;
        if (this.event.end !== null && this.event.end !== undefined) {
          rule_week_end = new RRule({
            freq: RRule.WEEKLY,
            interval: this.interval,
            dtstart: this.event.end,
            until: this.periodEnd
          });
        }
        rule_week.all().forEach((date, index) => {
          let body = {
            title: this.event.title, comments: this.event.comment, creator_id: this.authService.getLoggedInUserId(),
            location: this.event.location,
            category_tipe: this.event.type.id, property_reference: (this.event.property !== null) ? this.event.property.reference : null,
            client_id: (this.event.client !== null) ? this.event.client.id : null, property_id: (this.event.property !== null) ? this.event.property.id : null,
            creation_date: new Date().toISOString().slice(0,10) + ' ' + new Date().toString().slice(16,24),
            start_date: date.toISOString().slice(0,10) + ' ' + date.toString().slice(16,24),
            end_date: (rule_week_end === null || rule_week_end === undefined) ? null : rule_week_end.all()[index].toISOString().slice(0,10) + ' ' + rule_week_end.all()[index].toString().slice(16,24),
            all_day: (this.tempAllDay ? 1 : 0), id_serie: maxId, id_aviso1: this.event.idAviso1,
            id_aviso2: this.event.idAviso2, id_aviso3: this.event.idAviso3,
            clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
            usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), id_oferta: this.event.id_oferta, id_cuenta: this.event.accountId,
            cuenta_tipo: this.event.accountType,
            idEmpresa: this.event.account.idEmpresa,
            recurrence: rule_week.toString()
          };
          eventsPeriod.push(body);
        });
        // Ordeno los events en función del dia de cada uno de ellos
        eventsPeriod.sort(function(a,b){return a['start_date'].localeCompare(b['start_date']);});
        this.endpointService.addNewCalendarPeriodActivities(eventsPeriod).subscribe(data=>{
          // He insertado todos los events en backend, ahora creare los CustomEvents para que se vea en FrontEnd
          // Los ids ya vienen ordenados
          for(let i = 0; i < eventsPeriod.length; ++i){
            let tempEvent = new CustomEvent(eventsPeriod[i].title, eventsPeriod[i].comments, data['response'][i], eventsPeriod[i].creator_id, new Date(eventsPeriod[i].start_date), new Date(eventsPeriod[i].end_date), this.event.idCalendarioGoogle, this.event.type, this.event.signLike,
            this.event.signNotify, this.event.signDNI, this.event.signImgURL, this.event.signObservation, this.tempAllDay, this.event.idSerie, eventsPeriod[i].id_aviso1, eventsPeriod[i].id_aviso2, eventsPeriod[i].id_aviso3, this.event.estado, this.event.can_motivo,
            this.event.properties, this.event.clients, this.event.users, this.event.industriales, this.event.encargos, [], eventsPeriod[i].id_oferta, this.event.nombreCreador, undefined, undefined, this.event.accountId, this.event.accountType, data['response'][0], eventsPeriod[i].location);
            
            tempEvent['account'] = this.event.account;
            tempEvent['actions'] = this.activitiesService.imageUserActive;
            tempEvent['categories'] = this.categoryTypes;
            tempEvent['accountId'] = this.idAccount;     
            arrayEventsPeriodic.push(tempEvent);
          }
        });
        break;
      case 'per_month':
        const rule_month = new RRule({
          freq: RRule.MONTHLY,
          interval: this.interval,
          dtstart: this.tempStart,
          until: this.periodEnd
        });

        let rule_month_end = null;
        if (this.event.end !== null && this.event.end !== undefined) {
          rule_month_end = new RRule({
            freq: RRule.MONTHLY,
            interval: this.interval,
            dtstart: this.event.end,
            until: this.periodEnd
          });
        }

        rule_month.all().forEach((date, index) => {
          let body = {
            title: this.event.title, comments: this.event.comment, creator_id: this.authService.getLoggedInUserId(),
            location: this.event.location,
            category_tipe: this.event.type.id, property_reference: (this.event.property !== null) ? this.event.property.reference : null,
            client_id: (this.event.client !== null) ? this.event.client.id : null, property_id: (this.event.property !== null) ? this.event.property.id : null,
            creation_date: new Date().toISOString().slice(0,10) + ' ' + new Date().toString().slice(16,24),
            start_date: date.toISOString().slice(0,10) + ' ' + date.toString().slice(16,24),
            end_date: (rule_month_end === null || rule_month_end === undefined) ? null : rule_month_end.all()[index].toISOString().slice(0,10) + ' ' + rule_month_end.all()[index].toString().slice(16,24),
            all_day: (this.tempAllDay ? 1 : 0), id_serie: maxId, id_aviso1: this.event.idAviso1,
            id_aviso2: this.event.idAviso2, id_aviso3: this.event.idAviso3,
            clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
            usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
            id_oferta: this.event.id_oferta, id_cuenta: this.event.accountId,
            cuenta_tipo: this.event.accountType,
            idEmpresa: this.event.account.idEmpresa,
            recurrence: rule_month.toString()
          };
          eventsPeriod.push(body);
        });
        // Ordeno los events en función del dia de cada uno de ellos
        eventsPeriod.sort(function(a,b){return a['start_date'].localeCompare(b['start_date']);});
        this.endpointService.addNewCalendarPeriodActivities(eventsPeriod).subscribe(data=>{
          // He insertado todos los events en backend, ahora creare los CustomEvents para que se vea en FrontEnd
          // Los ids ya vienen ordenados
          for(let i = 0; i < eventsPeriod.length; ++i){
            let tempEvent = new CustomEvent(eventsPeriod[i].title, eventsPeriod[i].comments, data['response'][i], eventsPeriod[i].creator_id, new Date(eventsPeriod[i].start_date), new Date(eventsPeriod[i].end_date), this.event.idCalendarioGoogle, this.event.type, this.event.signLike,
            this.event.signNotify, this.event.signDNI, this.event.signImgURL, this.event.signObservation, this.tempAllDay, this.event.idSerie, eventsPeriod[i].id_aviso1, eventsPeriod[i].id_aviso2, eventsPeriod[i].id_aviso3, this.event.estado, this.event.can_motivo,
            this.event.properties, this.event.clients, this.event.users, this.event.industriales, this.event.encargos, [], eventsPeriod[i].id_oferta, this.event.nombreCreador, undefined, undefined, this.event.accountId, this.event.accountType, data['response'][0], eventsPeriod[i].location);
            
            tempEvent['account'] = this.event.account;
            tempEvent['actions'] = this.activitiesService.imageUserActive;
            tempEvent['categories'] = this.categoryTypes;
            tempEvent['accountId'] = this.idAccount;       
            arrayEventsPeriodic.push(tempEvent);
          }
        });
        break;
      case 'per_year':
        const rule_year = new RRule({
          freq: RRule.YEARLY,
          interval: this.interval,
          dtstart: this.tempStart,
          until: this.periodEnd
        });

        let rule_year_end = null;
        if (this.event.end !== null && this.event.end !== undefined) {
          rule_year_end = new RRule({
            freq: RRule.YEARLY,
            interval: this.interval,
            dtstart: this.event.end,
            until: this.periodEnd
          });
        }

        rule_year.all().forEach((date, index) => {
          let body = {
            title: this.event.title, comments: this.event.comment, creator_id: this.authService.getLoggedInUserId(),
            location: this.event.location,
            category_tipe: this.event.type.id, property_reference: (this.event.property !== null) ? this.event.property.reference : null,
            client_id: (this.event.client !== null) ? this.event.client.id : null, property_id: (this.event.property !== null) ? this.event.property.id : null,
            creation_date: new Date().toISOString().slice(0,10) + ' ' + new Date().toString().slice(16,24),
            start_date: date.toISOString().slice(0,10) + ' ' + date.toString().slice(16,24),
            end_date: (rule_year_end === null || rule_year_end === undefined) ? null : rule_year_end.all()[index].toISOString().slice(0,10) + ' ' + rule_year_end.all()[index].toString().slice(16,24),
            all_day: (this.tempAllDay ? 1 : 0), id_serie: maxId, id_aviso1: this.event.idAviso1,
            id_aviso2: this.event.idAviso2, id_aviso3: this.event.idAviso3,
            clientes: (this.event.clients ? this.event.clients : []), viviendas: (this.event.properties ? this.event.properties : []),
            usuarios: (this.event.users ? this.event.users : []), encargos: (this.event.encargos ? this.event.encargos : []), industriales: (this.event.industriales ? this.event.industriales : []), 
            id_oferta: this.event.id_oferta, id_cuenta: this.event.accountId,
            cuenta_tipo: this.event.accountType,
            idEmpresa: this.event.account.idEmpresa,
            recurrence: rule_year.toString()
          };
          eventsPeriod.push(body);
        });
        // Ordeno los events en función del dia de cada uno de ellos
        eventsPeriod.sort(function(a,b){return a['start_date'].localeCompare(b['start_date']);});
        this.endpointService.addNewCalendarPeriodActivities(eventsPeriod).subscribe(data=>{
          // He insertado todos los events en backend, ahora creare los CustomEvents para que se vea en FrontEnd
          // Los ids ya vienen ordenados
          for(let i = 0; i < eventsPeriod.length; ++i){
            let tempEvent = new CustomEvent(eventsPeriod[i].title, eventsPeriod[i].comments, data['response'][i], eventsPeriod[i].creator_id, new Date(eventsPeriod[i].start_date), new Date(eventsPeriod[i].end_date), this.event.idCalendarioGoogle, this.event.type, this.event.signLike,
            this.event.signNotify, this.event.signDNI, this.event.signImgURL, this.event.signObservation, this.tempAllDay, this.event.idSerie, eventsPeriod[i].id_aviso1, eventsPeriod[i].id_aviso2, eventsPeriod[i].id_aviso3, this.event.estado, this.event.can_motivo,
            this.event.properties, this.event.clients, this.event.users, this.event.industriales, this.event.encargos, [], eventsPeriod[i].id_oferta, this.event.nombreCreador, undefined, undefined, this.event.accountId, this.event.accountType, data['response'][0], eventsPeriod[i].location);
                
            tempEvent['account'] = this.event.account;
            tempEvent['actions'] = this.activitiesService.imageUserActive;
            tempEvent['categories'] = this.categoryTypes;
            tempEvent['accountId'] = this.idAccount; 
            arrayEventsPeriodic.push(tempEvent);
          }
        });
        break;
    }
    this._bottomSheetRef.dismiss(arrayEventsPeriodic);
  }

  // openAddElementEvent es la funcion que llama al componente AddElementEventComponent y abre el dialog en funcion del type
  openAddElementEvent(account: any, type: number){
    // dataOptions sera un array donde en la posicion type - 1 estara la estructura a enviarle al dialog
    let dataOptions;
    switch(type){
      case 1:
        dataOptions = this.usersControl;
        break;
      case 2:
        dataOptions = this.offersControl;
        break;
      case 3:
        dataOptions = this.clientsControl;
        break;
      case 4:
        dataOptions = this.propertiesControl;
        break;
      case 5:
        dataOptions = this.industrialsControl;
        break;
      case 6:
        dataOptions = this.encargosControl;
        break;
    }
    
    let panelClass = ['table-dialog'];
    if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
    const dialogRef = this.dialog.open(AddElementEventComponent, {
      data: {data: dataOptions, type: type, subtype: 1, account: account},
      height: '65vh',
      panelClass: panelClass
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch(type){
          case 1:
            if(this.creatingEvent) {
              this.event.users = [];
              result.forEach(e => { this.event.users.push(e);});
              this.usersControl.setValue(this.event.users);
            } else {
              this.event.users = [];
              result.forEach(e => { this.event.users.push(e);});
              this.usersControl.setValue(this.event.users);
            }
            break;
            
          case 2:
            if(this.creatingEvent) {
              this.event.offer = null;
              this.event.offer = result;
              this.offersControl.setValue(this.event.offer);
            }
            else {
              this.event.offer = null;
              this.event.offer = result;
              this.offersControl.setValue(this.event.offer);
            }
            break;

          case 3:
            if(this.creatingEvent) {
              this.event.clients = [];
              result.forEach(e => { this.event.clients.push(e);});
              this.clientsControl.setValue(this.event.clients);
            } else {
              this.event.clients = [];
              result.forEach(e => { this.event.clients.push(e);});
              this.clientsControl.setValue(this.event.clients);
            }
            break;
          
          case 4:

            if(this.creatingEvent) {
              this.event.properties = [];
              result.forEach(e => { this.event.properties.push(e);});
              this.propertiesControl.setValue(this.event.properties);
            } else {
              this.event.properties = [];
              result.forEach(e => { this.event.properties.push(e);});
              this.propertiesControl.setValue(this.event.properties);
            }
            break;

          case 5:

            if(this.creatingEvent) {
              this.event.industriales = [];
              result.forEach(e => { this.event.industriales.push(e);});
              this.industrialsControl.setValue(this.event.industriales);
            } else {
              this.event.industriales = [];
              result.forEach(e => { this.event.industriales.push(e);});
              this.industrialsControl.setValue(this.event.industriales);
            }
            break;
          case 6:
            // En el caso de los encargos, hemos de añadir tambien el cliente / industrial asignado
            if(this.creatingEvent) {
              this.event.encargos = [];
              console.log('justo antes de petar', result, this.event.encargos);
              result.Encargos.forEach(e => { this.event.encargos.push(e);});
              this.encargosControl.setValue(this.event.encargos);
            } else {
              this.event.encargos = [];
              console.log('justo antes de petar', result, this.event.encargos);
              result.Encargos.forEach(e => { this.event.encargos.push(e);});
              this.encargosControl.setValue(this.event.encargos);
              // He de buscar si ya esta asignado o no
            }
            let found = false; let clientid;
            result['Industriales'].forEach(c => { clientid = c['id']; });
              this.event.industriales.forEach(i=>{found = (i.id == clientid)});
              if(!found) result.Industriales.forEach(e => { this.event.industriales.push(e);});
              this.industrialsControl.setValue(this.event.industriales);
              
              result['Clientes'].forEach(i => { clientid = i['id']; found = false;});
              this.event.clients.forEach(i=>{found = (i.id == clientid)});
              if(!found) result.Clientes.forEach(e => {this.event.clients.push(e);});
              this.clientsControl.setValue(this.event.clients);
            break;
        }
        this._changeDetectorRef.detectChanges();
      }
    });
  }


  openColorPickerDialog(type: EventType): void {
    let panelClass = [];
    if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
    const dialogRef = this.dialog.open(ColorPickerDialog, {
      width: 'auto',
      height: 'auto',
      data: type,
      panelClass: panelClass,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // type is null => add new category
      if (type == null && result != null) {
        const name = result['name'];
        const color = result['color'];
        const isPrivate = result['isPrivate'];
        const signable = result['signable'];
        const portalVisitCompatibility = result['portalVisitCompatibility'];
        const profileId = this.authService.getLoggedInUserId();
        this.endpointService.addNewCategoryType({ name: name, color: color,
          isPrivate: isPrivate, signable: signable, portalVisitCompatibility: portalVisitCompatibility}, profileId,  this.event.account.id, this.authService.returnActiveAccountType()).subscribe(data => {
          this.endpointService.getCategories(profileId, this.event.account.id,
            this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data_cat => {
            setCategories(data_cat);
            this._changeDetectorRef.detectChanges();
            this.snackBar.open('Categoría añadida', 'X', {
              duration: 6000,
              panelClass: ['lightgreen-snackbar']
            });
            this._changeDetectorRef.detectChanges();
          });
          this._changeDetectorRef.detectChanges();
        });
      } else if (type != null && result != null) {
        const name = result['name'];
        const color = result['color'];
        const isPrivate = result['isPrivate'];
        const signable = result['signable'];
        const portalVisitCompatibility = result['portalVisitCompatibility'];

        const typeId = type.id;
        const profileId = this.authService.getLoggedInUserId();
        this.endpointService.updateCategoryType({ name: name, color: color, isPrivate: isPrivate, signable: signable, portalVisitCompatibility: portalVisitCompatibility}, profileId, typeId).subscribe(data => { });
        this.endpointService.getCategories(profileId, this.authService.returnActiveAccountId(), this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data => { setCategories(data) });

      }
      //this.update_category.emit(true);
    });
  }

  onClickSign(event: CustomEvent): void {
    let panelClass = [];
    if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
    const dialogRef = this.dialog.open(SignActivityDialog, {
      width: 'auto',
      height: 'auto',
      panelClass: panelClass,
      data: {event: event }
    });
    dialogRef.afterClosed().subscribe(data => {
        if(data) {
          if(data === 2) {
            event.estado = 2;
            this.event.cssClass = 'customEventClass';
            this._changeDetectorRef.detectChanges();
          }
        }
    });
  }

  onClickVisualize(event: CustomEvent): void {
    let panelClass = [];
    if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
    const dialogRef = this.dialog.open(ViewSignDialog, {
      width: 'auto',
      height: 'auto',
      panelClass: panelClass,
      data: {event: event, staticClient: false, staticProperty: true, staticUser: false, staticOffer: false }
    });
  }

  onClickDeleteActivity(event: CustomEvent): void {
    if(event['idParentPeriodicity'] !== null){
      // Eliminamos un evento periodico, hay que darle las diferentes opciones a escoger y actuar en consecuencia
      let message = this.strings.DELETE_ACTIVITY_PERIOD_MESSAGE;
      let panelClass = [];
      if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: panelClass,
        data: {
          message: message,
          labelButton1: 'Cancelar',
          labelButton2: 'Eliminar solo este',
          labelButton3: 'Eliminar todos a partir de este'
        }
      });
      
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          if (data === 2) { // El usuario elimina solamente esta acitividad
            this.endpointService.deleteCalendarActivity(this.event.id).subscribe(data1 => {
            if (data1['errorCode'] === 0) {
              this.snackBar.open('Actividad eliminada correctamente', 'X', {
                duration: 6000,
                panelClass: ['lightgreen-snackbar']
              });
              this.allEvents = this.allEvents.filter(listEvent => listEvent.id !== event.id);
              this._bottomSheetRef.dismiss([{all: false, event: event}]);
              }
            });

          } else if(data == 3){ // El usuario elimina todas a partir de esta actividad
            this.endpointService.deletePeriodCalendarActivity(event).subscribe(data => {
              if (data['errorCode'] === 0) {
                this.snackBar.open('Actividades eliminadas correctamente', 'X', {
                  duration: 6000,
                  panelClass: ['lightgreen-snackbar']
                });
                this._bottomSheetRef.dismiss([{all: true, option: "delete", event: event}]);
              }
            });
          }
        }
      });
    } else {
      // Eliminamos un evento no periodico
      let message = this.strings.DELETE_ACTIVITY_DIALOG_MESSAGE;
      let panelClass = [];
      if(this.genericService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: panelClass,
        data: {
          message: message,
          labelButton1: 'Cancelar',
          labelButton2: 'Eliminar'
        }
      });
  
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          if (data === 2) { // L'usuari prem el botó 'Eliminar'
            this.endpointService.deleteCalendarActivity(this.event.id).subscribe(data1 => {
              if (data1['errorCode'] === 0) {
                this.snackBar.open('Actividad eliminada correctamente', 'X', {
                  duration: 6000,
                  panelClass: ['lightgreen-snackbar']
                });
                this.allEvents = this.allEvents.filter(listEvent => listEvent.id !== event.id);
                this._bottomSheetRef.dismiss(this.allEvents);
              }
            });
          }
        }
      });
    }
  }

  updatePeriodEnd($event: any) {
    this.periodEnd = $event;
    if(this.periodEnd) {
      this.periodEnd.setHours(23, 59);
    } else {
      this.periodEnd = null;
    }
    this.refresh.next();
  }

  cambioHoraStart($event: any) {
    // this.editEventForm.value.eventStartDate = $event;
    this.tempStart = $event;
  }

  cambioHoraEnd($event: any) {
    this.tempEnd = $event;
  }

  notification1TimeValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } | null => {
      /*
      if (control.value !== undefined && this.editEventForm.get('eventRecordatorio2').value !== undefined) {
        if(control.value.timing === this.editEventForm.get('eventRecordatorio2').value.timing) { return {'notification1Equals2': true}; }
      }
      if (control.value !== undefined && this.editEventForm.get('eventRecordatorio3').value !== undefined) {
        if(control.value.timing === this.editEventForm.get('eventRecordatorio3').value.timing) { return {'notification1Equals3': true}; }
      }
      */

      if (control.value === undefined) {
        this.editEventForm.get('eventRecordatorio2').setValue(undefined);
        this.editEventForm.get('eventRecordatorio3').setValue(undefined);
      }
      return null;
    };
  }

  notification2TimeValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } | null => {
      /*
      if (control.value !== undefined && this.editEventForm.get('eventRecordatorio1').value !== undefined) {
        if(control.value.timing === this.editEventForm.get('eventRecordatorio1').value.timing) { return {'notification2Equals1': true}; }
      }
      if (control.value !== undefined && this.editEventForm.get('eventRecordatorio3').value !== undefined) {
        if(control.value.timing === this.editEventForm.get('eventRecordatorio3').value.timing) { return {'notification2Equals3': true}; }
      }
      */
      if (control.value === undefined) {
        this.editEventForm.get('eventRecordatorio3').setValue(undefined);
      }
      return null;
    };
  }

  notification3TimeValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } | null => {
      /*
      if (control.value !== undefined && this.editEventForm.get('eventRecordatorio2').value !== undefined) {
        if(control.value.timing === this.editEventForm.get('eventRecordatorio2').value.timing) { return {'notification3Equals2': true}; }
      }
      if (control.value !== undefined && this.editEventForm.get('eventRecordatorio1').value !== undefined) {
        if(control.value.timing === this.editEventForm.get('eventRecordatorio1').value.timing) { return {'notification3Equals1': true}; }
      }
      */
      return null;
    };
  }

  stateValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } | null => {
      if (control.value !== 3) {
        this.editEventForm.get('eventCanMotivo').setErrors(null);
        this.editEventForm.updateValueAndValidity();
      }
      return null;
    };
  }

  // validateIfChecked(): ValidatorFn {
  //   return (control: FormControl): { [key: string]: boolean } | null => {
  //     const eventIsPeriod_ = control.value;
  //     const eventFrecuency_ = this.editEventForm.get('eventFrecuency');
  //     const eventEndPeriod_ = this.editEventForm.get('eventEndPeriod');
  //     const eventPeriod_ = this.editEventForm.get('eventPeriod');
  //     if (eventIsPeriod_ && eventPeriod_ && eventFrecuency_) {
  //       return {'required': true};
  //     } else if (eventIsPeriod_ && eventPeriod_ && eventEndPeriod_) {
  //       return {'required': true};
  //     } else if (eventIsPeriod_ && eventFrecuency_ && eventEndPeriod_) {
  //       return {'required': true};
  //     }
  //     return null;
  //   };
  // }

  canMotivoValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } | null => {
      if (this.event && this.event.estado &&  this.event.estado === 3 && (control.value === null || control.value === undefined || control.value === '')) {
        return {'required': true};
      }
      return null;
    };
  }

  accountValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } | null => {
      return null;
    };
  }

  getEditEventErrorMessage() {
    if (this.editEventForm.get('eventCanMotivo').hasError('required')) {
      return 'Campo obligatorio';
    }
    return 'error';
  }

  setAccount(account: any) {
    if (account) {
      //RE_VISAAAAAR!!
      // Gabri preguntar
      let tipo = account.type_account;
      this.resetControls();
      this.idAccount = account.id;
      this.accountType = tipo;
      this.tempAccountId = account.id;
      this.tempAccountType = tipo;
      if(this.creatingEvent) {
        this.event.clients = [];
        this.event.users = [];
        this.event.offer = [];
        this.event.properties = [];
      } else {
        this.event.clients = [];
        this.event.users = [];
        this.event.offer = [];
        this.event.properties = [];
      }
      setCategories(this.activitiesService.categoriesByAccount[account.nombre].data);
    }
  }

  public resetControls() {
    this.clientsControl.reset();
    this.usersControl.reset();
    this.propertiesControl.reset();
    this.offersControl.reset();
    this.industrialsControl.reset();
    this.encargosControl.reset();
  }

  canChangeAccount() {
    return ((this.propertiesControl.value !== null && this.propertiesControl.value?.length > 0) ||
    (this.clientsControl.value !== null && this.clientsControl.value?.length > 0) ||
    (this.offersControl.value !== null && this.offersControl.value?.length > 0) ||
    (this.usersControl.value !== null && this.usersControl.value?.length > 0) ||
    (this.industrialsControl.value !== null && this.industrialsControl.value?.length > 0) ||
    (this.encargosControl.value !== null && this.encargosControl.value?.length > 0));
  }

  addLocation(from) {
    // Add property location
    if(from == 1) {
      this.endpointService.getPropertyLocation(this.propertiesControl.value[0]['id']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.tempLocation = data['response'][0]['direccion'];
        } else {
          this.snackBar.open('Parece que este inmueble no tiene la dirección indicada', 'X', {
            duration: 6000,
            panelClass: ['lightred-snackbar']
          });
        }
      });
    }
    // Add client location
    else {
      this.endpointService.getClientLocation(this.clientsControl.value[0]['id']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.tempLocation = data['response'][0]['direccion'];
        } else {
          this.snackBar.open('Parece que este cliente no tiene la dirección indicada', 'X', {
            duration: 6000,
            panelClass: ['lightred-snackbar']
          });
        }
      });
    }
  }

  loadInfoElement(from, value = 0) {
    let idModule = 0;
    let params = [];
    let bd_table = "";
    
    if(from == 1) {
      idModule = 17; // Inmueble
      bd_table = "vivienda";
    } else if(from == 2) {
      idModule = 2681; // Encargo
      bd_table = "encargo";
    } else if(from == 3) {
      idModule = 674; // Cliente
      bd_table = "clientes";
    } else if(from == 4) {
      idModule = 96; // Oferta
      bd_table = "ofertas";
    } else if(from == 5) {
      idModule = 674; // Industrial
      bd_table = "clientes";
    }

    params[0] = {bd_field: "id", bd_table: bd_table, id_db: 1, value: value};

    let x = {id_function: 20, internal_routing_id_functional_area: idModule, customParams: params};
    this.functionsService.getFunction(x);
  }
}
