import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { FormControl } from "@angular/forms";
import { EndpointService } from "app/services/endpoint/endpoint.service";
import {
  AngularEditorConfig,
  AngularEditorService,
} from "@kolkov/angular-editor";
import { Observable } from "rxjs/internal/Observable";
import { map, startWith } from "rxjs/internal/operators";

@Component({
  selector: "app-chip-creator-custom",
  templateUrl: "./chip-creator-custom.component.html",
  styleUrls: ["./chip-creator-custom.component.css"],
})
export class ChipCreatorCustomComponent implements OnInit {
  chipLabelControl = new FormControl("", []);
  chipList: any;
  toggled: boolean = false;
  @Input() htmlContent: any = "";
  @Input() label: any = "";
  @Input() showToolBar: any = true;
  @Input() showIcons: any = true;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    showToolbar: this.showToolBar,
    sanitize: false,
    defaultParagraphSeparator: "",
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat'
      ]
    ]
  };
  @ViewChild("editor") editor: any;
  public showSelector = false;
  filteredOptions: Observable<any>;
  @Output() newChipEvent = new EventEmitter();
  constructor(
    public endpointService: EndpointService,
    public htmlEditorService: AngularEditorService
  ) {}

  ngOnInit(): void {
    this.config.showToolbar = this.showToolBar;
    this.endpointService.getChipsInfo().subscribe({
      next: (data: any) => {
        this.chipList = data.response;
        this.filteredOptions = this.chipLabelControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            const name = typeof value === "string" ? value : value?.label;
            return name ? this._filter(name as string) : this.chipList.slice();
          })
        );
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  displayFn(user: any): string {
    return user && user.label ? user.label : "";
  }

  private _filter(value: any) {
    const filterValue = value.toLowerCase();

    return this.chipList.filter((option) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  addChip() {
    this.showSelector = !this.showSelector;
  }

  addText(id: any) {
    let label = "";
    let nombre_chip = "";
    for (let key in this.chipList) {
      if (this.chipList[key]["id"] == id) {
        label = this.chipList[key]["label"];
        nombre_chip = this.chipList[key]["nombre_chip"];
        break;
      }
    }
    this.editor.focus();
    this.editor.editorService.restoreSelection();
    this.editor.editorService.insertHtml(
      "<mark contenteditable='false'><span style='border: 1px solid #fff; padding: 3px 0.5em; background-color: #42678f; color: #fff; border-radius: 6px; white-space: nowrap;'>" + 
      label + "<span style='display: none;'>--" + nombre_chip + "--</span></span></mark>&nbsp;"
    );
    this.chipLabelControl.setValue("");
    this.showSelector = false;
    this.newChipEvent.emit(this.htmlContent);
  }

  sendHtmlCode(event) {
    this.newChipEvent.emit(event);
  }

  handleSelection(event) {
    let emoji = "" + event.char;
    console.log(event.char);
    this.editor.focus();
    this.editor.editorService.restoreSelection();
    this.editor.editorService.insertHtml(emoji);
    this.newChipEvent.emit(this.htmlContent);
  }
}
