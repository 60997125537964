<div class="knowledge">
  <mat-sidenav-container class="example-container" [hasBackdrop]="true">
    <mat-sidenav #sidenav [mode]="over" fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="80">
      <div class="notas-p">
        <div class="top-pdf">
          <h6 class="t-pdf">{{text_pdf}}</h6>
          <mat-icon (click)="sidenav.toggle()" class="removeit">close</mat-icon>
        </div>
        <iframe *ngIf="url_item_intern" [src]="url_item_intern | safe" class="pdf"></iframe>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="custom-mat-drawer-content">
      <div style="padding: 0 2vw;">
<!--        <span class="knowledge-title">Knowledge Place</span>-->
        <div *ngFor="let d of info; let i = index" style="margin-top: 5em;">
          <div class="tittlees" fxLayout="row wrap">
            <span></span>
            <h1>{{tipos[i].name}}</h1>
          </div>
          <p-carousel [value]="d.all" [numVisible]="5" [numScroll]="1" [circular]="false" [page]="0" [responsiveOptions]="this.responsiveOptions">
            <ng-template let-card pTemplate="item">
              <div *ngIf="tipos[i].id === 1 && card.id !== -1" (click)="toggleDrawer(i, card.subtitulo, card.url_item_intern)" class="item">
                <img [src]="card.imagen" class="img-carroussel">
                <div class="img-carroussel text-hov">
                  <h1>{{card.name}}</h1>
                  <span>{{card.descripcion}}</span>
                </div>
              </div>

              <app-knowledge-video class="item" *ngIf="tipos[i].id === 2 && card.id !== -1" [card]="card"></app-knowledge-video>

              <div *ngIf="tipos[i].id === 3 && card.id !== -1" class="item">
                <a href="{{card.url_item_extern}}" target="_blank">
                  <img [src]="card.imagen" class="img-carroussel">
                </a>
              </div>

              <div *ngIf="tipos[i].id === 4 && card.id !== -1" class="item">
                <img [src]="card.imagen" class="img-carroussel">
              </div>

              <div *ngIf="tipos[i].id === 5 && card.id !== -1" class="item">
                <img [src]="card.imagen" class="img-carroussel">
              </div>

              <div *ngIf="card.id === -1" class="item">
                <img src="{{card.src}}" class="img-carroussel">
              </div>

            </ng-template>
          </p-carousel>

<!--          <p-carousel *ngIf="d.all.length === 0" [value]="data" [numVisible]="5" [numScroll]="1" [circular]="false" [page]="0" [responsiveOptions]="this.responsiveOptions">-->
<!--            <ng-template let-item pTemplate="item">-->
<!--              <img src="{{item.src}}" class="img-carroussel">-->
<!--            </ng-template>-->

<!--          </p-carousel>-->
<!--          <div *ngIf="d.all.length === 0">
            <img src="assets/img/ComingSoon.png">
          </div>-->
          <!--        <app-knowledge-slide [element]="d" [index]="i"></app-knowledge-slide>-->
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

