<!-- Modal to add a new DOC-->
<div class="modal" id="uploadFileModal" tabindex="-1" role="dialog" aria-labelledby="saveDataLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="saveDataLabel">Añadir archivo</h2>
        <button type="button" class="close" id='cancel' data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #uploadForm="ngForm" #uploaderForm>
          <label for="filename">Nombre del archivo</label>
          <input name="filename" type="text" class="form-control" id="filename" [(ngModel)]="filename" placeholder="">

          <input type="file" name="uploader" id="uploader" ngControl="uploader" placeholder="Upload file"
                 accept=".pdf,.doc,.dot,.xml,.docx, .docm, .dotx, .dotm, .doc, .wpd, .txt, .odt, .ott, .ods, .ots, .xls, .xlw, .xlsx, .rtf, .csv, .odp, .odg, .pptx, .pptm, .ppt, .pps, .bmp, .jpg, .jpeg, .gif, .png, .svg, .psd, .pdf">
        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" id='cancel' onclick=''>{{strings.MODAL_CHANGES_CANCEL}}</button> -->
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id='discard' (click)="closeUploadFile()">
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" id='save' (click)="fileChange($event)">
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>
