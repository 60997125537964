<mat-card>
  <mat-card-header>
    <div mat-card-avatar></div>
    <mat-card-title>{{moduloReceived['nombre']}}</mat-card-title>
    <mat-card-subtitle>{{moduloReceived['subtitulo']}}</mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image *ngIf="moduloReceived['imagen']" [src]="sanitizer.bypassSecurityTrustResourceUrl(moduloReceived['imagen'])">
  <iframe *ngIf="!moduloReceived['imagen'] && moduloReceived['video']" width="420" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(moduloReceived['video'])">
  </iframe>
  <mat-card-content>
    <p>
      <span *ngIf="isOferta">Precio: {{moduloReceived['precio']}} <br></span>
      <!-- Fecha_inicio: {{mouduloReceived['fecha_inicio']}} <br>
      Fecha_fin: {{mouduloReceived['fecha_fin']}} <br>
      Descuento: {{mouduloReceived['descuento']}} <br>
      Descuento %: {{mouduloReceived['descuento_%']}} <br> -->
    </p>
    <p>
      {{moduloReceived['descripcion']}}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="isOferta" mat-button (click)="clickAddModulo()">Añadir módulo</button>
    <button *ngIf="!isOferta" mat-button (click)="eliminarModulo()">Eliminar</button>
    <button *ngIf="!isOferta" mat-button (click)="configurarModulo()">Configurar</button>
    <button *ngIf="!isOferta" mat-button (click)="ampliarModulo()">Ampliar</button>
  </mat-card-actions>
</mat-card>
