import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FunctionsService } from "app/services/functions/functions.service";
import { EndpointService } from "app/services/endpoint/endpoint.service";
import { TemplateService } from "app/services/generic/custom/template-element";
import { GenericService } from "app/services/generic/generic.service";
import * as _ from 'lodash';

@Component({
  selector: "app-events-template-custom-element",
  templateUrl: "./events-template-custom-element.component.html",
  styleUrls: ["./events-template-custom-element.component.css"],
})
export class EventsTemplateCustomElementComponent implements OnInit {
  @Input() singleElement;
  @Input() idPlantilla: number;

  @Input() arrayTemplateElements;

  @Output() emitFromChildChild = new EventEmitter<number>();
  @Output() emitFromChildChildElement = new EventEmitter<object>();

  constructor(
    public functionsService: FunctionsService,
    public endpointService: EndpointService,
    public templateService: TemplateService,
    public genericService: GenericService
  ) { }

  ngOnInit(): void { }

  deleteElement(element) {
    element["borrado"] = 1;
    element["mode_type"] = "delete";
    this.templateService.saveReuse = false;
    this.templateService.selectReuse = false;
    this.templateService.showResult = false;
    this.templateService.currentElement = undefined;
    this.templateService.valueEmittedFromChild = 0;
    this.templateService.showGridTypes = 0;

    if (
      this.templateService.currentRecordIndex !==
      this.templateService.arrayElementsPlantillaRecord.length - 1
    ) {
      this.templateService.arrayElementsPlantillaRecord.length =
        this.templateService.currentRecordIndex;
    }
    this.templateService.currentRecordIndex =
      this.templateService.arrayElementsPlantillaRecord.push(
        JSON.parse(JSON.stringify(this.templateService.arrayElementsPlantilla))
      ) - 1;

    console.log(
      this.templateService.arrayElementsPlantilla,
      "arrayElementsPlantilla"
    );
  }

  editElement(element) {
    console.log(element, "Element");
    this.templateService.saveReuse = false;
    this.templateService.selectReuse = false;
    this.templateService.showResult = false;
    this.templateService.valueEmittedFromChild = element["id_tipo"];
    this.templateService.showGridTypes = 0;
    this.templateService.currentElement = element;

    if (
      this.templateService.currentRecordIndex !==
      this.templateService.arrayElementsPlantillaRecord.length - 1
    ) {
      this.templateService.arrayElementsPlantillaRecord.length =
        this.templateService.currentRecordIndex;
    }
    this.templateService.currentRecordIndex =
      this.templateService.arrayElementsPlantillaRecord.push(
        JSON.parse(JSON.stringify(this.templateService.arrayElementsPlantilla))
      ) - 1;
  }

  preSaveElementReuse(element) {
    this.templateService.saveReuse = true;
    this.templateService.selectReuse = false;
    this.templateService.showResult = false;
    // this.templateService.currentElement = undefined;
    this.templateService.currentElement = element;
  }

  copyElement(element) {
    this.templateService.arrayCopyElement = _.cloneDeep(element);
    console.log(this.templateService.arrayCopyElement);
  }

  moveElementUpDown(element, direction, arrayElementsLevel) {
    let founded = false;
    if (direction == "up" || direction == "down") {
      for (let i in arrayElementsLevel) {
        if (arrayElementsLevel[i]["idTmp"] == element["idTmp"]) {
          founded = true;
          if (direction == "up") {
            console.log("Going up");
            if (arrayElementsLevel[+i - 1]) {
              // Change items
              let item = arrayElementsLevel[+i - 1];
              arrayElementsLevel[+i - 1] = arrayElementsLevel[i];
              arrayElementsLevel[i] = item;
              
              // Change order
              this.templateService.orderChange();
              console.log(this.templateService.arrayElementsPlantilla), "biiim up";
              break;
            }
          } else if (direction == "down") {
            console.log("Going Down");
            if (arrayElementsLevel[+i + 1]) {              
              // Change items
              let item = arrayElementsLevel[+i + 1];
              arrayElementsLevel[+i + 1] = arrayElementsLevel[i];
              arrayElementsLevel[i] = item;
              
              // Change order
              this.templateService.orderChange();
              console.log(this.templateService.arrayElementsPlantilla), "biiim down";
              break;
            }
          }
          if (this.templateService.currentRecordIndex !== this.templateService.arrayElementsPlantillaRecord.length - 1) {
            this.templateService.arrayElementsPlantillaRecord.length = this.templateService.currentRecordIndex;
          }
          this.templateService.currentRecordIndex = this.templateService.arrayElementsPlantillaRecord.push(JSON.parse(JSON.stringify(this.templateService.arrayElementsPlantilla))) - 1;
        }
        if(!founded && arrayElementsLevel[i]["children"] && arrayElementsLevel[i]["children"].length && arrayElementsLevel[i]["children"].length > 0) {
          this.moveElementUpDown(element, direction, arrayElementsLevel[i]["children"]);
        }
      }
    }
  }

  htmlResultSanitized() {
    let result = _.cloneDeep(this.singleElement.html_result);
    if(result.includes('href="')) {
      result = result.replace(/<mark[^>]*>.*?<\/mark>/g, '');
      result = result.replace(/href="[^"]*"/, '');
    }
    if(result.includes('src="')) {
      result = result.replace(/<mark[^>]*>.*?<\/mark>/g, '');
    }
    return result;
  }

  disableLink(event: Event) {
    event.preventDefault();
  }
}
