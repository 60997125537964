import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {PrivilegiesControlService} from "../services/privilegies-control/privilegies-control.service";
import {EndpointService} from "../services/endpoint/endpoint.service";
import {Strings} from "../../strings/strings";
import {StaticTablesService} from "../services/static-tables.service";
import {AuthService} from "../services/auth/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {AddMovinCoinComponent} from "./subcomponent/add-movin-coin.component";
import {MessageDialogComponent} from "../utils/message-dialog/message-dialog.component";
import {StripePaymentComponent} from "../stripe/stripe-payment/stripe-payment.component";

export interface PeriodicElement {
  nombre: string;
  precio: number;

}

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css']
})


export class BillComponent implements OnInit {

  @Input() idInmo;
  @Input() bodyInmo;
  @Input() bodyBill;

  @Output() loadInmo = new EventEmitter();

  @ViewChild('stripePayment') stripePayment: StripePaymentComponent;

  //public price: number;
  public dto: number;
  public inmoNumber: number;
  public finalPrice: number;
  public finalIva: number;
  public idPlan: number;

  public planesArray = new Array();
  public planesUsedArray = new Array();
  public planesBoughtArray = new Array();
  public actual = new Array();
  public totalMovin: number;
  public subscribeActual: number;
  public strings = Strings.INMO;


  headMovins = ['nmovin', 'price', 'iva', 'dto'];
  dataSource  = new MatTableDataSource<PeriodicElement>(this.planesArray);

  headBought = ['date', 'buyer' , 'nmovin', 'price'];
  dataSourceBought  = new MatTableDataSource<PeriodicElement>(this.planesBoughtArray);

  headUsed = ['date', 'user', 'nmovinSpent', 'concept'];
  dataSourceUsed  = new MatTableDataSource<PeriodicElement>(this.planesUsedArray);

  constructor(public privilegiesControlService: PrivilegiesControlService,
              public endpointService: EndpointService,
              public staticTablesService: StaticTablesService,
              public authService: AuthService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog) { }

  ngOnInit(): void {



    /*this.endpointService.getPlanesMovin().subscribe(data => {
      this.planesArray = data['response'];
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.planesArray);
    });

    this.endpointService.getPlanesComprados(this.idInmo).subscribe( data =>{

      this.planesBoughtArray = data['response'].allMovins;
      //if(data['response'].actual) this.actual = data['response'].actual;
      //this.precioActual = this.actual[0].precio;
      this.dataSourceBought = new MatTableDataSource<PeriodicElement>(this.planesBoughtArray);

    });*/

    this.actualizar();

    this.endpointService.getPlanesUsados(this.idInmo).subscribe( data =>{
      this.planesUsedArray = data['response'];
      this.dataSourceUsed = new MatTableDataSource<PeriodicElement>(this.planesUsedArray);
    });

  }

  /*calcular(n){
    let trobat = false;
    let i = 0;

        while(!trobat && i < this.planesArray.length){
          if(n <= this.planesArray[i].num_max){
            trobat = true;
          }else{
            i++;
          }
        }

        if(trobat){
          this.price = this.planesArray[0].precio * n;
          this.dto = this.planesArray[i].dto_aplicado;
          this.finalPrice = this.planesArray[i].precio * n;
          this.idPlan = this.planesArray[i].id;
        }


  }*/

  /*preventInput(event){
    let value=this.inmoNumber;
    if (value > this.planesArray[this.planesArray.length-1].num_max){
      event.preventDefault();
      // tslint:disable-next-line:radix
      this.inmoNumber = parseInt(value.toString().substring(0,7));
      this.inmoNumber = this.planesArray[this.planesArray.length-1].num_max;
      this.price = this.planesArray[0].precio * this.planesArray[this.planesArray.length-1].num_max;
      this.dto = this.planesArray[this.planesArray.length-1].dto_aplicado;
      this.finalPrice = this.planesArray[this.planesArray.length-1].precio * this.planesArray[this.planesArray.length-1].num_max;
      this.idPlan = this.planesArray[this.planesArray.length-1].id;
    }
  }*/

  buy(n: number){

      this.stripePayment.pay(this.finalIva, this.inmoNumber, n);

      /*if(this.bodyInmo.cif && this.bodyInmo.payDirection  && this.bodyInmo.payName && this.bodyInmo.payEmail && this.bodyInmo.payCity && this.bodyInmo.payCountry && this.bodyInmo.payPostalCode){
        switch (this.bodyInmo.payType) {
          case 0:
            if(this.bodyBill.titular && this.bodyBill.iban && this.bodyBill.bic) this.save(n);
            else{
              this.snackBar.open('Debe rellenar los datos de facturación', 'X', {
                duration: 4000, panelClass: ['red-snackbar']
              });
            }
            break;
          case 1:
            if(this.bodyBill.propietario && this.bodyBill.codeCredit && this.bodyBill.segurityCode && this.bodyBill.month && this.bodyBill.year) this.save(n);
            else {
              this.snackBar.open('Debe rellenar los datos de facturación', 'X', {
                duration: 4000, panelClass: ['red-snackbar']
              });
            }
            break;
        }
      }else{
        this.snackBar.open('Debe rellenar los datos de facturación', 'X', {
          duration: 4000, panelClass: ['red-snackbar']
        });
      }*/
  }

  save(n: object){
    if(n['error'] == 0) {
      this.endpointService.setPlanesMovin(this.idInmo, this.authService.userId, this.inmoNumber, this.finalPrice,
        this.idPlan, this.bodyInmo, this.bodyBill, n['suscription'], this.dto, n['paid']).subscribe(data=>{

        if (data['errorCode'] === 0) {
          if(this.bodyInmo.payType === 0){

            this.bodyInmo.idBank = data['response'].newBill;

          }else if(this.bodyInmo.payType === 1){

            this.bodyInmo.idCard = data['response'].newBill;

          }

          this.actualizar();
          //this.loadInmo.emit();
          //sessionStorage.setItem('selectedTab', '2');
          if(n['paid']) {
            this.snackBar.open('¡Gracias por su compra!', 'X', {
              duration: 4000, panelClass: ['green-snackbar']
            });
          } else {
            this.snackBar.open('Su plan se ha actualizado correctamente', 'X', {
              duration: 4000, panelClass: ['green-snackbar']
            });
          }


        }

      });
    } else {
      this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    }

  }

  subscribir(n: number){
    const dialogRef = this.dialog.open(AddMovinCoinComponent,{
      width: 'auto',
      height: 'auto',
      data: n
    });

    dialogRef.afterClosed().subscribe( info=> {
      //this.otherUsersArray.push(newUser[0]);
      //this.dataSourceUsers = new MatTableDataSource<PeriodicElement>(this.otherUsersArray);

      if (info){
        this.inmoNumber = info.movin;
        this.finalPrice = info.final;
        this.idPlan = info.plan;
        this.dto = info.descuento;
        this.finalIva = info.finalIva;

        this.buy(n);
      }

    });

  }

  cancelar(){

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '40vw !important',
      height: 'auto',
      data: {
        message: '¿Seguro que quiere eliminar tu plan actual? Si te quedas sin MOVINS, se limitarán temporalmente las posibilidades que estos suponen hasta que adquieras más.',
        labelButton1: 'Mantener plan',
        labelButton2: 'Cancelar plan'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {
        this.endpointService.unsubscribeMovin(this.idInmo).subscribe( data =>{
          this.actualizar();
        });
      }

    });

  }

  actualizar(){
    this.endpointService.getPlanesMovin().subscribe(data => {
      this.planesArray = data['response'];
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.planesArray);
    });

    this.endpointService.getPlanesComprados(this.idInmo).subscribe( data =>{

      this.planesBoughtArray = data['response'].allMovins;

      this.actual = data['response'].actual;

      if(data['response'].actual.length > 0) {
        this.subscribeActual = this.actual[0].num_comprados;
      }
      this.dataSourceBought = new MatTableDataSource<PeriodicElement>(this.planesBoughtArray);
    });

    this.endpointService.totalMovinCoin(this.idInmo).subscribe(t=>{
      this.totalMovin = t['response'][0].total;
    });
  }

}
