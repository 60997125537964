<div style="width: 100%; height: 100%;" fxLayout="row">
  <div fxFlex="30">
    <!--<span>Módulo seleccionado</span>-->
    <ng-template let-card *ngIf="moduloInfo.id !== -1" pTemplate="item">
      <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
        <div class="main-container" fxFlex="100" fxLayout="row wrap">
          <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start" >
            <span class="titulo-modulo" fxFlex="100">{{card.nombre}}</span>
            <span fxFlex="100" *ngIf="card.id_product_type">(Producto)</span>
            <span fxFlex="100" *ngIf="!card.id_product_type">(Widget)</span>
            <div class="price" fxFlex="100" fxLayout="row wrap">
              <span fxFlex="100">{{card.precio_compra}} MOVS</span>
              <span fxFlex="100" *ngIf="card.estate" style="margin-top: 1em; text-transform: uppercase; color: #42678f;">Renovación {{ card.fecha_desactivacion }}</span>
              <span fxFlex="100" *ngIf="!card.estate" style="margin-top: 1em; text-transform: uppercase; color: #882334;">Caducidad {{ card.fecha_desactivacion }}</span>
            </div>
          </div>
          <div fxFlex="50" class="right-part">
            <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
            <iframe *ngIf="!card.imagen && card.video" width="420" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.video)"></iframe>
          </div>
        </div>

        <!-- Hover -->
        <div class="text-modulo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
          <span fxFlex="100" class="description-modulo">{{card.descripcion}}</span>
        </div>

        <button class="button-registry" fxFlex="100" *ngIf="moduloInfo.video">Ver vídeo</button>
      </div>

      <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
        <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
      </div>
    </ng-template>
  </div>
  <div *ngIf="moduloInfo.isDependent && dependenciasLoaded" fxFlex="70">
    <p-carousel [value]="dependenciasModulo" [numVisible]="1" [numScroll]="1" [circular]="false" [page]="0">
      <ng-template let-card *ngIf="moduloInfo.id !== -1" pTemplate="item">
          <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
            <div class="main-container" fxFlex="100" fxLayout="row wrap">
              <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start" >
                <span class="titulo-modulo" fxFlex="100">{{card.nombre}}</span>
                <span fxFlex="100" *ngIf="card.id_product_type">(Producto)</span>
                <span fxFlex="100" *ngIf="!card.id_product_type">(Widget)</span>
                <div class="price" fxFlex="100" fxLayout="row wrap">
                  <span fxFlex="100">{{card.precio_compra}} MOVS</span>
                  <span fxFlex="100" *ngIf="card.estate" style="margin-top: 1em; text-transform: uppercase; color: #42678f;">Renovación {{ card.fecha_desactivacion }}</span>
                  <span fxFlex="100" *ngIf="!card.estate" style="margin-top: 1em; text-transform: uppercase; color: #882334;">Caducidad {{ card.fecha_desactivacion }}</span>
                </div>
              </div>
              <div fxFlex="50" class="right-part">
                <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
                <iframe *ngIf="!card.imagen && card.video" width="420" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.video)"></iframe>
              </div>
            </div>

            <!-- Hover -->
            <div class="text-modulo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
              <span fxFlex="100" class="description-modulo">{{card.descripcion}}</span>
            </div>

            <button class="button-registry" fxFlex="100" *ngIf="moduloInfo.video">Ver vídeo</button>
          </div>

          <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
            <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
          </div>
        </ng-template>
    </p-carousel>

    <button *ngIf="!dependenciasAdded" class="button-general" (click)="addModulosdependientes()">Añadir módulos dependientes</button>
    <span *ngIf="dependenciasAdded">Módulos dependientes añadidos</span>
  </div>
</div>
