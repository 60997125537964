<mat-tab-group mat-align-tabs="start" [(selectedIndex)]="structure[0]['tabActive']" (selectedTabChange)="onTabChange($event)" *ngIf="loaded">
  <ng-container *ngFor="let e of structure | keyvalue : genericService.returnZero">
    <mat-tab [id]="structure[e.key]['creatingMode'] ? structure[e.key]['idTmp'] : structure[e.key]['id_functional_area']"
    *ngIf="structure[e.key] && structure[e.key]['child'] && structure[e.key]['id_functional_type'] == 4 && genericService.evaluateIfs(structure[e.key]['id_condition'], structure[e.key]['id_functional_area'], structure[e.key]) && structure[e.key]['id_functional_status_general'] !== 2 && genericService.checkChildActive(structure[e.key])">
      <ng-template mat-tab-label>
        <span 
              [fxFlex]="structure[e.key]['width_general']"
              [fxFlex.xs]="structure[e.key]['width_xs'] != 0 ? structure[e.key]['width_xs'] : structure[e.key]['width_general']"
              [fxFlex.sm]="structure[e.key]['width_s'] != 0 ? structure[e.key]['width_s'] : structure[e.key]['width_general']"
              [fxFlex.md]="structure[e.key]['width_m'] != 0 ? structure[e.key]['width_m'] : structure[e.key]['width_general']"
              [fxFlex.lg]="structure[e.key]['width_l'] != 0 ? structure[e.key]['width_l'] : structure[e.key]['width_general']"
              [fxFlex.xl]="structure[e.key]['width_xl'] != 0 ? structure[e.key]['width_xl'] : structure[e.key]['width_general']"

              [fxHide]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || structure[e.key]['hide'] == 1"
              [fxHide.xs]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_xs']=='true' && structure[e.key]['hide_xs'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_xs'] == 1"
              [fxHide.sm]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_s']=='true' && structure[e.key]['hide_s'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_s'] == 1"
              [fxHide.md]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_m']=='true' && structure[e.key]['hide_m'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_m'] == 1"
              [fxHide.lg]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_l']=='true' && structure[e.key]['hide_l'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_l'] == 1"
              [fxHide.xl]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_xl']=='true' && structure[e.key]['hide_xl'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_xl'] == 1"

              [ngClass]="structure[e.key]['id_functional_status_mkt'] != 1 ? structure[e.key]['class_custom']+ ' ' + 'mkt-no-available' : structure[e.key]['class_custom']"
              [ngStyle]="structure[e.key]['styleParsed']['style']"
              [matTooltip]="structure[e.key]['tooltip']"
              [id]="structure[e.key]['creatingMode'] ? structure[e.key]['idTmp'] : structure[e.key]['id_functional_area']">
          <mat-icon *ngIf="structure[e.key]['icon'] && structure[e.key]['icon'] != ''" class="{{structure[e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[e.key]['icon'] }}</mat-icon>
          <span [innerHTML]="structure[e.key]['label'] | safeHtml"></span>
        </span>
      </ng-template>
      <ng-container *ngIf="structure[e.key]['child'] && structure[e.key]['tabActiveNow']">
        <app-generic [structure]="structure[e.key]"></app-generic>
      </ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>
