import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Strings} from 'strings/strings';
import {EndpointService} from 'app/services/endpoint/endpoint.service';
import {VariableMatching} from 'app/utils/VariableMatching';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'target-list-manager',
  templateUrl: './targetListManager.component.html',
  providers: [],
  styleUrls: ['./targetListManager.component.css']
})
export class TargetListManagerComponent implements OnInit {

  @Input() title: string;
  @Input() description = '';
  @Input() acceptButtonText = 'Aceptar';
  @Input() declineButtonText = 'Declinar';
  @Input() id = 'targetListManager';
  @Input() idFilter = this.id + 'Filter';
  @Input() targetList = [];

  @Output() onDecline = new EventEmitter();

  public newListName = '';
  public newListTargetType = null;
  public newListTarget = [];
  public emails = [];
  public currentEmailsList = [];
  public targetType;
  public edit = false;
  public listId: number;

  // Filter variables
  public name = null;
  public active = -1;
  public clientInmoTypes = [];
  public clientGroupTypes = [];
  public clientType = -1;
  public clientGroupType: number = -1;
  public loaded = false;
  public stringsMultiselectorOptions = Strings.MULTISELECTORVALUES;
  public addNewList = false;

  public dataSource: MatTableDataSource<any>;
  public inputFilter = '';

  public inmoId: any;
  public userId: any;
  public isGroup4DMovin: boolean;
  public type: number;
  // @Inject(MAT_DIALOG_DATA) public data: { id: number, nombre: string, id_tipo_destinatario: number }[];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['operations', 'nombre'];

  constructor(private endpointService: EndpointService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _dialogRef: MatDialogRef<TargetListManagerComponent>) {
                this.targetList = data.targetList;
                this.inmoId = data.inmoId;
                this.userId = data.userId;
                this.isGroup4DMovin = data.isGroup4DMovin;
                this.type = data.type;
  }

  ngOnInit() {
    this._dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      this.onClickDecline();
    });

    this.targetType = [];
    this.targetType.push({id: 1, valor: 'Clientes inmo'});
    this.targetType.push({id: 2, valor: 'Usuarios'});
    this.endpointService.getClientsPredefinedSelectorsValues().subscribe(
      data => {
        // console.log(data);
        if (data['errorCode'] === 0) {
          this.copyDefaultValuesArrays(data['response']);
        }
      }
    );
    this.inputFilter = '';

    let promise = new Promise<void>((resolve) => {
      console.log(this.targetList);
      this.dataSource = new MatTableDataSource(this.targetList);
      resolve();
    });

    promise.then(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
      console.log(this.targetList);
    });
    // this.updateDataSource();
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.targetList);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
    });

    console.log(this.targetList);
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private copyDefaultValuesArrays(response: any) {
    this.clientInmoTypes = response.clientType;
    this.clientGroupTypes = response.clientGroupCategory;
  }

  onClickDecline() {
    this.onDecline.emit();
    this._dialogRef.close(this.targetList);
  }

  public hideFilter() {
    this.addNewList = false;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
    });
  }

  public showFilter() {
    this.edit = false;
    this.addNewList = true;
    this.initFilterParams();
  }

  public initFilterParams() {
    this.newListName = '';
    this.newListTargetType = 1;
    this.newListTarget = [];
    this.getClients(true);
  }

  public getClients(hasChanged) {
   if(hasChanged){
     this.newListTarget = [];
     this.name = '';
   }
    if (this.newListTargetType === 1) {
      this.endpointService.eventsGetClients(this.inmoId).subscribe(
        data => {
          this.emails = data['response'];
          this.currentEmailsList = this.emails;
          this.currentEmailsList = this.resetFilteredStatus(this.currentEmailsList);
        }
      );
    } else if (this.newListTargetType === 2) {
      this.endpointService.eventsGetUsers(this.inmoId, this.isGroup4DMovin).subscribe(
        data => {
          this.emails = data['response'];
          this.currentEmailsList = this.emails;
          this.currentEmailsList = this.resetFilteredStatus(this.emails);
        }
      );
    }
  }

  public filterClients() {
    let list = [];
    if (this.newListTargetType === 1) {
      list = this.emails.filter(
        element => {
          const nameMatching = VariableMatching.checkString(element.name, this.name);
          const activeMatching = VariableMatching.checkEnum(element.activo, this.active);
          const clientTypeMatching = VariableMatching.checkEnum(element.id_tipo_cliente, this.clientType);
          return nameMatching && activeMatching && clientTypeMatching;
        }
      );
    } else {
      list = this.emails.filter(
        element => {
          return VariableMatching.checkString(element.name, this.name);
        }
      );
    }
    this.currentEmailsList = this.resetFilteredStatus(this.currentEmailsList);
    this.currentEmailsList.map(e => {
      if (!list.some(elem => elem.id === e.id)) {
        e.filtered = false;
      }
    });
  }

  public resetFilteredStatus(emailList: any) {
    emailList.map(e => {
      e.filtered = true;
    });
    return emailList;
  }

  public createList() {
    const list = {
      name: this.newListName,
      targetType: this.newListTargetType,
      targetList: this.newListTarget
    };
    this.endpointService.insertTargetList(list, this.inmoId, this.type).subscribe(
      () => {
        this.endpointService.getAllTargetLists(this.inmoId, this.type).subscribe(
          d1 => {
            console.log(d1);
            this.targetList = d1['response'];
            this.hideFilter();
            this.updateDataSource();
          }
        );
      }
    );
  }

  public editTargetList(id: number) {
    this.edit = true;
    this.addNewList = true;
    this.endpointService.getTargetList(id).subscribe(data => {
      this.newListName = data['response'].nombre;
      this.newListTarget = [];
      console.log(data);
      data['response'].lista.forEach(e => {
        this.newListTarget.unshift(e.id_destinatario);
      });
      this.newListTargetType = data['response'].id_tipo_destinatario;
      this.listId = data['response'].id;
      this.getClients(false);
    });
  }
  public showlist(){
    this.loaded = !this.loaded;
  }
  public refreshLoad(){
    this.loaded = false;
  }

  public deleteTargetList(id: number) {
    this.endpointService.deleteTargetList(id).subscribe(() => {
      this.endpointService.getAllTargetLists(this.inmoId, this.type).subscribe(
        d1 => {
          this.targetList = d1['response'];
        }
      );
    });
  }

  public updateList() {
    this.endpointService.updateTargetList({
      'nombre': this.newListName,
      'id_tipo_destinatario': this.newListTargetType,
      'id': this.listId,
      'targetList': this.newListTarget
    }).subscribe(() => {
      this.endpointService.getAllTargetLists(this.inmoId, this.type).subscribe(
        d1 => {
          console.log(d1);
          this.targetList = d1['response'];
          this.name = null;
          this.active = -1;
          this.clientType = -1;
          this.clientGroupType = -1;
          this.hideFilter();
          this.updateDataSource();
        }
      );
    });
  }

  public clearFilter() {
    this.name = null;
    this.active = -1;
    // this.clientTypes = [];
    this.clientType = -1;
    this.clientGroupType = -1;
    this.filterClients();
  }

  public getFirstOfListsName(id: string) {
    const email = this.currentEmailsList.filter(email => email.id === id)[0];
    return email ? email.name : '';
  }

  hide(text: HTMLInputElement, email: any) {
    return (text.value !== '' && email.name.toLowerCase().indexOf(text.value.toLowerCase()) === -1) || (this.active !== -1 && email.activo !== this.active);
  }
}
