import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatTableDataSource } from "@angular/material/table";
import { PeriodicElement } from "../../bill/bill.component";
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: 'app-movins-history',
  templateUrl: './movins-history.component.html',
  styleUrls: ['./movins-history.component.css']
})
export class MovinsHistoryComponent implements OnInit {

  public planesArray = new Array();
  public dataSource  = new MatTableDataSource<PeriodicElement>(this.planesArray);

  public planesBoughtArray = new Array();
  public planesUsedArray = new Array();
  public actual = new Array();
  public subscribeActual: number;
  headBought = ['date', 'buyer' , 'nmovin', 'price'];
  public dataSourceBought  = new MatTableDataSource<PeriodicElement>(this.planesBoughtArray);

  headUsed = ['date', 'user', 'nmovinSpent', 'concept'];
  dataSourceUsed  = new MatTableDataSource<PeriodicElement>(this.planesUsedArray);

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<MovinsHistoryComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public endpointService: EndpointService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    /*this.endpointService.getPlanesMovin().subscribe(data => {
      this.planesArray = data['response'];
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.planesArray);
    });

    this.endpointService.getPlanesComprados(this.authService.inmoId).subscribe( data =>{
      this.planesBoughtArray = data['response'].allMovins;
      this.actual = data['response'].actual;
      if(data['response'].actual.length > 0) {
        this.subscribeActual = this.actual[0].num_comprados;
      }
      this.dataSourceBought = new MatTableDataSource<PeriodicElement>(this.planesBoughtArray);
    });*/

    this.endpointService.getMovinsAdquiridos(this.authService.inmoId, this.authService.getIdCompanyType()).subscribe(data => {
      this.planesArray = data['response'];

      this.planesArray.forEach(item => {
        item.fecha_alta = this.authService.formateaFecha(item.fecha_alta);
      });

      this.dataSource = new MatTableDataSource<PeriodicElement>(this.planesArray);
    });

    this.endpointService.getMovinsInvertidos(this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.languageId).subscribe( data =>{
      this.planesBoughtArray = data['response'];

      this.planesBoughtArray.forEach(item => {
        item.fecha = this.authService.formateaFecha(item.fecha);
      });

      this.dataSourceBought = new MatTableDataSource<PeriodicElement>(this.planesBoughtArray);
    });
  }

  dateFormat(array: any) {
    array.forEach(product => {
      product.fecha = this.authService.formateaFecha(product.fecha_desactivacion);
    });
  }

}
