<div class="dialog-container" style="margin-top: 1.5em;" fxLayout="row wrap">
    <label fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="title-input">Selecciona tus Cuentas</label>
    <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
        <span class="generic-buttons-blue-revert"><button class="close-button" mat-button (click)="onClose(true)"><i class="material-icons">check</i> APLICAR FILTROS</button></span>
        <span class="generic-buttons-blue"><button style="margin-right: 10px;" class="close-button" mat-button (click)="onClose(false)"><i class="material-icons">close</i> VOLVER</button></span>  
    </span>
</div>

<div class="dialog-container" style="width: 100%; display: flex; flex-direction: column;">
    <div class="shareEvents shareEventsSelectAll">
        <span (click)="checkUncheckAll(!checkAll)" style="cursor: pointer;">
            <span *ngIf="!checkAll">Marcar todo</span>
            <span *ngIf="checkAll">Desmarcar todo</span>
            <mat-checkbox [ngModel]="checkAll" (click)="$event.stopPropagation()" (change)="checkUncheckAll($event.checked)" style="padding-right: 10px; margin-left: 6px;"></mat-checkbox>
        </span>
    </div>
    <div class="shareEvents scroll-lists-agenda">
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let acc of myGroupEventsFilter; let i = index;" (click)="changeStatusAccount(acc, !acc.value, i)">
                <span [ngClass]="{'agenda-selectors-background-allways': true, 'agenda-selectors-background': (i%2 == 0)}">
                    {{'Mis eventos de '}} {{ acc.nombre }} ({{acc.tipoNombre}})
                    <mat-checkbox [ngModel]="acc.value" (click)="$event.stopPropagation()" (change)="changeStatusAccount(acc, $event.checked, i)" style="float: right; padding-right: 20px;"></mat-checkbox>
                </span>
            </li>
        </ul>
    </div>
</div>