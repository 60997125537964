import { EventEmitter, Injectable, Output } from "@angular/core";
import { Values } from "../../../values/values";

// Serivce
import { RoutingService } from "../routing/routing.service";
import { ClientsFunctionsService } from "./clients-functions.service";
import { EndpointService } from "../endpoint/endpoint.service";
import { TemplateService } from "app/services/generic/custom/template-element";

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import html2canvas from "html2canvas";

// Material
import { MatDialog } from "@angular/material/dialog";

// Component
import { DialogComponent } from "../../generic/components/dialog/dialog.component";
import { GenericService } from "../generic/generic.service";

import * as _ from "lodash";
import { MatSnackBar } from "@angular/material/snack-bar";

import { saveAs } from "file-saver";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BottomSheetComponent } from "app/generic/components/bottom-sheet/bottom-sheet.component";
import { AuthService } from "../auth/auth.service";
import { Location } from "@angular/common";
import { SideNavService } from "app/utils/SideNavService";
// Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
//import { Subject } from "rxjs/Subject";
//import { EmptyObservable } from "rxjs-compat/observable/EmptyObservable";
import { Subject, EMPTY } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";

import { UserFunctionsService } from "./user-functions.service";
import { EditEventDialog } from "app/activities/edit-event-dialog/edit-event-dialog";
import { ColorPickerDialog } from "app/activities/subcomponents/color-picker-dialog/color-picker-dialog";
import { SimpleClient } from "app/activities/objects/simple-client";
import { SimpleProperty } from "app/activities/objects/simple-property";
import { SimpleEncargo } from "app/activities/objects/simple-encargo";
import { SimpleOffer } from "app/activities/objects/simple-offer";
import { CustomEvent } from "app/activities/objects/custom-event.component";
import { SimpleUser } from "app/activities/objects/simple-user";
import { EventType, setCategories } from "app/activities/objects/category";
import { Router } from "@angular/router";
import { DemoDialogComponent } from "app/demo-dialog/demo-dialog.component";

@Injectable({
  providedIn: "root",
})
export class FunctionsService {
  public dialogRef = null;
  private route = null;
  private id_route = null;
  public tmpArray = [];
  private removed = [];
  public bottomSheetRef = null;

  public dateToStringDBDate: Date;
  public dateToday: string;
  public minDate: string;
  public functionFinish: boolean;

  public open = false;
  public downloadingStaticPage = false;
  private imageUpload = [];

  private openHelpSubscription = new Subject<void>();
  public openHelp$ = this.openHelpSubscription.asObservable();

  private openChatSubscription = new Subject<void>();
  public openChat$ = this.openChatSubscription.asObservable();

  private openExternalWindowSubscription = new Subject<void>();
  public openExternalWindow$ =
    this.openExternalWindowSubscription.asObservable();

  private openButtonAutoPlantilla = new Subject<void>();
  public openButton$ = this.openButtonAutoPlantilla.asObservable();

  public portalConfigured = 0;

  public arrayImagenes = [];

  public arrayConditions = [];
  public arrayTables = [];
  public fieldNames = [];
  public arrayOperators = [];
  public arrayValues = [];

  public addConditionFormIsValid: boolean = false;

  @Output() update_category: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() openNoti: EventEmitter<any> = new EventEmitter();
  //@ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  constructor(
    private routingService: RoutingService,
    public dialog: MatDialog,
    public _bottomSheet: MatBottomSheet,
    public snackBar: MatSnackBar,
    public clientsFunctionsService: ClientsFunctionsService,
    private endpointService: EndpointService,
    private genericService: GenericService,
    private authService: AuthService,
    private location: Location,
    private sidenavService: SideNavService,
    private UserFunctionsService: UserFunctionsService,
    private router: Router,
    private clipboard: Clipboard,
    public templateService: TemplateService
  ) { }

  public getFunction(structure, param = null, finished = null, idFunction = null) {
    //console.log("functions.service");
    //console.log(structure);

    // if need intern_param use this function in your function case --> this.getInternParam(structure, param);
    // if need input_param use this function in your function case --> this.getInputParam(structure);

    let id = idFunction;
    if (structure !== undefined && id == null) {
      id = structure["id_function"];
    }

    /*
    if (param != null) {
      if ("id" in param) {
        this.saveIdInLocalStorage(param["id"]);
      }
    }
     */

    if (structure !== undefined && ((structure["readMode"] !== undefined && structure["readMode"]) || (structure["creatingMode"] !== undefined && structure["creatingMode"]))) {
      this.openSnackBar("Parece que solo tienes permiso de lectura.", 7000, [
        "lightred-snackbar",
      ]);
      this.finishFunction(finished);
      return;
    }

    this.genericService.actualFunctionFinish = true;

    if (!this.genericService.actualFunctionFinish) {
      this.openSnackBar("Parece que ya hay otra función en marcha.", 7000, [
        "lightred-snackbar",
      ]);
      this.finishFunction(finished);
      return;
    }

    this.genericService.actualFunctionFinish = false;

    if (structure !== undefined && this.checkPermissions(structure, finished)) {
      console.log("ID", id);
      switch (id) {
        case 1:
          this.saveParams(structure, param);
          this.goRouting(this.route);
          this.finishFunction(finished);
          break;
        case 2:
          if (structure["id_functional_parent_initial_dsb"] == 0) {
            this.getInternParam(structure, param);
          }
          this.openDialog(structure, 1);
          this.finishFunction(finished);
          break;
        case 3:
          // Cerrar generic dialog
          this.dialogRef.close(structure, param);
          this.finishFunction(finished);
          break;
        case 4:
          let req = this.formsArray(structure, finished);
          if (req) {
            this.duplicateElementUnified(structure, finished, true, 1);
            this.dialogRef.close(structure, finished);
            this.finishFunction(finished);
          }
          break;
        case 5:
          if (structure["id_functional_parent_initial_dsb"] == 0) {
            this.getInternParam(structure, param);
          }
          this.openDialog(structure, 2);
          //this.finishFunction(finished);
          break;
        case 6:
          let paramControl2 = this.generateNewOutputParam(structure);
          localStorage.setItem("paramControl", JSON.stringify(paramControl2));
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];

          this.routingService.previousModule =
            structure["id_functional_parent_initial"];
          if (param && param["tmp_param"]) {
            this.routingService.routeParam = null;
            console.log(this.routingService.routeParam);
          }
          this.goRouting(this.route);
          this.finishFunction(finished);
          break;
        case 7:
          this.insertUpdateForm(structure, param, finished, 0, true, false);
          break;
        case 8:
          this.insertUpdateForm(structure, param, finished, 1, false, false);
          break;
        case 9:
          this.insertUpdateForm(structure, param, finished, 0, false, false);
          break;
        case 10:
          this.insertUpdateForm(structure, param, finished, 0, false, true, 1);
          break;
        case 11:
          this.duplicateElementUnified(structure, finished);
          this.finishFunction(finished);
          break;
        case 12:
          this.openDeferContainer(param, structure);
          this.finishFunction(finished);
          break;
        case 13:
          console.log("open sidenav");
          this.sidenavService.open(0);
          this.finishFunction(finished);
          break;
        case 14:
          console.log("close sidenav");
          this.sidenavService.close(0);
          this.finishFunction(finished);
          break;
        case 15:
          this.revertValues(structure);
          break;
        case 16:
          let paramControl = JSON.parse(localStorage.getItem("paramControl"));
          let paramControlUser =
            paramControl[this.authService.getIdCompany(true)];
          if (paramControlUser != null) {
            for (let par of paramControlUser) {
              if (
                par["id_pantalla"] == structure["id_functional_parent_initial"]
              ) {
                for (let aux in param["tmp_param"]) {
                  let found = false;
                  for (let out of par["params"]["output"]) {
                    if (out["bd_field"] == aux) {
                      found = true;
                      out["value"] = param["tmp_param"][aux];
                    }
                  }
                  if (!found) {
                    par["params"]["output"].push({
                      id_db: structure["id_db"],
                      bd_table: structure["bd_table"],
                      bd_field: aux,
                      value: param["tmp_param"][aux],
                    });
                  }
                }
              }
            }
          }
          localStorage.setItem("paramControl", JSON.stringify(paramControl));
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule =
            structure["id_functional_parent_initial"];
          if (param && param["tmp_param"]) {
            this.routingService.routeParam = param["tmp_param"];
            console.log(this.routingService.routeParam);
          }
          this.goRouting(this.route);
          break;
        case 17:
          console.log("structure", structure);
          this.deleteFunctionalArea(structure);
          this.finishFunction(finished);
          break;
        case 18:
          //Chat
          this.openChatSubscription.next();
          this.finishFunction(finished);
          break;
        case 19:
          //Help
          this.openHelpSubscription.next();
          this.finishFunction(finished);
          break;
        case 20:
          //External Window
          let paramsExternalWindowOld = _.cloneDeep(this.genericService.paramsExternalWindow);
          if(structure["customParams"] === undefined) this.genericService.paramsExternalWindow = this.getInternParam(structure, param);
          else this.genericService.paramsExternalWindow = structure["customParams"];
          this.genericService.fetchStructureExternalWindow(structure["internal_routing_id_functional_area"]);
          if(this.genericService.paramsExternalWindow != paramsExternalWindowOld) this.openExternalWindowSubscription.next();
          this.finishFunction(finished);
          break;
        case 21:
          this.openEventDialog(structure, param);
          this.finishFunction(finished);
          break;
        case 22:
          console.log("next");
          this.openButtonAutoPlantilla.next();
          this.finishFunction(finished);
          break;
        case 23:
          this.genericService.openAccountMode();
          this.finishFunction(finished);
          break;
        case 24:
          this.openColorPickerDialog(param);
          this.finishFunction(finished);
          break;
        case 25:
          console.log("llamar duplciar functionalAreaDB");
          this.duplicateFunctionalAreaDB(structure, param);
          break;
        case 26:
          console.log("open external url");
          this.openExternalUrl(structure);
          break;
        case 27:
          this.reloadQueries(structure, param, finished);
          break;
        case 33:
          this.deleteDataDialog(structure, 0);
          break;
        case 34:
          this.openBottomSheet(structure);
          this.finishFunction(finished);
          break;
        case 35:
          if (structure["id_functional_parent_initial_dsb"] == 0) {
            this.getInternParam(structure, param);
          }
          this.openDialog(structure, 3);
          break;
        case 36:
          this.openFile(structure, param, false);
          this.finishFunction(finished);
          break;
        case 37:
          this.openFile(structure, param, true);
          this.finishFunction(finished);
          break;
        case 38:
          this.closeBottomSheet(structure);
          this.finishFunction(finished);
          break;
        case 39:
          this.insertUpdateForm(structure, param, finished, 0, false, true, 2);
          break;
        case 40:
          this.insertUpdateForm(structure, param, finished, 0, false, true, 3);
          break;
        case 41:
          this.deleteDataDialog(structure, 1);
          break;
        case 42:
          this.deleteDataDialog(structure, 2);
          break;
        case 43:
          this.deleteDataDialog(structure, 3);
          break;
        case 44:
          let interns = this.getInternParamDialog(structure, param);
          this.openDialog(structure, 4, interns);
          break;
        case 45:
          this.openDialog(structure, 5);
          break;
        case 46:
          let interns2 = this.getInternParamDialog(structure, param);
          console.log(interns2, "interns2interns2interns2");
          this.openDialog(structure, 6, interns2);
          break;
        case 47:
          let from = 0;
          if (
            structure["id_functional_parent_initial"] ==
            this.genericService.headerId ||
            structure["id_functional_parent_initial"] ==
            this.genericService.headerNoLoguedId
          )
            from = 1;
          if (
            structure["id_functional_parent_initial"] ==
            this.genericService.footerId ||
            structure["id_functional_parent_initial"] ==
            this.genericService.footerNoLoguedId
          )
            from = 2;

          if (structure["id_functional_type"] != 3) {
            let struct = this.genericService.findElementWithId(finished);
            if (struct == undefined || struct["child"] == undefined) break;
            struct = struct["child"];
            struct["expandedElement"] =
              struct["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
          } else {
            structure["expandedElement"] =
              structure["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
          }

          this.genericService.loadExpansion(param, from);
          break;
        case 48:
          this.genericService.fillExpansion(param, { 0: structure });
          console.log("UPDATING URL", param, structure);
          let selBox = document.createElement("textarea");
          selBox.style.position = "fixed";
          selBox.style.left = "0";
          selBox.style.top = "0";
          selBox.style.opacity = "0";
          selBox.value = structure["description"];
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand("copy");
          document.body.removeChild(selBox);
          this.openSnackBar(
            "Se ha copiado el link para que puedas facilitarlo a tus clientes, se lo facilites a través de tu web, lo pongas en tus visitas virtuales y un sin fin de oportunidades nuevas.\n" +
            "¡Con este link podrán pedirte una cita para ver el inmueble y aumentar tanto la conversión de leads como la calidad de los mismos!",
            30000,
            ["lightgreen-snackbar"]
          );
          break;
        case 49:
          /* VENTA */
          let productVenta = this.findProductFromParams(structure, param);
          this.productEgoStart(1, productVenta);
          this.finishFunction(finished);
          break;
        case 50:
          /* TUTORIAL */
          let productTutorial = this.findProductFromParams(structure, param);
          this.productEgoStart(2, productTutorial);
          this.finishFunction(finished);
          break;
        case 51:
          //Guardar vivienda como borrador
          this.insertUpdateForm(
            structure,
            param,
            finished,
            1,
            false,
            false,
            null,
            0
          );
          break;
        case 52:
          //Publicar vivienda
          this.insertUpdateForm(
            structure,
            param,
            finished,
            1,
            false,
            false,
            null,
            1
          );
          break;
        case 53:
          this.deleteDataDialog(structure, 4);
          break;
        case 54:
          this.insertUpdateForm(structure, param, finished, 2, false, false);
          break;
        case 55:
          this.copyTableField(structure, param);
          break;
        case 56:
          this.insertUpdateFormConfigPortales(structure, param, finished);
          break;
        case 57:
          this.productEgoStart(1, structure["internal_routing"]);
          this.finishFunction(finished);
          break;
        case 58:
          this.productEgoStart(2, structure["internal_routing"]);
          this.finishFunction(finished);
          break;
        case 59:
          this.downloadPDF(1);
          break;
        case 60:
          this.downloadPDF(0);
          break;
        case 61:
          this.sendEmailInmueble(structure, param, finished);
          break;
        case 62:
          this.sendWhatsAppInmueble(structure, param, finished);
          break;
        case 63:
          this.openFichaInmueble(structure, param, finished);
          break;
        case 64:
          this.getTextInmueble(structure, param, finished, 1); // whatsapp empresa
          break;
        case 65:
          this.getTextInmueble(structure, param, finished, 2); // contenido email empresa
          break;
        case 66:
          this.getTextInmueble(structure, param, finished, 3); //  asunto empresa
          break;
        case 67:
          this.updateImagePropertyCustom(finished);
          break;
        case 68:
          this.vincularGoogleMail(structure, param, finished);
          break;
        case 69:
          this.desvincularGoogleMail(structure, param, finished);
          break;
        case 70:
          this.updateConditionsCustom(finished, structure);
          break;
        case 71:
          this.showStaticReport(structure, param, finished);
          break;
        case 72:
          this.updatePlantillaElementsCustom(structure, param, finished);
          break;
        case 73:
          this.openEventDialog(structure, param, 3);
          this.finishFunction(finished);
          break;
        case 74:
          this.endpointService
            .vincularTwitter(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Twitter vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 75:
          this.endpointService
            .desvincularTwitter(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Twitter desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 76:
          this.endpointService
            .vincularInstagram(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Instagram vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 77:
          this.endpointService
            .desvincularInstagram(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Instagram desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 78:
          this.endpointService
            .vincularFacebook(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Facebook vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 79:
          this.endpointService
            .desvincularFacebook(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Facebook desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 80:
          this.endpointService
            .vincularLinkedin(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Linkedin vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 81:
          this.endpointService
            .desvincularLinkedin(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Linkedin desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 82:
          this.duplicarInmueble(structure, param, finished);
          break;
        case 83:
          this.validateEmailBackend(structure, param, finished);
          break;
        case 84:
          this.sendWhatsAppClient(structure, param, finished);
          break;
        case 85:
          this.getTextInmueble(structure, param, finished, 4); // whatsapp inmueble
          break;
        case 86:
          this.getTextInmueble(structure, param, finished, 5); // contenido email inmueble
          break;
        case 87:
          this.getTextInmueble(structure, param, finished, 6); //  asunto inmueble
          break;
        case 88:
          this.saveTextInmueble(structure, param, finished, 1); //  mensaje whatsapp inmueble
          break;
        case 89:
          this.saveTextInmueble(structure, param, finished, 2); //  mensaje email inmueble
          break;
        case 90:
          this.saveTextInmueble(structure, param, finished, 3); //  asunto email inmueble
          break;
        case 91:
          this.insertUpdateForm(
            structure,
            param,
            finished,
            0,
            false,
            false,
            null,
            null,
            true
          );
          break;
        case 92:
          this.demoInmo(param);
          this.finishFunction(finished);
          break;
        case 93:
          this.loadClientsFromFile(param, finished, structure);
          break;
        case 94:
          this.duplicatePlantillaEmail(param, finished, structure);
          break;
        case 95:
          this.duplicatePlantillaEmail(param, finished, structure, 1);
          break;
        case 96:
          this.openFichaPropietario(structure, param, finished);
          break;
        case 97:
          this.verForm(structure, param, finished);
          break;
        case 98:
          this.selectImageAndAddInEventsTemplateCustom(structure, param, finished);
        case 199:
          console.log("199.- dateToStringDBDate");
          const dt = new Date(param["date"]);
          return (
            dt.getFullYear() +
            "-" +
            (dt.getMonth() + 1 < 10 ? "0" : "") +
            (dt.getMonth() + 1) +
            "-" +
            (dt.getDate() < 10 ? "0" : "") +
            dt.getDate()
          );
        case 262:
          let paramIntern = [];
          let intern_data = this.getInternParam(structure, paramIntern);

          let email = null;
          for (let i in intern_data) {
            if (
              intern_data[i]["id_db"] == 1 &&
              intern_data[i]["bd_table"] == "usuarios" &&
              intern_data[i]["bd_field"] == "email"
            ) {
              console.log("email");
              email = intern_data[i]["value"];
            }
          }
          this.UserFunctionsService.setPassword(null, email);
          break;
        case 267:
          this.UserFunctionsService.vincularGoogle();
          //this.finishFunction(finished);
          break;
        case 268:
          this.UserFunctionsService.desvincularGoogle();
          //this.finishFunction(finished);
          break;
        case 269:
          this.UserFunctionsService.vincularMicrosoft();
          break;
        case 270:
          this.UserFunctionsService.desvincularMicrosoft();
          break;

        case 782:
          console.log("case 782: abrir tutorial");
          let panelClass = [];
          if (this.genericService.shouldOpenExternalWindow)
            panelClass.push("overAllDialog");
          this.dialog.open(DemoDialogComponent, {
            panelClass: panelClass,
          });
          break;
        case 783:
          // Se llama estática desde el código de notis
          this.openEventDialog(structure, param, 2);
          this.finishFunction(finished);
          break;
      }
    }

    if (structure !== undefined) {
      console.log(structure, "Structure");
      this.endpointService
        .insertMonitoringIdFunction(
          this.authService.userId,
          structure["id_functional_area"],
          id
        )
        .subscribe((data) => {
          console.log("monitoring_functions", data);
        });
    }
  }

  private openDeferContainer(param, structure) {
    console.log(param, "DEFEEEEER");
    for (let e in param) {
      if (param[e]["id_functional_type"] == 106) {
        if (param[e]["id_functional_area"] == structure["internal_routing"]) {
          param[e]["id_functional_status_general"] = 1;
          if (
            (!this.genericService.staticHTML &&
              this.genericService.breadcrumbs[
              this.genericService.breadcrumbs.length - 1
              ]["parent"] === null) ||
            this.genericService.breadcrumbs[
            this.genericService.breadcrumbs.length - 1
            ]["parent"] === undefined
          )
            this.genericService.breadcrumbs.pop();
          this.genericService.breadcrumbs[
            this.genericService.breadcrumbs.length - 1
          ]["defer"] = param;
          this.genericService.breadcrumbs.push({
            url: this.routingService.urlWithoutHashtag.split("/")[1],
            name: structure["label"],
          });
          this.location.replaceState(
            this.routingService.urlWithoutHashtag +
            "#" +
            param[e]["id_functional_area"]
          );
          this.endpointService
            .insertMonitoringInformation(
              this.authService.getLoggedInUserId(),
              param[e]["id_functional_area"],
              param[e]["id_functional_area"],
              this.genericService.staticHTML
            )
            .subscribe((data) => {
              param[e]["monitoreo"] = data["response"];
            });
        } else {
          if (param[e]["monitoreo"] !== null) {
            this.endpointService
              .updateMonitoringInformation(
                param[e]["monitoreo"],
                this.genericService.staticHTML
              )
              .subscribe((data) => { });
            param[e]["monitoreo"] = null;
          }
          param[e]["id_functional_status_general"] = 2;
        }
      }
    }
  }

  private finishFunction(finished) {
    if (finished !== null && typeof finished != "string") finished.done = true;
  }

  private openFile(structure, param, download) {
    console.log(structure);
    console.log(param);
    let fileName = null;
    if (param !== null) {
      for (let e in param) {
        if (
          e.split("-")[1] == structure["id_functional_url"]["bd_field"] ||
          e == structure["id_functional_url"]["bd_field"]
        )
          fileName = param[e];
      }
    } else {
      fileName = this.findFAWithTableField(
        this.genericService.parentStructure,
        structure["id_functional_url"]["id_db"],
        structure["id_functional_url"]["bd_table"],
        structure["id_functional_url"]["bd_field"],
        structure["id_functional_parent_initial_dsb"]
      )["tmp_value"];
    }
    if (fileName !== null && structure["id_functional_url"]["url"] !== null) {
      console.log("FILENAMEEEEEEEEEEEEEEEEEEEEEE", fileName);
      if (download) {
        this.endpointService
          .getFileFromUrl(
            structure["id_functional_url"]["url"] + fileName,
            structure["id_functional_url"]["path"] + fileName
          )
          .subscribe((data) => {
            console.log(data);
            saveAs(new Blob([data]), fileName);
          });
      } else window.open(structure["id_functional_url"]["url"] + fileName);
    } else
      this.openSnackBar("No se ha encontrado este fichero", 5000, [
        "red-snackbar",
      ]);
  }

  findFAWithTableField(subStructure, db, table, field, idDSB) {
    let fa = null;
    for (let i in subStructure) {
      if (subStructure[i]["id_functional_parent_initial_dsb"] == idDSB && subStructure[i]["bd_field"] == field && subStructure[i]["bd_table"] == table && subStructure[i]["id_db"] == db && subStructure[i]["form_field"] == 1)
        fa = subStructure[i];
      else if (subStructure[i]["child"] && fa === null)
        fa = this.findFAWithTableField(subStructure[i]["child"], db, table, field, idDSB);
    }
    return fa;
  }

  private findParams(par: any, subStructure: any, tableParams = null) {
    if (
      subStructure["id_functional_status_general"] != 2 &&
      subStructure["id_functional_type"] != 85
    ) {
      if (subStructure["param"] == 1) {
        console.log("Es param", subStructure);
        let found = false;
        for (let out of par) {
          if (subStructure["id_functional_type"] != 9) {
            if (
              out["id_db"] == subStructure["id_db"] &&
              out["bd_table"] == subStructure["bd_table"] &&
              out["bd_field"] == subStructure["bd_field"]
            ) {
              console.log("Fa encontrada");
              found = true;
              out["value"] = subStructure["tmp_value"];
            }
          } else if (tableParams !== null) {
            console.log("TABLE!!!!", tableParams);
            for (let e in tableParams["tmp_param"]) {
              console.log(
                subStructure["bd_field"],
                e,
                tableParams["tmp_param"][e]
              );
              if (
                e == subStructure["bd_field"] &&
                out["id_db"] == subStructure["id_db"] &&
                out["bd_table"] == subStructure["bd_table"] &&
                out["bd_field"] == subStructure["bd_field"]
              ) {
                found = true;
                out["id_db"] = subStructure["id_db"];
                out["table"] = subStructure["bd_table"];
                out["table"] = subStructure["bd_field"];
                out["value"] = tableParams["tmp_param"][e];
              }
            }
          }
        }
        if (!found) {
          console.log("Fa creada", subStructure["id_functional_type"]);
          if (subStructure["id_functional_type"] != 9) {
            par.push({
              id_db: subStructure["id_db"],
              bd_table: subStructure["bd_table"],
              bd_field: subStructure["bd_field"],
              value: subStructure["tmp_value"],
            });
          } else if (tableParams !== null) {
            let aux = null;
            for (let e in tableParams["tmp_param"]) {
              if (e == subStructure["bd_field"])
                aux = tableParams["tmp_param"][e];
            }
            par.push({
              id_db: subStructure["id_db"],
              bd_table: subStructure["bd_table"],
              bd_field: subStructure["bd_field"],
              value: aux,
            });
          }
        }
      }
      for (let child in subStructure["child"]) {
        par = this.findParams(par, subStructure["child"][child], tableParams);
      }
    }
    console.log("PPAAAAAR", par);
    return par;
  }

  public formsArray(structure, finished, validate = true) {
    let valid = true;
    this.tmpArray = [];
    this.removed = [];
    this.imageUpload = [];
    let useStructure = [];
    let validReq = true;

    useStructure = this.genericService.getAllStructures();
    console.log("ANTES DE REC BOOL ", useStructure, structure);
    for (let s in useStructure) {
      if (!this.eachRecursiveBool(useStructure[s], structure["window"])) {
        if (validate) {
          validReq = false;
          this.openSnackBar(
            "Falta completar el campo: " +
            this.tmpArray[this.tmpArray.length - 1]["label"],
            6000,
            ["red-snackbar"]
          );
        }
      }
    }
    return validReq;
  }

  private insertUpdateForm(
    structure,
    param,
    finished,
    refresh = 0,
    par = false,
    dialog = false,
    type = null,
    publicar = null,
    addParamStatic = false
  ) {
    let structure_pre = _.cloneDeep(structure);
    const v = this.insertFormData(
      structure,
      param,
      finished,
      refresh,
      type,
      publicar
    );
    if (v != null) {
      v.subscribe((data) => {
        if (data["errorCode"] == 0 || data["errorCode"] == 3) {
          if (data["response"] != 0 && data["errorCode"] == 3) {
            this.finishFunction(finished);
            if (data["errorMessage"] !== null && data["errorMessage"] !== "")
              this.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          } else {
            //Casos generals generic
            this.genericService.formsChanged[
              this.genericService.currentInitialArea["key"]
            ] = [];
            this.dialog.closeAll();
            this.finishFunction(finished);
            if (
              structure["id_functional_area"] == 7052 &&
              data["response"] > 0
            ) {
              // Solo para el registro
              this.openSnackBar("¡Bienvenid@ a 4DMOVIN!", 6000, [
                "green-snackbar",
              ]);
              localStorage.setItem("_i_", data["response"].toString());
              this.genericService.refreshStructure(2);
            } else if (
              !dialog &&
              structure["internal_routing"] !== null &&
              structure["internal_routing"] !== undefined &&
              publicar != 1
            ) {
              if (data["errorMessage"] !== null && data["errorMessage"] !== "")
                this.openSnackBar(data["errorMessage"], 6000, [
                  "green-snackbar",
                ]);
              else
                this.openSnackBar("Guardado con éxito", 6000, [
                  "green-snackbar",
                ]);
              if (par && !this.genericService.staticHTML)
                this.saveParams(structure, param);
              if (refresh == 2) this.goPrev(structure["internal_routing"]);
              if (this.genericService.staticHTML) {
                if (addParamStatic) {
                  let idAdd = -1;
                  if (Array.isArray(data["response"])) {
                    let newValues = data["response"];
                    for (let v in newValues) {
                      if (
                        newValues[v]["bd_table"] &&
                        newValues[v]["bd_table"] == structure["bd_table"] &&
                        newValues[v]["database"] &&
                        newValues[v]["database"] == structure["id_db"] &&
                        newValues[v]["bd_field"] &&
                        newValues[v]["bd_field"] == structure["bd_field"] &&
                        newValues[v]["responseId"]
                      ) {
                        idAdd = newValues[v]["responseId"];
                      }
                      if (idAdd != -1) break;
                    }
                  }
                  if (idAdd != -1)
                    structure["internal_routing"] =
                      structure["internal_routing"] + "&&" + idAdd;
                }
                this.authService.logout(structure["internal_routing"], true);
              } else this.goRouting(structure["internal_routing"]);
            } else if (!dialog) {
              if (refresh == 1) {
                if (
                  data["errorMessage"] !== null &&
                  data["errorMessage"] !== ""
                )
                  this.openSnackBar(data["errorMessage"], 6000, [
                    "green-snackbar",
                  ]);
                else
                  this.openSnackBar("Guardado con éxito", 6000, [
                    "green-snackbar",
                  ]);
                this.genericService.refreshStructure(0);
                this.genericService.originalInputsValues = [];
              } else if (refresh == 2)
                this.goPrev(structure["internal_routing"]);
              else {
                if (
                  data["errorMessage"] !== null &&
                  data["errorMessage"] !== ""
                )
                  this.openSnackBar(data["errorMessage"], 6000, [
                    "green-snackbar",
                  ]);
                else
                  this.openSnackBar("Guardado con éxito", 6000, [
                    "green-snackbar",
                  ]);
                this.genericService.updateFormFields.next(true);
                this.genericService.updateFormFields.next(false);
                this.genericService.updateResults();
                this.genericService.refreshOriginalInputsValues(
                  this.genericService.parentStructure
                );
                this.genericService.originalInputsValues = [];
              }
            } else if (structure["id_functional_parent_initial_dsb"]) {
              if (data["errorMessage"] !== null && data["errorMessage"] !== "")
                this.openSnackBar(data["errorMessage"], 6000, [
                  "green-snackbar",
                ]);
              else
                this.openSnackBar("Guardado con éxito", 6000, [
                  "green-snackbar",
                ]);
              let idDialogParent = this.genericService.findElementWithId(structure["internal_routing_id_functional_area"])["child"];
              if (type == 1)
                this.getFunction(
                  { child: Array(idDialogParent), id_function: 2 },
                  finished
                );
              if (type == 2)
                this.getFunction(
                  { child: Array(idDialogParent), id_function: 45 },
                  finished
                );
              if (type == 3)
                this.getFunction(
                  { child: Array(idDialogParent), id_function: 35 },
                  finished
                );
            }
          }
        } else if (data["errorCode"] == 1) {
          //INSERT/UPDATE INMUEBLE INCORRECTO
          if (
            this.genericService.parentStructure[0][
            "id_functional_parent_initial"
            ] == 17
          ) {
            if (data["response"] == 1)
              this.focusFA(
                { child: this.genericService.parentStructure },
                2663
              );
            if (data["response"] == 2 || data["response"] == 3)
              this.focusFA(
                { child: this.genericService.parentStructure },
                4985
              );
          } else if (
            this.genericService.parentStructure[0][
            "id_functional_parent_initial"
            ] == 13
          ) {
            if (data["response"] == 1)
              this.focusFA(
                { child: this.genericService.parentStructure },
                5078
              );
            if (data["response"] == 2 || data["response"] == 3)
              this.focusFA(
                { child: this.genericService.parentStructure },
                5060
              );
          }
          this.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          this.finishFunction(finished);
        } else if (data["errorCode"] == 2) {
          //INSERT/UPDATE INMUEBLE CORRECTO
          if (data["errorMessage"] !== null && data["errorMessage"] !== "") {
            console.log("DEVUELVE EL ID", data);
            this.saveParams(structure, param);
            let paramControl = JSON.parse(localStorage.getItem("paramControl"));
            let userParams = paramControl[this.authService.userId];
            for (let t in userParams) {
              if (userParams[t]["id_pantalla"] == 13) {
                userParams[t]["params"]["output"] = [
                  {
                    id_db: 1,
                    bd_table: "vivienda",
                    bd_field: "id",
                    value: data["errorMessage"],
                  },
                ];
              }
              if (userParams[t]["id_pantalla"] == 17) {
                userParams[t]["params"]["intern"] = [
                  {
                    id_db: 1,
                    bd_table: "vivienda",
                    bd_field: "id",
                    value: data["errorMessage"],
                  },
                ];
              }
            }
            this.openSnackBar(
              "Inmueble guardado correctamente.\n Indica en qué portales quieres publicarlo.",
              6000,
              ["green-snackbar"]
            );
            localStorage.setItem("paramControl", JSON.stringify(paramControl));
            this.genericService.formsChanged[
              this.genericService.currentInitialArea["key"]
            ] = [];
            this.dialog.closeAll();
            this.finishFunction(finished);
            this.goRouting(structure["internal_routing"]);
          }
        } else if (data["errorCode"] == 4) {
          window.open(data["response"], "_self");
          this.finishFunction(finished);
          this.dialog.closeAll();
        }
      });
    }
  }

  private insertUpdateFormConfigPortales(
    structure,
    param,
    finished,
    refresh = 0,
    type = null,
    publicar = null
  ) {
    const v = this.insertFormData(
      structure,
      param,
      finished,
      refresh,
      type,
      publicar
    );
    if (v != null) {
      v.subscribe((data) => {
        switch (data["errorCode"]) {
          case 0:
            console.log(data);
            let idPortal = null;
            let dataAcc = this.genericService.getDataAccount();
            for (let t in this.tmpArray)
              if (
                this.tmpArray[t]["database"] == 1 &&
                this.tmpArray[t]["table"] == "portales" &&
                this.tmpArray[t]["field"] == "id"
              )
                idPortal = this.tmpArray[t]["value"];
            console.log(idPortal, this.tmpArray);
            if (idPortal !== null) {
              this.endpointService
                .updateViviendaConfigPortalesMassive(idPortal, dataAcc)
                .subscribe((data2) => {
                  if (data2["response"] == 1) {
                    if (data2["errorCode"] == 0) {
                      this.openSnackBar(
                        "Los datos se han guardado correctamente y la publicación en el portal se ha actualizado.\n Revisa y gestiona desde esta pantalla la publicación del portal.",
                        12000,
                        ["green-snackbar"]
                      );
                      this.portalConfigured = idPortal;
                      this.genericService.formsChanged[
                        this.genericService.currentInitialArea["key"]
                      ] = [];
                      this.dialog.closeAll();
                      this.finishFunction(finished);
                      this.goRouting("gestionar-inmuebles-en-portales");
                    } else {
                      if (
                        data2["errorMessage"] !== null &&
                        data2["errorMessage"] !== ""
                      ) {
                        this.openSnackBar(
                          "Los datos se han guardado correctamente pero ha habido el siguiente error al actualizar alguno de los inmuebles: " +
                          data2["errorMessage"] +
                          ".\n Soluciona el error y actualízalos manualmente. Si persiste el error, ponte en contacto con nosotros en el chat de soporte.",
                          20000,
                          ["red-snackbar"]
                        );
                        this.portalConfigured = idPortal;
                        this.genericService.formsChanged[
                          this.genericService.currentInitialArea["key"]
                        ] = [];
                        this.dialog.closeAll();
                        this.finishFunction(finished);
                        this.goRouting("gestionar-inmuebles-en-portales");
                      }
                    }
                  } else {
                    if (data2["errorCode"] == 0) {
                      this.openSnackBar(
                        "Los datos se han guardado correctamente y se han despublicado los inmuebles del portal.",
                        6000,
                        ["green-snackbar"]
                      );
                      this.genericService.formsChanged[
                        this.genericService.currentInitialArea["key"]
                      ] = [];
                      this.dialog.closeAll();
                      this.finishFunction(finished);
                    }
                  }
                });
            }
            break;
          case 1:
            this.finishFunction(finished);
            break;
        }
      });
    }
  }

  focusFA(structure, id) {
    let found = false;
    if (structure) {
      if (structure["id_functional_area"] == id) {
        console.log("FOUND! ", id);
        return true;
      } else {
        let lastChild = null;
        for (let t in structure["child"]) {
          if (!found) found = this.focusFA(structure["child"][t], id);
          if (found) {
            lastChild = t;
            break;
          }
        }
        if (found) {
          if (structure["id_functional_type"] == 96) {
            console.log("FOUND STEPPER");
            structure["selectedIndex"] = lastChild;
          }
          if (structure["id_functional_type"] == 7) {
            structure["expansion_start_opened"] = lastChild;
          }
          if (structure["id_functional_type"] == 8) {
            structure["child"][0]["tabActive"] = lastChild;
          }
        }
      }
    }
    return found;
  }

  async goRouting(route) {
    if (route[0] == "#") {
      this.routingService.urlHashtag = route.slice(1);
      this.focusFA(
        { child: this.genericService.parentStructure },
        route.slice(1)
      );
      this.genericService.scroll(route.slice(1));
      const url = this.routingService.urlWithoutHashtag;
      this.location.replaceState(url + "#" + this.routingService.urlHashtag);
    } else {
      if (!route.includes(this.authService.labelLanguage + "/"))
        route = this.authService.labelLanguage + "/" + route;
      if (this.id_route != null) route += "#" + this.id_route;

      this.genericService.refreshStructureFinished = true;
      this.genericService.shouldOpenChat = false;
      this.genericService.shouldOpenHelp = false;
      this.genericService.shouldOpenExternalWindow = false;

      if (
        Object.keys(
          this.genericService.formsChanged[
          this.genericService.currentInitialArea["key"]
          ]
        ).length > 0
      ) {
        const status = await this.genericService.openWarningDialog(1, 1);
        if (status) this.routingService.go(route);
      } else this.routingService.go(route);
    }
  }

  openDialog(structure, type, params = null) {
    let dialogFA = null;
    if (structure["child"]) {
      for (let i in structure["child"]) {
        if (structure["child"][i]["id_functional_type"] == 85)
          dialogFA = structure["child"][i];
      }
    }

    if (dialogFA === null) dialogFA = this.genericService.findElementWithId(structure['internal_routing_id_functional_area'])['child'];

    this.loadDialogStructure(dialogFA, type, dialogFA["id_functional_parent_initial_dsb"], params);
  }

  private loadDialogStructure(structure, type, dialogId, params = null) {
    if (structure !== null && structure !== undefined) {
      if (structure["close_dialog_out"] == 1) {
        structure["class"] = [
          structure["class"],
          "dialog-material-generic-close-out",
          "dialog-material-generic",
        ];
      } else {
        structure["class"] = [
          structure["class"],
          "dialog-material-generic-no-close-out",
          "dialog-material-generic",
        ];
      }
    }

    if (structure !== undefined) {
      if (type == 4 || type == 6) {
        let paramControl = JSON.parse(localStorage.getItem("paramControl"));
        let paramControlUser =
          paramControl[this.authService.getIdCompany(true)];
        for (let i = 0; i < paramControlUser.length; i++) {
          if (
            paramControlUser[i]["id_pantalla"] ==
            structure["id_functional_parent_initial_dsb"]
          ) {
            paramControlUser.splice(i, 1);
          }
        }
        paramControlUser.push({
          id_pantalla: structure["id_functional_parent_initial_dsb"].toString(),
          params: {
            intern: params,
          },
        });
        localStorage.setItem("paramControl", JSON.stringify(paramControl));
      }
      console.log(structure["class"], "[structure[class]Fdialog");
      if (this.genericService.shouldOpenExternalWindow) structure["class"].push("overAllDialog");
      this.dialog.closeAll();
      this.dialogRef = this.dialog.open(DialogComponent, {
        width: "auto",
        height: "auto",
        panelClass: structure["class"],
        data: {
          structure: structure,
          type: type,
          dialogId: dialogId,
        },
        hasBackdrop: structure["close_dialog_out"] == 1,
      });

      this.dialogRef.afterClosed().subscribe((data) => {
        this.genericService.formsChanged[
          structure["id_functional_parent_initial_dsb"]
        ] = [];
      });
    }
  }

  findInterns(subStructure: any, param, tableParams, idNgFor, idDSB) {
    if(subStructure["id_functional_area"] == 20119) console.log("HHSDFHSHDFHSDHFDHS");
    if (subStructure !== null && subStructure !== undefined && subStructure["id_functional_status_general"] != 2) {
      if (subStructure["param_intern"] == 1 && (!idNgFor || subStructure["ngForId"] == -1 ||subStructure["ngForId"] == idNgFor) && subStructure["id_functional_parent_initial_dsb"] == idDSB) {
        console.log("soy intern", subStructure["id_functional_area"], subStructure["ngForId"], idNgFor, subStructure["id_functional_parent_initial_dsb"], idDSB);
        let found = false;
        if(subStructure["id_functional_area"] == 824) console.log(subStructure, "subStructuresubStructuresubStructuresubStructuresubStructure");
        if (subStructure["id_functional_type"] != 9 && subStructure["tmp_value"]) {
          console.log("subStructure['id_functional_type'] != 9");
          for (let p in param["intern"]) {
            if (param["intern"][p]["id_db"] == subStructure["id_db"] && param["intern"][p]["bd_table"] == subStructure["bd_table"] && param["intern"][p]["bd_field"] == subStructure["bd_field"]) {
              console.log(idNgFor, subStructure["ngForId"], subStructure["ngForId"] == idNgFor, !idNgFor || !subStructure["ngForId"] || subStructure["ngForId"] == idNgFor);
              console.log("Found param intern no table", param["intern"][p],subStructure);
              param["intern"][p]["value"] = subStructure["tmp_value"];
              found = true;
              break;
            }
          }
          if (!found) this.pushInterns(subStructure, idNgFor, param, tableParams);
        } else if (tableParams !== null) {
          console.log("subStructure['id_functional_type'] == 9", param["intern"], tableParams);
          for (let e in tableParams["param_intern"]) {
            if (e.split("-")[0] == subStructure["bd_table"] && e.split("-")[1] == subStructure["bd_field"] ) {
              for (let p in param["intern"]) {
                console.log("tableParams['param_intern'][e]",subStructure["id_db"],tableParams["param_intern"],e,param["intern"][p]);
                if (param["intern"][p]["id_db"] == subStructure["id_db"] && param["intern"][p]["bd_table"] == subStructure["bd_table"] && param["intern"][p]["bd_field"] == subStructure["bd_field"]) {
                  console.log("Found param intern table",subStructure,e,param,tableParams["param_intern"][e]);
                  param["intern"][p]["value"] = tableParams["param_intern"][e];
                  if (param["intern"][p]["value"] == "isbooleanfalse") param["intern"][p]["value"] = 0;
                  if (param["intern"][p]["value"] == "isbooleantrue") param["intern"][p]["value"] = 1;
                  console.log(param["intern"][p]["value"],tableParams["param_intern"][e],"tableParams['param_intern'][e]");
                  found = true;
                  break;
                }
              }
              if (!found)this.pushInterns(subStructure, idNgFor, param, tableParams);
            }
          }
        }
      }
      for (let e in subStructure["child"]) {
        this.findInterns(subStructure["child"][e], param, tableParams, idNgFor, idDSB);
      }
    }
  }

  pushInterns(subStructure, idNgFor, param, tableParams) {
    if (subStructure["id_functional_type"] != 9) {
      console.log(idNgFor, "idNgFor", subStructure["ngForId"]);
      console.log("Pushing new param intern no table", param["intern"], subStructure);
      param["intern"].push({
        id_db: subStructure["id_db"],
        bd_table: subStructure["bd_table"],
        bd_field: subStructure["bd_field"],
        value: subStructure["tmp_value"],
      });
    } else if (tableParams !== null) {
      let aux = null;
      console.log(subStructure["id_functional_area"], tableParams["param_intern"], "vamos a pushearlo");
      for (let e in tableParams["param_intern"]) {
        if (e.split("-")[1] == subStructure["bd_field"] && e.split("-")[0] == subStructure["bd_table"]) {
          aux = tableParams["param_intern"][e];
          if (aux == "isbooleanfalse") aux = 0;
          if (aux == "isbooleantrue") aux = 1;
          console.log("Pushing new param intern table", subStructure["bd_field"], subStructure["bd_table"], e, tableParams["param_intern"], param);
          break;
        }
      }
      param["intern"].push({
        id_db: subStructure["id_db"],
        bd_table: subStructure["bd_table"],
        bd_field: subStructure["bd_field"],
        value: aux,
      });
    }
  }

  findInternsDialog(subStructure: any, param, tableParams, idNgFor) {
    if (
      subStructure !== null &&
      subStructure !== undefined &&
      subStructure["id_functional_status_general"] != 2
    ) {
      if (subStructure["param_intern"] == 1) {
        let found = false;
        loop: for (let p in param["intern"]) {
          if (subStructure["id_functional_type"] != 9) {
            if (
              param["intern"][p]["id_db"] == subStructure["id_db"] &&
              param["intern"][p]["bd_table"] == subStructure["bd_table"] &&
              param["intern"][p]["bd_field"] == subStructure["bd_field"] &&
              subStructure["ngForId"] == idNgFor
            ) {
              found = true;
              console.log(
                "Found param intern no table",
                param["intern"][p],
                subStructure
              );
              if (subStructure["tmp_value"])
                param["intern"][p]["value"] = subStructure["tmp_value"];
              break;
            }
          } else if (tableParams !== null) {
            for (let e in tableParams["param_intern"]) {
              if (
                param["intern"][p]["id_db"] == subStructure["id_db"] &&
                param["intern"][p]["bd_table"] == e.split("-")[0] &&
                param["intern"][p]["bd_field"] == e.split("-")[1]
              ) {
                found = true;
                console.log(
                  "Found param intern table",
                  subStructure,
                  e,
                  param,
                  tableParams["param_intern"][e]
                );
                param["intern"][p]["value"] = tableParams["param_intern"][e];
                break loop;
              }
            }
          }
        }
        if (!found) {
          if (subStructure["id_functional_type"] != 9) {
            if (subStructure["ngForId"] == idNgFor) {
              console.log(
                "Pushing new param intern no table",
                param["intern"],
                subStructure
              );
              param["intern"].push({
                id_db: subStructure["id_db"],
                bd_table: subStructure["bd_table"],
                bd_field: subStructure["bd_field"],
                value: subStructure["tmp_value"],
              });
            }
          } else if (tableParams !== null) {
            let aux = null;
            for (let e in tableParams["param_intern"]) {
              if (
                e.split("-")[1] == subStructure["bd_field"] &&
                e.split("-")[0] == subStructure["bd_table"]
              ) {
                aux = tableParams["param_intern"][e];
                console.log(
                  "Pushing new param intern table",
                  subStructure,
                  e,
                  param,
                  tableParams["param_intern"][e]
                );
                break;
              }
            }
            param["intern"].push({
              id_db: subStructure["id_db"],
              bd_table: subStructure["bd_table"],
              bd_field: subStructure["bd_field"],
              value: aux,
            });
          }
        }
      }
      for (let e in subStructure["child"]) {
        this.findInternsDialog(
          subStructure["child"][e],
          param,
          tableParams,
          idNgFor
        );
      }
    }
  }

  openBottomSheet(structure: any) {
    let panelClass = [structure["class"]];
    if (this.genericService.shouldOpenExternalWindow)
      panelClass.push("overAllDialog");
    this.bottomSheetRef = this._bottomSheet.open(BottomSheetComponent, {
      panelClass: panelClass,
      data: {
        structure: structure["child"],
      },
    });
  }

  closeBottomSheet(strucuture: any) {
    this.bottomSheetRef.dismiss();
  }

  saveIdInLocalStorage(id: any) {
    localStorage.setItem("movin1", id);
  }

  formatDate(date, withSeconds = true) {
    if (date != "" && date !== null && date !== undefined) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = "" + d.getHours(),
        mins = "" + d.getMinutes(),
        seconds = "" + d.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;
      if (mins.length < 2) mins = "0" + mins;
      if (seconds.length < 2) seconds = "0" + seconds;

      if (withSeconds)
        return (
          [year, month, day].join("-") + " " + [hours, mins, seconds].join(":")
        );
      else return [year, month, day].join("-");
    } else return null;
  }

  eachRecursiveBool(obj, window: number): boolean {
    let child = null;
    let formField = 0;
    let database = null;
    let table = null;
    let field = null;
    let value = null;
    let relation = null;

    let form = null;
    let status = true;
    for (let k in obj) {
      if (k == "child" && typeof obj[k] === "object") {
        child = obj[k];
      } else if (k == "form_field") {
        formField = obj[k];
      } else if (k == "id_db") {
        database = obj[k];
      } else if (k == "bd_table") {
        table = obj[k];
      } else if (k == "bd_field") {
        field = obj[k];
      } else if (k == "tmp_value") {
        value = obj[k];
        if (value === undefined) value = null;
        if ((value == "" || value === undefined) && field == "id_product_mkt")
          value = 0;
        if (value == "" && field == "id_db") value = null;
      } else if (k == "id_table_relations") {
        relation = obj[k];
      } else if (k == "form") {
        form = obj[k];
      }
    }
    if (obj["id_functional_type"] == 15) obj["multiple"] == 0;

    if (formField == 1 && obj["id_functional_type"] != 113 && form === null)
      console.log("FORM FIELD MAL DECLARADO", obj);

    if (
      formField == 1 &&
      obj["id_functional_type"] != 113 &&
      form !== null &&
      !form["controls"][obj["id_functional_area"] + "-" + field].valid &&
      (form["value"][obj["id_functional_area"] + "-" + field] === null ||
        form["value"][obj["id_functional_area"] + "-" + field] == "") &&
      obj["window"] == window &&
      obj["id_db"] !== null &&
      obj["bd_table"] !== null
    ) {
      form["controls"][obj["id_functional_area"] + "-" + field]["touched"] =
        true;
      if (
        obj["id_functional_type"] == 15 ||
        obj["id_functional_type"] == 12 ||
        obj["id_functional_type"] == 6
      )
        obj["invalid"] = true;
      if (status) {
        let validation =
          form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]][
          "valid"
          ];
        if (obj["bd_table"] !== null) {
          if (
            obj["id_functional_type"] == 6 &&
            (obj["type"] == "datepicker" || obj["type"] == "datetime-local")
          ) {
            let ndate = this.formatDate(value);
            value = ndate;
          }
          if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
            value = value + ":00";
          }
          if (obj["type"] == "email" && value && value != "") {
            if (!this.validateEmail(value)) validation = false;
          }
          // Gestionar el caracter decimal
          if (
            obj["type"] == "decimal" &&
            value &&
            value != "" &&
            typeof value == "string"
          ) {
            value = value.replace(",", ".");
          }
          if (obj["multiple"] == 1) {
            let found = false;
            for (let i in obj["originalValue"]) {
              found = false;
              for (let j in obj["tmp_value"]) {
                if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                  found = true;
                }
              }
              if (!found) {
                this.removed.push({
                  functionalArea: obj["id_functional_area"],
                  database: database,
                  table: table,
                  field: field,
                  value: obj["originalValue"][i],
                  relation: relation,
                  label: obj["label"],
                });
              }
            }
            if (
              obj["id_functional_status_general"] == 5 &&
              obj["id_functional_type"] == 12 &&
              obj["type"] == "autocomplete" &&
              (!obj["tmp_value"] ||
                !Array.isArray(obj["tmp_value"]) ||
                obj["tmp_value"].length < 1)
            )
              validation = false;
          }
        }
        if (obj["id_functional_type"] == 121) {
          this.extractGeoFields(obj, database, table, relation, validation);
        }
        if (obj["id_functional_type"] != 121 && obj["timestamp"] === 0) {
          // -999
          //if(obj['id_functional_type'] == 12 && value == "") value = null;
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: obj["label"],
            value: value,
            relation: relation,
            valid: validation,
            label: obj["label"],
          });
        }
      }
      status = false;
    } else if (
      formField == 1 &&
      status &&
      obj["id_functional_type"] != 113 &&
      obj["window"] == window &&
      obj["id_db"] !== null &&
      obj["bd_table"] !== null
    ) {
      let validation =
        form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]][
        "valid"
        ];
      if (obj["bd_field"] !== null) {
        if (
          obj["id_functional_type"] == 6 &&
          (obj["type"] == "datepicker" || obj["type"] == "datetime-local")
        ) {
          let ndate = this.formatDate(value);
          value = ndate;
        }
        if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
          value = value + ":00";
        }
        if (obj["type"] == "email" && value && value != "") {
          if (!this.validateEmail(value)) validation = false;
        }
        // Gestionar el caracter decimal
        if (
          obj["type"] == "decimal" &&
          value &&
          value != "" &&
          typeof value == "string"
        ) {
          value = value.replace(",", ".");
        }
        if (obj["multiple"] == 1) {
          console.log("Form Field Multiple");
          console.log(obj["tmp_value"]);
          console.log(obj["originalValue"]);
          let found = false;
          for (let i in obj["originalValue"]) {
            found = false;
            for (let j in obj["tmp_value"]) {
              if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                found = true;
              }
            }
            if (!found) {
              this.removed.push({
                functionalArea: obj["id_functional_area"],
                database: database,
                table: table,
                field: field,
                value: obj["originalValue"][i],
                relation: relation,
                label: obj["label"],
              });
            }
          }
          if (
            obj["id_functional_status_general"] == 5 &&
            obj["id_functional_type"] == 12 &&
            obj["type"] == "autocomplete" &&
            (!obj["tmp_value"] ||
              !Array.isArray(obj["tmp_value"]) ||
              obj["tmp_value"].length < 1)
          )
            validation = false;
        }
      }
      if (obj["id_functional_type"] == 121) {
        this.extractGeoFields(obj, database, table, relation, validation);
      }
      if (
        obj["id_functional_type"] != 121 &&
        obj["bd_field"] !== null &&
        obj["timestamp"] === 0
      ) {
        // -999
        //if(obj['id_functional_type'] == 12 && value == "") value = null;
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: field,
          value: value,
          relation: relation,
          valid: validation,
          label: obj["label"],
        });
      }
      status = true;
      if (validation == false) status = false;
    } else if (child && obj["id_functional_type"] != 113) {
      for (let j in child) {
        if (
          status &&
          (child[j]["id_functional_status_general"] != 2 ||
            this.hasDSB(child[j]))
        )
          status = this.eachRecursiveBool(child[j], window);
      }
    }
    if (!status && obj["id_functional_type"] == 7) {
      console.log(obj);
      obj["expansion_start_opened"] = 1;
    }
    if (!status && obj["id_functional_type"] == 95) {
      let fa = this.genericService.findElementWithId(obj["id_functional_area"]);
      console.log(fa);
      fa["parent"]["selectedIndex"] = fa["key"];
    }
    if (status && obj["id_functional_type"] == 113 && obj["window"] == window) {
      if (
        obj["fileList"] !== null &&
        obj["fileList"] !== undefined &&
        obj["fileList"].length > 0
      ) {
        this.imageUpload.push(obj);
        let p = 0;
        let tempFile = [];
        if (obj["fileList"].length > 1) {
          for (let t in obj["fileList"]) {
            console.log(
              "ADDING IMAGE ",
              t,
              "for:",
              database,
              "   ",
              table,
              "     ",
              field,
              "      ",
              this.imageUpload.length - 1 + p
            );
            tempFile.push(this.imageUpload.length - 1 + p);
            p++;
          }
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: tempFile,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        } else {
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: this.imageUpload.length - 1,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        }
      } else if (
        (obj["required"] || obj["id_functional_status_general"] == 5) &&
        obj["bd_field"] !== null
      ) {
        status = false;
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: "archivos",
          value: value,
          relation: relation,
          valid: false,
          label: obj["label"],
        });
      }
    }
    return status;
  }

  hasDSB(struct) {
    if (struct["child"] !== null && struct["child"] !== undefined) {
      for (let t in struct["child"]) {
        if (
          struct["child"][t]["id_functional_type"] == 85 ||
          struct["child"][t]["id_functional_type"] == 92 ||
          struct["child"][t]["id_functional_type"] == 98
        )
          return true;
      }
      return false;
    }
  }

  private extractGeoFields(obj, database, table, relation, validation) {
    let geo_country_code = null;
    let geo_admin1_code = null;
    let geo_admin2_code = null;
    let geo_admin3_code = null;
    let geo_admin4_code = null;
    let name = null;
    let fullname = null;

    if (obj["tmp_value"] !== null && obj["tmp_value"] !== undefined) {
      if (
        !obj["tmp_value"]["country_code"] &&
        !obj["tmp_value"]["admin1_code"] &&
        !obj["tmp_value"]["admin2_code"] &&
        !obj["tmp_value"]["admin3_code"] &&
        !obj["tmp_value"]["admin4_code"] &&
        !obj["tmp_value"]["name"] &&
        !obj["tmp_value"]["fullname"]
      )
        return; // Per si ja hi havia un valor i no s'ha tocat el camp, que no ho maxaqui

      if (
        obj["tmp_value"]["country_code"] !== null &&
        obj["tmp_value"]["country_code"] !== undefined
      )
        geo_country_code = obj["tmp_value"]["country_code"];
      if (
        obj["tmp_value"]["admin1_code"] !== null &&
        obj["tmp_value"]["admin1_code"] !== undefined
      )
        geo_admin1_code = obj["tmp_value"]["admin1_code"];
      if (
        obj["tmp_value"]["admin2_code"] !== null &&
        obj["tmp_value"]["admin2_code"] !== undefined
      )
        geo_admin2_code = obj["tmp_value"]["admin2_code"];
      if (
        obj["tmp_value"]["admin3_code"] !== null &&
        obj["tmp_value"]["admin3_code"] !== undefined
      )
        geo_admin3_code = obj["tmp_value"]["admin3_code"];
      if (
        obj["tmp_value"]["admin4_code"] !== null &&
        obj["tmp_value"]["admin4_code"] !== undefined
      )
        geo_admin4_code = obj["tmp_value"]["admin4_code"];
      if (
        obj["tmp_value"]["name"] !== null &&
        obj["tmp_value"]["name"] !== undefined
      )
        name = obj["tmp_value"]["name"];
      if (
        obj["tmp_value"]["fullname"] !== null &&
        obj["tmp_value"]["fullname"] !== undefined
      )
        fullname = obj["tmp_value"]["fullname"];
    }
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_country_code",
      value: geo_country_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin1_code",
      value: geo_admin1_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin2_code",
      value: geo_admin2_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin3_code",
      value: geo_admin3_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin4_code",
      value: geo_admin4_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_name",
      value: name,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_fullname",
      value: fullname,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
  }

  public getTodayDate() {
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let todayDate = day + "-" + month + "-" + year;

    return todayDate;
  }

  public openSnackBar(msg, duration, panelClass = ["lightgreen-snackbar"]) {
    this.snackBar.open(msg, "X", {
      duration: duration,
      panelClass: panelClass,
    });
  }

  //Usar solo como parametro param.
  public applyFilter(filterValue: string, param: any): void {
    console.log("applyFilter");
    param.dataSource.filter = filterValue.trim().toLowerCase();

    if (param.dataSource.paginator) {
      param.dataSource.paginator.firstPage();
    }
  }

  //Gabri: puede recibir una string o un id como parametro y que corresponda a cada pantalla
  //con este id hacer un switch i adaptar aqui la funcion de format date poniendo esa funcion en el switch
  iniAllDates() {
    //getTodayDate
    //setMinDate
    /*const dt = new Date();
    this.dateToStringDBDate.getFullYear()
      + '-' +  ((this.dateToStringDBDate.getMonth() + 1) < 10 ? '0' : '') + (this.dateToStringDBDate.getMonth() + 1)
      + '-' +  (this.dateToStringDBDate.getDate() < 10 ? '0' : '') + this.dateToStringDBDate.getDate();*/
    let today = new Date();

    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.dateToday = day + "-" + month + "-" + year;

    let monthString = month.toString();
    let dayString = day.toString();
    if (day < 10) dayString = "0" + dayString;
    if (month < 10) monthString = "0" + monthString;
    this.minDate = dayString + "-" + monthString + "-" + (year - 100);
  }

  private findCount(current) {
    let max = 0;
    if (current) {
      console.log("currentcurrentcurrent", current["child"], max, current);
      for (let child in current["child"]) {
        let ext = 0;
        let max_child = this.findCount(current["child"][child]);
        console.log("currentcurrentcurrent 00", max_child);
        let id = (current["child"][child]["id_functional_area"] + "").split(
          "."
        );
        if (id.length > 1) {
          ext = Number(id[1]);
        }
        max = Math.max(ext, max_child);
        console.log("eeeeeeeeee", id, max, max_child, ext);
      }
      return max;
    }
  }

  private duplicateChilds(
    subtree,
    count,
    origin_duplicated_id_functional_area,
    tmp_data = false
  ) {
    if (subtree) {
      for (let key in subtree["child"]) {
        if (subtree["child"][key]) {
          this.duplicateChilds(
            subtree["child"][key],
            count,
            origin_duplicated_id_functional_area,
            tmp_data
          );
        }
        let id_functional_area = subtree["child"][key]["id_functional_area"];
        subtree["child"][key]["validatedForms"] = false;
        subtree["child"][key]["id_functional_area"] =
          subtree["child"][key]["id_functional_area"] + "." + count;

        subtree["child"][key]["id_functional_parent"] =
          subtree["child"][key]["id_functional_parent"] + "." + count;
        subtree["child"][key]["origin_duplicated_id_functional_area"] =
          origin_duplicated_id_functional_area + "." + count;
        subtree["child"][key]["duplicate_id_functional_area"] =
          subtree["child"][key]["duplicate_id_functional_area"] + "." + count;
        subtree["child"][key]["id_functional_area_original"] =
          subtree["child"][key][id_functional_area];
        subtree["child"][key]["duplicated_frontend"] = 1;
        subtree["child"][key]["ngForId"] =
          subtree["child"][key]["id_functional_area"];

        if (
          this.genericService.elementsThatAffectOthers[
          subtree["child"][key]["id_functional_area"]
          ]
        ) {
          this.genericService.elementsThatAffectOthers[
            subtree["child"][key]["id_functional_area"]
          ] = _.cloneDeep(
            this.genericService.elementsThatAffectOthers[
            subtree["child"][key]["id_functional_area"]
            ]
          );
        }
        for (let i in this.genericService.affectedElements) {
          for (let j in this.genericService.affectedElements[i]) {
            if (
              Object.keys(this.genericService.affectedElements[i][j]).includes(
                subtree["child"][key]["id_functional_area"]
              )
            ) {
              this.genericService.affectedElements[i][
                subtree["child"][key]["id_functional_area"]
              ] = this.genericService.affectedElements[i][j];
            }
          }
        }

        if (tmp_data == null || tmp_data == false || (subtree["child"][key] && !(subtree["child"][key]["bd_field"] == tmp_data["field"]
          && subtree["child"][key]["bd_table"] == tmp_data["table"] && subtree["child"][key]["id_db"] == tmp_data["database"])
          && (subtree["child"][key]["id_table_relations"] == 0 || subtree["child"][key]["id_table_relations"] == null))) {
          subtree["child"][key]["tmp_value"] = "";
          if (
            subtree["child"][key]["bd_field"] != "" &&
            subtree["child"][key]["form"] !== undefined &&
            subtree["child"][key]["form"]["controls"] !== undefined
          ) {
            if (
              subtree["child"][key]["form"]["controls"][
              subtree["child"][key]["bd_field"]
              ]
            ) {
              subtree["child"][key]["form"]["controls"][
                subtree["child"][key]["bd_field"]
              ]["value"] = "";
            }
          }
        }
      }
    }
  }

  private findElementWithDb(current, data) {
    if (current && current["child"]) {
      let val = {};
      current = current["child"];
      for (let i in current) {
        ("eventos_acciones");
        if (
          current[i] &&
          current[i]["bd_field"] == data["field"] &&
          current[i]["bd_table"] == data["table"] &&
          current[i]["id_db"] == data["database"]
        ) {
          return { parent: current, key: i, child: current[i] };
        } else {
          let data2 = this.findElementWithDb(current[i], data);
          if (data2) val = data2;
        }
      }
      return val;
    }
  }

  private setTmpValueToElement(element, data, index, isArray) {
    if (isArray) {
      element["tmp_value"] = data["value"][index];
    } else {
      element["tmp_value"] = data["value"];
    }
    if (element["form"]["controls"][element["bd_field"]]) {
      if (isArray) {
        element["form"]["controls"][element["bd_field"]]["value"] =
          data["value"][index];
      } else {
        element["form"]["controls"][element["bd_field"]]["value"] =
          data["value"];
      }
    }
  }

  private checkPermissions(structure, finished) {
    const mkt_status = structure["id_functional_status_mkt"];
    if (!structure["id_functional_status_mkt"]) return true;
    switch (mkt_status) {
      case 1:
        //this.openSnackBar("¡Todo bien!", 2000, ["green-snackbar"]);
        return true;
      //break;
      case 2:
        this.openSnackBar("¡Vaya, parece que no te quedan unidades!", 2000, [
          "red-snackbar",
        ]);
        this.finishFunction(finished);
        break;
      case 3:
        this.openSnackBar(
          "¡Vaya, parece que este producto ha caducado!",
          2000,
          ["red-snackbar"]
        );
        this.finishFunction(finished);
        break;
      case 4:
        this.openSnackBar(
          "¡Vaya, parece que no has comprado este producto!",
          2000,
          ["red-snackbar"]
        );
        this.finishFunction(finished);
        break;
      case 5:
        this.openSnackBar(
          "¡Vaya, parece que este producto no está disponible!",
          2000,
          ["red-snackbar"]
        );
        this.finishFunction(finished);
        break;
    }

    return false;
  }

  private duplicateElementUnified(
    structure,
    finished,
    hasParameters = false,
    type = 0
  ) {
    let node, tmp_data;
    let size = 0;
    if (type == 1) size = this.tmpArray.length;
    if (size == 0) size = 1;

    // Controlaremos el size
    for (let i = 0; i < size; ++i) {
      tmp_data = this.tmpArray[i];
      let length = 1; //Inicialitzem a 1 pel cas de que no tingui parametres
      if (hasParameters && Array.isArray(tmp_data.value)) {
        length = tmp_data.value.length; // en cas de que sigui form amb parametres s'actualitza el size
      }
      for (let j = 0; j < length; ++j) {
        if (hasParameters) tmp_data["route_param"] = this.routingService.routeParam;
        node = this.genericService.findElementWithId(structure["duplicate_id_functional_area"]);
        let max = this.findCount(node["parent"]) + 1;
        console.log("max", max);
        let subtree2 = _.cloneDeep(node["child"]);
        console.log(node, "nodenodenode", subtree2)
        this.duplicateChilds(
          subtree2,
          String(max),
          structure["duplicate_id_functional_area"]
        );
        let element;
        if (hasParameters) element = this.findElementWithDb(subtree2, tmp_data);

        node["parent"]["child"][max] = subtree2; // asigno el nou subtree duplicate
        node["parent"]["child"][max]["id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["id_functional_status_general"] = 1;
        node["parent"]["child"][max]["origin_duplicated_id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["duplicate_id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["duplicated_frontend"] = 1;
        node["parent"]["child"][max]["ngForId"] = node["parent"]["child"][max]["id_functional_area"] + max;
        node["parent"]["child"][max]['validatedForms'] = false;

        if (this.genericService.elementsThatAffectOthers[structure["id_functional_area"]]) {
          this.genericService.elementsThatAffectOthers[node["parent"]["child"][max]["id_functional_area"]] = _.cloneDeep(
            this.genericService.elementsThatAffectOthers[structure["id_functional_area"]]
          );
        }
        for (let i in this.genericService.affectedElements) {
          for (let j in this.genericService.affectedElements[i]) {
            if (Object.keys(this.genericService.affectedElements[i][j]).includes(structure["duplicate_id_functional_area"])) {
              this.genericService.affectedElements[i][
                node["parent"]["child"][max]["id_functional_area"]
              ] = this.genericService.affectedElements[i][j];
            }
          }
        }

        if (hasParameters) {
          if (!element || Object.keys(element).length === 0) {
            this.openSnackBar(
              "No existe ningun elemento con id_db, bd_table, bd_field",
              2000,
              ["red-snackbar"]
            );
          } else {
            this.setTmpValueToElement(
              element["child"],
              tmp_data,
              j,
              Array.isArray(tmp_data["value"])
            );
          }

          let value_tmp = tmp_data["value"];
          if (Array.isArray(tmp_data["value"]))
            value_tmp = tmp_data["value"][j];
          let params = {
            id_functional_area: structure["duplicate_id_functional_area"],
            id_language: this.authService.languageId,
            id_query_language:
              node["parent"]["child"][max]["id_query_language"],
            onlyOwner:
              structure["only_owner"] &&
              structure["only_owner"] == 1 &&
              structure["id_owner_field_filter"],
            id_owner_field_filter: structure["id_owner_field_filter"],
            tmp_data: {
              database: tmp_data["database"],
              field: tmp_data["field"],
              relation: tmp_data["relation"],
              table: tmp_data["table"],
              valid: tmp_data["valid"],
              value: value_tmp,
              route_param: tmp_data["route_param"],
            },
          };
          this.endpointService.parseLanguageTag(params).subscribe((data) => {
            let response = data["response"];
            console.log(response, "jan key 0");
            let keys = Object.keys(response);
            keys.forEach((key) => {
              console.log(
                node["parent"]["child"][max][key],
                response[key],
                "jan key"
              );
              if (key != "id_functional_area")
                node["parent"]["child"][max][key] = response[key];
            });
            if (Array.isArray(tmp_data["value"])) tmp_data["value"][j] = "";
            else tmp_data["value"] = "";
            this.finishFunction(finished);
          });
        } else {
        }
        // Construim de nou els forms del child per no tenir conflictes en les referencies
        node['parent']['validatedForms'] = false;
        this.genericService.findFormAttributes([node['parent']]);
        if (node["parent"]['id_functional_status_general'] == 2) node["parent"]['id_functional_status_general'] = node["parent"]['id_functional_status_initial'];
        this.finishFunction(finished);
      }
    }

    this.updateFormHistory(
      node["parent"]["id_functional_parent_initial_dsb"],
      node["parent"]["id_functional_parent_initial"],
      node["parent"],
      "duplicated",
      null,
      null,
      null,
      null,
      null,
      null,
      node["parent"]["child"][node["parent"]["child"].length - 1]["label"],
      hasParameters
    );
  }

  public async deleteFunctionalArea(structure) {
    if (structure["ngForId"]) {
      const id = structure["ngForId"];
      if (structure !== null) {
        const confirm = await this.genericService.openWarningDialog(
          4,
          3,
          structure
        );
        if (confirm) {
          this.genericService.findElementWithIdAndSetStatus(id, 2);
          if (structure["duplicated_frontend"] != 1) {
            let subStructure = structure;
            if (structure["id_functional_type"] == 5)
              subStructure = this.genericService.findElementWithId(structure["id_functional_parent"])["child"];
            let formFields = this.getParamsFA(subStructure);
            this.endpointService.deleteData(formFields).subscribe((data) => {
              this.openSnackBar(
                data["errorMessage"],
                7000,
                data["errorCode"] == 0
                  ? ["lightgreen-snackbar"]
                  : ["red-snackbar"]
              );
            });
          }
        }
      }
    }
  }

  public demoInmo(param): void {
    console.log("entro", param);
    this.endpointService
      .createInmoDemo(this.authService.userId)
      .subscribe((data) => {
        console.log("data", data);
        if (data["errorCode"] === 0) {
          this.snackBar.open(
            "Se ha creado una nueva demo. Entre con el ususario " +
            data["response"],
            "X",
            { duration: 6000, panelClass: ["lightgreen-snackbar"] }
          );
        } else {
          this.snackBar.open(
            "Error al crear la demo. Código de error " + data["errorCode"],
            "X",
            { duration: 6000, panelClass: ["red-snackbar"] }
          );
        }
      });
  }

  public loadClientsFromFile(param, finished, structure) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let dataAcc = this.genericService.getDataAccount();
      let interns = null;
      interns = this.getInternParam(structure, []);
      this.endpointService
        .loadClientsFromFile(
          dataAcc,
          this.tmpArray,
          this.removed,
          this.imageUpload,
          interns
        )
        .subscribe((data) => {
          console.log("data", data);
          if (data["errorCode"] === 0) {
            this.snackBar.open(
              "Se han importado correctamente todos los clientes.",
              "X",
              { duration: 6000, panelClass: ["lightgreen-snackbar"] }
            );
          } else {
            this.snackBar.open(
              "Se han importado todos los usuarios menos los de las filas: " +
              data["errorMessage"] +
              ". Puedes arreglar estas filas y volver a subirlas. Asegura de guardar las filas con error pues este mensaje no lo podrás volver a ver.",
              "X",
              { panelClass: ["red-snackbar"] }
            );
          }
          this.finishFunction(finished);
        });
    } else {
      console.log(finished);
      this.finishFunction(finished);
    }
  }

  public openEventDialog(structure, param, from = 1) {
    let intern_data = {};

    let clientType = null;

    let client = null;
    let idClient = null;
    let nameClient = null;

    let industrialesArray: SimpleClient[] = [];
    let industrial = null;
    let idIndustrial = null;
    let nameIndustrial = null;

    let property = null;
    let idProperty = null;
    let refProperty = null;
    let price = null;
    let internalName = null;

    let encargo = null;
    let idEncargo = null;
    let refEncargo = null;

    let offer = null;
    let idOffer = null;
    let refOffer = null;

    let event = null;
    let idEvent = null;

    let idDemandaRelated = null;

    if (from === 1 || from === 3) {
      if (structure == null) {
        structure = this.genericService.parentStructure;
      }

      intern_data = this.getInternParam(structure, param, 2);

      console.log(intern_data, "intern_data");

      // Para que los coja, todos deben ser params_interns

      for (let i in intern_data) {
        if (
          intern_data[i]["id_db"] == 1 &&
          intern_data[i]["bd_table"] == "relacion_cliente_tipo" &&
          intern_data[i]["bd_field"] == "id_tipo_cliente"
        ) {
          clientType = intern_data[i]["value"];
          console.log(clientType, "clientType");
        }
        if (
          from === 1 &&
          intern_data[i]["id_db"] == 1 &&
          intern_data[i]["bd_table"] == "actividades_calendario" &&
          intern_data[i]["bd_field"] == "id"
        ) {
          idEvent = intern_data[i]["value"];
          console.log(idEvent, "idEvent");
          break;
        }
      }
    }
    console.log(intern_data, "intern_dataintern_data");

    if (from === 2) {
      console.log(param, "param from 2 JAN");
      idEvent = param.actividad[0].id;
    }

    if (idEvent === null && (from === 1 || from === 3)) {
      for (let i in intern_data) {
        if (clientType === 7) {
          // INDUSTRIAL
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "id"
          ) {
            idIndustrial = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "nombre"
          ) {
            if (nameIndustrial === null) {
              nameIndustrial = intern_data[i]["value"];
            } else {
              nameIndustrial = intern_data[i]["value"] + " " + nameIndustrial; // Vol dir que té apellidos y fiquem abans el nombre
            }
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "apellidos"
          ) {
            if (nameIndustrial === null) {
              nameIndustrial = intern_data[i]["value"];
            } else {
              nameIndustrial = nameIndustrial + " " + intern_data[i]["value"]; // Vol dir que té nombre y afegim apellidos
            }
          }
        } else if (clientType === 8) {
          // CLIENTE COMUNIDAD
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "id"
          ) {
            idClient = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "referencia"
          ) {
            nameClient = intern_data[i]["value"];
          }
        } else if (clientType === 10) {
          // ENTIDAD
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "id"
          ) {
            idClient = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "nombre_empresa"
          ) {
            nameClient = intern_data[i]["value"];
          }
        } else {
          // RESTO DE CASOS DE CLIENTES + OFERTAS + ENCARGOS + INMUEBLES + USUARIOS
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "id"
          ) {
            idClient = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "nombre"
          ) {
            if (nameClient === null) {
              nameClient = intern_data[i]["value"];
            } else {
              nameClient = intern_data[i]["value"] + " " + nameClient; // Vol dir que té apellidos y fiquem abans el nombre
            }
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "apellidos"
          ) {
            if (nameClient === null) {
              nameClient = intern_data[i]["value"];
            } else {
              nameClient = nameClient + " " + intern_data[i]["value"]; // Vol dir que té nombre y afegim apellidos
            }
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "vivienda" &&
            intern_data[i]["bd_field"] == "id"
          ) {
            idProperty = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "vivienda" &&
            intern_data[i]["bd_field"] == "referencia_inmo"
          ) {
            refProperty = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "vivienda" &&
            intern_data[i]["bd_field"] == "precio"
          ) {
            price = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "vivienda" &&
            intern_data[i]["bd_field"] == "nombre_vivienda_inmo"
          ) {
            internalName = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "encargo" &&
            intern_data[i]["bd_field"] == "id"
          ) {
            idEncargo = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "encargo" &&
            intern_data[i]["bd_field"] == "codigo_encargo"
          ) {
            refEncargo = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "clientes" &&
            intern_data[i]["bd_field"] == "id_cliente"
          ) {
            idClient = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "ofertas" &&
            intern_data[i]["bd_field"] == "id"
          ) {
            idOffer = intern_data[i]["value"];
          }
          if (
            intern_data[i]["id_db"] == 1 &&
            intern_data[i]["bd_table"] == "ofertas" &&
            intern_data[i]["bd_field"] == "nombre_oferta"
          ) {
            refOffer = intern_data[i]["value"];
          }
        }
        if (
          intern_data[i]["id_db"] == 1 &&
          intern_data[i]["bd_table"] == "actividades_calendario" &&
          intern_data[i]["bd_field"] == "id"
        ) {
          idDemandaRelated = intern_data[i]["value"];
          console.log(idDemandaRelated, "idDemandaRelatedidDemandaRelated");
        }
      }
      // SI tinc idEncargo && this.genericService.parentStructure[0]['id_functional_parent_initial'] == 2681
      // SI idCliente pero no tinc nameClient && --> El busco a resutls a la query 139 al genericservice.selectOptions
      // Agafa industrisls de genericservice.tableData 2880 --> Busquem nombre i id dels n industrials
      if (
        idEncargo !== null &&
        this.genericService.parentStructure[0][
        "id_functional_parent_initial"
        ] == 2681
      ) {
        // He de añadir el cliente y los industriales al encargo
        // Añado el cliente
        if (idClient !== null && nameClient == null) {
          this.genericService.selectOptions[139].forEach((c) => {
            if (c.value == idClient) nameClient = c.text;
          });
        }
        // Añado industriales
        this.genericService.tableData[2880].data.forEach((industrial) => {
          let name = null;
          let idInd = null;
          Object.keys(industrial).forEach((i) => {
            if (
              i.includes("nombre") &&
              !i.includes("nombre_") &&
              name == null
            ) {
              name = industrial[i];
              if (Array.isArray(industrial[i])) name = industrial[i][0];
            } else if (
              i.includes("id") &&
              !i.includes("id_") &&
              idInd == null
            ) {
              idInd = industrial[i];
              if (Array.isArray(industrial[i])) idInd = industrial[i][0];
            }
          });
          let simpleClient = new SimpleClient(idInd, name);
          industrialesArray.push(simpleClient);
        });
      }
      if (idClient !== null && nameClient !== null)
        client = new SimpleClient(idClient, nameClient);
      if (idProperty !== null && refProperty !== null)
        property = new SimpleProperty(
          idProperty,
          refProperty,
          price,
          internalName
        );
      if (idEncargo !== null && refEncargo !== null)
        encargo = new SimpleEncargo(idEncargo, refEncargo);
      if (idOffer !== null && refOffer !== null)
        offer = new SimpleOffer(idOffer, refOffer);
      if (idIndustrial !== null && nameIndustrial !== null)
        industrial = new SimpleClient(idIndustrial, nameIndustrial);
      if (industrialesArray.length > 0) industrial = industrialesArray;

      console.log("client", client, idClient, nameClient);
      console.log(
        "property",
        property,
        idProperty,
        refProperty,
        price,
        internalName
      );
      console.log("encargo", idEncargo, refEncargo, encargo);
      console.log("offer", idOffer, refOffer, offer);
      let panelClass = [];
      if (this.genericService.shouldOpenExternalWindow)
        panelClass.push("overAllDialog");
      const bottomSheet = this._bottomSheet.open(EditEventDialog, {
        panelClass: panelClass,
        data: {
          simpleClient: client,
          simpleProperty: property,
          simpleEncargo: encargo,
          simpleOffer: offer,
          simpleIndustriales: industrial,
          event: event,
          idDemandaRelated: idDemandaRelated,
        },
      });
      bottomSheet.afterDismissed().subscribe((result) => {
        if (result) {
          console.log(result, "resultresult");
          this.openSnackBar(result["AddedMessageBackend"], 6000, [
            "lightgreen-snackbar",
          ]);
          this.genericService.refreshStructure(0);
        }
      });
    } else if (idEvent > 0) {
      console.log(idEvent, "entro aqui idEvent no null");
      this.endpointService
        .getCalendarOneActivity(idEvent, this.authService.userId)
        .subscribe((data) => {
          Object.keys(data["response"]).forEach((row) => {
            console.log(data["response"]);
            const id = data["response"][row]["id"];
            const comments = data["response"][row]["comments"];
            const location = data["response"][row]["location"];
            const creatorId = data["response"][row]["creator_id"];
            const title = data["response"][row]["title"];
            const startDate =
              data["response"][row]["start_date"] == null
                ? null
                : new Date(data["response"][row]["start_date"]);
            const endDate =
              data["response"][row]["end_date"] == null
                ? null
                : new Date(data["response"][row]["end_date"]);
            const idCalendarioGoogle =
              data["response"][row]["id_calendario_google"];
            const typeId = data["response"][row]["type_id"];
            const typeName = data["response"][row]["type_name"];
            const typeColor = data["response"][row]["type_color"];
            const typeCategory = data["response"][row]["id_categoria"];
            const active = data["response"][row]["active"];
            const isPrivate = data["response"][row]["privada"];
            const firmar = data["response"][row]["firmar"];
            const control_calendario =
              data["response"][row]["control_calendario"];
            const signLike = data["response"][row]["firma_gusta"];
            const signNotify = data["response"][row]["firma_recibir_alertas"];
            const signDNI = data["response"][row]["firma_DNI"];
            const signImgURL = data["response"][row]["firma_img_url"];
            const signObservation =
              data["response"][row]["firma_observaciones"];
            const allDay = data["response"][row]["dia_completo"];
            const idSerie = data["response"][row]["id_serie"];
            const idAviso1 = data["response"][row]["id_aviso1"];
            const idAviso2 = data["response"][row]["id_aviso2"];
            const idAviso3 = data["response"][row]["id_aviso3"];
            const estado = data["response"][row]["estado"];
            const can_motivo = data["response"][row]["can_motivo"];
            const viviendas = data["response"][row]["viviendas"];
            let viviendasArray: SimpleProperty[] = [];
            if (viviendas != undefined && viviendas !== null) {
              Object.keys(viviendas).forEach((property) => {
                let simpleProperty = new SimpleProperty(
                  viviendas[property]["id"],
                  viviendas[property]["reference"]
                );
                viviendasArray.push(simpleProperty);
              });
            }
            const clientes = data["response"][row]["clientes"];
            let clientesArray: SimpleClient[] = [];
            if (clientes != undefined && clientes !== null) {
              Object.keys(clientes).forEach((client) => {
                let simpleClient = new SimpleClient(
                  clientes[client]["id"],
                  clientes[client]["fullName"]
                );
                clientesArray.push(simpleClient);
              });
            }
            const usuarios = data["response"][row]["usuarios"];
            let usuariosArray: SimpleUser[] = [];
            if (usuarios != undefined && usuarios !== null) {
              Object.keys(usuarios).forEach((user) => {
                let simpleUser = new SimpleUser(
                  usuarios[user]["id"],
                  usuarios[user]["user"]
                );
                usuariosArray.push(simpleUser);
              });
            }
            const industriales = data["response"][row]["industriales"];
            let industrialesArray: SimpleClient[] = [];
            if (industriales != undefined && industriales !== null) {
              Object.keys(industriales).forEach((client) => {
                let simpleClient = new SimpleClient(
                  industriales[client]["id"],
                  industriales[client]["fullName"]
                );
                industrialesArray.push(simpleClient);
              });
            }
            const encargos = data["response"][row]["encargos"];
            let encargosArray: SimpleEncargo[] = [];
            if (encargos != undefined && encargos !== null) {
              Object.keys(encargos).forEach((encargo) => {
                let simpleEncargo = new SimpleEncargo(
                  encargos[encargo]["id"],
                  encargos[encargo]["reference"]
                );
                encargosArray.push(simpleEncargo);
              });
            }
            const arquitectos = data["response"][row]["clientes"];
            const idOferta = data["response"][row]["id_oferta"];
            const nombreOferta = data["response"][row]["nombre_oferta"];
            const nombreCreador = data["response"][row]["nombre_creador"];
            const idCuenta = data["response"][row]["id_cuenta"];
            const cuentaTipo = data["response"][row]["cuenta_tipo"];
            const idEmpresa = data["response"][row]["id_empresa"];
            const idParentPeriodicity =
              data["response"][row]["id_padre_periodicidad"];
            // const foto = data['response'][row]['foto'] || "https://movin.cloud/assets/img/default/perfil.png";
            const foto = data["response"][row]["foto"];
            let fotoPerfil =
              "https://movin.cloud/assets/img/default/perfil.png";
            if (foto) {
              fotoPerfil = foto;
            }
            let oferta = null;
            let type: EventType = new EventType();
            let action = {
              label:
                '<img title="' +
                nombreCreador +
                '" class="fotoEvento" src="' +
                fotoPerfil +
                '" alt="">',
              onClick: ({ event }: { event: CustomEvent }): void => { },
            };

            if (typeId !== null && typeName !== null && typeColor !== null) {
              type = new EventType(
                typeId,
                typeName,
                typeColor,
                typeCategory,
                active,
                isPrivate,
                firmar,
                control_calendario
              );
            }

            const tempEvent = new CustomEvent(
              title,
              comments,
              id,
              creatorId,
              startDate,
              endDate,
              idCalendarioGoogle,
              type,
              signLike,
              signNotify,
              signDNI,
              signImgURL,
              signObservation,
              allDay,
              idSerie,
              idAviso1,
              idAviso2,
              idAviso3,
              estado,
              can_motivo,
              viviendasArray,
              clientesArray,
              usuariosArray,
              industrialesArray,
              encargosArray,
              arquitectos,
              idOferta,
              nombreCreador,
              undefined,
              undefined,
              idCuenta,
              cuentaTipo,
              idParentPeriodicity,
              location
            );
            // this.authService.getAccount(idEmpresa).then(res => { tempEvent.account = res; });
            tempEvent.account = this.authService.accountsGeneric.filter(
              (e) => e.id === idEmpresa
            )[0];

            if (idOferta && nombreOferta) {
              oferta = new SimpleOffer(idOferta, nombreOferta);
              tempEvent.setOffer(oferta);
            }

            if (creatorId !== this.authService.userId) {
              tempEvent.actions.push(action);
              tempEvent.draggable = false;
              tempEvent.resizable.afterEnd = false;
              tempEvent.resizable.beforeStart = false;
            }
            event = tempEvent;

            let panelClass = [];
            if (this.genericService.shouldOpenExternalWindow)
              panelClass.push("overAllDialog");
            const bottomSheet = this._bottomSheet.open(EditEventDialog, {
              panelClass: panelClass,
              data: {
                simpleClient: client,
                simpleProperty: property,
                simpleEncargo: encargo,
                simpleOffer: offer,
                simpleIndustriales: industrial,
                event: event,
                idDemandaRelated: idDemandaRelated,
              },
            });
            bottomSheet.afterDismissed().subscribe((result) => {
              if (result) {
                console.log(result, "resultresult");
                this.openSnackBar(result["AddedMessageBackend"], 6000, [
                  "lightgreen-snackbar",
                ]);
                this.genericService.refreshStructure(0);
              }
            });
          });
        });
    }
  }

  public openColorPickerDialog(param): void {
    console.log("openColorPickerDialog");
    let panelClass = [];
    if (this.genericService.shouldOpenExternalWindow)
      panelClass.push("overAllDialog");
    const dialogRef = this.dialog.open(ColorPickerDialog, {
      width: "auto",
      height: "auto",
      data: param.type,
      autoFocus: true,
      panelClass: panelClass,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // type is null => add new category
      if (param.type == null && result != null) {
        const name = result["name"];
        const color = result["color"];
        const isPrivate = result["isPrivate"];
        const signable = result["signable"];
        const portalVisitCompatibility = result["portalVisitCompatibility"];
        const profileId = this.authService.getLoggedInUserId();
        this.endpointService
          .addNewCategoryType(
            {
              name: name,
              color: color,
              isPrivate: isPrivate,
              signable: signable,
              portalVisitCompatibility: portalVisitCompatibility,
            },
            profileId,
            this.authService.returnActiveAccountId(),
            this.authService.returnActiveAccountType()
          )
          .subscribe((data) => {
            this.endpointService
              .getCategories(
                profileId,
                this.authService.returnActiveAccountId(),
                this.authService.returnActiveAccountType(),
                this.authService.isOrphan
              )
              .subscribe((data_cat) => {
                setCategories(data_cat);
                this.openSnackBar("Categorí­a añadida", 6000, [
                  "lightgreen-snackbar",
                ]);
                // this._changeDetectorRef.detectChanges();
              });
            // this._changeDetectorRef.detectChanges();
          });
      } else if (param.type != null && result != null) {
        const name = result["name"];
        const color = result["color"];
        const isPrivate = result["isPrivate"];
        const signable = result["signable"];
        const portalVisitCompatibility = result["portalVisitCompatibility"];

        const typeId = param.type.id;
        const profileId = this.authService.getLoggedInUserId();
        this.endpointService
          .updateCategoryType(
            {
              name: name,
              color: color,
              isPrivate: isPrivate,
              signable: signable,
              portalVisitCompatibility: portalVisitCompatibility,
            },
            profileId,
            typeId
          )
          .subscribe((data) => { });
        this.endpointService
          .getCategories(
            profileId,
            this.authService.returnActiveAccountId(),
            this.authService.returnActiveAccountType(),
            this.authService.isOrphan
          )
          .subscribe((data) => {
            setCategories(data);
          });
      }
      this.update_category.emit(true);
    });
  }

  public insertFormData(structure, param, finished, refresh, type, publicar) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      if (publicar !== null) this.updateStatusVivienda(publicar);
      this.genericService.dirtyForm = false;
      console.log(this.imageUpload, "llego???? 4");
      let dataAcc = this.genericService.getDataAccount();
      let interns = null;
      interns = this.getInternParam(structure, [], type);
      console.log("PARAMS INSERT:", interns);
      return this.endpointService.insertFormData(
        dataAcc,
        this.tmpArray,
        this.removed,
        this.imageUpload,
        interns
      );
    } else {
      console.log(finished);
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  public updateStatusVivienda(type) {
    for (let tmp in this.tmpArray) {
      if (
        this.tmpArray[tmp]["field"] == "estado" &&
        this.tmpArray[tmp]["table"] == "vivienda" &&
        this.tmpArray[tmp]["database"] == 1
      ) {
        if (type == 0)
          this.tmpArray[tmp]["value"] =
            this.tmpArray[tmp]["value"] == 4 ? 9 : this.tmpArray[tmp]["value"];
        else if (type == 1) this.tmpArray[tmp]["value"] = 4;
      }
    }
  }

  public saveParams(structure, tableParams) {
    let paramControl = JSON.parse(localStorage.getItem("paramControl"));
    let idCompany = this.authService.getIdCompany(true);
    let paramControlUser = paramControl[idCompany];
    this.routingService.routeParam = [];
    console.log('paramControlUser', paramControlUser)
    console.log('structure99', structure)
    if (paramControlUser != null) {
      let i = 0;
      for (let par of paramControlUser) {
        if (par["id_pantalla"] == structure["id_functional_parent_initial"]) {
          if (structure["id_functional_type"] != 3) {
            for (let s in this.genericService.parentStructure) {
              paramControlUser[i]["params"]["output"] = this.findParams(
                paramControlUser[i]["params"]["output"],
                this.genericService.parentStructure[s],
                tableParams
              );
            }
            if (structure["id_functional_parent_initial_dsb"] > 0) {
              for (let s in this.genericService.dsbStructureOpened) {
                paramControlUser[i]["params"]["output"] = this.findParams(
                  paramControlUser[i]["params"]["output"],
                  this.genericService.dsbStructureOpened[s],
                  tableParams
                );
              }
            }
          } else {
            for (let aux in tableParams["tmp_param"]) {
              let found = false;
              for (let out of paramControlUser[i]["params"]["output"]) {
                if (out["bd_field"] == aux) {
                  found = true;
                  out["value"] = tableParams["tmp_param"][aux];
                }
              }
              if (!found) {
                paramControlUser[i]["params"]["output"].push({
                  id_db: structure["id_db"],
                  bd_table: structure["bd_table"],
                  bd_field: aux,
                  value: tableParams["tmp_param"][aux],
                });
              }
            }
          }
        }
        this.routingService.routeParam = paramControlUser[i]["params"]["output"];
        ++i;
      }
    }
    localStorage.setItem("paramControl", JSON.stringify(paramControl));
    this.route = structure["internal_routing"];
    this.id_route = structure["internal_routing_id_functional_area"];
    this.routingService.previousModule =
      structure["id_functional_parent_initial"];
  }

  public duplicateFunctionalAreaDB(structure, finished) {
    this.formsArray(structure, finished);
    let idToDuplicate = this.tmpArray[1]["value"];
    let idParent = this.tmpArray[0]["value"];
    let data = { idToDuplicate, idParent };

    this.endpointService.duplicateFunctionalAreaDB(data).subscribe((data) => {
      this.finishFunction(finished);
      this.openSnackBar(
        "Â¡LO TENGO, YA TE LO HE DUPLICADO Y SUBIDO! ;)",
        6000,
        ["green-snackbar"]
      );
    });
  }

  public getInternParam(structure, param, type = 0) {
    let paramControl3 = JSON.parse(localStorage.getItem("paramControl"));
    let idCompany = this.authService.getIdCompany(true);
    let paramControl3User = [];
    let isExternalWindow = this.genericService.shouldOpenExternalWindow && this.authService.externalWindowStructure !== null && this.authService.externalWindowStructure !== undefined;
    let idFAInitial = +structure["id_functional_parent_initial"];
    if (paramControl3 && paramControl3[idCompany]) paramControl3User = paramControl3[idCompany];
    if (paramControl3User != null) {
      if (isExternalWindow) {
        const found = paramControl3User.some((p) => p.id_pantalla == idFAInitial);
        if (!found) paramControl3User.push({id_pantalla: idFAInitial, params: { input: [], intern: [], output: [] }});
      }
      console.log("sfkljadsfnlkajsndfasdkljfn", paramControl3User, idFAInitial);
      for (let par of paramControl3User) {
        if (par["id_pantalla"] == idFAInitial) {
          par["params"]["intern"] = [];
          if (structure["id_functional_type"] != 3) {
            let structure2 = this.genericService.parentStructure;
            if(isExternalWindow) structure2 = this.authService.externalWindowStructure;
            if(!Array.isArray(structure2)) structure2 = [structure2];
            for (let e in structure2) {
              console.log(param, "parparparpar");
              this.findInterns(structure2[e], par["params"], param, structure["ngForId"], structure["id_functional_parent_initial_dsb"]);
            }
          } else { //table
            this.getParamInternTable(param, par, structure);
          }

          console.log(paramControl3, par["params"]["intern"], structure, "paramControl3paramControl3");
          if (type != 2) localStorage.setItem("paramControl", JSON.stringify(paramControl3));
          return par["params"]["intern"];
        }
      }
    }
  }

  private getParamInternTable(param: any, par: any, structure: any) {
    for (let aux in param["param_intern"]) {
      console.log(aux, "aux");
      console.log(Object.keys(param));
      if (param["param_intern"][aux] == "isbooleantrue") param["param_intern"][aux] = 1;
      if (param["param_intern"][aux] == "isbooleanfalse") param["param_intern"][aux] = 0;
      if (this.hasBDField(param, aux)) {
        let found = false;
        for (let intern of par["params"]["intern"]) {
          if (intern["bd_field"] == aux.split("-")[1] && intern["bd_table"] == aux.split("-")[0]) {
            found = true;
            intern["value"] = param["param_intern"][aux];
            //break;
          }
        }
        if (!found) {
          par["params"]["intern"].push({
            id_db: structure["id_db"],
            bd_table: aux.split("-")[0],
            bd_field: aux.split("-")[1],
            value: param["param_intern"][aux],
          });
        }
      }
    }
  }

  private hasBDField(params, field) {
    for (let p in params) {
      if (p.includes(field.split("-")[1])) return true;
    }
    return false;
  }

  private getInternParamDialog(structure, param) {
    console.log(structure);
    console.log(param);
    let par = {
      id_pantalla: 0,
      params: {
        intern: [],
      },
    };
    if (structure["id_functional_type"] != 3) {
      let structure3 = this.genericService.findElementWithId(structure["id_functional_parent_initial_dsb"]);
      console.log("substructure3 ", structure3);
      this.findInternsDialog(
        structure3["child"],
        par["params"],
        param,
        structure["ngForId"]
      );
    } else {
      //table
      console.log(param, "PARAMS TABLE");
      par["params"]["intern"] = [];
      this.getParamInternTable(param, par, structure);
    }
    return par["params"]["intern"];
  }

  private generateNewOutputParam(structure) {
    let paramControl2 = JSON.parse(localStorage.getItem("paramControl"));
    let paramControl2User = paramControl2[this.authService.getIdCompany(true)];
    if (paramControl2User != null) {
      for (let par of paramControl2User) {
        if (par["id_pantalla"] == structure["id_functional_parent_initial"]) {
          par["params"]["output"] = [];
        }
      }
    }
    return paramControl2;
  }

  private getInputParam(structure) {
    //console.log("im the first");
    //let x = localStorage.getItem("paramControl");
    let paramControl2 = JSON.parse(localStorage.getItem("paramControl"));
    paramControl2 = paramControl2[this.authService.getIdCompany(true)];
    //console.log(x[0]['params']['intern']);
    //console.log(x.length);
    if (paramControl2 != null) {
      for (let par of paramControl2) {
        if (par["id_pantalla"] == structure["id_functional_parent_initial"]) {
          return par["params"]["input"];
        }
      }
    }
    return false;
  }

  openExternalUrl(structure) {
    console.log("openExternalUrl", structure);
    window.open(structure["internal_routing"], "_blank");
  }

  findFunctionalArea(structure, id) {
    let str = null;
    if (structure["id_functional_area"] == id) str = structure;
    else {
      if (structure["child"]) {
        for (let e in structure["child"]) {
          if (str === null)
            str = this.findFunctionalArea(structure["child"][e], id);
        }
      }
    }
    return str;
  }

  revertValues(structure) {
    this.genericService.revertDuplicateFromValue(
      structure["id_functional_parent_initial"],
      null,
      structure
    );
  }

  deleteDataDialog(fa, type) {
    let dialog = this.genericService.findElementWithId(fa["window"])["child"];
    let formFields = this.getParamsFA(dialog);
    this.endpointService.deleteData(formFields).subscribe((data) => {
      this.openSnackBar(
        data["errorMessage"],
        7000,
        data["errorCode"] == 0 ? ["lightgreen-snackbar"] : ["red-snackbar"]
      );
      if (data["errorCode"] == 0) {
        if (type == 0) {
          this.dialog.closeAll();
          this.routingService.reloadPage(this.router.url);
        } else if (type == 4) {
          this.genericService.refreshStructure(2);
        } else {
          let fake = { child: this.genericService.parentStructure };
          let idDialogParent = this.genericService.findElementWithId(fa["internal_routing_id_functional_area"])["child"];
          if (type == 1)
            this.getFunction({ child: Array(idDialogParent), id_function: 2 });
          if (type == 2)
            this.getFunction({ child: Array(idDialogParent), id_function: 45 });
          if (type == 3)
            this.getFunction({ child: Array(idDialogParent), id_function: 35 });
        }
      }
    });
  }

  public reloadQueries(structure, param, finished) {
    let validReq = this.formsArray(structure, param, false);
    let allData = {
      paramInterns: this.getInternParam(structure, param),
      actualValueFields: JSON.parse(JSON.stringify(this.tmpArray)),
      dataAccount: this.genericService.getDataAccount(),
      faWithQueriesToRefresh: structure["internal_routing_id_functional_area"],
      staticHTML: this.genericService.staticHTML,
    };
    this.endpointService.reloadQueries(allData).subscribe((data) => {
      if (data["response"] != null && data["response"] != "") {
        //Refrescamos el service de queries
        for (let key in data["response"]) {
          this.genericService.selectOptions[data["response"][key]["idQuery"]] =
            data["response"][key]["result"];
          this.genericService.selectOptionsHeader[
            data["response"][key]["idQuery"]
          ] = data["response"][key]["result"];
          this.genericService.selectOptionsFooter[
            data["response"][key]["idQuery"]
          ] = data["response"][key]["result"];
        }
      }
      this.finishFunction(finished);
    });
  }

  private getParamsFA(dialog) {
    let params = [];
    params = this.getFormFields(dialog);
    console.log(params);
    let formFields = [];
    for (let g in params) {
      let formField = {
        database: params[g]["id_db"],
        table: params[g]["bd_table"],
        field: params[g]["bd_field"],
        value: params[g]["tmp_value"],
        relation: 0,
        valid: true,
      };
      formFields.push(formField);
    }
    return formFields;
  }

  getFormFields(subStructure) {
    let aux = [];
    if (subStructure) {
      console.log(subStructure);
      if (subStructure["form_field"] == 1) aux.push(subStructure);
      console.log(aux);
      if (subStructure["child"])
        for (let w in subStructure["child"])
          aux = aux.concat(this.getFormFields(subStructure["child"][w]));
    }
    return aux;
  }

  validateEmail(email) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  updateConditionsCustom(finished, structure) {
    this.endpointService
      .updateConditionsCustom(this.arrayConditions)
      .subscribe((data) => {
        this.finishFunction(finished);
        console.log(structure, "structure");
        this.openSnackBar("Guardado con éxito", 15000, ["green-snackbar"]);
        this.dialog.closeAll();
        if (data["response"] != null && data["response"] != "") {
          let idFA = this.findFAWithTableField(
            this.genericService.parentStructure,
            1,
            "eventos",
            "consulta_autogenerada_condiciones",
            0
          );
          if (idFA == null || idFA == undefined)
            idFA = this.findFAWithTableField(
              this.genericService.parentStructure,
              1,
              "eventos_reportes",
              "consulta_autogenerada_condiciones",
              0
            );
          idFA["form"]["controls"][
            idFA["id_functional_area"] + "-" + idFA["bd_field"]
          ].setValue(data["response"]);
        }
      });
  }

  updatePlantillaElementsCustom(structure, param, finished) {
    console.log(this.templateService.arrayElementsPlantillaSubject, "Subject");
    this.templateService.generateHTMLResult();
    this.endpointService.updatePlantillaElementsCustom(this.templateService.arrayElementsPlantilla, this.templateService.arrayElementsPlantillaSubject, this.templateService.plantillaInfo, ).subscribe((data) => {
      this.finishFunction(finished);
      if (data["errorMessage"] !== "OK!") {
        this.openSnackBar(data["errorMessage"], 7000, ["red-snackbar"]);
      }
      this.openSnackBar("Guardado con éxito", 7000, ["green-snackbar"]);
    });
  }

  sendEmailInmueble(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      this.endpointService
        .sendEmailInmueble(
          values,
          this.authService.userId,
          this.authService.getIdEmpresa()
        )
        .subscribe((data) => { });
    } else {
      console.log(finished);
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  getTextInmueble(structure, param, finished, type) {
    let idCompany = this.authService.empresaId;
    let language = null;
    let idProperty = null;
    let validReq = this.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let table = null;
    let field = null;

    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        language = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "id"
      ) {
        idProperty = values[i]["value"];
      }
    }

    if (type == 1 || type == 4) {
      table = "whatsapp_cliente_comodin";
      field = "mensaje";
    } else if (type == 2 || type == 5) {
      table = "email_comodin";
      field = "mensaje";
    } else if (type == 3 || type == 6) {
      table = "email_comodin";
      field = "asunto";
    }

    let value = -1;
    if (type == 1 || type == 2 || type == 3) {
      value = idCompany;
    } else if (type == 4 || type == 5 || type == 6) {
      value = idProperty;
    }

    this.endpointService
      .getTextInmueble(value, language, type)
      .subscribe((data) => {
        let message = "";
        if (
          data["response"].length > 0 &&
          data["response"][0]["mensaje"] !== undefined
        ) {
          message = data["response"][0]["mensaje"];
        }

        if (message != null && message != "") {
          let idFA = this.findFAWithTableField(this.genericService.parentStructure, 1, table, field, structure["id_functional_parent_initial_dsb"]);
          idFA["form"]["controls"][idFA["id_functional_area"] + "-" + idFA["bd_field"]].setValue(message);

          if (type == 1 || type == 2 || type == 3) {
            this.openSnackBar(
              "¡Ya lo tienes! Puedes modificarlo para este envio, pero no se guardará como configuración para casos futuros.",
              15000,
              ["green-snackbar"]
            );
          } else if (type == 4 || type == 5 || type == 6) {
            this.openSnackBar(
              "¡Ya lo tienes! Puedes modificarlo para este envio, pero no se guardará como configuración para casos futuros. Para que se guarde es necesario que le des al botón una vez hayas modificado el texto.",
              15000,
              ["green-snackbar"]
            );
          }
        } else if (type == 1 || type == 2 || type == 3) {
          this.openSnackBar(
            "Parece que no se ha definido el mensaje por defecto de la empresa.",
            6000,
            ["red-snackbar"]
          );
        } else if (type == 4 || type == 5 || type == 6) {
          this.openSnackBar(
            "Parece que no se ha definido el mensaje por defecto del inmueble.",
            6000,
            ["red-snackbar"]
          );
        }
        finished = true;
      });
  }

  saveTextInmueble(structure, param, finished, type) {
    let idCompany = this.authService.empresaId;
    let language = null;
    let idProperty = null;
    let validReq = this.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));

    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        language = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "id"
      ) {
        idProperty = values[i]["value"];
      }
    }

    let valueInsert = -1;
    if (type == 1) {
      valueInsert = this.findFAWithTableField(
        this.genericService.parentStructure,
        1,
        "whatsapp_cliente_comodin",
        "mensaje",
        structure["id_functional_parent_initial_dsb"]
      )["tmp_value"];
    } else if (type == 2) {
      valueInsert = this.findFAWithTableField(
        this.genericService.parentStructure,
        1,
        "email_comodin",
        "mensaje",
        structure["id_functional_parent_initial_dsb"]
      )["tmp_value"];
    } else if (type == 3) {
      valueInsert = this.findFAWithTableField(
        this.genericService.parentStructure,
        1,
        "email_comodin",
        "asunto",
        structure["id_functional_parent_initial_dsb"]
      )["tmp_value"];
    }

    let value = -1;
    if (type == 1 || type == 2 || type == 3) {
      value = idProperty;
    }

    this.endpointService
      .saveTextInmueble(value, language, type, valueInsert)
      .subscribe((data) => {
        this.openSnackBar("Guardado con éxito", 6000, ["green-snackbar"]);
        finished = true;
      });
  }

  updateImagePropertyCustom(finished) {
    console.log("ARRAY PARA GUARDAR: ", this.arrayImagenes);
    this.endpointService
      .updateImagesProperty(this.arrayImagenes)
      .subscribe((data) => {
        finished = true;
        this.openSnackBar("Cambios guardados correctamente", 15000, [
          "green-snackbar",
        ]);
        this.genericService.refreshStructure(0);
      });
  }

  vincularGoogleMail(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      console.log("VINCULA:", values);
      this.endpointService.vincularGoogleGmail(values).subscribe((data) => {
        console.log("datadatadata:", data);
        if (data["errorCode"] == 1) window.open(data["response"], "_self");
        this.finishFunction(finished);
        this.dialog.closeAll();
      });
    } else {
      this.finishFunction(finished);
    }
  }

  desvincularGoogleMail(structure, param, finished) { }

  showStaticReport(structure, param, finished) {
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let idUser = this.authService.userId;
    let nombreEmpresa = this.authService.companyGenericName;
    let nombreReport =
      param.param_intern[
      "statichtml_routing_functional_area_language-internal_routing"
      ];
    let idReport =
      param.param_intern["statichtml_functional_area-id_functional_area"];
    let url =
      "../" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "-" +
      idUser +
      "&&" +
      idReport +
      "&&0";
    window.open(url);
    this.finishFunction(finished);
  }

  sendWhatsAppInmueble(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let nTelefono = null;
      let nPrefijo = null;
      let mensaje = null;

      for (let i in values) {
        console.log("let ", i, ": ", values[i]["table"]);
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "whatsapp_cliente_comodin" &&
          values[i]["field"] == "telefono"
        ) {
          nTelefono = values[i]["value"];
        }
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "whatsapp_cliente_comodin" &&
          values[i]["field"] == "prefijo_pais"
        ) {
          nPrefijo = values[i]["value"];
        }
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "whatsapp_cliente_comodin" &&
          values[i]["field"] == "mensaje"
        ) {
          mensaje = values[i]["value"];
        }
      }
      let url = this.buildURLFichaInmueble();
      url = encodeURI(url.replace(/&/g, "%26"));
      console.log("mensaje WHATS: ", mensaje);
      mensaje += "%0A%0A" + url; // Saltos de pagina whatsApp
      mensaje = mensaje.replace(/(?:\r\n|\r|\n)/g, "%0A"); // Saltos de pagina whatsApp
      mensaje = mensaje.replace(/&/g, "%26"); // & whatsApp
      mensaje = mensaje.replaceAll("+", "%2B"); // + whatsApp
      mensaje = mensaje.replaceAll("#", "%23"); // # whatsApp
      console.log(mensaje, "mensajeWHATS");
      let urlWhats =
        "https://wa.me/" + nPrefijo + nTelefono + "?text=" + mensaje;
      window.open(urlWhats);
      this.finishFunction(finished);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  sendWhatsAppClient(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let nTelefono = null;
      let nPrefijo = null;
      let mensaje = null;

      for (let i in values) {
        console.log("let ", i, ": ", values[i]["table"]);
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "whatsapp_contactar_comodin" &&
          values[i]["field"] == "telefono"
        ) {
          nTelefono = values[i]["value"];
        }
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "whatsapp_contactar_comodin" &&
          values[i]["field"] == "prefijo_pais"
        ) {
          nPrefijo = values[i]["value"];
        }
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "whatsapp_contactar_comodin" &&
          values[i]["field"] == "mensaje"
        ) {
          mensaje = values[i]["value"];
        }
      }
      console.log("mensaje WHATS: ", mensaje);
      mensaje += "%0A%0A"; // Saltos de pagina whatsApp
      mensaje = mensaje.replace(/(?:\r\n|\r|\n)/g, "%0A"); // Saltos de pagina whatsApp
      mensaje = mensaje.replace(/&/g, "%26"); // & whatsApp
      mensaje = mensaje.replaceAll("+", "%2B"); // + whatsApp
      mensaje = mensaje.replaceAll("#", "%23"); // # whatsApp
      console.log(mensaje, "mensajeWHATS");
      let urlWhats =
        "https://wa.me/" + nPrefijo + nTelefono + "?text=" + mensaje;
      window.open(urlWhats);
      this.finishFunction(finished);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  openFichaPropietario(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let url = this.buildURLFichaPropietario();
      window.open(url, "_blank");
      this.finishFunction(finished);
    } else {
      console.log(finished);
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  selectImageAndAddInEventsTemplateCustom(structure, param, finished) {
    let paramIntern = [];
    let intern_data = this.getInternParam(structure, paramIntern);

    let url = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "empresa_multimedia" && intern_data[i]["bd_field"] == "url") {
        url = intern_data[i]["value"];
        break;
      }
    }
    let fullUrl = "https://movin.cloud/empresas/" + this.authService.empresaId + "/media/" + url;
    this.templateService.currentElement["url"] = fullUrl;
    this.templateService.modifyElement("url");
    this.dialog.closeAll();
    this.finishFunction(finished);
  }

  openFichaInmueble(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let url = this.buildURLFichaInmueble();
      window.open(url, "_blank");
      this.finishFunction(finished);
    } else {
      console.log(finished);
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  
  downloadPDF(from) {
    /*this.downloadingStaticPage = true;
    let element = document.getElementsByClassName("generic-type-0")[0];
    html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const docDefinition = {
          content: [{
              image: imgData,
              width: 500,
          }],
          pageSize: 'A4',
          pageOrientation: 'portrait'
        };
        let nameFile = this.genericService.containerFunctionArea["file_name_staticHTML"];
        const pdfDoc = pdfMake.createPdf(docDefinition);
        if (from == 0) {
            pdfDoc.download(`${nameFile}.pdf`);
        } else {
            pdfDoc.open();
            pdfDoc.print();
        }
    });
    this.downloadingStaticPage = false;*/
  }  
  

  verForm(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let url = this.buildURLVerForm(structure, param);
      window.open(url, "_blank");
      this.finishFunction(finished);
    } else {
      console.log(finished);
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  buildURLVerForm(structure, param) {
    let idLanguage = null;
    let nombrePlantilla = null;
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.getInternParam(structure, param);

    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "languages" &&
        intern_data[i]["bd_field"] == "label"
      ) {
        idLanguage = intern_data[i]["value"];
      }
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "statichtml_routing_functional_area_language" &&
        intern_data[i]["bd_field"] == "internal_routing"
      ) {
        nombrePlantilla = intern_data[i]["value"];
      }
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth" 
    );
  }


  buildURLFichaPropietario() {
    let idLanguage = null;
    let nombrePlantilla = null;
    //  let nombreEmpresa = this.authService.companyGenericName;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idUsuario = this.authService.userId;
    let idVivienda = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "plantilla"
      ) {
        nombrePlantilla = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "propiedad"
      ) {
        idVivienda = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "gestionado_por_1"
      ) {
        idUsuario = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "id_empresa"
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "url_empresa"
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "-" +
      idUsuario +
      "&&" +
      idVivienda
    );
  }


  buildURLFichaInmueble() {
    let idLanguage = null;
    let nombrePlantilla = null;
    //  let nombreEmpresa = this.authService.companyGenericName;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idVivienda = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "plantilla"
      ) {
        nombrePlantilla = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "id"
      ) {
        idVivienda = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "id_empresa"
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "url_empresa"
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "&&" +
      idVivienda +
      "&&0"
    );
  }

  buildURLStaticReport() {
    console.log("ENTRO EN buildURLStaticReport");
    console.log("tmpArray: ", this.tmpArray);
    let idLanguage = null;
    let nombreReport = null;
    //  let nombreEmpresa = this.authService.companyGenericName;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idReport = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    console.log("VALUE: ", values);
    for (let i in values) {
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        idLanguage = values[i]["value"];
        console.log("idioma: ", idLanguage);
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "statichtml_functional_area" &&
        values[i]["field"] == "internal_name"
      ) {
        nombreReport = values[i]["value"];
        console.log("nombreReport: ", nombreReport);
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "statichtml_functional_area" &&
        values[i]["field"] == "id_functional_area"
      ) {
        idReport = values[i]["value"];
        console.log("idReport: ", idReport);
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "id_empresa"
      ) {
        idEmpresa = values[i]["value"];
        console.log("idEmpresa: ", idEmpresa);
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "url_empresa"
      ) {
        nombreEmpresa = values[i]["value"];
        console.log("nombreEmpresa: ", nombreEmpresa);
      }
    }

    return (
      "https://movin.cloud" +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "&&" +
      idReport +
      "&&0"
    );
  }

  duplicarInmueble(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let idEmpresa = this.authService.getIdEmpresa();
      let idInmueble = null;
      for (let i in values) {
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "vivienda" &&
          values[i]["field"] == "id"
        ) {
          idInmueble = values[i]["value"];
        }
      }
      console.log("idInmueble: ", idInmueble);
      console.log("idEmpresa: ", idEmpresa);

      //  this.endpointService.duplicarInmueble(idInmueble).subscribe(data => {
      //  })

      //  INSERT INTO vivienda SELECT * FROM vivienda WHERE id = idInmueble
    } else {
      console.log(finished);
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  productEgoStart($from, product) {
    if (this.authService.userId > 0) {
      this.endpointService
        .insertPlantillaEgoButtonAppGenericProduct(
          product,
          this.authService.userId,
          this.authService.getIdEmpresa(),
          $from
        )
        .subscribe((data) => {
          let egoButtonApp: any;
          if (data["errorCode"] === 0) {
            egoButtonApp = data["response"][0];
            if (egoButtonApp.id_plantilla_ego == 0) {
              this.snackBar.open("Plantilla no disponible", "X", {
                duration: 6000,
                panelClass: ["red-snackbar"],
              });
            }
          } else {
            this.snackBar.open("No se ha encontrado plantilla", "X", {
              duration: 6000,
              panelClass: ["red-snackbar"],
            });
          }
        });
    } else {
      this.endpointService
        .getIdPlantillaEgoButtonAppGenericProduct(product, $from)
        .subscribe((data) => {
          let idPlantillaEgo: any;
          if (data["errorCode"] === 0) {
            idPlantillaEgo = data["response"];
            if (idPlantillaEgo == 0) {
              this.snackBar.open("Plantilla no disponible", "X", {
                duration: 6000,
                panelClass: ["red-snackbar"],
              });
            } else {
              this.openNoti.emit({ id: idPlantillaEgo, button: false });
            }
          } else {
            this.snackBar.open("No se ha encontrado plantilla", "X", {
              duration: 6000,
              panelClass: ["red-snackbar"],
            });
          }
        });
    }
  }

  private validateEmailBackend(structure, param, finished) {
    let intern_data = this.getInternParam(structure, param);
    let email = "";
    let contrasenya = "";
    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 1 &&
        intern_data[i]["bd_table"] == "empresa_email_config" &&
        intern_data[i]["bd_field"] == "email"
      ) {
        email = intern_data[i]["value"];
      }
      if (
        intern_data[i]["id_db"] == 1 &&
        intern_data[i]["bd_table"] == "empresa_email_config" &&
        intern_data[i]["bd_field"] == "contrasenya"
      ) {
        contrasenya = intern_data[i]["value"];
      }
    }

    if (email != "" && contrasenya != "") {
      this.endpointService
        .validateEmail(email, contrasenya)
        .subscribe((data) => {
          this.finishFunction(finished);
          if (data["errorCode"] === 0) {
            this.snackBar.open("Correo validado correctamente", "X", {
              duration: 6000,
              panelClass: ["green-snackbar"],
            });
            this.genericService.refreshStructure(0);
          } else {
            this.snackBar.open(
              "Las credenciales son incorrectas y no se ha validado este correo",
              "X",
              { duration: 6000, panelClass: ["red-snackbar"] }
            );
          }
        });
    } else {
      this.finishFunction(finished);
      this.snackBar.open("No se ha encontrado el email o la contraseña", "X", {
        duration: 6000,
        panelClass: ["red-snackbar"],
      });
    }
  }

  findProductFromParams(structure, param) {
    let product;
    let intern_data = {};

    if (structure == null) {
      structure = this.genericService.parentStructure;
    }

    intern_data = this.getInternParam(structure, param);

    console.log(intern_data, "intern_data");

    // Para que los coja, todos deben ser params_interns
    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "product_mkt" &&
        intern_data[i]["bd_field"] == "id_product"
      ) {
        product = intern_data[i]["value"];
        console.log(product, "product");
      }
      /* SI HAY QUE COGER DE OTRO SITIO TAMBIÉN, SE PONE AQUÍ EL IF*/
    }
    return product;
  }

  copyTableField(structure, param) {
    let columnId = structure["internal_routing"];
    let textToCopy = "";
    for (let t in param) {
      if (t.includes(columnId)) {
        textToCopy = param[t];
      }
    }
    this.clipboard.copy(textToCopy);
    this.openSnackBar("Copiado en el portapapeles", 3000, ["green-snackbar"]);
  }

  public async goPrev(routing = null) {
    const prev = this.genericService.getPreviousPage();
    console.log(prev, "preeeeev");
    console.log(routing);
    if (prev) {
      if (
        Object.keys(
          this.genericService.formsChanged[
          this.genericService.currentInitialArea["key"]
          ]
        ).length > 0
      ) {
        const status = await this.genericService.openWarningDialog(1, 1);
        if (status) {
          this.genericService.updateHistoryIndex(-1);
          this.genericService.formsChanged[
            this.genericService.currentInitialArea["key"]
          ] = {};
          this.routingService.go(prev.url);
        }
      } else {
        this.genericService.updateHistoryIndex(-1);
        this.routingService.go(prev.url);
      }
    } else if (routing !== null) {
      this.goRouting(routing);
    } else {
      this.genericService.refreshStructure(0);
    }
  }

  public async goNext() {
    const next = this.genericService.getNextPage();
    if (next) {
      if (
        Object.keys(
          this.genericService.formsChanged[
          this.genericService.currentInitialArea["key"]
          ]
        ).length > 0
      ) {
        const status = await this.genericService.openWarningDialog(1, 1);
        if (status) {
          this.genericService.updateHistoryIndex(1);
          this.genericService.formsChanged[
            this.genericService.currentInitialArea["key"]
          ] = {};
          this.routingService.go(next.url);
        }
      } else {
        this.genericService.updateHistoryIndex(1);
        this.routingService.go(next.url);
      }
    }
  }

  public updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField = null, hasParameters = true) {
    // ACTUALIZAMOS EL HISTORIAL DE CAMBIOS
    this.genericService.updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField, hasParameters);

    // BUSCAMOS EL ELEMENTO MODIFICADO
    if (id_dsb > 0) parent = id_dsb;
    let formsChanged = this.getFormsCurrentPage(parent);
    let formsChanged_pre = _.cloneDeep(formsChanged);
    for (let key in formsChanged_pre) {
      if (
        formsChanged_pre[key]["id_functional_area"] ==
        structure["id_functional_area"] &&
        ((formsChanged_pre[key]["lastValueOnExecuteFunction"] === undefined &&
          newValue !== old) ||
          formsChanged_pre[key]["lastValueOnExecuteFunction"] !== newValue)
      ) {
        this.genericService.formsChanged[parent][
          formsChanged_pre[key]["type"] +
          formsChanged_pre[key]["id_functional_area"]
        ]["lastValueOnExecuteFunction"] = newValue;
        // EJECUTAMOS FUNCIÓN
        if (structure["id_function"] > 0) this.getFunctionFormField(structure);
      }
    }
  }

  public getFunctionFormField(structure) {
    console.log("FORM FIELD FUNCTION:", structure["id_function"], structure);
    this.getFunction(structure);
  }

  public orderFormsChanged(formsChanged) {
    function swap(forms) {
      let ret = {};
      for (let key in forms) {
        ret[forms[key]["id"]] = forms[key];
        ret[forms[key]["id"]]["id_functional_area"] = key.split("_")[1];
        ret[forms[key]["id"]]["type"] = key.split("_")[0] + "_";
      }
      return ret;
    }
    let formsSorted = swap(formsChanged);
    return formsSorted;
  }

  getFormsCurrentPage(parent_initial = 0) {
    if (parent_initial === 0)
      parent_initial = this.genericService.currentInitialArea["key"];
    if (
      this.genericService.formsChanged[
      this.genericService.currentInitialArea["key"]
      ]
    )
      return this.orderFormsChanged(
        this.genericService.formsChanged[parent_initial]
      );
    return {};
  }

  duplicatePlantillaEmail(param, finished, structure, from = 0) {
    let validReq = this.formsArray(structure, finished, true);
    if (validReq) {
      let idPlantilla = -1;
      let idNewPlantilla = -1;
      let nombre = null;
      let descripcion = null;
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      console.log(values, "valuesvalues")
      for (let i in values) {
        if (from == 0) {
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "id") {
            idPlantilla = values[i]["value"];
          }
        } else {
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails_idiomas" && values[i]["field"] == "id_plantilla") {
            idPlantilla = values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "id") {
            idNewPlantilla = values[i]["value"];
          }
        }

        if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "nombre") {
          nombre = values[i]["value"];
        }

        if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "descripcion") {
          descripcion = values[i]["value"];
        }
      }

      this.endpointService.duplicatePlantillaEmail(idPlantilla, this.authService.userId, this.authService.getIdCompany(), idNewPlantilla, nombre, descripcion).subscribe((data) => {
        if (data["errorCode"] === 0) {
          if (from == 0) {
            this.openSnackBar(
              "Plantilla duplicada correctamente",
              7000,
              ["green-snackbar"]
            );
            this.genericService.refreshStructure(0);
          } else {
            this.openSnackBar(
              "Plantilla insertada correctamente",
              7000,
              ["green-snackbar"]
            );
            this.genericService.refreshStructure(1);
          }
          finished = true;
          this.finishFunction(finished);
        }
      });
    }
  }

  getNgForId(structure) {
    let ngForId = "-1";
    if(structure["ngForId"] && structure["ngForId"] != "-1" && typeof structure["ngForId"] === "string" && structure["ngForId"].includes(".")) {
      ngForId = "0";
      let parts = structure["ngForId"].split(".");
      if(parts && parts[1]) ngForId = parts[1];
    }
    return ngForId;
  }
}
