import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** A hero's name can't match the given regular expression */
export function checkNumberValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const name = control.value;
    let no = nameRe.test(name);
    no = !no;
    return no ? {'checkNumber': {name}} : null;
  };
}

@Directive({
  selector: '[checkNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: checkNumberValidator, multi: true}]
})
export class CheckNumberDirective implements Validator, OnChanges {
  @Input() checkNumber: string;
  private valFn = Validators.nullValidator;

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['checkNumber'];
    if (change) {
      const val: string | RegExp = change.currentValue;
      const re = val instanceof RegExp ? val : new RegExp(val, 'i');
      this.valFn = checkNumberValidator(re);
    } else {
      this.valFn = Validators.nullValidator;
    }
  }

  validate(control: AbstractControl): {[key: string]: any} {
    return this.valFn(control);
  }
}
