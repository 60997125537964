import { Component, OnInit, Input } from "@angular/core";
import { TemplateService } from "app/services/generic/custom/template-element";
import { EndpointService } from "app/services/endpoint/endpoint.service";

@Component({
  selector: "app-events-template-custom-loop",
  templateUrl: "./events-template-custom-loop.component.html",
  styleUrls: ["./events-template-custom-loop.component.css"],
})
export class EventsTemplateCustomLoopComponent implements OnInit {
  @Input() templateElementsArray;
  @Input() idPlantilla: number;
  @Input() idParent: number;

  border: boolean = false;
  elementIndexBorder: number;

  constructor(
    public endpointService: EndpointService,
    public templateService: TemplateService
  ) { }

  ngOnInit(): void { }

  addFirstElementBlock(tipo, copy) {
    if(!copy) {
      let element = {orden: 0};
      this.addElement(element, tipo, true, copy);
    }
    else {
      console.log("skdjfn", this.templateService.arrayCopyElement.element_info);
      let element = {orden: 0};
      this.addElement(element, this.templateService.arrayCopyElement.element_info, true, copy);
    }
  }

  pasteElement(element, first) {
    console.log(element, first, "In loop");
    this.addElement(element, this.templateService.arrayCopyElement.element_info, first, true);
  }

  addElement(element, tipo, first, copy = false) {
   this.templateService.addElement(element, tipo, first, copy, this.idParent, this.templateElementsArray)
  }

  public filterArrayTemplateElements() {
    if (this.templateElementsArray.length !== 0) {
      return this.templateElementsArray.filter(function (element) {
        return element.borrado != 1;
      });
    } else {
      return [];
    }
  }
}
