<ng-container *ngFor="let element of filterArrayTemplateElements(); first as isFirst">
  <div class="generic-buttons-blue generic-creator-add firstAddButton" *ngIf="isFirst">
    <button
      mat-raised-button
      matTooltip="Add"
      [matMenuTriggerFor]="menu"
    >
      <i class="material-icons">add</i>
    </button>
    <button
      *ngIf="templateService.arrayCopyElement != null"
      mat-raised-button
      matTooltip="Paste"
      (click)="pasteElement(element, true)"
    >
      <i class="material-icons">content_paste</i>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <button
      *ngFor="let tipo of this.templateService.arrayElementsTipo"
      (click)="addElement(element, tipo, true)"
      mat-menu-item
    >
      <mat-icon class="material-icons">{{ tipo.icon }}</mat-icon
      >{{ tipo.nombre_frontend }}
    </button>
  </mat-menu>
  <div
    [ngClass]="
      this.templateService.currentElement != undefined &&
      this.templateService.currentElement.idTmp != undefined &&
      this.templateService.currentElement.idTmp === element.idTmp
        ? 'example-list changeBorder'
        : 'example-list'
    "
  >
    <app-events-template-custom-element
      class="component-class example-box"
      [singleElement]="element"
      [idPlantilla]="idPlantilla"
      [arrayTemplateElements]="filterArrayTemplateElements()"
    >
    </app-events-template-custom-element>
  </div>
  <div class="generic-buttons-blue generic-creator-add showButton">
    <button
      mat-raised-button
      matTooltip="Add"
      [matMenuTriggerFor]="menu2"
    >
      <i class="material-icons">add</i>
    </button>
    <button
      *ngIf="templateService.arrayCopyElement != null"
      mat-raised-button
      matTooltip="Paste"
      (click)="pasteElement(element, false)"
    >
      <i class="material-icons">content_paste</i>
    </button>
  </div>
  <mat-menu #menu2="matMenu">
    <button
      *ngFor="let tipo of this.templateService.arrayElementsTipo"
      (click)="addElement(element, tipo, false)"
      mat-menu-item
    >
      <mat-icon class="material-icons">{{ tipo.icon }}</mat-icon
      >{{ tipo.nombre_frontend }}
    </button>
  </mat-menu>
</ng-container>
<ng-container *ngIf="filterArrayTemplateElements().length == 0">
  <div class="generic-buttons-blue generic-creator-add firstAddButton">
    <button mat-raised-button matTooltip="Add" [matMenuTriggerFor]="menu3">
      <i class="material-icons">add</i>
    </button>
    <button
      *ngIf="templateService.arrayCopyElement != null"
      mat-raised-button
      matTooltip="Paste"
      (click)="addFirstElementBlock(templateService.arrayCopyElement, true)"
    >
      <i class="material-icons">content_paste</i>
    </button>
  </div>
  <mat-menu #menu3="matMenu">
    <button
      *ngFor="let tipo of this.templateService.arrayElementsTipo"
      (click)="addFirstElementBlock(tipo)"
      mat-menu-item
    >
      <mat-icon class="material-icons">{{ tipo.icon }}</mat-icon
      >{{ tipo.nombre_frontend }}
    </button>
  </mat-menu>
</ng-container>