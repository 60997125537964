import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import { ActivitiesService } from '../activities/activities.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  public routeParam = null; // Si no es null hay que filtrar para mostrar un elemento en específico (ej: user-info)
  public previousModule = null;
  public url = null;
  public urlHashtag = null;
  public urlWithoutHashtag = null;
  public moduleId;
  public loadedBreadcrumbs = false;

  constructor(private router: Router,
              private location: Location,
              private activitiesService: ActivitiesService) { }

  go(route) {
    let aux = route.split('#')
    if(aux.length > 1) {
      this.router.navigate(['/' + aux[0]], {fragment: aux[1]});
    }
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([aux[0]], {skipLocationChange: true});
    }
  }

  reloadPage(route) {
    route =this.location.path(true)
    this.prepareRouting();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(route, {skipLocationChange: true});
    this.activitiesService.clearCustomEventsGeneral();
  }

  resetRouteParam() {
    this.routeParam = null;
  }

  prepareRouting() {
    if(this.router.url.includes('#')){
      let hashTagIndex = this.router.url.indexOf('#');
      let hashtag = this.router.url.slice(hashTagIndex+1);
      this.urlHashtag = hashtag;
      this.urlWithoutHashtag = this.router.url.slice(0,hashTagIndex);
    }else{
      this.urlHashtag = null;
      this.urlWithoutHashtag = this.router.url;
    }

  }
}
