import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserBody} from "../../../users/objects/user-body";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChatService} from "../../../services/chat.service";
import {Values} from "../../../../values/values";
import * as _ from "lodash";

@Component({
  selector: 'app-select-group-users',
  templateUrl: './select-group-users.component.html',
  styleUrls: ['./select-group-users.component.css']
})
export class SelectGroupUsersComponent implements OnInit {

  public firstFormGroup: UntypedFormGroup;
  public thirdFormGroup: UntypedFormGroup;
  public usersList: UserBody[];
  public myToken: string;
  public noPhotoImg: any = Values.CHAT_GROUP;
  public selectedUsers: Map<string, boolean> = new Map<string, boolean>();
  public usuario_search = "";
  public selectedUsersArray : any[];
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files  = [];

  constructor(public chatService: ChatService,
              public formBuilder: UntypedFormBuilder,
              private dialogRef: MatDialogRef<SelectGroupUsersComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.usersList = _.cloneDeep(data.users);
    this.myToken = data.myToken;
  }

  ngOnInit(): void {
    this.selectedUsersArray = [];
    this.firstFormGroup = this.formBuilder.group({
      firstCtrlGroupName: ['', Validators.required],
      firstCtrlGroupDescription: ['', Validators.required],
    });

    this.thirdFormGroup = this.formBuilder.group({
      firstCtrlOnlyAdminCanChat: [false],
      firstCtrlOnlyAdminCanAdd: [false],
      firstCtrlOnlyAdminCanEditGroupInfo: [false],
      firstCtrlGroupOpened: [2, Validators.required],
    });

  }

  search() {
    console.log(this.usuario_search);
  }

  changeStatusUser(id: string, checked: boolean) {
    console.log(id, checked);
    if(checked) {
      this.selectedUsers.set(id, checked);
      for(let i = 0;i<this.usersList.length;++i){
        if(this.usersList[i].userToken == id){
          this.selectedUsersArray.push(this.usersList[i]);
          this.usersList.splice(i, 1);
          break;
        }
      }
    }
    else {
      this.selectedUsers.delete(id);
      for(let i = 0;i<this.selectedUsersArray.length;++i){
        if(this.selectedUsersArray[i].userToken == id){
          this.usersList.push(this.selectedUsersArray[i]);
          this.selectedUsersArray.splice(i, 1);
          break;
        }
      }
    }
  }

  createGroup() {
    let users = Array.from(this.selectedUsers, ([name, value]) => ({ userId: name, isAdmin: false, canTalk: true }));
    this.chatService.createChatGroup(this.firstFormGroup.get('firstCtrlGroupName').value,
                                      this.firstFormGroup.get('firstCtrlGroupDescription').value,
                                      users,
                                      this.thirdFormGroup.get('firstCtrlOnlyAdminCanChat').value,
                                      this.thirdFormGroup.get('firstCtrlOnlyAdminCanAdd').value,
                                      this.thirdFormGroup.get('firstCtrlOnlyAdminCanEditGroupInfo').value,
                                      this.thirdFormGroup.get('firstCtrlGroupOpened').value,
                                      this.files[0],
                                      this.myToken
      ).then(res => {
        this.dialogRef.close(res);
    });
  }

  addImage() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++)
      {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      SelectGroupUsersComponent.uploadFiles(this.fileUpload);
    };
    fileUpload.click();
  }

  private static uploadFiles(picture: ElementRef) {
    picture.nativeElement.value = '';
  }

  preview(files: FileList) {
    if (files.length === 0)
      return;

    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      console.log("Only images are supported.");
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.noPhotoImg = reader.result;
    }
  }
}
