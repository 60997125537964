<ng-container *ngIf="loaded">
  <ng-container *ngFor="let e of structure | keyvalue : genericService.returnZero">
    <span *ngIf="structure[e.key] && genericService.evaluateIfs(structure[e.key]['id_condition'], structure[e.key]['id_functional_area'], structure[e.key]) && structure[e.key]['id_functional_status_general'] !== 2"
      [fxHide]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || structure[e.key]['hide'] == 1"
      [fxHide.xs]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_xs']=='true' && structure[e.key]['hide_xs'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_xs'] == 1"
      [fxHide.sm]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_s']=='true' && structure[e.key]['hide_s'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_s'] == 1"
      [fxHide.md]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_m']=='true' && structure[e.key]['hide_m'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_m'] == 1"
      [fxHide.lg]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_l']=='true' && structure[e.key]['hide_l'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_l'] == 1"
      [fxHide.xl]="(structure[e.key]['hide']=='true' && structure[e.key]['hide'] != 0) || (structure[e.key]['hide_xl']=='true' && structure[e.key]['hide_xl'] != 0) || structure[e.key]['hide'] == 1 || structure[e.key]['hide_xl'] == 1">
      <ng-container *ngIf="!structure[e.key]['child'] || !structure[e.key]['child'][0] || structure[e.key]['child'][0]['id_functional_type'] != 13">
        <button mat-menu-item (mouseenter)="openMenuTrigger(null, structure[e.key])" class="menu-button"
          [ngClass]="structure[e.key]['id_functional_status_mkt'] != 1 ? structure[e.key]['class_custom'] + ' ' + 'mkt-no-available' : genericService.highlightHeaderElements.includes(structure[e.key]['id_functional_area']) ? 'highlight' + ' ' + structure[e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[e.key]['class_custom'] "
          [ngStyle]="structure[e.key]['styleParsed']['style']"
          [matTooltip]="structure[e.key]['tooltip']"
          [id]="structure[e.key]['id_functional_area']"
          (click)="onClickEvent(this, e.key)">
            <i *ngIf="structure[e.key]['icon']" class="{{structure[e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[e.key]['icon'] }} </i>
            <span> {{ structure[e.key]['label'] }}</span>
        </button>
      </ng-container>

      <ng-container *ngIf="structure[e.key]['child'] && structure[e.key]['child'][0] && structure[e.key]['child'][0]['id_functional_type'] == 13">
        <button mat-menu-item #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="submenu" class="menu-button"
          (click)="onClickMenu(e.key)" (mouseenter)="openMenuTrigger(menuTrigger, structure[e.key])"
          [ngClass]="structure[e.key]['id_functional_status_mkt'] != 1 ? structure[e.key]['class_custom'] + ' ' + 'mkt-no-available' : genericService.highlightHeaderElements.includes(structure[e.key]['id_functional_area']) ? 'highlight' + ' ' + structure[e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[e.key]['class_custom']"
          [ngStyle]="structure[e.key]['styleParsed']['style']"
          [matTooltip]="structure[e.key]['tooltip']"
          [id]="structure[e.key]['id_functional_area']">
            <i *ngIf="structure[e.key]['icon']" class="{{structure[e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[e.key]['icon'] }} </i>
            <span> {{ structure[e.key]['label'] }} </span>
        </button>
  
        <mat-menu #submenu="matMenu">
          <span>
            <app-generic [structure]="structure[e.key]" [param]="param"></app-generic>
          </span>
        </mat-menu>
      </ng-container>

    </span>
  </ng-container>
</ng-container>