<div class="modal" id="{{id}}" role="dialog" aria-labelledby="deleteClientLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="deletePropertyLabel">{{title}}</h2>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let image of images">
          <img [src]="image.src" width="20%" height="20%"/>
          <input type="checkbox" name="check" [(ngModel)]="image.selected">
        </ng-container>
      </div>
      <div class="modal-footer">
<!--        <button type="button" class="btn btn-primary" (click)="showImageUpload()">{{uploadImageButtonText}}</button>-->
        <button type="button" class="btn btn-primary">{{uploadImageButtonText}}</button> <!--IBRA: He comentado la linia de arriba y he puesto esta para poder subirto a demo-->
        <button type="button" class="btn btn-primary" (click)="onClickAccept()">{{acceptButtonText}}</button>
        <button type="button" class="btn btn-secondary" (click)="onClickDecline()">{{declineButtonText}}</button>
      </div>
    </div>
  </div>
</div>

<!--div class="modal fade" id="{{idUploader}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" (click)="onClickDeclineUpload()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <image-upload [max]="1" [url]="backendRequest" [buttonCaption]="'Seleccionar imagen'" [dropBoxMessage]="'Arrastrar imagen'"
            (ngUploadFinished)="onUploadFinished($event)">
          </image-upload>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onClickDeclineUpload()">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onClickAceptUpload()">Aceptar</button>
        </div>

      </div>
    </div>
  </div>
</div-->
