import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CreateGroupDialog} from "./subcomponents/create-group-dialog/create-group-dialog";
import {SimpleGroup} from "../../../activities/objects/simple-group";
import {DeleteConfirmDialog} from "../../../utils/delete-confirm-dialog/delete-confirm-dialog";
import {Strings} from "../../../../strings/strings";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterUsersPipe} from "../../filter-users.pipe";
import * as _ from 'lodash';
import { ActivitiesService } from '../../../services/activities/activities.service';

@Component({
  selector: 'bottom-sheet-shared-calendar',
  templateUrl: 'bottom-sheet-shared-calendar.html',
  styleUrls: ['./bottom-sheet-shared-calendar.scss']
})
export class BottomSheetSharedCalendar implements OnInit {
  public strings = Strings.CALENDAR;
  usersFilter: { id: number, value: boolean, name: string, userPic: string, accountName: string, accountType: number, accountId: number, empresaId: number }[];
  // Joan
  //myFilter: { id: number, value: boolean, user: string };
  groupFilter: SimpleGroup[] = [];
  myGroupEventsFilter: { userId: number, id: number | any, value: boolean, user: string, type: string | any, name: string, type_account: number }[];

  public displayedColumns: string[] = ['visible', 'photo', 'name', 'account_name'];
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public checkAllGroups: boolean;
  public checkAllUsers: boolean;
  public checkShowOnlyMyAgenda: boolean;

  public groupsToDelete: number[];

  constructor(
    // public dialogRef: MatDialogRef<ManageCategoriesDialog>, antic de quan era un dialog
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetSharedCalendar>,
    private _changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public endpointService: EndpointService,
    public authService: AuthService,
    private userPipe: FilterUsersPipe,
    public activitiesService: ActivitiesService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.usersFilter = data.usersFilter;

    // Joan
    //this.myFilter = data.myFilter;
    this.groupFilter = data.groups;

    this.myGroupEventsFilter = data.myGroupEventsFilter;
    this.dataSource = new MatTableDataSource(this.userPipe.transform(this.usersFilter, this.myGroupEventsFilter, 1));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    console.log("groupFilter", this.groupFilter);
    console.log("usersFilter", this.usersFilter);
    console.log("dataSource", this.dataSource);

    this._bottomSheetRef.backdropClick().subscribe(e => {
      this.onClose(false);
    });

    this.groupsToDelete = [];

    this.setCheckBoxAllGroups();
    this.setCheckBoxAllUsers();
    this.checkShowOnlyMyAgenda = this.activitiesService.showOnlyMyAgenda;
  }

  onClose(applyFilter = false): void {
    for (let i = 0; i < this.groupsToDelete.length; i++) {
      // Esborrar de la BD els grups que s'hagin esborrat
      this.endpointService.deleteGroup(this.groupsToDelete[i]).subscribe(res => {});
    }
    
    if (applyFilter) {
      // Actualitzar localStorage
      let tmpFilterNotSelectedUsers : number[] = [];
      this.usersFilter.forEach(user => {
        if (!user.value) {
          tmpFilterNotSelectedUsers.push(user.id);
        }
      });
      localStorage.setItem('event_not_share_user', JSON.stringify(tmpFilterNotSelectedUsers));

      this.activitiesService.showOnlyMyAgenda = this.checkShowOnlyMyAgenda;
      localStorage.setItem('show_only_my_agenda', JSON.stringify(this.activitiesService.showOnlyMyAgenda ? 'true' : 'false'));

      this._bottomSheetRef.dismiss({applyFilters: true, groupFilter: this.groupFilter, myGroupEventsFilter: this.myGroupEventsFilter, usersFilter: this.usersFilter});
    }
    else {
      this._bottomSheetRef.dismiss({applyFilters: false, groupFilter: this.groupFilter, myGroupEventsFilter: this.myGroupEventsFilter, usersFilter: this.usersFilter});
    }
  }

  // Joan: Marca/Desmarca a la pantalla el checkbox de tots els grups
  public setCheckBoxAllGroups(): void {
    this.checkAllGroups = (this.groupFilter.filter(e => e.value).length == this.groupFilter.length);
  }

  // Joan: Marca/Desmarca a la pantalla el checkbox de tots els usuaris
  public setCheckBoxAllUsers(): void {
    this.checkAllUsers = (this.usersFilter.filter(e => e.value).length == this.usersFilter.length);
  }

  // Joan: De moment no la necessitem
  /*
  setUniqueGroupStatus(id: number, event: boolean): void {
    this.groupFilter.filter(group => group.id === id).forEach(data => {
      data.value = event;
      // data.setStatus();
    });
    // this._changeDetectorRef.detectChanges();
  }
  */

  // Joan: De moment no la necessitem
  /*
  setGroupStatus(): void {
    this.groupFilter.forEach(group => {
      group.setStatus(); // Assigna group.value = false, si no té cap usuari seleccionat, sino group.value = true
    });
    // this._changeDetectorRef.detectChanges();
  }
  */

  // Joan: Selecciona/deselecciona tots el grups o usuaris
  /*
  checkUncheckAll(allGroupsChanged: boolean, newStatus: boolean) {
    console.log("checkUncheckAll " + (allGroupsChanged ? "groups" : "users") + " newStatus=" + newStatus);
    if (allGroupsChanged) { // checkbox AllGroups changed
      this.checkAllUsers = newStatus;
    }
    else { // checkbox AllUsers changed
      this.checkAllGroups = newStatus;
    }

    // Actualitzem l'estat de tots els grups i de tots els usuaris dels grups
    this.groupFilter.forEach(group => {
      group.value = newStatus;

      group.users.forEach(user => {
        user.value = newStatus;
      });
    });

    // Actualitzem l'estat de tots els usuaris
    this.usersFilter.forEach(user => {
      user.value = newStatus;
    });
  }
  */

  // Joan: Selecciona/deselecciona tots el grups
  public checkUncheckAllGroups(newStatus: boolean): void {
    console.log("checkUncheckAllGroups: newStatus=" + newStatus);

    let usersInGroups : number[] = [];

    // Actualitzem l'estat de tots els grups i dels usuaris que estan als grups
    this.groupFilter.forEach(group => {
      group.value = newStatus;

      group.users.forEach(userGroup => {
        userGroup.value = newStatus;
        if (usersInGroups.indexOf(userGroup.id) == -1) {
          usersInGroups.push(userGroup.id);
        }
      });
    });

    // Actualitzem l'estat dels usuaris que estan als grups
    this.usersFilter.forEach(user => {
      if (usersInGroups.indexOf(user.id) >= 0) {
        user.value = newStatus;
      }
    });

    this.setCheckBoxAllUsers();
    this.setCheckBoxAllGroups();

    console.log("groupFilter", this.groupFilter);
    console.log("usersFilter", this.usersFilter);
  }

  // Joan: Selecciona/deselecciona tots el usuaris
  public checkUncheckAllUsers(newStatus: boolean): void {
    console.log("checkUncheckAllUsers: newStatus=" + newStatus);

    // Actualitzem l'estat de tots els usuaris i de tots els grups i els usuaris que estan als grups

    // Actualitzem l'estat de tots els usuaris
    this.usersFilter.forEach(user => {
      user.value = newStatus;
    });
    
    // Actualitzem l'estat de tots els grups i els usuaris que estan als grups
    this.groupFilter.forEach(group => {
      group.value = newStatus;

      group.users.forEach(userGroup => {
        userGroup.value = newStatus;
      });
    });

    this.setCheckBoxAllGroups();

    console.log("groupFilter", this.groupFilter);
    console.log("usersFilter", this.usersFilter);
  }

  /* Joan: No cal. Ho he canviat
  isAllSelected(currentGroupId) {
    let groupFilterAux = this.groupFilter;
    let group = groupFilterAux.find((obj) => obj.id === currentGroupId)
    group['value'] = !group['value']
    
    let count = groupFilterAux.filter(function(el){
      console.log(el, el.value)
      return el.value}).length;
      
    console.log(count, "How many TRUE")
    console.log(this.groupFilter.length, "Length")
    if(count == groupFilterAux.length) this.checkAllGroups = true;
    else this.checkAllGroups = false;
    console.log(this.checkAllGroups, "checkAllGroups")
  }
  */

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // changeStatusUser(id: number, accountId: number,  accountType: number, event: boolean) {
  public changeStatusUser(userId: number, event: boolean): void {
    console.log("changeStatusUser userId=" + userId + " event=" + event);

    this.usersFilter.filter(user => user.id === userId).forEach(us => {
      // Actualitzem estat usuari
      us.value = event;

      // Joan: Actualitzem també l'estat de l'usuari als grups on estigui
      this.groupFilter.forEach(group => {
        group.users.filter(ug => ug.id == userId).forEach(u => {
          u.value = event;
        });
      });

      // Joan: Actualitzem l'estat dels grups en funció de l'estat dels usuaris del grup
      this.groupFilter.forEach(group => {
        group.value = (group.users.filter(ug => ug.value).length == group.users.length); // Un grup está activat si ho estan tots els seus usuaris
        //console.log("group updated:", group);
      });
    });
    // Joan: De moment ho comento - Ja no cal
    //this.setGroupStatus();

    this._changeDetectorRef.detectChanges();

    this.setCheckBoxAllGroups();
    this.setCheckBoxAllUsers();
  }

  // Joan: Canvio la funció
  /*
  changeStatusGroup(id: number, event: boolean) {
    console.log("changeStatusGroup id=" + id + " event=" + event);

    if (event === true) {
      this.endpointService.getAllGroupUsersById(id, this.authService.userId).subscribe(data => {
        data['response'].map( d => {
          this.usersFilter.filter((e => (e.id === d.id_usuario))).forEach(e => { e.value = event; });
          if (event) { localStorage.removeItem('event_share_user_' + d.id_usuario); }
          else { localStorage.setItem('event_share_user_' + d.id_usuario, 'false'); }
        });
        // this.setGroupStatus();
        this.setUniqueGroupStatus(id, event);
        this.groupFilter.filter(group => group.id === id).forEach(data => {
          data.value = event;
        });
        // this._changeDetectorRef.detectChanges();
      });
    }
    else {
      this.endpointService.getAllGroupUsersById(id, this.authService.userId).subscribe(data => {
        data['response'].map( d => {
          let num = 1;
          // this.groupFilter.filter(grupo => grupo.value === true).forEach(gr => {
          this.groupFilter.filter(grupo => grupo.id !== id).forEach(gr => {
            if (gr.users.filter(us => us['id'] === d.id_usuario).length > 0 && gr.value === true) {
              num += 1;
            }
          });
          if ( num === 1 ) {
            this.usersFilter.filter((e => (e.id === d.id_usuario))).forEach(e => { e.value = event; });
            if (event) { localStorage.removeItem('event_share_user_' + d.id_usuario); }
            else { localStorage.setItem('event_share_user_' + d.id_usuario, 'false'); }
          }
        });
        this.setUniqueGroupStatus(id, event);
        this.groupFilter.filter(group => group.id === id).forEach(data => {
          data.value = event;
        });
        // this._changeDetectorRef.detectChanges();
      });
    }
  }
  */
  public changeStatusGroup(groupId: number, event: boolean): void {
    console.log("changeStatusGroup groupId=" + groupId + " event=" + event);

    if (event) {
      // Group seleccionat: Cal activar el grup, els seus usuaris, tant al grup com a usuaris
      this.groupFilter.filter(group => group.id === groupId).forEach(g => {
        g.value = event;

        g.users.forEach(u => {
          u.value = event;

          this.usersFilter.filter(user => user.id === u.id).forEach(us => {
            us.value = event;
          });
        });
      });
    }
    else {
      // Group deseleccionat: Cal desactivar el grup, els seus usuaris al grup, i a usuaris si no pertanyen a cap grup
      this.groupFilter.filter(group => group.id === groupId).forEach(g => {
        g.value = event;

        g.users.forEach(u => {
          u.value = event;

          // Mirar si l'usuari no existeix a cap altre grup. I si és així, desactivar-lo
          let found = false;
          this.groupFilter.filter(group => group.id !== groupId).forEach(g2 => {
            if (g2.users.findIndex(user => user.id === u.id) >= 0) {
              found = true;
              return;
            }
          });

          if (!found) {
            this.usersFilter.filter(user => user.id === u.id).forEach(us => {
              us.value = event;
            });
          }
        });
      });
    }
  
    this._changeDetectorRef.detectChanges();

    this.setCheckBoxAllGroups();
    this.setCheckBoxAllUsers();
  }

  public openCreateGroupDialog(): void {
    const dialogRef = this.dialog.open(CreateGroupDialog, {
      width: 'auto',
      height: 'auto',
      minWidth: '40vw',
      data: {users: this.usersFilter, opType: 1, totalUsers: this.usersFilter, isOnlyNewUsers: false},
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        // Joan: Afegim el grup com seleccionat i també els usuaris del grup. I també seleccionem els usuaris del grup
        //let newGroup = new SimpleGroup(result.id, false, result.nombre, result.id_cuenta, result.usuarios, null, null, result.id_empresa);
        let newGroup = new SimpleGroup(result.id, true, result.nombre, result.id_cuenta, result.usuarios, null, null, result.id_empresa);

        newGroup.users.forEach(userGroup => {
          userGroup.value = true;

          this.usersFilter.filter(u => u.id === userGroup.id).forEach(us => {
            us.value = true;
          });
        });

        this.groupFilter.push(newGroup);

        // Joan: Ho deixo perquè si no hi és no refresca la llista després d'afegir un grup
        this._changeDetectorRef.detectChanges();

        this.setCheckBoxAllGroups();
        this.setCheckBoxAllUsers();
      }
    });
  }

  public openDeleteConfirmGroupDialog(groupId: number): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialog, {
      maxWidth: '100vw',
      maxHeight: '70vh',
      width: 'auto',
      height: 'auto',
      data: this.strings.DELETE_GROUP_DIALOG_MESSAGE
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Esborrar el grup de la BD i de la pantalla --> Joan: Guardem al grup per esborra-lo si es surt d'aquesta pantalla confirmant
        //this.endpointService.deleteGroup(groupId).subscribe(res => {
          this.groupsToDelete.push(groupId);

          // Si el grup està seleccionat, desactivar els usuaris del grup si no pertanyen a cap altre grup
          const index = this.groupFilter.findIndex(g => g.id == groupId);
          if (this.groupFilter[index].value) {
            // Per cada usuari del grup...
            this.groupFilter[index].users.forEach(user => {
              // Mirar si l'usuari no existeix a cap altre grup. I si és així, desactivar-lo
              let found = false;
              this.groupFilter.filter(group => group.id !== groupId).forEach(g2 => {
                if (g2.users.findIndex(u => u.id === user.id) >= 0) {
                  found = true;
                  return;
                }
              });
  
              if (!found) {
                this.usersFilter.filter(u => u.id === user.id).forEach(us => {
                  us.value = false;
                });
              }
            });
          }

          // Eliminar el grup del filtre
          this.groupFilter = this.groupFilter.filter(g => g.id !== groupId);

          // Joan: Ho deixo perquè si no hi és no refresca la llista després d'eliminar un grup
          this._changeDetectorRef.detectChanges();

          this.setCheckBoxAllGroups();
          this.setCheckBoxAllUsers();
        //});
      }
    });
  }

  public openEditGroupDialog(group: SimpleGroup): void {
    const dialogRef = this.dialog.open(CreateGroupDialog, {
      width: 'auto',
      height: 'auto',
      minWidth: '40vw',
      data: { users: group['id_account'], grupos: this.groupFilter, opType: 2, group: group, totalUsers: this.usersFilter, isOnlyNewUsers: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Ha habido cambios -> Actualizamos los cambios
        console.log("result", result);

        const index = this.groupFilter.findIndex(g => g.id == group.id);
        this.groupFilter[index].name = result.nombre;

        // Actualizamos los usuarios si hay algun cambio
        if (result.usersToDelete.length > 0 || result.usersToAdd.length > 0) {
          if (result.usersToDelete.length > 0) {
            let usersArray: any = [];
            this.groupFilter[index].users.forEach(user => {
              if (result.usersToDelete.find(u => u == user.id) === undefined) {
                usersArray.push(user);
              }
              else {
                // Si el grup està seleccionat, desactivar l'usuari si no pertany a cap altre grup
                if (this.groupFilter[index].value) {
                  // Mirar si l'usuari no existeix a cap altre grup. I si és així, desactivar-lo
                  let groupId = this.groupFilter[index].id;
                  let found = false;
                  this.groupFilter.filter(group => group.id !== groupId).forEach(g2 => {
                    if (g2.users.findIndex(u => u.id === user.id) >= 0) {
                      found = true;
                      return;
                    }
                  });

                  if (!found) {
                    this.usersFilter.filter(u => u.id === user.id).forEach(us => {
                      us.value = false;
                    });
                  }
                }
              }
            });
            this.groupFilter[index].users = usersArray;
          }
          
          result.usersToAdd.forEach(user => {
            const userToAdd = this.usersFilter.filter(u => u.id == user.id)[0];
            this.groupFilter[index].users.push({id: userToAdd.id,
                                                empresaId: userToAdd.empresaId,
                                                name: userToAdd.name,
                                                userPic: userToAdd.userPic,
                                                cuenta_nombre: userToAdd.accountName});
            // Si el grup està seleccionat, activar l'usuari
            if (this.groupFilter[index].value) {
              this.usersFilter.filter(u => u.id === user.id).forEach(us => {
                us.value = true;
              });
            }
          });

          this._changeDetectorRef.detectChanges();

          this.setCheckBoxAllUsers();
        }
        // Joan
        //this._changeDetectorRef.detectChanges();
      }
    });
  }
}