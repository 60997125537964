<form [formGroup]="editEventForm">
  <div class="dialog-container custom-edit-evento-agenda">
    <input formControlName="eventTitle" type="text" class="form-control title-input" [ngModel]="tempTitle"
      (ngModelChange)="tempTitle = $event" (keyup)="refresh.next()" />
    <button class="close-button" mat-button (click)="onNoClick()"><i class="material-icons">close</i></button>
  </div>
  <div mat-dialog-content fxLayout="row wrap">
    <div fxFlex="100" fxLayout="row wrap">
      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="authService.accountsGeneric.length > 0">
        <mat-label>Cuenta</mat-label>
        <mat-select disabled formControlName="eventAccount" [(ngModel)]="this.event.account"
          (selectionChange)="setAccount($event.value)">
          <mat-option *ngFor="let account of authService.accountsGeneric" [value]="account">
            {{account.nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="showRemember && !creatingEvent">
        <mat-label>Estado</mat-label>
        <mat-select formControlName="eventState" [(ngModel)]="this.event.estado">
          <mat-option *ngFor="let estado of estados" [value]="estado.id">
            {{estado.estado}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="!creatingEvent && event.estado === 3">
        <textarea formControlName="eventCanMotivo" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" placeholder="Motivo de cancelación"
        [(ngModel)]="tempMotivoCancelacion" (ngModelChange)="tempMotivoCancelacion = $event"></textarea>
        <mat-error *ngIf="editEventForm.get('eventCanMotivo').invalid">{{getEditEventErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="padding-field">
        <textarea formControlName="eventLocation" #input matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" placeholder="Localización" [(ngModel)]="tempLocation" maxlength="500"
          (ngModelChange)="tempLocation = $event"></textarea>
            <mat-icon *ngIf="propertiesControl.value !== null && propertiesControl.value.length > 0"
              matTooltip="Añadir la localización del inmueble" matSuffix aria-label="Add Property" class="material-icons add-location" (click)="addLocation(1)">home_work</mat-icon>
            <mat-icon *ngIf="clientsControl.value !== null && clientsControl.value.length > 0"
              matTooltip="Añadir la localización del cliente" matSuffix aria-label="Add Client" class="material-icons add-location" (click)="addLocation(2)">supervisor_account</mat-icon>
      </mat-form-field>
    </div>
 
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field category-event-new">
        <mat-label>Categoría</mat-label>
        <mat-select formControlName="eventCategory" [disabled]="tempType.parentId == 2" [(ngModel)]="tempType.id"
          disableOptionCentering="true" required>
          <mat-optgroup *ngFor="let group of categoryTypes" [label]="group.name" [disabled]="group.disabled">
            <mat-option *ngFor="let type of group.types" [value]="type.id">
              <div class="category-circle" [ngStyle]="{'background': type.color}"></div>
              {{type.name}}
            </mat-option>
          </mat-optgroup>
          <mat-option disabled style="color: #000000; cursor: pointer" (click)="openColorPickerDialog(null)">+ Nueva
            Categoría</mat-option>
        </mat-select>
          
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field dates-inputs">
        <mat-label>Fecha inicio</mat-label>
        <input *ngIf="tempAllDay" [ngModelOptions]="{standalone: true}" matInput type="text" mwlFlatpickr
          [options]="datePickerOptions" [ngModel]="tempStart" (ngModelChange)="cambioHoraStart($event); refresh.next()"
          [altInput]="true" [convertModelValue]="true" [enableTime]="false" [time24hr]="true" dateFormat="Y-m-d"
          altFormat="F j, Y" [disabled]="isForeign" />

        <input *ngIf="!tempAllDay" [ngModelOptions]="{standalone: true}" matInput type="text" mwlFlatpickr
          [options]="datePickerOptions" [ngModel]="tempStart" (ngModelChange)="cambioHoraStart($event); refresh.next()"
          [altInput]="true" [convertModelValue]="true" [enableTime]="true" [time24hr]="true" dateFormat="Y-m-dTH:i"
          altFormat="F j, Y H:i" [disabled]="isForeign" />
        <mat-icon matSuffix *ngIf="tempStart && !isForeign" (click)="startDateUpdatedToNull()">close
        </mat-icon>
        <!--<mat-icon matSuffix *ngIf="!tempStart && !isForeign">calendar_today</mat-icon>-->
        <mat-hint *ngIf="tempStart == null">Si no se indica una fecha de inicio, se guardará como una "Tarea sin fecha"</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field dates-inputs">
        <mat-label>Fecha fin</mat-label>
        <input *ngIf="tempAllDay" [ngModelOptions]="{standalone: true}" matInput type="text" mwlFlatpickr
          [options]="datePickerOptions" [ngModel]="tempEnd" (ngModelChange)="cambioHoraEnd($event); refresh.next()"
          [altInput]="true" [convertModelValue]="true" [enableTime]="false" [time24hr]="true" dateFormat="Y-m-d"
          altFormat="F j, Y" [disabled]="isForeign" />

        <input *ngIf="!tempAllDay" [ngModelOptions]="{standalone: true}" matInput type="text" mwlFlatpickr
          [options]="datePickerOptions" [ngModel]="tempEnd" (ngModelChange)="cambioHoraEnd($event); refresh.next()"
          [altInput]="true" [convertModelValue]="true" [enableTime]="true" [time24hr]="true" dateFormat="Y-m-dTH:i"
          altFormat="F j, Y H:i" [disabled]="isForeign"/>
        <mat-icon matSuffix *ngIf="tempEnd && !isForeign" (click)="!isForeign ? tempEnd = null : false">close
        </mat-icon>
        <!--<mat-icon matSuffix *ngIf="!tempEnd && !isForeign">calendar_today</mat-icon>-->
      </mat-form-field>

      <mat-checkbox fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="tempStart" formControlName="eventAllDay" [ngModel]="tempAllDay"
        (change)="tempAllDay = $event.checked" labelPosition="after">Todo el día</mat-checkbox>
    </div>

    <mat-divider fxFlex="100" style="margin-top: 0.4em; margin-bottom: 0.4em"></mat-divider>

   <!--  ELEMENTOS DE LOS EVENTOS-->
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
      <div *ngIf="showInmueble" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" class="padding-field">
        <mat-form-field appearance="outline" fxFlex="100" (click)="!isForeign && openAddElementEvent(event.account, 4)">
          <mat-label>Inmueble <i *ngIf="!isForeign" style="float: right;" class="material-icons">search</i>
          </mat-label>
          <input readonly="readonly" formControlName="eventSearchProperty" style="cursor: pointer;" disabled matInput
            placeholder="Asignar inmuebles" value="">
        </mat-form-field>
        <div fxFlex="100" class="truncate" *ngFor="let property of propertiesControl.value">
          <a matTooltip="{{property.internalName}} ({{property.price}}€)">
            <mat-icon *ngIf="!isForeign" (click)="!isForeign && removeProperty(property)" class="removeit">delete</mat-icon>
            {{property.reference}} <mat-icon (click)="this.loadInfoElement(1, property.id)" class="material-icons" style="font-size: 14px; width: 14px; height: 14px;">info</mat-icon>
          </a>
        </div>
      </div>

      <div *ngIf="showEncargo" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" class="padding-field">
        <mat-form-field appearance="outline" fxFlex="100" (click)="!isForeign && openAddElementEvent(event.account, 6)">
          <mat-label>Encargos <i *ngIf="!isForeign" style="float: right;" class="material-icons">search</i>
          </mat-label>
          <input readonly="readonly" formControlName="eventSearchEncargos" style="cursor: pointer;" disabled matInput
            placeholder="Asignar encargos" value="">
        </mat-form-field>
        <div fxFlex="100" class="truncate" *ngFor="let encargo of encargosControl.value">
          <a>
            <mat-icon *ngIf="!isForeign" (click)="!isForeign && removeEncargos(encargo)" class="removeit">delete</mat-icon>
            {{encargo.reference}} <mat-icon (click)="this.loadInfoElement(2, encargo.id)" class="material-icons" style="font-size: 14px; width: 14px; height: 14px;">info</mat-icon>
          </a>
        </div>
      </div>

      <div *ngIf="showCliente" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" class="padding-field">
        <mat-form-field appearance="outline" fxFlex="100" (click)="!isForeign && openAddElementEvent(event.account, 3)">
          <mat-label>Cliente <i *ngIf="!isForeign" style="float: right;" class="material-icons">search</i></mat-label>
          <input readonly="readonly" formControlName="eventSearchClient" style="cursor: pointer;" disabled matInput
            placeholder="Asignar clientes" value="">
        </mat-form-field>
        <div fxFlex="100" class="truncate" *ngFor="let client of clientsControl.value">
          <a>
            <mat-icon *ngIf="!isForeign" (click)="!isForeign && removeClient(client)" class="removeit">delete</mat-icon>
            {{client.fullName}} <mat-icon (click)="this.loadInfoElement(3, client.id)" class="material-icons" style="font-size: 14px; width: 14px; height: 14px;">info</mat-icon>
          </a>
        </div>
      </div>

      <div *ngIf="showOferta" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" class="padding-field">
        <mat-form-field appearance="outline" fxFlex="100" (click)="!isForeign && openAddElementEvent(event.account, 2)">
          <mat-label>Oferta <i *ngIf="!isForeign" style="float: right;" class="material-icons">search</i></mat-label>
          <input readonly="readonly" formControlName="eventSearchOffer" style="cursor: pointer;" disabled matInput
            placeholder="Asignar oferta" value="">
        </mat-form-field>
        <div fxFlex="100" class="truncate" *ngFor="let offer of offersControl.value">
          <a>
            <mat-icon *ngIf="!isForeign" (click)="!isForeign && removeOffer(offer)" class="removeit">delete</mat-icon>
            {{offer.offer}} <mat-icon (click)="this.loadInfoElement(4, offer.id)" class="material-icons" style="font-size: 14px; width: 14px; height: 14px;">info</mat-icon>
          </a>
        </div>
      </div>

      <div *ngIf="showIndustrial" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" class="padding-field">
        <mat-form-field appearance="outline" fxFlex="100" (click)="!isForeign && openAddElementEvent(event.account, 5)">
          <mat-label>Industriales <i *ngIf="!isForeign" style="float: right;" class="material-icons">search</i>
          </mat-label>
          <input readonly="readonly" formControlName="eventSearchIndustriales" style="cursor: pointer;" disabled matInput
            placeholder="Asignar industriales" value="">
        </mat-form-field>
        <div fxFlex="100" class="truncate" *ngFor="let client of industrialsControl.value">
          <a>
            <mat-icon *ngIf="!isForeign" (click)="!isForeign && removeIndustriales(client)" class="removeit">delete</mat-icon>
            {{client.fullName}} <mat-icon (click)="this.loadInfoElement(5, client.id)" class="material-icons" style="font-size: 14px; width: 14px; height: 14px;">info</mat-icon>
          </a>
        </div>
      </div>
      
      <div *ngIf="showParticipante" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" class="padding-field">
        <mat-form-field appearance="outline" fxFlex="100" (click)="!isForeign && openAddElementEvent(event.account, 1)">
          <mat-label>Participantes <i *ngIf="!isForeign" style="float: right;" class="material-icons">search</i>
          </mat-label>
          <input readonly="readonly" formControlName="eventSearchUser" style="cursor: pointer;" disabled matInput
            placeholder="Invitar usuarios" value="">
        </mat-form-field>
        <div fxFlex="100" class="truncate" *ngFor="let user of usersControl.value">
          <a>
            <mat-icon (click)="!isForeign && removeUser(user)" *ngIf="!isForeign" class="removeit">delete</mat-icon>
            {{user.user}}
          </a>
        </div>
      </div>
    </div>

    <mat-divider fxFlex="100" style="margin-top: 0.4em; margin-bottom: 0.4em" *ngIf="showRemember"></mat-divider>

    <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="showRemember" fxFlex="100">
      <!--- RECORDARTORIO DE AVISOS -->
      <mat-checkbox fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" formControlName="eventRecordatorio" [(ngModel)]="setNotification"
        (change)="setNotification = $event.checked" labelPosition="after">Recordatorio
      </mat-checkbox>

      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="setNotification">
        <mat-label>Recordatorio 1</mat-label>
        <mat-select formControlName="eventRecordatorio1" [(ngModel)]="selectedNotification1">
          <mat-option> -- </mat-option>
          <mat-option *ngFor="let notification of notifications" [value]="notification"
            [disabled]="selectedNotification2?.id === notification.id || selectedNotification3?.id === notification.id">
            {{notification.tiempo}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="setNotification">
        <mat-label>Recordatorio 2</mat-label>
        <mat-select formControlName="eventRecordatorio2" [(ngModel)]="selectedNotification2"
          [disabled]="selectedNotification1 === undefined || isForeign">
          <mat-option> -- </mat-option>
          <mat-option *ngFor="let notification of notifications" [value]="notification"
            [disabled]="selectedNotification1?.id === notification.id || selectedNotification3?.id === notification.id">
            {{notification.tiempo}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="setNotification">
        <mat-label>Recordatorio 3</mat-label>
        <mat-select formControlName="eventRecordatorio3" [(ngModel)]="selectedNotification3"
          [disabled]="selectedNotification2 === undefined || selectedNotification1 === undefined || isForeign">
          <mat-option> -- </mat-option>
          <mat-option *ngFor="let notification of notifications" [value]="notification"
            [disabled]="selectedNotification1?.id === notification.id || selectedNotification2?.id === notification.id">
            {{notification.tiempo}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

     <!--  PERIODICIDAD DE LOS EVENTOS-->
     <ng-container *ngIf="tempStart !== null && event.idParentPeriodicity === null">
      <mat-divider fxFlex="100" style="margin-top: 0.4em; margin-bottom: 0.4em"></mat-divider>

      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-checkbox fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="event?.start" formControlName="eventIsPeriod" [(ngModel)]="isPeriod"
          (change)="isPeriod = $event.checked" style="padding-right: 20px;" labelPosition="after">Periodicidad
        </mat-checkbox>
  
        <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="isPeriod">
          <mat-label>Periodicidad de la actividad</mat-label>
          <mat-select formControlName="eventPeriod" [(ngModel)]="selectedValue" [required]="isPeriod">
            <mat-option *ngFor="let period of periods" [value]="period.value">
              {{period.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="padding-field" *ngIf="isPeriod">
          <mat-label>Frecuencia </mat-label>
          <input formControlName="eventFrecuency" type="number" min="1" style="cursor: pointer;" matInput
            [ngModel]="interval" (ngModelChange)="interval = $event" (keyup)="refresh.next()"
            value="" [required]="isPeriod">
        </mat-form-field>
  
        <mat-form-field appearance="outline" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="dates-inputs padding-field" *ngIf="isPeriod">
          <mat-label>Fin de la periodicidad</mat-label>
          <input matInput mwlFlatpickr type="text" [options]="datePickerOptions" [ngModel]="periodEnd"
            formControlName="eventEndPeriod" (ngModelChange)="updatePeriodEnd($event)" [altInput]="true"
            [convertModelValue]="true" [enableTime]="false" [time24hr]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y"
            [disabled]="periodDateIsInvalid()" [required]="isPeriod"/>
          <mat-error *ngIf="periodDateIsInvalid() && periodEnd !== null">{{this.strings.PERIOD_END_DATE_ERROR_MESSAGE }}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <mat-divider fxFlex="100" style="margin-top: 0.4em; margin-bottom: 0.4em"></mat-divider>

    <mat-form-field appearance="outline" fxFlex="100">
      <textarea formControlName="eventComentario" #input matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" placeholder="Comentario" [(ngModel)]="tempComment" maxlength="1000"
        (ngModelChange)="tempComment = $event"></textarea>
      <mat-hint *ngIf="input.value.length >= 1" class="{{input.value.length >= 800 ? input.value.length < 1000 ? 'almostFull' : 'full' : ''}}" align="end">{{input.value?.length || 0}}/1000</mat-hint>
    </mat-form-field>

    <mat-divider fxFlex="100" style="margin-top: 0.4em; margin-bottom: 0.4em"></mat-divider>
  </div>

  <div mat-dialog-actions class="add-event" *ngIf="!isForeign">
    <ng-container *ngIf="!creatingEvent">
      <button *ngIf="(tempType.firmar === 1) && !event.signImgURL && showFirma" (click)="onClickSign(event)"
        class="btns-filter btn-outline-primary btn1"
        cdkFocusInitial>
        <i style="margin-right: 0.3em;" class="material-icons">gesture</i>
        Firmar
      </button>
      <button  *ngIf="(tempType.firmar === 1) && event.signImgURL && showFirma" (click)="onClickVisualize(event)"
        class="btns-filter btn-outline-primary btn1"
        cdkFocusInitial>
        <i style="margin-right: 0.3em;" class="material-icons">remove_red_eye</i>
        Ver firma
      </button>
      <button class="btns-filter btn-outline-danger btn1"
        (click)="onClickDeleteActivity(event)" cdkFocusInitial>
        <i style="margin-right: 0.3em;" class="material-icons">delete_forever</i>
        Eliminar
      </button>
      <button class="btns-filter btn-outline-success btn1"
        (click)="editClick($event)" cdkFocusInitial>
        <i style="margin-right: 0.3em;" class="material-icons">save</i>
        Guardar cambios
      </button>
    </ng-container>
    <button class="btns-filter" *ngIf="creatingEvent" 
      (click)="newEventClick()">
      Añadir tarea
    </button>
  </div>
</form>
