import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FunctionsService } from '../../../services/functions/functions.service';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.css']
})
export class GoogleMapsComponent implements OnInit {
  @Input() structure = null;
  @Input() index : number;
  @Input() param : any = null;

  public src;
  public loaded = false;

  constructor(public sanitizer: DomSanitizer,
    public functionsService: FunctionsService) { }

  ngOnInit(): void {
    let validReq = this.functionsService.formsArray(this.structure[this.index], this.param, false);
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    let longitud = null;
    let latitud = null;
    for(let i in values) {
      if(values[i]['field'] == 'longitud') longitud = values[i]['value'];
      if(values[i]['field'] == 'latitud') latitud = values[i]['value'];
      if(longitud !== null && latitud !== null) break;
    }
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed/v1/view?key=AIzaSyDDD_E7LB2dKMu2qJniasF0fMatAyfmaXE&center=" + latitud + "," + longitud + "&zoom=20&maptype=satellite");
    this.loaded = true;
  }

}
