import { Component, OnInit, Inject } from '@angular/core';
import { EndpointService } from '../../../../services/endpoint/endpoint.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-user-profile',
  templateUrl: './dialog-user-profile.component.html',
  styleUrls: ['./dialog-user-profile.component.css']
})
export class DialogUserProfileComponent implements OnInit {
  public  perfiles: any;
  private inmoId : Number;
  public selectedValue: any;

  constructor(private endpointService: EndpointService, 
    public dialogRef: MatDialogRef<DialogUserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
    this.inmoId = data.inmoId;
  }

  ngOnInit(): void {
    this.endpointService.getProfiles(this.inmoId).subscribe(data => {
      this.perfiles = data['response'];
    });
  }

  onClick(){
    this.dialogRef.close({profile_id: this.selectedValue});
  }

  profileChanged(event){
    console.log('profileChanged', event);
    this.selectedValue = event;
  }

}
