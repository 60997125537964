<mat-nav-list>
  <a mat-list-item (click)="onClickDuplicateRenting(); closeBottomSheet();">
    <span mat-line>Duplicar como alquiler</span>
  </a>

  <a mat-list-item (click)="onClickDuplicateSale(); closeBottomSheet();">
    <span mat-line>Duplicar como venta</span>
  </a>

</mat-nav-list>
