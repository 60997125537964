import {
  Component,
  OnInit, Pipe, PipeTransform,
  ViewChild,
} from '@angular/core';
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { MatSidenav } from "@angular/material/sidenav";
import { Values } from "../../../values/values";
import { MonitoringActions, MonitoringDecorator } from "../../services/track-decorators";
import { Strings } from "../../../strings/strings";
import { AuthService } from "../../services/auth/auth.service";

export interface KnowledgeItem {
  id: number,
  imagen: string,
  titulo: string,
  subtitulo: string,
  descripcion: string,
  fecha_alta: Date,
  fecha_publicacion: Date,
  fecha_despublicacion: Date,
  visualizaciones: number,
  gratuito: number,
  precio: number,
  id_knowledge_tipo_item: number,
  id_knowledge_categoria: number,
  url_item_intern: string
  url_item_extern: string
}

@Pipe({name: 'demoNumber'})
export class DemoNumber implements PipeTransform {
  transform(value, args:string[]) : any {
    let res = [];
    for (let i = 0; i < value; i++) {
      res.push(i);
    }
    return res;
  }
}

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.css']
})
export class KnowledgeComponent implements OnInit {

  public info = [];
  public data: any = [{name: 'Coming Soon', src: 'assets/img/ComingSoon.png'}];
  @ViewChild('sidenav') sidenav: MatSidenav;

  public over = 'over';

  public text_pdf: string = null;
  public url_item_intern = null;
  public url_item_extern = null;
  public tipos = Values.KNOWLEDGE_TYPES;
  public responsiveOptions = Values.RESPONSIVE_OPTIONS;
  constructor(private endpointService: EndpointService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.voidMethodForMonitoring(null);
    this.getKnowledgeInfo();
  }
  @MonitoringDecorator(MonitoringActions.VIEW, Strings.USERTRACKVIEWCODE.KNOWLEDGE_SEARCH)
  private voidMethodForMonitoring(id) {
    return id;
  }
  public getKnowledgeInfo() {
    this.endpointService.getKnowledgeInfo().subscribe(data => {

      Object.keys(data['response']).forEach(row => {
        this.info.push(data['response'][row]);
      });
    });
  }

  toggleDrawer(i: number, text_pdf: string, url_item_intern: string) {
    let url = '';
    this.text_pdf = text_pdf;
    this.url_item_intern = url.concat(url_item_intern, '#toolbar=0');
    this.sidenav.toggle();
  }
}
