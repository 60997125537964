<ng-container *ngIf="loaded">
  <ng-container *ngIf="structure[this.index]['type'] === 'submit'" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" matBadge="this.structure[this.index]['badge']">
    <button mat-button [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']" type="submit" [attr.form]="'form'+structure['id_functional_parent_initial']" [disabled]="!functionFinish[this.index]" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" matBadge="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon'] && functionFinish[this.index]" class="{{structure[this.index]['icon_type']}}" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" matBadge="this.structure[this.index]['badge']"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml" *ngIf="functionFinish[this.index] && this.structure[this.index]['label']"></span>
        <mat-spinner style="margin:0 auto;" diameter="20" *ngIf="!functionFinish[this.index]"></mat-spinner>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'button'">
    <button mat-button *ngIf="((this.structure[this.index]['id_function'] == 7 || this.structure[this.index]['id_function'] == 8 || this.structure[this.index]['id_function'] == 54) && this.getNumChanges() > 0 && this.structure[this.index]['id_functional_parent_initial_dsb'] == 0) || (this.structure[this.index]['id_function'] != 7  && this.structure[this.index]['id_function'] != 8 || this.structure[this.index]['id_function'] == 54) || this.structure[this.index]['id_functional_parent_initial_dsb'] != 0" [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']" (click)="onClickEvent(this)" [disabled]="!functionFinish[this.index]" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon'] && functionFinish[this.index]" class="{{structure[this.index]['icon_type']}}" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml" *ngIf="functionFinish[this.index] && this.structure[this.index]['label']"></span>
        <mat-spinner style="margin:0 auto;" diameter="20" *ngIf="!functionFinish[this.index]"></mat-spinner>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'nextStep'">
    <button mat-button [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']" matStepperNext (click)="onClickEvent(this)" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon']" class="{{structure[this.index]['icon_type']}}" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml" *ngIf="this.structure[this.index]['label']"></span>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'previousStep'">
    <button mat-button [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']" matStepperPrevious (click)="onClickEvent(this)" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon']" class="{{structure[this.index]['icon_type']}}" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml" *ngIf="this.structure[this.index]['label']"></span>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'menu' && structure[index]['child'][0]['child'] && structure[index]['child']">
    <button #button mat-button [matMenuTriggerFor]="submenu" #menuTrigger="matMenuTrigger" (mouseenter)="buttonEnter(menuTrigger)" (mouseleave)="buttonLeave(menuTrigger)" (mouseover)='over()' (mouseout)='out()'
    [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']" class="menu-button"
    [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon']" class="{{structure[this.index]['icon_type']}}" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml" *ngIf="this.structure[this.index]['label']"></span>
      </ng-container>
    </button>
    <mat-menu #submenu="matMenu" direction="down">
      <span>
        <app-generic 
        [structure]="structure[index]" [param]="param"></app-generic>
      </span>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'menu' && !structure[index]['child']">
    <button mat-menu-item *ngIf="((this.structure[this.index]['id_function'] == 7 || this.structure[this.index]['id_function'] == 8 || this.structure[this.index]['id_function'] == 54) && this.getNumChanges() > 0 && (this.structure[this.index]['window'] == this.structure[this.index]['id_functional_parent_initial'])) || (this.structure[this.index]['id_function'] != 7  && this.structure[this.index]['id_function'] != 8 || this.structure[this.index]['id_function'] == 54) || (this.structure[this.index]['window'] != this.structure[this.index]['id_functional_parent_initial'])" [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
      (click)="onClickEvent(this)" [disabled]="!functionFinish[this.index]" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon'] && functionFinish[this.index]" class="{{structure[this.index]['icon_type']}}" [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)" [matBadge]="this.structure[this.index]['badge']"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml" *ngIf="functionFinish[this.index] && this.structure[this.index]['label']"></span>
      </ng-container>
    </button>
  </ng-container>
</ng-container>