import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import { FunctionsService } from 'app/services/functions/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TextComponent implements OnInit {

  @Input() structure = null;
  @Input() index : number;
  @Input() param : any = null;


  // public textType: string;
  public idTable = null;
  public idRow = null;

  public functionFinish = {};


  constructor(public sanitizer: DomSanitizer,
              public authService: AuthService,
              private functionsService: FunctionsService,
              private genericService: GenericService) { }

  ngOnInit(): void {
    
    if(this.structure[this.index]['id_functional_area'] == 253) console.log('JAAAN HTML:', this.structure[this.index]['text'])
    // this.textType = this.structure[this.index]['type'] !== '' ? this.structure[this.index]['type'] : 'p';
    if(this.structure[this.index]['type'] == 'iframe' && !this.structure[this.index]['sanitizer']) {
      this.structure[this.index]['url'] = this.sanitizer.bypassSecurityTrustResourceUrl(this.structure[this.index]['url'])
      this.structure[this.index]['sanitizer'] = true;
    }

    this.functionFinish[this.index] = true;

    if(this.param !== null) {
      let aux = this.param.split('.')
      this.idTable = aux[0]
      this.idRow = aux[1]
    }
    if(this.structure[this.index]['type'] == "img" && this.structure[this.index]['url'].includes("/empresas/")) {
      this.structure[this.index]['url'] = this.structure[this.index]['url'] + "?" + new Date().getTime();
    }
    console.log('text structure', this.structure);
  }

  onClickEvent = (eventTrigger) => {
    console.log('entro text');
    if (eventTrigger['structure']) {
      //this.functionsService.getFunction(eventTrigger['structure']);
      if(this.idTable == null && this.idRow == null) {
        let data = {done: false};
        this.functionFinish[this.index] = false;
        console.log('this.structure[this.index]', this.structure[this.index], 'data', data);
        this.functionsService.getFunction(this.structure[this.index], data);
        this.functionFinish[this.index] = data.done;
        console.log('button', this.functionFinish[this.index]);
      }
      else {
        this.functionsService.getFunction(this.structure[this.index], this.genericService.tableData[this.idTable][this.idRow])
      }
    }
  }


  scrollTo(id:string){
    let el = document.getElementById(id.slice(1));
    this.genericService.scroll(id.slice(1));
  }

}
