<div style="position: relative; margin: 15px 0;" class="full-content-chip-creator">
  <mat-label class="label-chip-custom">{{label}}</mat-label>
  <angular-editor
    [(ngModel)]="htmlContent"
    (ngModelChange)="sendHtmlCode($event)"
    [config]="config"
    #editor
  >
  </angular-editor>
  
  <div class="add-button-chip">
    <div class="generic-buttons-blue" style="margin-right: 5px;" *ngIf="showIcons">
      <button mat-raised-button (click)="toggled = !toggled" [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'" (emojiPickerSelect)="handleSelection($event)">
        <i class="material-icons">emoji_emotions</i> Añadir icono
      </button>
    </div>
    <div class="generic-buttons-blue" *ngIf="!showSelector">
      <button mat-raised-button (click)="addChip()">
        <i class="material-icons">new_label</i> Añadir chip
      </button>
    </div>
  </div>
  
  <span *ngIf="showSelector" class="selector-chip-group">
    <mat-form-field appearance="outline" class="selector-chip">
      <mat-label>Chips</mat-label>
      <input
        type="text"
        placeholder="Buscar chip"
        aria-label="Number"
        matInput
        [formControl]="chipLabelControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete (optionSelected)="addText(chipLabelControl.value)" #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  
    <div class="generic-buttons-blue close-button-chip">
      <button mat-raised-button (click)="addChip()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </span>
</div>