<div mat-dialog-content>
  <mat-horizontal-stepper class="stepper" linear #stepper>

    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Datos de recuperación</ng-template>
        <mat-form-field>
          <!--<input type="email" email formControlName="firstCtrl" matInput placeholder="{{Strings.EMAIL}}" required [(ngModel)]="recoverMail"/>-->
          <input matInput placeholder="{{Strings.EMAIL}}" [(ngModel)]="recoverMail" formControlName="firstCtrlEmail2" size="50">
          <!--<mat-hint>Los errores se muestran al momento</mat-hint>-->
          <mat-error *ngIf="firstFormGroup.get('firstCtrlEmail2').hasError('email') && !firstFormGroup.get('firstCtrlEmail2').hasError('required')">
            Correo electrónico inválido
          </mat-error>
          <mat-error *ngIf="firstFormGroup.get('firstCtrlEmail2').hasError('required')">
            Debe informar el correo electrónico
          </mat-error>
        </mat-form-field>
        <div>
          <button mat-button (click)="sendMailToRecoverPassword()">{{Strings.SEND_MAIL}}</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup" [editable]="false">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Código de verificación</ng-template>
        <mat-form-field>
          <input formControlName="secondCtrl" matInput placeholder="{{Strings.CODE}}" required [(ngModel)]="code"/>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>Cambiar email</button>
          <button *ngIf="!codeChecked" mat-button (click)="checkCode()">{{Strings.CHECK_CODE}}</button>
          <button *ngIf="codeChecked" mat-button matStepperNext>Siguiente</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="thirdFormGroup">
      <form [formGroup]="thirdFormGroup">
        <ng-template matStepLabel>Nueva contraseña</ng-template>

        <div>
          <mat-form-field style="width: 45%;margin-right: 10% !important;margin-left: 0 !important;display: inline-block;margin-top: 0.6em;">
            <input matInput formControlName="pass1" required placeholder="{{Strings.NEW_PASS}}">
          </mat-form-field>

          <mat-form-field style="width: 45%;margin-left: 0 !important;display: inline-block;margin-top: 0.6em;">
            <input matInput formControlName="pass2" required placeholder="{{Strings.REPEAT_PASS}}">
            <div *ngIf="formErrors.pass2" class="alert alert-danger">
              {{ formErrors.pass2 }}
            </div>
          </mat-form-field>
        </div>

        <div>
          <button mat-button matStepperNext (click)="submitNewPassword()">{{Strings.CHANGE_PASSWORD}}</button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Terminado</ng-template>
      La contraseña ha sido cambiada correctamente.
      <div>
        <button mat-button (click)="closeModal()">Cerrar</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
