import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import {NotificationsComponent} from "../../../home/subcomponents/notifications/notifications.component";
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-notifications-custom',
  templateUrl: './notifications-custom.component.html',
  styleUrls: ['./notifications-custom.component.scss']
})
export class NotificationsCustomComponent implements OnInit {

  @Input() structure;

  @ViewChild('notis1') public notis1: NotificationsComponent;
  @ViewChild('notis2') public notis2: NotificationsComponent;
  @ViewChild('notis3') public notis3: NotificationsComponent;


  // Structure
  public showNotificationBool: boolean;

  public showMenuNotisBool: boolean = false;
  public showMenuVisitasBool: boolean = false;
  public showMenuGestionarActivBool: boolean = true;

  public ego_not_num: any = null;
  public visita_not_num: any = null;
  public gestor_actividades_num: any = null;


  constructor(
    public authService: AuthService,
    public genericService: GenericService
  ) { }

  ngOnInit(): void {
    this.checkStructure();
  }


  getEgoNotificationsCount(event: any) {
    this.ego_not_num = event
  }

  getVisitNotificationsCount(event: any) {
    this.visita_not_num = event;
  }

  getGestorActividadesCount(event: any) {
    this.gestor_actividades_num = event;
  }

  public checkStructure(){
    if(this.structure != undefined && this.structure['value']['child']) {
      console.log("notttis structure this.structure['value']['child']" , this.structure['value']['child']);
      for(let x in this.structure['value']['child']) {
        console.log(x, "notttis structure x");
        if(this.structure['value']['child'][x]['id_functional_type'] === 2
        && this.genericService.evaluateIfs(this.structure['value']['child'][x]['id_condition'], this.structure['value']['child'][x]['id_functional_area'],this.structure['value']['child'][x])
        && this.structure['value']['child'][x]['id_functional_status_general'] !== 2
        && this.structure['value']['child'][x]['id_functional_status_mkt'] === 1) {
          if(this.structure['value']['child'][x]['id_functional_area'] === 119) this.showMenuNotisBool = true;
          if(this.structure['value']['child'][x]['id_functional_area'] === 120) this.showMenuVisitasBool = true;
          if(this.structure['value']['child'][x]['id_functional_area'] === 121) this.showMenuGestionarActivBool = true;
        }
      }
      console.log("notttis structure resultBools" , this.structure['value']['child'], this.showMenuNotisBool, this.showMenuVisitasBool, this.showMenuGestionarActivBool);
    }
  }
}
