import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericService } from '../generic.service';
import * as _ from "lodash";
import { FunctionsService } from 'app/services/functions/functions.service';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { map, startWith } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


@Injectable({
  providedIn: 'root'
})
export class GenericCreatorService {

  public arrayElementsGenericCreator = [];
  public tmpId = 0;
  public currentRecordIndex: number;
  public arrayElementsGenericCreatorRecord = []
  public arrayElementsTipo = [];
  public functional_subtype = [];
  public defaultArray = [];
  public functionalStatus = [];
  public class_angular_icon = [];
  public filteredFunctionalStatus = [];
  public filteredFunctionalTypes = [];
  public languages = [];
  public functionalSubtypes = [];
  public currentElement: any;
  public currentIdTmpParent: any;
  public actualSelectedElement: any;
  public activeTabIndex = 1;
  public expandedFloatingIndex = null;
  public expandedFloatingElement: any;
  public listElementOptionToggle = false;
  public actualSelectedFunctionalTypeChild: any;
  public copiedElement: any;
  currentLanguage = 1;
  public defaultLanguageElement: any;
  public get_all_columns_excluding_data_type_fa_restrictions: any;
  public get_all_columns_fa_restrictions: any;
  public queries: any;
  public db: any;
  public functional_type: any;
  public id_query: any;
  public id_db: any;
  public bd_table: any;
  public id_functional_type: any;
  public id_product_mkt: any;
  public id_product_mkt_no: any;
  public id_condition: any;
  public condition_internal_routing: any;
  public condition_session_active: any;
  public class_custom: any;
  public permissions_group: any;
  public functions: any;
  public company_type: any;
  public data_type: any;
  public admin_area: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public arrayFloatingElements = [];
  public actualSelectedSubElement: any;
  public isSubElement = false;
  public isAddingElement = false;
  public expandedIndex = 1;
  public hideOptions = [
    {label: 'No', value: 0},
    {label: 'Yes', value: 1},
    {label: 'Custom', value: 2}
  ]
  filteredOptions: Observable<string[]>;
  companyTypeOptions: Observable<string[]>;
  companyTypeOptionsRestriction: Observable<string[]>;
  idProductMktOptions: Observable<string[]>;
  idProductMktNoOptions: Observable<string[]>;
  idConditionOptions: Observable<string[]>;
  conditionInternalRoutingOptions: Observable<string[]>;
  profilePermissionOptions: Observable<string[]>;
  idFunctionOptions: Observable<string[]>;
  idConditionBadgeOptions: Observable<string[]>;
  idQueryOptions: Observable<string[]>;
  containerForm: any;
  actualSelectedElementObs$: Observable<any>;
  private actualSelectedElementObsSubject = new Subject<any>();

  constructor( public genericService: GenericService,
    public formBuilder: FormBuilder,
    public functionsService: FunctionsService,
    public endpointService: EndpointService) {
      this.containerForm = this.formContainer();
      this.actualSelectedElementObs$ = this.actualSelectedElementObsSubject.asObservable();
    }

    setActualSelectedElement(data: any) {
      this.actualSelectedElementObsSubject.next(data);
    }

    checkIndexClose(index){
      if(index === this.expandedIndex){
        this.expandedIndex = 0
      }
    }

  formBuilderCustom(element) {
    console.log('=====', element)
    return this.formBuilder.group({
      id_functional_type: [element.id_functional_type, Validators.required],
      id_functional_status_general: [element.id_functional_status_general == 0 ? 1 : element.id_functional_status_general, Validators.required],
      type: [element.type, this.getValidation('type')],
      label: [element.label, this.getValidation('label')],
      class_custom: [element.class_custom],
      style: [element.style],
      hide: [element.hide ? element.hide : 0],
      hide_xs: [element.hide_xs],
      hide_s: [element.hide_s],
      hide_m: [element.hide_m],
      hide_l: [element.hide_l],
      hide_xl: [element.hide_xl],
      width_general: [element.width_general, [Validators.max(100), Validators.min(0)]],
      isAutomaticResponsive: [false],
      width_xs: [element.width_xs, [Validators.max(100), Validators.min(0)]],
      width_s: [element.width_s, [Validators.max(100), Validators.min(0)]],
      width_m: [element.width_m, [Validators.max(100), Validators.min(0)]],
      width_l: [element.width_l, [Validators.max(100), Validators.min(0)]],
      width_xl: [element.width_xl, [Validators.max(100), Validators.min(0)]],
      id_product_mkt: [element.id_product_mkt],
      id_product_mkt_no: [element.id_product_mkt_no],
      id_condition: [element.id_condition],
      condition_internal_routing: [element.condition_internal_routing],
      condition_session_active: [element.condition_session_active],
      dev: [element.dev],
      buttonType: ['', this.getValidation('buttonType')],
      min_rows: [element.min_rows],
      max_rows: [element.max_rows],
      max_lengths: [element.max_lengths],
      form_field_empty: [element.form_field_empty],
      profiles_permissions: [element.profiles_permissions],
      permissions_group_functional_area: [[]],
      tooltip: [element.tooltip],
      icon: [element.icon],
      icon_type: [element.icon_type],
      badge: [element.badge],
      id_condition_badge: [element.id_condition_badge],
      id_function: [element.id_function],
      duplicate_id_functional_area: [element.duplicate_id_functional_area],
      internal_routing: [element.internal_routing],
      internal_routing_id_functional_area: [element.internal_routing_id_functional_area],
      text: [element.text, this.getValidation('text')],
      url: [element.url, this.getValidation('url')],
      iframe_fullscreen: [element.iframe_fullscreen],
      id_query: [element.id_query],
      show_total_graphs: [element.show_total_graphs],
      carousel_num_visible: [element.carousel_num_visible],
      carousel_num_scroll: [element.carousel_num_scroll],
      carousel_circular: [element.carousel_circular],
      expansion_start_opened: [element.expansion_start_opened],
      id_db: [element.id_db],
      id_table: [element.id_table],
      search: [element.search],
      pagination: [element.pagination],
      canExportTables: [element.canExportTables],
      internal_name: [element.internal_name],
      bd_table: [element.bd_table],
      close_dialog_out: [element.close_dialog_out],
      description: [element.description],
      bd_field: [element.bd_field],
      id_table_relations: [element.id_table_relations],
      param: [element.param],
      param_intern: [element.param_intern],
      multiple: [element.multiple],
      carousel_num_scroll_s: [element.carousel_num_scroll_s],
      carousel_num_scroll_xs: [element.carousel_num_scroll_xs],
      carousel_num_visible_s: [element.carousel_num_visible_s],
      carousel_num_visible_xs: [element.carousel_num_visible_xs],
    })
  }

  formDataValidity(){
    return this.formBuilder.group({
      id_data_type: ['', Validators.required],
      id_admin_area: ['', Validators.required],
      id_company_type: [[], Validators.required],
      companyTypes: [''],
      id_functional_status_general: ['', Validators.required]
    })
  }

  formRestriction(){
    return this.formBuilder.group({
      activation_date: ['', Validators.required],
      id_admin_area: ['', Validators.required],
      id_company_type: [[], Validators.required],
      companyTypes: [''],
      id_functional_status_general: ['', Validators.required]
    })
  }

  formContainer(){
    return this.formBuilder.group({
        id_query: [''],
        id_db: [''],
        bd_table: [''],
    })    
  }

  // return required validation based on functional type
  getValidation(formField: any){
    switch(formField){
      case 'type':
        if(this.actualSelectedElement.id_functional_type == 5 || this.actualSelectedElement.id_functional_type == 101
          || this.actualSelectedElement.id_functional_type == 14 || this.actualSelectedElement.id_functional_type == 99
          || this.actualSelectedElement.id_functional_type == 96 || this.actualSelectedElement.id_functional_type == 9){
          return Validators.required
        }
        break;

      case 'label':
        if(this.actualSelectedElement.id_functional_type == 5 || this.actualSelectedElement.id_functional_type == 14 
          || this.actualSelectedElement.id_functional_type == 4 || this.actualSelectedElement.id_functional_type == 105
          || this.actualSelectedElement.id_functional_type == 7 || this.actualSelectedElement.id_functional_type == 95
          || this.actualSelectedElement.id_functional_type == 9 || this.actualSelectedElement.id_functional_type == 86
          || this.actualSelectedElement.id_functional_type == 6 || this.actualSelectedElement.id_functional_type == 12 
          || this.actualSelectedElement.id_functional_type == 15 || this.actualSelectedElement.id_functional_type == 113 
          || this.actualSelectedElement.id_functional_type == 121){
          return Validators.required
        }
        break;

      case 'buttonType':
        if(this.actualSelectedElement.id_functional_type == 5){
          return Validators.required
        }
        break;

      case 'text':
        if(this.actualSelectedElement.id_functional_type == 118 || this.actualSelectedElement.id_functional_type == 101){
          return Validators.required
        }
        break;

      case 'url':
        if(this.actualSelectedElement.id_functional_type == 102 || this.actualSelectedElement.id_functional_type == 137
          || this.actualSelectedElement.id_functional_type == 138){
          return Validators.required
        }
        break;

      case 'id_db':
        if(this.actualSelectedElement.id_functional_type == 6 || this.actualSelectedElement.id_functional_type == 12
          || this.actualSelectedElement.id_functional_type == 15 || this.actualSelectedElement.id_functional_type == 113
          || this.actualSelectedElement.id_functional_type == 121){
          return Validators.required
        }
        break;

      case 'bd_table':
      if(this.actualSelectedElement.id_functional_type == 6 || this.actualSelectedElement.id_functional_type == 12
        || this.actualSelectedElement.id_functional_type == 15 || this.actualSelectedElement.id_functional_type == 113
        || this.actualSelectedElement.id_functional_type == 121){
        return Validators.required
      }
      break;

      case 'bd_field':
      if(this.actualSelectedElement.id_functional_type == 6 || this.actualSelectedElement.id_functional_type == 12
        || this.actualSelectedElement.id_functional_type == 15 || this.actualSelectedElement.id_functional_type == 113
        || this.actualSelectedElement.id_functional_type == 121){
        return Validators.required
      }
      break;

      case 'id_query':
      if(this.actualSelectedElement.id_functional_type == 12){
        return Validators.required
      }
      break;

        default:
          return null
          break;
    }
  }

  _filter(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.data_type.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  _filterCompanyTypes(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.company_type.filter(item => item.internal_name.toLowerCase().includes(filterValue));
  }

  _filterProductMkt(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.id_product_mkt.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  _filterProductMktNo(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.id_product_mkt_no.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  _filterIdCondition(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.id_condition.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  _filterConditionInternalRouting(value: any): string[] {
    const filterValue = value.internal_routing ? value.internal_routing.toLowerCase() : value.toLowerCase();
    return this.condition_internal_routing.filter(option => option.internal_routing.toLowerCase().includes(filterValue));
  }

  _filterProfilePermission(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.permissions_group.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  _filterIdFunction(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.functions.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  _filterIdConditionBadge(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.id_condition.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  _filterIdQuery(value: any): string[] {
    const filterValue = value.internal_name ? value.internal_name.toLowerCase() : value.toLowerCase();
    return this.queries.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  filterFunctionalTypes(){
    this.filteredFunctionalTypes = [];
    let allTypes = _.cloneDeep(this.arrayElementsTipo)
    allTypes.forEach((item: any) => {
      if(item.form_field == 1){this.filteredFunctionalTypes.push(item)}
    })
  }

  // Filter Status based on functional types
  filterStatusOptions(type: any){
    console.log('type----', type);
    this.filteredFunctionalStatus = [];
    let allStatus = _.cloneDeep(this.functionalStatus)
    if(type == 89 || type == 101
      || type == 5 || type == 91
      || type == 118 || type == 102
      || type == 14 || type == 138
      || type == 137 || type == 11
      || type == 117 || type == 4 
      || type == 99 || type == 105
      || type == 10 || type == 7
      || type == 96 || type == 95
      || type == 3 || type == 100
      || type == 9 || type == 114
      || type == 115 || type == 116
      || type == 140 || type == 85
      || type == 86 || type == 87
      || type == 88 || type == 98
      || type == 141){
      allStatus.forEach((item: any) => {
        if(item.id_functional_status == 1 || item.id_functional_status == 2){
          this.filteredFunctionalStatus.push(item)
        }
      });
    } else {
      this.filteredFunctionalStatus =  this.functionalStatus
    }
  }

  // set value in class_custom when select from button type selection
  setValueForClass(event: any, form){
    form.patchValue({class_custom: event.value});
  }

  conditionDependingOfFuntionalTypes(element: any, type: any){
   // 8 Tab Group, 4 Tab, 89 Divider, 5 Button, 101 Text, 137 Image, 138 Iframe, 91 Box, 118 HTML/Canva, 102 YouTube, 14 Graphs
   // 11 Form, 117 Carousel, 99 List Group, 105 List Item, 10 Expansion Group, 7 Expansion, 96 Stepper Group, 95 Step, 3 Table, 9 Table Column, 98 Bottom sheet
   // 100 Card, 114 Card-header, 115 Card-content, 116 Card-buttons, 140 Card Dynamic content, 85 Dialog, 86 Dialog Title, 87 Dialog Content, 88 Dialog button
   // 6 Input, 12, Selection, 15 Checkbox, 113 File Upload, 121 Location
    switch(element){

      case 'id_functional_type':
        if(type == 8 || type == 89
          || type == 5 || type == 101 
          || type == 137 || type == 138
          || type == 11 || type == 117
          || type == 4 || type == 99 
          || type == 105 || type == 10
          || type == 7 || type == 96
          || type == 95 || type == 3
          || type == 100 || type == 9
          || type == 114 || type == 115
          || type == 116 || type == 140
          || type == 85 || type == 86
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'type':
        if(type == 5 || type == 101 
          || type == 99 || type == 14
          || type == 96 || type == 9
          || type == 6 || type == 12 || type == 15 || type == 113){
          return true
        }
        break;

      case 'id_functional_status_general':
        if(type == 8 || type == 89 
          || type == 5 || type == 101 
          || type == 137 || type == 138
          || type == 91 || type == 102
          || type == 117 || type == 4
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 140 || type == 85
          || type == 86 || type == 87
          || type == 88 || type == 98 || type == 141){
          return true
        }
        break;

      case 'class_custom':
        if(type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 114 || type == 115
          || type == 116 || type == 140
          || type == 85 || type == 86
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'style':
        if(type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 114 || type == 115
          || type == 116 || type == 4 || type == 141
          || type == 140 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'hide':
        if(type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 86 || type == 87 || type == 141
          || type == 88 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'width':
        if(type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 114 || type == 115
          || type == 116 || type == 4 || type == 141
          || type == 140 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'id_product_mkt':
        if(type == 8 || type == 89 
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'id_product_mkt_no':
        if(type == 8 || type == 89 
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'id_condition':
        if(type == 8 || type == 89 
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116 || type == 141
          || type == 4 || type == 140 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'condition_internal_routing':
        if(type == 8 || type == 89 
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'condition_session_active':
        if(type == 8 || type == 89 
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'dev':
        if(type == 8 || type == 89 
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'buttonType':
        if(type == 8 || type == 5
          || type == 10){
          return true
        }
        break;

      case 'min_rows':
        if(type == 8 || type == 6){
          return true
        }
        break;

      case 'max_rows':
        if(type == 6){
          return true
        }
      break;

      case 'max_lengths':
        if(type == 6){
          return true
        }
      break;

      case 'form_field_empty':
        if(type == 6 || type == 12 || type == 121){
          return true
        }
      break;

      case 'profiles_permissions':
        if(type == 89 || type == 5 
          || type == 101 || type == 137
          || type == 138 || type == 91
          || type == 118 || type == 102
          || type == 14 || type == 11
          || type == 117 || type == 99 
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 4 || type == 105
          || type == 115 || type == 116
          || type == 140 || type == 85
          || type == 86 || type == 87 || type == 141
          || type == 88 || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'label':
        if(type == 5 || type == 14 ||
          type == 4 || type == 105 ||
          type == 7 || type == 95 ||
          type == 9 || type == 114
          || type == 115 || type == 86 || type == 141
          || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'tooltip':
        if(type == 5  || type == 101 || type == 4
          || type == 137 || type == 138
          || type == 102 || type == 11
          || type == 117 || type == 105
          || type == 7 || type == 95
          || type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'badge':
        if(type == 5){
          return true
        }
        break;

      case 'id_table_relations':
        if(type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'param':
        if(type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'param_intern':
        if(type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'multiple':
      if(type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
        return true
      }
      break;

      case 'id_function':
        if(type == 5 || type == 137 ||
          type == 7 || type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'icon':
        if(type == 5 || type == 4
          || type == 105 || type == 7
          || type == 95 || type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'text':
        if(type == 101 || type == 118
          || type == 14 || type == 7
          || type == 3 || type == 114 || type == 141
          || type == 115 || type == 6 || type == 12 || type == 15){
          return true
        }
        break;

      case 'description':
        if(type == 6 || type == 12 || type == 15 || type == 113  || type == 141){
          return true
        }
        break;

      case 'url':
        if(type == 101 || type == 137
          || type == 138 || type == 102
          || type == 114 || type == 115  || type == 141){
          return true
        }
        break;

      case 'iframe_fullscreen':
      if(type == 138){
        return true
      }
      break;

      case 'carousel_num_scroll_s':
        if(type == 117){
          return true
        }
        break;

      case 'carousel_num_scroll_xs':
        if(type == 117){
          return true
        }
        break;

      case 'carousel_num_visible_s':
        if(type == 117){
          return true
        }
        break;

      case 'carousel_num_visible_xs':
        if(type == 117){
          return true
        }
        break;

      case 'show_total_graphs':
      if(type == 14){
        return true
      }
      break;

      case 'id_query':
        if(type == 14 || type == 3 
          || type == 85 || type == 98  || type == 12){
          return true
        }
        break;

      case 'carousel_num_visible':
        if(type == 117){
          return true
        }
        break;

      case 'carousel_num_scroll':
        if(type == 117){
          return true
        }
        break;

      case 'carousel_circular':
        if(type == 117){
          return true
        }
        break;

      case 'expansion_start_opened':
        if(type == 7){
          return true
        }
        break;

      case 'id_db':
        if(type == 3 || type == 85
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'bd_field':
        if(type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'bd_table':
        if(type == 85 || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121){
          return true
        }
        break;

      case 'close_dialog_out':
        if(type == 85){
          return true
        }
        break;

      case 'id_table':
        if(type == 3){
          return true
        }
        break;

      case 'search':
        if(type == 3){
          return true
        }
        break;

      case 'pagination':
        if(type == 3){
          return true
        }
        break;

      case 'canExportTables':
        if(type == 3){
          return true
        }
        break;

      case 'internal_name':
        if(type == 85 || type == 98){
          return true
        }
        break;

      default:
        return false
        break;
    }
  }

  formValueSubscribe(value, element){
    console.log('1', value)
    console.log('2', element)
    for(let key in value){
      for(let key2 in element){
        if(key === key2){
          element[key2] = value[key];
          Object.keys(this.defaultLanguageElement).forEach(item => {
           if (item == key2){
             for(let ele in element['functional_area_language']){
              if(element['functional_area_language'][ele]['id_language'] == this.currentLanguage){
                element['functional_area_language'][ele][key2] = value[key];
                element['functional_area_language'][ele]['mode_type'] = 'update';
                break;
              }
             }
           }
          })
          if(element['mode_type'] == undefined) {
            element['mode_type'] = 'update';
          }
        }
      }
    }
    element['validatedFrontend'] = false;
    this.genericService.asignFrontend([element], this.arrayElementsGenericCreator[0]['id_funtional_area']);
    this.genericService.findFormAttributes([element]);
    this.addHistoryRecord();
  }

  // formBuilderFloatingEle(){
  //   if(this.arrayFloatingElements?.length > 0){
  //     for(let item in this.arrayFloatingElements){
  //       this.arrayFloatingElements[item]['floatingElementForm'] = this.formBuilder.group({
  //         id_functional_type : [this.arrayFloatingElements[item].id_functional_type],
  //         internal_name: [this.arrayFloatingElements[item].internal_name],
  //         id_query: [this.arrayFloatingElements[item].id_query],
  //         id_db: [this.arrayFloatingElements[item].id_db],
  //         bd_table: [this.arrayFloatingElements[item].bd_table]
  //       })
  //       this.arrayFloatingElements[item]['floatingElementForm'].valueChanges.subscribe(value => {
  //         console.log('value', value);
  //         // this.formValueSubscribe(value, this.actualSelectedElement['child'][item]);
  //       })
  //     }
  //     console.log('this.arrayFloatingElements', this.arrayFloatingElements);
  //   }
  // }

  addHistoryRecord(){
    if (this.currentRecordIndex !== this.arrayElementsGenericCreatorRecord.length - 1) {
      this.arrayElementsGenericCreatorRecord.length = this.currentRecordIndex;
    }
    this.currentRecordIndex = this.arrayElementsGenericCreatorRecord.push(_.cloneDeep(this.arrayElementsGenericCreator)) - 1;
    console.log('History Array', this.arrayElementsGenericCreatorRecord);
    console.log('this.currentRecordIndex', this.currentRecordIndex);
  }

  filterTypes(id_functional_type){
    this.functionalSubtypes =  _.cloneDeep(this.functional_subtype)
    this.functionalSubtypes = this.functional_subtype.filter(item => {
      return item.id_functional_type === id_functional_type
    })
  }

  resetOptions() {
    this.activeTabIndex = 1;
    this.currentElement = 0;
    this.actualSelectedElement = undefined;
    this.actualSelectedSubElement = undefined;
    this.isAddingElement = false;
  }

  addElementIdsTMPLoop(array) {
    for (let i in array) {
      array[i]['idTmp'] = this.tmpId;
      array[i]['creatingMode'] = true;
      ++this.tmpId;
      if(array[i]['child'] && array[i]['child'].length > 0) this.addElementIdsTMPLoop(array[i]['child']);
    }
  }

  pushNewElement(object, idParent, elements) {
    for(let item of elements){
      if(item['idTmp'] === idParent) {
        this.pushNewElementInParentChilds(item, object);
        break;
      } else if(item['child'] !== undefined) {
        this.pushNewElement(object, idParent, item['child']);
      }
    }
  }

  pushNewElementInParentChilds(item, object) {
    object['order_general'] = item.child ? item.child.length+1 : 0;
    if(item['child'] == undefined) item['child'] = [];
    item['child'].push(object)
    item['validatedFrontend'] = false;
    this.genericService.asignFrontend(item['child'], this.arrayElementsGenericCreator[0]['id_funtional_area']);
    this.genericService.findFormAttributes(item['child']);
    this.addHistoryRecord();
  }

  deleteElement(element: any){
    element.id_functional_status_general = 2;
    element.hide = 1
    element.hide_xs = 1
    element.hide_s = 1
    element.hide_m = 1
    element.hide_l = 1
    element.hide_xl = 1
    element.mode_type = 'delete'

    this.addHistoryRecord();
  }

  copyElement(element: any){
    this.copiedElement = _.cloneDeep(element);
    this.functionsService.openSnackBar("Element is copied.", 7000, ['lightgreen-snackbar']);
  }

  pasteElement(idParent: any){
   this.pushNewElement(_.cloneDeep(this.copiedElement), idParent, this.arrayElementsGenericCreator);
   this.addElementIdsTMPLoop(this.arrayElementsGenericCreator);
  }

  updateStructure(){
    let json = {
      elementsTemplate: this.arrayElementsGenericCreator
    }
    this.endpointService.updateGenericCreatorStructure(json).subscribe(res => {
      console.log('update api', res);
    });
  }

  moveElements(array: any, id_functional_area: any, element: any, direction: any){
    for (let i in array) {
      if(array[i]?.['id_functional_area'] == id_functional_area){
        for(let j in array[i]?.['child']){
          if(element['idTmp'] ==  array[i]?.['child'][j]['idTmp']){
            if(direction == 'up'){
              if(array[i]?.['child'][+j -1]){
                let order_general = array[i]?.['child'][+j -1]['order_general'];
                array[i]['child'][+j -1]['order_general'] = array[i]['child'][j]['order_general']
                array[i]['child'][j]['order_general'] = order_general

                let item = array[i]?.['child'][+j -1]
                array[i]['child'][+j -1] = array[i]['child'][j]
                array[i]['child'][j] = item
              }
            } else {
              if(array[i]?.['child'][+j +1]){
                let order_general = array[i]?.['child'][+j +1]['order_general'];
                array[i]['child'][+j +1]['order_general'] = array[i]['child'][j]['order_general']
                array[i]['child'][j]['order_general'] = order_general

                let item = array[i]?.['child'][+j +1]
                array[i]['child'][+j +1] = array[i]['child'][j]
                array[i]['child'][j] = item
                break;
              }
            }
          }
        }
        break;
      } else{
        if(array[i]?.['child'] && array[i]['child'].length > 0) this.moveElements(array[i]['child'], id_functional_area, element, direction)
      }
    }
  }

  prepareNewEleToAdd(newElement){
    newElement.idTmp = this.tmpId;
    newElement.id_functional_status_mkt = 1;
    newElement.creatingMode = true;
    newElement['class_custom'] += ' creating-mode';
    newElement['mode_type'] = 'insert';
    newElement['validatedFrontend'] = false;
    ++this.tmpId;
  }

  selectLanguage(lang){
    this.actualSelectedElement = null;
    console.log('this.arrayElementsGenericCreator', this.arrayElementsGenericCreator);
    this.checkLanguageLoop(this.arrayElementsGenericCreator, lang)
  }

  checkLanguageLoop(array, language){
    for (let i in array) {
      let langObj = _.cloneDeep(this.defaultLanguageElement);
      langObj['id_language'] = language;
      langObj['id_functional_area'] = array[i]['id_functional_area'];
      let founded = false;
      if(array[i]['functional_area_language'] == undefined){
        array[i]['functional_area_language'] = []
      }
      for (let k in array[i]['functional_area_language']){
        if( array[i]['functional_area_language'][k].id_language == language ){
          langObj = array[i]['functional_area_language'][k];
          founded = true;
          break;
        }
      }
      if(!founded){
        array[i]['functional_area_language'].push(langObj)
      }
      Object.keys(langObj).forEach(item => {
        array[i][item] = langObj[item];
      })
      if(array[i]['child'] && array[i]['child'].length > 0) this.checkLanguageLoop(array[i]['child'], language );
      array[i]['validatedFrontend'] = false;
    }
    this.genericService.asignFrontend(array, this.arrayElementsGenericCreator[0]['id_funtional_area']);
    this.genericService.findFormAttributes(array);

  }

}
