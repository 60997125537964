import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { EndpointService } from "../../../services/endpoint/endpoint.service";
import { AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: 'app-dependencies',
  templateUrl: './dependencies.component.html',
  styleUrls: ['./dependencies.component.scss']
})
export class DependenciesComponent implements OnInit {

  @Output() event = new EventEmitter();
  @Input('modulo') moduloInfo: any;
  public dependenciasModulo: any;
  public dependenciasLoaded: boolean;
  public dependenciasAdded: boolean;

  constructor(
    public sanitizer: DomSanitizer,
    private endpointService: EndpointService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(): void {
    this.dependenciasLoaded = false;
    this.dependenciasAdded = false;
    // GET dependencias del módulo seleccionado
    if (this.moduloInfo.isDependent === 1) {
      this.endpointService.getModulosDependientes(this.authService.inmoId, this.moduloInfo.id).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.dependenciasModulo = data['response'];
          this.dependenciasLoaded = true;
        }
      });
    }
  }

  public addModulosdependientes(): void {
    this.dependenciasAdded = true;
    this.event.emit(this.dependenciasModulo);
  }
}
