import {AfterContentChecked, Component, Input, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import { FunctionsService } from 'app/services/functions/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import { Subscription } from "rxjs";
// Joan: Error després de la migració de 9 a 14 -> Ho comento perquè em sembla que no es fa servir
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TwoDecimalDirective } from 'app/validators/two-decimal.directive';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit, AfterContentChecked, OnDestroy {

  @Input() index : number;
  @Input() structure = null;
  genericForm : UntypedFormGroup;

  public placeHolder : string;
  public bdField : string;
  public hide = true;
  public value : string;
  //public idQuery : number;
  public date : any;
  public multiple : boolean;
  public selectable : boolean;
  public removable : boolean;
  public addOnBlur : boolean;
  public separatorKeysCodes: number[];
  public chipsList : any[] = [];
  public maxLength = 0;
  public maxLengthDecimal = "X,YY";
  public maxlengthNum = 0;
  private formSubscription: Subscription;
  private structureSubscription : Subscription;
  private formFieldsSubscription: Subscription;
  private vcfcsbcr: Subscription;
  public focused = false;
  public valuePw;
  public initialValue = null;

  public class : string;

  public notOwner = false;
  public firstTime = true;
  constructor(private changeDetector: ChangeDetectorRef,
    private functionsService: FunctionsService,
    public genericService: GenericService,
    public datepipe: DatePipe
    ) { }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }
  
  ngOnInit(): void {
    //this.setFunctionalValue();
    this.genericForm = this.structure[this.index]['form'];
    this.bdField = this.structure[this.index]['bd_field'];
    this.date = this.genericForm.value[this.index['bd_field']];
    this.multiple = this.structure[this.index]['multiple'] == 1;
    this.maxLength = this.structure[this.index]['max_length'] !== null ? this.structure[this.index]['max_length'] : 524288
    if(this.maxLength == null || this.maxLength == 0) this.maxLength = 524288;
    if(this.structure[this.index]['type'] == "decimal") {
      this.maxLengthDecimal = new Array(this.maxLength + 1).join("X");
      this.maxLengthDecimal += ",yy"
      this.maxLength += 3;
    }
    this.maxlengthNum = 10**this.maxLength;
    this.selectable = true;
    this.removable = true;
    this.addOnBlur = true;
    this.separatorKeysCodes = [ENTER, COMMA];
    this.structure[this.index].starsOnHoverValue = -1;

    if(this.structure[this.index]['text'] !== null) {
      if(!this.structure[this.index]['tmp_value'] && this.structure[this.index]['tmp_value'] !== 0) {
        this.structure[this.index]['tmp_value'] = this.structure[this.index]['text']
      }
    }
    if((!this.structure[this.index]['tmp_value'] && this.structure[this.index]['tmp_value'] !== 0)
    || this.structure[this.index]['tmp_value'] === null || this.structure[this.index]['tmp_value'] === undefined) {
      this.structure[this.index]['tmp_value'] = '';
    }
    this.value = this.structure[this.index]['tmp_value'];
    this.initialValue = this.value;
    if(this.value == '???' && this.structure[this.index]['type'] != 'text'){
      this.value = '000000000';
      this.notOwner = true;
    }
    if(this.structure[this.index]['type'] == 'datepicker' || this.structure[this.index]['type'] == 'calendarpicker') {
      this.value = this.functionsService.formatDate(this.value, false);
    }
    if(this.structure[this.index]['type'] == 'password') {
      if(this.value !== null && this.value != '') {
        this.valuePw = JSON.parse(JSON.stringify(this.value));
        this.structure[this.index]['form_field'] = 0;
        this.structure[this.index].hasTmpValuePw = true;
      }
    }
    if(this.structure[this.index]['type'] == '5-stars') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false, false, false]
    }
    if(this.structure[this.index]['type'] == '10-stars') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false, false, false, false, false, false, false, false]
    }
    if(this.structure[this.index]['type'] == '3-faces') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false]
      this.structure[this.index].iconFacesArray = this.genericService.iconFacesArray3;
    }
    if(this.structure[this.index]['type'] == '5-faces') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false, false, false];
      this.structure[this.index].iconFacesArray = this.genericService.iconFacesArray5;
    }
    for(let k in this.structure[this.index].starsArray) {
      if(+k <= this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[k] = true;
      else this.structure[this.index].starsArray[k] = false;
    }
    if(this.multiple) {
      if (!Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = [];
      this.structure[this.index].originalValue = JSON.parse(JSON.stringify(this.structure[this.index]['tmp_value']))
    }

    if (this.value !== undefined && this.value !== "" && this.value !== null) {
      this.genericForm.patchValue({
        [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value
      });
    }
    let parent = this.structure[this.index]['id_functional_parent_initial'];
    this.formSubscription = this.genericForm.valueChanges.subscribe(newToogleValue=> {
      this.firstTime = false;
      this.genericForm.value[this.index['bd_field']] = this.date;
      let vnew = newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField];
      let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
      if(id_parent == undefined || id_parent == null) id_parent = this.structure[this.index]['id_functional_parent'];
      if(this.structure[this.index]['type'] === "datepicker") vnew = this.functionsService.formatDate(vnew, false);
      let vold = this.initialValue;
      let type = "";
      if(this.structure[this.index]['type'] == 'password') type = 'password_';
      else if(this.structure[this.index]['type'] == '5-stars') type = '5-stars_';
      else if(this.structure[this.index]['type'] == '10-stars') type = '10-stars_';
      else if(this.structure[this.index]['type'] == '3-faces') type = '3-faces_';
      else if(this.structure[this.index]['type'] == '5-faces') type = '5-faces_';
      else type = 'input_';
      this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], type, vold, vnew, vold, vnew, this.structure[this.index]['label']);
      // this.genericService.updateStatus(data_validity_value, this.structure[this.index])
      if(!this.multiple) this.structure[this.index]['tmp_value'] = newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField];
      if(this.initialValue != newToogleValue[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]){
        if(!this.genericService.formsChanged[parent]){
          this.genericService.formsChanged[parent] = {};
        }
      } else {
        if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
        if(this.genericService.formsChanged[parent] && !this.firstTime){
          delete this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']];
          // EJECUTAMOS FUNCIÓN
          let lastValueOnExecuteFunction = undefined;
          if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
          if(this.structure[this.index]["id_function"] > 0 && (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction !== vnew)) this.functionsService.getFunctionFormField(this.structure[this.index]);
        }
      }
    });

    this.structureSubscription = this.genericService.structureUpdated.subscribe(
      (strc) => {
        for(let key in strc){
          const elem = this.genericService.findElementWithIdLoop(strc[key], this.structure[this.index]['id_functional_area']);
          if(elem){
            this.value = elem['child']['tmp_value'];
            if (this.value) {
              this.genericForm.patchValue({
                [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value
              });
            }
          }
        }
      }
    );

    this.vcfcsbcr = this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']].valueChanges.subscribe((newValue) => {
      this.updateDataValidity(newValue);
    })

    this.formFieldsSubscription = this.genericService.updateFormFields.subscribe((change) => {
      if(change) this.value = this.structure[this.index]['tmp_value'];
    })
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe()
    this.structureSubscription.unsubscribe()
    this.formFieldsSubscription.unsubscribe()
    this.vcfcsbcr.unsubscribe()
  }

  setFunctionalValue() : void {
    let element;
    (async() => {
      while(typeof this.structure[this.index]['functional_value'] === 'undefined') {
        await new Promise(resolve => setTimeout(resolve, 200));
      }
      element = document.getElementById(this.bdField);
      element.value = this.structure[this.index]['functional_value'];
      this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + this.bdField] = this.structure[this.index]['functional_value'];
    })();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.chipsList.push({name: value.trim()});
      this.structure[this.index]['tmp_value'].push(value.trim())
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(chip: any): void {
    const index = this.chipsList.indexOf(chip);
    if (index >= 0) {
      this.chipsList.splice(index, 1);
    }
  }

  clear(field){
    this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + field] = '';
    this.value = '';
    if(!this.multiple)this.structure[this.index]['tmp_value'] = '';
    else this.structure[this.index]['tmp_value'] = [];
    let element = <HTMLInputElement>document.getElementById(this.structure[this.index]['id_functional_area'] + '-' + field);
    element.value = '';
    this.structure[this.index]['form']['controls'][this.structure[this.index]['id_functional_area'] + '-' + field].setValue('');
    this.structure[this.index].starsOnHoverValue = -1;
    let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
    let vnew = '';
    let vold = this.initialValue;
    let parent = this.structure[this.index]['id_functional_parent_initial'];
    if(id_parent == undefined || id_parent == null) id_parent = this.structure[this.index]['id_functional_parent'];
    if(this.structure[this.index]['type'] == 'password') this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], 'password_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '5-stars') this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '5-stars_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '10-stars') this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '10-stars_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '3-faces') this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '3-faces_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '5-faces') this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '5-faces_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], 'input_', vold, vnew, vold, vnew, this.structure[this.index]['label']);

    for(let k in this.structure[this.index].starsArray) {
      if(+k <= this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[k] = true;
      else this.structure[this.index].starsArray[k] = false;
    }
  }

  onClick(fa){
    this.functionsService.getFunction(fa);
  }

  removePw() {
    this.structure[this.index].hasTmpValuePw = false;
    this.structure[this.index]['form_field'] = 1;
    this.value = '';
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: ''
    });
    let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
    if(id_parent === undefined || id_parent === null) id_parent = this.structure[this.index]['id_functional_parent'];
    const parent = this.structure[this.index]['id_functional_parent_initial'];
    this.functionsService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], 'password_', 'OLD', '-', "OLD", '-', this.structure[this.index]['label']);
  }

  mouseOverStars(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(star <= i) this.structure[this.index].starsArray[star]= true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  mouseOutStars(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(+star <= this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[star] = true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  clickIcon(i) {;
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    this.structure[this.index].starsOnHoverValue = i;
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: i+1
    });
  }

  mouseOverFaces(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(star == i) this.structure[this.index].starsArray[star] = true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  mouseOutFaces(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(+star == this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[star] = true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  updateFormDateCalendar(value: any) {
    this.genericForm.get(this.structure[this.index]['id_functional_area'] + '-' + this.bdField).setValue(value);
  }

  filterDates = (date: Date): boolean => {
    if(this.structure[this.index]['id_functional_status_general'] !== 3) {
      if(this.structure[this.index]['id_query'] > 0) {
        let data = this.genericService.selectOptions[this.structure[this.index]['id_query']];
        if (data !== undefined) {
          for(let i in data) {
            if(data[i]['value']) {
              let da = data[i]['value'].split(' ')[0];
              da = da.split(',')[0];
              let d = this.datepipe.transform(date, 'dd-MM-y');
              if(da.split('-')[0].length > 3) d = this.datepipe.transform(date, 'y-MM-dd');
              if(da == d) return true;
            } else return false;
          }
          return false;
        } else return true;
      } else return true;
    } else return false;
  }

  private updateDataValidity(v, init = false) {
    console.log("tmp:", this.structure[this.index]['tmp_value']);
    console.log("vv:", v);
    let data_validity_value = v;
    if (v === undefined || v === null || v === '') data_validity_value = -1;
    this.genericService.updateStatus(data_validity_value, this.structure[this.index])
  }
}