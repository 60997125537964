<div>
  <mat-checkbox style="border: 0; background: inherit;" [(ngModel)]="active">Activo</mat-checkbox>
</div>

<div>
  <a *ngIf="campanaArray.length == 0">No hay campañas existentes</a>
  <mat-form-field *ngIf="campanaArray.length > 0">
    <mat-label>Vincular campaña</mat-label>
    <input matInput type="text" [formControl]="campanaGroup.get('campana')" [matAutocomplete]="autoSoft" autocomplete="movinAutoSoft">
    <mat-autocomplete #autoSoft="matAutocomplete" [displayWith]="displayFnCamp.bind(this)">
      <mat-option class="inmo-list" *ngFor="let option of filteredOptionsCamp | async" [value]="option">
        {{option.nombre}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <a *ngIf="campanaArray.length > 0 && campana == null">No se ha vinculado ninguna campaña</a>
  <a *ngIf="campanaArray.length > 0 && campana != null">Campaña vinculada: {{getNameCampana(campana)}} <i class="material-icons" click-stop-propagation (click)="campana = null">close</i></a>
</div>

<div>
  <mat-form-field>
    <input matInput type="text" placeholder="Nombre" [(ngModel)]="name" maxlength="100" required>
  </mat-form-field>
</div>

<mat-form-field>
  <mat-label>Agregar plantilla</mat-label>
  <input matInput type="text" [formControl]="plantillaGroup.get('plantilla')" [matAutocomplete]="autoSoft" autocomplete="movinAutoSoft">
  <mat-autocomplete #autoSoft="matAutocomplete" [displayWith]="displayFnPlant.bind(this)">
    <mat-option class="inmo-list" *ngFor="let option of filteredOptionsPlant | async" [value]="option">
      {{option.nombre}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

  <div class="row-expansion1 taulesgrans">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" style="border: 1px solid #e7e7e7; margin-top: 1.8em;">
      <ng-container matColumnDef="lopd">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element; let index = index" class="buttons-body">
          <button class="btn-danger" style="border: 0; background: inherit;" matTooltip="Eliminar Email" (click)="eliminarPlantilla(index)">
            <i class="material-icons">delete_forever</i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let element">{{element.nombre}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Estado</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox style="border: 0; background: inherit;" [(ngModel)]="element.estado" [checked]="element.estado"></mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    </table>
  </div>

<div class="dialog-footer-2">
  <button (click)="guardar()" [disabled]="!onChanges()">{{"Guardar"}}</button>
  <button (click)="cancelar()">{{"Cancelar"}}</button>
</div>
