<ng-container *ngIf="loaded && this.structure[this.index]['id_query'] > 0">

  <ng-container *ngIf="structure[this.index]['type'] === 'selection'">
    <mat-form-field appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm">
      <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
      <mat-select [required]="this.structure[this.index]['id_functional_status_general'] === 5"
        formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        [disabled]="this.structure[this.index]['id_functional_status_general'] === 3" [multiple]="multiple">

        <!--- emtpy option  -->
        <ng-container *ngIf="!multiple && this.structure[this.index]['form_field_empty'] == 1 && this.structure[this.index]['id_functional_status_general'] !== 5">
          <ng-container *ngFor="let option of this.genericService.selectOptions[this.idQuery]">
            <mat-option *ngIf="!option.value && option.value !== 0"></mat-option>
            <mat-option *ngIf="option.value || option.value === 0" [value]="option.value">
              {{ option.text }}
            </mat-option>
          </ng-container>
        </ng-container>

        <!--- no empty option -->
        <ng-container *ngIf="multiple || this.structure[this.index]['form_field_empty'] != 1 || this.structure[this.index]['id_functional_status_general'] === 5">
          <mat-option *ngFor="let option of filterFunction(this.genericService.selectOptions[this.idQuery])" [value]="option.value">
            {{ option.text }}
          </mat-option>
        </ng-container>

      </mat-select>
      <span *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero" matPrefix
          [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
          [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
          [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
          [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
          [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
          [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

          [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
          [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
          [id]="structure[index]['child'][e.key]['id_functional_area']"
          [matTooltip]="structure[index]['child'][e.key]['tooltip']">
        <ng-container *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 103 && genericService.evaluateIfs(structure[index]['child'][e.key]['id_condition'], structure[index]['child'][e.key]['id_functional_area'], structure[index]['child'][e.key]) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2">
          <mat-icon *ngIf="structure[index]['child'][e.key]['icon'] && structure[index]['child'][e.key]['icon'] != ''"
            class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
            (click)="onClick(structure[index]['child'][e.key], $event); $event.stopPropagation()">{{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
            {{ structure[index]['child'][e.key]['label'] }}
        </ng-container>
      </span>
      <span *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero" matSuffix
          [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
          [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
          [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
          [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
          [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
          [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

          [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
          [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
          [id]="structure[index]['child'][e.key]['id_functional_area']"
          [matTooltip]="structure[index]['child'][e.key]['tooltip']">
        <ng-container *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 104 && genericService.evaluateIfs(structure[index]['child'][e.key]['id_condition'], structure[index]['child'][e.key]['id_functional_area'], structure[index]['child'][e.key]) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2">
          {{ structure[index]['child'][e.key]['label'] }}
          <mat-icon *ngIf="structure[index]['child'][e.key]['icon'] && structure[index]['child'][e.key]['icon'] != ''"
            class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
            (click)="onClick(structure[index]['child'][e.key], $event); $event.stopPropagation()">{{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
        </ng-container>
      </span>
      <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'autocomplete'">
    <mat-form-field appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
      [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3, 'own-required-invalid-autocomplete-field':this.structure[this.index].invalid && this.structure[this.index]['id_functional_status_general'] === 5 && chipsList && chipsList.length < 1}">
      <mat-label *ngIf="multiple" [ngClass]="{'own-required-invalid':this.structure[this.index].invalid && this.structure[this.index]['id_functional_status_general'] === 5 && chipsList && chipsList.length < 1 }"><span [innerHTML]="structure[this.index]['label'] | safeHtml"></span> <span syle="color: red !important;" *ngIf="this.structure[this.index]['id_functional_status_general'] === 5"> *</span></mat-label>
      <mat-label *ngIf="!multiple" [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
      <ng-container *ngIf="multiple; else elseBlock">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable" [removable]="removable"
          (removed)="remove(chip)">
          {{chip}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input type="text" matInput id="{{ bdField }}" [formControl]="myControl"
        placeholder="{{ this.structure[this.index]['description'] }}"
        formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        [required]="this.structure[this.index]['id_functional_status_general'] === 5"
        [attr.disabled]="this.structure[this.index]['id_functional_status_general'] === 3 ? true :null"
        [readonly]="this.structure[this.index]['id_functional_status_general'] === 3" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)" (focusout)="saveFirstOption($event, auto)">
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
        <input type="text" matInput id="{{ bdField }}" [formControl]="myControl"
          placeholder="{{ this.structure[this.index]['description'] }}"
          formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          [required]="this.structure[this.index]['id_functional_status_general'] === 5"
          [attr.disabled]="this.structure[this.index]['id_functional_status_general'] === 3 ? true :null"
          [readonly]="this.structure[this.index]['id_functional_status_general'] === 3" [matAutocomplete]="auto"
          (focusout)="saveFirstOption($event, auto)">
      </ng-template>
      <mat-autocomplete #auto="matAutocomplete" id="autocomplete" (optionSelected)="selected($event)" autoActiveFirstOption="true">
        <ng-container *ngIf="(filteredOptions | async)?.length > 0; else noOptions">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
            [disabled]="this.structure[this.index]['id_functional_status_general'] === 3">
            {{option}}
          </mat-option>
        </ng-container>
        <ng-template #noOptions >
          <mat-option [disabled]="true">
            {{ 'form.error.notFound' | translate}}
          </mat-option>
        </ng-template>
      </mat-autocomplete>
      <span
        *ngIf="myControl.value !== '' && this.structure[this.index]['tmp_value'] != '' && this.structure[this.index]['tmp_value'] !== undefined && this.structure[this.index]['tmp_value'] !== null && this.structure[this.index]['tmp_value'] != -999 && this.structure[this.index]['form_field_empty'] == 1 && this.structure[this.index]['id_functional_status_general'] != 3"
        matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField); $event.stopPropagation()">
        <mat-icon class="{{structure[this.index]['class'] == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
      </span>
      <span *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero" matPrefix
          [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
          [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
          [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
          [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
          [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
          [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

          [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
          [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
          [id]="structure[index]['child'][e.key]['id_functional_area']"
          [matTooltip]="structure[index]['child'][e.key]['tooltip']">
        <ng-container *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 103 && genericService.evaluateIfs(structure[index]['child'][e.key]['id_condition'], structure[index]['child'][e.key]['id_functional_area'], structure[index]['child'][e.key]) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2">
          <mat-icon *ngIf="structure[index]['child'][e.key]['icon'] && structure[index]['child'][e.key]['icon'] != ''"
            class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
            (click)="onClick(structure[index]['child'][e.key], $event); $event.stopPropagation()">{{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
            {{ structure[index]['child'][e.key]['label'] }}
        </ng-container>
      </span>
      <span *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero" matSuffix
          [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
          [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
          [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
          [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
          [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
          [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

          [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
          [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
          [id]="structure[index]['child'][e.key]['id_functional_area']"
          [matTooltip]="structure[index]['child'][e.key]['tooltip']">
        <ng-container *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 104 && genericService.evaluateIfs(structure[index]['child'][e.key]['id_condition'], structure[index]['child'][e.key]['id_functional_area'], structure[index]['child'][e.key]) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2">
          {{ structure[index]['child'][e.key]['label'] }}
          <mat-icon *ngIf="structure[index]['child'][e.key]['icon'] && structure[index]['child'][e.key]['icon'] != ''"
            class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
            (click)="onClick(structure[index]['child'][e.key], $event); $event.stopPropagation()">{{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
        </ng-container>
      </span>
      <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'radio' && checkIfHasResults(this.genericService.selectOptions[idQuery])">
    <span fxFlex="100" fxLayout="row wrap" class="fix-class-radio">
      <label *ngIf="structure[this.index]['label']" id="label"
        [ngClass]="{'own-required':true, 'own-required-invalid':this.structure[this.index].invalid && this.structure[this.index]['id_functional_status_general'] === 5 }"
        fxFlex="100" style="font-weight: 500; margin-bottom: 15px !important;" [innerHTML]="structure[this.index]['label'] | safeHtml"><span *ngIf="this.structure[this.index]['id_functional_status_general'] === 5">*</span>
      </label>
      <mat-radio-group fxFlex="100" (change)="changeRadioValue($event)"
        [disabled]="this.structure[this.index]['id_functional_status_general'] === 3" aria-labelledby="label"
        [required]="this.structure[this.index]['id_functional_status_general'] === 5">
        <mat-radio-button *ngFor="let option of this.genericService.selectOptions[idQuery]; let i = index" [ngClass]="['radio-orden-' + i]" [value]="option.value" [checked]="option.value === value">
          {{ option.text }}
        </mat-radio-button>
        <mat-radio-button *ngIf="this.structure[this.index]['tmp_value'] !== '' && this.structure[this.index]['tmp_value'] !== undefined && this.structure[this.index]['tmp_value'] !== null && this.structure[this.index]['tmp_value'] != -999 && this.structure[this.index]['form_field_empty'] == 1 && this.structure[this.index]['id_functional_status_general'] != 3 && this.structure[this.index]['id_functional_status_general'] != 5"
        class="no-defined-option" [value]=""><i class="material-icons">close</i></mat-radio-button>
        <span *ngIf="!structure[this.index]['label'] && this.structure[this.index]['id_functional_status_general'] === 5"
          style="color: red; height: 0;">*</span>
          <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
        </mat-radio-group>
    </span>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'toggle' && checkIfHasResults(this.genericService.selectOptions[idQuery])">
    <span fxLayout="row wrap" class="fix-class-toggle">
      <mat-button-toggle-group fxFlex="100" appearance="{{ structure[this.index]['class'] }}" formControlName="genericForm"
        aria-labelledby="labelT" [required]=" this.structure[this.index]['id_functional_status_general'] === 5"
        (change)="changeToggleValue($event)"
        [disabled]="this.structure[this.index]['id_functional_status_general'] === 3" [multiple]="multiple">
        <mat-button-toggle *ngFor="let option of this.genericService.selectOptions[idQuery]" [value]="option.text">
          {{ option.text }}
        </mat-button-toggle>
        <span *ngIf="!structure[this.index]['label'] && this.structure[this.index]['id_functional_status_general'] === 5"
          style="color: red; height: 0;">*</span>
          <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
        </mat-button-toggle-group>
      <label *ngIf="structure[this.index]['label']" fxFlex="100" id="labelT"
        [ngClass]="{'own-required':true, 'own-required-invalid':this.structure[this.index].invalid && this.structure[this.index]['id_functional_status_general'] === 5 }"
        [innerHTML]="structure[this.index]['label'] | safeHtml"><span *ngIf="this.structure[this.index]['id_functional_status_general'] === 5"> *</span> </label>
    </span>
  </ng-container>
</ng-container>
