import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import {CustomEvent, CustomEventAction} from '../activities/objects/custom-event.component';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  // you can override any of the methods defined in the parent class

  month(event: CustomEvent): string {
    let title = event.title;
    if (!event.allDay) {
      title = `<b>${new DatePipe(this.locale).transform(
        event.start,
        'HH:mm',
        this.locale
      )}</b> ` + title;
    }
    return title;
  }

  week(event: CustomEvent): string {
    let title = event.title;
    // if (!event.allDay) {
    //   title = `<b>${new DatePipe(this.locale).transform(
    //     event.start,
    //     'HH:mm',
    //     this.locale
    //   )}</b> ` + title;
    // }
    if (event.account && event.accountId !== -1) {
      title += `<br> ${event.account.nombre} ${event.account.tipoNombre}`;
    }
    return title;
  }

  day(event: CustomEvent): string {
    let title = event.title;
    // if (!event.allDay) {
    //   title = `<b>${new DatePipe(this.locale).transform(
    //     event.start,
    //     'HH:mm',
    //     this.locale
    //   )}</b> ` + title;
    // }
    if (event.account && event.accountId !== -1) {
      title += `<br> ${event.account.nombre} ${event.account.tipoNombre}`;
    }
    return title;
  }

  weekTooltip(event: CustomEvent) {
    
    let title = event.title;
    if (event.properties?.length > 0) {
      title += '<br> Inmuebles: ';
      event.properties.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.reference}`;
        }
        else {
          title += `${e.reference}, `;
        }
      });
    }
    if (event.clients?.length > 0) {
      title += '<br> Clientes: ';
      event.clients.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.fullName}`;
        }
        else {
          title += `${e.fullName}, `;
        }
      });
    }
    
    if (event.offer?.length > 0) {
      title += '<br> Oferta: ';
      event.offer.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.offer}`;
        }
        else {
          title += `${e.offer}, `;
        }
      });
    }
    if (event.encargos?.length > 0) {
      title += '<br> Encargos: ';
      event.encargos.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.reference}`;
        }
        else {
          title += `${e.reference}, `;
        }
      });
    }
    if (event.industriales?.length > 0) {
      title += '<br> Industriales: ';
      event.industriales.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.fullName}`;
        }
        else {
          title += `${e.fullName}, `;
        }
      });
    }
    if(event.account != undefined){
      title += '<br> Organizador: ';
      title += `${event.creatorName}`;
    }

    if (event.users?.length > 0) {
      title += '<br> Participantes: ';
      event.users.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.user}`;
        }
        else {
          title += `${e.user}, `;
        }
      });
    }
    if (event.account) {
      title += `<br> ${event.account.nombre} ${event.account.tipoNombre}`;
    }

    return title;

  }

  dayTooltip(event: CustomEvent): string {
    
    let title = event.title;
    if (event.properties?.length > 0) {
      title += '<br> Inmuebles: ';
      event.properties.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.reference}`;
        }
        else {
          title += `${e.reference}, `;
        }
      });
    }
    if (event.clients?.length > 0) {
      title += '<br> Clientes: ';
      event.clients.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.fullName}`;
        }
        else {
          title += `${e.fullName}, `;
        }
      });
    }
    if (event.offer?.length > 0) {
      title += '<br> Oferta: ';
      event.offer.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.offer}`;
        }
        else {
          title += `${e.offer}, `;
        }
      });
    }
    if (event.encargos?.length > 0) {
      title += '<br> Encargos: ';
      event.encargos.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.reference}`;
        }
        else {
          title += `${e.reference}, `;
        }
      });
    }
    if (event.industriales?.length > 0) {
      title += '<br> Industriales: ';
      event.industriales.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.fullName}`;
        }
        else {
          title += `${e.fullName}, `;
        }
      });
    }
    
    if(event.account != undefined){
      title += '<br> Organizador: ';
      title += `${event.idCreator}`;
    }

    if (event.users?.length > 0) {
      title += '<br> Participantes: ';
      event.users.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.user}`;
        }
        else {
          title += `${e.user}, `;
        }
      });
    }

    if (event.account && event.accountId !== -1) {
      title += `<br> ${event.account.nombre} ${event.account.tipoNombre}`;
    }

    return title;
  }
}
