import { Location } from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import { RoutingService } from 'app/services/routing/routing.service';

@Component({
  selector: 'app-container-defer',
  templateUrl: './container-defer.component.html',
  styleUrls: ['./container-defer.component.css']
})
export class ContainerDeferComponent implements OnInit {

  @Input() structure = null;
  @Input() index = null;

  constructor(private routingService: RoutingService, private location: Location) { }

  ngOnInit(): void {
    this.routingService.prepareRouting();
    if(this.routingService.urlHashtag != null){
      const id = this.routingService.urlHashtag;
      for(const key in this.structure[this.index]['child']){
        if(this.structure[key]['id_functional_area'] == id){
          this.location.replaceState(this.routingService.urlWithoutHashtag + '#' + id);
          break;
        }
       
      }
    }
  }
}
