import {Component, Input, OnInit} from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';
import {FunctionsService} from "../../../services/functions/functions.service";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  @Input() structure = null;
  @Input() index: number;

  public oldId = null;

  constructor(public functionsService: FunctionsService,
              public genericService: GenericService,
              public authService: AuthService) { }

  ngOnInit(): void {
    if(!this.genericService.staticHTML) {
      let paramControl = JSON.parse(localStorage.getItem('paramControl'));
      paramControl = paramControl[this.authService.getIdCompany(true)]
      for(let param of paramControl) {
        if(param['id_pantalla'] == this.structure[this.index]['id_functional_parent_initial']) {
          if(param['params']['lastTableIndex']) {
            this.oldId = param['params']['lastTableIndex']
            break;
          }
        }
      }
    }

    if(this.oldId !== null && this.oldId !== undefined && this.structure[this.index]['child'][this.oldId] && this.structure[this.index]['child'][this.oldId]['id_function'] == 12) {
      this.functionsService.getFunction(this.structure[this.index]['child'][this.oldId], this.structure);
    } else if ((this.oldId === null || this.oldId === undefined) && this.structure[0]['child'] !== undefined && this.structure[0]['child'][0]['id_function'] == 12) {
      this.structure[0]['child'][0]['id_functional_status_general'] = 1;
    }
  }

  scrollTo(id:string){
    let el = document.getElementById(id.slice(1));
    console.log('GOTO',id.slice(1), el);
    //el.scrollTop = el.scrollHeight;
    this.genericService.scroll(id.slice(1));
  }

  public onClickItem(item: any) {
    this.genericService.lastListClick[this.structure[this.index['id_functional_parent']]] = this.index;
    if(!this.genericService.staticHTML) {
      let paramControl = JSON.parse(localStorage.getItem('paramControl'));
      let paramControlUser = paramControl[this.authService.getIdCompany(true)]
      for(let param of paramControlUser) {
        if(param['id_pantalla'] == this.structure[this.index]['id_functional_parent_initial']) {
          param['params']['lastTableIndex'] = item['id_functional_area'];
        }
      }
      localStorage.setItem("paramControl", JSON.stringify(paramControl));
    }
    this.oldId = item['id_functional_area']
    console.log(item);
    this.functionsService.getFunction(item, this.structure);
  }

}
