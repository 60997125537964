import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import {CustomEvent} from "../../../activities/objects/custom-event.component";
import {MatDialog} from "@angular/material/dialog";
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import {DatePipe} from "@angular/common";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {DeviceDetectorService} from "ngx-device-detector";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {Values} from "../../../../values/values";
import {DomSanitizer} from "@angular/platform-browser";
import {AdviceReportService} from "../../../services/advice-report/advice-report.service";
import {BottomSheetFinishedEvents} from "../bottom-sheet-finished-events/bottom-sheet-finished-events";
import {EventType} from "../../../activities/objects/category";
import { SimpleClient } from '../../../activities/objects/simple-client';
import { SimpleProperty } from '../../../activities/objects/simple-property';
import {SimpleUser} from "../../../activities/objects/simple-user";
import {SimpleOffer} from "../../../activities/objects/simple-offer";
import { SimpleEncargo } from '../../../activities/objects/simple-encargo';
import {Strings} from "../../../../strings/strings";
import { PushNotificationsService } from 'app/services/pushNotifications.service';
import { FunctionsService } from '../../../services/functions/functions.service';
import { ActivitiesService } from '../../../services/activities/activities.service';

import {FlatpickrDefaultsInterface} from 'angularx-flatpickr/flatpickr-defaults.service';
import {Spanish} from 'flatpickr/dist/l10n/es.js'
import flatpickr from 'flatpickr';

flatpickr.localize(Spanish); // default locale is now Russian
flatpickr(".flatpickr", {
  disable: [
    function(dateObject){
        let enabledDates = ["2016/07/16", "2016/08/22"]; // Gabri comments -> que es esto? repasar
        for(let i = 0; i < enabledDates.length; i++)
            if(dateObject.getTime() === new Date(enabledDates[i]).getTime())
                return false;
        return true;
    }
  ]
});


@Pipe({ name: 'safeStyle' }) export class SafeStylePipe implements PipeTransform
  {
    constructor(private sanitizer: DomSanitizer) { } transform(value) { return this.sanitizer.bypassSecurityTrustStyle(value); } }

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() not_type;
  @Input() userLogged;
  @Output() ego_not_num = new EventEmitter();
  @Output() visita_not_num = new EventEmitter();
  @Output() gestor_actividades_num = new EventEmitter();
  public options = {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'} as const;
  nextEvents: CustomEvent[] = [];
  notifications: {
    id: number, id_comercial: number, titulo: string, subtitulo: string, fecha_inicio: string, fecha_fin: string,
    estado: number, id_usuario: number, id_inmo: number, id_lead: number, catastro: string, id_pais: number,
    id_tipo_comercializacion: number, tipo: number, viviendaNombre: string, desc_cancelacion: string, id_vivienda: number, nombre_usuario: string
  }[] = [];

  activitiesNotifications: {
    id: number, id_comercial: number, titulo: string, subtitulo: string, fecha_inicio: string, fecha_fin: string,
    estado: number, id_usuario: number, id_inmo: number, id_lead: number, catastro: string, id_pais: number,
    id_tipo_comercializacion: number, tipo: number, viviendaNombre: string, desc_cancelacion: string, id_vivienda: number, nombre_usuario: string,
    actividad: [], mensaje_recordatorio: string, estado_actividad: number, mostrarPosponer: boolean, mostrarCancelacion: boolean,
    newDateStart: Date; lastActivatedUser: boolean;
  }[] = [];

  egoNotifications: {
    id_not: number, id_usuario: number, usuario_secundario: number, fecha_alta_usuario_secundario: string,
    id_plantilla_ego: number, fecha: string, hora_inicio: string, hora_fin: string, num_veces: number, mensaje: string,
    hour_not_shown: any, plantilla: [], boton: []
  }[] = [];

  pendingEvents: CustomEvent[] = [];
  public vivienda: any;
  public routes = Values.ROUTES;
  private destroy$ = new Subject();
  public inmoId: number;
  public empresaId: number;
  public userId: number;
  public tempComment: string;
  public strings = Strings.CALENDAR;
  refresh: Subject<any> = new Subject();

  private pickNotiHome: number;

  constructor(public dialog: MatDialog,
              private endpointService: EndpointService,
              public authService: AuthService,
              private _bottomSheet: MatBottomSheet,
              public deviceService: DeviceDetectorService,
              // private _changeDetectorRef: ChangeDetectorRef,
              private router: Router,
              public datepipe: DatePipe,
              public adviceReportService: AdviceReportService,
              private pushNotificationsService: PushNotificationsService,
              public functionsService: FunctionsService,
              public activitiesService: ActivitiesService) {
  }

  ngOnInit() {

    this.pickNotiHome = null;
    this.adviceReportService.homeClose.subscribe(i => {
      this.homeClose(i);
    });
    this.adviceReportService.homeCloseRemoto.subscribe(i => {
      this.homeCloseRemoto(i);
    });
    this.adviceReportService.notiHome.subscribe(i => {
      if(this.not_type == 2) this.setEgoNotificationsButton(i.info, i.button);
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnChanges() {
    // create header using child_id
    if (this.userLogged) {
      switch (this.not_type) {
        case 1:
          this.setNotifications();
          // this.setPendingEvents();
          break;
        case 2:
          this.setEgoNotifications();
          break;
        case 3:
          this.setActivitiesNotifications();
          this.setPendingEvents();
          break;
      }
    } else if (this.userLogged) {
      this.egoNotifications = [];
      this.notifications = [];
      this.activitiesNotifications = [];
    }
  }

  setEgoNotifications() {
    this.egoNotifications = [];
    this.endpointService.getEgoHomeNotifications(this.authService.userId, this.authService.empresaId).subscribe(data => {
      Object.keys(data['response']).forEach(row => {
        const id_not = data['response'][row]['id'];
        const id_usuario = data['response'][row]['id_usuario'];
        const usuario_secundario = data['response'][row]['usuario_secundario'];
        const fecha_alta_usuario_secundario = data['response'][row]['fecha_alta_usuario_secundario'];
        const id_plantilla_ego = data['response'][row]['id_plantilla_ego'];
        const fecha = data['response'][row]['fecha'];
        const hora_inicio = (data['response'][row]['hora_inicio']);
        const hora_fin = (data['response'][row]['hora_fin']);
        const num_veces = (data['response'][row]['num_veces']);
        const mensaje = (data['response'][row]['mensaje']);
        const plantilla = (data['response'][row]['plantilla']);
        const boton = (data['response'][row]['boton']);
        let d = new Date();
        let hour_not_shown = this.datepipe.transform(d, 'y-MM-dd HH:mm:ss');
        /*const nombre_evento = data['response'][row]['nombre_evento'];
        const titulo = data['response'][row]['titulo'];
        const titulo_color = data['response'][row]['titulo_color'];
        const subtitulo = data['response'][row]['subtitulo'];
        const subtitulo_color = data['response'][row]['subtitulo_color'];
        const id_icon = data['response'][row]['id_icon'];
        const button_cerrar = data['response'][row]['button_cerrar'];
        const cerrar_color = data['response'][row]['cerrar_color'];
        const id_button1 = data['response'][row]['id_button1'];
        const id_button2 = data['response'][row]['id_button2'];
        const id_button3 = data['response'][row]['id_button3'];
        const fondo_color = data['response'][row]['fondo_color'];*/
        /*const btn1 = data['response'][row]['btn1'] ? data['response'][row]['btn1'] : null;
        const btn2 = data['response'][row]['btn2'] ? data['response'][row]['btn2'] : null;
        const btn3 = data['response'][row]['btn3'] ? data['response'][row]['btn3'] : null;*/

        if(this.egoNotifications.find(not => not.id_plantilla_ego === id_plantilla_ego) === undefined){
          this.egoNotifications.push({
            id_not,
            id_usuario,
            usuario_secundario,
            fecha_alta_usuario_secundario,
            id_plantilla_ego,
            fecha,
            hora_inicio,
            hora_fin,
            num_veces,
            mensaje,
            hour_not_shown,
            plantilla,
            boton
          });
        }
      });

      this.ego_not_num.emit(this.egoNotifications.length);
    });
  }

  public datePickerOptions : FlatpickrDefaultsInterface = {
    allowInput : true,
    enableTime : true,
    time24hr: true,
    mode : 'single',
    dateFormat : "Y-m-d H:i",
    // this:
    enable : [{from : new Date(0, 1), to : new Date(new Date().getFullYear() + 200, 12)}]
  }

  setEgoNotificationsButton(info, button) {

    if(this.not_type === 2){
      const id_not = null;
      const id_usuario = null;
      const usuario_secundario = null;
      const fecha_alta_usuario_secundario = null;
      const id_plantilla_ego = info.id;
      const fecha = null;
      const hora_inicio = null;
      const hora_fin = null;
      const num_veces = null;
      const mensaje = null;
      const plantilla = info;
      const boton = button;
      let d = new Date();
      let hour_not_shown = this.datepipe.transform(d, 'y-MM-dd HH:mm:ss');
      /*const nombre_evento = data['response'][row]['nombre_evento'];
      const titulo = data['response'][row]['titulo'];
      const titulo_color = data['response'][row]['titulo_color'];
      const subtitulo = data['response'][row]['subtitulo'];
      const subtitulo_color = data['response'][row]['subtitulo_color'];
      const id_icon = data['response'][row]['id_icon'];
      const button_cerrar = data['response'][row]['button_cerrar'];
      const cerrar_color = data['response'][row]['cerrar_color'];
      const id_button1 = data['response'][row]['id_button1'];
      const id_button2 = data['response'][row]['id_button2'];
      const id_button3 = data['response'][row]['id_button3'];
      const fondo_color = data['response'][row]['fondo_color'];*/
      /*const btn1 = data['response'][row]['btn1'] ? data['response'][row]['btn1'] : null;
      const btn2 = data['response'][row]['btn2'] ? data['response'][row]['btn2'] : null;
      const btn3 = data['response'][row]['btn3'] ? data['response'][row]['btn3'] : null;*/

      if(this.egoNotifications.find(not => not.id_plantilla_ego === id_plantilla_ego) === undefined) {
        this.egoNotifications.push({
          id_not,
          id_usuario,
          usuario_secundario,
          fecha_alta_usuario_secundario,
          id_plantilla_ego,
          fecha,
          hora_inicio,
          hora_fin,
          num_veces,
          mensaje,
          hour_not_shown,
          plantilla,
          boton
        });
      }
      this.ego_not_num.emit(this.egoNotifications.length);
    }

  }

  setNotifications(isNew = false) {
    this.notifications = [];
    this.endpointService.getCommercialNotifications(this.authService.userId).subscribe(data => {
      const notis = [];
      Object.keys(data['response']).forEach(row => {
        const id = data['response'][row]['id'];
        const id_comercial = data['response'][row]['id_comercial'];
        const titulo = data['response'][row]['titulo'];
        const subtitulo = data['response'][row]['subtitulo'];
        // const startDate = ((data['response'][row]['start_date']) == null) ? null : new Date((data['response'][row]['start_date']).replace(' ', 'T') + 'Z');

        // const fecha_inicio = ((data['response'][row]['fecha_inicio']) === null) ? null : new Date((data['response'][row]['fecha_inicio']).replace(' ', 'T') + 'Z').toLocaleString([], this.options);
        // const fecha_fin = ((data['response'][row]['fecha_fin']) === null) ? null : new Date((data['response'][row]['fecha_fin']).replace(' ', 'T') + 'Z').toLocaleString([], this.options);

        let fecha_inicio = null;
        let fecha_fin = null;

        if (data['response'][row]['fecha_fin'] !== null) {
          let fi = new Date(data['response'][row]['fecha_inicio'])
          fecha_inicio = this.activitiesService.changeDateFormat(fi, 1);
        }

        if ( data['response'][row]['fecha_inicio'] !== null ) {
          let ff = new Date(data['response'][row]['fecha_fin']);
          fecha_fin = this.activitiesService.changeDateFormat(ff, 1);
        }


        // const fecha_fin = data['response'][row]['fecha_fin'];
        const estado = data['response'][row]['estado'];
        const id_usuario = data['response'][row]['id_usuario'];
        const id_inmo = data['response'][row]['id_inmo'];
        const id_lead = data['response'][row]['id_lead'];
        const catastro = data['response'][row]['catastro'];
        const id_pais = data['response'][row]['id_pais'];
        const id_tipo_comercializacion = data['response'][row]['id_tipo_comercializacion'];
        const tipo = data['response'][row]['tipo'];
        const desc_cancelacion = data['response'][row]['desc_cancelacion'];
        const viviendaNombre = data['response'][row]['referencia_inmo'];
        const id_vivienda = data['response'][row]['id_vivienda'];
        const nombre_usuario = data['response'][row]['nombre_usuario'];
        const enviado_push = data['response'][row]['enviado_push'];

        this.notifications.push({
          id,
          id_comercial,
          titulo,
          subtitulo,
          fecha_inicio,
          fecha_fin,
          estado,
          id_usuario,
          id_inmo,
          id_lead,
          catastro,
          id_pais,
          id_tipo_comercializacion,
          tipo,
          viviendaNombre,
          desc_cancelacion,
          id_vivienda,
          nombre_usuario
        });

        const noti_body = ""
        const notification_push_payload = {
          title: titulo,
          body: nombre_usuario,
          userId: this.authService.userId,
          enviado_push: enviado_push,
          id: id
        };
        if(enviado_push == 0){
          notis.push(notification_push_payload);
        }

      });
      if(isNew){
        if(notis.length > 0){
          this.pushNotificationsService.notifyAllUsers(notis).subscribe(data => {
          });
        }
    }
      this.visita_not_num.emit(this.notifications.length);
    });
  }

  setActivitiesNotifications(isNew = false) {
    this.activitiesNotifications = [];
    const notis = [];
    this.endpointService.getActivitiesNotifications(this.authService.userId).subscribe(data => {
      Object.keys(data['response']).forEach(row => {
        const id = data['response'][row]['id'];
        const id_comercial = data['response'][row]['id_comercial'];
        const titulo = data['response'][row]['titulo'];
        const subtitulo = data['response'][row]['subtitulo'];
        // const startDate = ((data['response'][row]['start_date']) == null) ? null : new Date((data['response'][row]['start_date']).replace(' ', 'T') + 'Z');

        const fecha_inicio = ((data['response'][row]['fecha_inicio']) === null) ? null : new Date((data['response'][row]['fecha_inicio']).replace(' ', 'T') + 'Z').toLocaleString([], this.options);
        const fecha_fin = ((data['response'][row]['fecha_fin']) === null) ? null : new Date((data['response'][row]['fecha_fin']).replace(' ', 'T') + 'Z').toLocaleString([], this.options);
        // const fecha_fin = data['response'][row]['fecha_fin'];
        const estado = data['response'][row]['estado'];
        const id_usuario = data['response'][row]['id_usuario'];
        const id_inmo = data['response'][row]['id_inmo'];
        const id_lead = data['response'][row]['id_lead'];
        const catastro = data['response'][row]['catastro'];
        const id_pais = data['response'][row]['id_pais'];
        const id_tipo_comercializacion = data['response'][row]['id_tipo_comercializacion'];
        const tipo = data['response'][row]['tipo'];
        const desc_cancelacion = data['response'][row]['desc_cancelacion'];
        const viviendaNombre = data['response'][row]['referencia_inmo'];
        const id_vivienda = data['response'][row]['id_vivienda'];
        const nombre_usuario = data['response'][row]['nombre_usuario'];
        const actividad = data['response'][row]['actividad'];
        const mensaje_recordatorio = data['response'][row]['mensaje_recordatorio'];
        const estado_actividad = data['response'][row]['estado_actividad'];
        const enviado_push = data['response'][row]['enviado_push'];
        const mostrarPosponer = false;
        const mostrarCancelacion = false;
        const newDateStart = null;
        const lastActivatedUser = false;

        this.activitiesNotifications.push({
          id,
          id_comercial,
          titulo,
          subtitulo,
          fecha_inicio,
          fecha_fin,
          estado,
          id_usuario,
          id_inmo,
          id_lead,
          catastro,
          id_pais,
          id_tipo_comercializacion,
          tipo,
          viviendaNombre,
          desc_cancelacion,
          id_vivienda,
          nombre_usuario,
          actividad,
          mensaje_recordatorio,
          estado_actividad,
          mostrarPosponer,
          mostrarCancelacion,
          newDateStart,
          lastActivatedUser
        });

        const noti_body = "";
        const notification_push_payload = {
          title: titulo,
          body: nombre_usuario,
          userId: this.authService.userId,
          enviado_push: enviado_push,
          id: id
        };
        if(enviado_push == 0){
          notis.push(notification_push_payload);
        }
      });
      console.log(this.activitiesNotifications, "activitiesNotifications JAN");
      if(isNew){
        if(notis.length > 0){
          this.pushNotificationsService.notifyAllUsers(notis).subscribe(data => {
          });
        }
      }
      this.gestor_actividades_num.emit(this.activitiesNotifications.length);
    });
  }

  setPendingEvents() {
    let tempArray: CustomEvent[] = [];
    this.pendingEvents = [];

    //TODO IBRA: Ahora he puesto momentáneamente la promesa y el timeout, para poder obtener el campo inmoId, pero mas adelante
    // habrá que arreglarlo
    let p2 = new Promise<void>((resolve, reject) => {
      setTimeout(async () => {
        this.empresaId = this.authService.empresaId;
        this.userId = this.authService.userId;
        resolve();
      }, 300);
    });

    p2.then(res => {
      this.endpointService.getPendingActivities(this.empresaId, this.userId).subscribe(data => {
        Object.keys(data['response']).forEach(row => {
          const id = data['response'][row]['id'];
          const comments = data['response'][row]['comments'];
          const creatorId = data['response'][row]['creator_id'];
          const title = data['response'][row]['title'];
          const startDate = ((data['response'][row]['start_date']) == null) ? null : new Date((data['response'][row]['start_date']).replace(' ', 'T') + 'Z');
          const endDate = ((data['response'][row]['end_date']) == null) ? null : new Date((data['response'][row]['end_date']).replace(' ', 'T') + 'Z');
          const idCalendarioGoogle = data['response'][row]['id_calendario_google'];
          const typeId = data['response'][row]['type_id'];
          const typeName = data['response'][row]['type_name'];
          const typeColor = data['response'][row]['type_color'];
          const typeCategory = data['response'][row]['id_categoria'];
          const active = data['response'][row]['active'];
          const isPrivate = data['response'][row]['privada'];
          const firmar = data['response'][row]['firmar'];
          const control_calendario = data['response'][row]['control_calendario'];
          const signLike = data['response'][row]['firma_gusta'];
          const signNotify = data['response'][row]['firma_recibir_alertas'];
          const signDNI = data['response'][row]['firma_DNI'];
          const signImgURL = data['response'][row]['firma_img_url'];
          const signObservation = data['response'][row]['firma_observaciones'];
          const allDay = data['response'][row]['dia_completo'];
          const idSerie = data['response'][row]['id_serie'];
          const idAviso1 = data['response'][row]['id_aviso1'];
          const idAviso2 = data['response'][row]['id_aviso2'];
          const idAviso3 = data['response'][row]['id_aviso3'];
          const estado = data['response'][row]['estado'];
          const can_motivo = data['response'][row]['can_motivo'];
          const viviendas = data['response'][row]['viviendas'];
          let viviendasArray: SimpleProperty[] = [];
          if(viviendas != undefined && viviendas !== null){
            Object.keys(viviendas).forEach(property => {
              let simpleProperty = new SimpleProperty(viviendas[property]['id'], viviendas[property]['reference'], viviendas[property]['price'], viviendas[property]['internalName']);
              viviendasArray.push(simpleProperty);
            })
          }
          const clientes = data['response'][row]['clientes'];
          let clientesArray: SimpleClient[] = [];
          if(clientes != undefined && clientes !== null){
            Object.keys(clientes).forEach(client => {
              let simpleClient = new SimpleClient(clientes[client]['id'], clientes[client]['fullName']);
              clientesArray.push(simpleClient);
            });
          }                                           
          const usuarios = data['response'][row]['usuarios'];
          let usuariosArray: SimpleUser[] = [];
          if(usuarios != undefined && usuarios !== null){
            Object.keys(usuarios).forEach(user => {
              let simpleUser = new SimpleUser(usuarios[user]['id'], usuarios[user]['user']);
              usuariosArray.push(simpleUser);
            });
          }
          const industriales = data['response'][row]['industriales'];
          let industrialesArray: SimpleClient[] = [];
          if(industriales != undefined && industriales !== null){
            Object.keys(industriales).forEach(client => {
              let simpleClient = new SimpleClient(industriales[client]['id'], industriales[client]['fullName']);
              industrialesArray.push(simpleClient);
            });
          }
          const encargos = data['response'][row]['encargos'];
          let encargosArray: SimpleEncargo[] = [];
          if(encargos != undefined && encargos !== null){
            Object.keys(encargos).forEach(encargo => {
              let simpleEncargo = new SimpleEncargo(encargos[encargo]['id'], encargos[encargo]['reference']);
              encargosArray.push(simpleEncargo);
            });
          }
          const arquitectos = data['response'][row]['arquitectos'];
          const idOferta = data['response'][row]['id_oferta'];
          const nombreOferta = data['response'][row]['nombre_oferta'];
          const nombreCreador = data['response'][row]['nombre_creador'];
          const idCuenta = data['response'][row]['id_cuenta'];
          const cuentaTipo = data['response'][row]['cuenta_tipo'];
          // const foto = data['response'][row]['foto'] || "https://movin.cloud/assets/img/default/perfil.png";
          const foto = data['response'][row]['foto'];
          let fotoPerfil = "https://movin.cloud/assets/img/default/perfil.png";
          if (foto) {
            fotoPerfil = foto;
          }
          let property = null;
          let client = null;
          let user = null;
          let oferta = null;
          let type: EventType = new EventType();
          let action = {
            label: '<img title="' + nombreCreador + '" class="fotoEvento" src="' + fotoPerfil + '" alt="">',
            onClick: ({event}: { event: CustomEvent }): void => {
            }
          };

          if (typeId !== null && typeName !== null && typeColor !== null) {
            type = new EventType(typeId, typeName, typeColor, typeCategory, active, isPrivate, firmar, control_calendario);
          }

          const tempEvent = new CustomEvent(title, comments, id, creatorId, startDate, endDate, idCalendarioGoogle, type, signLike,
            signNotify, signDNI, signImgURL, signObservation, allDay, idSerie, idAviso1, idAviso2, idAviso3, estado, can_motivo,
            viviendasArray, clientesArray, usuariosArray, industrialesArray, encargosArray, arquitectos, idOferta, nombreCreador, undefined, undefined, idCuenta, cuentaTipo);
          this.authService.getAccount(this.authService.empresaId).then(res => {
            tempEvent.account = res;
          });
          if (idOferta && nombreOferta) {
            oferta = new SimpleOffer(idOferta, nombreOferta);
            tempEvent.setOffer(oferta);
          }

          if (creatorId !== this.authService.userId) {
            tempEvent.actions.push(action);
            tempEvent.draggable = false;
            tempEvent.resizable.afterEnd = false;
            tempEvent.resizable.beforeStart = false;
          }
          tempArray = [
            ...tempArray, tempEvent
          ];
          this.pendingEvents = tempArray;
        });
      });
    });
  }

  aceptarVisita(not: {
    id: number, id_comercial: number, titulo: string, subtitulo: string, fecha_inicio: string,
    fecha_fin: string, estado: number, id_usuario: number, id_inmo: number, id_lead: number, catastro: string, id_pais: number,
    id_tipo_comercializacion: number, tipo: number, viviendaNombre: string, desc_cancelacion: string, id_vivienda: number, nombre_usuario: string
  }) {

    // not.fecha_inicio = this.activitiesService.changeDateFormat(not.fecha_inicio, 2);
    // not.fecha_fin = this.activitiesService.changeDateFormat(not.fecha_fin, 2);

    this.endpointService.commercialAcceptedVisit(this.authService.userId, not).subscribe(data => {
    });
    this.notifications.filter(e => e.id === not.id)[0].estado = 2;
    // this._changeDetectorRef.detectChanges();
    this.visita_not_num.emit(this.notifications.length);
  }

  rechazarVisita(not: {
    id: number, id_comercial: number, titulo: string, subtitulo: string, fecha_inicio: string,
    fecha_fin: string, estado: number, id_usuario: number, id_inmo: number, id_lead: number, catastro: string, id_pais: number,
    id_tipo_comercializacion: number, tipo: number, viviendaNombre: string, desc_cancelacion: string, id_vivienda: number, nombre_usuario: string
  }) {
    this.endpointService.commercialRejectedVisit(this.authService.userId, not).subscribe(data => {
    });
    this.notifications.filter(e => e.id === not.id)[0].estado = 3;
    // this._changeDetectorRef.detectChanges();
    this.visita_not_num.emit(this.notifications.length);
  }

  deleteNotification(id: number) {
    this.notifications = this.notifications.filter(e => e.id !== id);
    this.visita_not_num.emit(this.notifications.length);
    this.endpointService.deleteNotification(id).subscribe(data => {
      // this._changeDetectorRef.detectChanges();
    });
  }

  deleteAgendaNotification(id: number) {
    this.endpointService.deleteNotification(id).subscribe(res => {
      this.activitiesNotifications = this.activitiesNotifications.filter(ac => (ac.id !== id));
      this.gestor_actividades_num.emit(this.activitiesNotifications.length);
      // this._changeDetectorRef.detectChanges();
    });
  }


  openPropertyInfo(id_vivienda: number) {
    /*
    // TODO Ibra: Cuando SeePropertyInfoComponent esté listo, abrir el dialog en lugar de redireccionar
    const dialogRef = this.dialog.open(SeePropertyInfoComponent, {
      width: '70vw',
      height: '65vh',
      panelClass: 'table-dialog',
      data: id_vivienda

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
    */
    localStorage.setItem("movin2", id_vivienda.toString());
    this.router.navigate([this.routes.PROPERTIES_GENERIC]);
  }

  openClientInteressesInfo(id_usuario: number) {
    localStorage.setItem("movin1", id_usuario.toString());
    this.router.navigate([this.routes.CLIENTS_GENERIC]);
  }

  setEgoNotificationStyle(btn1: any) {
    return 'color: ' + btn1.button_color_texto + ' !important';
  }

  homeClose(i: number) {
    this.egoNotifications.splice(i, 1);
    this.ego_not_num.emit(this.egoNotifications.length);
  }

  homeCloseRemoto(id: number) {
    let index = this.egoNotifications.findIndex( x => x.id_plantilla_ego == id);
    this.egoNotifications.splice(index, 1);
    this.ego_not_num.emit(this.egoNotifications.length);
  }


  BottomSheetOpenPendentEvents(): void {
    const bottomSheet = this._bottomSheet.open(BottomSheetFinishedEvents, {
      data: this.pendingEvents
    });

    bottomSheet.afterDismissed().subscribe(() => {
      this.setPendingEvents();
    });
  }

  setEventFinished(element: any) {
    this.hightlightNotification(element);
    this.endpointService.updateActivityState(element.actividad[0].id, 2).subscribe(d => {
      element.mostrarPosponer = false;
      element.mostrarCancelacion = false;
      this.deleteAgendaNotification(element.id);
    });
  }

  setEventCancelled(element: any) {
    element.mostrarPosponer = false;
    element.mostrarCancelacion = !element.mostrarCancelacion;
    this.hightlightNotification(element);
  }

  setMotivoCancelacion(element: any) {
    this.endpointService.cancelActivityState(element.actividad[0].id, 3, this.tempComment).subscribe(d1 => {
      this.deleteAgendaNotification(element.id);
    });
  }

  postponeEvent(element: any) {
    this.hightlightNotification(element);
    element.mostrarCancelacion = false;
    element.mostrarPosponer = !element.mostrarPosponer;
  }

  isInvalid() {
    return !(this.tempComment !== null && this.tempComment !== undefined && this.tempComment !== "");
  }

  startDateIsInvalid(startDate: Date) {
    return (startDate < new Date());
  }

  endDateIsInvalid(startDate: Date, endDate: Date) {
    return (endDate < startDate || startDate === null || startDate === undefined);
  }

  getStartDateErrorMessage(startDate: Date) {
    return this.startDateIsInvalid(startDate) ? this.strings.START_DATE_ERROR_MESSAGE : '';
  }

  getEndDateErrorMessage(startDate: Date, endDate: Date) {
    return this.endDateIsInvalid(startDate, endDate) ? this.strings.END_DATE_ERROR_MESSAGE : '';
  }


  postponeActivity(startDate: Date, element: any) {
    console.log("Start Date: " + startDate);
    let startDate_formated = this.datepipe.transform(startDate, 'yyyy-MM-dd HH:mm:ss');

    const body = {
      start_date: (startDate === null || startDate === undefined) ? null : startDate_formated,
      original_start_date: element.actividad[0].start_date,
      original_end_date: element.actividad[0].end_date
    };
    this.endpointService.postponeActivity(body, element.actividad[0].id).subscribe(d => {
      this.deleteAgendaNotification(element.id);
    });
  }
  
  openActivityInfo(param) {
    let x = {id_function: 783};
    this.functionsService.getFunction(x, param);
    this.hightlightNotification(param);
  }

  hightlightNotification(param) {
    for(let x in this.activitiesNotifications) {
      if(this.activitiesNotifications[x].id !== param.id && this.activitiesNotifications[x].lastActivatedUser) this.activitiesNotifications[x].lastActivatedUser = false;
    }
    param.lastActivatedUser = true;
  }

}
