<ng-container *ngIf="!genericService.staticHTML">
  <ng-container *ngIf="!authService.loadingInit || !genericService.finishedLoadStructureHeader">
    <mat-progress-bar style="top: 0 !important;" class="loading-window" mode="indeterminate"></mat-progress-bar>
  </ng-container>
  
  <ng-container *ngIf="!authService.finishToLogued">
    <!-- i'm in loggin process -->
  </ng-container>
  
  <ng-container *ngIf="authService.loadingInit && authService.finishToLogued">
    <mat-sidenav-container *ngIf="authService.isUserSessionActive()" [hasBackdrop]="true" [ngClass]="{'opened': genericService.shouldOpenHelp || genericService.shouldOpenChat || genericService.shouldOpenExternalWindow, 'side-nav-general': true}" style="height: 100%;">
      <mat-sidenav #sidenav mode="over" position="end" fxLayout="row wrap" fxFlex="100" [ngClass]="{'generic-tab-full-width-2': genericService.shouldOpenHelp, 'generic-tab-group-guias': genericService.shouldOpenHelp, 'sidenvaHome': genericService.shouldOpenHelp || genericService.shouldOpenChat || genericService.shouldOpenExternalWindow}" (closed)="this.closeSidenav(!this.closeSidenavButton)">
        <div fxFlex="100" class="yellow go-back-close-sidenav" (click)="this.closeSidenav(true); this.closeSidenavButton = true"><span class="material-icons" matTooltip="Cerrar y volver a la pantalla anterior">arrow_back</span> Cerrar y volver</div>
        <div *ngIf="genericService.shouldOpenHelp" style="width: 50vw;" ngClass.sm="mat-sidenav-bis-faqs" ngClass.xs="mat-sidenav-bis-faqs" fxFlex="100">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">live_help</mat-icon>
                <span fxHide.sm="true" fxHide.xs="true" style="padding-right: 0px;">PREGUNTAS FRECUENTES DE ESTA </span><span style="padding-left: 3px;">PANTALLA</span>
              </ng-template>
              <span fxFlex="100" fxLayout="row wrap" class="tabla-puntuaciones">
                <mat-accordion fxLayout="row wrap" fxFlex="100">
                  <mat-expansion-panel *ngFor="let c of this.adviceReportService.preguntasYrespuestas;" fxFlex="100">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span [innerHTML]="c.pregunta | safeHtml"></span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <span [innerHTML]="c.respuesta | safeHtml"></span>
                  </mat-expansion-panel>
                  <span *ngIf="this.adviceReportService.preguntasYrespuestas.length <= 0" fxFlex="100" class="no-asks">Parece que todaví­a no hay "Preguntas frecuentes" para esta pantalla</span>
                </mat-accordion>
              </span>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">help_center</mat-icon>
                <span fxHide.sm="true" fxHide.xs="true" style="padding-right: 0px;">PREGUNTAS FRECUENTES </span><span style="padding-left: 3px;">GENERALES</span>
              </ng-template>
              <span fxFlex="100" fxLayout="row wrap" class="tabla-puntuaciones">
                <mat-accordion fxLayout="row wrap" fxFlex="100">
                  <mat-expansion-panel *ngFor="let c of this.adviceReportService.preguntasYrespuestasGeneral;" fxFlex="100">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span [innerHTML]="c.pregunta | safeHtml"></span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <span [innerHTML]="c.respuesta | safeHtml"></span>
                  </mat-expansion-panel>
                  <span *ngIf="this.adviceReportService.preguntasYrespuestasGeneral.length <= 0" fxFlex="100" class="no-asks">Parece que todaví­a no hay "Preguntas frecuentes generales"</span>
                </mat-accordion>
              </span>
            </mat-tab>
            <!--<mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">support_agent</mat-icon>
                SOLICITAR CITA
              </ng-template>
              <div fxFlex="100" fxLayout="row wrap" class="tabla-puntuaciones" style="padding: 1vw 2vw; border-left: 1px solid #222223;">
                <iframe src="https://calendly.com/d/cfrc-vw8x/4dmovin?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=882334" style="width: 100%; height: 100%;"></iframe>
              </div>
            </mat-tab>-->
          </mat-tab-group>
        </div>
        <div *ngIf="genericService.shouldOpenChat" fxFlex="100" style="min-width: 100vw; width: 100vw;">
          <app-chat [name]="name"></app-chat>
        </div>
        <div *ngIf="genericService.shouldOpenExternalWindow" fxFlex="100" style="min-width: 100vw; width: 100vw; background: #fff; height: 100%;" id="externalURL">
          <app-generic [structure]="authService.externalWindowStructure" *ngIf="authService.externalWindowStructure != undefined && authService.externalWindowStructure != null"></app-generic>
          <ng-container *ngIf="authService.externalWindowStructure == undefined || authService.externalWindowStructure == null">
            <mat-progress-bar class="loading-window" style="top: 5.3em !important" mode="indeterminate"></mat-progress-bar>
          </ng-container>
        </div>
      </mat-sidenav>
      
      <mat-sidenav-content (scroll)="onScroll($event)">
        <ng-container *ngIf="genericService.finishedLoadStructureHeader">
          <app-generic #generic (openSidebar)="onOpenSidebar($event)" [structure]="authService.headerStructure" *ngIf="authService.headerStructure != undefined && authService.headerStructure != null"></app-generic>
          <div class="bar-tools" fxFlex="100" fxLayout="row wrap" *ngIf="this.routingService.loadedBreadcrumbs">
            <div class="first-bar-tools" fxFlex="80" fxLayoutAlign="start center">
              <span class="material-icons"(click)="this.functionsService.goPrev()" [ngClass]="!this.genericService.hasPrev() ? 'arrow-opacity' : ''" matTooltip="Ir a la pantalla anterior">arrow_back</span>
              <span *ngIf="this.genericService.refreshStructureFinished" class="material-icons" matTooltip="Recargar la pantalla" (click)="this.genericService.refresh()">refresh</span>
              <mat-spinner style="margin:0 auto;" diameter="20" *ngIf="!this.genericService.refreshStructureFinished"></mat-spinner>
              <span class="material-icons" [matMenuTriggerFor]="historial" *ngIf="this.getHistory().length > 1 || genericService.getCurrentIndex() > 0" matTooltip="Historial de navegación">history</span>
              <span class="material-icons arrow-opacity" *ngIf="this.getHistory().length <= 1 && genericService.getCurrentIndex() <= 0" matTooltip="Historial de navegación">history</span>
              <span class="material-icons" (click)="this.functionsService.goNext()" [ngClass]="!this.genericService.hasNext() ? 'arrow-opacity' : ''" matTooltip="Ir a la siguiente pantalla">arrow_forward</span>
              <ng-container *ngIf="this.genericService.moduleId != 43">
                <p fxShow="true" fxShow.sm="false" fxShow.xs="false" class="breadcrumbs">
                  <span class="material-icons" (click)="go('inicio')"> home </span>
                  <ng-container *ngFor="let b of genericService.breadcrumbs">
                    <span class="material-icons">navigate_next </span>
                    <span class="link" (click)="navigateBreadcrumbs(b)"> {{b['name'].replaceAll('-', ' ')}} </span>
                  </ng-container>
                </p>
              </ng-container>
            </div>
            <div class="second-bar-tools icons-header-custom" style="padding-right: 0.3em;" fxFlex="20" fxLayoutAlign="end center" *ngIf="!genericService.readMode">
              <span [matBadge]="getNumChanges()" [matBadgeHidden]="getNumChanges()<1" class="badge-form-change">
                <span class="material-icons" *ngIf="getNumChanges() > 0" [matMenuTriggerFor]="form_changes" matTooltip="Cambios realizados en la pantalla">published_with_changes</span>
                <span class="material-icons arrow-opacity" *ngIf="getNumChanges() < 1" matTooltip="Cambios realizados en la pantalla">published_with_changes</span>
              </span>
            </div>
          </div>
          <div fxFlex="100" fxLayout="row wrap" style="padding-top: 5.2em;" *ngIf="authService.externalWindowStructure == null">
            <notificacion-superior *ngIf="genericService.finishedLoadStructure" class="notificacion-superior-class" id="notificacion-superior-class" #NotificacionSuperiorComponent style="width: 100%; position: fixed; z-index: 999;" ></notificacion-superior>
            <span style="height: 100%;" [style.margin-top.px]="this.getHeight()"> 
              <router-outlet></router-outlet>
              <div style="height: 9em;" fxHide="true" fxHide.sm="false" fxHide.xs="false"></div>
            </span>
          </div>
          <app-generic #footer (openSidebar)="onOpenSidebar($event)" [structure]="authService.footerStructure" *ngIf="authService.footerStructure != undefined && authService.footerStructure != null"></app-generic>
        </ng-container>
      </mat-sidenav-content>
      
      <mat-menu #historial="matMenu">
        <span *ngFor="let e of this.genericService.history.slice(this.genericService.getCurrentIndex() - 10 >= 0 ? this.genericService.getCurrentIndex() - 10 : 0).reverse(); let i = index">
          <button *ngIf="this.authService.labelLanguage && e['url'].split(this.authService.labelLanguage + '/')[1] !== undefined" mat-menu-item (click)="navigateHistory(e, i)" style="text-transform: capitalize" [ngClass]="i == genericService.history.length - 1 - genericService.getCurrentIndex() ? 'highlight' : ''">{{ e['url'].split(this.authService.labelLanguage + "/")[1].replaceAll('/', '').replaceAll('-', ' ').split('#')[0] }}</button>
        </span>
      </mat-menu>
      
      <mat-menu #form_changes="matMenu">
        <span mat-menu-item class="form-change-window" fxLayout="row wrap">
          <p class="form-change-window-label">{{ getInternalName(this.genericService.currentInitialArea['key']) }} <span *ngIf="this.genericService.refreshStructureFinished" class="material-icons" style="color: #fddd00 !important" matTooltip="Deshacer todos los cambios" (click)="this.genericService.refresh()">restart_alt</span></p>
          <span *ngFor="let elem of getFormsCurrentPage() | keyvalue" class="form-change" fxFlex="100">
            
            <!--  CABECERA  -->
            <!--- ETIQUETA QUAN ES UN DUPLICATE I AVISA QUE S'HA CREAT EL DUPLICATE -> ES DE LA FORMA "NEW (X)"-->
            <p *ngIf="elem.value.labelNewDuplicated" class="form-change-label" fxLayoutAlign="space-between start">
              <span style="text-transform: uppercase;"> NEW (
                <span class="material-icons">segment</span> {{ elem.value.id_duplicated }})
              </span>
              <span class="material-icons" style="margin-left: 10px !important;" (click)="revertDuplicateFromValue(this.genericService.currentInitialArea['key'], elem)" matTooltip="Deshacer cambio">restart_alt</span>
            </p>
            
            <!--- ETIQUETA QUAN ES UN UNA MODIFICACIÓ D'UN ELEMENT QUE NO S'ACABA DE DUPLICAR I NO TE MÉS D'UNA OPCIÓ -->
            <p *ngIf="!elem.value.labelNewDuplicated && !elem.value.isDuplicated && !elem.value.hasNgForOptions" class="form-change-label" fxLayoutAlign="space-between start" >
              <span style="text-transform: uppercase;"> {{ getLabel(elem.value.name) }}
                <span class="form-change-type">({{ getType(elem.value.name) }})</span></span>
                <span class="material-icons" style="margin-left: 10px !important;" (click)="revertFormValue(this.genericService.currentInitialArea['key'],elem)" matTooltip="Deshacer cambio">restart_alt</span>
              </p>
              
              <!--- ETIQUETA QUAN ES UN UNA MODIFICACIÓ D'UN ELEMENT QUE NO S'ACABA DE DUPLICAR I TE MÉS D'UNA OPCIÓ -->
              <p *ngIf="!elem.value.labelNewDuplicated && (elem.value.isDuplicated || elem.value.hasNgForOptions)" class="form-change-label" fxLayoutAlign="space-between start" >
                <span style="text-transform: uppercase;" matTooltip="{{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].tooltip }}"> {{ getLabel(elem.value.name) }} (<span class="material-icons">segment</span> {{elem.value.id_duplicated}})
                <span class="form-change-type">({{ getType(elem.value.name) }})</span></span>
                <span class="material-icons" style="margin-left: 10px !important;" (click)="revertFormValue(this.genericService.currentInitialArea['key'],elem)" matTooltip="Deshacer cambio">restart_alt</span>
              </p>
              
              <!-- CONTINGUT DEL CANVI -->
              <p class="form-changedd" fxLayoutAlign="start center" fxLayout="row wrap">
                <span class="form-change-old">
                  <ng-container *ngIf="elem.value.type !== 'checkbox_' && elem.value.type !== 'slide_' && elem.value.type !== 'password_' && elem.value.type !== '5-stars_' && elem.value.type !== '10-stars_' && elem.value.type !== '3-faces_' && elem.value.type !== '5-faces_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].old }}
                  </ng-container>
                  <ng-container *ngIf="elem.value.type === 'checkbox_'">
                    <i style="color: green !important;" *ngIf="elem.value.oldValue == true || elem.value.internal_value.old == true" class="material-icons">
                      check_box
                    </i>
                    <i style="color: red !important;" *ngIf="elem.value.oldValue == false || elem.value.internal_value.old == false" class="material-icons">
                      check_box_outline_blank
                    </i>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type === 'slide_'">
                    <i style="color: green !important;" *ngIf="elem.value.oldValue == true || elem.value.internal_value.old == true" class="material-icons">
                      toggle_on
                    </i>
                    <i style="color: red !important;" *ngIf="elem.value.oldValue == false || elem.value.internal_value.old == false" class="material-icons">
                      toggle_off
                    </i>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type == 'password_'">
                    <i class="material-icons" style="color: #FFFFFF !important;">
                      password
                    </i>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type == '5-stars_' || elem.value.type == '10-stars_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].old }}
                    <i class="material-icons" style="color: #FFFFFF !important;">
                      star
                    </i>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type == '3-faces_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].old }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray3; let i = index"> 
                      <i [ngStyle]="{'color': face['color']}" *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].old" class="material-icons">
                        {{ face.icon }}
                      </i> 
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type == '5-faces_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].old }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray5; let i = index"> 
                      <i [ngStyle]="{'color': face['color']}" *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].old" class="material-icons">
                        {{ face.icon }}
                      </i> 
                    </ng-container>
                  </ng-container>
                </span>
                <span *ngIf="!elem.value.labelNewDuplicated && !elem.value.is_from_no_params" class="material-icons form-changed-icon">trending_flat</span>
                <span class="form-change-new">
                  <ng-container *ngIf="elem.value.type !== 'checkbox_' && elem.value.type !== 'slide_' && elem.value.type !== '5-stars_' && elem.value.type !== '10-stars_' && elem.value.type !== '3-faces_' && elem.value.type !== '5-faces_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].new }}
                  </ng-container>
                  <ng-container *ngIf="elem.value.type === 'checkbox_'">
                    <i style="color: green !important;" *ngIf="elem.value.newValue == true || elem.value.internal_value.new == true" class="material-icons">
                      check_box
                    </i>
                    <i style="color: red !important;" *ngIf="elem.value.newValue == false || elem.value.internal_value.new == false" class="material-icons">
                      check_box_outline_blank
                    </i>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type === 'slide_'">
                    <i style="color: green !important;" *ngIf="elem.value.newValue == true || elem.value.internal_value.new == true" class="material-icons">
                      toggle_on
                    </i>
                    <i style="color: red !important;" *ngIf="elem.value.newValue == false || elem.value.internal_value.new == false" class="material-icons">
                      toggle_off
                    </i>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type == '5-stars_' || elem.value.type == '10-stars_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].new }}
                    <i class="material-icons" style="color: #FFFFFF !important;">
                      star
                    </i>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type == '3-faces_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].new }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray3; let i = index"> 
                      <i [ngStyle]="{'color': face['color']}" *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].new" class="material-icons">
                        {{ face.icon }}
                      </i> 
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="elem.value.type == '5-faces_'">
                    {{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][elem.value.name].new }}
                    <ng-container *ngFor="let face of genericService.iconFacesArray5; let i = index"> 
                      <i [ngStyle]="{'color': face['color']}" *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].new" class="material-icons">
                        {{ face.icon }}
                      </i> 
                    </ng-container>
                  </ng-container>
                </span>
              </p>
          </span>
        </span>
      </mat-menu>
    </mat-sidenav-container>

    <ng-container *ngIf="!authService.isUserSessionActive() && genericService.finishedLoadStructureHeader">
      <span *ngIf="authService.headerStructure != undefined && authService.headerStructure != null"
        [ngClass]="authService.headerStructure['id_functional_status_mkt'] != 1 ? authService.headerStructure['class_custom']+ ' ' + 'mkt-no-available' : authService.headerStructure['class_custom']"
        style="position: fixed;width: 100vw; z-index: 99999;">
        <app-generic #generic [structure]="authService.headerStructure"></app-generic>
      </span>
      <span style="display: block; padding-top: 2.6em; width: 100%;"></span>
      <app-rrss-home-custom *ngIf="genericService.projectID == 0" #SociaSocialMediaCustomComponent></app-rrss-home-custom>
      <router-outlet></router-outlet>
      <app-generic #footerNoLogued (openSidebar)="onOpenSidebar($event)" [structure]="authService.footerStructure" *ngIf="authService.footerStructure != undefined && authService.footerStructure != null && authService.footerStructure[0] != undefined && authService.footerStructure[0]['id_functional_parent'] != undefined"></app-generic>
    </ng-container>
  </ng-container>

  <auto-plantilla #AutoPlantillaComponent></auto-plantilla>
  
  <div *ngIf="showInstallAppIos">
    Install this webapp on your iphone tap (icon)
  </div>
    
  <ng-template #warningDialog>
    <div mat-dialog-title>
      <p>{{ this.genericService.warningTitle }}</p>
    </div>
    <div mat-dialog-content>
      <p>{{ this.genericService.warningMessage }}</p>
    </div>
    <div mat-dialog-actions style="float: right;">
      <div class="generic-buttons-blue">
        <button mat-button [mat-dialog-close]="false" >{{ this.genericService.warningButton1 }}</button>
      </div>
      <div class="generic-buttons-blue-revert" style="margin-left: 7px;">
        <button mat-button [mat-dialog-close]="true" > {{ this.genericService.warningButton2 }} </button>
      </div>
    </div>
  </ng-template>

</ng-container>

<ng-container *ngIf="genericService.staticHTML">
  <div *ngIf="genericService.urlFullInfo.can_download == 1 || genericService.urlFullInfo.can_print == 1" class="buttons-static-html">
    <div *ngIf="genericService.urlFullInfo.can_print == 1" class="generic-buttons-red-revert generic-buttons-big" style="margin-right: 7px;">
      <button mat-button (click)="printFunction()"> {{ 'general.print' | translate}} </button>
    </div>
    <div *ngIf="genericService.urlFullInfo.can_download == 1" class="generic-buttons-red-revert generic-buttons-big" style="margin-left: 7px;">
      <button mat-button (click)="downloadFunction()"> {{ 'general.download' | translate}} </button>
    </div>
  </div>
  <div style="height: calc(2.6em + 15px);" *ngIf="!functionsService.downloadingStaticPage && genericService.staticHTML && (genericService.urlFullInfo.can_download == 1 || genericService.urlFullInfo.can_print == 1)"></div>
  <router-outlet></router-outlet>
</ng-container>

<span class="notificacion-superior-class" *ngIf="!notificacionSuperiorNotLoaded" style="display: none;"></span>