<mat-horizontal-stepper class="general-stepper" [linear]="false" #stepper>
  <mat-step label="Resumen" *ngIf="this.data.moduloInfo.isDependent === 1">
    <app-dependencies [modulo]="data.moduloInfo" (event)="receiveMessage($event)"></app-dependencies>
  </mat-step>
  <mat-step label="Checkout">
    <app-checkout [carro]="carritoCompra" [totalCarro]="totalCompra"></app-checkout>
    <button class="button-general" (click)="checkComprarModulos()">Comprar</button>
  </mat-step>
  <mat-step label="Movins">
    <add-movin-coin (sendEvent)="openStripe($event)"  [type]="1"></add-movin-coin>
    <app-stripe-payment #stripePayment (sendBuyEvent)="save($event)"></app-stripe-payment>
    <mat-spinner *ngIf="loading" class="loader-spinner-landing" [diameter]="60" [strokeWidth]="12"></mat-spinner>
  </mat-step>
  <!--<mat-step label="Pagament" *ngIf="showPagament">
    <app-stripe-payment #stripePayment (sendBuyEvent)="save($event)"></app-stripe-payment>
    <button class="button-general" (click)="testStripe()">TEST STRIPE</button>
    <button class="button-general" (click)="nextStep(1)">Confirmar compra</button>
  </mat-step>-->
  <mat-step label="Configuración" *ngIf="this.data.moduloInfo.esConfigurable === 1">
    <app-configuration [modulos]="carritoCompra" [new]="true" (event)="receiveMessageConfig($event)"></app-configuration>
    <!--<button class="button-general" (click)="nextStep(2)">Siguiente</button>-->
  </mat-step>
</mat-horizontal-stepper>
