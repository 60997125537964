import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EndpointService} from "../../services/endpoint/endpoint.service";
import {AuthService} from "../../services/auth/auth.service";

import { MarketplaceFunctions } from '../../services/functions/functions.marketplace.service'


@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.css']
})
export class ConfigDialogComponent implements OnInit {

  // Accion:
  // 1 --> comprar
  // 2 --> ampliar
  // 3 --> configurar

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {accion, modulo},
    private endpointService: EndpointService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ConfigDialogComponent>,
    public marketplaceFunctionsService: MarketplaceFunctions
  ) { }

  ngOnInit(): void {
    console.log(this.data.modulo);
  }

  public insertModuloInmo(): void {
    this.marketplaceFunctionsService.getFunction(602,{that: this});
  }
}
