<mat-horizontal-stepper class="stepper" [linear]="true" #stepper style="width: 60vw;" ngStyle.md="width: 80vw;" ngStyle.sm="width: 90vw;" ngStyle.xs="width: 97vw;">
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Información</ng-template>
      <span fxLayout="row wrap" fxFlex="100">
        <span fxFlex="70" fxFlex.md="65" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
          <mat-form-field fxFlex="100" appearance="outline">
            <input matInput placeholder="Nombre del grupo" formControlName="firstCtrlGroupName">
            <mat-error *ngIf="firstFormGroup.get('firstCtrlGroupName').hasError('required')">
              Debes elegir un <strong>nombre</strong> para el grupo
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="100">
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                    name="name" type="text" placeholder="Descripción del grupo" formControlName="firstCtrlGroupDescription"></textarea>
            <mat-error *ngIf="firstFormGroup.get('firstCtrlGroupDescription').hasError('required')">
              Debes elegir una <strong>descripción</strong> para el grupo
            </mat-error>
          </mat-form-field>
        </span>
        <span fxFlex="30" fxFlex.md="35" fxFlex.sm="100" fxFlex.xs="100"  fxLayout="row wrap">
          <div style="padding: 0 4em; width: 100%;" ngStyle.md="padding: 0 2em;" ngStyle.sm="padding: 0;" ngStyle.xs="padding: 0;">
            <div class="imagen-perfiling">
              <img [src]="noPhotoImg" class="img-fluid marketing-img-height"/>
            </div>
            <div class="button-bottom-1">
              <button mat-button (click)="addImage()" matTooltip="Añadir imagen de perfil"><i class="material-icons">photo</i> Añadir</button>
              <input #file type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*"
                     (change)="preview(file.files)" style="display:none;">
            </div>
            <!--<div class="dialog-footer-2" style="display: flex" *ngIf="editable && profilePicture">
              <button mat-button (click)="addImage()" matTooltip="Cambiar imagen de perfil"><i class="material-icons">photo</i> Editar</button>
              <button mat-button (click)="deleteImage()" matTooltip="Eliminar imagen de perfil"><i class="material-icons">delete</i> Eliminar</button>
              <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;">
            </div>-->
          </div>
        </span>
        <div fxFlex="100" class="buttons-steps">
          <button mat-button matStepperNext>Siguiente</button>
        </div>
      </span>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Seleccionar usuarios</ng-template>

    <div class="users">
      <mat-form-field appearance="outline">
        <textarea [(ngModel)]="usuario_search" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"
                  name="name" type="text" (change)="search()" placeholder="Buscar chat"></textarea>
          <mat-icon>search_off</mat-icon>
      </mat-form-field>
      <ul class="users-list">
        <li *ngFor="let selected of selectedUsersArray; let i = index; " style="height: 3em;">
          <img class="fotoEvento2" src="{{selected.profilePictureURL}}" alt="" style="max-width:100%; max-height:100%;">
          {{selected.name}} {{selected.surname}}
          <mat-checkbox (click)="changeStatusUser(selected.userToken,false)" style="float: right; padding-right: 20px;"  [checked]="true"></mat-checkbox>
        </li>
        <li *ngFor="let users of usersList | filter : usuario_search: 2; let i = index; " style="height: 3em;">
          <img class="fotoEvento2" src="{{users.profilePictureURL}}" alt="" style="max-width:100%; max-height:100%;">
          {{users.name}} {{users.surname}}
          <mat-checkbox (click)="changeStatusUser(users.userToken,true)" style="float: right; padding-right: 20px;"></mat-checkbox>
        </li>
      </ul>
    </div>

    <div fxFlex="100" class="buttons-steps">
      <button mat-button matStepperNext>Siguiente</button>
      <button mat-button matStepperPrevious>Anterior</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup" fxFlex="100" fxLayout="row wrap">
      <ng-template matStepLabel>Permisos</ng-template>

      <div fxFlex="100" fxLayout="row wrap">
        <mat-checkbox class="config-element" fxFlex="100" formControlName="firstCtrlOnlyAdminCanChat" (click)="$event.stopPropagation()">Solo puede hablar el administrador</mat-checkbox>
        <mat-checkbox class="config-element" fxFlex="100" formControlName="firstCtrlOnlyAdminCanAdd" (click)="$event.stopPropagation()">Solo puede añadir usuarios el administrador</mat-checkbox>
        <mat-checkbox class="config-element" fxFlex="100" formControlName="firstCtrlOnlyAdminCanEditGroupInfo" (click)="$event.stopPropagation()">Solo puede editar información del grupo el administrador</mat-checkbox>
        <div class="config-element" fxFlex="100" fxLayout="row wrap">
          <span class="labels-padds" fxFlex="100">Tipo de grupo</span>
          <mat-radio-group formControlName="firstCtrlGroupOpened">
            <mat-radio-button [value]="2" style="margin-right: 1em;">PRIVADO</mat-radio-button>
            <mat-radio-button [value]="1" style="margin-right: 1em;">PÚBLICO</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="thirdFormGroup.get('firstCtrlGroupOpened').hasError('required')">
            Debes definir si el grupo es <strong>privado</strong> o <strong>público</strong>
          </mat-error>
        </div>
      </div>

      <div fxFlex="100" class="buttons-steps">
        <button mat-button (click)="createGroup()">Crear grupo</button>
        <button mat-button matStepperPrevious>Anterior</button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
