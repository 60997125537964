import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { AuthService } from "../../services/auth/auth.service";
import { forkJoin } from "rxjs";
import { ConfigDialogComponent } from "../config-dialog/config-dialog.component";
import { MatDialog } from "@angular/material/dialog";

import { MarketplaceFunctions } from '../../services/functions/functions.marketplace.service'


@Component({
  selector: 'app-marketplace-card',
  templateUrl: './marketplace-card.component.html',
  styleUrls: ['./marketplace-card.component.css']
})
export class MarketplaceCardComponent implements OnInit {

  @Input('modulo') moduloReceived: any;
  @Input('isOferta') isOferta: boolean;

  constructor(
    public sanitizer: DomSanitizer,
    private endpointService: EndpointService,
    private authService: AuthService,
    public dialog: MatDialog,
    public marketplaceFunctionsService: MarketplaceFunctions) { }

  ngOnInit(): void {
  }

  public clickAddModulo(): void {
    let openConfigDialog = true;
    console.log(this.moduloReceived);

    const p1 = new Promise<void>( ((resolve, reject) => {
      // Comprobar que el usuario tiene permisos para comprar modulos
      resolve();
    }));

    const p2 = new Promise<void>( ((resolve, reject) => {
      // Comprobar si el modulo depende de otro --> y si depende de otro, comprobar que la inmo tiene ese otro modulo
      if (this.moduloReceived['id_modulo_dependiente'] !== null) {
        this.endpointService.inmoTieneModuloDependiente(this.authService.inmoId, this.moduloReceived['id_modulo_dependiente']).subscribe(data => {
          if (data['errorCode'] === 0) {
            if (data['response'] === true) {
              // OK
            } else {
              console.log('No puedes comprar el módulo, ya que no tienes el modulo dependiente');
              openConfigDialog = false;
            }
          }
          resolve();
        });
      } else {
        resolve();
      }
    }));

    const p3 = new Promise<void>( ((resolve, reject) => {
      // Comprobar que la inmo tiene suficientes movins para comprar el modulo
      this.endpointService.getMovinsInmo(this.authService.inmoId, 1).subscribe(data => {
        if (data['errorCode'] === 0) {
          console.log('Movins totales: ', data['response'][0]['total_movins']);
          if (data['response'][0]['total_movins'] >= this.moduloReceived['precio']) {
            // OK
          } else {
            console.log('No puedes comprar el módulo, ya que no tienes suficientes movins');
            openConfigDialog = false;
          }
        }
        resolve();
      });
    }));

    const observerArray = [];
    observerArray.push(p1);
    observerArray.push(p2);
    observerArray.push(p3);
    forkJoin(observerArray).subscribe(data => {
      console.log(openConfigDialog);
      if (openConfigDialog === true) {
        this.openConfigDialog(1);
      }
    });
  }

  public ampliarModulo(): void {
    this.openConfigDialog(2);
  }

  public configurarModulo(): void {
    this.openConfigDialog(3);
  }

  public eliminarModulo(): void {
    this.marketplaceFunctionsService.getFunction(618,{that: this});
  }

  public openConfigDialog(accion: number): void {
    console.log('Llego al openConfigDialog');
    const dialogRef = this.dialog.open(ConfigDialogComponent, {
      data: {accion, modulo: this.moduloReceived},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
