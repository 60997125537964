import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { GenericService } from 'app/services/generic/generic.service';
import { RoutingService } from 'app/services/routing/routing.service';
import { EndpointService } from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnDestroy {

  @Input() structure = null;
  @Input() type;
  @Input() dialogId;

  public params = null;
  public formGroup = {};
  public loaded = false;
  public emptyDialog = true;
  private monitoreo;

  constructor(
    private endpointService: EndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private routingService:RoutingService,
    public genericService: GenericService,
    public authService: AuthService
  ) {
    console.log("data2", data);
    this.structure = data['structure'];
    this.type = data['type'];
    this.dialogId = data['dialogId'];
  }
  //Ahora se tendra que pasar el id de la functional area a cargar en el dialog
  ngOnInit(): void {
    if(this.structure['creatingMode'] == undefined || !this.structure['creatingMode']) {
      this.genericService.fetchDSBStructure(this.structure['id_functional_parent_initial'], this.dialogId, this.structure['id_query'], this.type).subscribe((data): void => {
        this.structure['child'] = data;
        if(this.type == 3) {
          console.log(this.params, "paraaaaams")
          let paramControl = JSON.parse(localStorage.getItem("paramControl"));
          console.log(paramControl, "paraaaaams local", this.authService.getIdCompany(true))
          paramControl = paramControl[this.authService.getIdCompany(true)]
          for (let par of paramControl) {
            if (par['id_pantalla'] == this.structure['id_functional_parent_initial']) this.params = par['params']['intern'];
          }
          this.assignParams(this.structure, this.params);
          this.loaded = true
        } else if(this.type == 6) {
          let paramControl = JSON.parse(localStorage.getItem("paramControl"));
          paramControl = paramControl[this.authService.getIdCompany(true)]
          console.log(paramControl)
          for (let par of paramControl) {
            if (par['id_pantalla'] == this.structure['id_functional_parent_initial_dsb']) this.params = par['params']['intern'];
          }
          this.assignParams(this.structure, this.params);
          this.loaded = true
        } else this.loaded = true;
  
        console.log(this.structure, "dialooogs")
        this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), this.dialogId, this.dialogId, this.genericService.staticHTML).subscribe(data => {
          this.monitoreo = data['response'];
        });
      });
    } else {
      this.loaded = true
    }
  }

  ngOnDestroy() {
    this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }

  assignParams(subStructure, params){
    for(let i in params) {
      if(params[i]['value']) {
        if(subStructure['id_db'] == params[i]['id_db'] && subStructure['bd_table'] == params[i]['bd_table'] && subStructure['bd_field'] == params[i]['bd_field']) {
          console.log("DIALOG PARAM ENCONTRADO EN EL DIALOG!!", params[i])
          subStructure['tmp_value'] = params[i]['value'];
          subStructure['form'].patchValue( {
            [subStructure['id_functional_area'] + '-' + subStructure['bd_field']]: params[i]['value']
          })
          break;
        }
      }
    }
    if(subStructure['child'])
      for(let j in subStructure['child'])
        this.assignParams(subStructure['child'][j], params)
  }

}
