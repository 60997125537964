import {Component, OnInit, Input, Output, OnDestroy, ViewChild, EventEmitter, HostListener} from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {EndpointService} from "../services/endpoint/endpoint.service";
import {AdviceReportService} from "../services/advice-report/advice-report.service";
import { GenericService } from 'app/services/generic/generic.service';
import {Router} from "@angular/router";
import {Values} from "../../values/values";
import {ChatService} from "../services/chat.service";
import {NotificationsCustomComponent} from "./components-custom/notifications-custom/notifications-custom.component";
import { FormComponent } from './components/form/form.component';
import { ActivitiesService } from 'app/services/activities/activities.service';
import { MatDialog } from '@angular/material/dialog';
import { AddGenericElementDialogComponent } from './components-custom/generic-creator-custom/add-generic-element-dialog/add-generic-element-dialog.component';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.css']
})

export class GenericComponent implements OnInit, OnDestroy {

  private index = 0;
  public array = [];
  public classesList = [];
  public showChatActive: boolean;
  public showHelpActive: boolean;
  public parentLayout = {};
  public idParent;
  public idFirst;
  public alignGeneral;
  public loaded = false;

  @Input() structure = null;
  @Input() creatingMode = false;
  @Input() param = null;
  @Output() openSidebar: EventEmitter<string> = new EventEmitter<string>();
  //@Output() openHelp = new EventEmitter<string>();
  @Output() notify = new EventEmitter<{}>();

  //public styles = [];
  public routes = Values.ROUTES;
  public countChat: any = null;
  @ViewChild('notisCustom', { static: false }) set notificationsCustom(notificationsCustom: NotificationsCustomComponent){
    if(notificationsCustom) this.adviceReportService.notificationsComponent = notificationsCustom;
  };

  private monitoreo: any;
  public hovered = -1;

  constructor(public router: Router,
              public authService: AuthService,
              public endpointService: EndpointService,
              public adviceReportService: AdviceReportService,
              public genericService: GenericService,
              public chatService: ChatService,
              public activitiesService: ActivitiesService,
              public dialog: MatDialog,
              public genericCreatorService: GenericCreatorService
              ) { }

  ngOnInit(): void {
    // no form field => status 5 => status 1
    // hide true => status 2

    this.monitoreo = null;

    /*this.authService.monitoringIndex.subscribe( i =>{
      this.index = i;
    });*/

    this.loaded = true;

    /*if(this.routingService.urlHashtag != null){
      const id = this.routingService.urlHashtag;
      for(const key in this.structure['child']){
          let data = [];
          const element = this.genericService.findElementWithIdWithinPopups(this.structure[key], id, data);
          if(element && JSON.stringify(element) !== JSON.stringify({})){
            for(const d of element['data']){
              this.functionsService.getFunction(d['parent']);
            }
          }
        }
    }*/



    for(const key in this.structure['child']){
      if(this.structure['child'][key]['id_functional_type'] == 122) { // Codi d'agenda
        this.activitiesService.setControls(this.structure['child'][key]);
      }

      if(this.structure['child'][key]['id_functional_status_mkt'] != 1) {
        this.structure['child'][key]['class_custom'] += ' mkt-no-available';
      }

      if(this.structure['child'][key]['creatingMode']) {
        this.structure['child'][key]['class_custom'] += ' creating-mode';
      }
    }

    if(this.structure['creatingMode'] && this.structure['id_language'] && this.structure['id_language'] > 0){
      this.genericCreatorService.currentLanguage = this.structure['id_language']
    }
  }

  showChat(isMovil = false) {
    if(isMovil) this.router.navigate([this.routes.CHAT]);
    else {
      this.showChatActive = !this.showChatActive;
      this.showHelpActive = !this.showChatActive && this.showHelpActive;
      this.openSidebar.emit('chat');
    }
  }

  showHelp() {
    this.showHelpActive = !this.showHelpActive;
    this.showChatActive = !this.showHelpActive && this.showChatActive;
    this.openSidebar.emit('help');
  }

  // public childCanDeactivate(moduleId: number): boolean {
  //   switch(moduleId) {
  //     case 17: return this.property.childCanDeactivate();
  //     default: return true;
  //   }
  // }

  @HostListener('window:beforeunload', ['$event'])
  /*unloadNotification($event: any) {
    this.genericService.isStructureLoadedAsync(this.structure).then(() => {
    if (this.monitoreo != null) {
      this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
      });
      //$event.returnValue =true;
    }
    });
  }*/

  ngOnDestroy(): void {
    //let ind = Object.keys(this.structure)[0];
    if(this.monitoreo != null){
      this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data=>{});
    }
    //
  }

  styleObject(key){
    let ob = {};
    let elements = this.structure[key]['style'].split('; ');

    elements.array.forEach(element => {
      let sep = element.split(': ');
      ob[sep[0]] = sep[1];
    });
    return ob;
  }

  private child;
  @ViewChild('child',{ static: true }) set content(content: FormComponent) {
    if(content) { // initially setter gets called with undefined
      console.log('tools viewchild', content);
        this.child = content;
    }
  }

  notifyForms() {
    console.log('tools refresh generic', this.child)
    this.child.onRefreshForm();
  }

  openGeneralCreatorPopup(){
    this.genericCreatorService.resetOptions();
    const dialogRef = this.dialog.open(AddGenericElementDialogComponent, {
      data: this.structure['idTmp'],
      panelClass: 'addElementDialog'
   });
   dialogRef.afterClosed().subscribe(added => {
    if(added == undefined || !added) {
      this.genericCreatorService.resetOptions();
    }
   });
  }

  editOption(element: any){
    this.genericCreatorService.actualSelectedElement = element;
    this.genericCreatorService.filterStatusOptions(element['id_functional_type']);
    this.genericCreatorService.isAddingElement = false;
    this.genericCreatorService.expandedIndex = 1;
  }
}
