import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {EndpointService} from '../../services/endpoint/endpoint.service';
import {AuthService} from '../../services/auth/auth.service';

declare const jQuery: any;

@Component({
  selector: 'upload-file-modal-component',
  templateUrl: './UploadFileModal.html',
  styleUrls: ['./UploadFileModal.css'],
})

export class UploadFileModalComponent {

  @ViewChild('uploaderForm') uploadForm;
  @Output() OnFileUploads = new EventEmitter<UploadFileModalItem>();
  @Output() OnCloseModal = new EventEmitter();
  @Input() Table = '';
  public filename = 'Nombre del archivo';

  constructor(private endpointService: EndpointService,
              public authService: AuthService) {
  }

  public fileChange(event) {

    let fileList: FileList = this.uploadForm.nativeElement[1].files;
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        this.endpointService.uploadFile(this.authService.userId, formData, this.filename, this.Table,this.authService.inmoId).subscribe(
          data => {
            this.OnFileUploads.emit(data['response']);
          }
        );
        /*
        this.endpointService.uploadOfferDoc(this.authService.userId, formData, this.docName, this.offer.idOffer).subscribe(
          data => {
            if (data.errorCode == 0) {
              this.endpointService.getOfferDocs(this.offer.idOffer).subscribe(
                data => {
                  this.offer.docs = data.response;
                }
              );
            }
          }
        );
        */
      }
    }
  }

  public closeUploadFile() {
    jQuery('#uploadFileModal').modal('hide');
    this.OnCloseModal.emit();
  }
}

export interface UploadFileModalItem {
  id: number;
  name: string;
}
