<div>

  <div style="width: 100%; padding-left: 4%; padding-right: 4%;">
      <mat-form-field style="width: 100%;" appearance="outline">
        <input matInput placeholder="Texto" autocomplete="off" type="text" [(ngModel)]="trackingLinkName">
      </mat-form-field>

      <mat-form-field style="width: 100%;" appearance="outline">
        <input matInput placeholder="Link" type="url" [(ngModel)]="trackingLinkLink">
      </mat-form-field>

  </div>

  <div class="dialog-footer-2">
    <button (click)="closeBottomSheet(2)">Cancelar</button>
    <button (click)="closeBottomSheet(1)">Añadir</button>
  </div>
</div>
