<div>
  <div *ngIf="action === 4">

    <!--mat-card>
      <mat-card-header>
        <div mat-card-avatar></div>
        <mat-card-title>Elegir cliente</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="row-expansion2">
          <mat-form-field appearance="outline">
            <mat-label>Clientes</mat-label>
            <input type="text" matInput [formControl]="clientControl" #checkerClients [matAutocomplete]="auto1" placeholder="escribe el nombre del cliente">
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
              <mat-option *ngFor="let option of clientControlOptions | async" [value]="option">
                {{option.clientName}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="clientControl.hasError('required')">
              Es necesario introducir <strong>Cliente</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Introducir el email</mat-label>
            <input matInput [formControl]="emailFormControl" #checker (keyup.enter)="addEmailChip()" placeholder="e.g. usuario@ejemplo.com">
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
              Por favor introduzca un email correcto
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Es necesario introducir <strong>Email</strong>
            </mat-error>
            <span matSuffix (click)="addEmailChip()"><mat-icon>add</mat-icon></span>
          </mat-form-field>
        </div>

        <div class="row-expansion1" appearance="outline">
          <mat-form-field class="example-chip-list">
            <mat-label>Emails</mat-label>
            <mat-chip-list #chipList aria-label="Fruit selection">
              <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                        [removable]="removable" (removed)="remove(email)">
                {{email}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
          </mat-form-field>
        </div>
      </mat-card-content>

    </mat-card-->

    <!--mat-card>
      <mat-card-header>
        <div mat-card-avatar></div>
        <mat-card-title>Elegir plantilla</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let templete of templates">
            <mat-radio-button color="primary" (change)="setIdTemplete(templete.id_plantilla);">
              <span mat-line>{{templete.nombre}}</span>
            </mat-radio-button>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card-->

    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Elige cliente</ng-template>
          <div class="row-expansion2">
            <mat-form-field appearance="outline">
              <mat-label>Clientes</mat-label>
              <input type="text" matInput formControlName="clientControl" #checkerClients [matAutocomplete]="auto1" (keydown.enter)="false" placeholder="escribe el nombre del cliente">
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
                <mat-option *ngFor="let option of clientControlOptions | async" [value]="option">
                  {{option.clientName}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="firstFormGroup.get('clientControl').hasError('required')">
                Es necesario introducir <strong>Cliente</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Introducir el email</mat-label>
              <input matInput formControlName="emailFormControl" #checker (keyup.enter)="addEmailChip()" (keydown.enter)="false" placeholder="e.g. usuario@ejemplo.com">
              <mat-error *ngIf="firstFormGroup.get('emailFormControl').hasError('email') && !firstFormGroup.get('emailFormControl').hasError('required')">
                Por favor introduzca un email correcto
              </mat-error>
              <mat-error *ngIf="firstFormGroup.get('emailFormControl').hasError('required')">
                Es necesario introducir <strong>Email</strong>
              </mat-error>
              <span matSuffix (click)="addEmailChip()" matTooltip="Añadir email"><mat-icon>add</mat-icon></span>
            </mat-form-field>
          </div>

          <div class="row-expansion1">
            <mat-form-field class="example-chip-list">
              <mat-label>Emails</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(email)">
                  {{email}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div>
            <button mat-button matStepperNext (click)="checkValidatorsBeforeNext()">Siguiente</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Elige plantilla</ng-template>
          <!--mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let templete of templates">
              <mat-radio-button color="primary" (change)="setIdTemplete(templete.id_plantilla);">
                <span mat-line>{{templete.nombre}}</span>
              </mat-radio-button>
            </mat-list-item>
          </mat-list-->

          <div class="example-container mat-elevation-z3 taulesgrans" style="overflow: auto;">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let element; let index = index" (click)="setIdTemplete(element.id_plantilla,index)"> {{element.nombre}} </td>
              </ng-container>

              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element; let index = index">
                  <mat-checkbox ngDefaultControl [(ngModel)]="element.checked" (change)="setIdTempleteChecker(element.id_plantilla,index)" [ngModelOptions]="{standalone: true}"></mat-checkbox>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <div class="bottom-table">
            <div class="bottom-table2">
              <mat-form-field class="input-filter">
                <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value);" placeholder="Buscar" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
            </div>
            <mat-paginator style="width: 50%;" [pageSizeOptions]="[5,10,20]"></mat-paginator>
          </div>
          <div>
            <button mat-button matStepperPrevious>Anterior</button>
            <button mat-button matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Hecho</ng-template>
        <p>Enviar el email</p>
        <div>
          <button mat-button matStepperPrevious>Anterior</button>
          <button mat-button (click)="closeBottomSheet(1)">{{buttonStrings[action - 1]}}</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>

  <div *ngIf="action !== 4">

    <div class="example-container mat-elevation-z3 taulesgrans" style="overflow: auto;">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell *matCellDef="let element; let index = index" (click)="setIdTemplete(element.id_plantilla,index)"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="check">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element; let index = index">
            <mat-checkbox ngDefaultControl [(ngModel)]="element.checked" (change)="setIdTempleteChecker(element.id_plantilla,index)" [ngModelOptions]="{standalone: true}"></mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="bottom-table">
      <div class="bottom-table2">
        <mat-form-field class="input-filter">
          <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value);" placeholder="Buscar" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
      <mat-paginator style="width: 50%;" [pageSizeOptions]="[5,10,20]"></mat-paginator>
    </div>

    <!--mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let templete of templates">
        <mat-radio-button color="primary" (change)="setIdTemplete(templete.id_plantilla);">
          <span mat-line>{{templete.nombre}}</span>
        </mat-radio-button>
      </mat-list-item>
    </mat-list-->

    <div class="dialog-footer-2">
      <button (click)="closeBottomSheet(1)">{{buttonStrings[action - 1]}}</button>
      <button (click)="closeBottomSheet(2)">Cancelar</button>
    </div>
  </div>
</div>
  <!--mat-selection-list>
    <mat-list-option *ngFor="let templete of templates" (click)="setIdTemplete(templete.id_plantilla)" selected>
      <span mat-line>{{templete.nombre}}</span>
      <span mat-line>{{templete.asunto}}</span>
    </mat-list-option>
  </mat-selection-list-->




