import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import { GenericService } from 'app/services/generic/generic.service';
import * as _ from "lodash";
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, distinctUntilChanged  } from 'rxjs/operators';
import { AddGenericElementDialogComponent } from '../add-generic-element-dialog/add-generic-element-dialog.component';


@Component({
  selector: 'app-generic-custom-creator-options',
  templateUrl: './generic-custom-creator-options.component.html',
  styleUrls: ['./generic-custom-creator-options.component.css']
})
export class GenericCustomCreatorOptionsComponent implements OnInit, OnDestroy {

  @Input() actualElement: any;
  variableObservable: Observable<string>;
  oldActualSelectedElement = {};
  isHeaderAdded = false;
  isContentAdded = false;
  isActionAdded = false;
  isDynamicAdded = false;
  initialized = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('companyTypeInput') companyTypeInput: ElementRef<HTMLInputElement>;
  @ViewChild('companyTypeInputRestriction') companyTypeInputRestriction: ElementRef<HTMLInputElement>;
  @ViewChild('profilePermissionInput') profilePermissionInput: ElementRef<HTMLInputElement>;

  constructor(public genericCreatorService: GenericCreatorService,
              public genericService:GenericService,
              public dialog: MatDialog,
              public formBuilder: FormBuilder) {

                // this.genericCreatorService.actualSelectedElementObs$.subscribe(data => {
                //   if (data) {
                //     this.actualElement = data;
                //     this.initialize();
                //   }
                // });

                this.variableObservable = new Observable((observer) => {
                  setInterval(() => {
                    observer.next(this.genericCreatorService.actualSelectedElement);
                  }, 1000);
                });

                this.variableObservable.subscribe((valor) => {
                  if(this.oldActualSelectedElement['idTmp'] != this.genericCreatorService.actualSelectedElement['idTmp']) this.initialize();
                });
              }

  ngOnInit(): void {
    if (!this.initialized){
      this.initialize();
    }
  }

  ngOnDestroy(): void {
    console.log('destroyed');
    this.initialized = false;
  }

  initialize() {
    console.log('called');
    this.genericCreatorService.listElementOptionToggle = false;
    this.genericCreatorService.activeTabIndex = 2;

    this.actualElement.hasSubEle = false;
    this.oldActualSelectedElement = _.cloneDeep(this.genericCreatorService.actualSelectedElement);
    if(this.genericCreatorService.actualSelectedSubElement === undefined) {
      this.genericCreatorService.arrayElementsTipo.forEach((item:any) =>{
        if(item.id_functional_type === this.actualElement.id_functional_type
          && item.id_functional_type_child > 0){
            this.actualElement.hasSubEle = true;
            this.genericCreatorService.actualSelectedFunctionalTypeChild = item.id_functional_type_child;
          }
      })
    }
    this.actualElement['formCreator'] = this.genericCreatorService.formBuilderCustom(this.actualElement);
    console.log(this.actualElement, "actualElementactualElement");
    this.genericCreatorService.filterTypes(this.actualElement['id_functional_type']);
    if(this.actualElement?.mode_type == undefined) {
      this.actualElement['mode_type'] = 'update';
    }
    // Call Value changes function after 3 seconds
     this.actualElement['formCreator']
     .valueChanges
     .pipe(
         debounceTime(3000),
         distinctUntilChanged()
     )
     .subscribe({
         next: (value) => {
          if(value['id_product_mkt'] !== null && value['id_product_mkt'] !== undefined){
            value['id_product_mkt'] = value['id_product_mkt']['id_product'];
          }
          if(value['id_product_mkt_no'] !== null && value['id_product_mkt_no'] !== undefined){
            value['id_product_mkt_no'] = value['id_product_mkt_no']['id_product'];
          }
          if(value['id_condition'] != null && value['id_condition'] !== undefined){
            value['id_condition'] = value['id_condition']['id_condition'];
          }
          if(value['condition_internal_routing'] != null && value['condition_internal_routing'] !== undefined){
            value['condition_internal_routing'] = value['condition_internal_routing']['id_functional_area'];
          }
          this.genericCreatorService.formValueSubscribe(value, this.actualElement);                   
          // this.genericCreatorService.setActualSelectedElement(this.actualElement);
         }
     });
     
     // For Text if subtype is 'a' then url is required
     this.actualElement['formCreator'].controls['type'].valueChanges.subscribe(value => {
        if(this.genericCreatorService.actualSelectedElement.id_functional_type == 101){
          if(value == 'a'){
            this.actualElement['formCreator'].get('url').setValidators(Validators.required);
          } else {
            this.actualElement['formCreator'].get('url').clearValidators();
          }
          this.actualElement['formCreator'].get('url').updateValueAndValidity();
        }
     })

       // For Graph if show total graph is selected then text is required
       this.actualElement['formCreator'].controls['show_total_graphs'].valueChanges.subscribe(value => {
        if(this.genericCreatorService.actualSelectedElement.id_functional_type == 14){
          if(value == true){
            this.actualElement['formCreator'].get('text').setValidators(Validators.required);
          } else {
            this.actualElement['formCreator'].get('text').clearValidators();
          }
          this.actualElement['formCreator'].get('text').updateValueAndValidity();
        }
     })

     this.genericCreatorService.idProductMktOptions = this.actualElement['formCreator'].controls['id_product_mkt'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterProductMkt(value || '')),
    );

    this.genericCreatorService.idProductMktNoOptions = this.actualElement['formCreator'].controls['id_product_mkt_no'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterProductMktNo(value || '')),
    );

    this.genericCreatorService.idConditionOptions = this.actualElement['formCreator'].controls['id_condition'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterIdCondition(value || '')),
    );

    this.genericCreatorService.conditionInternalRoutingOptions = this.actualElement['formCreator'].controls['condition_internal_routing'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterConditionInternalRouting(value || '')),
    );

    this.genericCreatorService.profilePermissionOptions = this.actualElement['formCreator'].controls['profiles_permissions'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterProfilePermission(value || '')),
    );

    this.genericCreatorService.idFunctionOptions = this.actualElement['formCreator'].controls['id_function'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterIdFunction(value || '')),
    );

    this.genericCreatorService.idConditionBadgeOptions = this.actualElement['formCreator'].controls['id_condition_badge'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterIdConditionBadge(value || '')),
    );

    this.genericCreatorService.idQueryOptions = this.actualElement['formCreator'].controls['id_query'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterIdQuery(value || '')),
    );


    // Add new item to the Data Validity expansion
     if(this.actualElement.excluding_data_type_fa_restrictions){
       for(let item in this.actualElement['excluding_data_type_fa_restrictions']){
        this.actualElement['excluding_data_type_fa_restrictions'][item]['excludingFaRestrictionForm'] = this.genericCreatorService.formDataValidity();
        this.suscribeDataValidityForm(this.actualElement['excluding_data_type_fa_restrictions'][item]);
      }
     }

     // Add new item to the Restriction expansion
     if(this.actualElement.functional_area_restrictions){
       for(let item in this.actualElement['functional_area_restrictions']){
        this.actualElement['functional_area_restrictions'][item]['restrictionForm'] = this.genericCreatorService.formRestriction();
         this.suscribeRestrictionForm(this.actualElement['functional_area_restrictions'][item]);
       }
     }
     console.log('actualElement>>>>', this.actualElement)
     this.initialized = true;
  }

  onToggle(event: any){
    if(event.checked == true){
      console.log('in true');
      this.actualElement['formCreator'].get('label').clearValidators();
      this.genericCreatorService.actualSelectedElement['child'] = [];
      this.actualElement['child'] = [];
    } else {
      this.actualElement['formCreator'].get('label').setValidators(Validators.required);
    }
    this.actualElement['formCreator'].get('label').updateValueAndValidity();
    console.log('==>', this.actualElement['formCreator'])
  }

  suscribeDataValidityForm(obj) {
    obj['excludingFaRestrictionForm'].valueChanges.subscribe(value => {
      value['id_data_type'] = value['id_data_type']['id_data_type'];
      this.genericCreatorService.formValueSubscribe(value, obj);
      this.genericCreatorService.filteredOptions = obj['excludingFaRestrictionForm'].controls['id_data_type'].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.genericCreatorService._filter(value || '')),
      );

       this.genericCreatorService.companyTypeOptions = obj['excludingFaRestrictionForm'].controls['companyTypes'].valueChanges.pipe(
         startWith(''),
         map((value: any) => this.genericCreatorService._filterCompanyTypes(value || '')),
       );
    })
  }

  suscribeRestrictionForm(obj) {
    obj['restrictionForm'].valueChanges.subscribe(value => {
      this.genericCreatorService.formValueSubscribe(value, obj);
    })
    this.genericCreatorService.companyTypeOptionsRestriction = obj['restrictionForm'].controls['companyTypes'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterCompanyTypes(value || '')),
    );
  }


  drop(event: CdkDragDrop<object[]>) {
    console.log('caleld', event.currentIndex, event.previousIndex);
    let positionsMoved = event.currentIndex - event.previousIndex
    moveItemInArray(this.actualElement.child, event.previousIndex, event.currentIndex);
    this.actualElement.child.forEach((item, index) => {
      item['order_general'] = index
      if(item['mode_type'] == undefined) {
        item['mode_type'] = 'update';
      }
    })
  }

  /**
   * Option param id_functional_type, will be there in case of card sub element only
   * @param id_functional_type 
   */
  addNewItem(id_functional_type?:any){
    console.log('this.genericCreatorService.actualSelectedElement**1', this.genericCreatorService.actualSelectedElement)
    let newObj = {}
    newObj = _.cloneDeep(this.genericCreatorService.defaultArray);
    this.genericCreatorService.prepareNewEleToAdd(newObj)
    newObj['id_functional_type'] = id_functional_type ? id_functional_type : this.genericCreatorService.actualSelectedFunctionalTypeChild;
    if(this.genericCreatorService.actualSelectedElement['id_functional_type'] === 11){
      newObj['form_field'] = 1
    } else {
      newObj['form_field'] = 0
    }
    if(this.genericCreatorService.listElementOptionToggle || newObj['id_functional_type'] !== 105 ) newObj['child'] = [];
    this.genericCreatorService.filterTypes(this.genericCreatorService.actualSelectedFunctionalTypeChild);
    this.genericCreatorService.pushNewElementInParentChilds(this.actualElement, newObj)
    this.genericCreatorService.checkLanguageLoop(this.genericCreatorService.arrayElementsGenericCreator, this.genericCreatorService.currentLanguage)
    this.openSettingPopup(newObj)
  }

  addNewCardItem(type: any){
    let id_functional_type: any;
    if(type === 'header'){
      id_functional_type = 114
    } else if (type === 'content'){
      id_functional_type = 115
    } else if (type === 'action'){
      id_functional_type = 116
    } else {
      id_functional_type = 140
    }
    this.addNewItem(id_functional_type);
  }

  openSettingPopup(element){
    console.log(element)
    console.log('this.genericCreatorService.actualSelectedElement**', this.genericCreatorService.actualSelectedElement)
    this.genericCreatorService.isSubElement = true;
    this.genericCreatorService.actualSelectedSubElement = element;
    this.genericCreatorService.filterStatusOptions(element['id_functional_type']);
    this.genericCreatorService.expandedIndex = 1;
    const dialogRef = this.dialog.open(AddGenericElementDialogComponent, {});
    dialogRef.afterClosed().subscribe(added => {
      this.genericCreatorService.isSubElement = false;
      this.genericCreatorService.actualSelectedSubElement = undefined;
      this.genericCreatorService.expandedIndex = 2;
      if(added == undefined || !added) {
      } else {
        this.genericCreatorService.actualSelectedElement['child'].push(this.genericCreatorService.actualSelectedSubElement)
      }
      if(this.genericCreatorService.actualSelectedElement['id_functional_type'] == 100){
        this.isHeaderAdded = this.genericCreatorService.actualSelectedElement['child'].some(e => e.id_functional_type == 114)
        this.isContentAdded = this.genericCreatorService.actualSelectedElement['child'].some(e => e.id_functional_type == 115)
        this.isActionAdded = this.genericCreatorService.actualSelectedElement['child'].some(e => e.id_functional_type == 116)
        this.isDynamicAdded = this.genericCreatorService.actualSelectedElement['child'].some(e => e.id_functional_type == 140)
      } else {
        this.isHeaderAdded = false;
        this.isContentAdded = false;
        this.isActionAdded = false;
        this.isDynamicAdded = false;     
      }
      // if(this.genericCreatorService.listElementOptionToggle == true){
      //   console.log('In clear child condition');
      //   this.genericCreatorService.actualSelectedElement['child'] = [];
      //   this.actualElement['child'] = [];
      // }
   });
  }

  displayFn(item: any){
    return item && item.internal_name ? item.internal_name : '';
  }

  displayRoutingFn(item: any){
    return item && item.internal_routing ? item.internal_routing : '';
  }

  addNewExcludingFaRestrictionObj(){
    let obj = _.cloneDeep(this.genericCreatorService.get_all_columns_excluding_data_type_fa_restrictions);
    obj['excludingFaRestrictionForm'] = this.genericCreatorService.formDataValidity();
    obj['mode_type'] = 'insert';
    this.suscribeDataValidityForm(obj);
    this.actualElement.excluding_data_type_fa_restrictions.push(obj);
  }
  
  removeExcludingFaRestrictionObj(index){
    this.actualElement.excluding_data_type_fa_restrictions[index]['mode_type'] = 'delete'
    console.log(this.actualElement.excluding_data_type_fa_restrictions)    
  }
  removeRestrictionObj(index){
    this.actualElement.functional_area_restrictions[index]['mode_type'] = 'delete'
  }

  addNewRestriction(){
    let obj = _.cloneDeep(this.genericCreatorService.get_all_columns_fa_restrictions);
    obj['restrictionForm'] = this.genericCreatorService.formRestriction();
    obj['mode_type'] = 'insert';
    this.suscribeRestrictionForm(obj);
    this.actualElement.functional_area_restrictions.push(obj)
    console.log('this.actualElement', this.actualElement);
  }

  // Add Company
  selectedCompany(event: MatAutocompleteSelectedEvent, index: any): void {
    this.genericCreatorService.actualSelectedElement['excluding_data_type_fa_restrictions'][index]['excludingFaRestrictionForm'].controls['id_company_type'].value.push(event.option.viewValue);
    this.companyTypeInput.nativeElement.value = '';
    this.genericCreatorService.actualSelectedElement['excluding_data_type_fa_restrictions'][index]['excludingFaRestrictionForm'].controls['companyTypes'].setValue(null);
  }

  // Remove company from added companies
  removeCompany(item: any, itemIndex: any): void {
    const index = this.genericCreatorService.actualSelectedElement['excluding_data_type_fa_restrictions'][itemIndex]['excludingFaRestrictionForm'].controls['id_company_type'].value.indexOf(item);
    if (index >= 0) {
      this.genericCreatorService.actualSelectedElement['excluding_data_type_fa_restrictions'][itemIndex]['excludingFaRestrictionForm'].controls['id_company_type'].value.splice(index, 1);
    }
  }

  // Add Company For Restriction Expansion
  selectedCompanyRestriction(event: MatAutocompleteSelectedEvent, index: any): void {
    this.genericCreatorService.actualSelectedElement['functional_area_restrictions'][index]['restrictionForm'].controls['id_company_type'].value.push(event.option.viewValue);
    this.companyTypeInputRestriction.nativeElement.value = '';
    this.genericCreatorService.actualSelectedElement['functional_area_restrictions'][index]['restrictionForm'].controls['companyTypes'].setValue(null);
  }

  // Remove company from added companies For Restriction Expansion
  removeCompanyRestriction(item: any, itemIndex: any): void {
    const index = this.genericCreatorService.actualSelectedElement['functional_area_restrictions'][itemIndex]['restrictionForm'].controls['selectedCompanyTypes'].value.indexOf(item);
    if (index >= 0) {
      this.genericCreatorService.actualSelectedElement['functional_area_restrictions'][itemIndex]['restrictionForm'].controls['selectedCompanyTypes'].value.splice(index, 1);
    }
  }

    // Add Profile Permission
    selectedProfilePermission(event: MatAutocompleteSelectedEvent, index: any): void {
      this.genericCreatorService.actualSelectedElement['formCreator'].controls['permissions_group_functional_area'].value.push(event.option.viewValue);
      this.profilePermissionInput.nativeElement.value = '';
      this.genericCreatorService.actualSelectedElement['formCreator'].controls['profiles_permissions'].setValue(null);
    }
  
    // Remove Profile Permission
    removeProfilePermission(item: any, itemIndex: any): void {
      const index = this.genericCreatorService.actualSelectedElement['formCreator'].controls['permissions_group_functional_area'].value.indexOf(item);
      if (index >= 0) {
        this.genericCreatorService.actualSelectedElement['formCreator'].controls['permissions_group_functional_area'].value.splice(index, 1);
      }
    }

}
