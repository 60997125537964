<span fxFlex="100" fxLayout="row wrap">
  <div fxFlex="100" style="font-weight: bold; font-size: 16px; text-transform: uppercase; padding-bottom: 1.2em;">Gestiona tus inmuebles compartidos</div>
  <div fxFlex="100" fxLayoutAlign="center start" fxLayout="row wrap" class="admin-container">
  <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
    <span fxFlex="100" style="margin-bottom: -0.6em;">FlatSharing <i class="material-icons">help</i></span>
    <span fxFlex="100">
      <mat-form-field>
        <mat-select [(ngModel)]="colaborationInfo.flatSharing" (ngModelChange)="confirmOperation(6)">
          <mat-option [disabled]="colaborationInfo.flatLending === 1 || colaborationInfo.flatLending === 2 || colaborationInfo.flatLending === 3" [value]="1">Todas</mat-option>
          <mat-option [disabled]="colaborationInfo.flatLending === 1 || colaborationInfo.flatLending === 2" [value]="2">Sólo exclusivas</mat-option>
          <mat-option [disabled]="colaborationInfo.flatLending === 1" [value]="3">Personalizada</mat-option>
          <mat-option [value]="0">Ninguna</mat-option>
        </mat-select>
      </mat-form-field>
      <i *ngIf="colaborationInfo.flatSharing === 3" class="material-icons" (click)="viewPropertyListFlatSharing()">visibility</i>
    </span>
  </span>
  <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
    <span fxFlex="100" style="margin-bottom: -0.6em;">FlatLending <i class="material-icons">help</i></span>
    <span fxFlex="100">
      <mat-form-field>
        <mat-select [(ngModel)]="colaborationInfo.flatLending" (ngModelChange)="confirmOperation(7)">
          <mat-option [disabled]="colaborationInfo.flatSharing === 1 || colaborationInfo.flatSharing === 2 || colaborationInfo.flatSharing === 3" [value]="1">Todas</mat-option>
          <mat-option [disabled]="colaborationInfo.flatSharing === 1 || colaborationInfo.flatSharing === 2" [value]="2">Sólo exclusivas</mat-option>
          <mat-option [disabled]="colaborationInfo.flatSharing === 1" [value]="3">Personalizada</mat-option>
          <mat-option [value]="0">Ninguna</mat-option>
        </mat-select>
      </mat-form-field>
      <i *ngIf="colaborationInfo.flatLending === 3" class="material-icons" (click)="viewPropertyListFlatLending()">visibility</i>
    </span>
  </span>
</div>
</span>
