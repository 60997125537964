<mat-tab-group>
  <mat-tab label="Movins adquiridos">
    <ng-template mat-tab-label>Movins adquiridos</ng-template>
    <ng-template matTabContent>
      <div class="example-container column taulesgrans">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z2 " >

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Fecha compra </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_alta}} </td>
          </ng-container>

          <ng-container matColumnDef="buyer">
            <th mat-header-cell *matHeaderCellDef> Comprador </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
          </ng-container>

          <ng-container matColumnDef="nmovin">
            <th mat-header-cell *matHeaderCellDef> Nº Movins </th>
            <td mat-cell *matCellDef="let element"> {{element.num_comprados}} </td>
          </ng-container>

          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Precio con iva </th>
            <td mat-cell *matCellDef="let element"> {{element.precio+ " €"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headBought"></tr>
          <tr mat-row *matRowDef="let row; columns: headBought;"></tr>

        </table>
        <i *ngIf="planesBoughtArray.length <= 0" style="text-align: center"> No se han encontrado datos </i>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Movins invertidos">
    <ng-template mat-tab-label>Movins invertidos</ng-template>
    <ng-template matTabContent>
      <div class="example-container column taulesgrans">
        <table mat-table [dataSource]="dataSourceBought" class="mat-elevation-z2 " >

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Fecha gasto </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
          </ng-container>

          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> Usuario </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
          </ng-container>

          <ng-container matColumnDef="nmovinSpent">
            <th mat-header-cell *matHeaderCellDef> Movin invertido </th>
            <td mat-cell *matCellDef="let element"> {{element.gasto_movins}} </td>
          </ng-container>

          <ng-container matColumnDef="concept">
            <th mat-header-cell *matHeaderCellDef> Concepto </th>
            <td mat-cell *matCellDef="let element">
              {{element.name}}
              <span *ngIf="element.id_product_type === 1">(Módulo)</span>
              <span *ngIf="element.id_product_type === 2">(Widget)</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headUsed"></tr>
          <tr mat-row *matRowDef="let row; columns: headUsed;"></tr>
        </table>
        <i *ngIf="planesBoughtArray.length <= 0" style="text-align: center"> No se han encontrado datos </i>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
