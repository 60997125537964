<ng-container *ngIf="actualElement !== undefined && actualElement !== null && this.actualElement['formCreator'] !== undefined">
    <form [formGroup]="this.actualElement['formCreator']" class="formGeneralJourneys">
        <div class="add_nodes">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="name" #nameAction maxlength="100">
                <mat-hint align="end">{{nameAction.value?.length || 0}}/100</mat-hint>                        
            </mat-form-field>
            
            <mat-form-field appearance="outline">
                <mat-label>Notas</mat-label>
                <textarea matInput formControlName="notes"></textarea>
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>Action</mat-label>
                <mat-select formControlName="id_type_action">
                    <ng-container *ngIf="this.actualElement['id_category'] != 2">
                        <mat-option *ngFor="let action of journeyService.actionTypes" [value]="action.id">
                            <span class="material-icons-outlined">{{action.icon}}</span> {{action.name}}
                        </mat-option>
                    </ng-container>
                    <ng-container *ngIf="this.actualElement['id_category'] == 2">
                        <mat-option *ngFor="let action of journeyService.triggerTypes" [value]="action.id">
                            <span class="material-icons-outlined">{{action.icon}}</span> {{action.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-container *ngIf="showExtraInfo">
            <div class="autocomplete_div" *ngIf="autoComplete">
                <div class="form-group" *ngIf="type != 5">
                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_template'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_template" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.nombre">
                                <span class="">{{item.icon}}</span> {{item.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_tag'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_tag" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.nombre">
                                {{item.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_action_new'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_action_new" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.name">
                                {{item.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>
                </div>
                
            
                <div class="form-group" *ngIf="type == 5" style="display: flex;">
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Temporalidad</mat-label>
                        <input matInput type="number" placeholder="Enter time to wait" formControlName="num_temporality" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Unidad de tiempo</mat-label>
                        <mat-select formControlName="id_temporality" required>
                            <mat-option *ngFor="let time of this.journeyService.temporalities" [value]="time.id_temporality">
                                {{time.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            
            </div>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="end">
            <div class="generic-buttons-blue" style="margin-right: 10px;">
                <button mat-raised-button (click)="cancelElement()">Cancelar</button>
            </div>
            <div class="generic-buttons-blue-revert">
                <button mat-raised-button (click)="submitElement()">Añadir</button>
            </div>
        </div>
    </form>
</ng-container>



<!--

<div class="delete_options" *ngIf="popup == 'delete'">
    <div class="delete-dialog-content">
        <h2>Delete Confirmation</h2>
        <p>
            Are you sure you want to delete this? If you delete this
            if/else branch we need to know<br /> what you wish
            to do with all the actions that are within the if/else.
        </p>
        <mat-radio-group [(ngModel)]="selectedOption" aria-label="Select an option">
            <mat-radio-button value="2">Remove both the "Yes" and the "No" paths</mat-radio-button><br />
            <mat-radio-button value="0" *ngIf="keepOnlyYes">Keep only the "Yes" path</mat-radio-button><br />
            <mat-radio-button value="1" *ngIf="keepOnlyNo">Keep only the "No" path</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="delete-dialog-actions">
        <button class="cancel" [mat-dialog-close]>Cancel</button>
        <button class="OK" [mat-dialog-close]="selectedOption">OK</button>
    </div>
</div>




-->