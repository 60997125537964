import { Component, Inject, HostListener, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {CustomEvent} from '../objects/custom-event.component';

@Component({
  selector: 'view-sign-dialog',
  templateUrl: './view-sign-dialog.html'
})

export class ViewSignDialog {

  event : CustomEvent;
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.event = data.event;
  }

  onNoClick(): void {
    this.dialog.closeAll();
  }


}
