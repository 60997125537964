<div>
  <!--    SEARCH USER   -->
  <div *ngIf="type == 1">
    <div class="example-container taulesgrans" style="overflow: auto; height: calc(65vh - 59px);  min-width: 30vw;">
      <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef> <i class="material-icons">add</i> </th>
          <td mat-cell *matCellDef="let element; let index = index" class="buttons-body"
            (click)="element.check = !element.check">
            <mat-checkbox [(ngModel)]="element.check" (click)="$event.stopPropagation()"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.user}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsType1; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsType1;" style="cursor: pointer;"></tr>
      </table>
    </div>
  </div>

  <!---   SEARCH OFFER    -->
  <div *ngIf="type == 2">
    <div class="example-container taulesgrans" style="overflow: auto; height: calc(65vh - 59px);  min-width: 30vw;">
      <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef> <i class="material-icons">add</i> </th>
          <td mat-cell *matCellDef="let element; let index = index" class="buttons-body" (click)="setChecked(element)">
            <mat-checkbox [(ngModel)]="element.check" (click)="$event.stopPropagation()" [name]="element.id"
              (change)="onChange(element.id)" [disabled]="isDisabled(element)"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="offerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
          <td mat-cell *matCellDef="let element" (click)="setChecked(element)">{{element.offer}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsType2; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsType2;" style="cursor: pointer;"></tr>
      </table>
    </div>
  </div>
  <!--    SEARCH CLIENT   -->
  <div *ngIf="type == 3">
    <div class="example-container taulesgrans" style="overflow: auto; height: calc(65vh - 59px);  min-width: 30vw;">
      <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef> <i class="material-icons">add</i> </th>
          <td mat-cell *matCellDef="let element; let index = index" class="buttons-body"
            (click)="element.check = !element.check">
            <mat-checkbox [(ngModel)]="element.check" (click)="$event.stopPropagation()"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.clientName}}</td>
        </ng-container>

        <ng-container matColumnDef="eMail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.eMail}}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Teléfono</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.phone}}</td>
        </ng-container>

        <ng-container matColumnDef="languageName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Idioma</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.languageName}}</td>
        </ng-container>

        <ng-container matColumnDef="clientTypeNames">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">
            <div *ngFor="let clientTypeName of element.clientTypeNames">
              {{clientTypeName}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="categoryNames">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">
            <div *ngFor="let categoryName of element.categoryNames">
              {{categoryName}}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsType3; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsType3;" style="cursor: pointer;"></tr>
      </table>
    </div>
  </div>

  <!---   SEARCH PROPERTIES    -->
  <div *ngIf="type == 4">
    <div class="example-container taulesgrans" style="overflow: auto; max-height: calc(65vh - 59px);  min-width: 30vw;">
      <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef> <i class="material-icons">add</i> </th>
          <td mat-cell *matCellDef="let element; let index = index" class="buttons-body"
            (click)="element.check = !element.check">
            <mat-checkbox *ngIf="subtype === 1" [(ngModel)]="element.check" (click)="$event.stopPropagation()">
            </mat-checkbox>
            <mat-checkbox *ngIf="subtype === 2" [disabled]="(element.disabled)" [(ngModel)]="element.check"
              (click)="$event.stopPropagation()"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="internalName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.INTERNALNAME}}</th>
          <td mat-cell *matCellDef="let element" (click)="check(element)">{{element.internalName}}</td>
        </ng-container>

        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.REFERENCE}}</th>
          <td mat-cell *matCellDef="let element" (click)="check(element)">{{element.reference}}</td>
        </ng-container>

        <ng-container matColumnDef="houseTypeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.HOUSINGTYPE}}</th>
          <td mat-cell *matCellDef="let element" (click)="check(element)">{{element.houseSubTypeName}}</td>
        </ng-container>

        <ng-container matColumnDef="qualification">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.QUALIFICATION}}</th>
          <td mat-cell *matCellDef="let element" (click)="check(element)">{{element.qualification}}</td>
        </ng-container>

        <ng-container matColumnDef="locationName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.POPULATION}}</th>
          <td mat-cell *matCellDef="let element" class="two-items" (click)="check(element)">{{element.locationName}}<div
              *ngIf="element.zoneName"> ({{element.zoneName}})</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="operationTypeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.TYPEOPERATION}}</th>
          <td mat-cell *matCellDef="let element" (click)="check(element)">{{element.operationTypeName}}</td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.PRICE}}</th>
          <td mat-cell *matCellDef="let element" (click)="check(element)">{{element.price}}</td>
        </ng-container>

        <ng-container matColumnDef="statusName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{stringsProperties.STATUS}}</th>
          <td mat-cell *matCellDef="let element" class="two-items" (click)="check(element)">{{element.statusName}}<div
              *ngIf="element.substatusName"> ({{element.substatusName}})</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
          </th>
          <td (click)="expandedElement = expandedElement === element ? null : element" mat-cell
            *matCellDef="let element; let index = index">
            <i class="material-icons">info</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsType4.length">
            <div class="element-detail" style="display: flex;"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div *ngIf="element.image" style="width: 18%;">
                <img style="width: 100%; padding: 3%; border-radius: 9px; height: 200px; object-fit: cover;"
                  [src]="element.image" />
              </div>
              <div *ngIf="!element.image" style="width: 18%;">
                <img style="width: 100%; padding: 3%; border-radius: 9px; height: 200px; object-fit: cover;"
                  [src]="defaultImg" />
              </div>
              <div fxFlex="100" fxLayout="row wrap" style="padding: 10px;">
                <div fxFlex="100" fxLayout="row wrap">
                  <div *ngIf="element.address" style="width: 90%;" matTooltip="Dirección"><i
                      class="material-icons">room</i> {{element.address}}</div>
                  <div *ngIf="!element.address" style="width: 90%;" matTooltip="No hay datos de la dirección"><i
                      class="material-icons">room</i> La dirección no esta definida</div>
                  <div *ngIf="element.commercialManager" style="width: 10%; text-align: right">
                    {{element.commercialManager}} <i matTooltip="Gestor" class="material-icons">work</i></div>
                  <div *ngIf="!element.commercialManager" style="width: 10%; text-align: right">
                    <p><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                        matTooltip="No hay datos del gestor" class="material-icons">work</i></p>
                  </div>
                </div>
                <div fxLayout="row wrap" fxFlex="100" style="padding: 10px;">
                  <div class="detail-contents1" fxFlex="20">
                    <div>
                      <p *ngIf="element.m2" matTooltip="Superfície">{{element.m2}} m2</p>
                      <p *ngIf="!element.m2" matTooltip="No hay datos de la superfície"><i class="material-icons"
                          style="color: #e0e0e0;">fiber_manual_record</i> m2</p>
                    </div>
                    <div>
                      <p *ngIf="element.price" matTooltip="Precio">{{element.price}} €</p>
                      <p *ngIf="!element.price" matTooltip="No hay datos del precio"><i class="material-icons"
                          style="color: #e0e0e0;">fiber_manual_record</i> €</p>
                    </div>
                    <div>
                      <p *ngIf="element.rooms" matTooltip="Número de habitaciones">{{element.rooms}} <i
                          class="material-icons">hotel</i></p>
                      <p *ngIf="!element.rooms" matTooltip="No hay datos de las habitaciones"><i class="material-icons"
                          style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">hotel</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.bathrooms" matTooltip="Número de baños">{{element.bathrooms}} <i
                          class="material-icons">bathtub</i></p>
                      <p *ngIf="!element.bathrooms" matTooltip="No hay datos de los baños"><i class="material-icons"
                          style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">bathtub</i></p>
                    </div>
                  </div>

                  <div class="detail-contents1" fxFlex="20">
                    <div>
                      <p *ngIf="element.terraces" matTooltip="Nº terrazas y metros quadrados">{{element.terraces}}
                        Terrazas - {{element.m2Terraces}} m2</p>
                      <p *ngIf="element.terraces == false" matTooltip="Hay 0 terrazas">{{element.terraces}} Terrazas</p>
                      <p *ngIf="element.terraces == null" matTooltip="No hay datos sobre las terrazas"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> Terrazas</p>
                    </div>
                    <div>
                      <p *ngIf="element.balconies" matTooltip="Nº balcones y metros quadrados">{{element.balconies}}
                        Balcones - {{element.m2Balconies}} m2</p>
                      <p *ngIf="element.balconies == false" matTooltip="Hay 0 balcones">{{element.balconies}} Balcones
                      </p>
                      <p *ngIf="element.balconies == null" matTooltip="No hay datos sobre los balcones"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> Balcones</p>
                    </div>
                    <div>
                      <p *ngIf="element.m2LivingRoom" matTooltip="Metros quadrados del salón">{{element.m2LivingRoom}}
                        m2 Salón</p>
                      <p *ngIf="!element.m2LivingRoom" matTooltip="No hay datos sobre los metros quadrados del salón"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> m2 Salón</p>
                    </div>
                    <div>
                      <p *ngIf="element.hasOfficeKitchen" matTooltip="La cocina es tipo office"><i
                          class="material-icons" style="color: #17c33e;">fiber_manual_record</i> Cocina office</p>
                      <p *ngIf="element.hasOfficeKitchen == false" matTooltip="La cocina no es tipo office"><i
                          class="material-icons" style="color: #dc3544;">fiber_manual_record</i> Cocina office</p>
                      <p *ngIf="element.hasOfficeKitchen == null"
                        matTooltip="No se ha indicado si la cocina es tipo office"><i class="material-icons"
                          style="color: #e0e0e0;">fiber_manual_record</i> Cocina office</p>
                    </div>
                  </div>

                  <div class="detail-contents1" fxFlex="20">
                    <div>
                      <p *ngIf="element.hasHeating" matTooltip="Hay calefacción"><i class="material-icons"
                          style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">fireplace</i></p>
                      <p *ngIf="element.hasHeating == false" matTooltip="No hay calefacción"><i class="material-icons"
                          style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">fireplace</i></p>
                      <p *ngIf="element.hasHeating == null" matTooltip="No se ha indicado si hay calefacción"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">fireplace</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasAirConditioning" matTooltip="Hay aire acondicionado"><i
                          class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i
                          class="material-icons">toys</i></p>
                      <p *ngIf="element.hasAirConditioning == false" matTooltip="No hay aire acondicionado"><i
                          class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i
                          class="material-icons">toys</i></p>
                      <p *ngIf="element.hasAirConditioning == null"
                        matTooltip="No se ha indicado si hay aire acondicionado"><i class="material-icons"
                          style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">toys</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasElevator" matTooltip="Hay ascensor"><i class="material-icons"
                          style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">unfold_more</i></p>
                      <p *ngIf="element.hasElevator == false" matTooltip="No hay ascensor"><i class="material-icons"
                          style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">unfold_more</i></p>
                      <p *ngIf="element.hasElevator == null" matTooltip="No se ha indicado si hay ascensor"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">unfold_more</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasFurniture" matTooltip="Esta amueblado"><i class="material-icons"
                          style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">kitchen</i></p>
                      <p *ngIf="element.hasFurniture == false" matTooltip="No esta amueblado"><i class="material-icons"
                          style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">kitchen</i></p>
                      <p *ngIf="element.hasFurniture == null" matTooltip="No se ha indicado si esta amueblado"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">kitchen</i></p>
                    </div>
                  </div>

                  <div class="detail-contents1" fxFlex="20">
                    <div>
                      <p *ngIf="element.orientation" matTooltip="Información sobre la orientación del inmueble">
                        {{element.orientation}} <i class="material-icons">explore</i></p>
                      <p *ngIf="!element.orientation" matTooltip="No hay datos sobre la orientación"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">explore</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasAirSwimmingPol" matTooltip="Hay piscina"><i class="material-icons"
                          style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">pool</i></p>
                      <p *ngIf="element.hasAirSwimmingPol == false" matTooltip="No hay piscina"><i
                          class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i
                          class="material-icons">pool</i></p>
                      <p *ngIf="element.hasAirSwimmingPol == null" matTooltip="No se ha indicado si hay piscina"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">pool</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasCommonArea" matTooltip="Hay zona comunitaria"><i class="material-icons"
                          style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">sports_tennis</i>
                      </p>
                      <p *ngIf="element.hasCommonArea == false" matTooltip="No hay zona comunitaria"><i
                          class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i
                          class="material-icons">sports_tennis</i></p>
                      <p *ngIf="element.hasCommonArea == null" matTooltip="No se ha indicado si hay zona comunitaria"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">sports_tennis</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.parkings" matTooltip="Hay parking"><i class="material-icons"
                          style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">directions_car</i>
                      </p>
                      <p *ngIf="element.parkings == false" matTooltip="No hay parking"><i class="material-icons"
                          style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">directions_car</i>
                      </p>
                      <p *ngIf="element.parkings == null" matTooltip="No se ha indicado si hay parking"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">directions_car</i></p>
                    </div>
                  </div>

                  <div class="detail-contents1" fxFlex="20">
                    <div>
                      <p *ngIf="element.ownerName" matTooltip="Nombre del propietario">{{element.ownerName}} <i
                          class="material-icons">person</i></p>
                      <p *ngIf="!element.ownerName" matTooltip="No hay datos sobre el nombre del propietario"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">person</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.ownerPhone" matTooltip="Teléfono del propietario">{{element.ownerPhone}} <i
                          class="material-icons">phone</i></p>
                      <p *ngIf="!element.ownerPhone" matTooltip="No hay datos sobre el teléfono del propietario"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">phone</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.ownerEmail" matTooltip="Email del propietario">{{element.ownerEmail}} <i
                          class="material-icons">email</i></p>
                      <p *ngIf="!element.ownerEmail" matTooltip="No hay datos sobre el email del propietario"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">email</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasExclusive" matTooltip="Esta en exclusiva"><i class="material-icons"
                          style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">vpn_key</i></p>
                      <p *ngIf="element.hasExclusive == false" matTooltip="No esta en exclusiva"><i
                          class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i
                          class="material-icons">vpn_key</i></p>
                      <p *ngIf="element.hasExclusive == null" matTooltip="No se ha indicado si esta en exclusiva"><i
                          class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i
                          class="material-icons">vpn_key</i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsType4; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumnsType4;" class="element-row"
          [class.expanded-row]="expandedElement === element" style="cursor: pointer;">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </div>

  <!--    SEARCH INDUSTRIALES   -->
  <div *ngIf="type == 5">
    <div class="example-container taulesgrans" style="overflow: auto; height: calc(65vh - 59px); min-width: 30vw;">
      <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef> <i class="material-icons">add</i> </th>
          <td mat-cell *matCellDef="let element; let index = index" class="buttons-body"
            (click)="element.check = !element.check">
            <mat-checkbox [(ngModel)]="element.check" (click)="$event.stopPropagation()"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.clientName}}</td>
        </ng-container>


        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.clientName}}</td>
        </ng-container>


        <ng-container matColumnDef="eMail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.eMail}}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Teléfono</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.phone}}</td>
        </ng-container>

        <ng-container matColumnDef="languageName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Idioma</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.languageName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="clientTypeNames">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">
            <div *ngFor="let clientTypeName of element.clientTypeNames">
              {{clientTypeName}}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsType5; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsType5;" style="cursor: pointer;"></tr>
      </table>
    </div>
  </div>

  <!--    SEARCH ENCARGOS   -->
  <div *ngIf="type == 6">
    <div class="example-container taulesgrans" style="overflow: auto; height: calc(65vh - 59px);  min-width: 30vw;">
      <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef> <i class="material-icons">add</i> </th>
          <td mat-cell *matCellDef="let element; let index = index" class="buttons-body"
            (click)="element.check = !element.check">
            <mat-checkbox [(ngModel)]="element.check" (click)="$event.stopPropagation()"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.code}}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.encargoDate}}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.type}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
          <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.state}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsType6; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsType6;" style="cursor: pointer;"></tr>
      </table>
    </div>
  </div>


  

  <div class="bottom-table" fxLayout="row wrap">
    <div class="bottom-table2" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <mat-form-field class="input-filter">
        <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
      </mat-form-field>
    </div>
    <mat-paginator fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" [pageSizeOptions]="[50,100,200]"></mat-paginator>
  </div>


  <div class="addBtn generic-buttons-blue-revert" *ngIf="subtype === 1">
    <button mat-button (click)="addElement()">
      <i style="margin-right: 0.3em; font-size: 15px !important;" class="material-icons">add</i>
      {{this.label}}
    </button>
  </div>

  <div class="addBtn generic-buttons-blue-revert" *ngIf="subtype === 2" style="display: flex;">
    <button *ngIf="extraData !== undefined && extraData.quickEdit === false" mat-button (click)="addElement()">
      Finalizar
    </button>

    <button *ngIf="extraData !== undefined && extraData.quickEdit === true" mat-button (click)="quickEditFunction()">
      Finalizar
    </button>

    <button mat-button (click)="closeDialog()">
      Cancelar
    </button>
  </div>

</div>


<span [ngClass]="{'loader-spinner0':true, 'loader-spinner0-out':loaded}">
  <mat-progress-spinner class="loader-spinner" [color]="'primary'" [value]="75" [mode]="'indeterminate'"
    [diameter]="120" [strokeWidth]="15">
  </mat-progress-spinner>
</span>