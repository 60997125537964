<form
  [formGroup]="structure['form']"
  (ngSubmit)="functionsService.getFunction(structure[index], structure['form'])"
  ngNativeValidate
  [id]="formId"
  #documentEditForm="ngForm"
  *ngIf="structure['child']">
    <app-generic fxLayout="row wrap" [structure]="structure"></app-generic>
</form>

<!-- el disabled funciona. mirar de hacerlo en el button.generic
<button class="btn-save button primary" [attr.form]="formId" [disabled]="!documentEditForm.form.valid">
  SAVE {{formId}}
</button>
-->
