<span [formGroup]="genericForm" class="fix-class-slide-toggle">
  <ng-container *ngIf="structure[this.index]['type'] === 'checkbox'">
    <mat-checkbox
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + structure[this.index]['bd_field'] }}"
      [disabled]="this.structure[this.index]['id_functional_status_general'] === 3"
      [required]="this.structure[this.index]['id_functional_status_general'] === 5"
      (change)="updateValue($event.checked)"
      class="fix-class-checkbox"
      [ngClass]="{'own-required':true, 'own-required-invalid':this.structure[this.index].invalid }"
    >
      <span *ngIf="!notOwner" style="font-weight: 500; white-space: break-spaces;" [innerHTML]="structure[this.index]['label'] | safeHtml">
      </span>
      <span *ngIf="notOwner" style="font-weight: 500; white-space: break-spaces;" [innerHTML]="structure[this.index]['label'] | safeHtml">
         (?)
      </span>
      <span class="required-icon" *ngIf="this.structure[this.index]['id_functional_status_general'] === 5"> *</span>
    </mat-checkbox>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'slide'">
    <mat-slide-toggle
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + structure[this.index]['bd_field'] }}"
      [(ngModel)]="value"
      [disabled]="this.structure[this.index]['id_functional_status_general'] === 3"
      [required]="this.structure[this.index]['id_functional_status_general'] === 5"
      [color]="structure['class']"
      (change)="updateValue($event.checked)"
      [ngClass]="{'own-required':true, 'own-required-invalid':this.structure[this.index].invalid }"
    >
      <span *ngIf="!notOwner" style="font-weight: 500; white-space: break-spaces;" [innerHTML]="structure[this.index]['label'] | safeHtml">
      </span>
      <span *ngIf="notOwner" style="font-weight: 500; white-space: break-spaces;" [innerHTML]="structure[this.index]['label'] | safeHtml">
         (?)
      </span>
      <span class="required-icon" *ngIf="this.structure[this.index]['id_functional_status_general'] === 5"> *</span>
    </mat-slide-toggle>
  </ng-container>

    <i *ngIf="structure[index]['icon']" class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
        structure[index]['icon_type']=='Filled' ? 'material-icons' :
        structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
        structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
        structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}" (click)="iconClick(); $event.stopPropagation()"> {{ structure[index]['icon'] }} </i>
</span>
