import {Component, OnInit, ViewChild, Inject} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Strings} from "../../../../strings/strings";
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import {MonitoringActions, MonitoringDecorator} from "../../../services/track-decorators";
import {PrivilegiesControlService} from "../../../services/privilegies-control/privilegies-control.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SimpleProperty} from "../../objects/simple-property";
import {SimpleClient} from "../../objects/simple-client";
import {SimpleOffer} from "../../objects/simple-offer";
import {SimpleUser} from "../../objects/simple-user";
import {animate, state, style, transition, trigger} from '@angular/animations';


import {MessageDialogComponent} from "../../../utils/message-dialog/message-dialog.component";
import { SimpleEncargo } from 'app/activities/objects/simple-encargo';



@Component({
  selector: 'app-add-element-event',
  templateUrl: './add-element-event.component.html',
  styleUrls: ['./add-element-event.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AddElementEventComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  public stringsProperties = Strings.SEARCHFLATS;

  //Este componente buscara en funcion del type
  
  public dataSource: MatTableDataSource<any>;

  // Definimos las diferentes displayedColumnsType
  public displayedColumnsType1: string[] = ['buttons', 'userName']; // Search User
  public displayedColumnsType2: string[] = ['buttons', 'offerName']; // Search Offer
  public displayedColumnsType3: string[] = ['buttons', 'clientName', 'eMail', 'phone', 'languageName', 'clientTypeNames', 'categoryNames']; // Search Clients
  public displayedColumnsType4: string[] = ['buttons', 'internalName', 'reference', 'houseTypeName', 'qualification', 'locationName', 'operationTypeName', 'price', 'statusName', 'expand']; // Search Property
  public displayedColumnsType5: string[] = ['buttons', 'clientName', 'company', 'eMail', 'phone', 'languageName', 'clientTypeNames']; // Search Industriales
  public displayedColumnsType6: string[] = ['buttons', 'code', 'date', 'type', 'state']; // Search Encargo


  // Definimos las diferentes displayedColumnsMb
  public displayedColumnsMb1: string[] = ['userName'];
  public displayedColumnsMb2: string[] = ['offerName'];
  public displayedColumnsMb3: string[] = ['clientName'];
  public displayedColumnsMb4: string[] = ['reference'];

  public expandedElement: any | null;

  public inputFilter: string;

  public loaded: boolean;

  public account: any;
  public agenda: boolean;

  private isChecked: boolean;
  private isCheckedId: number;
  
  // Definimos dataInformation, es el cuerpo con la informacion
  public dataInformation: any;
  // Definimos type, nos dará el tipo a mostrar
  public type: number;
  // Definimos subtype, algunos tipos tienen subtipos en los mismos, otros no
  // El subtype conceptualmente representa el lugar des de donde se llama al componente
  // Cada type puede ser llamado des de distintos componentes, por convenio diremos que subtype = 1 es que viene de agenda
  public subtype: number;

  // Definimos extraData como una estructura auxiliar, un map donde guardaremos informacion extra que necesita cada caso, sera especifica para cada caso
  public extraData: any;
  
  public label = "Añadir";

  constructor(private endpointService: EndpointService,
    private authService: AuthService,
    private privilegiesControlService: PrivilegiesControlService,
    public dialog: MatDialog,
    public deviceService: DeviceDetectorService,
    private dialogRef: MatDialogRef<AddElementEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dataInformation = data.data;
      this.type = data.type;
      this.subtype = data.subtype;
      this.account = data.account;
      this.extraData = data.extraData;
    }
  ngOnInit(): void {
    this.inputFilter = '';
    this.voidMethodForMonitoring(null);

    switch (this.type) {
      case 1:
        // Search User
        this.loadUsers();
        this.label = "Añadir participantes";
        break;
      case 2:
        // Search Offer
        this.loadOffer();
        this.label = "Añadir ofertas";
        break;
      case 3:
        // Search Client
        this.loadClient();
        this.label = "Añadir clientes";
        break;
      case 4:
        // Search Property
        this.loadProperty();
        this.label = "Añadir inmuebles";
        break;
      case 5:
        // Search Industriales
        this.loadIndustriales();
        this.label = "Añadir industriales";
        break;
      case 6:
        // Search Encargos
        this.loadEncargos();
        this.label = "Añadir encargos";
        break;
    }

  }

  @MonitoringDecorator(MonitoringActions.VIEW, Strings.USERTRACKVIEWCODE.PROPERTIES_SEARCH)
  private voidMethodForMonitoring(id) {
    return id;
  }

  ngOnDestroy() {}


  // SEARCH USER FUNCTIONS
  private loadUsers(){
    let globalArray = [];
    this.endpointService.getAllFullNamesAndIdsByEmpresa(this.account.idEmpresa).subscribe(
      data => {
        Object.keys(data['response']).forEach(user => {
          let check = false;
          let id = data['response'][user]['id'];
          let usr = data['response'][user]['user'];

          if(this.dataInformation.value) {
            for (let i = 0; i < this.dataInformation.value.length; i++) {
              if(this.dataInformation.value[i].id === id) { check = true; }
            }
          }

          let arr = {id: id, user: usr, check: check};
          if(arr.id !== this.authService.userId) {
            globalArray.push(arr);
          }
        });
        this.dataSource = new MatTableDataSource(globalArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.applyFilter(this.inputFilter);
        this.loaded = true;
      });
  }

  
  // SEARCH OFFER FUNCTIONS
  private loadOffer(){
    let globalArray = [];
    this.endpointService.getOffersByIdEmpresa(this.authService.empresaId, this.authService.userId).subscribe(
      data => {
        Object.keys(data['response']).forEach(user => {
          let check = false;
          let id = data['response'][user]['id'];
          let offer = data['response'][user]['offer'];
          if(this.dataInformation.value) {
            if (this.dataInformation.value[0].id === id) {
              this.onChange(id);
              check = true;
            }
          }
          let arr = {id: id, offer: offer, check: check};
          globalArray.push(arr);
        });
      this.dataSource = new MatTableDataSource(globalArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
      this.loaded = true;
    });
  }

  
  // SEARCH CLIENT FUNCTIONS
  private loadClient(){
    let globalArray = [];
    this.endpointService.filterClients(this.account.idEmpresa, false, this.authService.userId).subscribe(data => {
        globalArray = data['response'].map( d => {
        let check = false;
        if(this.dataInformation.value) {
          for (let i = 0; i < this.dataInformation.value.length; i++) {
            if(this.dataInformation.value[i].id === d.clientId) { check = true; }
          }
        }
        d.check = check;
        return d;
      });
      this.dataSource = new MatTableDataSource(globalArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
      this.loaded = true;
    });
  }


  // SEARCH PROPERTY FUNCTIONS
  private loadProperty(){
    let globalArray = [];
    switch(this.subtype){
      case 1:
        this.endpointService.filterFlats(this.authService.empresaId, this.authService.userId, this.authService.languageId).subscribe(data => {
            globalArray = data['response'].map( d => {
              let check = false;
              if(this.dataInformation.value) {
                for (let i = 0; i < this.dataInformation.value.length; i++) {
                  if(this.dataInformation.value[i].id === d.propertyId) { check = true; }
                }
              }
              d.check = check;
              return d;
            });
          this.dataSource = new MatTableDataSource(globalArray);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.applyFilter(this.inputFilter);
          this.loaded = true;
        });
        break;
      case 2:
        this.endpointService.filterFlatsColaboration(this.authService.empresaId,
          this.authService.userId,
          this.privilegiesControlService.getPropertyAccessPermission(),
          this.authService.profileId,
          !this.deviceService.isDesktop(),
          this.authService.languageId).subscribe(data => {
          globalArray = data['response'].map( d => {
            let check = false;
            let disabled = false;
            if(this.dataInformation) {
              for (let i = 0; i < this.dataInformation.length; i++) {
                if((this.dataInformation[i].id === d.propertyId)) { check = true; }
              }
            }

            if ((this.extraData.flatSharing === true && d.tipo_colaboracion === 2) ||
              this.extraData.flatLending === true && d.tipo_colaboracion === 1) {
              disabled = true;
            }

            d.check = check;
            d.disabled = disabled;
            return d;
          });
          this.dataSource = new MatTableDataSource(globalArray);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.applyFilter(this.inputFilter);
          this.loaded = true;
        });
        break;
    }
  }


  // SEARCH INDUSTRIALES FUNCTIONS
  private loadIndustriales(){
    let globalArray = [];
    this.endpointService.filterClients(this.account.idEmpresa, true, this.authService.userId).subscribe(data => {
      globalArray = data['response'].map( d => {
        let check = false;
        if(this.dataInformation.value) {
          for (let i = 0; i < this.dataInformation.value.length; i++) {
            if(this.dataInformation.value[i].id === d.clientId) { check = true; }
          }
        }
        d.check = check;
        return d;
      });
      this.dataSource = new MatTableDataSource(globalArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
      this.loaded = true;
    });
  }


  // SEARCH ENCARGOS FUNCTIONS
  private loadEncargos(){
    let globalArray = [];
    this.endpointService.filterEncargos(this.account.idEmpresa, this.account.idUser).subscribe(data => {
        globalArray = data['response'].map( d => {
        let check = false;
        if(this.dataInformation.value) {
          for (let i = 0; i < this.dataInformation.value.length; i++) {
            if(this.dataInformation.value[i].id === d.encargoId) { check = true; }
          }
        }
        d.check = check;
        return d;
      });
      this.dataSource = new MatTableDataSource(globalArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
      this.loaded = true;
    });
  }

  private addElement(): void {
    // Cada type retorna la seva estructura
    let arr;
    switch(this.type){
      case 1:
        arr = this.dataSource.filteredData.filter(e => e.check === true);
        let users: SimpleUser[] = [];
        arr.forEach(a => {
          let us = new SimpleUser(a.id, a.user);
          users.push(us);
        });
        this.dialogRef.close(users);
        break;
      case 2:
        arr = this.dataSource.filteredData.filter(e => e.check === true);
        let off: SimpleOffer[] = [];
        arr.forEach(a => {
          let o = new SimpleOffer(a.id, a.offer);
          off.push(o);
        });
        this.dialogRef.close(off);
        break;
      case 3:
        arr = this.dataSource.filteredData.filter(e => e.check === true);
        let cli: SimpleClient[] = [];
        arr.forEach(a => {
          let pr = new SimpleClient(a.clientId, a.clientName);
          cli.push(pr);
        });
        this.dialogRef.close(cli);
        break;
      case 4:
        arr = this.dataSource.filteredData.filter(e => e.check === true);
        let prop: SimpleProperty[] = [];
        arr.forEach(a => {
          let pr = new SimpleProperty(a.propertyId, a.reference, a.price, a.internalName);
          prop.push(pr);
        });
        this.dialogRef.close(prop);
        break;
      case 5:
        arr = this.dataSource.filteredData.filter(e => e.check === true);
        let ind: SimpleClient[] = [];
        arr.forEach(a => {
          let pr = new SimpleClient(a.clientId, a.clientName);
          ind.push(pr);
        });
        this.dialogRef.close(ind);
        break;
      case 6:
        arr = this.dataSource.filteredData.filter(e => e.check === true);
        let enc: SimpleEncargo[] = [];
        arr.forEach(a => {
          let pr = new SimpleEncargo(a.encargoId, a.code);
          enc.push(pr);
        });

        // Cuando cargo el encargo, también he de cargar sus arquitectos y sus clientes
        let cliEncargo: SimpleClient[] = [];
        let indEncargo: SimpleClient[] = [];
        enc.forEach(e=>{
          this.endpointService.filterClientsFromEncargo(this.account.idEmpresa, e.id, this.authService.userId).subscribe(data => {
            data['response']['clientes'].forEach(c => {
                let cEncargo = new SimpleClient(c.clientId, c.clientName);
                cliEncargo.push(cEncargo);
            });
            data['response']['industriales'].forEach(i => {
              let iEncargo = new SimpleClient(i.clientId, i.clientName);
              indEncargo.push(iEncargo);
            });
            this.dialogRef.close({Encargos: enc, Clientes: cliEncargo, Industriales: indEncargo});
          });
        });

        break;
      case 7:
        arr = this.dataSource.filteredData.filter(e => e.check === true);
        let arc: SimpleClient[] = [];
        arr.forEach(a => {
          let pr = new SimpleClient(a.clientId, a.clientName);
          arc.push(pr);
        });
        this.dialogRef.close(arc);
        break;
    }
  }

  public quickEditFunction(): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: 'CONFIRMA QUE QUIERES REALIZAR ESTA ACCIÓN',
        labelButton1: 'CANCELAR',
        labelButton2: 'CONFIRMAR'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {
        let arr = this.dataSource.filteredData.filter(e => e.check === true);
        let prop: SimpleProperty[] = [];
        arr.forEach(a => {
          let pr = new SimpleProperty(a.propertyId, a.reference, a.price, a.internalName);
          prop.push(pr);
        });
        this.dialogRef.close(prop);
      }
    });
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isDisabled(element: any) {
    return (element.check === false && this.isChecked && this.isCheckedId!=element.id);
  }

  public onChange(id: number){
    this.isChecked = !this.isChecked;
    this.isCheckedId = id;
  }

  setChecked(element: any) {
    // Gabri Comments -> solamente se puede escoger una oferta por que se ha determinado así
    if (!this.isDisabled(element)) {
      element.check = !element.check;
      this.onChange(element.id);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  check(element: any) {
    if (element.disabled !== true) {
      element.check = !element.check;
    }
  }

}
