
<div class="container">
  <div class="form-group">
    <p>
      <span>¿Le ha gustado?: </span>
      <span *ngIf="event.signLike" style="display:inline-block">Sí.</span>
      <span *ngIf="!event.signLike" style="display:inline-block">No.</span>
    </p>
    <p>
      <span> ¿Recibir notificación?: </span>
      <span *ngIf="event.signNotify" style="display:inline-block">Sí.</span>
      <span *ngIf="!event.signNotify" style="display:inline-block">No.</span>
    </p>
    <p>
      <span> Observaciones: {{event.signObservation}} </span>
    </p>
    <p>
      <span> DNI: {{event.signDNI}} </span>
    </p>
    <img style="border-style: solid" src="{{event.signImgURL}}"/>
  </div>
</div>

<div class="dialog-footer-1">
  <button type="button" (click)="onNoClick()">Cancelar</button>
</div>
