import { Component, Input, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

//SERVICES
import { FunctionsService } from '../../../services/functions/functions.service';
import { GenericService } from '../../../services/generic/generic.service';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() structure = null;
  @Input() index : number;
  @Input() param : any = null;
  @ViewChild('matMenuTrigger') trigger: MatMenuTrigger;
  @ViewChild('button') button: ElementRef<HTMLElement>;

  public idTable = null;
  public idRow = null;
  public loaded = false;
  public isOnOver = false;
  public menuTrigger;
  public functionFinish = {};
  public menuVisible: string;

  enteredButton = false;
  isMatMenuOpen = false;
  isMenuOpen = false;
  prevButtonTrigger;

  constructor(public functionsService: FunctionsService,
    public genericService: GenericService,
    public authService: AuthService) {
      this.menuVisible = genericService.lastPosition;
    }

  ngOnInit(): void {
    this.functionFinish[this.index] = true;
    this.menuVisible = 'isNotOpen';
    this.genericService.menuVisibilityChange.subscribe(value => {
      this.menuVisible = value;
      if(this.menuVisible == 'out'){
        this.isOnOver = false;
        this.isMenuOpen = false;
      } else {
        this.isOnOver = true;
        this.isMenuOpen = true;
      }
      this.buttonLeave();
    });

    if(this.param !== null) {
      let aux = this.param.split('.')
      if(aux.length == 2) {
        this.idTable = aux[0]
        this.idRow = aux[1]
      }
      else if(aux.length == 3) {
        this.idTable = aux[0] + '.' + aux[1]
        this.idRow = aux[2]
      }
    }
    this.loaded = true;
  }

  onClickEvent = (eventTrigger) => {
    console.log('he picado' , this.structure[this.index]["id_function"]);
    if (eventTrigger['structure'] && this.structure[this.index]["id_function"] > 0) {
      //this.functionsService.getFunction(eventTrigger['structure']);
      if(this.idTable == null && this.idRow == null) {
        let finished = {done: true};
        if(this.structure[this.index]['class'] && this.structure[this.index]['class'] != null && this.structure[this.index]['class'] != 0 && this.structure[this.index]['class'] != '' && this.structure[this.index]['class'] != "only-icon") {
          console.log("si spinner");
          this.functionFinish[this.index] = false;
          finished.done = false;
        } else {
          console.log("no spinner");
          this.functionFinish[this.index] = true;
          finished.done = true;
          this.genericService.actualFunctionFinish = true;
        }
        this.functionsService.getFunction(this.structure[this.index], null, finished);
        this.functionFinish[this.index] = finished.done;
        this.genericService.actualFunctionFinish = finished.done;
        console.log("spinner_timeout: ", this.structure[this.index]["spinner_timeout"]);
        if(this.structure[this.index]["spinner_timeout"] == 1) {
          setTimeout(() =>{ // si han pasado 3 segundos y no ha terminado que ponga this.functionFinish a true de nuevo
            this.functionFinish[this.index] = true;
            this.genericService.actualFunctionFinish = true;
          } , 3000);
        }
      } else {
        console.log(this.genericService.tableData, this.idTable, "this.genericService.tableData[this.idTable]['data'][this.idRow]")
        this.genericService.tablesActualRows.push({"id_functional_area": this.idTable, "rowID": this.idRow});
        this.functionsService.getFunction(this.structure[this.index], this.genericService.tableData[this.idTable]['data'][this.idRow], this.idTable)
      }
    }
  }

  getNumChanges() {
    return this.genericService.getNumChanges();
  }

  over(){
    this.isOnOver = true;
    this.isMenuOpen = true;
    //console.log('estoy over');
  }

  out(){
    //console.log('estoy out');
    setTimeout(() => {
      this.buttonCloseIfNotSubmenu();
    }, 500);
  }

  buttonEnter(trigger) {
    this.menuTrigger = trigger;
    setTimeout(() => {
      if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMenuOpen = true;
        trigger.openMenu();
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        this.isMenuOpen = true;
        trigger.openMenu();
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    });
  }

  buttonLeave(trigger = null) {
    if(trigger == null) trigger = this.menuTrigger;
    if(trigger != null && trigger != undefined){
      setTimeout(() => {
        if (this.enteredButton && !this.isMatMenuOpen && this.menuVisible == 'out' && !this.isOnOver) {
          trigger.closeMenu();
        } if (!this.isMatMenuOpen && this.menuVisible == 'out' && !this.isOnOver) {
          trigger.closeMenu();
        } else {
          this.enteredButton = false;
        }
      }, 500);
    }
    this.buttonCloseIfNotSubmenu();
  }

  buttonCloseIfNotSubmenu(){
    setTimeout(() => {
      if (!this.isMenuOpen && this.menuVisible == "isNotOpen") {
        if(this.menuTrigger != undefined) this.menuTrigger.closeMenu();
      }
    }, 500);
  }
}
