import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function matchPassword (flag : boolean): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const pass2 = control.value;
    const pass1 = control.root.value['pass1'];
    let no : boolean = false;
    if(pass1 === pass2){
      no = false;
    }else{
      no = true;
    }

    return no ? {'matchPassword': {name}} : null;
  };
}


@Directive({
  selector: '[matchPassword]',
  providers: [{provide: NG_VALIDATORS, useExisting: matchPassword, multi: true}]

})

export class MatchPasswordDirective implements Validator, OnChanges {
 @Input() forbiddenName: string;
 private valFn = Validators.nullValidator;

 ngOnChanges(changes: SimpleChanges): void {
   const change = changes['matchPassword'];
   if (change) {
     //const val: string  = change.currentValue;
     //const re = val instanceof RegExp ? val : new RegExp(val, 'i');
     this.valFn = matchPassword(false);
   } else {
     this.valFn = Validators.nullValidator;
   }
 }

 validate(control: AbstractControl): {[key: string]: any} {
   return this.valFn(control);
 }
}
