import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {BottomSheetDuplicate} from '../../property/Bottom-sheet-duplicate/bottom-sheet-duplicate';
import {BottomSheetUploadImage} from '../bottom-sheet-upload-image/bottom-sheet-upload-image';
import {AuthService} from '../../services/auth/auth.service';

declare let jQuery: any;

@Component({
  selector: 'image-selector-modal',
  templateUrl: './imageSelector.component.html',
  providers: [],
  styleUrls: ['./imageSelector.component.css']
})
export class ImageSelectorModalComponent implements OnInit {  //converted into a bottom-sheet

  @Input() title: string;
  @Input() acceptButtonText = 'Aceptar';
  @Input() declineButtonText = 'Declinar';
  @Input() uploadImageButtonText = 'Subir imagen';
  @Input() id: string;
  @Input() idUploader: string;
  //@Input() backendGetImagesCall = null;
  @Input() backendRequest = "";
  @Input() images: Array<any>;

  @Output() onAccept = new EventEmitter();
  @Output() onDecline = new EventEmitter();
  @Output() loadImages = new EventEmitter();

  constructor(private endpointService: EndpointService,
              private authService: AuthService,
              public _bottomSheet : MatBottomSheet) {
  }

  ngOnInit() {
  }



  onClickAccept() {
    this.onAccept.emit();
  }

  onClickDecline() {
    this.onDecline.emit();
  }

  onClickAceptUpload() {
    //jQuery('#' + this.idUploader).modal('hide');
    this.loadImages.emit();
    jQuery('#' + this.id).modal('show');

    //this.endpointService.insertEmailImage(this.authService.inmoId).subscribe(data => {});
  }

  onClickDeclineUpload() {
    jQuery('#' + this.idUploader).modal('hide');
    this.loadImages.emit();
    jQuery('#' + this.id).modal('show');
  }

  /*showImageUpload() {
    jQuery('#' + this.id).modal('hide');
    //jQuery('#' + this.idUploader).modal('show');

    let bottomSheet = this._bottomSheet.open(BottomSheetUploadImage, {
      data : {backendRequest : this.backendRequest}
    });

    bottomSheet.afterDismissed().subscribe(data => {
      if (data === 1){
        this.onClickAceptUpload();
      } else {
        this.onClickDeclineUpload();
      }
    });
  }*/

}
