<div style="min-width: 30vw;">
    <ng-container *ngIf="!genericCreatorService.isSubElement">
        <mat-stepper [linear]="isLinear" #stepper>
            <mat-step>
                <ng-template matStepLabel>Tipo</ng-template>
                <ng-container>
                    <div>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>search</mat-label>
                            <input matInput placeholder="search by name" [ngModel]="searchText" (keyup)="filterData($event)" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <span *ngIf="filteredArray?.length > 0" fxLayout="row wrap">
                        <div fxFlex="100"><h4>Generic</h4></div>
                        <ng-container *ngFor="let item of filteredArray; let i = index;">
                            <div *ngIf="item.custom != 1 && item.show_frontend === 1 && item.dsb === 0" (click)="setActiveItem(item, 'generic', i, stepper)" 
                            id="generic{{i}}" fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
                            <span class="typeItem" [ngClass]="{'active' : selectedItemClass === ('generic'+i)}">
                                <div class="typeIcon" *ngIf="item.icon"><i class="material-icons">{{item.icon}}</i></div>            
                                <div class="typeIcon" *ngIf="!item.icon"><i class="material-icons">settings</i></div>            
                                <div class="typeName">{{item.name_frontend}} <i *ngIf="item.description_frontend" matTooltip="{{item.description_frontend}}" class="material-icons infoIcon">info</i></div>
                            </span>    
                            </div>
                        </ng-container>
                        <div fxFlex="100"><h4>Custom</h4></div>
                        <ng-container *ngFor="let item of filteredArray; let j = index;">
                            <div class="typeItem" *ngIf="item.custom == 1 && item.show_frontend === 1 && item.dsb === 0" (click)="setActiveItem(item, 'custom', j, stepper)"
                                id="custom{{j}}" fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
                                <span class="typeItem" [ngClass]="{'active' : selectedItemClass === ('custom'+j)}">
                                    <div class="typeIcon" *ngIf="item.icon"><i class="material-icons">{{item.icon}}</i></div>            
                                    <div class="typeIcon" *ngIf="!item.icon"><i class="material-icons">settings</i></div>
                                    <div class="typeName">{{item.name_frontend}} <i *ngIf="item.description_frontend" matTooltip="{{item.description_frontend}}" class="material-icons infoIcon">info</i></div>
                                </span>
                            </div>
                        </ng-container>
                    </span>
                    <div *ngIf="filteredArray?.length === 0">
                        No result
                    </div>
                </ng-container>
            <!-- <div>
                <button mat-button matStepperNext [disabled]="!selectedItem">Next</button>
            </div> -->
            </mat-step>
            <mat-step label="Características">
                <app-generic-custom-creator-options *ngIf="genericCreatorService.actualSelectedElement" [actualElement]="genericCreatorService.actualSelectedElement"></app-generic-custom-creator-options>
            <div>
                <button mat-button matStepperPrevious (click)="resetSelectedElement()">Previous</button>
                <button mat-button (click)="addItemToArray()" [disabled]="!selectedItem">Add</button>
            </div>
            </mat-step>
        </mat-stepper>
    </ng-container>
    <ng-container *ngIf="genericCreatorService.isSubElement">
        <app-generic-custom-creator-options *ngIf="genericCreatorService.actualSelectedSubElement" [actualElement]="genericCreatorService.actualSelectedSubElement"></app-generic-custom-creator-options>
    </ng-container>
</div>