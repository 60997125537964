<mat-accordion>
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Window details
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxFlex="100">
            <div fxFlex="100">
                <form [formGroup]="genericCreatorService.containerForm" fxLayout="row wrap" fxFlex="100">
                    <mat-form-field appearance="outline">
                        <mat-label>Id query</mat-label>
                        <input type="text"
                                placeholder="Id query"
                                aria-label="internal_name"
                                matInput
                                formControlName="id_query"
                                [matAutocomplete]="autoQuery">
                        <mat-autocomplete autoActiveFirstOption #autoQuery="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let item of idQueryOptions | async" [value]="item">{{item.internal_name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    
                    <mat-form-field appearance="fill" appearance="outline">
                        <mat-label>DB</mat-label>
                        <mat-select formControlName="id_db">
                            <mat-option *ngFor="let item of genericCreatorService.db" [value]="item.id_db">{{item.db_name}}</mat-option>
                        </mat-select>
                    </mat-form-field> 
        
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Bd table</mat-label>
                        <input matInput placeholder="Bd Table" formControlName="bd_table" autocomplete="off">
                    </mat-form-field>                    
                </form>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="false" (closed)="closeAllFloatings()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Floating elements
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion *ngIf="genericCreatorService?.arrayFloatingElements?.length > 0">
            <mat-expansion-panel *ngFor="let element of genericCreatorService.arrayFloatingElements | keyvalue : genericService.returnZero; let i = index;" [expanded]="genericCreatorService.expandedFloatingIndex === i"
            (opened)="expansionOpened(genericCreatorService.arrayFloatingElements[element.key], i)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{genericCreatorService.arrayFloatingElements[element.key].internal_name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="row wrap" fxFlex="100" style="text-align: center; margin-bottom: 1.5em">
                    <ng-container *ngIf="genericCreatorService.actualSelectedElement !== undefined && genericCreatorService.actualSelectedElement !== null">
                        <app-generic-custom-creator-options [actualElement]="genericCreatorService.actualSelectedElement"></app-generic-custom-creator-options>
                    </ng-container>
                </div>
            </mat-expansion-panel>  
            <div style="text-align: center; padding-top: 10px;">
                <span class="generic-buttons-blue generic-creator-add">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <i class="material-icons">add</i>
                    </button>
                </span>
                <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addDsb('dialog')">
                    <mat-icon>check_box_outline_blank</mat-icon>
                    <span>Dialog</span>
                </button>
                <button mat-menu-item (click)="addDsb('bottomSheet')">
                    <mat-icon>list</mat-icon>
                    <span>Bottom Sheet</span>
                </button>
                </mat-menu>
            </div>                  
        </mat-accordion>
    </mat-expansion-panel>
</mat-accordion>