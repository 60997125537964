import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AddJourneyElementDialogComponent } from './add-journey-element-dialog/add-journey-element-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { JourneyService } from 'app/services/journey/journey.service';
import { AuthService } from 'app/services/auth/auth.service';
import * as _ from 'lodash';
import { empty } from 'rxjs';
import { FunctionsService } from 'app/services/functions/functions.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-journey-creator-custom',
  templateUrl: './journey-creator-custom.component.html',
  styleUrls: ['./journey-creator-custom.component.css']
})
export class JourneyCreatorCustomComponent implements OnInit, AfterViewInit {

  @Input() structure = null;
  @Input() index: number;

  elements:any = [];
  elementsOriginal:any = [];
  lines = [];
  arrowSize = 50;
  maxWidth = 1500;
  maxHeight = 3000;
  maxWidthPX = this.maxWidth + "px";
  maxHeightPX = this.maxHeight + "px";
  actualY = 0;
  triggerWidth = 225;
  countNumTriggers = 1;
  actualX = 0;
  @ViewChild('container') container: ElementRef;
  centerX = [];
  notAllowd = false;
  deleteType: number;
  elementBelowNodeNo: any[];
  elementBelowNodeYes: any[];
  recentAddedNode: any;
  newElements: any;
  public actualTmpId = 1;
  public loaded = false;

  constructor(public dialog: MatDialog, 
    private endpointService: EndpointService, 
    private authService: AuthService,
    private journeyService: JourneyService,
    private functionsService: FunctionsService,
    public genericService: GenericService,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.journeyService.copyElement = null;
    this.getJourneysCreator();
  }

  ngAfterViewInit() {
    //let container = this.container.nativeElement.offsetWidth/(this.elementsOriginal.length==0 ? 1 : this.elementsOriginal.length);
    let container = 0;
    this.centerX.push(container/2);
  }

  drawLines() {
    this.removeLines();
    for(let i in this.elements) {
      for(let j in this.elements[i]['parentsTmp']) {
        for(let k in this.elements) { //Parent
          if(this.elements[i]['parentsTmp'][j] == this.elements[k]['tmpId']) {
            let typeLine = this.elements[k]['id_type'];
            let startX = this.elements[k]['x'] + (this.elements[k]['width'] / 2);
            let endX = this.elements[i]['x'] + (this.elements[i]['width'] / 2);
            let startY = this.elements[k]['y'] + this.elements[k]['height'];
            let endY = startY + this.arrowSize;
            startY = startY + this.elements[k]['extraHeight']
            if(startX == endX) {
              this.drawOneLine(startX, startY, endX, endY, typeLine);
            } else {
              let startX1 = startX;
              let endX1 = startX;
              let startY1 = startY;
              let endY1 = (endY+startY)/2;
              typeLine = 10;
              this.drawOneLine(startX1, startY1, endX1, endY1, typeLine);

              let startX2 = startX;
              let endX2 = endX;
              if(startX > endX) {
                startX2 = startX2 + 1.5;
                endX2 = endX2 - 1.5;
              } else {
                startX2 = startX2 - 1.5;
                endX2 = endX2 + 1.5;
              }
              let startY2 = endY1;
              let endY2 = startY2;
              typeLine = 11;
              this.drawOneLine(startX2, startY2, endX2, endY2, typeLine);

              let startX3 = endX;
              let endX3 = endX;
              let startY3 = endY2;
              let endY3 = endY;
              typeLine = 12;
              this.drawOneLine(startX3, startY3, endX3, endY3, typeLine);
            }
          }
        }
      }
    }
  }

  drawOneLine(startX, startY, endX, endY, typeLine) {
    if(typeLine == 2) endY = endY - 8;
    this.lines.push({startX, startY, endX, endY, typeLine});   
  }

  removeLines() {
    this.lines = [];
  }

  openDialog(element, data, nodeId) {
    if(element['id_type'] == 2) this.journeyService.isLastElement = !(element['childsTmp'] && element['childsTmp'].length > 0);
    else this.journeyService.isLastElement = !(element['childsOriginalTMP'] && element['childsOriginalTMP'].length > 0);
    let originalElement = _.cloneDeep(element);
    let deleteType;
    let triggerAdd = false;
    if(element['id_category'] == 2 && data != 'delete') {
      data = 'editTrigger';
      triggerAdd = element['id_type'] == 3;
      if(triggerAdd) {
        element.name = this.journeyService.journeyActionDefault['name'];
        element = _.cloneDeep(element);
      }
    } else if (data == '+') {
      for(let i in this.elementsOriginal) {
        if(this.elementsOriginal[i]['tmpId'] == element['parentsTmp'][0] && this.elementsOriginal[i]['id_type'] == 3) {
          this.openDialog(this.elementsOriginal[i], 'editNodes', nodeId);
          return;
        }
        if(this.elementsOriginal[i]['tmpId'] == element['childsTmp'][0] && this.elementsOriginal[i]['id_type'] == 3) {
          this.openDialog(this.elementsOriginal[i], 'editNodes', nodeId);
          return;
        }
      }
      data = 'addNodes'
      element = _.cloneDeep(element);
    } else if (data == 'delete') {
      /*let deletedElement: any;
      let keepOnlyYes;
      let keepOnlyNo;
      data = 'delete';

      this.elements.forEach(element => {
        if (element.id === nodeId) {
            deletedElement = element;
        }
      });
      this.elementBelowNodeNo = this.elements.filter(element => (element.parent >= deletedElement.id && element.parentType === 'no') || element.id == deletedElement.id);
      this.elementBelowNodeYes = this.elements.filter(element => (element.parent >= deletedElement.id && element.parentType === 'yes') || element.id == deletedElement.id);      
      keepOnlyYes = this.elementBelowNodeYes.length>3;
      keepOnlyNo = this.elementBelowNodeNo.length>3;
      deleteType = {keepOnlyYes: keepOnlyYes, keepOnlyNo: keepOnlyNo};*/
    } else {
      data = 'editNodes';
      if(element['id_type'] == 3) element.name = this.journeyService.journeyActionDefault['name'];
    }
    const dialogRef = this.dialog.open(AddJourneyElementDialogComponent, {
      data: {nodes: data, ifElse: this.notAllowd, deleteType, element, }, disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(this.journeyService.applyChanges) {
        console.log(nodeId, element, data , "hellkooo", this.journeyService.actualElement);
        if (data === 'addNodes') {
          this.addNode(element, this.journeyService.actualElement);
          this.functionsService.openSnackBar("Acción añadida correctamente.", 3000, [
            "green-snackbar",
          ]);
        } else if (data === 'delete') {
          this.deleteElement(element);
        } else if(data === 'editNodes') {
          if(element['id_type'] == 3) {
            element['id_type'] = 1;
          }
          this.replaceElementInOriginals(element);
          this.functionsService.openSnackBar("Acción editada correctamente.", 3000, [
            "green-snackbar",
          ]);
        } else if('editTrigger') {
          if(triggerAdd) {
            element.id_type = "1";
            this.elementsOriginal.push(element);
            ++this.countNumTriggers;
            console.log("kjsfankfjsa", triggerAdd, element);
            this.sortElements();
            this.drawElements();
            this.functionsService.openSnackBar("Trigger añadido correctamente.", 3000, [
              "green-snackbar",
            ]);
          } else {
            this.replaceElementInOriginals(element);
            this.functionsService.openSnackBar("Trigger editado correctamente.", 3000, [
              "green-snackbar",
            ]);
          }
        }
      } else {
        this.replaceElementInOriginals(originalElement);
        this.drawElements();
      }
    });
  }

  replaceElementInOriginals(element) {
    for(let i in this.elementsOriginal) {
      if(this.elementsOriginal[i]['tmpId'] == element['tmpId']) {
        this.elementsOriginal[i] = element;
        this.drawElements();
        break;
      }
    }
  }

  addNode(element, addElement) {
    element = _.cloneDeep(element);
    addElement = _.cloneDeep(addElement);
    addElement['tmpId'] = this.actualTmpId;
    addElement['width'] = this.triggerWidth;
    addElement['height'] = 60;
    addElement['extraHeight'] = 0;
    addElement['id_type'] = 1;
    ++this.actualTmpId;
    this.assignChildsAndParents(element, addElement);
    this.elementsOriginal.push(addElement);
    this.sortElements();
    console.log(this.elementsOriginal, "this.elementsOriginal");
    this.drawElements();
    return;
  }

  assignChildsAndParents(element, addElement) {
    let newParents = element['parentsTmp'];
    let newChilds = element['childsTmp'];
    
    for(let i in this.elements) {
      if(this.elements[i]['tmpId'] == newParents[0] && this.elements[i]['id_type'] == 4) {
        newParents = this.elements[i]['parentsTmp'];
        break;
      }
    }
    
    addElement['parentsOriginalTMP'] = newParents;
    addElement['childsOriginalTMP'] = newChilds;
    addElement['parentsTmp'] = [];
    addElement['childsTmp'] = [];
    addElement['parents'] = [];
    addElement['childs'] = [];

    this.assignParents(addElement);
    this.assignChilds(addElement);
    console.log(addElement, element, "elementelementelement", this.elementsOriginal);
    this.cleanParents(addElement);
    this.cleanChilds(addElement);
  }

  assignParents(actualElement) {
    for(let i in actualElement['parentsOriginalTMP']) {
      for(let j in this.elementsOriginal) {
        if(actualElement['parentsOriginalTMP'][i] == this.elementsOriginal[j]['tmpId']) {
          this.elementsOriginal[j]['childsOriginalTMP'].push(actualElement['tmpId']);          
        }
      }
    }
  }

  assignChilds(actualElement) {
    for(let i in actualElement['childsOriginalTMP']) {
      for(let j in this.elementsOriginal) {
        if(actualElement['childsOriginalTMP'][i] == this.elementsOriginal[j]['tmpId']) {
          this.elementsOriginal[j]['parentsOriginalTMP'].push(actualElement['tmpId']);
        }
      }
    }
  }

  cleanChilds(actualElement) {
    for(let k in actualElement['childsOriginalTMP']) {
      for(let j in this.elementsOriginal) {
        // ACTUAL: actualElement
        // PARENT: this.elementsOriginal[j]
        const index = this.elementsOriginal[j]['childsOriginalTMP'].indexOf(actualElement['childsOriginalTMP'][k]);
        if(index > -1) {
          for(let i in actualElement['parentsOriginalTMP']) {
            if(this.elementsOriginal[j]['tmpId'] == actualElement['parentsOriginalTMP'][i]) {
              this.elementsOriginal[j]['childsOriginalTMP'].splice(index, 1);
              if(index == 0) this.elementsOriginal[j]['childsOriginalTMP'] = this.elementsOriginal[j]['childsOriginalTMP'].reverse();
              console.log("ACTUAL:", actualElement, "PARENT:", this.elementsOriginal[j]);
              break;
            }
          }
        }
      }
    }
  }

  cleanParents(actualElement) {
    for(let k in actualElement['parentsOriginalTMP']) {
      for(let j in this.elementsOriginal) {
        // ACTUAL: actualElement
        // CHILD: this.elementsOriginal[j]
        const index = this.elementsOriginal[j]['parentsOriginalTMP'].indexOf(actualElement['parentsOriginalTMP'][k]);
        if(index > -1) {
          for(let i in actualElement['childsOriginalTMP']) {
            if(this.elementsOriginal[j]['tmpId'] == actualElement['childsOriginalTMP'][i]) {
              this.elementsOriginal[j]['parentsOriginalTMP'].splice(index, 1);
              console.log("ACTUAL:", actualElement, "CHILD:", this.elementsOriginal[j]);
              break;
            }
          }
        }
      }
    }
  }

  deleteElement(element) {
    let showMessage = true;
    console.log(element);
    if(element['id_category'] != 2) {
      for(let j in this.elementsOriginal) {
        if(this.elementsOriginal[j]['tmpId'] == element['childsOriginalTMP'][0]) {
          this.elementsOriginal[j]['parentsOriginalTMP'] = element['parentsOriginalTMP'];
          break;
        }
      }

      for(let i in element['parentsOriginalTMP']) {
        for(let j in this.elementsOriginal) {
          if(this.elementsOriginal[j]['tmpId'] == element['parentsOriginalTMP'][i]) {
            this.elementsOriginal[j]['childsOriginalTMP'] = [element['childsOriginalTMP'][0]];
            break;
          }
        }
      }
    } else {
      if(this.countNumTriggers > 2) {
        for(let i in element['childsOriginalTMP']) {
          for(let j in this.elementsOriginal) {
            if(this.elementsOriginal[j]['tmpId'] == element['childsOriginalTMP'][i]) {
              const index = this.elementsOriginal[j]['parentsOriginalTMP'].indexOf(element['tmpId']);
              if (index > -1) {
                this.elementsOriginal[j]['parentsOriginalTMP'].splice(index, 1);
                break;
              }
            }
          }
        }
      } else {
        showMessage = false;
      }
    }
    
    if(showMessage) {
      for(let i in this.elementsOriginal) {
        if(this.elementsOriginal[i]['tmpId'] == element['tmpId']) {
          if(element['id_category'] == 2) --this.countNumTriggers;
          this.elementsOriginal.splice(i, 1);
          break;
        }
      }
      console.log(this.elementsOriginal, "deleted: elementsOriginalelementsOriginal");
      this.drawElements();
      this.functionsService.openSnackBar("Elemento eliminado correctamente.", 3000, [
        "green-snackbar",
      ]);
    } else {
      this.functionsService.openSnackBar("No puedes dejar un Journey sin Trigger (Start Point). Si lo hicieras jamás ningún cliente empezaría este Journey.", 7000, [
        "red-snackbar",
      ]);
    }
      
  }

  deleteElement_old(id: number) {
    let ifElse = [];
    let deletedElement = this.elements.find(x => x.id == id);
    let nextElement = this.elements.find(x => x.parent == deletedElement.id+1);

    if (deletedElement.type === 'If/Else') {
      ifElse.push(deletedElement);
      this.elements.forEach(ele => {
        if (ele.parent === deletedElement.id) {
          ifElse.push(ele);
        }
      });

      ifElse.forEach(el => {
        if (el.type == 'no') {
          this.elementBelowNodeYes.push(el);
          this.elements.forEach(itm => {
            if (itm.id === (el.id+2)) {
              this.elementBelowNodeYes.push(itm);
            }
          });
        }
        if (el.type == 'yes') {
          this.elementBelowNodeNo.push(el);
          this.elements.forEach(itm => {
            if (itm.id === (el.id+2)) {
              this.elementBelowNodeNo.push(itm);
            }
          });
        }
      });
    } else {
      ifElse = this.elements.filter(element => element.parent == deletedElement.parent 
        || element.parent == deletedElement.parent + 1);
    }

    if (ifElse.length>2) {
      let index = this.elements.findIndex(x => x.id == ifElse[1].id);
      
      if (this.elements.length-1 != index+1) {      
        this.elements[index+2].parent = ifElse[1].parent;
      }      

      if (this.deleteType == 2) {
        let combinedElements = [...this.elementBelowNodeNo, ...this.elementBelowNodeYes];
        let myArray = this.elements.filter( ( el ) => !combinedElements.includes( el ) );
        this.elements = myArray;
      } else if (this.deleteType == 1) {        
        let myArray = this.elements.filter( ( el ) => !this.elementBelowNodeYes.includes( el ) );
        this.elements = myArray;
      } else {
        let myArray = this.elements.filter( ( el ) => !this.elementBelowNodeNo.includes( el ) );
        this.elements = myArray;
      }

      this.elements.forEach((ele, i) => {

        if (ele.id == deletedElement.id + 5) {          
          ele.parent = deletedElement.parent;
        }
 
        if (ele.id > ifElse[1].id && this.deleteType == 2) {
          ele.y = ele.y - 100 - (ifElse[1].height * 2);
        }

        if (ele.id === deletedElement.parent && this.deleteType == 1) {
          ele.x = (this.centerX[0] - ele.width/2);
          ele.y = ele.y - 300;
          if (ele.parent == deletedElement.id) {
            ele.x = (this.centerX[0] - ele.width/2);
            ele.type = '';
          }
        }

        if (ele.id > deletedElement.id && this.deleteType == 0) {
          ele.x = (this.centerX[0] - ele.width/2);
          ele.y = ele.y - 300;
          if (ele.parent == deletedElement.id) {
            ele.x = (this.centerX[0] - ele.width/2);
            ele.type = '';
          }
          if (ele.id === (deletedElement.id+3)) {
            ele.parent = deletedElement.parent;
          }
        }
      });

    } else {
      this.elements = this.elements.filter(element => element.id !== id);
      this.elements = this.elements.filter(element => element.name !== "+" || element.parent !== id);
      if (nextElement) {
        this.elements.forEach(x => {
          if (nextElement.id === x.id) {
            x.parent = deletedElement.parent;
          }
          if ((x.parent >= nextElement.parent && x.id != this.elements[this.elements.length-3].id)
          || nextElement.id === x.id) {            
              x.y = x.y - 75 - (deletedElement.height * 2);
          }
        });
      }
      
    }
    this.drawLines();
  }

  getJourneysCreator() {
    let idCompanyGeneric = this.authService.getIdCompany(true);
    let routeParams = JSON.parse(localStorage.getItem('paramControl'))[idCompanyGeneric];
    for(let i in routeParams) {
      if(routeParams[i]['id_pantalla'] == this.structure[this.index]['id_functional_parent_initial']){
        for(let k in routeParams[i]['params']['input']) {
          if(routeParams[i]['params']['input'][k]['id_db'] == 1 && routeParams[i]['params']['input'][k]['bd_table'] == 'journeys' && routeParams[i]['params']['input'][k]['bd_field'] == 'id') {
            this.journeyService.idJourney = routeParams[i]['params']['input'][k]['value'];
            break;
          }
        }
      }
    }
    this.endpointService.getJourneysCreator(this.journeyService.idJourney, this.authService.getIdCompany()).subscribe(data => {
        let information = data['response'];
        this.elementsOriginal = information['elementsJourney'];
        this.journeyService.emailTemplates = information['emailTemplates'];
        this.journeyService.journeys = information['journeys'];
        this.journeyService.journeyAction = this.elements;
        this.journeyService.smsTemplates = information['smsTemplates'];
        this.journeyService.whatappTemplates = information['whatappTemplates'];
        this.journeyService.temporalities = information['temporalities'];
        this.journeyService.tagsTemplates = information['tags'];
        information['journeyActionDefault']['childsOriginalTMP'] = [];
        information['journeyActionDefault']['parentsOriginalTMP'] = [];
        this.journeyService.journeyActionDefault = information['journeyActionDefault'];
        this.journeyService.triggerTypes = information['triggerTypes']; 
        this.journeyService.actionTypes = information['actionTypes'];     
        
        for(let i in this.elementsOriginal) {
          this.elementsOriginal[i]['tmpId'] = this.actualTmpId;
          this.elementsOriginal[i]['width'] = this.triggerWidth;
          this.elementsOriginal[i]['height'] = 60;
          this.elementsOriginal[i]['extraHeight'] = 0;
          this.elementsOriginal[i]['id_type'] = 1;
          ++this.actualTmpId;
          if(this.elementsOriginal[i]['id_category'] == 2) ++this.countNumTriggers;
        }

        for(let i in this.elementsOriginal) {

          this.elementsOriginal[i]['childsOriginalTMP'] = [];
          for(let j in this.elementsOriginal[i]['childs']) {
            for(let k in this.elementsOriginal) {
              if(this.elementsOriginal[k]['id'] == this.elementsOriginal[i]['childs'][j]['id']) {
                this.elementsOriginal[i]['childsOriginalTMP'].push(this.elementsOriginal[k]['tmpId']);
                break;
              }
            }
          }

          this.elementsOriginal[i]['parentsOriginalTMP'] = [];
          for(let j in this.elementsOriginal[i]['parents']) {
            for(let k in this.elementsOriginal) {
              if(this.elementsOriginal[k]['id'] == this.elementsOriginal[i]['parents'][j]['id_action_parent']) {
                this.elementsOriginal[i]['parentsOriginalTMP'].push(this.elementsOriginal[k]['tmpId']);
                break;
              }
            }
          }
          
        }

        this.sortElements();
        this.drawElements();
    });
  }

  drawElements() {
    this.elements = [];
    this.removeLines();
    let newElements = [];
    this.actualY = 0;
    this.maxWidth = (this.triggerWidth * 1.5 * this.countNumTriggers);
    console.log(this.countNumTriggers, "this.countNumTriggers");
    let position = 0;
    let countNumTriggers = this.countNumTriggers;
    if(countNumTriggers < 1) countNumTriggers = 1;
    const element = this.elementRef.nativeElement.querySelector('#container');
    const widthContainer = element.offsetWidth - 100;
    if(widthContainer > this.maxWidth) {
      this.maxWidth = widthContainer - 25;
      position = (this.maxWidth / 2) - ((this.triggerWidth * 2)/2);
      position += 100;
    } else {
      position = (this.maxWidth / 2) - ((this.triggerWidth * 1.5)/2);
      this.maxWidth -= (this.triggerWidth/2);
    }

    let addWidth = this.triggerWidth + (this.triggerWidth/2);
    let isPair = ((countNumTriggers/2) % 1 == 0);
    let addExtraWidth = 0;
    let idsTmpTriggers = [];
    let myParentsYesNo = {myParentsYes: [], myParentsNo: []};
    if(this.elementsOriginal.length > 0 && countNumTriggers > 0) {
      if(isPair) {
        addExtraWidth = this.triggerWidth * 0.75;
      } else {
        countNumTriggers -= 1;
      }
      let countMultiply = null;
      let drawAddTriggerElement = true;
      for(let ele in this.elementsOriginal) {
        let i:number = +ele;
        if(this.elementsOriginal[i]['id_category'] == 2) {
          if(countMultiply == null) countMultiply = (countNumTriggers - (countNumTriggers/2)) * -1;
          this.elementsOriginal[i]['x'] = position + (addWidth * countMultiply) + addExtraWidth;          
          this.elementsOriginal[i]['y'] = this.actualY + 50;
          this.elementsOriginal[i]['parent'] = 0;
          this.elementsOriginal[i]['parentsTmp'] = [];
          this.elementsOriginal[i]['childsTmp'] = [this.actualTmpId];
          this.elementsOriginal[i]['myParentsYesNo'] = myParentsYesNo;
          ++countMultiply;
          newElements.push(this.elementsOriginal[i]);
          idsTmpTriggers.push(this.elementsOriginal[i]['tmpId']);
        }
        if(this.elementsOriginal[i]['id_type'] == 3 && this.elementsOriginal[i]['id_category'] == 2) drawAddTriggerElement = false;
        if(this.elementsOriginal[i]['id_type_action'] == 4) {
          let lengthIfElseChilds = this.elementsOriginal[i]['childsOriginalTMP'].length;
          if(lengthIfElseChilds == 0) {
            this.elementsOriginal[i]['childsOriginalTMP'].push(this.actualTmpId);
            this.drawAddYesNoElement(this.elementsOriginal, this.elementsOriginal[i], [this.elementsOriginal[i]['tmpId']]);
          }
          if(lengthIfElseChilds == 1 || lengthIfElseChilds == 0) {
            this.elementsOriginal[i]['childsOriginalTMP'].push(this.actualTmpId);
            this.drawAddYesNoElement(this.elementsOriginal, this.elementsOriginal[i], [this.elementsOriginal[i]['tmpId']]);
          }
        }
      }
      this.sortElements();
      console.log('drawAddYesNoElement', this.elementsOriginal);
      this.actualY = this.actualY + 50;
      if(drawAddTriggerElement) this.drawAddTriggerElement(newElements, this.elementsOriginal[0], position + (addWidth * countMultiply) + addExtraWidth);
      idsTmpTriggers.push(this.actualTmpId - 1);
      this.drawAddElement(newElements, this.elementsOriginal[0], this.actualY, position, idsTmpTriggers, this.elementsOriginal[0]['childsOriginalTMP'], myParentsYesNo);
      this.drawElementsRecursive(newElements, this.elementsOriginal[0], position, myParentsYesNo);
    } else {
      let countMultiply = 0;
      this.actualY = this.actualY + 50;
      let parentNew = {
        childsOriginalTMP: [],
        id_journey: this.journeyService.idJourney,
      }
      this.drawAddTriggerElement(newElements, parentNew, position + (addWidth * countMultiply) + addExtraWidth); // Check values
    }

    this.elements = newElements;
    
    for(let ele in this.elements) {
      let i:number = +ele;
      if(this.elements[i]['id_type'] == 3 && this.elements[i]['id_category'] == 2) {
        for(let j in this.elements[i]['childsTmp']) {
          for(let k in this.elements) {
            if(this.elements[i]['childsTmp'][j] == this.elements[k]['tmpId']) {
              this.elements[k]['parentsTmp'].push(this.elements[i]['tmpId']);
              this.elements[k]['parentsOriginalTMP'].push(this.elements[i]['tmpId']);
            }
          }
        }
      }
      this.assignTypeLabel(this.elements[i]);
    }
    
    console.log('newElements', newElements);

    this.calculateHeightWidthWindow();
    this.drawLines();
    this.loaded = true;
  }

  drawElementsRecursive(newElements, parent, xPosition, myParentsYesNo) {
    console.log("sdvsfdakiwedknjae");
    for(let ele in this.elementsOriginal) {
      let i:number = +ele;
      for (let j in this.elementsOriginal[i]['parentsOriginalTMP']) {
        if (this.elementsOriginal[i]['parentsOriginalTMP'][j] === parent['tmpId']) {
          console.log("sdvsfdakiwedknjaeZZzz", parent['tmpId'], parent, this.elementsOriginal[i]);

          let yPosition = parent['y'];

          this.elementsOriginal[i]['parentsTmp'] = [];
          for(let j in newElements) {
            if(newElements[j]['id_type'] == "2") {
              for(let k in newElements[j]['childsOriginalTMP']) {
                if(newElements[j]['childsOriginalTMP'][k] == this.elementsOriginal[i]['tmpId']) {
                  this.elementsOriginal[i]['parentsTmp'].push(newElements[j]['tmpId']);
                }
              }
            }
          }
          if(this.elementsOriginal[i]['parentsTmp'] == []) this.elementsOriginal[i]['parentsTmp'] = [this.actualTmpId - 1];

          if(parent['id_type_action'] == 4) {
            yPosition = yPosition + 100;
            let indexChild = 1;
            this.elementsOriginal[i]['childsTmp'] = [];
            for(let j in parent['childsOriginalTMP']) {
              if(parent['childsOriginalTMP'][j] == this.elementsOriginal[i]['tmpId']) {
                myParentsYesNo = _.cloneDeep(myParentsYesNo);
                console.log(myParentsYesNo, "myParentsYesNomyParentsYesNo");
                if(indexChild == 1) {
                  myParentsYesNo['myParentsYes'].push(this.actualTmpId);
                  myParentsYesNo['myParentsNo'] = [];
                } else {
                  myParentsYesNo['myParentsNo'].push(this.actualTmpId);
                  myParentsYesNo['myParentsYes'] = [];
                }
                this.elementsOriginal[i]['childsTmp'].push(this.actualTmpId);
                if(indexChild == 1) xPosition = parent['x'] - 150;
                else xPosition = parent['x'] + 150;
                let resultIfElse = this.drawYesNoElement(newElements, i, yPosition, xPosition, indexChild - 1, [parent['tmpId']], myParentsYesNo,);
                yPosition = resultIfElse['yPosition'];
                this.elementsOriginal[i]['parentsTmp'] = [resultIfElse['parentTMP'] + 1];
                break;
              }
              ++indexChild;
            }
          } else {
            yPosition = yPosition + 200;
          }

          if(myParentsYesNo['myParentsYes'].length > 0) myParentsYesNo['myParentsYes'].push(this.elementsOriginal[i]['tmpId']);
          else if(myParentsYesNo['myParentsNo'].length > 0) myParentsYesNo['myParentsNo'].push(this.elementsOriginal[i]['tmpId']);
                    
          this.elementsOriginal[i]['x'] = xPosition;
          this.elementsOriginal[i]['y'] = yPosition;
          newElements.push(this.elementsOriginal[i]);
          console.log(this.elementsOriginal[i], parent, "fsdfs");
          
          if(this.elementsOriginal[i]['id_type_action'] != 4 && this.elementsOriginal[i]['id_type_action'] != 16) {
            this.elementsOriginal[i]['childsTmp'] = [this.actualTmpId];
            yPosition = this.drawAddElement(newElements, this.elementsOriginal[i], yPosition, this.elementsOriginal[i]['x'], [this.elementsOriginal[i]['tmpId']], this.elementsOriginal[i]['childsOriginalTMP'], myParentsYesNo);
          }
          if(this.elementsOriginal[i]['id_type_action'] == 4) {
            console.log(this.elementsOriginal[i], "eqfaef", myParentsYesNo);
            myParentsYesNo = this.recalculateWidths(myParentsYesNo, newElements, xPosition);
          }

          this.elementsOriginal[i]['myParentsYesNo'] = myParentsYesNo;

          if(xPosition > this.actualX) this.actualX = xPosition;
          if(yPosition > this.actualY) this.actualY = yPosition;
          
          this.drawElementsRecursive(newElements, this.elementsOriginal[i], xPosition, myParentsYesNo);
        }
      }
    }
  }

  recalculateWidths(myParentsYesNo, newElements, xPosition, addWidthExtra = 0, treatedIdsNo = [], treatedIdsYes = [], yesWidth = 0, noWidth = 0) {
    let addWidth = 150;
    if(myParentsYesNo['myParentsYes'].length > 0) yesWidth = addWidth + addWidthExtra;
    if(myParentsYesNo['myParentsNo'].length > 0) noWidth = addWidth + addWidthExtra;

    this.treatYes(myParentsYesNo, yesWidth, noWidth, newElements, treatedIdsYes, treatedIdsNo);
    this.treatNo(myParentsYesNo, yesWidth, noWidth, newElements, treatedIdsYes, treatedIdsNo);

    if(xPosition > this.actualX) this.actualX = xPosition;

    return myParentsYesNo;
  }

  treatYes(myParentsYesNo, yesWidth, noWidth, newElements, treatedIdsYes, treatedIdsNo) {
    for(let i in myParentsYesNo['myParentsYes']) {
      for(let j in newElements) {
        if(myParentsYesNo['myParentsYes'][i] == newElements[j]['tmpId']) {
          console.log("ewqrfdeqwrfewqfr", newElements[j]['tmpId']);
          treatedIdsYes.push(newElements[j]['tmpId']);
          this.checkGrandParents(yesWidth, noWidth, treatedIdsYes, treatedIdsNo, newElements[j], newElements);
          newElements[j]['x'] = newElements[j]['x'] - yesWidth;
          break;
        }
      }
    }
  }

  treatNo(myParentsYesNo, yesWidth, noWidth, newElements, treatedIdsYes, treatedIdsNo) {
    for(let i in myParentsYesNo['myParentsNo']) {
      for(let j in newElements) {
        if(myParentsYesNo['myParentsNo'][i] == newElements[j]['tmpId']) {
          treatedIdsNo.push(newElements[j]['tmpId']);
          console.log("ewqrfdeqwrfewqfr nooo", newElements[j]['tmpId']);
          this.checkGrandParents(yesWidth, noWidth, treatedIdsYes, treatedIdsNo, newElements[j], newElements);
          newElements[j]['x'] = newElements[j]['x'] + noWidth;
          break;
        }
      }
    }
  }

  checkGrandParents(yesWidth, noWidth, treatedIdsYes, treatedIdsNo, parent, newElements) {
    if(!treatedIdsYes.includes(parent['tmpId']) && parent['myParentsYesNo'] && parent['myParentsYesNo']['myParentsYes'].length > 0) {
      this.recalculateWidths(parent['myParentsYesNo'], newElements, 0, 150, treatedIdsYes, treatedIdsNo, yesWidth, noWidth);
    }
    if(!treatedIdsNo.includes(parent['tmpId']) && parent['myParentsYesNo'] && parent['myParentsYesNo']['myParentsNo'].length > 0) {
      this.recalculateWidths(parent['myParentsYesNo'], newElements, 0, 150, treatedIdsYes, treatedIdsNo, yesWidth, noWidth);
    }
  }

  drawAddElement(newElements, parent, yPosition, xPosition, tmpParents, childs, myParentsYesNo) {
    let widthAdd = 50;
    yPosition = yPosition + 100;
    newElements.push({
      height: 50,
      tmpId: this.actualTmpId,
      id_type: "2",
      name: "+",
      parentsTmp: tmpParents,
      type: "add",
      width: widthAdd,
      x: xPosition + (parent['width']/2) - (widthAdd / 2),
      y: yPosition,
      childsOriginalTMP: parent['childsOriginalTMP'],
      extraHeight: 0,
      childsTmp: childs,
      myParentsYesNo: myParentsYesNo,
    });
    if(myParentsYesNo['myParentsYes'].length > 0) myParentsYesNo['myParentsYes'].push(this.actualTmpId);
    else if(myParentsYesNo['myParentsNo'].length > 0) myParentsYesNo['myParentsNo'].push(this.actualTmpId);
    ++this.actualTmpId;
    return yPosition;
  }

  drawYesNoElement(newElements, i, yPosition, xPosition, indexChild, parentTMP, myParentsYesNo = []) {
    let parent = newElements[newElements.length];
    for(let i in newElements) {
      for(let j in parentTMP) {
        if(parentTMP[j] == newElements[i]['tmpId']) {
          parent = newElements[i];
          break;
        }
      }
    }

    let widthAdd = 35;
    let name = "No";
    let type = "no";
    let extraHeight = 7.5;
    if(indexChild == 0) {
      name = "Yes";
      type = "yes";
    }
    let addElement = {
      height: 35,
      tmpId: this.actualTmpId,
      id_type: "4",
      name: name,
      parentsTmp: parentTMP,
      type: type,
      width: widthAdd,
      x: xPosition + (this.elementsOriginal[i]['width']/2) - (widthAdd / 2),
      y: yPosition + 15,
      childsOriginalTMP: this.elementsOriginal[i]['childsOriginalTMP'],
      extraHeight: extraHeight,
      childsTmp: [this.actualTmpId + 1],
      myParentsYesNo: parent['myParentsYesNo'],
    };
    parentTMP = this.actualTmpId;
    ++this.actualTmpId;
    newElements.push(addElement);
    yPosition = this.drawAddElement(newElements, addElement, yPosition - extraHeight, addElement['x'], [addElement['tmpId']], [this.elementsOriginal[i]['tmpId']], myParentsYesNo);

    yPosition = yPosition + 100;
    return {yPosition: yPosition, parentTMP: parentTMP};
  }

  drawAddTriggerElement(newElements, parent, x) {
    let newTrigger = _.cloneDeep(this.journeyService.journeyActionDefault);
    newTrigger.height = 60;
    newTrigger.tmpId = this.actualTmpId;
    newTrigger.id_type = "3";
    newTrigger.id_category = "2";
    newTrigger.name = "+ New Trigger";
    newTrigger.typeLabel = "Add start point";
    newTrigger.parentsTmp = [];
    newTrigger.type = "newTrigger";
    newTrigger.width = this.triggerWidth;
    newTrigger.x = x;
    newTrigger.y = this.actualY;
    newTrigger.childsOriginalTMP = parent['childsOriginalTMP'];
    newTrigger.childsTmp = parent['childsOriginalTMP'];
    newTrigger.id_journey = parent['id_journey'];
    newTrigger.extraHeight = 0;
    newTrigger.myParentsYesNo = parent['myParentsYesNo'];
    newElements.push(newTrigger);
    ++this.actualTmpId;
  }

  drawAddYesNoElement(newElements, parent, tmpParents) {
    newElements.push({
      height: 50,
      tmpId: this.actualTmpId,
      id_type: "3",
      name: "+ Add Action",
      typeLabel: "Execute after condition",
      parentsOriginalTMP: tmpParents,
      type: "addYesNo",
      width: parent['width'],
      childsOriginalTMP: [],
      extraHeight: 0,
      childsTmp: [],
      myParentsYesNo: {myParentsYes: [], myParentsNo: []},
    });
    ++this.actualTmpId;
  }
 
  calculateHeightWidthWindow() {
    if(this.maxWidth - this.triggerWidth < this.actualX) this.maxWidth = this.actualX + 150 + this.triggerWidth; 
    console.log(this.actualX, this.maxWidth, "this.maxWidth")
    this.maxWidthPX = this.maxWidth + "px";
    this.maxHeight = this.actualY + 100;
    if(this.maxHeight < 350) this.maxHeight = 350;
    this.maxHeightPX = this.maxHeight + "px";
    //document.querySelector('div.outer_container').scrollLeft = 10000;
  }

  sortElements() {
    let newElementsSort = []
    let pushedIds = [];

    for(let i in this.elementsOriginal){
      if(this.elementsOriginal[i]['id_category'] == 2) {
        newElementsSort.push(this.elementsOriginal[i]);
        pushedIds.push(this.elementsOriginal[i]['tmpId']);
      }
    }

    for(let i in this.elementsOriginal) {
      if(this.elementsOriginal[i]['id_category'] == 2) {
        this.sortElementsRecursive(newElementsSort, this.elementsOriginal[i], pushedIds);
      }
    }

    this.elementsOriginal = newElementsSort;
  }
  
  sortElementsRecursive(newElementsSort, parent, pushedIds) {
    for(let i in parent['childsOriginalTMP']) {
      if(!pushedIds.includes(parent['childsOriginalTMP'][i])) {
        for(let j in this.elementsOriginal) {
          if(this.elementsOriginal[j]['tmpId'] == parent['childsOriginalTMP'][i]) {
            newElementsSort.push(this.elementsOriginal[j]);
            pushedIds.push(this.elementsOriginal[j]['tmpId']);
            this.sortElementsRecursive(newElementsSort, this.elementsOriginal[j], pushedIds);
            break;
          }
        }
      }
    }
  }

  assignTypeLabel(element) {
    if(element['id_category'] == 2) {
      for(let j in this.journeyService.triggerTypes) {
        if(this.journeyService.triggerTypes[j]['id'] == element['id_type_action']) {
          element['typeLabel'] = this.journeyService.triggerTypes[j]['name'];
          break;
        }
      }
    } else {
      for(let j in this.journeyService.actionTypes) {
        if(this.journeyService.actionTypes[j]['id'] == element['id_type_action']) {
          element['typeLabel'] = this.journeyService.actionTypes[j]['name'];
          break;
        }
      }
    }

    if(element['id_type_action'] == 1 && element['id_template']) {
      for(let i in this.journeyService.emailTemplates) {
        if(this.journeyService.emailTemplates[i]['id'] == element['id_template']) {
          element['typeSubabel'] = " (" + this.journeyService.emailTemplates[i]['nombre'] + ")";
        }
      }
    } else if(element['id_type_action'] == 2 && element['id_template']) {
      for(let i in this.journeyService.smsTemplates) {
        if(this.journeyService.smsTemplates[i]['id'] == element['id_template']) {
          element['typeSubabel'] = " (" + this.journeyService.smsTemplates[i]['nombre'] + ")";
        }
      }
    } else if(element['id_type_action'] == 3 && element['id_template']) {
      for(let i in this.journeyService.whatappTemplates) {
        if(this.journeyService.whatappTemplates[i]['id'] == element['id_template']) {
          element['typeSubabel'] = " (" + this.journeyService.whatappTemplates[i]['nombre'] + ")";
        }
      }
    } else if((element['id_type_action'] == 4 || element['id_type_action'] == 8 || element['id_type_action'] == 9 || element['id_type_action'] == 11 || element['id_type_action'] == 12) && element['id_tag']) {
      for(let i in this.journeyService.tagsTemplates) {
        if(this.journeyService.tagsTemplates[i]['id'] == element['id_tag']) {
          element['typeSubabel'] = " (" + this.journeyService.tagsTemplates[i]['nombre'] + ")";
        }
      }
    } else if(element['id_type_action'] == 5 && element['id_temporality'] && element['num_temporality']) {
      for(let i in this.journeyService.temporalities) {
        if(this.journeyService.temporalities[i]['id_temporality'] == element['id_temporality']) {
          element['typeSubabel'] = " (" + element['num_temporality'] + " " + this.journeyService.temporalities[i]['name'] + ")";
        }
      }
    } else if(element['elementid_type_action'] == 6 && element['id_action_new']) {
      for(let i in this.journeyService.journeys) {
        if(this.journeyService.journeys[i]['id'] == element['id_action_new']) {
          element['typeSubabel'] = " (" + this.journeyService.journeys[i]['name'] + ")";
        }
      }
    } else if(element['elementid_type_action'] == 7 && element['id_action_new']) {
      for(let i in this.journeyService.journeyAction) {
        if(this.journeyService.journeyAction[i]['id'] == element['id_action_new']) {
          element['typeSubabel'] = " (" + this.journeyService.journeyAction[i]['name'] + ")";
        }
      }
    }

  }

  copyElement(element) {
    this.journeyService.copyElement = element;
    this.functionsService.openSnackBar("Acción copiado. ¡Ya puedes pegarla dónde quieras!", 5000, [
      "green-snackbar",
    ]);
  }

  pasteElement(element) {
    console.log("addElementaddElement", element, this.elementsOriginal);
    this.addNode(element, this.journeyService.copyElement);
    this.functionsService.openSnackBar("Acción añadida correctamente.", 3000, [
      "green-snackbar",
    ]);
    console.log(element, this.journeyService.copyElement);
  }

}