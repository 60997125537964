<p-carousel *ngIf="this.loaded && structure[this.index]['items'] && structure[this.index]['items'].length > 0" [value]="structure[this.index]['items']" 
            [numVisible]="structure[this.index]['carousel_num_visible']" 
            [numScroll]="structure[this.index]['carousel_num_scroll']" 
            [circular]="this.structure[this.index]['carousel_circular'] == 1"
            [page]="this.page"
            [responsiveOptions]="responsiveOptions"
            (onPage)="setPage($event, structure[this.index]['carousel_num_scroll'], structure[this.index]['carousel_num_visible'], structure[this.index]['carousel_num_scroll_s'], structure[this.index]['carousel_num_visible_s'], structure[this.index]['carousel_num_scroll_xs'], structure[this.index]['carousel_num_visible_xs'])"
            [ngClass]="{'show-buttons': structure[this.index]['show_buttons_carousel']}">
    <!--<ng-template pTemplate="header">
        <h5>HEADER</h5>
    </ng-template>-->
    <ng-template let-product pTemplate="item">
        <div class="product-item" *ngIf="product.orden <= maxElement && product.orden >= minElement">
            <div class="product-item-content">
                <div class="p-mb-3" *ngIf="product.image">
                    <img [ngStyle]="{'height': 'calc((10vh + 35vw)/' + this.heightIMG + ')'}" [src]="product.image" (error)="product.image = this.genericService.defaultNoImage">
                </div>
                <div>
                    <h4 class="p-mb-1" *ngIf="product.title" [innerHTML]="product.title | safeHtml"></h4>
                    <h6 class="p-mt-0 p-mb-3" *ngIf="product.description" [innerHTML]="product.description | safeHtml"></h6>
                    <span [class]="'product-badge status-1'" *ngIf="product.text" [innerHTML]="product.text | safeHtml"></span>
                    <ng-container *ngIf="product.element['child']">
                        <app-generic [structure]="product.element"></app-generic>
                    </ng-container>                    
                    <!--<div class="car-buttons p-mt-5">
                        <p-button type="button" styleClass="p-button p-button-rounded p-mr-2" icon="pi pi-search"></p-button>
                        <p-button type="button" styleClass="p-button-success p-button-rounded p-mr-2" icon="pi pi-star"></p-button>
                        <p-button type="button" styleClass="p-button-help p-button-rounded" icon="pi pi-cog"></p-button>
                    </div>-->
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>