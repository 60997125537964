<div class="dialog-container">
  <label class="title-input">Actividades</label>
  <button class="close-button" mat-button (click)="onNoClick()"><i class="material-icons">close</i></button>
</div>
<div id="start-end-dates">
  <mat-form-field class="dates-inputs">
    <input matInput readonly [matDatepicker]="picker1" placeholder="Inicio" [formControl]="startDate">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="dates-inputs">
    <input  matInput readonly [matDatepicker]="picker2" placeholder="Fin" [formControl]="endDate">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
</div>

<div class="bottom-table">
  <div class="bottom-table2" style="width: 40%;">
    <mat-form-field class="input-filter">
      <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>
  </div>
  <mat-paginator style="width: 60%;" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
</div>

<div class="example-container taulesgrans all-activities-table-agenda" style="overflow: auto; height: calc(65vh - 59px); min-width: 50em;">

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Falta poner el disabled en base a permisos -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="openEditEventDialog(element)"><i class="material-icons blue">edit</i></td>
    </ng-container>

    <ng-container matColumnDef="sign">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let index = index">
        <i matTooltip="Firmar actividad" *ngIf="(element.type.firmar === 1) && !element.signImgURL && showFirma" (click)="buttonSignClick(element)" class="material-icons blue">gesture</i>
        <i matTooltip="Ver firma" *ngIf="(element.type.firmar === 1) && element.signImgURL && showFirma" (click)="buttonViewSign(element)" class="material-icons blue">remove_red_eye</i>
      </td>

    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="openDeleteConfirmEventDialog(element)"><i class="material-icons red">delete</i></td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Título</th>
      <td (click)="openEditEventDialog(element)" mat-cell *matCellDef="let element">{{element.title}}</td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Categoría</th>
      <td (click)="openEditEventDialog(element)" mat-cell *matCellDef="let element"><mat-icon [ngStyle]="{'color': element.type.color}">fiber_manual_record</mat-icon>{{element.type.name}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Fecha</th>
      <td (click)="openEditEventDialog(element)" mat-cell *matCellDef="let element">
        <div *ngIf="!element.allDay "class="dates">{{element.start?.toLocaleString([], {day: '2-digit' , month: '2-digit' ,  year : '2-digit',hour: '2-digit', minute:'2-digit',hour12: false})}}<div *ngIf="element.end"> a {{element.end.toLocaleString([], {day: '2-digit' , month: '2-digit' ,  year : '2-digit',hour: '2-digit', minute:'2-digit',hour12: false})}}</div></div>
        <div *ngIf="element.allDay" class="dates">{{element.start?.toLocaleString([], options)}}<div *ngIf="element.end"> a {{element.end.toLocaleString([], options)}}</div></div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<span [ngClass]="{'loader-spinner0':true, 'loader-spinner0-out':loaded}">
  <mat-progress-spinner
    class="loader-spinner"
    [color]="'primary'"
    [value]="75"
    [mode]="'indeterminate'"
    [diameter]="120"
    [strokeWidth]="15">
  </mat-progress-spinner>
</span>
