import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

declare let jQuery: any;

@Component({
  selector: 'two-options-modal',
  templateUrl: './twoOptionsModal.component.html',
  providers: [],
  styleUrls: ['./twoOptionsModal.component.css']
})
export class TwoOptionsModalComponent {

  @Input() title: string;
  @Input() description = '';
  @Input() acceptButtonText = 'Aceptar';
  @Input() declineButtonText = 'Declinar';
  @Input() id: string;

  @Output() onAccept = new EventEmitter();
  @Output() onDecline = new EventEmitter();

  onClickAccept() {
    this.onAccept.emit();
    jQuery('#' + this.id).modal('hide');
  }

  onClickDecline() {
    this.onDecline.emit();
    jQuery('#' + this.id).modal('hide');
  }

}
