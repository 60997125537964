<div style="margin: 0 1em">
  <h3><b>{{ 'web.terminos-y-condiciones.bienvenida' | translate}}</b></h3>

  <p>
    {{ 'web.terminos-y-condiciones.p1' | translate}}
  </p>

  <h4><b>{{ 'web.terminos-y-condiciones.condiciones-generales' | translate}}</b></h4>
  <p>
    {{ 'web.terminos-y-condiciones.p2' | translate}}
  </p>
  <p>
    {{ 'web.terminos-y-condiciones.p3' | translate}}
  </p>
  <p>
    {{ 'web.terminos-y-condiciones.p4-a' | translate}} <a href="https://4dmovin.com" target="_blank" class="link">4dmovin.com</a> {{ 'web.terminos-y-condiciones.p4-b' | translate}} <a href="https://movin.cloud" target="_blank" class="link">movin.cloud</a> {{ 'web.terminos-y-condiciones.p4-c' | translate}}
  </p>
  <p>
    {{ 'web.terminos-y-condiciones.p5' | translate}}
  </p>

  <p>{{ 'web.terminos-y-condiciones.p6' | translate}} </p>
  <p>{{ 'web.terminos-y-condiciones.p7' | translate}} </p>
  <p>{{ 'web.terminos-y-condiciones.p8' | translate}} </p>
  <p>{{ 'web.terminos-y-condiciones.p9' | translate}} </p>
  <p>{{ 'web.terminos-y-condiciones.p10' | translate}} </p>
  <p>{{ 'web.terminos-y-condiciones.p11' | translate}} </p>


  <h4><b>{{ 'web.terminos-y-condiciones.nulidad' | translate}}</b></h4>
  <p>
    {{ 'web.terminos-y-condiciones.p12' | translate}}
  </p>

  <h3><b>{{ 'web.terminos-y-condiciones.politica-privacidad' | translate}}</b></h3>

  <p>
    {{ 'web.terminos-y-condiciones.p13' | translate}}
  </p>
  <p>
    {{ 'web.terminos-y-condiciones.p14' | translate}}
  </p>
  <p>
    {{ 'web.terminos-y-condiciones.p15' | translate}}
  </p>
  <p>
    {{ 'web.terminos-y-condiciones.p16' | translate}}
  </p>
  <p>
    {{ 'web.terminos-y-condiciones.p17' | translate}} <br>
    {{ 'web.terminos-y-condiciones.p18' | translate}}
  </p>

  <h4><b>{{ 'web.terminos-y-condiciones.envio-comunicaciones' | translate}}</b></h4>
  <p>
    {{ 'web.terminos-y-condiciones.p19' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p20' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p21' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p22' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p23' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p24' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p25' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p26' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p27' | translate}}
  </p>

  <h4><b>{{ 'web.terminos-y-condiciones.aceptacion-terminos' | translate}}</b></h4>
  <p>
    {{ 'web.terminos-y-condiciones.p28' | translate}}
  </p>

  <p>
    {{ 'web.terminos-y-condiciones.p29' | translate}}
  </p>

  <div>
    <button class="button-general" mat-button [mat-dialog-close]="true">{{ 'web.terminos-y-condiciones.visto' | translate}}</button>
  </div>
  <br>
</div>
