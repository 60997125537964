import {Component, OnInit, HostListener, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Router} from "@angular/router";
import {GenericService} from "../services/generic/generic.service";
import {AuthService} from "../services/auth/auth.service";
import {RoutingService} from "../services/routing/routing.service";
import { Location } from '@angular/common';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { AdviceReportService } from 'app/services/advice-report/advice-report.service';
@Component({
  selector: 'app-generic-container',
  templateUrl: './generic-container.component.html',
  styleUrls: ['./generic-container.component.css']
})
export class GenericContainerComponent implements OnInit, OnDestroy {

  private moduleId: number;
  public structure = null;
  private containerStructure;
  public loadedStructure = false;
  private monitoreo;
  public classContainer= null;

  @Output() onOpenSidebar = new EventEmitter<string>();


  constructor(private router: Router,
              public genericService: GenericService,
              private authService: AuthService,
              private routingService: RoutingService,
              private location: Location,
              private endpoint: EndpointService,
              public adviceReportService: AdviceReportService) { }

  ngOnInit(): void {
    this.genericService.finishedLoadStructure = false;

    let url = this.router.url;
    this.routingService.prepareRouting();

    if(url[0] != '/') url = '/' + url;
    if(url.indexOf('%23') != -1){
      let re = "%23"
      url = url.replace(re, "#")
    }
    this.genericService.fetchStructure(0).subscribe((data): void => {
      this.routingService.moduleId = data['idModule'];
      this.genericService.originalInputsValues = [];
      this.genericService.formsChanged[data['idModule']] = [];
      
      if(this.authService.isUserSessionActive()) this.classContainer = this.genericService.containerFunctionArea['class_custom'] + " session-active";
      else this.classContainer = this.genericService.containerFunctionArea['class_custom'] + " session-no-active";

      if(this.genericService.staticHTML) this.classContainer = this.genericService.containerFunctionArea['class_custom'] + " staticHTML";
      
      if(!this.genericService.staticHTML) {
        if (url[0] != '/') url = '/' + url;
        this.genericService.pushPage({url: url});
        this.routingService.previousModule = null;
        this.location.replaceState(data['url'])
        
        this.genericService.breadcrumbs = data['breadcrumbs'].reverse()
        this.genericService.highlightHeaderComponents();
        this.routingService.loadedBreadcrumbs = true;
      }
      
      this.structure = data['structure'][0];
      this.containerStructure = data['structure'];
      this.genericService.genericContainerInstance = this;
      this.genericService.formsChanged[this.routingService.moduleId] = {};
      this.genericService.renderedComponents = {};
      this.removeAllDefers(this.structure);

      if(this.routingService.urlHashtag != null && this.routingService.urlHashtag != '') {
        for(let key in this.structure['child']) {
          const x = this.genericService.findElementWithIdLoop(this.structure['child'][key], this.routingService.urlHashtag);
          if(x) {
              this.structure['child'][key]['id_functional_status_general'] = 1;
              this.location.replaceState(this.routingService.urlWithoutHashtag + '#' + x['child']['id_functional_area']);
          }
        }
      }

      this.loadedStructure = true;
      /*this.genericService.promiseAllComponentsRendered(this.genericService.renderedComponents).then(() => {
        this.genericService.renderedFinished = true;
        if(this.routingService.urlHashtag != null){
          this.genericService.scroll(this.routingService.urlHashtag);
        }
      });*/

      if(!this.genericService.staticHTML) {
        this.adviceReportService.newNotifications(this.authService.userId, this.routingService.moduleId, this.authService.getIdEmpresa());
        this.adviceReportService.newNotificationSuperior(this.authService.userId, this.routingService.moduleId, this.authService.getIdEmpresa());
        this.adviceReportService.questions(this.routingService.moduleId);
        //this.adviceReportService.tratarAccionesPendientes();
      }
    });
  }

  ngOnDestroy() {
    this.unloadNotification()
  }

  showChat() {
    this.onOpenSidebar.emit('chat');
  }

  unloadNotification() {
    if (this.monitoreo != null) {
      console.log("UPDATE MONITORING PANTALLA")
      this.endpoint.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
      });
    }
  }

  removeAllDefers(subStructure: any) {
    for(let e in subStructure['child']) {
      if(subStructure['child'][e]['id_functional_type'] == 106) subStructure['child'][e]['id_functional_status_general'] = 2;
      else {
        if(subStructure['child'][e]['child']) {
          this.removeAllDefers(subStructure['child'][e]['child'])
        }
      }
    }
  }
}
