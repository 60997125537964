import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @Output() event = new EventEmitter();

  @Input() modulos: string;
  @Input() new: boolean;

  constructor() { }

  ngOnInit(): void {

  }

  public nextStep(): void {
    if (this.stepper.selectedIndex === this.modulos.length - 1) this.event.emit();
    else this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
  }

}
