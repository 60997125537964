import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { FunctionsService } from 'app/services/functions/functions.service';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-generic-element-dialog',
  templateUrl: './add-generic-element-dialog.component.html',
  styleUrls: ['./add-generic-element-dialog.component.css']
})
export class AddGenericElementDialogComponent implements OnInit {

  searchText: any;
  filteredArray: any;
  selectedItem: any;
  selectedItemClass: any;
  idParent: any;
  isLinear = false;
  newElement: any;

  constructor(public genericCreatorService: GenericCreatorService,
      public functionsService: FunctionsService,
    public dialogRef: MatDialogRef<AddGenericElementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log('idTmp', this.data);
    this.idParent = this.data;
    this.filteredArray = [...this.genericCreatorService.arrayElementsTipo];
    this.newElement = _.cloneDeep(this.genericCreatorService.defaultArray);
    this.genericCreatorService.prepareNewEleToAdd(this.newElement)
  }

  /**
   * Filtering data based on search input value
   * @param event
   * @returns
   */
  filterData(event){
    this.searchText = event?.target?.value
    // no search text, all records
    if (!this.searchText) {
      this.filteredArray = [...this.genericCreatorService.arrayElementsTipo];
      return;
    }

    const allData = [...this.genericCreatorService.arrayElementsTipo];
    const properties = Object.keys(allData[0]);
    if(this.searchText){
      this.filteredArray = allData.filter((item: any) => {
        return properties.find((property) => {
          const valueString = item[property]?.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          return valueString?.includes(this.searchText.toLowerCase());
        })
        ? item
        : null;
      });
    }
  }

  resetSelectedElement(){
    this.genericCreatorService.actualSelectedElement = null;
    this.selectedItemClass = '';
    this.selectedItem = null;
  }

  setActiveItem(item, type, index, stepper: MatStepper){
    this.resetSelectedElement();
    this.selectedItemClass = type+index;
    this.selectedItem = item;
    console.log('selected item', item);
    this.newElement.id_functional_type = item.id_functional_type;
    this.genericCreatorService.actualSelectedElement = this.newElement;
    this.genericCreatorService.filterStatusOptions(this.newElement['id_functional_type']);
    this.genericCreatorService.filterTypes(this.newElement['id_functional_type']);
    if(this.newElement['id_functional_type'] == 137){
      this.newElement['type'] = 57
    } else if (this.newElement['id_functional_type'] == 138){
      this.newElement['type'] = 56
    }
    this.genericCreatorService.expandedIndex = 1;
    if(this.selectedItem.id_functional_type_child !== 0){
      this.genericCreatorService.isAddingElement = true;
    } else {
      this.genericCreatorService.isAddingElement = false;
    }
    stepper.next();
  }

  addItemToArray(){
    if(!this.genericCreatorService.actualSelectedElement.formCreator.valid){
      this.functionsService.openSnackBar("¡Vaya, parece que hay campos obligatorios sin completar!", 7000, ['red-snackbar']);
      return
    }
    this.genericCreatorService.pushNewElement(this.newElement, this.idParent, this.genericCreatorService.arrayElementsGenericCreator);
    this.dialogRef.close(true);
    console.log('>>>>', this.genericCreatorService.actualSelectedElement);
    if(this.newElement.hasSubEle == true){
      this.genericCreatorService.isAddingElement = false;
      this.genericCreatorService.expandedIndex = 2;
    }
  }

}
