import { Component, OnInit, Input } from "@angular/core";
import { FunctionsService } from "app/services/functions/functions.service";
import { GenericService } from "app/services/generic/generic.service";

@Component({
  selector: 'app-messages-template-custom',
  templateUrl: './messages-template-custom.component.html',
  styleUrls: ['./messages-template-custom.component.css']
})
export class MessagesTemplateCustomComponent implements OnInit {
  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;
  public actualStructure = this.genericService.parentStructure;
  public message = "";
  public founded = false;
  public idFaToEdit = {};

  constructor(public functionsService: FunctionsService,
    public genericService: GenericService) { }

  ngOnInit(): void {
    let actualNgForId = this.functionsService.getNgForId(this.structure[this.index]);
    if(actualNgForId != "-1") this.searchFirstNgForElement(actualNgForId, this.actualStructure);
    this.idFaToEdit = this.functionsService.findFAWithTableField(this.actualStructure, this.structure[this.index]['id_db'], this.structure[this.index]['bd_table'], this.structure[this.index]['bd_field'], this.structure[this.index]["id_functional_parent_initial_dsb"]);
    this.message = this.idFaToEdit['tmp_value'];
  }

  addChipCode(htmlCode: string) {
    this.message = htmlCode;
    this.idFaToEdit["form"]["controls"][this.idFaToEdit["id_functional_area"] + "-" + this.idFaToEdit["bd_field"]].setValue(this.message);
  }

  searchFirstNgForElement(ngForId, structure) {
    for(let i in structure) {      
      if(this.functionsService.getNgForId(structure[i]) == ngForId) {
        this.actualStructure = [structure[i]];
        this.founded = true;
        break;
      }
      if(!this.founded && structure[i]['child']) this.searchFirstNgForElement(ngForId, structure[i]['child']);
    }
  }
}
