import { Injectable, EventEmitter, Inject, PLATFORM_ID } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { RedirectionService } from "../redirection/redirection.service";
import * as _ from "lodash";
import { isPlatformServer } from "@angular/common";
import { BehaviorSubject } from "rxjs";

//SERVICES
import { EndpointService } from "../endpoint/endpoint.service";
import { AuthService } from "../auth/auth.service";
import { ChatService } from "../chat.service";
import { RoutingService } from "../routing/routing.service";
import { SwapAccountComponent } from "app/swap-account/swap-account.component";
import { GenericContainerComponent } from "../../generic-container/generic-container.component";
import { StaticTablesService } from "../static-tables.service";
import { NgxFreshChatService } from "ngx-freshchat";
import { Subject } from "rxjs";
import { findAllSubstringIndices } from "@angular/cdk/schematics";
import { DomSanitizer } from "@angular/platform-browser";
import { map } from "rxjs/internal/operators/map";

const ID_FUNCTIONAL_TYPE_TAB = 8;
const ID_FUNCTIONAL_TYPE_EXPANSION = 10;
const ID_FUNCTIONAL_TYPE_FORM = 11;

@Injectable({
  providedIn: "root",
})
export class GenericService {
  public projectID = 0;

  public parentStructure = [];
  public renderedComponents = {};
  public structure = null;
  private idQuery: number;
  private inputValues: any;

  public serviceLanguageGeneric = new Map();
  public notHasBeenDuplicated: boolean = true;
  private lastManagedChanges = 500;
  private lastNameDuplicated: string;
  private lastParent: string;
  private lastOld: string;
  private elemsToDuplicate: string[];
  private lastLabel: string;
  public numTimesFormsChanged = 0;
  private duplicatedIds = {};

  public elementsThatAffectOthers;
  public elementsThatAffectOthersHeader;
  public elementsThatAffectOthersFooter;
  public affectedElements;
  public affectedElementsHeader;
  public affectedElementsFooter;
  public selectOptions = [];
  public selectOptionsHeader = [];
  public selectOptionsFooter = [];
  private data: {};
  public tableData;
  public dsbStructureOpened = [];
  private tableDataHeader;
  private tableDataFooter;
  //public renderedFinished = false;
  public dataValidityOldValues = {};
  public dataValidityBeingChanged = [];
  public statusFunctionalAreas = {};
  public dirtyForm = false;
  public intialFunctionalAreas = {};
  public history = [];
  private historyIndex = -1;
  public redirectByTools = false;
  public genericContainerInstance: GenericContainerComponent;
  private functional_area_changeds = {};

  structureUpdated: EventEmitter<{}> = new EventEmitter();
  public refreshStructureFinished = true;
  public currentInitialArea;
  public containerFunctionArea;

  public warningDialog;
  public warningMessage;
  public warningTitle;
  public warningButton1;
  public warningButton2;
  public readMode = false;

  public lastMenuClick = null;
  public lastListClick = {};
  public headerId = 89;
  public headerNoLoguedId = 707;
  public footerId = 7118;
  public footerNoLoguedId = 7119;
  public finishedLoadStructure = false;
  public finishedLoadStructureHeader = false;
  public finishedLoadStructureFooter = false;

  public externalWindowStructure = [];
  public paramsExternalWindow = false;
  public elementsThatAffectOthersPrevExternalWindow = null;
  public affectedElementsPrevExternalWindow = null;
  public selectOptionsPrevExternalWindow = null;
  public tableDataPrevExternalWindow = null;
  public currentInitialAreaPrevExternalWindow = null;
  public readModePrevExternalWindow = null;
  public parentStructurePrevExternalWindow = null;
  public containerFunctionAreaPrevExternalWindow = null;
  public pendingRevertPrevExternalWindow = false;
  public lastExternalWindowModuleId = 0;
  public firstLoad = true;

  public breadcrumbs = [];
  public urlFullInfo = [];

  public highlightHeaderElements = [];

  public originalInputsValues = {};

  lastPosition: string = "";

  menuVisibilityChange: Subject<string> = new Subject<string>();

  public updateFormFields: Subject<boolean> = new Subject<boolean>();

  public heightTableChange = false;
  public selectOptionsChange = false;
  public selectOptionsChangeEvent: Subject<boolean> = new Subject<boolean>();

  public iconFacesArray3 = [
    { icon: "sentiment_very_dissatisfied", color: "#E30B31" },
    { icon: "sentiment_neutral", color: "#FFB200" },
    { icon: "sentiment_very_satisfied", color: "#44C744" },
  ];
  public iconFacesArray5 = [
    { icon: "sentiment_very_dissatisfied", color: "#E30B31" },
    { icon: "sentiment_dissatisfied", color: "#FF6B00" },
    { icon: "sentiment_neutral", color: "#FFB200" },
    { icon: "sentiment_satisfied", color: "#017F01" },
    { icon: "sentiment_very_satisfied", color: "#44C744" },
  ];

  public tablesActualRows = [];

  public defaultNoImage = "https://movin.cloud/assets/img/no-image.png";

  public formsChanged = {};

  public x = 0;

  public actualFunctionFinish = true;

  public shouldOpenChat: boolean;
  public shouldOpenHelp: boolean;
  public shouldOpenExternalWindow: boolean;

  public staticHTML = false;

  public openInEditMode = false; // Used to indicate if a page is call in EdithMode or not
  public commercialVideoconferenceId;
  public clientToCall;
  public webpSupported = false;

  public headHeight = 41.6;
  public scrollValue = 0;
  private scrollPostionY = new BehaviorSubject<number>(this.scrollValue);
  scrollPostionY$ = this.scrollPostionY.asObservable();

  constructor(
    private endpointService: EndpointService,
    public authService: AuthService,
    public chatService: ChatService,
    private routingService: RoutingService,
    private formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    public staticTablesService: StaticTablesService,
    public chat: NgxFreshChatService,
    private redirectionService: RedirectionService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.menuVisibilityChange.subscribe((value) => {
      this.lastPosition = value;
    });
    this.clientToCall = null;
    this.commercialVideoconferenceId = null;
  }

  toggleMenuVisibility(position) {
    this.menuVisibilityChange.next(position);
  }

  isStructureNotLoaded(): boolean {
    return this.structure === null;
  }

  getNavigatorLang() {
    const lang = navigator.language;

    if (lang.includes("es")) return 1;
    else if (lang.includes("cat")) return 2;
    else if (lang.includes("en")) return 3;

    return 1; // default lang
  }

  loadData(moduleId) {
    return {
      moduleId: +moduleId,
      languageId: localStorage.getItem("_ul_") || this.getNavigatorLang(),
      idAccountType: this.authService.getIdCompanyType(true),
      idCompanyGeneric: this.authService.getIdCompany(true),
      idUser: this.authService.getLoggedInUserId(),
      URL: this.routingService.urlWithoutHashtag,
      URLID: this.routingService.urlHashtag,
      idProfile: this.authService.profileIdGeneric,
      dsbType: 0,
      //idCompany es el idEmpresa y el idCompanyType es si es marca(1) o empresa(2) (los id de los servicios se cogen en backend)
      dsbId: 0,
      lastModule: this.routingService.previousModule,
    };
  }

  fetchStructure(moduleId: number): any {
    let firstKey: any;
    this.data = this.loadData(moduleId);
    return this.endpointService.getStructure(this.data, this.routingService.routeParam).pipe(
      map((data) => {
        this.actualFunctionFinish = true;
        this.projectID = data["response"]["projectID"];
        this.firstLoad = true;
        if (
          data["response"]["checkUserIsDeveloping"] &&
          data["response"]["checkUserIsDeveloping"] == 1
        )
          this.authService.checkUserIsDeveloping = 1;
        firstKey = Object.keys(data["response"]["structure"])[0];
        this.idQuery = data["response"]["structure"][firstKey]["id_query"];
        this.structure = data["response"]["structure"][firstKey];
        this.currentInitialArea = {
          key: data["response"]["structure"][firstKey]["id_functional_area"],
          value: data["response"]["structure"][firstKey]["internal_name"],
        };
        if (this.staticHTML &&data["response"]["dataAccount"]["idUser"] > 0) {
          this.authService.userId = data["response"]["dataAccount"]["idUser"];
          localStorage.setItem("_in_", null);
          this.getUserInfo(2, data["response"]["dataAccount"]["idCompanyGeneric"]);
        }
        this.authService.languageId = data["response"]["languageId"];
        localStorage.setItem("_ul_", this.authService.languageId.toString());
        this.authService.labelLanguage = data["response"]["labelLanguage"];
        this.authService.arrayLanguages = data["response"]["languagesInfo"];
        this.routingService.url = data["response"]["url"];
        this.urlFullInfo = data["response"]["urlFullInfo"];
        if (!this.staticHTML)
          this.readMode = data["response"]["structure"][firstKey]["readMode"];

        this.parentStructure = this.structure['child'];
        this.assignClass(data["response"]["structure"][firstKey], 1);
        this.containerFunctionArea = data["response"]["structure"][firstKey];

        localStorage.setItem(
          "paramControl",
          JSON.stringify(data["response"]["params"])
        );

        if (data["response"]['error'] == -1) {
          this.snackBar.open('¡Vaya, parece que no has iniciado sesión!', 'X', {
            duration: 3000,
            panelClass: ['lightred-snackbar']
          });
          this.location.replaceState("/" + this.authService.labelLanguage + '/login');

        } else if (data["response"]['error'] == -2 && data['response']['idModule'] != this.footerId) {
          this.snackBar.open('¡Vaya, parece que no tienes permisos para acceder a esta pantalla!', 'X', {
            duration: 3000,
            panelClass: ['lightred-snackbar']
          });
          this.location.replaceState("/" + this.authService.labelLanguage + '/inicio');

        } else if (data["response"]['error'] == -3) {
          this.snackBar.open('¡Vaya, parece que este usuario no existe o se encuentra inactivo!', 'X', {
            duration: 3000,
            panelClass: ['lightred-snackbar']
          });

        } else if (data['response']['error'] == -999) {
          let panelClass = [];
          if (this.shouldOpenExternalWindow) panelClass.push('overAllDialog');
          const dialogRef = this.dialog.open(SwapAccountComponent, {
            width: 'auto',
            height: 'auto',
            panelClass: panelClass
          });

          dialogRef.afterClosed().subscribe((data) => {
            if (data.cancel) {
              if (data.reload) {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            } else {
              this.changeAccount(data.index);
              if (
                this.router.url ===
                "/" + this.authService.labelLanguage + "/inicio"
              ) {
                this.refreshStructure(2);
              } else {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            }
          });
        }

        if (
          data["response"]["structure"][firstKey]["id_functional_area"] ==
          this.headerId ||
          data["response"]["structure"][firstKey]["id_functional_area"] ==
          this.headerNoLoguedId ||
          data["response"]["structure"][firstKey]["id_functional_area"] ==
          this.footerId ||
          data["response"]["structure"][firstKey]["id_functional_area"] ==
          this.footerNoLoguedId
        ) {
          this.elementsThatAffectOthers =
            data["response"]["elementsThatAffectOthers"];
          this.elementsThatAffectOthersHeader =
            data["response"]["elementsThatAffectOthers"];
          this.elementsThatAffectOthersFooter =
            data["response"]["elementsThatAffectOthers"];

          this.affectedElements = data["response"]["affectedElements"];
          this.affectedElementsHeader = data["response"]["affectedElements"];
          this.affectedElementsFooter = data["response"]["affectedElements"];

          this.selectOptions = data["response"]["results"];
          this.selectOptionsHeader = data["response"]["results"];
          this.selectOptionsFooter = data["response"]["results"];

          this.tableData = data["response"]["tableData"];
          this.tableDataHeader = data["response"]["tableData"];
          this.tableDataFooter = data["response"]["tableData"];
        } else {
          if (
            this.elementsThatAffectOthersHeader &&
            Object.keys(this.elementsThatAffectOthersHeader).length === 0 &&
            Object.getPrototypeOf(this.elementsThatAffectOthersHeader) ===
            Object.prototype
          ) {
            this.elementsThatAffectOthers =
              data["response"]["elementsThatAffectOthers"];
          } else {
            this.elementsThatAffectOthers = {
              ...data["response"]["elementsThatAffectOthers"],
              ...this.elementsThatAffectOthersHeader,
            };
          }

          if (
            this.affectedElementsHeader &&
            Object.keys(this.affectedElementsHeader).length === 0 &&
            Object.getPrototypeOf(this.affectedElementsHeader) ===
            Object.prototype
          ) {
            this.affectedElements = data["response"]["affectedElements"];
          } else {
            this.affectedElements = {
              ...data["response"]["affectedElements"],
              ...this.affectedElementsHeader,
            };
          }

          if (
            this.selectOptionsHeader &&
            Object.keys(this.selectOptionsHeader).length === 0 &&
            Object.getPrototypeOf(this.selectOptionsHeader) ===
            Object.prototype
          ) {
            this.selectOptions = data["response"]["results"];
          } else {
            this.selectOptions = {
              ...data["response"]["results"],
              ...this.selectOptionsHeader,
            };
          }

          if (this.tableDataHeader && Object.keys(this.tableDataHeader).length === 0 && Object.getPrototypeOf(this.tableDataHeader) === Object.prototype
          ) {
            this.tableData = data["response"]["tableData"];
          } else {
            this.tableData = {
              ...data["response"]["tableData"], ...this.tableDataHeader,
            };
          }
        } if (moduleId == 0) moduleId = data["response"]["idModule"];
        let struct = []; if (struct['child'] !== undefined) struct = this.structure['child'];
        else struct = [this.structure];
        if (this.structure !== undefined) this.asignFrontend(struct, moduleId);
        this.getInputValues();
        this.findFormAttributes(struct);
        if (moduleId == this.headerNoLoguedId || moduleId == this.headerId) return this.structure;
        if (moduleId == this.footerNoLoguedId || moduleId == this.footerId) return this.structure;
        else return data["response"];
      }));
  }

  fetchStructureExternalWindow(moduleId: number): any {
    let firstKey: any;
    this.data = this.loadData(moduleId);
    this.lastExternalWindowModuleId = moduleId;
    return this.endpointService.getStructure(this.data, this.routingService.routeParam, this.paramsExternalWindow).subscribe((data) => {
      if(this.shouldOpenExternalWindow) {
        this.pendingRevertPrevExternalWindow = true;
        this.actualFunctionFinish = true;
        this.projectID = data["response"]['projectID'];
        if (data["response"]['checkUserIsDeveloping'] && data["response"]['checkUserIsDeveloping'] == 1) this.authService.checkUserIsDeveloping = 1;
        firstKey = Object.keys(data["response"]['structure'])[0];
        this.idQuery = data["response"]['structure'][firstKey]["id_query"];
        this.structure = data["response"]['structure'][firstKey];
        this.externalWindowStructure = data["response"]['structure'];
        if (!this.staticHTML && this.firstLoad) {
          this.readModePrevExternalWindow = _.cloneDeep(this.readMode);
          this.readMode = data["response"]['structure'][firstKey]['readMode'];
        }
        if (this.firstLoad) {
          this.currentInitialAreaPrevExternalWindow = _.cloneDeep(this.currentInitialArea);
          this.currentInitialArea = { key: data["response"]['structure'][firstKey]['id_functional_area'], value: data["response"]['structure'][firstKey]['internal_name'] };
          this.parentStructurePrevExternalWindow = _.cloneDeep(this.parentStructure);
          this.parentStructure = this.structure;
          this.containerFunctionAreaPrevExternalWindow = _.cloneDeep(this.containerFunctionArea);
          this.containerFunctionArea = data["response"]['structure'][firstKey];
        }
  
        this.assignClass(data["response"]['structure'][firstKey], 1);
  
        if (this.firstLoad) {
          this.elementsThatAffectOthersPrevExternalWindow = _.cloneDeep(this.elementsThatAffectOthers);
          this.affectedElementsPrevExternalWindow = _.cloneDeep(this.affectedElements);
          this.selectOptionsPrevExternalWindow = _.cloneDeep(this.selectOptions);
          this.tableDataPrevExternalWindow = _.cloneDeep(this.tableData);
          this.elementsThatAffectOthers = data["response"]["elementsThatAffectOthers"];
          this.affectedElements = data["response"]["affectedElements"];
          this.selectOptions = data["response"]["results"];
          this.tableData = data["response"]["tableData"];
        }
  
        if (data["response"]["error"] == -1) {
          this.snackBar.open("¡Vaya, parece que no has iniciado sesión!", "X", {
            duration: 3000,
            panelClass: ["lightred-snackbar"],
          });
          this.shouldOpenExternalWindow = false;
        } else if (data["response"]["error"] == -2 && data["response"]["idModule"] != this.footerId) {
          this.snackBar.open(
            "¡Vaya, parece que no tienes permisos para acceder a esta pantalla!",
            "X",
            {
              duration: 3000,
              panelClass: ["lightred-snackbar"],
            }
          );
          this.shouldOpenExternalWindow = false;
        } else if (data["response"]["error"] == -3) {
          this.snackBar.open(
            "¡Vaya, parece que este usuario no existe o se encuentra inactivo!",
            "X",
            {
              duration: 3000,
              panelClass: ["lightred-snackbar"],
            }
          );
          this.shouldOpenExternalWindow = false;
        } else if (data["response"]["error"] == -999) {
          let panelClass = [];
          if (this.shouldOpenExternalWindow) panelClass.push("overAllDialog");
          const dialogRef = this.dialog.open(SwapAccountComponent, {
            width: "auto",
            height: "auto",
            panelClass: panelClass,
          });
  
          dialogRef.afterClosed().subscribe((data) => {
            if (data.cancel) {
              if (data.reload) {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            } else {
              this.changeAccount(data.index);
              if (
                this.router.url ===
                "/" + this.authService.labelLanguage + "/inicio"
              ) {
                this.refreshStructure(2);
              } else {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            }
          });
        }
  
        if (moduleId == 0) moduleId = data["response"]["idModule"];
        let struct = []; if (struct['child'] !== undefined) struct = this.structure['child'];
        else struct = [this.structure];
        if (this.structure !== undefined) this.asignFrontend(struct, moduleId);
        this.getInputValues();
        this.findFormAttributes(struct);
        this.authService.externalWindowStructure = this.structure;
        this.firstLoad = false;
      }
    });
  }

  fetchDSBStructure(moduleId: number, dsbId = 0, idQuery, type) {
    if (dsbId != 0) {
      let info = {
        moduleId: +moduleId,
        languageId: this.authService.languageId || this.getNavigatorLang(),
        idAccountType: this.authService.getIdCompanyType(true),
        idCompanyGeneric: this.authService.getIdCompany(true),
        idUser: this.authService.getLoggedInUserId(),
        URL: this.routingService.urlWithoutHashtag,
        idProfile: this.authService.profileIdGeneric,
        dsbType: type,
        //idCompany es el idEmpresa y el idCompanyType es si es marca(1) o empresa(2) (los id de los servicios se cogen en backend)
        dsbId: dsbId,
        lastModule: null,
      };

      return this.endpointService.getStructure(info, this.routingService.routeParam).pipe(
          map((data) => {
            if (
              data["response"]["checkUserIsDeveloping"] &&
              data["response"]["checkUserIsDeveloping"] == 1
            )
              this.authService.checkUserIsDeveloping = 1;
            console.log(data);
            let struct = data["response"]["structure"];
            if (struct && struct.length > 0) {
              this.elementsThatAffectOthers = {
                ...this.elementsThatAffectOthers,
                ...data["response"]["elementsThatAffectOthers"],
              };
              this.affectedElements = {
                ...this.affectedElements,
                ...data["response"]["affectedElements"],
              };
              this.selectOptions = {
                ...this.selectOptions,
                ...data["response"]["results"],
              };
              this.tableData = {
                ...this.tableData,
                ...data["response"]["tableData"],
              };
              this.asignFrontend(struct, moduleId);
              this.getInputValuesDSB(struct, idQuery);
              this.findFormAttributes(struct);
              this.dsbStructureOpened = struct;
              return struct;
            } else {
              this.snackBar.open(
                "¡Vaya, parece que no tienes permisos para acceder a este contenido!",
                "X",
                {
                  duration: 3000,
                  panelClass: ["lightred-snackbar"],
                }
              );
              this.dialog.closeAll();
            }
          })
        );
    }
  }

  public async refresh() {
    this.redirectByTools = true;
    if (
      this.formsChanged[this.currentInitialArea["key"]] &&
      Object.keys(this.formsChanged[this.currentInitialArea["key"]]).length > 0
    ) {
      const status = await this.openWarningDialog(1, 1);
      if (status) {
        this.refreshStructure(0);
      }
    } else this.refreshStructure(0);
  }

  refreshStructure(type = 0) {
    this.refreshStructureFinished = true;
    this.dialog.closeAll();
    switch (type) {
      case 0:
        // Recarga generic (solo pantalla, sin header, y si hay external solo la external)
        if (!this.shouldOpenExternalWindow) {
          this.routingService.reloadPage(this.router.url.split("#", 1)[0]);
          this.authService.externalWindowStructure = null;
        } else {
          this.authService.externalWindowStructure = null;
          this.fetchStructureExternalWindow(this.lastExternalWindowModuleId);
        }
        break;
      case 1:
        // Recarga generic (pantalla y header)
        this.authService.headerStructure = null;
        this.authService.footerStructure = null;
        this.shouldOpenExternalWindow = false;
        this.authService.externalWindowStructure = null;
        this.loadHeaderStructure();
        this.loadFooterStructure();
        this.routingService.reloadPage(this.router.url.split("#", 1)[0]);
        break;
      case 2:
        // Recarga todo
        window.location.reload();
        break;
    }
  }

  updateResults() {
    console.log(this.selectOptions);
    let ids = Object.keys(this.selectOptions);
    console.log(ids);
    let elements = this.getFAFromIds({ child: this.parentStructure }, ids, []);
    console.log(elements);
    let params = [];
    if (elements.length > 0) {
      if (!this.staticHTML) {
        let paramControl = JSON.parse(localStorage.getItem("paramControl"));
        paramControl = paramControl[this.authService.getIdCompany(true)];
        for (let x in paramControl) {
          if (paramControl[x]["idPantalla"] == this.currentInitialArea)
            params = paramControl[x]["params"]["input"];
        }
      }

      this.endpointService
        .updateResults(elements, this.data, params)
        .subscribe((data) => {
          this.selectOptions = data["response"];
          this.selectOptionsChange = !this.selectOptionsChange;
          this.selectOptionsChangeEvent.next(this.selectOptionsChange);
        });
    }
  }

  getFAFromIds(subStructure, ids, elements) {
    if (subStructure !== null && subStructure !== undefined) {
      console.log(subStructure["id_query"]);
      if (ids.includes(String(subStructure["id_query"]))) {
        elements.push({
          id_query: subStructure["id_query"],
          form_field: subStructure["form_field"],
          query_id_db: subStructure["query_id_db"],
          affect_permissions_module: subStructure["affect_permissions_module"],
          only_owner: subStructure["only_owner"],
          id_owner_field_filter: subStructure["id_owner_field_filter"],
          type: subStructure["type"],
        });
      }
      if (
        subStructure["child"] !== null &&
        subStructure["child"] !== undefined
      ) {
        for (let e in subStructure["child"])
          this.getFAFromIds(subStructure["child"][e], ids, elements);
      }
    }
    return elements;
  }

  refreshOriginalInputsValues(subStructure: any) {
    if (subStructure !== null) {
      for (const key in subStructure) {
        if (subStructure[key]["child"]) {
          this.refreshOriginalInputsValues(subStructure[key]["child"]);
        }
        // Comprovo el seu index
        if (
          this.originalInputsValues[
          subStructure[key]["id_functional_area"] +
          "-" +
          subStructure[key]["bd_field"]
          ] != undefined
        ) {
          // Comprovo que sigui diferent
          if (
            this.originalInputsValues[
            subStructure[key]["id_functional_area"] +
            "-" +
            subStructure[key]["bd_field"]
            ] !=
            subStructure[key]["form"]["controls"][
            subStructure[key]["id_functional_area"] +
            "-" +
            subStructure[key]["bd_field"]
            ]
          )
            this.originalInputsValues[
              subStructure[key]["id_functional_area"] +
              "-" +
              subStructure[key]["bd_field"]
            ] = _.cloneDeep(
              subStructure[key]["form"]["controls"][
              subStructure[key]["id_functional_area"] +
              "-" +
              subStructure[key]["bd_field"]
              ]
            );
        }
      }
    }
  }

  buildServiceLanguageGeneric(
    service: string,
    name: string,
    tree,
    fieldType,
    text
  ) {
    //Construim un mapa per tal de accedir rapidament i de forma ordenada i concisa a les posicions que necessitem
    if (service == "authService") {
      if (this.serviceLanguageGeneric.get(service) == null) {
        //No hi ha aparegut encara cap variable del authService, el creo i l'inicialitzo
        let mapId = new Map(),
          list = [],
          obj = {};
        obj[fieldType] = text;
        list.push(obj);
        mapId.set(tree["id_functional_area"], list);
        let mapName = new Map();
        mapName.set(name, mapId);
        this.serviceLanguageGeneric.set(service, mapName);
      } else {
        //Hi ha aparegut, comprobo la paraula
        if (this.serviceLanguageGeneric.get(service).get(name) == null) {
          //No hi ha aparegut encara cap variable del amb aquest nom, la creo i l'inicialitzo
          let mapId = new Map(),
            list = [],
            obj = {};
          obj[fieldType] = text;
          list.push(obj);
          mapId.set(tree["id_functional_area"], list);
          this.serviceLanguageGeneric.get(service).set(name, mapId);
        } else {
          //Hi ha aparegut, comprobo el seu id_functional_area
          if (
            this.serviceLanguageGeneric
              .get(service)
              .get(name)
              .get(tree["id_functional_area"]) == null
          ) {
            //No hi ha aparegut encara cap variable del amb aquest nom, la creo i l'inicialitzo
            let list = [],
              obj = {};
            obj[fieldType] = text;
            list.push(obj);
            this.serviceLanguageGeneric
              .get(service)
              .get(name)
              .set(tree["id_functional_area"], list);
          } else {
            //Ja ha aparegut
            let obj = {};
            obj[fieldType] = text;
            this.serviceLanguageGeneric
              .get(service)
              .get(name)
              .get(tree["id_functional_area"])
              .push(obj);
          }
        }
      }
    } else if (service == "chatService") {
      if (this.serviceLanguageGeneric.get(service) == null) {
        //No hi ha aparegut encara cap variable del authService, el creo i l'inicialitzo
        let mapId = new Map(),
          list = [],
          obj = {};
        obj[fieldType] = text;
        list.push(obj);
        mapId.set(tree["id_functional_area"], list);
        let mapName = new Map();
        mapName.set(name, mapId);
        this.serviceLanguageGeneric.set(service, mapName);
      } else {
        //Hi ha aparegut, comprobo la paraula
        if (this.serviceLanguageGeneric.get(service).get(name) == null) {
          //No hi ha aparegut encara cap variable del amb aquest nom, la creo i l'inicialitzo
          let mapId = new Map(),
            list = [],
            obj = {};
          obj[fieldType] = text;
          list.push(obj);
          mapId.set(tree["id_functional_area"], list);
          this.serviceLanguageGeneric.get(service).set(name, mapId);
        } else {
          //Hi ha aparegut, comprobo el seu id_functional_area
          if (
            this.serviceLanguageGeneric
              .get(service)
              .get(name)
              .get(tree["id_functional_area"]) == null
          ) {
            //No hi ha aparegut encara cap variable del amb aquest nom, la creo i l'inicialitzo
            let list = [],
              obj = {};
            obj[fieldType] = text;
            list.push(obj);
            this.serviceLanguageGeneric
              .get(service)
              .get(name)
              .set(tree["id_functional_area"], list);
          } else {
            //Ja ha aparegut
            let obj = {};
            obj[fieldType] = text;
            this.serviceLanguageGeneric
              .get(service)
              .get(name)
              .get(tree["id_functional_area"])
              .push(obj);
          }
        }
      }
    }
  }

  getTextGeneric(text, tree, fieldType) {
    text.toString();
    //Aqui tractarem cada text i substituirem els seus parametres pels valor que els corresponen
    //Realitzarem un bucle iteran sobre el proxim index corresponent a '{'
    let indexFrom = 0;
    if (text.indexOf("<html") > -1) {
      indexFrom = text.indexOf("</html>");
    }
    let index = text.indexOf("{{", indexFrom);
    let correctText = "";
    let totalText = text;
    if (index == -1) return text;
    while (index != -1) {
      //Concatenem el correct text fins la proxima aparicio d'una variable
      correctText += text.substring(0, index);
      //Treiem el index del final de la variable
      let indexFin = text.indexOf("}}");
      let size = indexFin - index;
      //Definim la variable sense {{ }}
      let variable = text.substr(index + 2, size - 2);
      variable = variable.split(".");

      if (variable[1] && variable[0]) {
        this.buildServiceLanguageGeneric(
          variable[0],
          variable[1],
          tree,
          fieldType,
          totalText
        );
        //La separem y agafem el seu valor
        //En aquest punt ja hem obtingut la variable, per tant l'afegim al text
        //S'ha de tenir importat el service per que aquesta linea funcioni
        console.log(variable[0], variable[1], "variables", tree);
        correctText += this[variable[0]][variable[1]];
      } else {
        return text;
      }
      //El text pasa a ser el restant del tractament de la variable
      text = text.substring(indexFin);
      index = text.indexOf("{{");
    }
    return correctText;
  }

  getServiceLanguage(tree) {
    //Tractarem el cas dels texts que tenen variables per asignar dins d'ells
    //Per realitzar aixo, MOLT IMPORTANT que els noms que arribin de la BD siguin correctes i que estiguin importats
    //amb el mateix nom, és a dir, authService.xxxxxxx / endpointService.xxxxxxxx i que aquests estiguin importats i amb el mateix nom
    if (tree["description"] != null) {
      if (tree["description"] != "" && typeof tree["description"] === "string")
        tree["description"] = this.getTextGeneric(
          tree["description"],
          tree,
          "description"
        );
    }
    if (tree["text"] != null) {
      if (tree["text"] != "" && typeof tree["text"] === "string")
        tree["text"] = this.getTextGeneric(tree["text"], tree, "text");
    }
    if (tree["label"] != null) {
      if (tree["label"] != "" && typeof tree["label"] === "string")
        tree["label"] = this.getTextGeneric(tree["label"], tree, "label");
    }
    if (tree["hint"] != null) {
      if (tree["hint"] != "" && typeof tree["hint"] === "string")
        tree["hint"] = this.getTextGeneric(tree["hint"], tree, "hint");
    }
    if (tree["tooltip"] != null) {
      if (tree["tooltip"] != "" && typeof tree["tooltip"] === "string")
        tree["toolip"] = this.getTextGeneric(tree["tooltip"], tree, "tooltip");
    }
    if (tree["tmp_value"] != null) {
      if (tree["tmp_value"] != "" && typeof tree["tmp_value"] === "string")
        tree["tmp_value"] = this.getTextGeneric(
          tree["tmp_value"],
          tree,
          "tmp_value"
        );
    }
    if (tree["badge"] != null) {
      if (tree["badge"] != "" && typeof tree["badge"] === "string")
        tree["badge"] = this.getTextGeneric(tree["badge"], tree, "badge");
    }
    if (tree["internal_routing"] != null) {
      if (
        tree["internal_routing"] != "" &&
        typeof tree["internal_routing"] === "string"
      )
        tree["internal_routing"] = this.getTextGeneric(
          tree["internal_routing"],
          tree,
          "internal_routing"
        );
    }
    if (tree["url"] != null) {
      if (
        tree["url"].substring(0, 1) == "{" &&
        typeof tree["url"] === "string"
      ) {
        let newImage = tree["url"].substring(2, tree["url"].length - 2);
        let imageService = newImage.split(".");
        this.buildServiceLanguageGeneric(
          imageService[0],
          imageService[1],
          tree,
          "url",
          tree["url"]
        );
        tree["url"] = this[imageService[0]][imageService[1]];
        //URL tiene la url definitica
      }
    }
  }

  getInputValues(): void {
    let id: string;
    let params: {};

    if (this.checkIfNeedDoQuery()) {
      //this value was stored in the local storage by the previous page
      id = localStorage.getItem("movin1");
      //console.log("IDDDDD1", id)
      if (typeof id !== "undefined" && id !== null) {
        params = { idClient: id };

        this.endpointService
          .fetchInputsValues(this.idQuery, params)
          .subscribe((data) => {
            this.inputValues = data["response"];
            this.fillInputValues(this.structure);
          });
      }
    }
  }

  getInputValuesDSB(structureDSB, idQuery): void {
    let id: string;
    let params: {};

    if (idQuery != 0) {
      //this value was stored in the local storage by the previous page
      id = localStorage.getItem("movin1");
      console.log("IDDDD", id);
      if (typeof id !== "undefined" && id !== null) {
        params = { idClient: id };

        this.endpointService
          .fetchInputsValues(idQuery, params)
          .subscribe((data) => {
            this.inputValues = { ...this.inputValues, ...data["response"] };
            console.log("INPUTS ", data);
            this.fillInputValues(structureDSB);
          });
      }
    }
  }

  checkIfNeedDoQuery(): boolean {
    return this.idQuery !== 0;
  }

  fillInputValues(subStructure: any): void {
    for (const key in subStructure) {
      if (subStructure.hasOwnProperty(key)) {
        if (subStructure[key]["form_field"] == 1) {
          for (const bdField in this.inputValues[0]) {
            if (
              bdField === subStructure[key]["bd_field"] &&
              this.inputValues[0].hasOwnProperty(bdField)
            ) {
              subStructure[key]["functional_value"] =
                this.inputValues[0][bdField];
            }
          }
        } else if (
          subStructure[key]["id_functional_type"] ===
          ID_FUNCTIONAL_TYPE_EXPANSION
        ) {
          this.fillInputValues(subStructure[key]["child"]);
        } else if (
          subStructure[key]["id_functional_type"] === ID_FUNCTIONAL_TYPE_FORM
        ) {
          this.fillInputValues(subStructure[key]["child"]);
        } else if (
          subStructure[key]["id_functional_type"] === ID_FUNCTIONAL_TYPE_TAB
        ) {
          this.fillInputValues(subStructure[key]["child"]);
        }
      }
    }
  }

  fillSelectValues(idQuery): any {
    return this.endpointService.getSelectionOptions(this.data, idQuery).pipe(
      map((data) => {
        return data["response"];
      })
    );
  }

  findFormAttributes(subStructure: any): void {
    if (subStructure && subStructure !== null) {
      //let paramControl = localStorage.getItem("paramControl");
      console.log(subStructure, "sdfdsf");
      for (const key in subStructure) {
        if (subStructure[key]["id_functional_area"] == "4990.1") console.log(subStructure[key], key, " key sdfdsf");
        if (subStructure[key]["validatedForms"] != undefined && subStructure[key]["validatedForms"]) continue;
        if (subStructure[key]["id_functional_type"] == 11) {
          let data = {};
          data["tablesToBeUpdated"] = {};
          data["formAttributes"] = [];
          let tables: string[] = [];
          this.buildFormAttributes(subStructure[key], tables, data);
          let genericForm = this.formBuilder.group(data["formAttributes"]);

          this.passFormToChilds(subStructure[key], genericForm);
        }
        this.findFormAttributes(subStructure[key]["child"]);
        subStructure[key]["validatedForms"] = true;
      }
      //localStorage.removeItem('paramControl');
      //localStorage.setItem('paramControl', paramControl)
    }
  }

  buildFormAttributes(subStructure: any, bdTables: string[], data: {}): void {
    let bdFieldComponent: string;
    let bdTable: string;
    if (subStructure !== null && typeof subStructure !== "undefined") {
      if (subStructure["form_field"] == 1) {
        bdFieldComponent = subStructure["bd_field"];
        bdTable = subStructure["bd_table"];

        data["formAttributes"][
          subStructure["id_functional_area"] + "-" + bdFieldComponent
        ] = "";

        if (!bdTables.includes(bdTable)) {
          bdTables.push(bdTable);
          data["tablesToBeUpdated"][bdTable] = {};
        }

        data["tablesToBeUpdated"][bdTable][bdFieldComponent] = "";
      } else if (
        subStructure["id_functional_type"] === 10 ||
        subStructure["id_functional_type"] === 16 ||
        subStructure["id_functional_type"] === 91 ||
        subStructure["id_functional_type"] === 11 ||
        subStructure["id_functional_type"] === 7
      ) {
        // 10 expansion-group, 91 box, 16 radio group
        for (const key in subStructure["child"]) {
          this.buildFormAttributes(subStructure["child"][key], bdTables, data);
        }
      }
    }
  }

  passFormToChilds(subStructure: any, genericForm: UntypedFormGroup) {
    if (subStructure !== null && subStructure !== undefined) {
      if (subStructure["form_field"] == 1) {
        subStructure["form"] = genericForm;
      } else if (subStructure["id_functional_type"] === 11) {
        subStructure["form"] = genericForm;
        for (const key in subStructure["child"]) {
          this.passFormToChilds(subStructure["child"][key], genericForm);
        }
      } else if (
        subStructure["id_functional_type"] === 10 ||
        subStructure["id_functional_type"] === 16 ||
        subStructure["id_functional_type"] === 91 ||
        subStructure["id_functional_type"] === 7
      ) {
        for (const key in subStructure["child"]) {
          this.passFormToChilds(subStructure["child"][key], genericForm);
        }
      }
    }
  }

  public findFormControl(table) {
    let data = [];
    for (let s in this.parentStructure) {
      data.push(this.findFormControls_rec(this.parentStructure[s], table));
    }

    return data;
  }

  private findFormControls_rec(current, filter) {
    if (current) {
      let data = [];
      for (let child in current["child"]) {
        if (
          current["child"][child] &&
          current["child"][child]["id_functional_area"] !=
          filter["id_functional_area"] &&
          current["child"][child]["bd_table"] == filter["bd_table"] &&
          current["child"][child]["bd_field"] == filter["bd_field"] &&
          current["child"][child]["id_db"] == filter["id_db"] &&
          current["child"][child]["form_field"] == "1"
          //current['child'][child]['window'] == filter['window']
        ) {
          if (
            current["child"][child]["form"]["controls"][
            current["child"][child]["bd_field"]
            ]["value"] != ""
          ) {
            data.push(
              current["child"][child]["form"]["controls"][
              current["child"][child]["bd_field"]
              ]["value"]
            );
          }
        }
        let data2 = this.findFormControls_rec(current["child"][child], filter);
        if (data2) data = data.concat(data2);
      }
      return data;
    }
  }

  public findSelect(table) {
    let data = [];
    for (let s in this.parentStructure) {
      let data2 = this.findFormSelect_rec(this.parentStructure[s], table);
      if (data2 != undefined || data2.length != 0) data.push(data2);
    }

    return data;
  }

  private findFormSelect_rec(current, filter) {
    if (current) {
      let data = [];
      for (let child in current["child"]) {
        if (
          current["child"][child] &&
          current["child"][child]["id_functional_area"] !=
          filter["id_functional_area"] &&
          current["child"][child]["bd_table"] == filter["bd_table"] &&
          current["child"][child]["bd_field"] == filter["bd_field"] &&
          current["child"][child]["id_db"] == filter["id_db"] &&
          current["child"][child]["form_field"] == "1"
        ) {
          data.push(current["child"][child]);
        }
        let data2 = this.findFormControls_rec(current["child"][child], filter);
        if (data2 != undefined || data2.length != 0) {
          data = data.concat(data2);
        }
      }
      return data;
    }
  }

  public findCount(current) {
    let max = 0;
    if (current) {
      for (let child in current["child"]) {
        let ext = 0;
        let max_child = this.findCount(current["child"][child]);
        let id = (current["child"][child]["id_functional_area"] + "").split(
          "."
        );
        if (id.length > 1) {
          ext = Number(id[1]);
        }
        max = Math.max(ext, max_child);
      }
      return max;
    }
  }

  public translateTree(table) {
    let data = [];
    for (let s in this.parentStructure) {
      //const max = this.findCount(this.parentStructure[s]);
      this.translateRec(this.parentStructure[s], table);
    }
  }

  private inverseTag(result, oldvalue, newvalue) {
    return result.replaceAll(oldvalue, newvalue);
  }

  public translateRec(current, filter) {
    if (current) {
      for (let key in current["child"]) {
        if (current["child"][key]["window"] == filter["window"]) {
          if (
            current["child"][key]["id_query_language"] != "" &&
            current["child"][key]["id_query_language"] != null &&
            current["child"][key]["id_query_language"] != 0
          ) {
            if (Array.isArray(filter["value"])) {
              for (let i = 0; i < length; ++i) {
                const value = filter["value"][i];
                const names = [
                  "name",
                  "description",
                  "text",
                  "label",
                  "hint",
                  "tooltip",
                  "url",
                ];
                for (let name of names) {
                  if (current["child"][key][name + "_tag"]) {
                    const invTag = this.inverseTag(
                      current["child"][key][name],
                      filter["oldvalue"],
                      value
                    );
                    current["child"][key][name] = invTag;
                  }
                }
              }
            } else {
              const value = filter["value"];
              const names = [
                "name",
                "description",
                "text",
                "label",
                "hint",
                "tooltip",
                "url",
              ];
              for (let name of names) {
                if (current["child"][key][name + "_tag"]) {
                  const invTag = this.inverseTag(
                    current["child"][key][name],
                    filter["oldvalue"],
                    value
                  );
                  current["child"][key][name] = invTag;
                }
              }
            }
          }
        }

        this.translateRec(current["child"][key], filter);
      }
    }
  }

  public resetTreeStatus(data) {
    for (let key in data) {
      if (data[key]) {
        console.log(
          this.parentStructure,
          key,
          "vengaaaaa this.parentStructure[s], key "
        );
        this.findElementWithIdAndSetStatus(key, -1);
      }
    }
  }

  findElementWithIdAndSetStatus(idFA, status) {
    let fake = null;
    let allStructures = this.getAllStructures();
    fake = { 'child': allStructures };
    return this.findElementWithIdAndSetStatusLoop(fake, idFA, status);
  }

  public findElementWithIdAndSetStatusLoop(current, id, status) {
    let r = false;
    if (current) {
      if (
        id !== null &&
        (current["id_functional_area"] == id ||
          current["id_functional_area_original"] == id)
      ) {
        if (status != -1) {
          this.dataValidityOldValues[id] =
            current["id_functional_status_general"];
          this.updateStatusFormControl(current, status);
          current["id_functional_status_general"] = Number(status);
          return true;
        } else {
          this.updateStatusFormControl(
            current,
            current["id_functional_status_initial"]
          );
          current["id_functional_status_general"] =
            current["id_functional_status_initial"];
        }
      }
      if (current["child"]) {
        for (let i in current["child"]) {
          r = this.findElementWithIdAndSetStatusLoop(
            current["child"][i],
            id,
            status
          );
        }
      }
    }
    return r;
  }

  updateStatusFormControl(structure, status) {
    if (
      structure["newStatus"] != status &&
      structure["form"] &&
      structure["id_functional_type"] != 113
    ) {
      let form: UntypedFormGroup = structure["form"];
      if (
        form.contains(
          structure["id_functional_area"] + "-" + structure["bd_field"]
        )
      ) {
        let formControl =
          form.controls[
          structure["id_functional_area"] + "-" + structure["bd_field"]
          ];
        structure["newStatus"] = status;
        if (
          (status == 2 || status == 3) &&
          structure["id_functional_status_general"] == 5 &&
          formControl !== null
        ) {
          formControl.clearValidators();
          formControl.updateValueAndValidity({ onlySelf: true });
          structure["original_value"] = structure["tmp_value"];
        }
        if (status == 5 && formControl !== null) {
          formControl.setValidators([Validators.required]);
          //structure['tmp_value'] = structure['original_value'];
        }
      }
    }
  }

  getAllStructures() {
    let fakeArray = [];
    if (this.parentStructure === undefined || this.parentStructure == null) this.parentStructure = [];
    if (this.externalWindowStructure === undefined || this.externalWindowStructure == null) this.externalWindowStructure = [];
    if (this.authService.headerStructure === undefined || this.authService.headerStructure == null) this.authService.headerStructure = [];
    if (this.authService.footerStructure === undefined || this.authService.footerStructure == null) this.authService.footerStructure = [];
    return fakeArray.concat(this.parentStructure, this.externalWindowStructure, [this.authService.headerStructure], [this.authService.footerStructure]);
  }

  findElementWithId(idFA) {
    let fake = null;
    let allStructures = this.getAllStructures();
    fake = { 'child': allStructures };
    return this.findElementWithIdLoop(fake, idFA);
  }

  public findElementWithIdLoop(current, id) {
    if (current && current["child"] && id != null) {
      let data;
      if (current["id_functional_area"] == id) {
        return { child: current, parent: null };
      }
      for (let i in current["child"]) {
        if (current["child"][i] && current["child"][i]["id_functional_area"] == id) {
          return { child: current["child"][i], parent: current, key: i };
        } else {
          let data2 = this.findElementWithIdLoop(current["child"][i], id);
          if (data2) data = data2;
        }
      }
      return data;
    }
  }

  public parseStyle(struct): any {
    if (struct["style"] == 0) struct["style"] = null;
    let styles = {};
    for (let [key, value] of Object.entries(struct)) {
      if (value !== null && value != undefined && key == "style") {
        let elements = struct[key].split(";");
        let map = {};
        elements.forEach((element) => {
          if (element !== "") {
            let index = element.indexOf(":");
            let propertyName = element.slice(0, index);
            let propertyValue = element.slice(index + 1) + ";";
            map[propertyName] = propertyValue;
          }
        });
        styles[key] = map;
      }
    }
    return styles;
  }

  private shouldChangeState(newState, oldState) {
    // 5 1 3 2 4
    if (newState != oldState) {
      if (newState == 5) {
        return -1;
      }
      if (newState == 4) {
        return 1;
      }
      if (newState == 1 && oldState == 3) {
        return -1;
      }
      if (newState == 3 && oldState == 2) {
        return -1;
      }
      if (newState == 1 && oldState == 2) {
        return -1;
      }

      return 1;
    } else {
      return 0;
    }
  }

  private shouldChangeStatePermissions(newState, oldState) {
    // 2 4 3 5 1
    if (newState != oldState) {
      if (newState == 2) {
        return -1;
      }
      if (newState == 1) {
        return 1;
      }
      if (newState == 4 && oldState == 3) {
        return -1;
      }
      if (newState == 3 && oldState == 5) {
        return -1;
      }
      if (newState == 4 && oldState == 5) {
        return -1;
      }

      return 1;
    } else {
      return 0;
    }
  }

  public dataValidityMultiSelect(value, structure) {
    console.log(value, "dvalue");
    const id_functional_area =
      structure["id_functional_area_original"] ||
      structure["id_functional_area"];
    const affected_areas_ids =
      this.elementsThatAffectOthers[id_functional_area];
    const affected_areas = {};
    let anyToInitial = false;
    for (let i in affected_areas_ids) {
      if (Number(i) != -1) {
        if (!this.affectedElements[affected_areas_ids[i]]) {
          anyToInitial = true;
        } else {
          for (let id_func_area in this.affectedElements[
            affected_areas_ids[i]
          ]) {
            if (!affected_areas[id_func_area]) {
              affected_areas[id_func_area] = [];
            }
            if (value.indexOf(Number(i)) >= 0) {
              const status =
                this.affectedElements[affected_areas_ids[i]][id_func_area];
              affected_areas[id_func_area].push(status[0]);
            } else {
              const elem = this.findElementWithId(id_func_area);
              if (elem) {
                affected_areas[id_func_area].push(
                  elem["child"]["id_functional_status_initial"]
                );
                break;
              }
            }
          }
        }
      }
    }

    if (anyToInitial) {
      for (let i in affected_areas) {
        const elem = this.findElementWithId(i);
        if (elem) {
          affected_areas[i].push(
            elem["child"]["id_functional_status_initial"]
          );
          break;
        }
      }
    }
    return affected_areas;
  }

  public updateStatus(value, structure, checkbox = false) {
    let permissions = -1;
    const id_functional_area =
      structure["id_functional_area_original"] ||
      structure["id_functional_area"];
    let NGForIndex = -1;
    if (structure["ngForId"] != -1) {
      let elementContents = structure["id_functional_area"]
        .toString()
        .split(".");
      if (elementContents[1] > 0) NGForIndex = elementContents[1];
      //else NGForIndex = 0;
    }
    if (value || value === 0) {
      this.dataValidityOldValues = {};
      if (Array.isArray(value)) {
        const affected_areas = this.dataValidityMultiSelect(value, structure);
        console.log(affected_areas, "affected_areas");
        for (let id in affected_areas) {
          if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
          const affected_area_sorted = affected_areas[id].sort(
            this.shouldChangeState
          );
          const status = affected_area_sorted[0];
          this.findElementWithIdAndSetStatus(id, status);
        }
      } else if (!Array.isArray(value) && value !== undefined) {
        let states = [];
        if (this.elementsThatAffectOthers[id_functional_area]) {
          const id_data_type =
            this.elementsThatAffectOthers[id_functional_area][value];
          const toRefresh = this.resetFunctionalAreas(
            value,
            structure,
            NGForIndex
          );
          this.resetTreeStatus(toRefresh);
          let idsToChange = this.affectedElements[id_data_type];
          if (idsToChange) {
            for (let id in idsToChange) {
              if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
              if (structure["id_functional_status_general"] != 2) {
                this.setStatusFromSingleValue(value, idsToChange[id][0], id);
              }
              /*
                states[id].push(idsToChange[id][0]);
                // Si pasas -1 como tercer parametro -> pone functional_area_status_general al estado inicial
                let elem = this.findElementWithId(id);
                if(elem) {
                  permissions = elem['child']['id_functional_status_profile_permissions'];
                }
              */
            }
          } else {
            if (checkbox) {
              this.setStatusToInitial(structure, NGForIndex);
            }
          }
          /*states.sort(this.shouldChangeState);

                if(permissions == 3){
                  let nstate = states[0];
                  states = [];
                  states.push(nstate);
                  states.push(permissions);
                  states.sort(this.shouldChangeStatePermissions);
                }
          states = states.reduce((accumulator, value) => accumulator.concat(value), []);
          let state = states[0];

          this.setStatusFromSingleValue(value, state);*/
        }
      } else {
        this.setStatusToDefault(structure, NGForIndex);
      }
    }
  }

  private haveToContinueNGFOR(id, structure, NGForIndex) {
    if (structure["ngForId"] > 0) {
      let elementContentsId = id.split(".");
      if (
        NGForIndex != -1 &&
        !(+elementContentsId[1] > 0 && +elementContentsId[1] == NGForIndex)
      )
        return true;
      if (NGForIndex == -1 && +elementContentsId[1] > 0) return true;
    }
    return false;
  }

  private resetFunctionalAreas(val, structure, NGForIndex) {
    const id_functional_area = structure["id_functional_area"];
    if (this.elementsThatAffectOthers[id_functional_area]) {
      const toRefresh = {};
      for (let e in this.elementsThatAffectOthers[id_functional_area]) {
        const x = this.elementsThatAffectOthers[id_functional_area][e];
        for (let id in this.affectedElements[x]) {
          if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
          if (e == val) {
            toRefresh[id] = false;
          } else {
            toRefresh[id] = true;
          }
        }
      }
      return toRefresh;
    }
  }

  private detectConflict(me, idToChange) {
    if (this.functional_area_changeds[idToChange]) {
      return true;
    }

    return false;
  }

  private setStatusFromSingleValue(value, status, id) {
    console.log("UPDATE SETS STATUS FROM SINGLE VALUE", value, status, id);
    this.dataValidityBeingChanged.push(id);
    console.log("UPDATE PARENT STRUCTURE", this.parentStructure);
    //let conflict = this.detectConflict(id_functional_area, id);
    let conflict = false;
    let changed = this.findElementWithIdAndSetStatus(id, status);
    if (changed) {
      this.functional_area_changeds[id] = 2222222;
      //this.resetTreeStatus();
    }
  }

  private setStatusFromArray(value, structure) {
    const id_functional_area = structure["id_functional_area"];
    const id_data_type =
      this.elementsThatAffectOthers[id_functional_area][value];
    let idsToChange = this.affectedElements[id_data_type];
    if (idsToChange) {
      for (let id in idsToChange) {
        let conflict = this.detectConflict(id_functional_area, id);
        let changed = this.findElementWithIdAndSetStatus(id, value);
        if (changed) {
          this.functional_area_changeds[id] = id_functional_area;
        }
      }
    }
  }

  private setStatusToInitial(structure, NGForIndex) {
    const id_functional_area = structure["id_functional_area"];
    if (this.elementsThatAffectOthers[id_functional_area]) {
      const firstKey = Object.keys(
        this.elementsThatAffectOthers[id_functional_area]
      )[0];
      const ids =
        this.affectedElements[
        this.elementsThatAffectOthers[id_functional_area][firstKey]
        ];
      for (let id in ids) {
        if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
        // Si pasas -1 como tercer parametro -> pone functional_area_status_general al estado inicial
        let conflict = this.detectConflict(id_functional_area, id);
        let changed = this.findElementWithIdAndSetStatus(id, -1);
        if (changed) {
          this.functional_area_changeds[id] = id_functional_area;
        }
      }
    }
  }

  private setStatusToDefault(structure, NGForIndex) {
    const id_functional_area = structure["id_functional_area"];

    if (this.elementsThatAffectOthers[id_functional_area]) {
      const firstKey = -1;
      const ids =
        this.affectedElements[
        this.elementsThatAffectOthers[id_functional_area][firstKey]
        ];
      for (let id in ids) {
        if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
        for (let s in this.parentStructure) {
          // Si pasas -1 como tercer parametro -> pone functional_area_status_general al estado iniciado
          //this.dispatcher.notify({id_functional_area:id,status:-1});
          let conflict = this.detectConflict(id_functional_area, id);
          let changed = this.findElementWithIdAndSetStatus(id, ids[id][0]);
          if (changed) {
            delete this.functional_area_changeds[id];
          }
        }
      }
    }
  }

  public getCurrentIndex() {
    return this.historyIndex;
  }

  public getCurrentPage() {
    return this.history[this.historyIndex];
  }

  public getNextPage() {
    if (this.historyIndex < this.history.length) {
      this.redirectByTools = true;
      return this.history[this.historyIndex + 1];
    }
  }

  public updateHistoryIndex(n) {
    if (this.historyIndex + n >= 0) this.historyIndex = this.historyIndex + n;
  }

  public hasPrev() {
    return this.historyIndex > 0;
  }

  public hasNext() {
    return this.historyIndex < this.history.length - 1;
  }

  public getPreviousPage() {
    if (this.historyIndex > 0) {
      this.redirectByTools = true;
      return this.history[this.historyIndex - 1];
    }
  }

  public pushPage(page) {
    if (!this.redirectByTools) {
      if (this.historyIndex < this.history.length - 1) {
        this.removePages(this.historyIndex);
      }
      if (
        this.history.length == 0 ||
        page.url != this.history[this.history.length - 1].url
      ) {
        this.history.push(page);
        this.updateHistoryIndex(1);
      }
    }
    this.redirectByTools = false;
  }

  public removePages(index) {
    console.log(index);
    this.history = this.history.slice(0, index + 1);
    this.historyIndex = this.history.length - 1;
  }

  public clearHistory() {
    this.history = [];
    this.historyIndex = -1;
  }

  public updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField, hasParameters) {
    let lastValueOnExecuteFunction = undefined;
    if (id_dsb > 0) parent = id_dsb;
    if (this.formsChanged && this.formsChanged[parent] && this.formsChanged[parent][type + id_functional_area] && this.formsChanged[parent][type + id_functional_area]["lastValueOnExecuteFunction"] !== undefined) lastValueOnExecuteFunction = this.formsChanged[parent][type + id_functional_area]["lastValueOnExecuteFunction"];
    if (id_functional_area == "duplicated") {
      //Si es tracta de un element duplicat s'entrara aqui per poder asignar-li el canvi
      if (this.lastNameDuplicated != undefined && hasParameters) {
        // Quan te parametres es comprova si hi ha més d'una opcio seleccionada
        this.elemsToDuplicate = this.lastNameDuplicated.split(",");
      } else {
        this.elemsToDuplicate = [typeField];
      }
      for (let elem in this.elemsToDuplicate) {
        //For pel cas de que seleccioni més d'un element per duplicar
        //Agafo l'ultim insertat, el més gran que estigui actiu
        let idDuplicated = 0;
        let pos = this.elemsToDuplicate.length - parseInt(elem);
        for (let child in id_parent["child"]) {
          if (id_parent["child"][child]["id_functional_status_general"] != 2)
            ++idDuplicated;
        }
        if (this.elemsToDuplicate.length > 1)
          idDuplicated -= this.elemsToDuplicate.length - 1 - parseInt(elem);
        let idFunctionalArea;
        if (!hasParameters) {
          // Es tracta d'un element sense parametres
          this.lastLabel = typeField;
          console.log("LastLabel", this.lastLabel);
          idFunctionalArea =
            id_parent["child"][
            id_parent["child"].length - this.elemsToDuplicate.length
            ]["id_functional_area"];
          console.log("idFuncArea", idFunctionalArea);
          if (this.lastLabel !== null)
            this.formsChanged[id_parent["id_functional_parent_initial"]][
              this.lastLabel.replace(" ", "-") +
              "_NEW_" +
              (id_parent["child"].length - 1)
            ] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: true,
              isDuplicated: true,
              hasNgForOptions: true,
              oldValue: old,
              newValue: newValue,
              type: type,
              is_from_duplicated: false,
              is_from_no_params: true,
              id_duplicated: idDuplicated,
              tooltip: this.lastLabel,
              id_functional_area_to_duplicate: idFunctionalArea,
              name:
                this.lastLabel.replace(" ", "-") +
                "_NEW_" +
                (id_parent["child"].length - 1),
              old: this.lastLabel,
              new: undefined,
              internal_value: { old: "NEW", new: "label" },
              numerChange: ++this.numTimesFormsChanged,
              id_functional_area: idFunctionalArea,
              lastValueOnExecuteFunction: lastValueOnExecuteFunction,
            };
        } else {
          // Es tracta d'un element amb parametres
          console.log(id_parent["child"], idDuplicated);
          idFunctionalArea =
            id_parent["child"][idDuplicated - 1]["id_functional_area"];
          this.formsChanged[this.lastParent][
            this.lastLabel +
            "NEW_" +
            (id_parent["child"].length - pos).toString()
          ] = {
            id: this.lastManagedChanges,
            id_parent: id_parent,
            labelNewDuplicated: true,
            isDuplicated: true,
            hasNgForOptions: true,
            oldValue: old,
            newValue: newValue,
            type: type,
            is_from_duplicated: false,
            is_from_no_params: false,
            id_duplicated: idDuplicated,
            id_functional_area_to_duplicate: idFunctionalArea,
            name:
              this.lastLabel +
              "NEW_" +
              (id_parent["child"].length - pos).toString(),
            old: this.lastOld + ":",
            new: this.elemsToDuplicate[elem],
            internal_value: { old: "NEW", new: "label" },
            numerChange: ++this.numTimesFormsChanged,
            id_functional_area: idFunctionalArea,
            lastValueOnExecuteFunction: lastValueOnExecuteFunction,
          };
        }
        // L'inserto en la estructura d'elements duplicated
        this.duplicatedIds[
          id_parent["child"][id_parent["child"].length - pos][
          "id_functional_area"
          ]
        ] = {
          id: idDuplicated,
          name: this.elemsToDuplicate[elem],
          id_functional_area: idFunctionalArea,
          parent: parent,
          id_parent: id_parent["id_functional_area"],
        };
        --this.lastManagedChanges;
      }
      this.elemsToDuplicate = [];
    } else {
      if (
        (old === "" || old === null) &&
        newValue !== "" &&
        newValue !== null
      ) {
        old = "-";
      }
      if (newValue === "" || newValue === null) {
        newValue = "-";
      }
      if (
        old !== newValue &&
        (type == "checkbox_" || type == "slide_" || old || old === 0) &&
        (type == "checkbox_" || type == "slide_" || newValue || newValue === 0)
      ) {
        if (!this.formsChanged[parent]) {
          this.formsChanged[parent] = {};
        }
        if (
          this.duplicatedIds[id_parent] != undefined ||
          this.duplicatedIds[id_parent] != null
        ) {
          if (!hasParameters)
            this.formsChanged[parent][type + id_functional_area] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: false,
              isDuplicated: true,
              hasNgForOptions: true,
              oldValue: old,
              newValue: newValue,
              type: type,
              id_duplicated: this.duplicatedIds[id_parent]["id"],
              tooltip: label + ": " + this.duplicatedIds[id_parent]["name"],
              name: type + id_functional_area,
              old: typeField,
              internal_value: { old: internal_old, new: internal_new },
              numerChange: ++this.numTimesFormsChanged,
              lastValueOnExecuteFunction: lastValueOnExecuteFunction,
            };
          else
            this.formsChanged[parent][type + id_functional_area] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: false,
              isDuplicated: true,
              hasNgForOptions: true,
              oldValue: old,
              newValue: newValue,
              type: type,
              is_from_duplicated: true,
              is_from_no_params: false,
              id_duplicated: this.duplicatedIds[id_parent]["id"],
              tooltip: this.duplicatedIds[id_parent]["name"],
              name: type + id_functional_area,
              old: old,
              new: newValue,
              internal_value: { old: internal_old, new: internal_new },
              numerChange: ++this.numTimesFormsChanged,
              id_functional_area: id_functional_area,
              lastValueOnExecuteFunction: lastValueOnExecuteFunction,
            };
        } else {
          let idNoDuplicated;
          if (typeof id_functional_area === "string") {
            idNoDuplicated = id_functional_area.split(".");
            idNoDuplicated = parseInt(idNoDuplicated[1]) + 1;
          } else idNoDuplicated = 1;
          const element2 = this.findElementWithId(id_parent);
          if (
            element2["parent"]["id_ng_for_type"] !== null &&
            element2["parent"]["id_ng_for_type"] !== undefined &&
            element2["parent"]["id_ng_for_type"] !== 0
          )
            this.formsChanged[parent][type + id_functional_area] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: false,
              isDuplicated: false,
              hasNgForOptions: true,
              oldValue: old,
              newValue: newValue,
              type: type,
              is_from_duplicated: false,
              is_from_no_params: false,
              id_duplicated: idNoDuplicated,
              tooltip: element2["parent"]["label"],
              name: type + id_functional_area,
              old: old,
              new: newValue,
              internal_value: { old: internal_old, new: internal_new },
              numerChange: ++this.numTimesFormsChanged,
              id_functional_area: id_functional_area,
            };
          else
            this.formsChanged[parent][type + id_functional_area] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: false,
              isDuplicated: false,
              hasNgForOptions: false,
              oldValue: old,
              newValue: newValue,
              type: type,
              is_from_duplicated: false,
              is_from_no_params: false,
              id_duplicated: idNoDuplicated,
              tooltip: element2["parent"]["label"],
              name: type + id_functional_area,
              old: old,
              new: newValue,
              internal_value: { old: internal_old, new: internal_new },
              numerChange: ++this.numTimesFormsChanged,
              id_functional_area: id_functional_area,
              lastValueOnExecuteFunction: lastValueOnExecuteFunction,
            };
        }
        --this.lastManagedChanges;
      }
    }
  }

  public updateValueCheckBox(structure, index, value) {
    structure[index]["invalid"] = false;
    if (value) structure[index]["tmp_value"] = 1;
    else {
      if (structure[index]["id_functional_status_general"] === 5)
        structure[index]["invalid"] = true;
      structure[index]["tmp_value"] = 0;
    }

    const id = value ? 1 : 2;
    this.updateStatus(id, structure[index], true);
  }

  public deleteFormHistory(parent, key) {
    if (
      this.formsChanged[parent] &&
      this.formsChanged[parent][key] != undefined
    ) {
      delete this.formsChanged[parent][key];
    }
  }

  public async revertDuplicateFromValue(parent, input, structure = null) {
    // Revert de duplicar un element
    // 1. Poner id_functional_status = 2
    // 2. Delete aparicions en el historial de l'element duplicat i els seus fills

    // Si la structure no es null se trata la structure
    let elem;
    if (structure != null) elem = this.findElementWithId(structure["child"][0]["id_functional_area"]);
    else elem = this.findElementWithId(input["value"]["id_functional_area_to_duplicate"]);
    if (elem) {
      console.log(elem);
      const status = await this.openWarningDialog(2, 0);
      if (status) {
        if (structure != null) structure["id_functional_status_general"] = 2;
        else elem["id_functional_status_general"] = 2;
        this.deleteChildsFromHistory(
          parent,
          elem["child"]["id_functional_area"],
          elem["parent"]["id_functional_area"]
        );
        this.refreshDuplicatedIds(
          parent,
          elem["child"]["id_functional_area"],
          elem["parent"]["id_functional_area"],
          elem["child"]
        );
      }
    }
  }

  // Quan dupliquem un element i fem un revert hem de borrar de l'historial els canvis dels seus fills (si hi ha)
  public deleteChildsFromHistory(parent, key, id_parent) {
    let duplicatedId;
    for (let i in this.formsChanged[parent]) {
      if (this.formsChanged[parent][i]["id_parent"] == key) {
        //Netejo els seus fills
        delete this.formsChanged[parent][i];
      } else if (
        this.formsChanged[parent][i]["id_functional_area_to_duplicate"] == key
      ) {
        //Netejo el propi element duplicate
        duplicatedId = this.formsChanged[parent][i]["id_duplicated"];
        delete this.formsChanged[parent][i];
      }
    }

    for (let elem in this.formsChanged[parent]) {
      let parentTree = this.formsChanged[parent][elem]["id_parent"];
      if (parentTree != undefined) {
        // Es tracta d'un form d'un element duplicat
        for (let elem2 in this.formsChanged[parent]) {
          if (
            this.formsChanged[parent][elem2][
            "id_functional_area_to_duplicate"
            ] == this.formsChanged[parent][elem]["id_parent"]
          )
            parentTree = this.formsChanged[parent][elem2]["id_parent"];
        }
      }
      if (
        this.formsChanged[parent][elem]["id_duplicated"] != null &&
        parentTree["id_functional_area"] == id_parent &&
        this.formsChanged[parent][elem]["id_duplicated"] >= duplicatedId
      ) {
        --this.formsChanged[parent][elem]["id_duplicated"];
      }
    }
  }

  // Quan dupliquem un element i fem revert hem d'actualitzar el diccionari d'elements duplicar i els valors de tots els altres elements
  public refreshDuplicatedIds(parent, key, id_parent, value) {
    let correctPosition = false;
    for (let elem in this.duplicatedIds) {
      // Comprovo si son de la mateixa pantalla
      if (this.duplicatedIds[elem]["parent"] == parent) {
        // Disminueixo en 1 el valor del seu id
        if (
          this.duplicatedIds[elem]["id_parent"] == id_parent &&
          correctPosition
        )
          --this.duplicatedIds[elem]["id"];

        // Elimino l'element que correspongui
        if (this.duplicatedIds[elem]["id_functional_area"] == key) {
          delete this.duplicatedIds[elem];
          correctPosition = true;
        }
      }
    }
  }

  public getUserInfo(callFrom = 0, idCompany = -1) {
    let hasActive = false;
    let localActive = localStorage.getItem("_in_");

    this.endpointService
      .getUserInfo(this.authService.userId)
      .subscribe((data) => {
        if (data["response"]["userInfo"].length > 0) {
          this.authService.accountsGeneric = data["response"]["userInfo"];
          for (let i = 0; i < data["response"]["userInfo"].length; i++) {
            let isCall2Company = (idCompany == -1 || (idCompany != data["response"]["userInfo"][i]["id_empresa"] && idCompany != data["response"]["userInfo"][i]["idEmpresa"]));
            if(callFrom == 2 && isCall2Company && this.authService.accountsGeneric.length > 1) {
              this.authService.accountsGeneric[i].active = false;
              this.authService.accountsGeneric[i].value = true;
            } else if (callFrom != 2 && (i != +localActive || localActive === null) && this.authService.accountsGeneric.length > 1) {
              this.authService.accountsGeneric[i].active = false;
              this.authService.accountsGeneric[i].value = true;
            } else {
              if (callFrom == 1 && this.authService.accountsGeneric.length > 1) {
                // Si entras desde el login y hay varias cuentas, aseguramos que no haya ninguna cuenta activa, pues no debería
                this.authService.accountsGeneric[i].active = false;
                this.authService.accountsGeneric[i].value = true;
              } else {
                this.authService.accountsGeneric[i].active = true;
                this.authService.accountsGeneric[i].value = true;
                hasActive = true;

                localStorage.setItem("_in_", i.toString());
                this.authService.userFullName =
                  data["response"]["userInfo"][i].nombre_user;
                this.authService.profileId =
                  data["response"]["userInfo"][i].perfil;
                this.authService.isMovinUser =
                  data["response"]["userInfo"][i].es_usuario_movin;
                this.authService.isResponsible =
                  data["response"]["userInfo"][i].es_responsable;
                this.authService.languageId =
                  data["response"]["userInfo"][i].id_idioma;
                localStorage.setItem(
                  "_ul_",
                  this.authService.languageId.toString()
                );
                this.authService.fechaInicioCal =
                  data["response"]["userInfo"][i].hora_inicio_cal;
                this.authService.fechaFinCal =
                  data["response"]["userInfo"][i].hora_fin_cal;
                this.authService.idAviso1 =
                  data["response"]["userInfo"][i].id_aviso1;
                this.authService.idAviso2 =
                  data["response"]["userInfo"][i].id_aviso2;
                this.authService.idAviso3 =
                  data["response"]["userInfo"][i].id_aviso3;
                this.authService.espera =
                  data["response"]["userInfo"][i].espera;
                this.authService.cargo =
                  data["response"]["userInfo"][i].id_cargo;
                this.authService.inmoTipoPerfil =
                  data["response"]["userInfo"][i].tipo_perfil;
                this.authService.responsable_community =
                  data["response"]["userInfo"][i].responsable_community;
                this.authService.puede_colaborar =
                  data["response"]["userInfo"][i].puede_colaborar;
                this.authService.profilePic = data["response"].foto;

                console.log(
                  "userId, getUserInfo_1",
                  this.authService.userId,
                  data["response"]["userInfo"][i]
                );

                /* CAL REVISAR */
                this.authService.inmobiliariaName =
                  data["response"]["userInfo"][i].nombreInmobiliaria;
                this.authService.inmoURL =
                  data["response"]["userInfo"][i].url_canonica;
                this.authService.rango_colabo =
                  data["response"]["userInfo"][i].rango_colabo;
                this.authService.user_token =
                  data["response"]["userInfo"][i].usuario_token;
                this.authService.dia_inicio_calendario =
                  data["response"]["userInfo"][i].dia_inicio_calendario;
                this.authService.googleToken =
                  data["response"]["userInfo"][i].token_google;
                this.authService.microsoftToken =
                  data["response"]["userInfo"][i].token_microsoft;
                this.authService.groupId = null;
                this.authService.inmoId = null;

                if (data["response"]["userInfo"][i].id > 0) {
                  // TODO JOAN: NO SE PERQUE ES FA SERVIR
                  this.authService.accounts.push({
                    id: data["response"]["userInfo"][i].id,
                    idCompany: data["response"]["userInfo"][i].id_empresa,
                    name: data["response"]["userInfo"][i].nombre,
                    type: data["response"]["userInfo"][i].tipoNombre, // STRING: Marca o Empresa
                    type_account: data["response"]["userInfo"][i].type_account, // INT: 1 (Marca) o 2 (Empresa)
                    inmo_id: data["response"]["userInfo"][i].idInmobiliaria,
                    group_id: data["response"]["userInfo"][i].id_grupo,
                    group_type:
                      data["response"]["userInfo"][i].id_tipo_grupo_inmo,
                    group_name: data["response"]["userInfo"][i].nombreGrupo,
                    group_type_perfil:
                      data["response"]["userInfo"][i].tipo_perfil,
                    group_url: data["response"]["userInfo"][i].url_canonica,
                  });
                }

                if (data["response"]["userInfo"][i].idInmobiliaria > 0) {
                  this.authService.accounts.push({
                    id: data["response"]["userInfo"][i].idInmobiliaria,
                    name: data["response"]["userInfo"][i].nombreInmobiliaria,
                    type: "inmo",
                    type_account: 1,
                  });
                  this.authService.isInmobiliari = 1;
                  this.authService.inmoId =
                    data["response"]["userInfo"][i].idInmobiliaria;
                  this.authService.isOrphan = false;
                } else {
                  this.authService.isInmobiliari = 0;
                  this.authService.isOrphan = true;
                  if (data["response"]["userInfo"][i].id_grupo > 0) {
                    this.authService.accounts.push({
                      id: data["response"]["userInfo"][i].id_grupo,
                      name: data["response"]["userInfo"][i].nombreGrupo,
                      type: "group",
                      type_account: 2,
                      type_group:
                        data["response"]["userInfo"][i].id_tipo_grupo_inmo,
                      tipo_perfil: data["response"]["userInfo"][i].tipo_perfil,
                      url_canonica:
                        data["response"]["userInfo"][i].url_canonica,
                    });
                    this.authService.groupId =
                      data["response"]["userInfo"][i].id_grupo;
                  }
                }
              }
            }
          }
          console.log(
            this.authService.accountsGeneric,
            callFrom,
            "this.authService.accountsGenericthis.authService.accountsGeneric.."
          );
          console.log(
            data["response"]["userInfo"],
            "this.authService.accountsGenericthis.authService.accountsGeneric......."
          );

          switch (callFrom) {
            case 0: // appComponent al hacer init
              if (this.authService.accountsGeneric.length > 1) {
                // Usuari amb varis comptes
                console.log(hasActive);
                if (hasActive) {
                  this.changeAccount(localActive, true);
                  this.initFreshChat();
                } else {
                  this.openAccountMode(callFrom);
                }
              } else {
                // Usuari amb un sol compte o sense cap cpmpte (orfe)
                this.changeAccount(0, true);
                this.initFreshChat();
                // Joan  this.chatService.initIoConnection();
              }
              break;
            case 1: // login
              // Joan: Aquestes 2 linies estaven a loginComponent despres de cridar a aquesta funcio. Pero no se si calen.
              //this.authService.setExpirationDate();
              //this.authService.loadAdminArea();
              if (this.authService.accountsGeneric.length > 1) {
                // Usuari amb varis comptes
                this.openAccountMode(callFrom);
              } else {
                // Usuari amb un sol compte o sense cap cpmpte (orfe)
                this.changeAccount(0);
                // Joan  this.chatService.initIoConnection();
                //this.authService.login();
                console.log(
                  this.redirectionService.redirectionURL,
                  "this.redirectionService.redirectionURL"
                );
                if (
                  this.redirectionService.redirectionURL !==
                  "/" + this.authService.labelLanguage + "/login" &&
                  this.redirectionService.redirectionURL !== "/" &&
                  this.redirectionService.redirectionURL !== null
                ) {
                  this.navigateToOldRoute();
                  this.authService.finishToLogued = true;
                } else {
                  this.refreshStructure(2);
                }
              }
              break;
            case 2: // session static
              this.changeAccount(localStorage.getItem("_in_"));
              this.authService.finishToLogued = true;
              break;
          }
        } else {
          this.snackBar.open(
            "¡Vaya, parece que este usuario no existe o ya no está activo!",
            "X",
            {
              duration: 3000,
              panelClass: ["lightred-snackbar"],
            }
          );
          //this.authService.logout();
        }
      });
  }

  public initFreshChat() {
    if (this.authService.isUserSessionActive) {
      this.chat
        .init({
          token: "7a842221-3b23-4eae-b57d-37a0de87765b",
          host: "https://wchat.eu.freshchat.com",
          externalId: String(this.authService.userId), // user’s id unique to your system
          firstName: this.authService.userFullName, // user’s first name
          //lastName: "Doe",                // user’s last name
          //email: "john.doe@gmail.com",    // user’s email address
          //phone: "8668323090",            // phone number without country code
          //phoneCountryCode: "+1"          // phone’s country code
        })
        .subscribe();
    } else {
      this.chat
        .init({
          token: "7a842221-3b23-4eae-b57d-37a0de87765b",
          host: "https://wchat.eu.freshchat.com",
        })
        .subscribe();
    }
  }

  public loadHeaderStructure() {
    // Start menu
    let idModuleHeader = this.headerNoLoguedId;
    if (this.authService.isUserSessionActive()) {
      idModuleHeader = this.headerId;
    }
    if (this.authService.headerStructure === null) {
      this.staticTablesService.initialize();
      //this.genericService.fetchStructure(this.headerId, this.languageId).subscribe((data): void => {
      this.fetchStructure(idModuleHeader).subscribe((data): void => {
        this.authService.headerStructure = data;
        this.authService.loadingInit = true;
      });
    } else {
      this.authService.loadingInit = true;
    }
  }

  public loadFooterStructure() {
    // Start menu
    let idModuleFooter = this.footerNoLoguedId;
    if (this.authService.isUserSessionActive()) idModuleFooter = this.footerId;
    if (this.authService.footerStructure === null) {
      this.staticTablesService.initialize();
      //this.genericService.fetchStructure(this.footerId, this.languageId).subscribe((data): void => {
      this.fetchStructure(idModuleFooter).subscribe((data): void => {
        this.authService.footerStructure = data;
      });
    }
  }

  public navigateToOldRoute() {
    console.log(
      this.redirectionService.redirectionJoinGroup,
      this.redirectionService.redirectionURL
    );
    if (this.redirectionService.redirectionURL !== null) {
      this.router.navigate([this.redirectionService.redirectionURL]);
      if (this.redirectionService.redirectionJoinGroup) {
        this.redirectionService.redirectionJoinGroup = false;
      }
    } else {
      this.router.navigate([""]);
      //this.refreshStructure(2);
    }
  }

  public openAccountMode(callFrom = 0) {
    let panelClass = [];
    if (this.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    const dialogRef = this.dialog.open(SwapAccountComponent, {
      width: "auto",
      height: "auto",
      panelClass: panelClass,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.cancel) {
        if (callFrom == 1) this.authService.logout();
      } else {
        this.changeAccount(data.index);
        if (callFrom == 1) {
          if (
            this.redirectionService.redirectionURL !==
            "/" + this.authService.labelLanguage + "/login" &&
            this.redirectionService.redirectionURL !== "/login" &&
            this.redirectionService.redirectionURL !== "/" &&
            this.redirectionService.redirectionURL !== null
          ) {
            console.log("dale jan");
            this.navigateToOldRoute();
            this.authService.finishToLogued = true;
          } else {
            console.log("dale jan 2");
            this.refreshStructure(2);
          }
        } else {
          this.refreshStructure(2);
          //this.login();
        }
      }
    });
  }

  public changeAccount(i, loadHeader = false) {
    if (this.authService.accountsGeneric.length > 0) {
      this.authService.activeTypeAccount =
        this.authService.accountsGeneric[i].type_account;
      this.authService.empresaId = this.authService.accountsGeneric[i].id;
      this.authService.empresaIdCategoryGeneric =
        this.authService.accountsGeneric[i].categoryCompanyId;
      this.authService.empresaIdCompanyType =
        this.authService.accountsGeneric[i].companyType;
      this.authService.empresaCategoryGeneric =
        this.authService.accountsGeneric[i].categoryCompanyName;

      //this.inmoId = this.accountsGeneric[i].inmo_id;
      //this.groupId = this.accountsGeneric[i].group_id;
      this.authService.groupType =
        this.authService.accountsGeneric[i].group_type;
      this.authService.groupName =
        this.authService.accountsGeneric[i].group_name;
      this.authService.groupTipoPerfil =
        this.authService.accountsGeneric[i].group_type_perfil;
      this.authService.groupURL = this.authService.accountsGeneric[i].group_url;
      this.authService.companyGenericName =
        this.authService.accountsGeneric[i].nombre;
      this.authService.profileIdGeneric =
        this.authService.accountsGeneric[i].idProfile;
      this.authService.loadAdminArea(); // TODO JOAN: Crec que no cal
      localStorage.setItem("_in_", i.toString());
      for (let j = 0; j < this.authService.accountsGeneric.length; j++) {
        this.authService.accountsGeneric[j].active = j == i;
      }
    }
    if (loadHeader) {
      this.loadHeaderStructure();
      this.loadFooterStructure();
    }
  }

  public async openWarningDialog(messageId, titleId, fa = null) {
    switch (titleId) {
      case 1:
        this.warningTitle = "Parece que hay cambios sin guardar.";
        this.warningButton1 = "Cancelar";
        this.warningButton2 = "Continuar sin guardar";
        break;
      case 2:
        this.warningTitle = 'Eliminar "' + fa["label"] + '"';
        this.warningButton1 = "Cancelar";
        this.warningButton2 = "Eliminar";
        break;
      case 3:
        this.warningTitle = 'Eliminar "' + fa["description"] + '"';
        this.warningButton1 = "Cancelar";
        this.warningButton2 = "Eliminar";
        break;
      default:
        this.warningTitle = "";
        this.warningButton1 = "Volver";
        this.warningButton2 = "Continuar";
        break;
    }
    switch (messageId) {
      case 1:
        this.warningMessage = "¿Quieres continuar con esta acción?";
        break;
      case 2:
        this.warningMessage =
          "¿Seguro que quiere revertir los cambios de este campo?";
        break;
      case 3:
        this.warningMessage =
          '¿Estás seguro que quieres eliminar "' + fa["label"] + '"?';
        break;
      case 4:
        this.warningMessage =
          '¿Estás seguro que quieres eliminar "' + fa["description"] + '"?';
        break;
      default:
        this.warningMessage =
          "¿Estás seguro que quieres continuar con esta acción?";
        break;
    }

    let panelClass = ["dialog-material-generic-no-close-out"];
    if (this.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    const dialogRef = this.dialog.open(this.warningDialog, {
      width: "250px",
      hasBackdrop: false,
      panelClass: panelClass,
    });

    return await dialogRef.afterClosed().toPromise();
  }

  highlightHeaderComponents() {
    this.highlightHeaderElements = [];
    let breadurls = this.breadcrumbs.map(function (value, index) {
      return value["url"];
    });
    this.findIfRouting(this.authService.headerStructure[0], breadurls);
  }

  findIfRouting(subStructure: any, breadurls: any[]) {
    let result = false;
    if (subStructure !== null && subStructure !== undefined) {
      if (
        (subStructure["id_function"] == 1 ||
          subStructure["id_function"] == 6) &&
        breadurls.includes(subStructure["internal_routing"])
      ) {
        this.highlightHeaderElements.push(subStructure["id_functional_area"]);
        if (subStructure["child"]) {
          for (let p in subStructure["child"])
            if (!result)
              result = this.findIfRouting(subStructure["child"][p], breadurls);
        }
        result = true;
      } else {
        if (subStructure["child"])
          for (let p in subStructure["child"])
            if (!result)
              result = this.findIfRouting(subStructure["child"][p], breadurls);
      }
      if (result && subStructure["id_functional_area"] !== undefined)
        this.highlightHeaderElements.push(subStructure["id_functional_area"]);
    }
    return result;
  }

  public evaluateIfs(ids: any[], id_fa, structure, isBadge = false): boolean {
    if (
      structure["id_functional_type"] === 5 &&
      structure["type"] === "menu" &&
      !(
        structure["child"] &&
        structure["child"][0] &&
        structure["child"][0]["child"]
      )
    )
      return false;
    if (ids !== null && ids != undefined) {
      if (isBadge) {
        return this.evaluateIfsCondition(ids, id_fa, structure);
      } else {
        if (this.evaluateIfsCondition(ids, id_fa, structure)) {
          if (
            "id_functional_status_general_precondition" in structure &&
            structure["id_functional_status_general_precondition"] != 2 &&
            structure["id_functional_status_general_precondition"] != null &&
            structure["id_functional_status_general_precondition"] != undefined
          ) {
            structure["id_functional_status_general"] =
              structure["id_functional_status_general_precondition"];
          } else {
            if (structure["id_functional_status_general"] == 2) {
              structure["id_functional_status_general"] = 1;
            } else {
              structure["id_functional_status_general"] =
                structure["id_functional_status_general"];
            }
          }
          return true;
        } else {
          if (
            structure["id_functional_status_general"] != 2 &&
            !("id_functional_status_general_precondition" in structure)
          ) {
            structure["id_functional_status_general_precondition"] =
              structure["id_functional_status_general"];
          }
          structure["id_functional_status_general"] = 2;
          return false;
        }
      }
    } else {
      return true;
    }
  }

  public asignFrontend(subStructure: any, moduleId, asignNoFloatTopRight = true): void {
    if (subStructure !== null) {
      for (const key in subStructure) {


        if (subStructure[key]['validatedFrontend'] !== undefined && subStructure[key]['validatedFrontend']) continue; if (asignNoFloatTopRight && subStructure[key]["id_functional_parent_initial"] != this.headerId && subStructure[key]["id_functional_parent_initial"] != this.headerNoLoguedId && subStructure[key]["id_functional_parent_initial"] != this.footerId && subStructure[key]["id_functional_parent_initial"] != this.footerNoLoguedId &&
          subStructure[key]["class_custom"] && subStructure[key]["class_custom"] !== null && subStructure[key]["class_custom"] != undefined && subStructure[key]["class_custom"] !== ""
        ) {
          if (subStructure[key]["class_custom"].includes("float-top-right")) asignNoFloatTopRight = false;
          else if (!subStructure[key]["class_custom"].includes("ignore-float-top-right")
          ) subStructure[key]["class_custom"] = subStructure[key]["class_custom"] + " no-float-top-right";
        }

        if (subStructure[key]["child"]) {
          this.asignFrontend(subStructure[key]["child"], moduleId, asignNoFloatTopRight);
        }

        if (
          this.readMode &&
          subStructure[key]["form_field"] == 1 &&
          subStructure[key]["id_functional_status_general"] !== 2
        )
          subStructure[key]["id_functional_status_general"] = 3;

        if (
          subStructure[key]["id_functional_status_general"] == undefined ||
          subStructure[key]["id_functional_status_general"] === null
        )
          subStructure[key]["id_functional_status_general"] = 1;
        if (
          subStructure[key]["id_condition"] == undefined ||
          subStructure[key]["id_condition"] == 0
        )
          subStructure[key]["id_condition"] = null;
        if (
          subStructure[key]["id_condition_badge"] == undefined ||
          subStructure[key]["id_condition_badge"] == 0
        )
          subStructure[key]["id_condition_badge"] = null;
        if (subStructure[key]["multiple"] === null)
          subStructure[key]["multiple"] = 0;

        if (
          subStructure[key]["id_functional_type"] == 113 ||
          subStructure[key]["id_functional_type"] == 5
        ) {
          if (
            !subStructure[key]["class_custom"] ||
            subStructure[key]["class_custom"] === null ||
            subStructure[key]["class_custom"] == undefined ||
            subStructure[key]["class_custom"] === ""
          ) {
            if (
              subStructure[key]["label"] &&
              subStructure[key]["label"] !== null &&
              subStructure[key]["label"] != undefined &&
              subStructure[key]["label"] !== ""
            ) {
              subStructure[key]["class_custom"] = "generic-buttons-black";
            } else if (
              subStructure[key]["class"] &&
              subStructure[key]["class"] !== null &&
              subStructure[key]["class"] != undefined &&
              subStructure[key]["class"] !== ""
            ) {
              subStructure[key]["class"] = "only-icon";
            }
          }
        }

        if (
          subStructure[key]["id_functional_type"] == 5 &&
          subStructure[key]["icon"] == "delete" &&
          subStructure[key]["label"] &&
          subStructure[key]["label"] !== null &&
          subStructure[key]["label"] != undefined &&
          subStructure[key]["label"] !== ""
        ) {
          subStructure[key]["class_custom"] =
            subStructure[key]["class_custom"] + " generic-buttons-red";
        }

        if (
          !subStructure[key]["class_custom"] ||
          subStructure[key]["class_custom"] === null ||
          subStructure[key]["class_custom"] == undefined ||
          subStructure[key]["class_custom"] === ""
        ) {
          if (
            subStructure[key]["label"] &&
            subStructure[key]["label"] !== null &&
            subStructure[key]["label"] != undefined &&
            subStructure[key]["label"] !== ""
          ) {
            subStructure[key]["class_custom"] =
              subStructure[key]["class_custom"] +
              " generic-element-" +
              subStructure[key]["id_functional_area"];
          } else {
            subStructure[key]["class_custom"] =
              "generic-element-" + subStructure[key]["id_functional_area"];
          }
        }

        this.assignClass(subStructure[key], +key);

        subStructure[key]['width_general'] = subStructure[key]['width_general'] != 0 ? subStructure[key]['width_general'] : 100;
        subStructure[key]['styleParsed'] = this.parseStyle(subStructure[key]);

        subStructure[key]['validatedFrontend'] = true;

        this.getServiceLanguage(subStructure[key]);


        if (subStructure[key]['icon']) {
          if (subStructure[key]['icon_type'] == 'Outlined') {
            subStructure[key]['icon_type'] = "material-icons-outlined";
          } else if (subStructure[key]['icon_type'] == 'Filled') {
            subStructure[key]['icon_type'] = "material-icons";
          } else if (subStructure[key]['icon_type'] == 'Round') {
            subStructure[key]['icon_type'] = "material-icons-round";
          } else if (subStructure[key]['icon_type'] == 'Two-tone') {
            subStructure[key]['icon_type'] = "material-icons-two-tone";
          } else if (subStructure[key]['icon_type'] == 'Sharp') {
            subStructure[key]['icon_type'] = "material-icons-sharp";
          }

          subStructure[key]['icon_type'] = subStructure[key]['icon_type'] + " generic-icon-" + subStructure[key]['icon'];
        }

        if (subStructure[key]['id_functional_type'] == 5) {
          if (subStructure[key]['class'] && subStructure[key]['class'] != null && subStructure[key]['class'] != 0 && subStructure[key]['class'] && subStructure[key]['class'] != "only-icon") {
            subStructure[key]['class'] = subStructure[key]['class'] + " button-generic"
          } else {
            subStructure[key]['class'] = "no-angular-class button-generic"
          }
          if (subStructure[key]['url'] == undefined || subStructure[key]['url'] == "0" || subStructure[key]['url'] == "") subStructure[key]['url'] = null;
        }


        if (subStructure[key]['id_functional_type'] == 118) {
          subStructure[key]['text'] = subStructure[key]['text'].replace(new RegExp('"', 'g'), "'");
          subStructure[key]['text'] = this.sanitizer.bypassSecurityTrustHtml(subStructure[key]['text']);
        }

        if (subStructure[key]['id_functional_type'] == 117) {
          subStructure[key]['items'] = [];

          if (subStructure[key]['child']) {
            for (let key2 in subStructure[key]['child']) {
              if (subStructure[key]['child'][key2]['id_functional_status_general'] == 2) continue;
              let item = {};
              item['title'] = subStructure[key]['child'][key2]['label'];
              item['image'] = subStructure[key]['child'][key2]['url'];
              item['text'] = subStructure[key]['child'][key2]['text'];
              item['description'] = subStructure[key]['child'][key2]['description'];
              item['element'] = subStructure[key]['child'][key2];
              item['orden'] = +key2;

              subStructure[key]['items'].push(item);
            }
          }

          console.log(subStructure[key]['items'], "sdfsdfdsfs");
          if (subStructure[key]['items'].length == 0) subStructure[key]['id_functional_status_general'] = 2;
        }


        if (subStructure[key]["id_functional_parent"] == moduleId) {
          if (moduleId == this.headerId || moduleId == this.headerNoLoguedId) {
            this.finishedLoadStructureHeader = true;
          } else if (
            moduleId == this.footerId ||
            moduleId == this.footerNoLoguedId
          ) {
            this.finishedLoadStructureFooter = true;
          } else {

            this.finishedLoadStructure = true;

          }
        }
      }
    }
  }

  public setOriginalInputsValues(structure, key) {
    if (structure[key]["id_functional_area"] == 2359)
      console.log(
        this.originalInputsValues[
        structure[key]["id_functional_area"] +
        "-" +
        structure[key]["bd_field"]
        ],
        "asdmknjodeojrje"
      );
    if (
      this.originalInputsValues[
      structure[key]["id_functional_area"] + "-" + structure[key]["bd_field"]
      ] === undefined
    ) {
      this.originalInputsValues[
        structure[key]["id_functional_area"] + "-" + structure[key]["bd_field"]
      ] = _.cloneDeep(
        structure[key]["form"]["controls"][
        structure[key]["id_functional_area"] +
        "-" +
        structure[key]["bd_field"]
        ]
      );
    }
  }

  returnZero() {
    return 0;
  }

  assignClass(subStructure, key) {
    let orderkey = key + 1;
    if (
      subStructure["class_custom"] &&
      subStructure["class_custom"] !== null &&
      subStructure["class_custom"] != undefined &&
      subStructure["class_custom"] !== ""
    ) {
      if (
        subStructure["icon"] &&
        subStructure["icon"] !== null &&
        subStructure["icon"] != undefined &&
        subStructure["icon"] !== ""
      ) {
        subStructure["class_custom"] =
          subStructure["class_custom"] +
          " " +
          subStructure["icon"] +
          "-icon-generic yes-icon-generic generic-type-" +
          subStructure["id_functional_type"] +
          " generic-order-" +
          orderkey;
      } else {
        subStructure["class_custom"] =
          subStructure["class_custom"] +
          " no-icon-generic generic-type-" +
          subStructure["id_functional_type"] +
          " generic-order-" +
          orderkey;
      }
    } else {
      if (
        subStructure["icon"] &&
        subStructure["icon"] !== null &&
        subStructure["icon"] != undefined &&
        subStructure["icon"] !== ""
      ) {
        subStructure["class_custom"] =
          subStructure["icon"] +
          "-icon-generic yes-icon-generic generic-type-" +
          subStructure["id_functional_type"] +
          " generic-order-" +
          orderkey;
      } else {
        subStructure["class_custom"] =
          "no-icon-generic generic-type-" +
          subStructure["id_functional_type"] +
          " generic-order-" +
          orderkey;
      }
    }
  }

  getDataAccount() {
    return {
      languageId: this.authService.languageId,
      idAccountType: this.authService.getIdCompanyType(true),
      idCompanyGeneric: this.authService.getIdCompany(true),
      idUser: this.authService.getLoggedInUserId(),
      arrayCompanyType: this.authService.empresaIdCompanyType,
      idCompanyCategory: this.authService.empresaIdCategoryGeneric,
    };
  }

  public loadExpansion(row: any, from = 0) {
    console.log("rowrowrowrowrowrow", row);
    console.log("fromfromfromfromfrom", from);
    if (row !== undefined && row !== null) {
      let structure = this.parentStructure;
      if(this.externalWindowStructure && this.externalWindowStructure.length > 0) structure = this.externalWindowStructure;
      else if (from == 1) structure = this.authService.headerStructure;
      else if (from == 2) structure = this.authService.footerStructure;
      this.fillExpansion(row, structure);
      this.heightTableChange = true;
    }
  }

  public fillExpansion(row, subStructure) {
    console.log(row, subStructure, "fdnjklsd");
    if (subStructure) {
      for (let e in subStructure) {
        if (
          subStructure[e]["text"] !== null &&
          subStructure[e]["text"] !== undefined
        ) {
          if (subStructure[e]["text"].toString().includes("*{$")) {
            subStructure[e]["plantillaText"] = JSON.parse(
              JSON.stringify(subStructure[e]["text"])
            );
          }
        }
        if (
          (subStructure[e]["type"] == "img" ||
            subStructure[e]["id_functional_type"] == 5) &&
          subStructure[e]["url"] !== null &&
          subStructure[e]["url"] !== undefined
        ) {
          if (subStructure[e]["url"].toString().includes("*{$")) {
            subStructure[e]["plantillaUrl"] = JSON.parse(
              JSON.stringify(subStructure[e]["url"])
            );
          }
        }
        if (
          subStructure[e]["description"] !== null &&
          subStructure[e]["description"] !== undefined
        ) {
          if (subStructure[e]["description"].toString().includes("*{$")) {
            subStructure[e]["plantillaDescription"] = JSON.parse(
              JSON.stringify(subStructure[e]["description"])
            );
          }
        }
        if (subStructure[e]["plantillaText"]) {
          this.changeAllTagsWithValues(subStructure[e], row, 1);
        }
        if (subStructure[e]["plantillaUrl"]) {
          this.changeAllTagsWithValues(subStructure[e], row, 2);
        }
        if (subStructure[e]["plantillaDescription"]) {
          this.changeAllTagsWithValues(subStructure[e], row, 3);
        }
        //Check status expansion with id_db, bd_table, bd_field
        this.changeExpansionStatus(subStructure[e], row);
        this.fillExpansion(row, subStructure[e]["child"]);
      }
    }
  }

  public changeExpansionStatus(structure, row) {
    if (
      (structure["id_db"] !== null &&
        structure["id_db"] !== undefined &&
        structure["bd_table"] !== null &&
        structure["bd_table"] !== undefined &&
        structure["bd_field"] !== null &&
        structure["bd_field"] !== undefined &&
        structure["id_condition"] == 10) ||
      structure["id_condition"] == 11
    ) {
      let tag =
        "exp*" +
        structure["id_db"] +
        "." +
        structure["bd_table"] +
        "." +
        structure["bd_field"] +
        "-" +
        structure["id_functional_area"];
      if (row[tag] !== null && row[tag] !== undefined) {
        console.log("Value is correct, normal view for: ", structure);
        if (structure["id_condition"] == 10) structure["expansionStatus"] = 1;
        else if (structure["id_condition"] == 11)
          structure["expansionStatus"] = 2;
      } else {
        console.log("Value is incorrect, no-view for: ", structure);
        if (structure["id_condition"] == 10) structure["expansionStatus"] = 2;
        else if (structure["id_condition"] == 11)
          structure["expansionStatus"] = 1;
      }
    }
  }

  public changeAllTagsWithValues(structure, row, type) {
    let row2 = [];
    for (let r in row) {
      let newLabelRow = r.split("-")[0];
      row2[newLabelRow] = row[r];
    }

    let str: string = "";
    let finalString = "";
    let finalStringTrafficLights = "";

    if (type == 1) {
      str = structure["plantillaText"].toString();
      finalStringTrafficLights = structure["text"];
    } else if (type == 2) {
      str = structure["plantillaUrl"].toString();
      finalStringTrafficLights = structure["url"];
    } else if (type == 3) {
      str = structure["plantillaDescription"].toString();
      finalStringTrafficLights = structure["description"];
    }

    let splits = str.split("*");

    if (structure["id_functional_type"] != 124) {
      for (let s of splits) {
        if (s.includes("{$") && s.includes("$}")) {
          console.log(row2);
          let tagId = s.split("$")[1];
          let value = row2["exp*" + tagId];
          if (value === null || value === undefined) value = "";
          finalString += value;
        } else {
          finalString += s;
        }
      }
      finalString = finalString.replace(/(?:\r\n|\r|\n)/g, "<br>");
      if (type == 1) {
        if (structure["text"] !== null && structure["text"] !== undefined)
          structure["text"] = finalString;
      } else if (type == 2) {
        if (structure["url"] !== null && structure["url"] !== undefined)
          structure["url"] = finalString;
      } else if (type == 3) {
        if (
          structure["description"] !== null &&
          structure["description"] !== undefined
        )
          structure["description"] = finalString;
      }
    } else {
      let tagId = str.split("$")[1];
      finalStringTrafficLights = finalStringTrafficLights.replace(/\*.*\*/, "");
      structure["trafficLightValue"] = row2["exp*" + tagId];
      if (type == 1) {
        structure["text"] = finalStringTrafficLights;
      } else if (type == 2) {
        structure["url"] = finalStringTrafficLights;
      } else if (type == 3) {
        structure["description"] = finalStringTrafficLights;
      }
    }
  }

  public evaluateIfsCondition(ids: any[], id_fa: any, structure: any) {
    let result = true;

    ids.toString();
    let allIds = JSON.parse("[" + ids + "]");

    for (const id of allIds) {
      id.toString();
      switch (id) {
        case 0:
          result = result && true;
          break;
        case 1:
          if (structure[id_fa]["id_functional_area"] % 2 == this.x) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 2:
          result = result && false;
          break;
        case 3:
          if (!this.shouldOpenChat) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 4:
          if (this.shouldOpenChat) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 5:
          if (!this.shouldOpenHelp) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 6:
          if (this.shouldOpenHelp) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 7:
          if (this.authService.accountsGeneric.length > 0) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 8:
          if (
            this.authService.companyGenericName !== undefined &&
            this.authService.companyGenericName !== null
          ) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 9:
          if (this.chatService.non_read > 0) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 10:
          result = false;
          if (structure["expansionStatus"] == 1) result = true;
          else if (structure["expansionStatus"] == 2) result = false;
          break;
        case 11:
          result = true;
          if (structure["expansionStatus"] == 1) result = true;
          else if (structure["expansionStatus"] == 2) result = false;
          break;
        case 12:
          if (!(this.authService.empresaId > 0)) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 13:
          if (this.authService.empresaId > 0) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 14:
          if (
            structure["checkedCondition"] === undefined ||
            structure["checkedCondition"] === null
          ) {
            structure["checkedCondition"] = true;
            let status = this.findElementWithId(2351)["child"]["tmp_value"];
            if (status == 4) {
              structure["checkedCondition"] = true;
            } else structure["checkedCondition"] = false;
          }
          result = structure["checkedCondition"];
          break;
        case 15:
          if (
            structure["checkedCondition"] === undefined ||
            structure["checkedCondition"] === null
          ) {
            structure["checkedCondition"] = true;
            let status = this.findElementWithId(2351)["child"]["tmp_value"];
            if (status != 4) {
              structure["checkedCondition"] = true;
            } else structure["checkedCondition"] = false;
          }
          result = structure["checkedCondition"];
          break;
        case 16:
          let changes = this.getNumChanges();
          if (changes > 0) result = true;
          else result = false;
          break;
      }
    }
    return result;
  }

  getNumChanges() {
    const keys = Object.keys(this.formsChanged);
    let changes = 0;
    for (let key of keys) {
      if (this.currentInitialArea["key"] == key) {
        changes += Object.keys(this.formsChanged[key]).length;
      }
    }
    return changes;
  }

  public processThumbnails(item): String[] {
    if (
      isPlatformServer(this.platformId) ||
      item == null ||
      item.thumbnails == null
    ) {
      return [];
    }
    if (this.webpSupported) {
      const webp = item.thumbnails.filter(
        (t) => t.substr(-4, 4).toLowerCase() === "webp"
      );
      if (webp.length > 0) {
        return webp;
      }
    }
    const ret = item.thumbnails.map((t) => t.substr(0, t.length - 4) + "jpg");
    return ret;
  }

  public processFotos(item): String[] {
    if (
      isPlatformServer(this.platformId) ||
      item == null ||
      item.fotos == null
    ) {
      return [];
    }
    if (this.webpSupported) {
      const webp = item.fotos.filter(
        (t) => t.substr(-4, 4).toLowerCase() === "webp"
      );
      if (webp.length > 0) {
        return webp;
      }
    }
    const ret = item.fotos.map((t) => t.substr(0, t.length - 4) + "jpg");
    return ret;
  }

  public prepareHrefPage(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      return "http://" + url;
    } else {
      return url;
    }
  }

  public prepareWebLinkToShow(url: string) {
    return url.replace(/^(?:https?:\/\/)?/i, "").split("/")[0];
  }

  public round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public scrollToTop(): void {
    window.scroll(0, 0);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  public getUserInmoName = (userId) => {
    return new Promise((resolve) => {
      this.endpointService.getInmoName(userId).subscribe((data) => {
        resolve(data["response"][0]?.nombre);
      });
    });
  };

  scroll(id) {
    setTimeout(() => {
      let yOffset = this.headHeight; // Headers
      if (this.authService.isUserSessionActive()) yOffset += this.headHeight;
      let box = document.getElementsByClassName('notificacion-superior-class')[0].getBoundingClientRect();
      yOffset += box.height;
      let element = document.getElementById(id);
      console.log("yOffset", element);

      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      //window.scroll(0, y);
      //window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }, 250);
  }

  writeScrollPosition(scrollPos) {
    this.scrollValue = scrollPos;
    this.scrollPostionY.next(this.scrollValue);
  }

  getHeadScrollHeight() {
    return this.headHeight;
  }

  /*public processImages(data: any) {
    console.log(data)
    for (let i = 0; i < data.length; i++) {
      if (data[i].id !== -1) {
        const aux = this.processImage(data[i]);
        console.log(aux);
        data[i].imagen = aux;
      };
      //data[i].thumbnails = this.processThumbnails(data[i]);
    }
  }*/

  public processImage(item): String {
    const imagen = item.imagen;
    /*const auxLength = imagen.split('.').length;
    const format = imagen.split('.')[auxLength-1];
    console.log(format);
    if (isPlatformServer(this.platformId) || item == null || imagen == null) {
      return '';
    }
    if (this.webpSupported) {
      // const aux = imagen.substr(-4, 4).toLowerCase();
      if (format === 'webp') {
        return imagen;
      }
    }
    if (format === 'jpg') {
      return imagen;
    } else {
      const aux = imagen.substr(-4, 4).toLowerCase() + '.jpg';
      return aux;
    }*/
    return imagen;
  }


  public checkChildActive(element) {
    for (let i in element['child']) {
      if (this.evaluateIfs(element['child'][i]['id_condition'], element['child'][i]['id_functional_area'], element['child'][i]) && element['child'][i]['id_functional_status_general'] != 2 && element['child'][i]['hide'] != 1) {
        return true;
      }
    }
    element['id_functional_status_general'] = 2;
    return false;
  }
}
