<div class="row part-superior">
  <div class="column3">
    <div>

      <h2>
        ¿MOVINS?
      </h2>

      <p>Para poder disfrutar de las ventajas que ofrece 4DMOVIN, hemos creado una nueva forma de adquirir nuestros
        productos y servicios mucho más cómoda y simplificada que las convencionales.</p>

      <p>Os presentamos los MOVINS.</p>

      <p>Los MOVINS son la moneda que las inmobiliarias usarán para adquirir productos o servicios en la plataforma 4DMOVIN.</p>

      <p>Están basados en un modelo de suscripción, así pues cada inmobiliaria podrá decidir cuantos MOVINS adquirir y
        éstos se renovarán con la misma cantidad cuando se terminen.
        Este plan se podrá modificar y adquirir más MOVINS o menos, según las necesidades de cada empresa.</p>

    </div>
  </div>
  <div class="example-container column3 taulesgrans" style="width: 36%;">

    <h2>Tarifas</h2>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">

      <ng-container matColumnDef="nmovin">
        <th mat-header-cell *matHeaderCellDef> Nº movins </th>
        <td mat-cell *matCellDef="let element; let i = index"><span *ngIf="planesArray.length - 1 == i">> {{element.num_min}}</span><span *ngIf="planesArray.length - 1 != i">{{element.num_min + " - " + element.num_max}}</span> </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Precio por Movin </th>
        <td mat-cell *matCellDef="let element"> {{element.precio + " €"}} </td>
      </ng-container>

      <ng-container matColumnDef="iva">
        <th mat-header-cell *matHeaderCellDef> Precio con IVA </th>
        <td mat-cell *matCellDef="let element"><span>{{(element.precio*1.21) | number : '1.2-2'}}</span><span>{{" €"}}</span> </td>
      </ng-container>

      <ng-container matColumnDef="dto">
        <th mat-header-cell *matHeaderCellDef> Descuento aplicado </th>
        <td mat-cell *matCellDef="let element"> {{element.dto_aplicado + " %"}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headMovins"></tr>
      <tr mat-row *matRowDef="let row; columns: headMovins;"></tr>

    </table>

  </div>
  <div class="column3">

    <div *ngIf="totalMovin <= 0">
      <p class="no-movins">¡Vaya, parece que estás <b>sin MOVINS</b>!</p>
      <p class="text-no-movins">Si quieres disfrutar todos los beneficios de 4DMOVIN, deberás escoger el plan que mejor se adapte a tus necesidades.</p>
    </div>

    <div *ngIf="totalMovin > 0">
      <p class="no-movins">Tienes <b>{{totalMovin}} MOVINS</b> disponibles</p>
    </div>

    <mat-tab-group class="group-2-tabs tabs-bill-2">
      <mat-tab>
        <ng-template mat-tab-label>Mi plan</ng-template>
        <div>
          <p *ngIf="actual.length > 0">
            Plan de suscripción actual para tu próxima compra:
          </p>
          <p *ngIf="actual.length > 0"><b class="my-plan">{{subscribeActual}} movins</b></p>
          <p *ngIf="actual.length <= 0"><b class="my-plan">NO TIENES NINGÚN PLAN</b></p>
          <p>
            <i>
              Cuando no dispongas de más Movins, se renovará el plan automáticamente.
            </i>
          </p>
        </div>
        <button mat-button *ngIf="actual.length <= 0" (click)="subscribir(1)">Escoger plan</button>
        <button mat-button *ngIf="actual.length > 0" (click)="subscribir(1)">Cambiar plan</button>
        <button mat-button *ngIf="actual.length > 0" (click)="cancelar()" >Cancelar plan</button>

      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>Compra única</ng-template>
        <div>
          <p class="my-plan">
            Aquí puedes hacer una compra única de MOVINS
          </p>
          <p>
            <i>
              Cuando no dispongas de más Movins, NO se renovará el plan automáticamente.
            </i>
          </p>
        </div>
        <button mat-button (click)="subscribir(0)">Comprar ahora</button>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<!--<div class="row">
  <div class="example-container column taulesgrans">

    <h2>Movins adquiridos</h2>

    <table mat-table [dataSource]="dataSourceBought" class="mat-elevation-z2 " >

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Fecha compra </th>
        <td mat-cell *matCellDef="let element"> {{element.fecha_alta}} </td>
      </ng-container>

      <ng-container matColumnDef="buyer">
        <th mat-header-cell *matHeaderCellDef> Comprador </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="nmovin">
        <th mat-header-cell *matHeaderCellDef> Nº Movins </th>
        <td mat-cell *matCellDef="let element"> {{element.num_comprados}} </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Precio sin iva </th>
        <td mat-cell *matCellDef="let element"> {{element.precio+ " €"}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headBought"></tr>
      <tr mat-row *matRowDef="let row; columns: headBought;"></tr>

    </table>
    <i *ngIf="planesBoughtArray.length <= 0" style="text-align: center"> No se han encontrado datos </i>
  </div>
  <div class="example-container column taulesgrans">

    <h2>Movins invertidos</h2>

    <table mat-table [dataSource]="dataSourceUsed" class="mat-elevation-z2 " >

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Fecha gasto </th>
        <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> Usuario </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="nmovinSpent">
        <th mat-header-cell *matHeaderCellDef> Movin invertido </th>
        <td mat-cell *matCellDef="let element"> {{element.gasto_movins}} </td>
      </ng-container>

      <ng-container matColumnDef="concept">
        <th mat-header-cell *matHeaderCellDef> Concepto </th>
        <td mat-cell *matCellDef="let element"> {{element.tipo}} ({{element.ref}})</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headUsed"></tr>
      <tr mat-row *matRowDef="let row; columns: headUsed;"></tr>
    </table>
    <i *ngIf="planesUsedArray.length <= 0" style="text-align: center"> No se han encontrado datos </i>
  </div>
</div>-->

<!--
<mat-tab-group class="group-2-tabs tabs-bill">
  <mat-tab>
    <ng-template mat-tab-label>Compras realizadas</ng-template>

    <div class="row">
        <div class="example-container column taulesgrans">

          <h2>Movins adquiridos</h2>

          <table mat-table [dataSource]="dataSourceBought" class="mat-elevation-z2 " >

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Fecha compra </th>
              <td mat-cell *matCellDef="let element"> {{element.fecha_alta}} </td>
            </ng-container>

            <ng-container matColumnDef="buyer">
              <th mat-header-cell *matHeaderCellDef> Comprador </th>
              <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>

            <ng-container matColumnDef="nmovin">
              <th mat-header-cell *matHeaderCellDef> Nº Movins </th>
              <td mat-cell *matCellDef="let element"> {{element.num_comprados}} </td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef> Precio sin iva </th>
              <td mat-cell *matCellDef="let element"> {{element.precio+ " €"}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="headBought"></tr>
            <tr mat-row *matRowDef="let row; columns: headBought;"></tr>

          </table>
          <i *ngIf="planesBoughtArray.length <= 0" style="text-align: center"> No se han encontrado datos </i>
      </div>
        <div class="example-container column taulesgrans">

          <h2>Movins invertidos</h2>

          <table mat-table [dataSource]="dataSourceUsed" class="mat-elevation-z2 " >

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Fecha gasto </th>
              <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
            </ng-container>

            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef> Usuario </th>
              <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>

            <ng-container matColumnDef="nmovinSpent">
              <th mat-header-cell *matHeaderCellDef> Movin invertido </th>
              <td mat-cell *matCellDef="let element"> {{element.gasto_movins}} </td>
            </ng-container>

            <ng-container matColumnDef="concept">
              <th mat-header-cell *matHeaderCellDef> Concepto </th>
              <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="headUsed"></tr>
            <tr mat-row *matRowDef="let row; columns: headUsed;"></tr>
          </table>
          <i *ngIf="planesUsedArray.length <= 0" style="text-align: center"> No se han encontrado datos </i>
        </div>
    </div>

  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Datos facturación</ng-template>
    <div style="padding: 30px;">
      <div class="row-expansion4">
        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.CIF}}"
                 [(ngModel)]="bodyInmo.cif" maxlength="100" required>
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 type="text" placeholder="{{strings.DIR_BILL}}"
                 [(ngModel)]="bodyInmo.payDirection" maxlength="100" required>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.BUSINESS}}"
                 [(ngModel)]="bodyInmo.payName" maxlength="100" required>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.EMAIL}}"
                 [(ngModel)]="bodyInmo.payEmail" maxlength="100" required>
        </mat-form-field>

      </div>
      <div class="row-expansion4">
        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.LOCALIZATION}}"
                 [(ngModel)]="bodyInmo.payCity" maxlength="100" required>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.COUNTRY}}"
                 [(ngModel)]="bodyInmo.payCountry" maxlength="100" required>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.MPOST}}"
                 [(ngModel)]="bodyInmo.payPostalCode" [maxLength]="5" required>
        </mat-form-field>
      </div>
      <mat-radio-group class="row-expansion4" [(ngModel)]="bodyInmo.payType" [ngModelOptions]="{standalone: true}">
        <mat-radio-button class="buit" [value]="0" color="primary">Cuenta bancaria</mat-radio-button>
        <mat-radio-button class="buit" [value]="1" color="primary">Tarjeta</mat-radio-button>
      </mat-radio-group>
      <div class="row-expansion4" *ngIf="bodyInmo.payType == 0">
        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.TITULAR}}"
                 [(ngModel)]="bodyBill.titular" maxlength="100">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.IBAN}}"
                 [(ngModel)]="bodyBill.iban" maxlength="100">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.BIC}}"
                 [(ngModel)]="bodyBill.bic" maxlength="100">
        </mat-form-field>

      </div>
      <div class="row-expansion4" *ngIf="bodyInmo.payType == 1">
        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.ADMIN_INMO_NAME}}"
                 [(ngModel)]="bodyBill.propietario" maxlength="100">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.CODE_CREDIT}}"
                 [(ngModel)]="bodyBill.codeCredit" maxlength="100">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="{{strings.CODE_SECURITY}}"
                 [(ngModel)]="bodyBill.segurityCode" maxlength="100">
        </mat-form-field>

        <div class="row-expansion2 buit" *ngIf="bodyInmo.payType == 1">

          <mat-form-field>
            <mat-label>Mes Caducidad</mat-label>
            <input matInput type="number" placeholder="MM"
                   [(ngModel)]="bodyBill.month" [maxLength]="2">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Año Caducidad</mat-label>
            <input matInput type="number" placeholder="AA"
                   [(ngModel)]="bodyBill.year" [maxLength]="2">
          </mat-form-field>
        </div>

      </div>
    </div>
  </mat-tab>
</mat-tab-group>
-->

<app-stripe-payment #stripePayment
(sendBuyEvent)="save($event)"
></app-stripe-payment>
