import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function checkSpecialCharacter (nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const name = control.value;
    let no = nameRe.test(name);
    return no ? {'checkSpecialCharacter': {name}} : null;
  };
}


@Directive({
  selector: '[checkSpecialCharacter]',
  providers: [{provide: NG_VALIDATORS, useExisting: CheckSpecialCharacterDirective, multi: true}]

})
export class CheckSpecialCharacterDirective  implements Validator, OnChanges {
 @Input() forbiddenName: string;
 private valFn = Validators.nullValidator;

 ngOnChanges(changes: SimpleChanges): void {
   const change = changes['checkSpecialCharacter'];
   if (change) {
     const val: string | RegExp = change.currentValue;
     const re = val instanceof RegExp ? val : new RegExp(val, 'i');
     this.valFn = checkSpecialCharacter(re);
   } else {
     this.valFn = Validators.nullValidator;
   }
 }

 validate(control: AbstractControl): {[key: string]: any} {
   return this.valFn(control);
 }
}
