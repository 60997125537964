import {Component, ElementRef, Inject, ViewChild, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'bottom-sheet-actions',
  templateUrl: 'bottom-sheet-actions.html',
})
export class BottomSheetActions implements OnInit{

  public templates = new Array();
  public clients = new Array();
  public action: number;
  public client : number;
  public id_templete = null;

  public buttonStrings = ['Descargar','Imprimir','Compartir','Enviar'];

  clientControlOptions: Observable<any[]>;

  @ViewChild("checker") ckeckerField: ElementRef;

  @ViewChild("checkerClients") ckeckerFieldClient: ElementRef;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  trick = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public emails = new Array();

  /*emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  public clientControl = new FormControl('', [
    Validators.required
  ]);*/

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['name', 'check'];

  public dataSource: MatTableDataSource<any>;


  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  private inputFilter: string;
  private loaded: boolean;

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetActions>,
              public snackBar: MatSnackBar,
              private _formBuilder: UntypedFormBuilder,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {

    this.templates = data.templates;
    this.action = data.action;
    this.clients = data.clients;
  }

  ngOnInit(){
    //this.emailFormControl.setValue("rupeshpons@gmail.com");

    this.inputFilter = '';

    this.firstFormGroup = this._formBuilder.group({
      clientControl: ['', Validators.required],
      emailFormControl: ['', [Validators.required, Validators.email]]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ''
    });

    this.clientControlOptions = this.firstFormGroup.get('clientControl').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.clientName),
      map(name => name ? this._filter(name) : this.clients.slice())
    );

    let array = this.templates.map(data => {
      data.checked = false;
      return data;
    });

    this.dataSource = new MatTableDataSource(array);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilter(this.inputFilter);
    this.loaded = true;

  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our email
    if ((value || '').trim()) {
      this.emails.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(email: any): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }

    this.checkAnyValidatorChange();
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.clients.filter(option => option.clientName.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(object?: any): string | undefined {
    return object ? object.clientName : undefined;
  }

  public optionSelected($event){
    //this.client = $event.option.value.id;
    this.firstFormGroup.get('clientControl').setValue("");
    this.ckeckerFieldClient.nativeElement.blur();
    this.emails.push($event.option.value.eMail);

    this.firstFormGroup.get('clientControl').clearValidators();
    this.firstFormGroup.get('clientControl').updateValueAndValidity();

    this.firstFormGroup.get('emailFormControl').clearValidators();
    this.firstFormGroup.get('emailFormControl').setValidators([Validators.email]);
    this.firstFormGroup.get('emailFormControl').updateValueAndValidity();
  }

  setIdTemplete(id, index){
    if (this.dataSource.data[index].checked !== true){
      this.dataSource.data = this.dataSource.data.map((data, i) => {
        data.checked = i === index ? true : false;
        return data;
      });
    } else {this.dataSource.data[index].checked = false}

    this.id_templete = id;
  }

  setIdTempleteChecker(id, index){

    this.dataSource.data = this.dataSource.data.map((data, i) => {
        data.checked = i === index ? true : false;
        return data;
      });

    this.id_templete = id;
  }

  public checkAnyValidatorChange(){
    if (this.emails.length === 0){
      this.firstFormGroup.get('clientControl').setValidators([Validators.required]);
      this.firstFormGroup.get('clientControl').updateValueAndValidity();
      this.firstFormGroup.get('emailFormControl').setValidators([Validators.required, Validators.email]);
      this.firstFormGroup.get('emailFormControl').updateValueAndValidity();

      this.snackBar.open('Es necessario introducir un cliente o un email', 'X', {
        duration: 3000,
        panelClass: ['lightblue-snackbar'],
        verticalPosition: 'top'
      });
    }
  }

  public checkValidatorsBeforeNext(){
    if (this.emails.length === 0){
      this.snackBar.open('Es necessario introducir un cliente o un email', 'X', {
        duration: 3000,
        panelClass: ['lightblue-snackbar'],
        verticalPosition: 'top'
      });
    }
  }
  closeBottomSheet(type){
    if (type === 1){
      if (this.action === 4){ //significa que és del tipus enviar email

        //let email = (this.firstFormGroup.get('emailFormControl').hasError('required')) || (this.firstFormGroup.get('emailFormControl').hasError('email') && !this.firstFormGroup.get('emailFormControl').hasError('required'));
        //let client = (this.firstFormGroup.get('clientControl').hasError('required'));

        if (this.emails.length === 0){

          this.firstFormGroup.get('clientControl').setValidators([Validators.required]);
          this.firstFormGroup.get('clientControl').updateValueAndValidity();
          this.firstFormGroup.get('emailFormControl').setValidators([Validators.required, Validators.email]);
          this.firstFormGroup.get('emailFormControl').updateValueAndValidity();

          /*les lines següents són perquè quan l'usauri premi una de les plantilles miri si s'ha introduit un email, el el cas que no
          * fara un focus al input (premer l'input des de typescript i blur es com premer fora del input, de forma que et mostrarà l'error)*/
          this.ckeckerField.nativeElement.focus();  //email
          this.ckeckerField.nativeElement.blur();

          this.ckeckerFieldClient.nativeElement.focus();  //client
          this.ckeckerFieldClient.nativeElement.blur();

          this.snackBar.open('Es necessario introducir un cliente o un email', 'X', {
            duration: 6000,
            panelClass: ['lightblue-snackbar'],
            verticalPosition: 'top'
          });
        } else {
          this._bottomSheetRef.dismiss({idTemplete : this.id_templete, email : this.emails});

        }
      } else {
        this._bottomSheetRef.dismiss({idTemplete : this.id_templete, email : null});
      }
    } else {
      this._bottomSheetRef.dismiss();
    }
  }

  addEmailChip() {

    if ((this.firstFormGroup.get('emailFormControl').hasError('required')) || (this.firstFormGroup.get('emailFormControl').hasError('email') && !this.firstFormGroup.get('emailFormControl').hasError('required'))){
      this.ckeckerField.nativeElement.focus();
      this.ckeckerField.nativeElement.blur();
    } else {
      if (this.firstFormGroup.get('emailFormControl').value !== ""){
        this.emails.push(this.firstFormGroup.get('emailFormControl').value);
        this.firstFormGroup.get('emailFormControl').setValue("");

        this.firstFormGroup.get('clientControl').clearValidators();
        this.firstFormGroup.get('clientControl').updateValueAndValidity();

        this.firstFormGroup.get('emailFormControl').clearValidators();
        this.firstFormGroup.get('emailFormControl').setValidators([Validators.email]);
        this.firstFormGroup.get('emailFormControl').updateValueAndValidity();
      }
    }
  }

  clear () {  //clear the validators when one of the two inputs is pressed
    this.firstFormGroup.get('clientControl').clearValidators();
    this.firstFormGroup.get('clientControl').updateValueAndValidity();

    this.firstFormGroup.get('emailFormControl').clearValidators();
    this.firstFormGroup.get('emailFormControl').updateValueAndValidity();
  }
}
