<div class="notis0 mkt-dialoguing">
  <mat-horizontal-stepper class="general-stepper" [linear]="false" #stepper style="min-width: 65vw; max-height: 60vh; min-height: 40vh;">
  <mat-step label="Dependencias">
    <div fxFlex="100" style="height: 18em;" fxLayout="row wrap">
      <div fxFlex="30" fxLayout="row wrap" style="height: 85%; padding-right: 3em;">
        <span style="font-weight: bolder; font-size: 18px; text-align: left; margin-left: 10px;">PRODUCTO SELECCIONADO</span>
        <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
          <div class="main-container" fxFlex="100" fxLayout="row wrap">
            <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start" >
              <span class="titulo-modulo" fxFlex="100">{{modulo.nombre}}</span>
              <div class="price" fxFlex="100" fxLayout="row wrap">
                <span fxFlex="100" *ngIf="modulo.precio < modulo.price_base" style="text-decoration: line-through;">{{modulo.price_base}} MOVS</span>
                <span fxFlex="100">{{modulo.precio}} MOVS</span>
              </div>
            </div>
            <div fxFlex="50" class="right-part">
              <div class="category-product-0">
                <span class="category-product" fxFlex="100" *ngIf="modulo.id_product_type == 1">Producto</span>
                <span class="category-product" fxFlex="100" *ngIf="modulo.id_product_type == 2">Widget</span>
              </div>
              <img *ngIf="modulo.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(modulo.imagen)">
            </div>
          </div>
          <!-- Hover -->
          <div class="text-modulo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <span fxFlex="100" class="description-modulo">{{modulo.descripcion}}</span>
          </div>
          <button class="button-registry" fxFlex="100" *ngIf="modulo.video">Ver vídeo</button>
        </div>
      </div>
      <div fxFlex="70" fxLayout="row wrap" style="height: 85%; padding-left: 3em;" *ngIf="moduloDependencias.length > 0">
        <span style="font-weight: bolder; font-size: 18px; margin-left: calc(45px + 0.7em);">PRODUCTOS DEPENDIENTES <i class="material-icons" matTooltip="Los productos dependientes son productos necesarios para el correcto funcionamiento del producto que deseas adquirir" style="cursor: help;">help_outlined</i></span>
        <p-carousel [value]="moduloDependencias" [numVisible]="2" [numScroll]="2" [circular]="false" [page]="0">
          <ng-template let-card pTemplate="item">
            <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
              <div class="main-container" fxFlex="100" fxLayout="row wrap">
                <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start" >
                  <span class="titulo-modulo" fxFlex="100">{{card.nombre}}</span>
                  <div class="price" fxFlex="100" fxLayout="row wrap">
                    <span fxFlex="100" *ngIf="card.precio < card.price_base" style="text-decoration: line-through;">{{card.price_base}} MOVS</span>
                    <span fxFlex="100">{{card.precio}} MOVS</span>
                  </div>
                </div>
                <div fxFlex="50" class="right-part">
                  <div class="category-product-0">
                    <span class="category-product" fxFlex="100" *ngIf="modulo.id_product_type == 1">Producto</span>
                    <span class="category-product" fxFlex="100" *ngIf="modulo.id_product_type == 2">Widget</span>
                  </div>
                  <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
                </div>
              </div>
              <!-- Hover -->
              <div class="text-modulo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <span fxFlex="100" class="description-modulo">{{card.descripcion}}</span>
              </div>
              <button class="button-registry" fxFlex="100" *ngIf="card.video">Ver vídeo</button>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
    <span fxFlex="100" *ngIf="firstConfirm === false" style="font-size: 14px; color: red;">Lamentablemente no puedes comprar alguna de las dependencias de este modulo...</span>
    <button *ngIf="firstConfirm === true" class="button-general" (click)="getWidgets(0)">Confirmar</button>
    <button *ngIf="firstConfirm === false" class="button-general" disabled>Confirmar</button>
  </mat-step>
  <mat-step label="Widgets">
    <div style="display: flex;" fxLayout="row wrap">
      <span style="font-weight: bolder; font-size: 18px;" fxFlex="100">ELIGE LOS WIDGETS QUE DESEAS <i class="material-icons" matTooltip="Los widgets son extensiones que se adhieren a los productos para potenciarlos" style="cursor: help;">help_outlined</i></span>
      <div *ngFor="let w of widgets | keyvalue" fxFlex="100" fxLayout="row wrap" style="justify-content: space-between; margin-top: 1em; margin-bottom: 0.5em">
        <div fxFlex="100" style="text-transform: uppercase; font-weight: 500;">WIDGETS DEL PRODUCTO <span style="color: #fddd00; font-weight: 700;">{{getModuloNameById(w.key)}} <i class="material-icons" matTooltip="{{modulo.descripcion}}" style="cursor: help; font-size: 18px;">info</i></span></div>
        <div fxFlex="100" fxLayout="row wrap">
          <div *ngFor="let widget of w.value; let i = index;" fxFlex="25" fxLayout="row wrap" style="justify-content: space-between; margin-top: 0.5em; margin-bottom: 0.2em">
            <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
              <div class="main-container" fxFlex="100" fxLayout="row wrap">
                <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start" >
                  <span class="titulo-modulo" fxFlex="100">{{widget.nombre}}</span>
                  <div class="price" fxFlex="100" fxLayout="row wrap">
                    <span fxFlex="100" *ngIf="widget.precio < widget.price_base" style="text-decoration: line-through;">{{widget.price_base}} MOVS</span>
                    <span fxFlex="100">{{widget.precio}} MOVS</span>
                  </div>
                </div>
                <div fxFlex="50" class="right-part">
                  <div class="category-product-0">
                    <span class="category-product" fxFlex="100" *ngIf="widget.id_product_type == 1">Producto</span>
                    <span class="category-product" fxFlex="100" *ngIf="widget.id_product_type == 2">Widget</span>
                  </div>
                  <img *ngIf="widget.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(widget.imagen)">
                </div>
              </div>
              <!-- Hover -->
              <div class="text-modulo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <span fxFlex="100" class="description-modulo">{{widget.descripcion}}</span>
              </div>
              <mat-checkbox style="font-weight: 700; text-align: center; width: 100%; color: #fddd00; background: #222;" [(ngModel)]="widget.added" (ngModelChange)="widgetCarro(widget)">AÑADIR WIDGET</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <mat-divider fxFlex="100" style="margin-bottom: 0.5em;"></mat-divider>

      <div fxFlex="100" fxLayout="row wrap" style="justify-content: space-between;">
        <button *ngIf="!verWidgets" class="button-general" (click)="backStep()">Atrás</button>
        <button class="button-general" (click)="insertarCarritoCompra(); nextStep(2, 1);">Confirmar</button>
      </div>

      <!--<button class="button-general" (click)="nextStep(2)">Confirmar</button>-->
    </div>
  </mat-step>
  <mat-step label="Carrito">
    <div style="display: flex;" fxLayout="row wrap">
      <span style="font-weight: bolder; font-size: 18px;">ASEGURA QUE LA COMPRA ES CORRECTA <i class="material-icons" matTooltip="Aquí podrás ver el resumen de tu compra con tu producto, sus productos dependientes y los widgets que has seleccionado" style="cursor: help;">help_outlined</i></span>
      <div *ngFor="let c of carritoCompra; let i = index;" fxFlex="100" fxLayout="row wrap" style="justify-content: space-between; margin-top: 1em; margin-bottom: 0.5em">
        <div *ngIf="!(verWidgets && c.id_product_type == 1)" fxFlex="46" style="text-transform: uppercase; font-weight: 700;" [ngStyle]="{'color': (c.id_product_type === 1 ? '#fddd00' : '#fff')}">
          {{c.nombre}} <i class="material-icons" matTooltip="{{c.descripcion}}" style="cursor: help; font-size: 18px;">info</i>
        </div>
        <div fxFlex="27" style="text-align: center;">
          <span *ngIf="c.id_product_type === 1" style="text-transform: uppercase; font-weight: 500;">Producto</span>
          <span *ngIf="c.id_product_type === 2" style="text-transform: uppercase; font-weight: 500;">Widget</span>
        </div>
        <div fxFlex="27" fxLayout="row wrap" style="text-align: end;">
          <!-- Movs -->
          <div fxFlex="100" *ngIf="c.precio < c.price_base" style="text-decoration: line-through; color: red">{{c.price_base}} MOVS</div>
          <div fxFlex="100">{{c.precio}} MOVS</div>

          <!-- Descuento -->
          <div fxFlex="100" *ngIf="c.price_discount && c.price_discount != 0" style="color: grey">-{{c.price_discount}} MOVS</div>
          <div fxFlex="100" *ngIf="c.price_discount_perc && c.price_discount_perc != 0" style="color: grey">-{{c.price_discount_perc}}%</div>
        </div>
      </div>
      <mat-divider fxFlex="100" style="margin-bottom: 0.5em;"></mat-divider>
      <div fxFlex="100" fxLayout="row wrap" style="justify-content: space-between; color: #fddd00; font-size: 16px;">
        <div fxFlex="46" fxLayout="row wrap">
          <mat-form-field class="input_descuento">
            <mat-label>Descuento</mat-label>
            <input matInput type="text" [(ngModel)]="voucherCode">
          </mat-form-field>
          <button class="button-general" style="margin: 1em 0 1.5em 0em;" (click)="applyVoucherCode()">Aplicar</button>
        </div>
        <div fxFlex="27" style="font-weight: bold; text-transform: uppercase; text-align: center;">Total</div>
        <div fxFlex="27" style="text-align: end; font-weight: bold;">{{totalCarro}} MOVS</div>
      </div>

      <div fxFlex="100" fxLayout="row wrap" style="justify-content: space-between;">
        <button class="button-general" (click)="backStep()">Atrás</button>
        <button class="button-general" (click)="confirmarCompra(2)">Confirmar</button>
      </div>

      <!--<button class="button-general" (click)="confirmarCompra()">Confirmar</button>-->
    </div>
  </mat-step>
  <mat-step label="Movins">
    <add-movin-coin *ngIf="showAddMovinCoin" (sendEvent)="openStripe($event)"  [type]="1" [minMovins]="totalCarro - movins"></add-movin-coin>
    <app-stripe-payment *ngIf="showAddMovinCoin" #stripePayment (sendBuyEvent)="save($event)"></app-stripe-payment>
  </mat-step>
  <mat-step label="Facturació">
    <span style="font-weight: bolder; font-size: 18px;">STEP 4: Para añadir facturación</span>

  </mat-step>
  <mat-step label="Productos relacionados">
    <div style="display: flex;" fxLayout="row wrap">
      <span fxFlex="100" style="font-weight: bolder; font-size: 18px;">STEP 5: Productos relacionados:</span>
      <div *ngFor="let p of productosRelacionados; let i = index;" fxFlex="100" fxLayout="row wrap" style="justify-content: space-between; margin-top: 1em; margin-bottom: 0.5em">
        <div fxFlex="50">{{p.nombre}}</div>
        <div fxFlex="50" style="text-align: end;">
          <button class="button-general" (click)="cerrarStepperYAbrir(p)">Adquirir</button>
        </div>
      </div>
      <mat-divider fxFlex="100" style="margin-bottom: 0.5em;"></mat-divider>

      <button class="button-general" (click)="cerrarStepper()">Acceptar</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
</div>
