<h2 *ngIf="title !== null" mat-dialog-title>{{title}}</h2>

<div class="content-dialog" mat-dialog-content>{{message}}</div>
<div class="dialog-footer-2" *ngIf="labelButton3 === null">
  <button *ngIf="labelButton1 !== null" mat-primary class="mat-raised-button"(click)="clickButton(1)">{{labelButton1}}</button>
  <button *ngIf="labelButton2 !== null" class="mat-raised-button" (click)="clickButton(2)">{{labelButton2}}</button>
</div>
<div class="dialog-footer-2" *ngIf="labelButton3 !== null">
  <button style="width: 100%;" *ngIf="labelButton2 !== null" class="mat-raised-button" (click)="clickButton(2)">{{labelButton2}}</button>
  <button style="width: 100%;" class="mat-raised-button" (click)="clickButton(3)">{{labelButton3}}</button>
  <button style="width: 100%;" *ngIf="labelButton1 !== null" mat-primary class="mat-raised-button"(click)="clickButton(1)">{{labelButton1}}</button>
</div>

<!--
<mat-dialog-content [formGroup]="form">
  
	<mat-form-field>
        <input matInput
               placeholder="{{message}}"
               formControlName="{{message}}">
    </mat-form-field>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button *ngIf="labelButton1" mat-primary class="mat-raised-button"(click)="clickButton(1)">{{labelButton1}}</button>
    <button *ngIf="labelButton2" class="mat-raised-button"(click)="clickButton(2)">{{labelButton2}}</button>
    <button *ngIf="labelButton3" class="mat-raised-button"(click)="clickButton(3)">{{labelButton3}}</button>
</mat-dialog-actions>
-->
