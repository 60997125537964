<div>
  <div>

    <div>
<!--      <input type="file" placeholder="Subir imagen" style="width: 100%;" accept="image/*" (change)="changeSelectedImage($event)">-->
      <input type="file" placeholder="Subir imagen" style="width: 100%;" accept="image/*"> <!--IBRA: He comentado la linia de arriba y he puesto esta para poder subirto a demo-->
      <img [src]="imgURL">
    </div>
    <!--image-upload [max]="1" [url]="backendRequest" [buttonCaption]="'Seleccionar imagen'" [dropBoxMessage]="'Arrastrar imagen'"
                  (uploadFinished)="onUploadFinished($event)">
    </image-upload-->
  </div>

  <div class="dialog-footer-2">
    <button mat-button (click)="closeBottomSheet(2)">Cancelar</button>
    <button mat-button (click)="closeBottomSheet(1)">Aceptar</button>
  </div>

</div>
