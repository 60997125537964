import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {FormControl} from '@angular/forms';
import {GenericService} from "../../../../services/generic/generic.service";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/fr';
import { ComponentResourceCollector } from '@angular/cdk/schematics';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-bottom-sheet-filter-table',
  templateUrl: './bottom-sheet-filter-table.component.html',
  styleUrls: ['./bottom-sheet-filter-table.component.css'],
/*
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
*/
})
export class BottomSheetFilterTableComponent implements OnInit {

  public arrayFiltersFields = [];

  //public filteredOptions = [];
  public eventResetFilter: Subject<void> = new Subject<void>();
  
  public loaded = false;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public _bottomSheetRef: MatBottomSheetRef<BottomSheetFilterTableComponent>,
              public genericService: GenericService,
              private _adapter: DateAdapter<any>,
              @Inject(MAT_DATE_LOCALE) private _locale: string) {
    this.arrayFiltersFields = data['arrayFiltersFields'];
  }

  ngOnInit(): void {
    console.log(this.arrayFiltersFields, "arrayFiltersFieldsarrayFiltersFields init");
    this.loaded = true;
  }

  filterChanged(filter: any[]) {
    this.arrayFiltersFields[filter[0]]['filter'] = filter[1];
  }

  public closeBottomSheet() {
    this._bottomSheetRef.dismiss();
  }

  public filterTableValues() {
    this._bottomSheetRef.dismiss(({filters: this.arrayFiltersFields, reset: 0}));
  }

  public resetFilterTableValues() {
    this.eventResetFilter.next(); // Emit event to childs

    for(let i in this.arrayFiltersFields){
      this.arrayFiltersFields[i]['filter'] = [];
    }
    this._bottomSheetRef.dismiss({filters: this.arrayFiltersFields, reset: 1});
  }

  public clearFieldFilter(key, type) {
    if(type == 1) this.arrayFiltersFields[key]['filter'].min = null;
    else if(type == 2) this.arrayFiltersFields[key]['filter'].max = null;
    else if(type == 3) this.arrayFiltersFields[key]['filter'].fechaInicio = null;
    else if(type == 4) this.arrayFiltersFields[key]['filter'].fechaFin = null;
    else this.arrayFiltersFields[key]['filter'] = [];
  }
}
