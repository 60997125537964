import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-chip-autocomplete',
  templateUrl: './chip-autocomplete.component.html',
  styleUrls: ['./chip-autocomplete.component.css']
})
export class ChipAutocompleteComponent implements OnInit, OnDestroy {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  optionCtrl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = [];
  allOptions: string[] = [];

  @ViewChild('optionInput', {static: false}) optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  @Input() filterInitial: any;
  @Input() filterIndex: any;
  @Input() filterFields: any;
  private eventsSubscription: Subscription;
  @Input() resetFilter: Observable<void>;
  @Output() filterChanged = new EventEmitter<any[]>();

  constructor() {}

  ngOnInit(): void {
    console.log(this, "this.allOptionsthis.allOptionsthis.allOptions");

    this.eventsSubscription = this.resetFilter.subscribe(() => this.clearFilter());
    this.allOptions = this.filterFields['value'];
    this.options = this.filterInitial;
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(''),
      //map((option: string | null) => (option ? this._filterChipsAutocomplete(option) : this.allOptions[this.gi].slice())),
      map((option: string) => this._filter(option)),
    );
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    // Inicialitzat this.addOnBlur a false per no utilitzar la funcionalitat add
    // Add option only when addOnBlur
    // and MatAutocomplete is not open to avoid conflicting with OptionSelected Event
    if (this.addOnBlur && !this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our option
      if ((value || '').trim()) {
        this.options.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.optionCtrl.setValue(null);
    }
  }

  remove(option: string): void {
    const index = this.options.indexOf(option);

    if (index >= 0) {
      this.options.splice(index, 1);
    }

    this.newFilterValues();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    //this.optionInput.nativeElement.value = ''; --> No funciona i fa que no funcioni el codi de darrera
    this.optionCtrl.setValue(null);
  
    // Chapuza per fer que el desplegable obri totes les opcions i no nomes l'ultima seleccionada...
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(''),
      //map((option: string | null) => (option ? this._filterChipsAutocomplete(option) : this.allOptions[this.gi].slice())),
      map((option: string) => this._filter(option)),
    );

    this.newFilterValues();
  }

  newFilterValues(): void {
    let filter: any[] = [];
    filter.push(this.filterIndex);
    filter.push(this.options);
    this.filterChanged.emit(filter);
  }

  clearFilter(): void {
    this.options = [];
    //this.optionInput.nativeElement.value = ''; --> No funciona i fa que no funcioni el codi de darrera
    this.optionCtrl.setValue(null);
  
    // Chapuza per fer que el desplegable obri totes les opcions i no nomes l'ultima seleccionada + funcioni filtre...
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(''),
      //map((option: string | null) => (option ? this._filterChipsAutocomplete(option) : this.allOptions[this.gi].slice())),
      map((option: string) => this._filter(option)),
    );

    this.newFilterValues();
  }
  
  private _filter(value: string): string[] {
    if (value != null) {
      const filterValue = value.toLowerCase();

      //return this.allOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0); --> Busca 
      return this.allOptions.filter(option => option.toLowerCase().includes(filterValue));
      }
  }
}
