<div>
  <div>
    <form #uploadForm="ngForm" #uploaderForm>
      <mat-form-field>
        <input matInput name="name" type="text" [(ngModel)]="filename" placeholder="Nombre del archivo">
      </mat-form-field>
      <input style="margin-top: 1em;" type="file" name="uploader" id="uploader" ngControl="uploader" placeholder="Upload file" accept=".pdf,.doc,.dot,.xml,.docx, .docm, .dotx, .dotm, .doc, .wpd, .txt, .odt, .ott, .ods, .ots, .xls, .xlw, .xlsx, .rtf, .csv, .odp, .odg, .pptx, .pptm, .ppt, .pps, .bmp, .jpg, .jpeg, .gif, .png, .svg, .psd, .pdf" multiple>
    </form>
  </div>
  <div class="dialog-footer-2">
    <button (click)="closeBottomSheet(1);" >Cancelar</button>
    <button (click)="fileChange($event);closeBottomSheet(2);" >Confirmar</button>
  </div>
</div>
