import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { StripePaymentComponent } from "../../../stripe/stripe-payment/stripe-payment.component";
import { EndpointService } from "../../../services/endpoint/endpoint.service";
import { AuthService } from "../../../services/auth/auth.service";
import { forkJoin } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('stripePayment') stripePayment: StripePaymentComponent;

  public carritoCompra = [];
  public totalCompra: number;
  public movins: number;
  public loading: boolean;

  public showCompraMovins: boolean;
  // public showPagament: boolean;

  // Checkout:
  public precioFinal: number;
  public numMovins: number;
  public suscripcion: number;

  constructor(
    public dialogRef: MatDialogRef<GeneralComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { moduloInfo, movins },
    private endpointService: EndpointService,
    public authService: AuthService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(): void {
    this.showCompraMovins = false;
    this.loading = false;
    // this.showPagament = false;

    this.carritoCompra.push(this.data.moduloInfo);
    this.totalCompra = this.data.moduloInfo.precio;
    this.movins = parseInt(this.data.movins, 10);
  }

  public nextStep(i: number): void {
    this.stepper.selectedIndex = this.stepper.selectedIndex + i;
  }

  public checkComprarModulos(): void {
    if (this.movins >= this.totalCompra) {
      this.comprarModulos(2);
    } else {
      // No se tienen suficientes Movins, por lo que se tiene que comprar
      this.showCompraMovins = true;
      // this.showPagament = true;
      this.nextStep(1);
    }
  }

  public receiveMessage(data: any): void {
    data.forEach(e => {
      this.carritoCompra.push(e);
      this.totalCompra += e.precio;
    });
    this.nextStep(1);
  }

  public receiveMessageConfig(data: any): void {
    this.dialogRef.close(true);
  }

  public comprarModulos(nextStep: number): void {
    // No hace falta comprar Movins
    const inmoId = this.authService.inmoId;
    const userId = this.authService.userId;
    // 1. Restar Movins
    this.endpointService.restarMovins(this.totalCompra, inmoId, userId).subscribe(data => {
      console.log(data);
      if (data['errorCode'] === 0) {
        console.log('Se han eliminado ', this.totalCompra, ' movins.');
        // 2. Añadir Módulos a la Inmo
        const observerArray = [];
        this.carritoCompra.forEach(element => {
          observerArray.push(this.endpointService.insertModuloInmo(inmoId, element.id_modulo, element.precio, userId));
        });
        forkJoin(observerArray).subscribe(data => {
          let modulosInsertadosCorrectamente = true;
          data.forEach(d => {
            if (d['errorCode'] !== 0) modulosInsertadosCorrectamente = false;
          });

          if (modulosInsertadosCorrectamente === true) {
            try {
              this.nextStep(nextStep);
            } catch (error) {
              this.dialogRef.close(true);
            }
          }
          this.loading = false;
        });
      }
    });
  }

  public openStripe($event: any): void {
    this.suscripcion = 0;
    this.numMovins = $event.movin;
    this.precioFinal = $event.finalIva;
    // TODO: al abrir stripe que no deje poner menos de lo necesario
    this.stripePayment.pay(this.precioFinal, this.numMovins, this.suscripcion);
  }

  save(n: object) {
    this.loading = true;
    if (n['error'] === 0) {
      this.endpointService.setPlanesMovin(
        this.authService.inmoId, this.authService.userId, this.numMovins, this.precioFinal,
        this.suscripcion, null, null, n['suscription'], 0, n['paid']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.comprarModulos(1);
        }
      });
    } else {
      this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    }
  }


}
