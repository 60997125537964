import { Component, OnInit } from '@angular/core';
import { MessageDialogComponent } from "../../../utils/message-dialog/message-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { EndpointService } from "../../../services/endpoint/endpoint.service";
import { Values } from "../../../../values/values";
import { ColaborationBody } from "../colaboration-body";
import { BodyGroup } from "../../../inmo-group/body/body-group";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { LocalStorageService } from "../../../services/local-storage.service";
import { AddElementEventComponent } from "../../../activities/edit-event-dialog/add-element-event/add-element-event.component";


@Component({
  selector: 'app-community-administrator-tab',
  templateUrl: './community-administrator-tab.component.html',
  styleUrls: ['./community-administrator-tab.component.css']
})
export class CommunityAdministratorTabComponent implements OnInit {

  public routes = Values.ROUTES;

  public colaborationInfo = new ColaborationBody();
  public colaborarConAgrupaciones = 0;
  public tipoPerfil = 0;
  public basic = false;
  public silver = false;
  public golden = false;
  public FlatSharing = 0;
  public FlatLending = 0;
  public mensajes = 0;
  public inmoId: number;
  public loaded = false;
  public grupos: BodyGroup[] = [];
  public tempBool: number;
  public showCustomGroups: boolean;

  constructor(
    public router: Router,
    public authService: AuthService,
    public dialog: MatDialog,
    private endpointService: EndpointService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    let p2 = new Promise<void>((resolve, reject) => {
      setTimeout(async () => {
        this.inmoId = this.authService.inmoId;
        resolve();
      }, 100);
    });

    p2.then(res => {
      //this.loadInmoGrupos();
      this.endpointService.getCommunityInfo(this.authService.inmoId).subscribe(async data => {
        await this.colaborationInfo.handleResponse(data['response']);
        this.colaborarConAgrupaciones = this.colaborationInfo.colaborarConAgrupaciones;
        this.tipoPerfil = this.colaborationInfo.tipoPerfil;
        this.basic = this.colaborationInfo.basic;
        this.silver = this.colaborationInfo.silver;
        this.golden = this.colaborationInfo.golden;
        this.FlatSharing = this.colaborationInfo.flatSharing;
        this.FlatLending = this.colaborationInfo.flatLending;
        this.mensajes = this.colaborationInfo.mensajes;
        this.showCustomGroups = this.colaborationInfo.colaborarConAgrupaciones === 3;
        this.loaded = true;
      });
    });
  }

  confirmOperation(i: number) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: 'CONFIRMA QUE QUIERES REALIZAR ESTA ACCIÓN',
        labelButton1: 'CANCELAR',
        labelButton2: 'CONFIRMAR'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {
        switch (i) {
          case 1: //Tipo de perfil
            this.endpointService.updateProfileType(this.colaborationInfo.tipoPerfil, this.inmoId).subscribe(data => {
              this.tipoPerfil = this.colaborationInfo.tipoPerfil;
            });
            break;
          case 2: //Colaborar con mis Agrupaciones
            this.showCustomGroups = this.colaborationInfo.colaborarConAgrupaciones === 3
            this.endpointService.updateColaborarSegunMisAgrupaciones(this.colaborationInfo.colaborarConAgrupaciones,
              this.inmoId).subscribe(data => {
              this.colaborarConAgrupaciones = this.colaborationInfo.colaborarConAgrupaciones;
            });
            break;
          case 3: //Colaborar según aportacion
            this.endpointService.colaborarConBasic(this.colaborationInfo.basic ? 1 : 0, this.inmoId).subscribe(data => {
              this.basic = this.colaborationInfo.basic;
            });
            break;
          case 4: //Colaborar según aportacion
            this.endpointService.colaborarConSilver(this.colaborationInfo.silver ? 1 : 0, this.inmoId).subscribe(data => {
              this.silver = this.colaborationInfo.silver;
            });
            break;
          case 5: //Colaborar según aportacion
            this.endpointService.colaborarConGolden(this.colaborationInfo.golden ? 1 : 0, this.inmoId).subscribe(data => {
              this.golden = this.colaborationInfo.golden;
            });
            break;
          case 6: //Gestionar mis viviendas compartidas (FlatSharing)
            if (this.colaborationInfo.flatSharing === 3) {
              // opType 1 --> Evento calendario, opType 2 --> Administrador colaboracion
              const dialogRef = this.dialog.open(AddElementEventComponent, {
                data: {data: this.colaborationInfo.listaPersonalizadaFS, type: 4, subtype: 2, account: this.authService.accountsGeneric, 
                  extraData: {flatSharing: true, flatLending: false}},
                // width: '70vw',
                height: '65vh',
                panelClass: 'table-dialog'
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.colaborationInfo.listaPersonalizadaFS = result;
                  this.endpointService.updateFlatSharing(this.colaborationInfo.flatSharing,
                    this.inmoId, this.colaborationInfo.listaPersonalizadaFS, this.colaborationInfo.listaPersonalizadaFL).subscribe(data => {
                    this.FlatSharing = this.colaborationInfo.flatSharing;
                  });
                  //result.forEach(e => { this.colaborationInfo.listaPersonalizada.push(e);});
                  //this.propertiesControl.setValue(this.event.properties);
                  //this._changeDetectorRef.detectChanges();
                }
                else {
                  this.colaborationInfo.flatSharing = this.FlatSharing;
                }
              });
            }
            else {
              this.endpointService.updateFlatSharing(this.colaborationInfo.flatSharing,
                this.inmoId, this.colaborationInfo.listaPersonalizadaFS, this.colaborationInfo.listaPersonalizadaFL,).subscribe(data => {
                this.FlatSharing = this.colaborationInfo.flatSharing;
              });
            }
            break;
          case 7: //Gestionar mis viviendas compartidas (FlatLending)
            if (this.colaborationInfo.flatLending === 3) {
              // opType 1 --> Evento calendario, opType 2 --> Administrador colaboracion
              const dialogRef = this.dialog.open(AddElementEventComponent, {
                data: {data: this.colaborationInfo.listaPersonalizadaFL, type: 4, subtype: 2, account: this.authService.accountsGeneric, 
                  extraData: {flatSharing: false, flatLending: true}},
                // width: '70vw',
                height: '65vh',
                panelClass: 'table-dialog'
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.colaborationInfo.listaPersonalizadaFL = result;
                  this.endpointService.updateFlatLending(this.colaborationInfo.flatLending,
                    this.inmoId, this.colaborationInfo.listaPersonalizadaFS, this.colaborationInfo.listaPersonalizadaFL).subscribe(data => {
                    this.FlatLending = this.colaborationInfo.flatLending;
                  });
                  //result.forEach(e => { this.colaborationInfo.listaPersonalizada.push(e);});
                  //this.propertiesControl.setValue(this.event.properties);
                  //this._changeDetectorRef.detectChanges();
                }
                else {
                  this.colaborationInfo.flatLending = this.FlatLending;
                }
              });
            }
            else {
              this.endpointService.updateFlatLending(this.colaborationInfo.flatLending,
                this.inmoId, this.colaborationInfo.listaPersonalizadaFS, this.colaborationInfo.listaPersonalizadaFL).subscribe(data => {
                this.FlatLending = this.colaborationInfo.flatLending;
              });
            }
            break;
          case 8: //Mensajes
            this.endpointService.updateRecibirMensajes(this.colaborationInfo.mensajes, this.inmoId).subscribe(data => {
              this.mensajes = this.colaborationInfo.mensajes;
            });
            break;
        }
      }
      else {
        switch (i) {
          case 1: //Tipo de perfil
            this.colaborationInfo.tipoPerfil = this.tipoPerfil;
            break;
          case 2: //Colaborar con mis Agrupaciones
            this.colaborationInfo.colaborarConAgrupaciones = this.colaborarConAgrupaciones;
            break;
          case 3: //Colaborar según aportacion (Basic)
            this.colaborationInfo.basic = this.basic
            break;
          case 4: //Colaborar según aportacion (Silver)
            this.colaborationInfo.silver = this.silver;
            break;
          case 5: //Colaborar según aportacion (Golden)
            this.colaborationInfo.golden = this.golden;
            break;
          case 6: //Gestionar mis viviendas compartidas (FlatSharing)
            this.colaborationInfo.flatSharing = this.FlatSharing;
            break;
          case 7: //Gestionar mis viviendas compartidas (FlatLending)
            this.colaborationInfo.flatLending = this.FlatLending;
            break;
          case 8: //Mensajes
            this.colaborationInfo.mensajes = this.mensajes;
            break;
        }
      }
    });
  }

  viewPropertyListFlatSharing() {
    const dialogRef = this.dialog.open(AddElementEventComponent, {
      data: {data: this.colaborationInfo.listaPersonalizadaFS, type: 4, subtype: 2, account: this.authService.accountsGeneric, 
        extraData: {flatSharing: true, flatLending: false, quickEdit: true}},
      // width: '70vw',
      height: '65vh',
      panelClass: 'table-dialog'
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.colaborationInfo.listaPersonalizadaFS = result;
        this.endpointService.updateFlatSharing(this.colaborationInfo.flatSharing,
          this.inmoId, this.colaborationInfo.listaPersonalizadaFS, this.colaborationInfo.listaPersonalizadaFL).subscribe(data => {
          this.FlatSharing = this.colaborationInfo.flatSharing;
        });
      }
    });
  }

  viewPropertyListFlatLending() {
    const dialogRef = this.dialog.open(AddElementEventComponent, {
      data: {data: this.colaborationInfo.listaPersonalizadaFL, type: 4, subtype: 2, account: this.authService.accountsGeneric, 
        extraData: {flatSharing: false, flatLending: true, quickEdit: true}},
      // width: '70vw',
      height: '65vh',
      panelClass: 'table-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.colaborationInfo.listaPersonalizadaFL = result;
        this.endpointService.updateFlatLending(this.colaborationInfo.flatLending,
          this.inmoId, this.colaborationInfo.listaPersonalizadaFS, this.colaborationInfo.listaPersonalizadaFL).subscribe(data => {
          this.FlatLending = this.colaborationInfo.flatLending;
        });
        //result.forEach(e => { this.colaborationInfo.listaPersonalizada.push(e);});
        //this.propertiesControl.setValue(this.event.properties);
        //this._changeDetectorRef.detectChanges();
      }
    });
  }

}
