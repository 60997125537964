<div class="modal" id="{{id}}" role="dialog" aria-labelledby="deleteClientLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="deletePropertyLabel">{{title}}</h2>
      </div>
      <div *ngIf="description.length>0" class="modal-body">
        {{description}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="onClickAccept()" >{{acceptButtonText}}</button>
        <button type="button" class="btn btn-secondary" (click)="onClickDecline()">{{declineButtonText}}</button>
      </div>
    </div>
  </div>
</div>