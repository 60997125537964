import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {EndpointService} from "../../../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../../../services/auth/auth.service";
import {UntypedFormControl, Validators} from "@angular/forms";
import {Strings} from "../../../../../../strings/strings";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SimpleGroup} from "../../../../../activities/objects/simple-group";
import {FilterUsersPipe} from "../../../../filter-users.pipe";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import * as _ from 'lodash';
import { MessageDialogComponent } from "app/utils/message-dialog/message-dialog.component";

@Component({
  selector: 'create-group-dialog',
  templateUrl: 'create-group-dialog.html',
  styleUrls: ['create-group-dialog.css'],
})
export class  CreateGroupDialog implements OnInit, OnDestroy {

  public strings = Strings.CALENDAR;
  public grupos: [] = [];                   // Lista de todos los grupos - En modo edición no contiene el grupo a editar. Se filtra en el constructor y se carga el nombre de los grupos
  public grupo: SimpleGroup;                // Lista del grupo que estamos editando
  public totalUsers = [];                   // Lista de todos los usuarios
  public creatingEvent: boolean;
  public isOnlyNewUsers: boolean = false;   // Indica si el pop-up se abre para añadir nuevos usuarios exclusivamente o no

  nameCategoryControl = new UntypedFormControl('', [Validators.required]);
  allUsers: { id: number, value: boolean, name: string, userPic: string, accountName: string, empresaId: number}[] = [];
  users: { id: number, value: boolean, name: string, userPic: string }[];
  private check: boolean;
  myGroupEventsFilter: { userId: number, id: number | any, value: boolean, user: string, type: string | any, name: string, type_account: number }[];

  public displayedColumns: string[] = ['icon', 'photo', 'name', 'account_name'];
  public dataSource: MatTableDataSource<any>;
  public idUsersToDelete = [];
  public idUsersToAdd = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public checkAll: boolean;

  constructor(public dialogRef: MatDialogRef<CreateGroupDialog>,
              private endpointService: EndpointService,
              public snackBar: MatSnackBar,
              public authService: AuthService,
              private userPipe: FilterUsersPipe,
              private changeDetectorRefs: ChangeDetectorRef,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    // Defino el tipo de operación
    this.creatingEvent = (this.data.opType === 1);

    // Cargo los usuarios
    this.users = data.users; // Joan: No ho entenc this.users
    this.totalUsers = data.totalUsers;

    // Cargo la información
    this.isOnlyNewUsers = data.isOnlyNewUsers;
    if (data.group && !this.creatingEvent) {
      this.grupo = data.group;
      this.nameCategoryControl.setValue(this.grupo.name);
      this.grupos = data.grupos.filter(e => e.id !== this.grupo.id).map(a => a.name);
    }
  }

  ngOnInit() {
    /* Joan: De moment ho comento aquesta gestió de comptes
    console.log("authService.accounts:", this.authService.accounts);
    this.myGroupEventsFilter = [];
    this.authService.accounts.forEach(acc => {
      if (localStorage.getItem('event_share_account_' + acc.id)) {
        this.myGroupEventsFilter.push({ userId: this.authService.userId, id: acc.id, value: false,
          user: this.authService.userFullName, type: acc.type, name: acc.name, type_account: acc.type_account });
      }
      else {
        this.myGroupEventsFilter.push({ userId: this.authService.userId, id: acc.id, value: true,
          user: this.authService.userFullName, type: acc.type, name: acc.name, type_account: acc.type_account });
      }
    });
    */

    // Se trata de un grupo ya creado: cargamos sus usuarios y su información
    if (!this.creatingEvent) {
      // Ya tenemos los usuarios cargados, no los cargamos de nuevo si no que los cogemos del grupo que estamos cargando
      this.grupo.users.forEach(u => {
        this.allUsers.push({id: u.id,
                            value: this.check,
                            name: u.name,
                            userPic: (u.userPic ? u.userPic : "https://movin.cloud/assets/img/default/perfil.png"),
                            accountName: u.cuenta_nombre,
                            empresaId: u.empresaId});
      });
    } 
    else {
      // Se trata de un grupo nuevo, por tanto mostramos todos los usuarios disponibles para hacer un grupo
      this.totalUsers.forEach(u => {
        this.allUsers.push({id: u.id,
                            value: false,
                            name: u.name,
                            userPic: (u.userPic ? u.userPic : "https://movin.cloud/assets/img/default/perfil.png"),
                            accountName: u.accountName,
                            empresaId: u.accountId});
      });
    }

    console.log("allUsers:", this.allUsers);
    console.log("myGroupEventsFilter:", this.myGroupEventsFilter);

    // Joan: Com que myGroupEventsFilter està buit (perquè ho he comentat), no fa res
    //this.dataSource = new MatTableDataSource(this.userPipe.transform(this.allUsers, this.myGroupEventsFilter, 1));
    this.dataSource = new MatTableDataSource(this.allUsers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (!this.creatingEvent) {
      this.setCheckBoxAll();
    }
  }

  ngOnDestroy() {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  // Guarda els canvis fets a un grup existent
  public editGroup(): void {
    //let usuarios = this.dataSource.data.filter(e => e.value === true);
    let body = {
      nombre: this.nameCategoryControl.value,
      id_cuenta: this.authService.returnActiveAccountId(), 
      id_creador: this.authService.userId,
      usersToDelete: this.idUsersToDelete,
      usersToAdd: this.idUsersToAdd
    };

    let groupExists = this.grupos.some(gr => gr === this.nameCategoryControl.value);
    if (!groupExists) {
      this.endpointService.updateUserGroup(this.grupo.id, body).subscribe(e => {
        if (e['errorCode'] === 0) {
          this.snackBar.open('Grupo actualizado correctamente', 'X', {
            duration: 6000,
            panelClass: ['lightgreen-snackbar']
          });
          this.dialogRef.close(body);
        }
        else {
          this.snackBar.open('No se ha podido actualizar el grupo', 'X', {
            duration: 6000,
            panelClass: ['red-snackbar']
          });
        }
      })
    }
    else {
      this.snackBar.open('Ya existe un grupo con este nombre', 'X', {
        duration: 6000,
        panelClass: ['red-snackbar']
      });
    }
  }

  // Crea un grup nou
  public createGroup(): void {
    // Insertamos el grupo creado en la BD solo si no estamos añadiendo usuarios nuevos
    let body = {
      nombre: this.nameCategoryControl.value,
      id_empresa: this.authService.getIdEmpresa(),
      usuarios: this.allUsers.filter(e => e.value === true),
      id_creador: this.authService.userId
    };
    if (!this.isOnlyNewUsers){
      this.endpointService.insertGroup(body).subscribe(e => {
        if (e['errorCode'] === 0) {
          body['id'] = e['response'];
          this.snackBar.open('Grupo creado correctamente', 'X', {
            duration: 6000,
            panelClass: ['lightgreen-snackbar']
          });
          this.dialogRef.close(body);
        }
        else {
          this.snackBar.open('No se ha podido crear el grupo', 'X', {
            duration: 6000,
            panelClass: ['red-snackbar']
          });
        }
      })
    }
    else {
      this.dialogRef.close(body);
      this.snackBar.open('Usuarios añadidos correctamente', 'X', {
        duration: 6000,
        panelClass: ['lightgreen-snackbar']
      });
    }
  }

  // Esborra un usuari d'un grup
  public deleteUserFromGroup(user): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: '¿Estás seguro de que quieres eliminar este usuario del grupo?',
        labelButton1: 'No',
        labelButton2: 'Sí'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data == 2) {
        console.log("dialogRef.afterClosed: data", data);
        // Confirma que lo quiere borrar
        // Compruebo que no era un usuario que iba a añadir
        const isNewUser = (this.idUsersToAdd.find(u => u.id == user.id) !== undefined);
        if (!isNewUser) {
          // Era un usuario que ya estaba en la BD, lo añado a los id de los usuarios a borrar
          this.idUsersToDelete.push(user.id);
        }
        else {
          // Era un usario que iba a añadir pero que todavia no estaba en la BD
          this.idUsersToAdd = this.idUsersToAdd.filter(u=> u.id != user.id);
        }
        // No lo muestro en la lista de usuarios activos pero no lo borro hasta q confirme los cambios
        this.allUsers = this.allUsers.filter(u=> u.id != user.id);

        // Joan: Com que myGroupEventsFilter està buit (perquè ho he comentat), no fa res
        //this.dataSource = new MatTableDataSource(this.userPipe.transform(this.allUsers, this.myGroupEventsFilter, 1));
        this.dataSource = new MatTableDataSource(this.allUsers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;    
      }
    });
  }

  // Afegeix un usuari a un grup
  public addUserToGroup(): void {
    // Esta función permite añadir usuarios a un grupo ya creado, se llama a si misma
    // He de filtrar los usuarios que podrá añadir -> estos serán los que tiene para ver en la agenda compartida
    // menos los que ya tiene añadidos en el grupo
    /*
    let filterUsers = _.cloneDeep(this.totalUsers);
    this.allUsers.forEach(user => {
      filterUsers = filterUsers.filter(u => u.id !== user.id);
    });
    */
    let filterUsers: any[] = [];
    this.totalUsers.forEach(user => {
      if (this.allUsers.findIndex(u => u.id === user.id) == -1) {
        filterUsers.push(user);
      }
    });

    const dialogRef = this.dialog.open(CreateGroupDialog, {
      width: 'auto',
      height: 'auto',
      minWidth: '40vw',
      data: {users: this.users, opType: 1, totalUsers: filterUsers, isOnlyNewUsers: true},
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("dialogRef.afterClosed: result", result);
        result.usuarios.forEach(user => {
          this.idUsersToAdd.push({id: user.id, empresaId: user.empresaId});
          this.allUsers.push(user);

          // Joan: Com que myGroupEventsFilter està buit (perquè ho he comentat), no fa res
          //this.dataSource = new MatTableDataSource(this.userPipe.transform(this.allUsers, this.myGroupEventsFilter, 1));
          this.dataSource = new MatTableDataSource(this.allUsers);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      }
    });
  }

  getNameErrorMessage() {
    if (this.nameCategoryControl.hasError('required')) {
      return this.strings.COLOR_PICKER_NAME_ERROR_MESSAGE;
    }
    return '';
  }

  public refreshDataSource(): void {
    this.changeDetectorRefs.detectChanges();
  }

  // Només es crida en mode crear grup
  public checkUncheck(userId: number, newValue: boolean): void {
    //element.value = $event.checked; refreshDataSource(); setCheckBoxAll();

    this.allUsers.filter(user => user.id == userId).forEach(u => {
      u.value = newValue;
    });

    //this.refreshDataSource();
    
    this.setCheckBoxAll();
  }

  // Només es crida en mode crear grup
  public checkUncheckAll(newValue: boolean): void {
    this.checkAll = newValue;
    for (let i = 0; i < this.allUsers.length; i++) {
      this.allUsers[i].value = newValue;
    }
  }

  // Només aplica en mode crear grup
  public setCheckBoxAll(): void {
    /* Joan
    console.log(this.allUsers, "groupFilter")
    const count = this.allUsers.filter(element => element.value === true).length;
    console.log(count, "How many TRUE")
    console.log(this.allUsers.length, "Length")

    if(count == this.allUsers.length) this.checkAll = true;
    else this.checkAll = false
    */
    this.checkAll = (this.allUsers.filter(u => u.value === true).length == this.allUsers.length);
  }
}
