<!--<button (click)="this.loadedInfo = !this.loadedInfo;">TEST</button>-->

<div *ngIf="loadedInfo === true || comingFromInmos === true">
  <span *ngIf="myProfile === false" class="material-icons backing" (click)="goBack()">arrow_back</span>

  <div fxLayout="row wrap" fxFlex="100" class="inmo-profile">
    <div [ngStyle]="{'background-image': 'url(https://4dmovin.com/inmobiliarias/3/info/BannerPortal.jpg)'}" class="inmo-details" fxLayout="row wrap" fxFlex="100">
      <div class="general-content-filter" fxLayout="row wrap" fxFlex="100">
        <div fxFlex="100" fxLayout="row wrap">
          <div fxFlex="3" fxFlex.sm="0" fxFlex.xs="0"></div>
          <div fxFlex="7" fxFlex.sm="15" fxFlex.xs="20" fxLayoutAlign="center start">
            <div class="logo-inmo clickable">
              <img alt="logo-inmo-Home" [src]="infoInmo.logo_cuadrado">
              <span fxShow="true" fxShow.md="false" fxShow.sm="false" fxShow.xs="false" *ngIf="isBasic === true || isSilver === true || isGolden === true" class="material-icons positioning-icon-3">verified</span>
              <span fxShow="true" fxShow.md="false" fxShow.sm="false" fxShow.xs="false" *ngIf="isBasic === true || isSilver === true || isGolden === true" class="material-icons positioning-icon-2">verified</span>
              <span fxShow="true" fxShow.md="false" fxShow.sm="false" fxShow.xs="false" *ngIf="isBasic === true" matTooltip="Beginner" class="material-icons positioning-icon" style="color: #ff4646 !important">verified</span>
              <span fxShow="true" fxShow.md="false" fxShow.sm="false" fxShow.xs="false" *ngIf="isSilver === true" matTooltip="Standard" class="material-icons positioning-icon" style="color: #FDDD00 !important">verified</span>
              <span fxShow="true" fxShow.md="false" fxShow.sm="false" fxShow.xs="false" *ngIf="isGolden === true" matTooltip="Expert" class="material-icons positioning-icon" style="color: #71b5ff !important">verified</span>
            </div>
          </div>
          <div fxFlex="3" fxFlex.sm="3" fxFlex.xs="2"></div>
          <div fxFlex="70" fxFlex.sm="82" fxFlex.xs="78" fxLayout="row wrap" class="inmo-info">
            <div fxFlex="100" fxLayout="row wrap" class="title">
              <mat-card-title>
                <span fxLayout="row wrap" fxFlex="100">
                  <span fxFlex="100" class="clickable">{{infoInmo.nombre}}
                    <span fxShow="false" fxShow.md="true" fxShow.sm="true" fxShow.xs="true" *ngIf="isBasic === true" matTooltip="Beginner" class="material-icons" style="color: #ff4646 !important">verified</span>
                    <span fxShow="false" fxShow.md="true" fxShow.sm="true" fxShow.xs="true" *ngIf="isSilver === true" matTooltip="Standard" class="material-icons" style="color: #FDDD00 !important">verified</span>
                    <span fxShow="false" fxShow.md="true" fxShow.sm="true" fxShow.xs="true" *ngIf="isGolden === true" matTooltip="Expert" class="material-icons" style="color: #71b5ff !important">verified</span></span>
                  <span fxFlex="100" class="category" *ngIf="isInmo === false" style="margin-top: 5px;">{{infoInmo.tipo_grupo}}</span>
                  <span fxFlex="100" class="category" *ngIf="isInmo === true" style="margin-top: 5px;">Inmobiliaria</span>
                  <p *ngIf="isInmo === true" matTooltip="{{this.tooltipInfo}}">
                    <i fxFlex="100" *ngFor="let number of [1,2,3,4,5]">
                      <i *ngIf="number <= infoInmo.media" class="material-icons-outlined">star</i>
                      <i *ngIf="number < infoInmo.media && number+1 > infoInmo.media" class="material-icons-outlined" style="position: absolute;">star_half</i>
                      <i *ngIf="number-1 >= infoInmo.media" class="material-icons-outlined">star_border</i>
                    </i>
                  </p>
                </span>
              </mat-card-title>
            </div>
            <div fxFlex="100" fxLayout="row wrap" class="mat-card-subtitle">
              <mat-card-subtitle fxFlex="100" fxLayout="row wrap">
                <div class="slogan" fxFlex="100">{{infoInmo.slogan}}</div>
                <div class="description" fxFlex="100" fxShow="true" fxShow.xs="false">{{infoInmo.descripcion}}</div>
              </mat-card-subtitle>
            </div>
          </div>
          <div fxFlex="3" fxFlex.sm="0" fxFlex.xs="0"></div>
          <div fxFlex="14" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="center start" fxLayoutAlign.sm="space-evenly center" fxLayoutAlign.xs="space-evenly center" fxLayout="row wrap">
            <button fxFlex="100" *ngIf="showEtiqueta1" class="button-registry" (click)="ejecutarAccion(accion1)">{{etiqueta1}}</button>

            <button *ngIf="infoInmo.followId === null && !myProfile && showFollowButton" fxFlex="100" class="button-registry" (click)="follow()">Seguir</button>
            <button *ngIf="infoInmo.followId !== null && !myProfile && showFollowButton" fxFlex="100" class="button-registry" (click)="unfollow()">Siguiendo</button>

            <button fxFlex="100" *ngIf="showEtiqueta2 && accion2 === 13" class="button-registry" (click)="ejecutarAccion(accion2)">{{etiqueta2}}</button>

            <button [ngClass]="{'button-registry': true, 'button-registry-bloqued': enviarMensajeBloqueado}"
                    fxFlex="100" *ngIf="showEtiqueta2 && accion2 !== 13 && showMessageButton" (click)="enviarMensaje(accion2)"><span *ngIf="enviarMensajeBloqueado" class="material-icons">lock</span> Mensaje
            </button>
            <!-- OLD
            <button [disabled]="enviarMensajeBloqueado" [ngClass]="{'button-registry': true, 'button-registry-bloqued': enviarMensajeBloqueado}"
                    fxFlex="100" *ngIf="showEtiqueta2 && accion2 !== 13" (click)="enviarMensaje(accion2)"><span *ngIf="enviarMensajeBloqueado" class="material-icons">lock</span> Mensaje
            </button>
            -->
          </div>
        </div>
        <div *ngIf="isInmo === true &&  mlsInmo.length > 0" fxFlex="100" fxLayout="row wrap" style="margin-top: 20px;">
          <div fxFlex="3" fxFlex.sm="0" fxFlex.xs="0"></div>
          <div fxLayout="row wrap" fxFlex="94" fxFlex.sm="100" fxFlex.xs="100" class="lista-inmos">
              <p-carousel id="inmo-groups" [value]="mlsInmo" [numVisible]="15" [numScroll]="5" [circular]="false" [page]="0" [responsiveOptions]="this.responsiveOptionsCarousel">
                <ng-template let-inmo pTemplate="item">
                  <div fxLayout="row wrap">
                    <div fxFlex="100">
                      <img *ngIf="inmo.colaboraGrup == 0" class="logo-carousel" [src]="inmo.logo_cuadrado" matTooltip="{{inmo.orden}}. {{inmo.nombre}}">
                      <img *ngIf="inmo.colaboraGrup == 1" class="logo-carousel-yellow" [src]="inmo.logo_cuadrado" matTooltip="{{inmo.orden}}. {{inmo.nombre}}">
                    </div>
                    <span *ngIf="inmo.colaboraGrup == 0" fxFlex="100" class="titulo-position" style="font-size: 12px; font-weight: bolder;text-transform: uppercase;color: #42678f; margin-top: 5px;">{{inmo.orden}}. {{this.inmo.nombre}}</span>
                    <span *ngIf="inmo.colaboraGrup == 1" fxFlex="100" class="titulo-position" style="font-size: 12px; font-weight: bolder;text-transform: uppercase;color: #fddd00; margin-top: 5px;">{{inmo.orden}}. {{this.inmo.nombre}}</span>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          <div fxFlex="3" fxFlex.sm="0" fxFlex.xs="0"></div>
        </div>
      </div>
    </div>

    <div class="inmo-content" fxLayout="row wrap" fxFlex="100">
      <div style="width: 100%">
        <mat-tab-group mat-stretch-tabs>
          <mat-tab *ngIf="isInmo === false && showInmos" label="Inmobiliarias">
            <ng-template mat-tab-label>
              <i fxShow="false" fxShow.sm="true" fxShow.xs="true" class="material-icons rounded" matTooltip="Inmobiliarias">business</i>
              <span class="options-view" matTooltip="Inmobiliarias" fxShow="true" fxShow.sm="false" fxShow.xs="false">Inmobiliarias</span>
            </ng-template>
            <ng-template matTabContent>
            <span class="lista-inmos-0" fxLayout="row wrap" *ngIf="loadedInfo">
              <span *ngIf="inmosMLS.length > 0" fxFlex="100" fxLayout="row wrap" style="margin: 1vw;">
                <div *ngFor="let inmo of inmosMLS" fxFlex="25" fxFlex.md="33.33" fxFlex.sm="50" fxFlex.xs="100">
                  <app-inmo-card [inmo]="inmo" [tipo]="inmo.tipo" [esMLS]="inmo.esMLS" [type]="type" [permisos]="permisos"></app-inmo-card>
                </div>
              </span>
              <div *ngIf="inmosMLS.length == 0" class="message-no-results" fxFlex="100">
                  <span class="material-icons outlined" style="font-size: 18px; vertical-align: text-bottom; padding-right: 4px;">sentiment_very_dissatisfied</span>Vaya, parece que no hay inmobiliarias en este grupo.
              </div>
            </span>
            </ng-template>
          </mat-tab>
          <mat-tab *ngIf="showViviendas" label="Inmuebles">
            <ng-template mat-tab-label>
              <i fxShow="false" fxShow.sm="true" fxShow.xs="true" class="material-icons rounded" matTooltip="Inmuebles">home_work</i>
              <span class="options-view" matTooltip="Inmuebles" fxShow="true" fxShow.sm="false" fxShow.xs="false">Inmuebles</span>
            </ng-template>
            <ng-template matTabContent>
              <div class="spacing inmos">
                <div style="background: #fff;" *ngIf="searchResult.length > 0" class="virtual-scroll content-filter" [ngClass]="{'three-elements': true, 'cont-1': true}" [ngClass.sm]="{'two-elements': true, 'cont-2': true}" [ngClass.xs]="{'one-elements': true, 'cont-3': true}" fxFlex="100">
                  <ng-container *ngFor="let i of searchResultRowIndex">
                    <ng-container *ngFor="let j of [0,1,2]">
                      <div class="each-card" [attr.data-row]="i" [attr.data-cell]="i + j">
                        <div class="preload" *ngIf="searchResult[i + j]"></div>
                        <mat-card class="each-card-1 ssrload" *ngIf="searchResult[i + j] && searchResult[i + j].id == null">
                          <div class="image-placeholder"></div>
                        </mat-card>
                        <mat-card [ngClass]="{'each-card-1': true, 'ssrload': true}" *ngIf="searchResult[i + j] && searchResult[i + j].id != null"
                                  [attr.data-property-id]="searchResult[i + j].id">
                          <div class="viewer">
                            <div fxLayout="row wrap" class="details-top">
                              <h2><span class="tittle-flat">{{searchResult[i + j].tipo_operacion}} </span><span class="tittle-flat">{{searchResult[i + j].subtipo_propiedad}} </span><span class="tittle-flat">{{searchResult[i + j].poblacion}} </span></h2>
                              <div class="delete-card" ngClass.sm="no-hover" ngClass.xs="no-hover" (click)="goToPortal(searchResult[i + j])"><i class="material-icons-outlined">visibility</i></div>
                            </div>
                            <div fxLayout="row wrap" class="details-top-iconos">
                              <!--<div *ngIf="searchResult[i + j].te_video" class="detail-icon" fxLayout="row" matTooltip="{{ 'view-flats.tiene-video' | translate}}"><i class="material-icons-outlined detail-icono">videocam</i></div>-->
                            </div>
                            <div fxLayout="row wrap" class="details-bottom">
                              <mat-card-content class="card-content">
                                <div fxLayout="row" class="meters" matTooltip="Precio"><p>{{tratarPrecio(searchResult[i + j].precio)}}</p></div>
                                <div fxLayout="row" class="meters" matTooltip="Superfície"><p>{{searchResult[i + j].superficie}} m2</p></div>
                                <div fxLayout="row" matTooltip="Habitaciones"><p fxFlex="50">{{searchResult[i + j].num_habitaciones}}</p><i fxFlex="50" class="material-icons-outlined">hotel</i></div>
                                <div fxLayout="row" matTooltip="Baños"><p fxFlex="50">{{searchResult[i + j].num_banos}} </p><i fxFlex="50" class="material-icons-outlined">bathtub</i></div>
                                <div *ngIf="searchResult[i + j].parking" fxLayout="row" matTooltip="Parking"><i class="material-icons-outlined">directions_car</i></div>
                                <div *ngIf="searchResult[i + j].tiene_piscina" fxLayout="row" matTooltip="Piscina"><i class="material-icons-outlined">pool</i></div>
                                <div *ngIf="searchResult[i + j].id_tipo_calefaccion !== null && searchResult[i + j].id_tipo_calefaccion !== 1" fxLayout="row" matTooltip="Calefacción"><i class="material-icons-outlined">fireplace</i></div>
                                <div *ngIf="searchResult[i + j].id_tipo_aire_acondicionado !== null && searchResult[i + j].id_tipo_aire_acondicionado !== 1" fxLayout="row" matTooltip="Aire acondicionado"><i class="material-icons-outlined">toys</i></div>
                              </mat-card-content>
                              <mat-card-actions class="card-actions" fxLayoutAlign="space-around center">
                                <div></div>
                                <i *ngIf="type === 1 && searchResult[i + j].tipo_colaboracion === 2" class="material-icons rounded" matTooltip="Flat Lending">swap_calls</i>
                                <i *ngIf="type === 1 && searchResult[i + j].tipo_colaboracion === 1" class="material-icons rounded" matTooltip="Flat Sharing">policy</i>
                                <i *ngIf="type === 1 && searchResult[i + j].tipo_colaboracion === 0"  class="material-icons rounded" matTooltip="No disponible para colaboración">lock</i>
                              </mat-card-actions>
                            </div>
                            <div *ngIf="!showCarousel[i + j].show" mat-card-image class="imagen-nocarousel1">
                              <div style="cursor: pointer;" class="imagen-nocarousel2" [ngStyle]="{'background-image': 'url(' + searchResult[i + j].thumbnails[0] + ')'}"></div>
                              <button ngClass.sm="no-hover" ngClass.xs="no-hover" mat-button (click)="photoClicked(i + j)"><i class="material-icons">arrow_forward_ios</i></button>
                            </div>
                            <div *ngIf="showCarousel[i + j].show" mat-card-image class="imagenes-carousel-flat-card">
                              <p-carousel [value]="searchResult[i + j].thumbnails" [numVisible]="1" [numScroll]="1" [circular]="true" [page]="showCarousel[i + j].page">
                                <ng-template let-thumbnail pTemplate="item">
                                  <img [src]="thumbnail">
                                  <div style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center">
                                    <button style="cursor: pointer; opacity: 1 !important; width: 100% !important; height: 100% !important; background: inherit !important; box-shadow: inherit !important; border: 0 !important;"></button>
                                  </div>
                                </ng-template>
                              </p-carousel>
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <div *ngIf="searchResult.length === 0" class="message-no-results" fxFlex="100">
                  <span class="material-icons outlined" style="font-size: 18px; vertical-align: text-bottom; padding-right: 4px;">sentiment_very_dissatisfied</span>Vaya, parece que no hay inmuebles a mostrar.
                </div>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab *ngIf="isInmo === true" label="Opiniones">
            <ng-template mat-tab-label>
              <i fxShow="false" fxShow.sm="true" fxShow.xs="true" class="material-icons rounded" matTooltip="Opiniones">forum</i>
              <span class="options-view" matTooltip="Opiniones" fxShow="true" fxShow.sm="false" fxShow.xs="false">Opiniones</span>
            </ng-template>
            <ng-template matTabContent>
              <div class="opiniones">
                <div fxLayout="row wrap" class="rating-opinions">
                  <div fxLayout="row wrap" class="rating-opinion-1" fxFlex="33.33" fxLayoutAlign="center center">
                    <div class="rating">
                      <div class="spinner-group spinner-0">
                        <mat-progress-spinner mode="determinate" value="100" class="background"></mat-progress-spinner>
                        <mat-progress-spinner mode="determinate" value="{{genericService.round(infoInmo.puntuacion*20, 1)}}"></mat-progress-spinner>
                      </div>
                      <div class="number-rating">
                        {{genericService.round(infoInmo.puntuacion*20, 1)}}%
                      </div>
                    </div>
                    <h2 fxFlex="100">General</h2>
                    <h5 fxFlex="100" *ngIf="infoInmo.num_opiniones > 0">( {{infoInmo.num_opiniones}} opiniones )</h5>
                    <h5 fxFlex="100" *ngIf="infoInmo.num_opiniones === 0">( 0 opiniones )</h5>
                  </div>
                  <div fxLayout="row wrap" class="rating-opinion-1" fxFlex="33.33" fxLayoutAlign="center center">
                    <div class="rating">
                      <div class="spinner-group spinner-1">
                        <mat-progress-spinner mode="determinate" value="100" class="background"></mat-progress-spinner>
                        <mat-progress-spinner mode="determinate" value="{{genericService.round(opinionCriterio1[1]*20, 1)}}"></mat-progress-spinner>
                      </div>
                      <div class="number-rating">
                        {{genericService.round(opinionCriterio1[1]*20, 1)}}%
                      </div>
                    </div>
                    <h2 fxFlex="100">{{opinionCriterio1[0]}}</h2>
                    <h5 fxFlex="100" *ngIf="opinionCriterio1[2] > 0">( {{opinionCriterio1[2]}} opiniones )</h5>
                    <h5 fxFlex="100" *ngIf="opinionCriterio1[2] === 0">( 0 opiniones )</h5>
                  </div>
                  <div fxLayout="row wrap" class="rating-opinion-1" fxFlex="33.33" fxLayoutAlign="center center">
                    <div class="rating">
                      <div class="spinner-group spinner-2">
                        <mat-progress-spinner mode="determinate" value="100" class="background"></mat-progress-spinner>
                        <mat-progress-spinner mode="determinate" value="{{genericService.round(opinionCriterio2[1]*20, 1)}}"></mat-progress-spinner>
                      </div>
                      <div class="number-rating">
                        {{genericService.round(opinionCriterio2[1]*20, 1)}}%
                      </div>
                    </div>
                    <h2 fxFlex="100">{{opinionCriterio2[0]}}</h2>
                    <h5 fxFlex="100" *ngIf="opinionCriterio2[2] > 0">( {{opinionCriterio2[2]}} opiniones )</h5>
                    <h5 fxFlex="100" *ngIf="opinionCriterio2[2] === 0">( 0 opiniones )</h5>
                  </div>
                </div>
                <div class="opinions">
                  <h1 *ngIf="this.opiniones.length === 0" fxFlex="100" class="yellow no-resultados" style="text-align: center;"><span class="material-icons rounded" style="font-size: 18px; vertical-align: text-bottom; padding-right: 4px;">sentiment_very_dissatisfied</span>Todavía no hay opiniones de esta inmobiliaria
                  </h1>
                  <div *ngIf="this.opiniones.length > 0" fxLayout="row wrap" fxFlex="100">
                    <p-carousel class="all-inmos" [value]="this.opiniones" [numVisible]="4" [circular]="true" [page]="0" [responsiveOptions]="responsiveOptions">
                      <ng-template let-opinion pTemplate="item">
                        <div class="opinion">
                          <mat-card>
                            <mat-card-header>
                              <div mat-card-avatar class="avatar-image">
                                <img *ngIf="opinion.foto_perfil" routerLink="/" alt="logo-inmo Home" src="{{opinion.foto}}">
                                <span *ngIf="!opinion.foto_perfil" class="material-icons">account_circle</span>
                              </div>
                              <mat-card-title>{{opinion.nombre}} {{opinion.apellidos}}</mat-card-title>
                              <mat-card-subtitle fxLayout="row wrap">
                                <p class="red" fxFlex="100">
                                  <i *ngFor="let number of [1,2,3,4,5]">
                                    <i *ngIf="number <= opinion.puntuacion" class="material-icons-outlined red">star</i>
                                    <i *ngIf="number > opinion.puntuacion" class="material-icons-outlined red">star_border</i>
                                  </i>
                                </p>
                                <p fxFlex="100">{{opinion.opinion}}</p>
                                <div fxFlex="100" *ngIf="opinion.tipo === 2" class="more-opinions" fxFlex="100" fxLayout="row wrap">
                                  <div *ngFor="let criteria of opinion.other_criterias">
                                    <div fxFlex="100" fxLayoutAlign="start center">
                                      <p>{{criteria.valor}}: </p>
                                      <p class="red">
                                        <i *ngFor="let number of [1,2,3,4,5]">
                                          <i *ngIf="number <= criteria.puntuacion" class="material-icons-outlined red">star</i>
                                          <i *ngIf="number > criteria.puntuacion" class="material-icons-outlined red">star_border</i>
                                        </i>
                                      </p>
                                    </div>
                                    <br>
                                  </div>
                                </div>
                              </mat-card-subtitle>
                            </mat-card-header>
                          </mat-card>
                        </div>
                      </ng-template>
                    </p-carousel>
                    <!-- <div *ngFor="let opinion of this.opiniones" fxFlex="33.33" fxFlex.sm="50" fxFlex.xs="100"> FER CAROUSEL COM A VIVIENDAS SIMILARES
                    </div> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Contacto">
            <ng-template mat-tab-label>
              <i fxShow="false" fxShow.sm="true" fxShow.xs="true" class="material-icons rounded" matTooltip="Contacto">mail</i>
              <span class="options-view" matTooltip="Contacto" fxShow="true" fxShow.sm="false" fxShow.xs="false">Contacto</span>
            </ng-template>
            <ng-template matTabContent>
              <div fxLayout="row wrap" class="contacto black-field-type">
                <form [formGroup]="mensajeForm" (ngSubmit)="enviarMensajeFormulario()" ngStyle.sm="padding-left: 3%; padding-left: 3%;" ngStyle.xs="padding-left: 3%; padding-right: 3%;" fxFlex="50" fxFlex.sm="55" fxFlex.xs="100" fxLayout="row wrap">
                  <h2 class="yellow" fxFlex="100">Dinos qué necesitas</h2>

                  <mat-form-field fxFlex="100" class="field" appearance="outline">
                    <mat-label>Mensaje</mat-label>
                    <input matInput type="text" formControlName="mensaje" required>
                    <mat-error class="error-message" *ngIf="enviarFormClicked && mensajeForm.get('mensaje').value === ''">Este campo no puede estar vacío</mat-error>
                  </mat-form-field>

                  <div class="form-group" fxFlex="100">
                    <button class="button-general" fxFlex="100" mat-button>Enviar</button>
                  </div>
                </form>
                <div ngStyle.sm="padding-right: 3%; padding-left: 5%; padding-top: 10%;"
                     ngStyle.xs="padding-left: 3%; padding-right: 3%; padding-top: 10%;"
                     class="dades" fxFlex="50" fxFlex.sm="45" fxFlex.xs="100" fxLayout="row wrap"
                     *ngIf="infoInmo.telefono || infoInmo.mail || infoInmo.web || infoInmo.facebook || infoInmo.twitter || infoInmo.instagram || infoInmo.linkedin || infoInmo.youtube">
                  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
                    <h2 class="yellow" fxFlex="100">¿Quieres saber más sobre nosotros?</h2>
                    <div class="inmo-info-contact" fxLayout="row wrap" fxLayoutAlign="center start" fxFlex="100">
                      <div fxFlex="100">
                        <p *ngIf="infoInmo.telefono" ngStyle.sm="margin-top: 20px;" ngStyle.xs="margin-top: 20px;" style="font-size: 11px;"><a href="tel:{{(infoInmo.telefono).trim()}}">{{infoInmo.telefono}}</a></p>
                      </div>
                      <div fxFlex="100">
                        <p *ngIf="infoInmo.mail" ngStyle.sm="margin-top: 20px;" ngStyle.xs="margin-top: 20px;"><a href="mailto:{{infoInmo.mail}}" target="_blank">{{infoInmo.mail}}</a></p>
                      </div>
                      <div fxFlex="100">
                        <p *ngIf="infoInmo.web" ngStyle.sm="margin-top: 20px;" ngStyle.xs="margin-top: 20px;"><a href="{{genericService.prepareHrefPage(infoInmo.web)}}" target="_blank">{{genericService.prepareWebLinkToShow(infoInmo.web)}}</a></p>
                      </div>
                      <div fxFlex="100">
                        <p ngStyle.sm="margin-top: 20px;" ngStyle.xs="margin-top: 20px;" class="redes-sociales">
                          <a *ngIf="infoInmo.facebook" href="{{infoInmo.facebook}}" target="_blank"><img class="icono-redes" src="../../assets/icons/facebook-icon.png"></a>
                          <a *ngIf="infoInmo.twitter" href="{{infoInmo.twitter}}" target="_blank"><img class="icono-redes" src="../../assets/icons/twitter-icon.png"></a>
                          <a *ngIf="infoInmo.instagram" href="{{infoInmo.instagram}}" target="_blank"><img class="icono-redes" src="../../assets/icons/instagram-icon.png"></a>
                          <a *ngIf="infoInmo.linkedin" href="{{infoInmo.linkedin}}" target="_blank"><img class="icono-redes" src="../../assets/icons/linkedin-icon.png"></a>
                          <a *ngIf="infoInmo.youtube" href="{{infoInmo.youtube}}" target="_blank"><img class="youtube icono-redes" src="../../assets/icons/youtube-icon.png"></a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
<!--<div class="img-back0"><div class="img-back"></div></div>-->

<mat-progress-spinner *ngIf="!loadedInfo"
                      class="loader-spinner"
                      [color]="'primary'"
                      [value]="75"
                      [mode]="'indeterminate'"
                      [diameter]="120"
                      [strokeWidth]="15">
</mat-progress-spinner>
<auto-plantilla #AutoPlantillaComponent></auto-plantilla>
