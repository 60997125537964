import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { GenericService } from 'app/services/generic/generic.service';
import { RoutingService } from 'app/services/routing/routing.service';
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css']
})
export class BottomSheetComponent implements OnInit, OnDestroy {

  @Input() structure = null;

  private bottomsheetId;
  private monitoreo;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetref: MatBottomSheetRef<BottomSheetComponent>,
    private routingService: RoutingService,
    private genericService: GenericService,
    private endpointService: EndpointService,
    public authService: AuthService
  ) {
      this.structure = data['structure'];
  }

  ngOnInit(): void {
    this.bottomsheetId = this.structure['id_functional_parent_initial_dsb']
    if(this.routingService.urlHashtag){
      const id  = this.routingService.urlHashtag;
      for(const key in this.structure){
        if(key == id){
          this.genericService.scroll(id);
          break;
        }else{
          const element = this.genericService.findElementWithIdLoop(this.structure[key], id);
          if(element){
            this.genericService.scroll(key);
            break;
          }
        }
      }
    }
    this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), this.bottomsheetId, this.bottomsheetId, this.genericService.staticHTML).subscribe(data => {
      this.monitoreo = data['response'];
    });
  }

  ngOnDestroy() {
    this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }
}
