import {Component, Inject, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {CustomEvent} from '../objects/custom-event.component';
import SignaturePad from 'signature_pad';
import { EndpointService } from '../../services/endpoint/endpoint.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sign-activity-dialog',
  templateUrl: './sign-activity-dialog.html'
})

export class SignActivityDialog {

  @ViewChild('sPad', {static: true}) signaturePadElement;
  public signaturePad: any;

  event : CustomEvent;
  activities: CustomEvent[] = [];
  public clientName: string;
  public propertyReference: string;
  public clientId: number;


  constructor(public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<SignActivityDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private endpointService: EndpointService) {
    this.event = data.event;
    
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  onNoClick(): void {
    this.dialog.closeAll();
  }

  onCleanSign() {
    this.signaturePad.clear();
  }

  //called to sign a activity
  public onClickAcceptSignActivity() {
    // Pasa la firma a imagen
    if(this.signaturePad.toData().length !== 0){
      // Si ha firmado algo
      this.event.signImgURL = this.signaturePad.toDataURL();
      this.endpointService.signActivity(this.event.id, this.event.signLike, this.event.signNotify, this.event.signDNI, this.event.signImgURL, this.event.signObservation).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.dialogRef.close(2);
        } else {
          // Ha ocurrido un error
          this.snackBar.open('Ha ocurrido un error con la firma, por favor inténtelo de nuevo (Jan lo que quieras)', 'X', {
            duration: 3000,
            panelClass: ['red-snackbar']
          }); 
        }
      });
    } else {
      // Ha de firmar
      this.snackBar.open('Es necesario firmar', 'X', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    }
  }
}
