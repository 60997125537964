import {Component, OnInit, ViewChild} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {MatDialog} from "@angular/material/dialog";
import {EndpointService} from "../services/endpoint/endpoint.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {AutoBottomSheetComponent} from "./auto-bottom-sheet/auto-bottom-sheet.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AutoSnackbarComponent} from "./auto-snackbar/auto-snackbar.component";
import {AutoPopupComponent} from "./auto-popup/auto-popup.component";
import {DynamicReportBody} from "../workflow/subcomponents/dynamic-report/body/dynamic-report-body";
import {AutoComponentsComponent} from "./auto-components/auto-components.component";
import {forkJoin} from "rxjs";
import {NotificationsComponent} from "../home/subcomponents/notifications/notifications.component";
import {AdviceReportService} from "../services/advice-report/advice-report.service";
import {AuthService} from "../services/auth/auth.service";


export interface ButtonArray {
  id_button: number,
  active: boolean,
  name: string,
  text: string,
  tooltip: string,
  //action: number,
  actionArray: [],
  colorText: string,
  colorBackground: string,
  colorEdge: string,
  icon: string,
  icon_color: string,
  icon_type: number,
  icon_tooltipe: string,
  icon_active: boolean,
  link: string,
  pantalla: number,
  otra_plantilla: number,
  order: number;
}

@Component({
  selector: 'auto-plantilla',
  templateUrl: './auto-plantilla.component.html',
  styleUrls: ['./auto-plantilla.component.css']
})

export class AutoPlantillaComponent implements OnInit{

  public isDemo: boolean;

  public iconD;
  public colorD;
  public typeD;
  public toolD;
  public activeD;

  public bodyReport: DynamicReportBody;

  public containerArray : {idType: number, container: any, color: string, id: number, icon: string} [] = new Array();

  public buttonArray: ButtonArray[] = [];

  public stepArray;

  @ViewChild('NotificationsComponent') public notifications: NotificationsComponent;
  /*public buttonArray: {id_button: number, active: boolean, name: string, text: string, tooltip: string, action: number, colorText: string,
    colorBackground: string, colorEdge: string, icon: string, icon_color: string, icon_type: number, icon_tooltipe: string,
    icon_active: boolean, link: string, pantalla: number, otra_plantilla: number} [] = new Array();*/

  // public egoNotification: {id: number, id_usuario: number, id_plantilla_ego: number, fecha: string, hora_inicio: string, hora_fin: string} = null;
  public egoNotification: any = null;
  constructor(private adviceReportService: AdviceReportService,
              private material: MatIconModule,
              private endpointService: EndpointService,
              private dialog: MatDialog,
              private bottomSheet: MatBottomSheet,
              private snackBar:MatSnackBar,
              private authService: AuthService) { }

  ngOnInit(): void {

    this.bodyReport = new DynamicReportBody();

    this.containerArray = new Array();

    this.buttonArray.push({id_button: null, active: false, name: "Botón 1: ", text: null, tooltip: null, actionArray: [], colorText: "#000000",
      colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null,
      icon_active: true, link: null, pantalla: null, otra_plantilla: null, order: 1});

    this.buttonArray.push({id_button: null, active: false, name: "Botón 2: ", text: null, tooltip: null, actionArray: [], colorText: "#000000",
      colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null,
      icon_active: true, link: null, pantalla: null, otra_plantilla: null, order: 2});

    this.buttonArray.push({id_button: null, active: false, name: "Botón 3: ", text: null, tooltip: null, actionArray: [], colorText: "#000000",
      colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null,
      icon_active: true, link: null, pantalla: null, otra_plantilla: null, order: 3});

  }

  public staticButton(id: number){
    this.endpointService.getButtonActionReportActive(id).subscribe( data =>{
      this.openScreen(data['response']);
    })
  }

   public egoButton(id: number, body: any) {
     return new Promise(resolve => {
       this.endpointService.executeEgoButtonAction(id, body).subscribe( data => {
         resolve(data);
       });
     });
  }

  public staticButtonUpdateTipoColaboracion(id: number): any {
    this.endpointService.getButtonActionReportActive(id).subscribe( data =>{
      let ret = false;
      if (data['response'] !== 0) {
        ret = true;
        this.openScreen(data['response']);
      } else {
        ret = false;
      }
      return ret;
    })
  }

  public openScreen(id: number, not?: any, button?: boolean) {
    this.containerArray = [];
    this.stepArray = [];
    this.isDemo = false;
    console.log("abro popup con id:", id);
    if (id != 0) { // Tiene una plantilla asignada.
      this.endpointService.getPlantilla(id, this.authService.empresaId).subscribe(data =>{

        if(data['response']['plantilla'][0].id_tipo == 1 || data['response']['plantilla'][0].id_tipo == 2){

          this.bodyReport.handlePlantillaInfoResponse(data['response']['plantilla'][0], data['response']['screen'], data['response']['icon']).subscribe(async d => {

            for(let x = 0; x < data['response']['step']['length']; x++){

              let auxButton = new Array();
              let auxContainer = new Array();
              let containerPromise = new Promise<void>((resolve, reject) => {
                if(data['response']['step'][x]['cont']['length'] > 0){
                  for (let i = 0 ; i < data['response']['step'][x]['cont']['length'] ; i++)
                    auxContainer.push({idType: data['response']['step'][x]['cont'][i].id_plantillas_ego_tipo_contenido,
                      container: data['response']['step'][x]['cont'][i].contenido, color: data['response']['step'][x]['cont'][i].contenido_color,
                      id: data['response']['step'][x]['cont'][i].id});
                }
                resolve();
              });

              let p1 = new Promise<void>((resolve, reject) => {
                if(data['response']['step'][x]['button1'][0] != null) {

                  if(data['response']['step'][x]['iconButton1']['length'] > 0){

                    this.iconD = data['response']['step'][x]['iconButton1'][0].icon_nombre;
                    this.colorD = data['response']['step'][x]['iconButton1'][0].icon_color;
                    this.typeD = data['response']['step'][x]['iconButton1'][0].id_icon_clase;
                    this.toolD = data['response']['step'][x]['iconButton1'][0].icon_tootltip;
                    this.activeD = true;

                  } else {

                    this.iconD = "event";
                    this.colorD = "#000000";
                    this.typeD = 1;
                    this.toolD = null;
                    this.activeD = false;

                  }

                  let btn1: ButtonArray = null;
                  btn1 = {
                    id_button: data['response']['step'][x]['button1'][0].id,
                    active: true,
                    name: "Botón 1: ",
                    text: data['response']['step'][x]['button1'][0].button_texto,
                    tooltip: data['response']['step'][x]['button1'][0].button_tooltip,
                    actionArray: data['response']['step'][x]['button1'][0].action,
                    colorText: data['response']['step'][x]['button1'][0].button_color_texto,
                    colorBackground: data['response']['step'][x]['button1'][0].button_color_fondo,
                    colorEdge: data['response']['step'][x]['button1'][0].button_color_borde,
                    icon: this.iconD,
                    icon_color: this.colorD,
                    icon_type: this.typeD,
                    icon_tooltipe: this.toolD,
                    icon_active: this.activeD,
                    link: data['response']['step'][x]['button1'][0].button_accion_link,
                    pantalla: data['response']['step'][x]['button1'][0].id_pantalla,
                    otra_plantilla: data['response']['step'][x]['button1'][0].id_plantilla_ego_button,
                    order: 1
                  };

                  auxButton[0] = btn1;
                  resolve();
                  //this.buttonArray[0] = btn1;
                }
                else {
                  // auxButton[0] = this.buttonArray[0];
                  resolve();
                }
              });
              let p2 = new Promise<void>((resolve, reject) => {
                if(data['response']['step'][x]['button2'][0] != null) {

                  if(data['response']['step'][x]['iconButton2']['length'] > 0){

                    this.iconD = data['response']['step'][x]['iconButton2'][0].icon_nombre;
                    this.colorD = data['response']['step'][x]['iconButton2'][0].icon_color;
                    this.typeD = data['response']['step'][x]['iconButton2'][0].id_icon_clase;
                    this.toolD = data['response']['step'][x]['iconButton2'][0].icon_tootltip;
                    this.activeD = true;

                  }else{

                    this.iconD = "event";
                    this.colorD = "#000000";
                    this.typeD = 1;
                    this.toolD = null;
                    this.activeD = false;

                  }
                  let btn2: ButtonArray = null;
                  btn2 = {
                    id_button: data['response']['step'][x]['button2'][0].id,
                    active: true,
                    name: "Botón 2: ",
                    text: data['response']['step'][x]['button2'][0].button_texto,
                    tooltip: data['response']['step'][x]['button2'][0].button_tooltip,
                    actionArray: data['response']['step'][x]['button2'][0].action,
                    colorText: data['response']['step'][x]['button2'][0].button_color_texto,
                    colorBackground: data['response']['step'][x]['button2'][0].button_color_fondo,
                    colorEdge: data['response']['step'][x]['button2'][0].button_color_borde,
                    icon: this.iconD,
                    icon_color: this.colorD,
                    icon_type: this.typeD,
                    icon_tooltipe: this.toolD,
                    icon_active: this.activeD,
                    link: data['response']['step'][x]['button2'][0].button_accion_link,
                    pantalla: data['response']['step'][x]['button2'][0].id_pantalla,
                    otra_plantilla: data['response']['step'][x]['button2'][0].id_plantilla_ego_button,
                    order: 2
                  };
                  auxButton[1] = btn2;
                  resolve();
                  //this.buttonArray[1] = btn2;
                }
                else {
                  // auxButton[1] = this.buttonArray[1];
                  resolve();
                }
              });
              let p3 = new Promise<void>((resolve, reject) => {
                if(data['response']['step'][x]['button3'][0] != null){

                  if(data['response']['step'][x]['iconButton3']['length'] > 0){

                    this.iconD = data['response']['step'][x]['iconButton3'][0].icon_nombre;
                    this.colorD = data['response']['step'][x]['iconButton3'][0].icon_color;
                    this.typeD = data['response']['step'][x]['iconButton3'][0].id_icon_clase;
                    this.toolD = data['response']['step'][x]['iconButton3'][0].icon_tootltip;
                    this.activeD = true;

                  }else{

                    this.iconD = "event";
                    this.colorD = "#000000";
                    this.typeD = 1;
                    this.toolD = null;
                    this.activeD = false;

                  }

                  let btn3: ButtonArray = null;
                  btn3 = {
                    id_button: data['response']['step'][x]['button3'][0].id,
                    active: true, name: "Botón 3: ",
                    text: data['response']['step'][x]['button3'][0].button_texto,
                    tooltip: data['response']['step'][x]['button3'][0].button_tooltip,
                    actionArray: data['response']['step'][x]['button3'][0].action,
                    colorText: data['response']['step'][x]['button3'][0].button_color_texto,
                    colorBackground: data['response']['step'][x]['button3'][0].button_color_fondo,
                    colorEdge: data['response']['step'][x]['button3'][0].button_color_borde,
                    icon: this.iconD,
                    icon_color: this.colorD,
                    icon_type: this.typeD,
                    icon_tooltipe: this.toolD,
                    icon_active: this.activeD,
                    link: data['response']['step'][x]['button3'][0].button_accion_link,
                    pantalla: data['response']['step'][x]['button3'][0].id_pantalla,
                    otra_plantilla: data['response']['step'][x]['button3'][0].id_plantilla_ego_button,
                    order: 3
                  };
                  auxButton[2] = btn3;
                  resolve();
                  //this.buttonArray[2] = btn3;
                }
                else {
                  // auxButton[2] = [];
                  resolve();
                }
              });

              const observersArray = [];
              observersArray.push(containerPromise);
              observersArray.push(p1);
              observersArray.push(p2);
              observersArray.push(p3);

              forkJoin(observersArray).subscribe(dataa => {
                //this.buttonArray = auxButton;
                this.egoNotification = not ? not : null;
                //this.containerArray = auxContainer;

                this.stepArray.push({id: data['response']['step'][x]['id_step'],
                                     container : auxContainer,
                                     buttons: auxButton,
                                     name: data['response']['step'][x]['nombre_step'],
                                     icon: data['response']['step'][x]['icon'][0]});
                console.log("1")
                if(x+1 == data['response']['step']['length']) this.preview(not, id);
              });

            }

          });

        }else{
          this.bodyReport.handlePlantillaInfoResponse(data['response']['plantilla'][0], data['response']['screen'], data['response']['icon']).subscribe(async d => {

            let auxButton = new Array();
            let auxContainer = null;//new Array();
            /*let containerPromise = new Promise((resolve, reject) => {
              if(data['response']['cont']['length'] > 0){
                for (let i = 0 ; i < data['response']['cont']['length'] ; i++)
                  auxContainer.push({idType: data['response']['cont'][i].id_plantillas_ego_tipo_contenido, container: data['response']['cont'][i].contenido, color: data['response']['cont'][i].contenido_color, id: data['response']['cont'][i].id});
              }
              resolve();
            });*/

            let p1 = new Promise<void>((resolve, reject) => {
              if(data['response']['button1'][0]) {

                if(data['response']['iconButton1']['length'] > 0){

                  this.iconD = data['response']['iconButton1'][0].icon_nombre;
                  this.colorD = data['response']['iconButton1'][0].icon_color;
                  this.typeD = data['response']['iconButton1'][0].id_icon_clase;
                  this.toolD = data['response']['iconButton1'][0].icon_tootltip;
                  this.activeD = true;

                }else{

                  this.iconD = "event";
                  this.colorD = "#000000";
                  this.typeD = 1;
                  this.toolD = null;
                  this.activeD = false;

                }

                let btn1: ButtonArray = null;
                btn1 = {
                  id_button: data['response']['button1'][0].id,
                  active: true,
                  name: "Botón 1: ",
                  text: data['response']['button1'][0].button_texto,
                  tooltip: data['response']['button1'][0].button_tooltip,
                  actionArray: data['response']['button1'][0].action,
                  colorText: data['response']['button1'][0].button_color_texto,
                  colorBackground: data['response']['button1'][0].button_color_fondo,
                  colorEdge: data['response']['button1'][0].button_color_borde,
                  icon: this.iconD,
                  icon_color: this.colorD,
                  icon_type: this.typeD,
                  icon_tooltipe: this.toolD,
                  icon_active: this.activeD,
                  link: data['response']['button1'][0].button_accion_link,
                  pantalla: data['response']['button1'][0].id_pantalla,
                  otra_plantilla: data['response']['button1'][0].id_plantilla_ego_button,
                  order: 1
                };
                resolve();
                auxButton[0] = btn1;
                //this.buttonArray[0] = btn1;
              }
              else {
                // auxButton[0] = this.buttonArray[0];
                resolve();
              }
            });
            let p2 = new Promise<void>((resolve, reject) => {
              if(data['response']['button2'][0]) {

                if(data['response']['iconButton2']['length'] > 0){

                  this.iconD = data['response']['iconButton2'][0].icon_nombre;
                  this.colorD = data['response']['iconButton2'][0].icon_color;
                  this.typeD = data['response']['iconButton2'][0].id_icon_clase;
                  this.toolD = data['response']['iconButton2'][0].icon_tootltip;
                  this.activeD = true;

                }else{

                  this.iconD = "event";
                  this.colorD = "#000000";
                  this.typeD = 1;
                  this.toolD = null;
                  this.activeD = false;

                }
                let btn2: ButtonArray = null;
                btn2 = {
                  id_button: data['response']['button2'][0].id,
                  active: true,
                  name: "Botón 2: ",
                  text: data['response']['button2'][0].button_texto,
                  tooltip: data['response']['button2'][0].button_tooltip,
                  actionArray: data['response']['button2'][0].action,
                  colorText: data['response']['button2'][0].button_color_texto,
                  colorBackground: data['response']['button2'][0].button_color_fondo,
                  colorEdge: data['response']['button2'][0].button_color_borde,
                  icon: this.iconD,
                  icon_color: this.colorD,
                  icon_type: this.typeD,
                  icon_tooltipe: this.toolD,
                  icon_active: this.activeD,
                  link: data['response']['button2'][0].button_accion_link,
                  pantalla: data['response']['button2'][0].id_pantalla,
                  otra_plantilla: data['response']['button2'][0].id_plantilla_ego_button,
                  order: 2
                };
                resolve();
                auxButton[1] = btn2;
                //this.buttonArray[1] = btn2;
              }
              else {
                // auxButton[1] = this.buttonArray[1];
                resolve();
              }
            });
            let p3 = new Promise<void>((resolve, reject) => {
              if(data['response']['button3'][0]){

                if(data['response']['iconButton3']['length'] > 0){

                  this.iconD = data['response']['iconButton3'][0].icon_nombre;
                  this.colorD = data['response']['iconButton3'][0].icon_color;
                  this.typeD = data['response']['iconButton3'][0].id_icon_clase;
                  this.toolD = data['response']['iconButton3'][0].icon_tootltip;
                  this.activeD = true;

                }else{

                  this.iconD = "event";
                  this.colorD = "#000000";
                  this.typeD = 1;
                  this.toolD = null;
                  this.activeD = false;

                }

                let btn3: ButtonArray = null;
                btn3 = {
                  id_button: data['response']['button3'][0].id,
                  active: true, name: "Botón 3: ",
                  text: data['response']['button3'][0].button_texto,
                  tooltip: data['response']['button3'][0].button_tooltip,
                  actionArray: data['response']['button3'][0].action,
                  colorText: data['response']['button3'][0].button_color_texto,
                  colorBackground: data['response']['button3'][0].button_color_fondo,
                  colorEdge: data['response']['button3'][0].button_color_borde,
                  icon: this.iconD,
                  icon_color: this.colorD,
                  icon_type: this.typeD,
                  icon_tooltipe: this.toolD,
                  icon_active: this.activeD,
                  link: data['response']['button3'][0].button_accion_link,
                  pantalla: data['response']['button3'][0].id_pantalla,
                  otra_plantilla: data['response']['button3'][0].id_plantilla_ego_button,
                  order: 3
                };
                resolve();
                auxButton[2] = btn3;
                //this.buttonArray[2] = btn3;
              }
              else {
                // auxButton[2] = [];
                resolve();
              }
            });

            const observersArray = [];
            //observersArray.push(containerPromise);
            observersArray.push(p1);
            observersArray.push(p2);
            observersArray.push(p3);
            forkJoin(observersArray).subscribe(dataa => {
              this.buttonArray = auxButton;
              this.egoNotification = not ? not : null;
              this.containerArray = auxContainer;
              console.log("2")
              this.preview(not, id);

            });
          });
        }


      });

    } else {
      // ID: 199, Plantilla No Implementada
      this.endpointService.getPlantilla(119, this.authService.empresaId).subscribe(data =>{
        this.bodyReport.handlePlantillaInfoResponse(data['response']['plantilla'][0], data['response']['screen'], data['response']['icon']).subscribe(async d => {

          let auxButton = new Array();
          let auxContainer = new Array();
          let containerPromise = new Promise<void>((resolve, reject) => {
            if(data['response']['cont']['length'] > 0){
              for (let i = 0 ; i < data['response']['cont']['length'] ; i++)
                auxContainer.push({idType: data['response']['cont'][i].id_plantillas_ego_tipo_contenido, container: data['response']['cont'][i].contenido, color: data['response']['cont'][i].contenido_color, id: data['response']['cont'][i].id});
            }
            resolve();
          });

          let p1 = new Promise<void>((resolve, reject) => {
            if(data['response']['button1'][0]) {

              if(data['response']['iconButton1']['length'] > 0){

                this.iconD = data['response']['iconButton1'][0].icon_nombre;
                this.colorD = data['response']['iconButton1'][0].icon_color;
                this.typeD = data['response']['iconButton1'][0].id_icon_clase;
                this.toolD = data['response']['iconButton1'][0].icon_tootltip;
                this.activeD = true;

              }else{

                this.iconD = "event";
                this.colorD = "#000000";
                this.typeD = 1;
                this.toolD = null;
                this.activeD = false;

              }

              let btn1: ButtonArray = null;
              btn1 = {
                id_button: data['response']['button1'][0].id,
                active: true,
                name: "Botón 1: ",
                text: data['response']['button1'][0].button_texto,
                tooltip: data['response']['button1'][0].button_tooltip,
                actionArray: data['response']['button1'][0].action,
                colorText: data['response']['button1'][0].button_color_texto,
                colorBackground: data['response']['button1'][0].button_color_fondo,
                colorEdge: data['response']['button1'][0].button_color_borde,
                icon: this.iconD,
                icon_color: this.colorD,
                icon_type: this.typeD,
                icon_tooltipe: this.toolD,
                icon_active: this.activeD,
                link: data['response']['button1'][0].button_accion_link,
                pantalla: data['response']['button1'][0].id_pantalla,
                otra_plantilla: data['response']['button1'][0].id_plantilla_ego_button,
                order: 1
              };
              resolve();
              auxButton[0] = btn1;
              //this.buttonArray[0] = btn1;
            }
            else {
              // auxButton[0] = this.buttonArray[0];
              resolve();
            }
          });
          let p2 = new Promise<void>((resolve, reject) => {
            if(data['response']['button2'][0]) {

              if(data['response']['iconButton2']['length'] > 0){

                this.iconD = data['response']['iconButton2'][0].icon_nombre;
                this.colorD = data['response']['iconButton2'][0].icon_color;
                this.typeD = data['response']['iconButton2'][0].id_icon_clase;
                this.toolD = data['response']['iconButton2'][0].icon_tootltip;
                this.activeD = true;

              }else{

                this.iconD = "event";
                this.colorD = "#000000";
                this.typeD = 1;
                this.toolD = null;
                this.activeD = false;

              }

              let btn2: ButtonArray = null;
              btn2 = {
                id_button: data['response']['button2'][0].id,
                active: true,
                name: "Botón 2: ",
                text: data['response']['button2'][0].button_texto,
                tooltip: data['response']['button2'][0].button_tooltip,
                actionArray: data['response']['button2'][0].action,
                colorText: data['response']['button2'][0].button_color_texto,
                colorBackground: data['response']['button2'][0].button_color_fondo,
                colorEdge: data['response']['button2'][0].button_color_borde,
                icon: this.iconD,
                icon_color: this.colorD,
                icon_type: this.typeD,
                icon_tooltipe: this.toolD,
                icon_active: this.activeD,
                link: data['response']['button2'][0].button_accion_link,
                pantalla: data['response']['button2'][0].id_pantalla,
                otra_plantilla: data['response']['button2'][0].id_plantilla_ego_button,
                order: 2
              };
              resolve();
              auxButton[1] = btn2;
              //this.buttonArray[1] = btn2;
            }
            else {
              // auxButton[1] = this.buttonArray[1];
              resolve();
            }
          });
          let p3 = new Promise<void>((resolve, reject) => {
            if(data['response']['button3'][0]){

              if(data['response']['iconButton3']['length'] > 0){

                this.iconD = data['response']['iconButton3'][0].icon_nombre;
                this.colorD = data['response']['iconButton3'][0].icon_color;
                this.typeD = data['response']['iconButton3'][0].id_icon_clase;
                this.toolD = data['response']['iconButton3'][0].icon_tootltip;
                this.activeD = true;

              }else{

                this.iconD = "event";
                this.colorD = "#000000";
                this.typeD = 1;
                this.toolD = null;
                this.activeD = false;

              }

              let btn3: ButtonArray = null;
              btn3 = {
                id_button: data['response']['button3'][0].id,
                active: true, name: "Botón 3: ",
                text: data['response']['button3'][0].button_texto,
                tooltip: data['response']['button3'][0].button_tooltip,
                actionArray: data['response']['button3'][0].action,
                colorText: data['response']['button3'][0].button_color_texto,
                colorBackground: data['response']['button3'][0].button_color_fondo,
                colorEdge: data['response']['button3'][0].button_color_borde,
                icon: this.iconD,
                icon_color: this.colorD,
                icon_type: this.typeD,
                icon_tooltipe: this.toolD,
                icon_active: this.activeD,
                link: data['response']['button3'][0].button_accion_link,
                pantalla: data['response']['button3'][0].id_pantalla,
                otra_plantilla: data['response']['button3'][0].id_plantilla_ego_button,
                order: 3
              };
              resolve();
              auxButton[2] = btn3;
              //this.buttonArray[2] = btn3;
            }
            else {
              // auxButton[2] = [];
              resolve();
            }
          });

          const observersArray = [];
          observersArray.push(containerPromise);
          observersArray.push(p1);
          observersArray.push(p2);
          observersArray.push(p3);
          forkJoin(observersArray).subscribe(dataa => {
            this.buttonArray = auxButton;
            this.egoNotification = not ? not : null;
            this.containerArray = auxContainer;
            this.preview(not);
          });
        });
      });
    }
  }

  public openDemo(info, button, step) {

    this.isDemo = true;

    this.bodyReport = info;
    this.buttonArray = button;
    if(this.bodyReport.idType == 1 || this.bodyReport.idType == 2) this.stepArray = step;
    console.log("4")
    this.preview(null);
  }

  private preview(not: any, id: any = null){
    console.log(not)
    switch (this.bodyReport.idType) {
      case 1:
        this.openBottomSheet(this.bodyReport, this.stepArray, not, id);
        break;
      case 2:
        this.openPopUp(this.bodyReport, this.stepArray, not, id);
        break;
      case 3:
        this.openAdviceHome(this.bodyReport, this.buttonArray, not, id);
        break;
      case 4:
        this.openAdviceSuperior(this.bodyReport, this.buttonArray, not, id);
        break;
      case 5:
        //this.autoPlantilla.openSnackBar(this.bodyReport, this.buttonArray, this.bodyReport.snackTime);
        //this.openSnackBar(this.bodyReport, this.buttonArray, this.bodyReport.snackTime);
        this.openSnackBar(this.bodyReport, this.buttonArray, this.bodyReport.snackTime, not, id);
        break;
    }

  }

  private openBottomSheet(info, step, not, id){
    this.bottomSheet.open(AutoBottomSheetComponent,
{data: { plantilla: info, step: step, demo: this.isDemo, not: not, index: id}
    });
  }

  private openPopUp(info, step, not, id){

    const dialogRef = this.dialog.open(AutoPopupComponent,{
      width: 'auto',
      height: 'auto',
      data:{plantilla: info, step: step, demo: this.isDemo, not: not, index: id}
    });

    dialogRef.afterClosed().subscribe( data=>{
    });

  }

  private openAdviceHome(info, button, not, id){

    if(this.isDemo){
      const dialogRef = this.dialog.open(AutoComponentsComponent,{
        width: 'auto',
        height: 'auto',
        data:{plantilla: info, boton: button, demo: this.isDemo, not: not, opType: 3, index: id}
      });

      dialogRef.afterClosed().subscribe( data=>{
      });
    }else{
      this.adviceReportService.newNotiHome(info, button);
    }

  }

  private openAdviceSuperior(info, button, not, id){
    const dialogRef = this.dialog.open(AutoComponentsComponent,{
      width: 'auto',
      height: 'auto',
      data:{plantilla: info, boton: button, demo: this.isDemo, not: not, opType: 4, index: id}
    });

    dialogRef.afterClosed().subscribe( data=>{
    });

  }

  private openSnackBar(info, button, time, not, id){
      this.snackBar.openFromComponent(AutoSnackbarComponent, {
        duration: time * 1000, data: {plantilla: info, boton: button, demo: this.isDemo, not: not, index: id}
      });
  }

}
