import {Component, Inject, Input, ViewChild} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {EndpointService} from '../../services/endpoint/endpoint.service';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'bottom-sheet-upload-file',
  templateUrl: 'bottom-sheet-upload-file.html',
})
export class BottomSheetUploadFile {

  @ViewChild('uploaderForm') uploadForm;
  public Table = '';
  public filename = 'Nombre del archivo';
  public filesToAttach : any;


  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetUploadFile>,
              private endpointService: EndpointService,
              private authService: AuthService,
              private _bottomSheet: MatBottomSheet,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.filesToAttach = data.filesToAttach;
  }

  ngOnInit(){
  }

  closeBottomSheet(buttonOption : number){
    this._bottomSheetRef.dismiss(buttonOption);
  }

  public fileChange(event) {

    let fileList: FileList = this.uploadForm.nativeElement[1].files;
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        this.endpointService.uploadFile(this.authService.userId, formData, this.filename, this.Table,this.authService.inmoId).subscribe(
          data => {}
        );
        /*
        this.endpointService.uploadOfferDoc(this.authService.userId, formData, this.docName, this.offer.idOffer).subscribe(
          data => {
            if (data.errorCode == 0) {
              this.endpointService.getOfferDocs(this.offer.idOffer).subscribe(
                data => {
                  this.offer.docs = data.response;
                }
              );
            }
          }
        );
        */
      }
    }
  }

}
