import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CdkDragDrop, CdkDrag, CdkDragStart, CdkDropList, CdkDropListGroup, moveItemInArray } from "@angular/cdk/drag-drop";
import { AuthService } from 'app/services/auth/auth.service';
import { GenericService } from "app/services/generic/generic.service";
import { FunctionsService } from "app/services/functions/functions.service";
import { EndpointService } from 'app/services/endpoint/endpoint.service';

@Component({
  selector: 'app-flat-images-custom',
  templateUrl: './flat-images-custom.component.html',
  styleUrls: ['./flat-images-custom.component.css']
})
export class FlatImagesCustomComponent implements OnInit {
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  
  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;

  public loaded = false;

  constructor(
    public authService: AuthService,
    public genericService: GenericService,
    public functionsService: FunctionsService,
    public endpointService: EndpointService)
  {}

  ngOnInit(): void {
    let intern_data = [];
    let idProperty = 0;
    intern_data = this.functionsService.getInternParam(this.structure[this.index], this.param);
    this.functionsService.arrayImagenes = [];
    for (let i in intern_data) {
      if(idProperty > 0) break;
      if (intern_data[i]['id_db'] == 1 && intern_data[i]['bd_table'] == 'vivienda' && intern_data[i]['bd_field'] == 'id') {
        idProperty = intern_data[i]['value'];
        this.endpointService.getImagesProperty(idProperty).subscribe(data => {
          if (data['response'].length > 0) {
            let imagesArray = data['response'];
            for(let j in imagesArray) {
              imagesArray[j]['nombre_fichero'] = imagesArray[j]['nombre_fichero'] + "?" + new Date().getTime();
            }
            this.functionsService.arrayImagenes = imagesArray;
          }
          this.loaded = true;
        });
      }
    }
  }

  drop(event: CdkDragDrop<unknown>) {
    console.log("drop", event.previousIndex, event.currentIndex);
    moveItemInArray(this.functionsService.arrayImagenes, event.previousIndex, event.currentIndex);

    let i = 1;
    this.functionsService.arrayImagenes.forEach(item => {
      item.orden = i;
      i++;
    });
  }

  updatePublish(value, id) {
    this.functionsService.arrayImagenes.forEach(item => {
      if(item.id == id) {
        if(value) item.selected = 1;
        else item.selected = 0;
      }
    });
  }

  updatePlano(value, id) {
    this.functionsService.arrayImagenes.forEach(item => {
      if(item.id == id) {
        if(value) item.plano = 1;
        else item.plano = 0;
      }
    });
  } 
}