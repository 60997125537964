<div *ngIf="addNewList" id="{{idFilter}}" tabindex="-1">
  <h2 style="font-size: 21.5px; font-weight: bold; text-transform: uppercase;">Nueva lista de destinatarios</h2>
  <div class="row-expansion2" style="display: flex">
    <!--Nombre de la lista-->
    <mat-form-field>
      <mat-label>Nombre de la lista</mat-label>
      <input matInput [(ngModel)]="newListName" [disabled]="false">
    </mat-form-field>

    <!--Tipo del destinatario-->
    <mat-form-field style="width: auto;">
      <mat-label>Tipo del destinatario</mat-label>
      <mat-select [(ngModel)]="newListTargetType" (ngModelChange)="getClients(true)">
        <mat-option *ngFor="let type of targetType" [value]="type.id">{{type.valor}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="newListTargetType === 1">
    <div class="row-expansion2" style="display: flex">
      <!--Nombre o apellido-->
      <mat-form-field>
        <mat-label>Nombre o apellido</mat-label>
        <input matInput type="text" [(ngModel)]="name" [disabled]="false">
      </mat-form-field>
    </div>
    <div class="row-expansion2" style="display: flex">
      <!--Activo-->
      <mat-form-field style="width: auto; margin-top: auto">
        <mat-label>Activo</mat-label>
        <mat-select [(ngModel)]="active">
          <mat-option [value]="1">Si</mat-option>
          <mat-option [value]="0">No</mat-option>
          <mat-option [value]="-1">Todos</mat-option>
        </mat-select>
      </mat-form-field>

      <!--Tipo cliente-->
      <mat-form-field style="width: auto; margin-top: auto">
        <mat-label>Tipo cliente</mat-label>
        <mat-select [(ngModel)]="clientType">
          <mat-option *ngFor="let type of clientInmoTypes" [value]="type.id">{{type.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="newListTargetType === 2">
    <div class="row-expansion2" style="display: flex">
      <!--Nombre o apellido-->
      <mat-form-field>
        <mat-label>Nombre o apellido</mat-label>
        <input matInput type="text" [(ngModel)]="name" [disabled]="false">
      </mat-form-field>
    </div>
  </div>

  <div class="row-expansion2" style="display: flex;">
    <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer; margin-right: 0.7em !important;" (click)="filterClients()"><i class="material-icons">search</i> Filtrar </div>
    <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer; margin-left: 0.7em !important;" (click)="clearFilter()"><i class="material-icons">clear</i> Limpiar </div>
  </div>

  <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer;" (click)="showlist()"> <i class="material-icons">face</i>Mostrar Destinatarios </div>

  <div class="row-expansion1" *ngIf="loaded">
    <!--Destinatarios-->
    <mat-form-field>
      <mat-label>Destinatarios</mat-label>
      <mat-select [(ngModel)]="newListTarget" multiple>
        <mat-select-trigger>
          {{newListTarget[0] ? getFirstOfListsName(newListTarget[0]) : ''}}
          <span *ngIf="newListTarget?.length > 1" class="example-additional-selection">
              (+{{newListTarget.length - 1}} {{newListTarget?.length === 2 ? 'destinatario mas' : 'destinatarios mas'}})
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let email of currentEmailsList" [value]="email.id" [class.hide]="!email.filtered">
          {{email.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row-expansion2" style="display: flex;">
    <div *ngIf="!edit" style="margin-right: 0.7em !important; width: 100%">
      <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer;" (click)="createList()"><i class="material-icons">add</i> Crear lista </div>
    </div>

    <div *ngIf="edit" style="margin-right: 0.7em !important; width: 100%">
      <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer;" (click)="updateList()"><i class="material-icons">autorenew</i> Actualizar lista </div>
    </div>

    <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer; margin-left: 0.7em; width: 100%" (click)="hideFilter()"><i class="material-icons">clear</i> Cancelar </div>
  </div>
</div>

<!--
  Administrador de listas destinatarios
-->

<div *ngIf="!addNewList">
  <h2 style="font-size: 21.5px; font-weight: bold; text-transform: uppercase;">Listas de destinatarios</h2>
  <div class="example-container mat-elevation-z3 taulesgrans" style="overflow: auto; height: calc(40vh);">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef class="buttons-head">
          <i class="material-icons">add</i>
        </th>
        <td mat-cell *matCellDef="let element">
          <button click-stop-propagation (click)="editTargetList(element.id); refreshLoad();" class="btn btn-warning erp-status-btn"><i class="material-icons">create</i></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="editTargetList(row.id); refreshLoad();" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class="bottom-table">
    <div class="bottom-table2">
      <mat-form-field class="input-filter">
        <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
      </mat-form-field>
    </div>
    <mat-paginator style="width: 50%;" [pageSizeOptions]="[5,10,20]"></mat-paginator>
  </div>

  <!--<div *ngFor="let list of targetList" class="col-sm-12">
    <button (click)="editTargetList(list.id); refreshLoad();" class="btn btn-warning erp-status-btn">
      <i class="material-icons">create</i>
    </button>
    &lt;!&ndash;
    <button (click)="deleteTargetList(list.id)" class="btn btn-danger erp-status-btn">
      <i class="material-icons">delete_forever</i>
    </button>
    &ndash;&gt;
    {{list.nombre}}
  </div>-->
  <div class="row-expansion2" style="display: flex; margin-top: 1.5em">
    <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer; margin-right: 0.7em !important;"
         (click)="showFilter()"><i class="material-icons">search</i> Añadir nueva lista </div>
    <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer; margin-left: 0.7em !important;"
         (click)="onClickDecline()"><i class="material-icons">clear</i> Cerrar </div>
  </div>
</div>

