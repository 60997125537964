import {Component, Input, OnInit} from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() structure = null;
  @Input() index: number;

  constructor(public genericService: GenericService) { }
  ngOnInit(): void {
  }
}
