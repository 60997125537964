import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'bottom-sheet-tracking-link',
  templateUrl: 'bottom-sheet-tracking-link.html',
})
export class BottomSheetTrackingLink {

  trackingLinkName: any;
  trackingLinkLink: any;

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetTrackingLink>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.trackingLinkName = data.trackingLinkName;
    this.trackingLinkLink = data.trackingLinkLink;
  }


  closeBottomSheet(type: number) {
    let object = {num : type, trackingLinkName : this.trackingLinkName, trackingLinkLink : this.trackingLinkLink};

    this._bottomSheetRef.dismiss(object);

  }

}
