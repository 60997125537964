import { isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxFreshChatService } from "ngx-freshchat";
import { Subscription } from "rxjs";
import { Strings } from '../strings/strings';
import { Values } from '../values/values';
import { AutoPlantillaComponent } from "./auto-plantilla/auto-plantilla.component";
import { SocialMediaCustomComponent } from './generic/components-custom/rrss-home-custom/rrss-home-custom.component';
import { GenericComponent } from "./generic/generic.component";
import { LoginScreenComponent } from "./login/login-screen/login-screen.component";
import { LoginComponent } from "./login/login.component";
import { NotificacionSuperiorComponent } from "./notificacion-superior/notificacion-superior.component";
import { AdviceReportService } from "./services/advice-report/advice-report.service";
import { ApiChatService } from './services/api-chat/api-chat.service';
import { AuthService } from './services/auth/auth.service';
import { ChatService } from "./services/chat.service";
import {NotificationsCustomComponent} from "./generic/components-custom/notifications-custom/notifications-custom.component";
import { FunctionsService } from './services/functions/functions.service';
import { GenericService } from "./services/generic/generic.service";
//import { PrivilegiesControlService } from './services/privilegies-control/privilegies-control.service';
import { PushNotificationsService } from './services/pushNotifications.service';
import { RedirectionService } from './services/redirection/redirection.service';
import { RoutingService } from './services/routing/routing.service';
//import { StaticTablesService } from './services/static-tables.service';
import { SideNavService } from './utils/SideNavService';
import * as _ from "lodash";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  readonly VAPID_PUBLIC_KEY = "BIwKVBxu5QfUclJbgJTmWnqp8RagohmcKYZfONJb5r2tuHWgccTHsnWNk53S8h5iEqC86PcL_rpXjdGVKIfuTwE";
  public Version = Values.Version;
  public strings = Strings.APP_C_STRINGS;
  public routes = Values.ROUTES;
  //loaded: Observable<any>;

  public headerStructure = null;
  public structure = null;

  //Suscripciones al observable emmitter para captar los clicks de los botones de generic (pone que no se usan pero sí)
  private openChatSubscription: Subscription = null;
  private openHelpSubscription: Subscription = null;
  private openExternalWindowSubscription: Subscription = null;
  private openButtonAutoPlantilla: Subscription = null;
  public notificacionSuperiorNotLoaded = false;

  public freshchat: any;

  private toolsCache = {};
  public showInstallAppIos = false;
  public closeSidenavButton = false;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('LoginScreenComponent') public LoginScreenComponent: LoginScreenComponent;
  @ViewChild('LoginComponent') public LoginComponent: LoginComponent;
  @ViewChild('NotificacionSuperiorComponent') public NotificacionSuperiorComponent: NotificacionSuperiorComponent;
  @ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;
  @ViewChild('generic') public generic: GenericComponent;
  @ViewChild ('SocialMediaCustomComponent') public SociaSocialMediaCustomComponent: SocialMediaCustomComponent;
  @ViewChild('warningDialog') warningDialog : any;

  @ViewChild('notisCustom2') public notificationsCustom2: NotificationsCustomComponent;

  private nots: any[];
  public prueba: any;

  @HostListener('window:beforeunload', ['$event'])
  async unloadNotification($event: any) {
    if (Object.keys(this.genericService.formsChanged[this.genericService.currentInitialArea['key']]).length > 0) {
      $event.returnValue = 'Parece que hay cambios sin guardar. ¿Quieres continuar con esta acción?';
    }
  }

  constructor(
    private adviceReportService: AdviceReportService,
    public sideNavService: SideNavService,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private redirectionService: RedirectionService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    //public staticTablesService: StaticTablesService,
    public deviceService: DeviceDetectorService,
    public chat: NgxFreshChatService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId,
    public genericService : GenericService,
    public chatService: ChatService,
    private apiChatService: ApiChatService,
    public routingService: RoutingService,
    private swPush: SwPush,
    private pushNotificationsService: PushNotificationsService,
    private functionsService: FunctionsService
    ) {
    this.genericService.shouldOpenHelp = false;
    this.genericService.shouldOpenChat = false;
    this.genericService.shouldOpenExternalWindow = false;

      /*router.events.filter(event => event instanceof NavigationStart)
        .subscribe((event:NavigationStart) => {
          // You only receive NavigationStart events
        });*/

    // Idiomas para traducir
    translate.addLangs(['es', 'en', 'ca']);
    // Usar idioma del navegador
    translate.use( this.translate.getBrowserLang() );
    // Traducción de backup
    translate.setDefaultLang('es');

    /*this.location.onUrlChange(url =>
      {
        alert(url);
        if(this.genericService.dirtyForm){
          alert(url)
          this.genericService.dirtyForm = false;
        }
      });*/
  }

  ngOnInit() {
    this.genericService.staticHTML = window.location.href.includes('sth');
    this.route.paramMap.subscribe(params => {
      const id = params['id'];
      if(this.router.url.indexOf('/joinGroup') > -1 ){
        if(this.authService.isUserSessionActive()){
          this.apiChatService.joinGroupByInvitationLink(id, this.authService.user_token).subscribe(data => {
            /* COMENTADO FALLA --> SOUFIANE
            if(data.error == -1){
              this.snackBar.open('¡Vaya, parece que este grupo no existe!', 'X', {
                duration: 6000,
                panelClass: ['lightred-snackbar']
              });

            } else if(data.error == -2){
              this.snackBar.open('¡Vaya, parece que este enlace ha expirado!', 'X', {
                duration: 6000,
                panelClass: ['lightred-snackbar']
              });
            } else{
              this.snackBar.open('TODO OK!', 'X', {
                duration: 6000,
                panelClass: ['lightgreen-snackbar']
              });

              this.name = {key: data._id, groupType: data.groupType, isPublic: data.isPublic};
            }
            */

            this.genericService.shouldOpenChat = true;
            this.sidenav.toggle();
            //this.router.navigateByUrl('/');

          });
        } else{
          this.snackBar.open('¡Vaya, parece que necesitas iniciar sesión!', 'X', {
            duration: 6000,
            panelClass: ['lightred-snackbar']
          });
          this.redirectionService.redirectionJoinGroup = true;
          this.redirectionService.redirectionURL = 'joinGroup/' + id; // TODO: SOUFIANE: Cambiar URL
          this.router.navigate([Values.ROUTES.LOGIN]);
          }
      } else {
        this.redirectionService.redirectionURL = this.router.url;
      }
    });

    if(!this.genericService.staticHTML) {
      // Checks if should display install popup notification:
      if (this.isIos() && this.isRunningStandalone()) {
        this.showInstallAppIos = true;
      }
      
      //Los dos emmits para captar los clicks en los buttons respectivos
      this.openChatSubscription = this.functionsService.openChat$.subscribe(()=>{
        this.onOpenSidebar('chat');
      });
  
      this.openHelpSubscription = this.functionsService.openHelp$.subscribe(()=>{
        this.onOpenSidebar('help');
      });

      this.openExternalWindowSubscription = this.functionsService.openExternalWindow$.subscribe(()=>{
        this.onOpenSidebar('externalWindow');
      });
  
      this.openButtonAutoPlantilla = this.functionsService.openButton$.subscribe(()=>{
        this.openButtonAutoPlantillaCaller(1);
      });
      
      if(this.authService.isUserSessionActive()) {
        this.chatService.getNonReadMessagesCount();
        this.swPush.requestSubscription({serverPublicKey: this.VAPID_PUBLIC_KEY})
        .then(sub => this.pushNotificationsService.addPushSubscriber(this.authService.userId, sub).subscribe( data => {
        }))
        .catch(err => console.error("Could not subscribe to notifications", err));
      } else {
        //'NO ESTAS LOGEADO NO PUEDES WEBPUSH'
      }
  
      // this.router.onSameUrlNavigation = 'reload';
      this.router.onSameUrlNavigation = 'ignore';
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  
      // Notis Superior
      this.adviceReportService.open.subscribe( i =>{
        console.log("HEEYEOER NOTI SUPERIOR OPEN");
        this.openOtherPlantilla(i.id, i.button);
      });
      
      this.functionsService.openNoti.subscribe( i =>{
        this.openOtherPlantilla(i.id, i.button);
      });
      
      this.adviceReportService.superior.subscribe( i =>{
        this.previewPlantillaSuperior();
      });
      
      this.adviceReportService.openSuperior.subscribe( i =>{
        this.openOtherPlantillaSuperior(i.id, i.noti);
      });

      this.adviceReportService.notiEGO.subscribe( i =>{
        console.log("HEEEEEEEHVJDHDYEYYYY");
        this.previewPlantilla();
      });
  
      if (isPlatformBrowser(this.platformId)) {
        this.checkWebp().then((res) => {
          this.genericService.webpSupported = res;
        });
      }
  
      this.authService.getTokenFromLS();
  
      if (this.authService.isUserSessionActive()) {
        this.genericService.getUserInfo(0);
        console.log("entro?");
        // this.privilegiesControlService.getUserPermissionsAndNavigate(this.redirectionService.redirectionURL); //TODO JOAN: Crec que ja no cal
      } else {
        this.genericService.loadHeaderStructure();
        this.genericService.loadFooterStructure();
      }
    }
  }

  ngAfterViewInit(): void {
    if(!this.genericService.staticHTML) {
      this.adviceReportService.loadNotis();
      this.genericService.warningDialog = this.warningDialog;
      this.sideNavService.setSidenav(this.sidenav, 0);
      if (this.NotificacionSuperiorComponent !== null && this.NotificacionSuperiorComponent !== undefined) {
        this.NotificacionSuperiorComponent.reset();
      }
  
      //this.previewPlantilla();
      //this.previewPlantillaSuperior();
      //this.adviceReportService.refresh = false;
    
      this.freshchat =  document.querySelectorAll( '.d-hotline.h-btn' ) ;
    }
    
    if(document.getElementById('notificacion-superior-class')) {
      this.notificacionSuperiorNotLoaded = true;
    }
  }

  onOpenSidebar(open){
    if(open == 'chat') {
      this.genericService.shouldOpenChat = !this.genericService.shouldOpenChat;
      this.genericService.shouldOpenHelp = false;
      this.genericService.shouldOpenExternalWindow = false;
    } else if(open == 'help') {
      this.genericService.shouldOpenHelp = !this.genericService.shouldOpenHelp;
      this.genericService.shouldOpenChat = false;
      this.genericService.shouldOpenExternalWindow = false;
    } else {
      this.genericService.shouldOpenExternalWindow = !this.genericService.shouldOpenExternalWindow;
      this.genericService.shouldOpenChat = false;
      this.genericService.shouldOpenHelp = false;
    }

    if(!this.sidenav.opened && (this.genericService.shouldOpenChat || this.genericService.shouldOpenHelp || this.genericService.shouldOpenExternalWindow)) this.sidenav.toggle();
    else if(this.sidenav.opened && this.genericService.shouldOpenChat == false && this.genericService.shouldOpenHelp == false && this.genericService.shouldOpenExternalWindow == false) this.sidenav.toggle();
    
    if(this.genericService.shouldOpenChat || this.genericService.shouldOpenHelp || this.genericService.shouldOpenExternalWindow){
      this.chat.destroy();
    }
  }

  public async closeSidenav (execute = false) {
    if(execute) {
      if(this.genericService.pendingRevertPrevExternalWindow) {
        if(this.genericService.formsChanged[this.genericService.currentInitialArea['key']] !== undefined && this.genericService.formsChanged[this.genericService.currentInitialArea['key']] && this.genericService.formsChanged[this.genericService.currentInitialArea['key']] !== null && Object.keys(this.genericService.formsChanged[this.genericService.currentInitialArea['key']]).length > 0){
          const status = await this.genericService.openWarningDialog(1, 1);
          if(status) {
            this.closeSidenavExternalWindow();
          }
        } else {
          this.closeSidenavExternalWindow();
        }
      } else {
        this.closeSidenavExternalWindow();
      }
    }
  }

  closeSidenavExternalWindow() {
    this.sidenav.close();
    this.genericService.initFreshChat();
    this.genericService.shouldOpenHelp = false;
    this.genericService.shouldOpenChat = false;
    this.genericService.shouldOpenExternalWindow = false;
    this.closeSidenavButton = false;
    if(this.genericService.pendingRevertPrevExternalWindow) {
      this.genericService.pendingRevertPrevExternalWindow = false;
      this.authService.externalWindowStructure = null;
      let currentInitialAreaExternal = _.cloneDeep(this.genericService.currentInitialArea);
      this.genericService.formsChanged[currentInitialAreaExternal] = [];
      this.genericService.elementsThatAffectOthers = this.genericService.elementsThatAffectOthersPrevExternalWindow;
      this.genericService.affectedElements = this.genericService.affectedElementsPrevExternalWindow;
      this.genericService.selectOptions = this.genericService.selectOptionsPrevExternalWindow;
      this.genericService.tableData = this.genericService.tableDataPrevExternalWindow;
      this.genericService.currentInitialArea = this.genericService.currentInitialAreaPrevExternalWindow;
      this.genericService.readMode = this.genericService.readModePrevExternalWindow;
      this.genericService.parentStructure = this.genericService.parentStructurePrevExternalWindow;
      this.genericService.containerFunctionArea = this.genericService.containerFunctionAreaPrevExternalWindow;
      this.genericService.externalWindowStructure = [];
      this.genericService.firstLoad = true;
    }
  }

  isRunningStandalone() {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  @HostListener("scroll", ['$event'])
  onScroll($event: Event){
    // @ts-ignore
    let scrollOffset = $event.srcElement.scrollTop;
    this.genericService.writeScrollPosition(scrollOffset);
  }

  private checkWebp(): Promise<boolean> {
    return new Promise(res => {
      const webP = new Image();
      webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
      webP.onload = webP.onerror = () => {
        res(webP.height === 2);
      };
    })
  }

  public previewPlantilla() {
    for (let i = 0; i < this.adviceReportService.notifications.length; i++) {
      this.AutoPlantillaComponent.openScreen(this.adviceReportService.notifications[i].id_plantilla_ego, this.adviceReportService.notifications[i].id_not);
    }
  }

  public previewPlantillaSuperior() {
    this.NotificacionSuperiorComponent.reset();
    for (let i = 0; i < this.adviceReportService.notificationSuperior.length; i++) {
      this.NotificacionSuperiorComponent.openSuperior(this.adviceReportService.notificationSuperior[i].id_plantilla_ego, this.adviceReportService.notificationSuperior[i].id_not);
    }
  }

  public openOtherPlantillaSuperior(id: number, noti: number) {
    this.NotificacionSuperiorComponent.openSuperior(id, noti);
  }

  public openOtherPlantilla(id: number, button?: boolean) {
    this.AutoPlantillaComponent.openScreen(id, null);
  }

  public openButtonAutoPlantillaCaller(id: number){
    this.AutoPlantillaComponent.staticButton(id);
  }

  public getType(key) {
    const type = key.split('_')[0];
    if(type == 'password') return 'input'
    return type;
  }

  public getId(key) {
    const id = key.split('_')[1];
    return id;
  }

  public getInternalName(key) {
    if(this.genericService.currentInitialArea['key'] == key){
      return this.genericService.currentInitialArea['value'];
    }
  }


  public getLabel(key) {
    // Cas de que es un canvi normal
    if(!this.toolsCache[key]){
      const elem = this.genericService.findElementWithId(this.getId(key));
      if(elem){
        this.toolsCache[key] = elem['child']['label'];
        return elem['child']['label'];
      }
    } else{
      return this.toolsCache[key];
    }
  }

  public async go(url){
    if(Object.keys(this.genericService.formsChanged[this.genericService.currentInitialArea['key']]).length > 0){
      const status = await this.genericService.openWarningDialog(1, 1);
      if(status) {
        this.genericService.formsChanged[this.genericService.currentInitialArea['key']] = {};
        if(!(url.includes(this.authService.labelLanguage + "/"))) url = this.authService.labelLanguage + "/" + url;
        this.routingService.go(url);
      }
    }
    else {
      if(!(url.includes(this.authService.labelLanguage + "/"))) url = this.authService.labelLanguage + "/" + url;
      this.routingService.go(url);
    }
  }

  public getHistory() {
    return this.genericService.history.slice(this.genericService.getCurrentIndex() - 10 >= 0 ? this.genericService.getCurrentIndex() - 10 : 0).reverse().slice();
  }

  public async revertFormValue(parent, input){
    const id = this.getId(input['value']['name']);
    const elem = this.genericService.findElementWithId(id);
    if(elem){
      console.log("Reverting 1", elem)
      const status = await this.genericService.openWarningDialog(2, 0);
      if(status) {
        elem['child']['tmp_value'] = this.genericService.formsChanged[parent][input.value.name]['internal_value']['old'];
        if(elem['child']['type'] == '5-stars' || elem['child']['type'] == '10-stars' || elem['child']['type'] == '3-faces' || elem['child']['type'] == '5-faces') {
          elem['child']['starsOnHoverValue'] = this.genericService.formsChanged[parent][input.value.name]['internal_value']['old'] - 1;
          if(elem['child']['type'] == '5-stars' || elem['child']['type'] == '10-stars') {
            for(let star in elem['child']['starsArray']) {
              if(+star <= elem['child']['starsOnHoverValue']) elem['child']['starsArray'][star] = true;
              else elem['child']['starsArray'][star] = false;
            }
          }
          else if(elem['child']['type'] == '3-faces' || elem['child']['type'] == '5-faces') {
            for(let star in elem['child']['starsArray']) {
              if(+star == elem['child']['starsOnHoverValue']) elem['child']['starsArray'][star] = true;
              else elem['child']['starsArray'][star] = false;
            }
          }
        }
        if (elem['child']["form"]["controls"][elem['child']['id_functional_area'] + '-' + elem['child']["bd_field"]]) {
          elem['child']["form"]["controls"][elem['child']['id_functional_area'] + '-' + elem['child']["bd_field"]].setValue(this.genericService.formsChanged[parent][input.value.name]['internal_value']['old']);
          this.genericService.deleteFormHistory(parent, input.value.name);
          if (elem['child']['type'] == "slide" || elem['child']['type'] == "checkbox") this.genericService.updateValueCheckBox(elem, 'child', elem['child']['tmp_value']);
          console.log("REVERTING 2 ", elem)
          if (elem['child']['type'] == "password" && elem['child'].hasTmpValuePw !== undefined) {
            console.log(elem)
            elem['child']['form_field'] = 0;
            elem['child'].hasTmpValuePw = true;
          }
        }
      }
    }
  }

  public revertDuplicateFromValue(parent, input){
    this.genericService.revertDuplicateFromValue(parent, input);
  }

  getNumChanges() {
    return this.genericService.getNumChanges()
  }

  getFormsCurrentPage() {
    return this.functionsService.getFormsCurrentPage();
  }

  public navigateBreadcrumbs(b) {
    if(b['url'] != this.routingService.urlWithoutHashtag.split('/')[1]) this.go('/' + b['url'])
    else if(b != this.genericService.breadcrumbs[this.genericService.breadcrumbs.length-1] && this.genericService.breadcrumbs[this.genericService.breadcrumbs.length-2] && this.genericService.breadcrumbs[this.genericService.breadcrumbs.length-2]['defer']) this.closeAllDefers(this.genericService.breadcrumbs[this.genericService.breadcrumbs.length-2]['defer'])
    else this.functionsService.openSnackBar("Ya te encuentras en esta pantalla.", 1000)
  }

  public navigateHistory(h, i) {
    if(h['url'] != this.routingService.urlWithoutHashtag){
      this.genericService.redirectByTools = true;
      this.genericService.updateHistoryIndex(-(i - (this.genericService.history.length - 1 - this.genericService.getCurrentIndex())))
      this.go(h['url'])
    }
    else this.functionsService.openSnackBar("Ya te encuentras en esta pantalla.", 1000)
  }

  public closeAllDefers(structure) {
    for(let i in structure) {
      if(structure[i]['id_functional_type'] == 106) structure[i]['id_functional_status_general'] = 2;
    }
    this.genericService.breadcrumbs.pop()
  }

  public getHeight() {
    let box = document.getElementsByClassName('notificacion-superior-class')[0].getBoundingClientRect();
    return box.height;
  }

  public downloadFunction(){
    let x = {id_function: 60};
    this.functionsService.getFunction(x);
  }

  public printFunction(){
    let x = {id_function: 59};
    this.functionsService.getFunction(x);
  }
}

