<div class="knowledge">
  <mat-sidenav-container class="example-container" [hasBackdrop]="true">
    <mat-sidenav #sidenav position="end" fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="80">
      <div class="notas-p">
        <div class="top-pdf">
          <mat-icon (click)="sidenav.toggle()" class="removeit">close</mat-icon>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="custom-mat-drawer-content">
      <mat-accordion>
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Pregunta
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          Respuesta
        </mat-expansion-panel>
      </mat-accordion>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
