<div class="loggin" ngClass.sm="loggin2" ngClass.xs="loggin2"> <!--*ngIf="!authService.userId">-->
    <form fxLayout="row wrap" fxFlex="100">
      <a *ngIf="validate" style="font-weight: 700; margin-top: 2em; text-align: right; width: 100%; font-size: 11px; text-transform: uppercase;" (click)="changeEmail()">{{strings.CHANGE_EMAIL}}</a>
      <mat-form-field ngStyle.sm="padding: 0.5em 0;" ngStyle.xs="padding: 0.5em 0;" appearance="outline" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <input matInput placeholder="Introduce tu email" required autocomplete [(ngModel)]="user" [ngModelOptions]="{standalone: true}" [disabled]="validate">
      </mat-form-field>

      <a *ngIf="validate" style="font-weight: 700; margin-top: 2em; text-align: right; width: 100%; font-size: 11px; text-transform: uppercase;" (click)="recoverPasswordClick()">{{strings.RECOVER_PASSWORD}}</a>
      <mat-form-field *ngIf="validate" ngStyle.sm="padding: 0.5em 0;" ngStyle.xs="padding: 0.5em 0;" appearance="outline" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <input matInput placeholder="Introduce tu contraseña" [type]="hide ? 'password' : 'text'" required [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
        <div class="mat-icon-button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon style="font-size: 14px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <!--<mat-icon fxShow="true" fxShow.sm="false" fxShow.xs="false" *ngIf="deviceService.isDesktop()" style="font-size: 14px;" (click)="recoverPasswordClick()" matTooltip="Recuperar contraseña">refresh</mat-icon>-->
        </div>
      </mat-form-field>

      <button *ngIf="!validate" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" name="go" (click)="validateEmail()">{{strings.GO}}</button>
      <button *ngIf="validate" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" name="go" (click)="loginUser()">{{strings.GO}}</button>
    </form>
</div>

<!--<div class="container" *ngIf="deviceService.isDesktop()">
  <div class="row" id="pwd-container">
    <div style="width: 29%"></div>
    <div style="width: 42%" *ngIf="!authService.userId">
      <div class="login-form">
        <form>
          <img src="assets/img/logo.png" class="img-responsive" alt="" height="80" />

          <mat-form-field style="width: 100%;">
            <input matInput placeholder="Email" required autocomplete [(ngModel)]="user" [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field style="width: 100%;">
            <input matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'" required [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
            <div class="mat-icon-button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
          </mat-form-field>

          <button name="go" class="btns-filter" (click)="loginUser()">{{strings.GO}}</button>
          <div><a (click)="recoverPasswordClick()">{{strings.RECOVER_PASSWORD}}</a></div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="container" *ngIf="!deviceService.isDesktop()">
  <div class="row" id="pwd-container">
    <div style="width: 100%" *ngIf="!authService.userId">
      <div>
        <form style="padding: 35px;">
          <img src="assets/img/logo.png" class="img-responsive" alt="" height="80" />

          <mat-form-field style="width: 100%;">
            <input matInput placeholder="Email" required autocomplete [(ngModel)]="user" [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field style="width: 100%;">
            <input matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'" required [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
            <div class="mat-icon-button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon style="font-size: 17px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
          </mat-form-field>

          <button style="margin-top: 0.5em; padding-top: 0.5em; padding-bottom: 0.5em;" name="go" class="btns-filter" (click)="loginUser()">{{strings.GO}}</button>
          <div style="margin-top: 0.5em;"><a (click)="recoverPasswordClick()">{{strings.RECOVER_PASSWORD}}</a></div>
        </form>
      </div>

      <mat-tab label="¿Quiénes somos?">
        <div>
          <p>
            4DMOVIN tiene como finalidad facilitar la transformación y actualización de las inmobiliarias para adaptarse al mercado actual. <br>

            Para ello, 4DMOVIN crea una plataforma innovadora, colaborativa e integrada a disposición de las inmobiliarias para que éstas puedan aprovecharse de los avances tecnológicos tanto a nivel interno como externo. <br>

            Creemos que la tecnología es la palanca que permitirá revolucionar el sector, mejorando y optimizando tanto procesos de trabajo como sistemas en venta. <br>

            Con ello 4DMOVIN profesionalizará el sector y mejorará su reputación. <br>

            Deja que te conozcamos y generemos tu estrategia para ser una empresa del <b> PRESENTE </b>.<br>
          </p>

        </div>

      </mat-tab>
      <mat-tab label="Contactar">
        <form class="example-form" [formGroup]="form">
          <mat-form-field class="example-container">
            <input matInput type="text" placeholder="{{'Nombre'}}" [(ngModel)]="name" formControlName="name" required maxlength="100">
          </mat-form-field>

          <mat-form-field class="example-container">
            <input matInput type="text" placeholder="{{'Nombre de la immobiliaria'}}" [(ngModel)]="inmoName" formControlName="inmoName" required maxlength="100">
          </mat-form-field>

          <mat-form-field class="example-container">
            <input matInput type="email" placeholder="{{'Teléfono de contacto'}}" [(ngModel)]="phone" formControlName="phone" maxlength="20">
          </mat-form-field>

          <mat-form-field class="example-container">
            <input matInput type="email" placeholder="{{'Correo de contacto'}}" [(ngModel)]="mail" formControlName="mail" required maxlength="100">
          </mat-form-field>

          <mat-form-field class="example-container">
            <textarea matInput ng-reflect-model placeholder="{{'Comentarios'}}"  [(ngModel)]="comentario" formControlName="comentario" rows="5" cols="40" style="resize:both;" required maxlength="1500"></textarea>
          </mat-form-field>

          <div style="margin-left:2%!important; text-align: start; margin-bottom: 0.8em;" class="normalText thecheck">
            <input aria-label="privacity" type="checkbox" formControlName="privacidad" [(ngModel)]="privacidad">
            <a class="textCheckBox" (click)="readPrivacity()">{{'He leído y acepto el aviso legal, la política de privacidad, y los términos y condiciones de uso'}}</a>
          </div>

          <div style="margin-left:2%!important; text-align: start; margin-bottom: 0.8em;" class="normalText thecheck">
            <input aria-label="newsletter" type="checkbox" formControlName="newsletter" [(ngModel)]="newsletter">
            <a class="textCheckBox">{{'Acepto recibir mails informativos de Movin'}}</a>
          </div>
          <div style="width:22%; left:0; padding-top:2%; padding-bottom:2%;">
            <button name="checkFormulario" mat-raised-button class="normalText thebutton thebutton4" type="submit" (click)="checkForm(mail, name, inmoName, comentario)">{{'Enviar'}}</button>
          </div>
        </form>
      </mat-tab>
    </div>
  </div>
</div>-->
