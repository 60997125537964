import { Component, OnInit } from '@angular/core';
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { AuthService } from "../../services/auth/auth.service";
import { MonitoringActions, MonitoringDecorator } from "../../services/track-decorators";
import { Strings } from "../../../strings/strings";

import { MarketplaceFunctions } from '../../services/functions/functions.marketplace.service'

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  public modulosOferta: any;
  public loadedModulosOferta: boolean;

  public misModulos: any;
  public loadedMisModulos: boolean;

  constructor(
    private endpointService: EndpointService,
    private authService: AuthService,
    public marketplaceFunctionsService: MarketplaceFunctions) { }

  ngOnInit(): void {
    this.voidMethodForMonitoring(null);
    this.initialize();
  }

  @MonitoringDecorator(MonitoringActions.VIEW, Strings.USERTRACKVIEWCODE.MARKETPLACE_SEARCH)
  private voidMethodForMonitoring(id) {
    return id;
  }

  public initialize(): void {
    this.marketplaceFunctionsService.getFunction(600,{that: this});
  }

  public testFunctions(): void {
    this.marketplaceFunctionsService.getFunction(601,{that: this});
  }

}
