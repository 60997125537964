import { Component, OnInit } from '@angular/core';
import { AddMovinCoinComponent } from "../../../bill/subcomponent/add-movin-coin.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-buy-movins',
  templateUrl: './buy-movins.component.html',
  styleUrls: ['./buy-movins.component.css']
})
export class BuyMovinsComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subscribir(0);
  }

  subscribir(n: number){
    const dialogRef = this.dialog.open(AddMovinCoinComponent,{
      width: 'auto',
      height: 'auto',
      data: n
    });

    dialogRef.afterClosed().subscribe( info=> {
      //this.otherUsersArray.push(newUser[0]);
      //this.dataSourceUsers = new MatTableDataSource<PeriodicElement>(this.otherUsersArray);
    });

  }

}
