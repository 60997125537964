import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public showMore: boolean;
  @Input('carro') carritoCompra: any;
  @Input('totalCarro') totalCompra: number;

  constructor(
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(): void {
    this.showMore = false;
  }

  public saberMas() {
    this.showMore = !this.showMore;
  }
}
