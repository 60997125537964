import {Component, OnInit, Input, ViewChild, AfterViewInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

//SERVICES
import { FunctionsService } from '../../../services/functions/functions.service';
import {RoutingService} from "../../../services/routing/routing.service";
import {GenericService} from "../../../services/generic/generic.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AuthService} from "../../../services/auth/auth.service";
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { BottomSheetFilterTableComponent } from './bottom-sheet-filter-table/bottom-sheet-filter-table.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TableComponent implements OnInit, AfterViewInit{
  // @Input() functionId: number;
  @Input() structure = null;
  @Input() index: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public idQuery: number;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns = [];
  public sticky;
  public search;
  public paginatorOptions;
  public tableData;
  public arrayFilters = [];
  public arrayFiltersFields = [];
  //public arrayFiltersFieldsOld = [];
  public filterSearch = null;
  public filtersCount = 0;
  public loadedFilter = true;
  public aux = [];
  public oldValue = -1;
  public oldBdField = '';
  public expandedCol = null;
  public expandedElement;
  public page : number = 0;
  public prevFilter = null;
  public loaded = false;
  public loadedFilters = false;
  public loadedAfterView = false;
  public clickedRow = null;
  public bottomSheetRef = null;

  public getHeightV;
  public getHeightTable2V;
  public getHeightTableV;
  public caluclated = false;
  public expandedElementLast = null;

  constructor(public functionsService: FunctionsService,
              public routingService: RoutingService,
              public genericService: GenericService,
              public authService: AuthService,
              public _bottomSheet: MatBottomSheet,
              public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.sticky = this.structure[this.index]['sticky'] == 1;
    this.search = this.structure[this.index]['search'] == 1;
    this.paginatorOptions = this.structure[this.index]['paginator'] === null ? null : this.structure[this.index]['paginator'] != 0 ? this.structure[this.index]['paginator'].split(',') : null
    this.idQuery = this.structure[this.index]['id_query'];
    this.tableData = this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data'];
    this.displayedColumns = this.genericService.tableData[this.structure[this.index]['id_functional_area']]['columns'];
    console.log(this.structure[this.index]['id_functional_area'], this.genericService.tableData)
    console.log("TABLE", JSON.parse(JSON.stringify(this.displayedColumns)))
    console.log("TABLE", this.tableData)

    console.log("ttttTABLE", this.tableData.length, this.tableData.length == 0 , this.structure[this.index]['form_field_empty'] == 1);
    if(this.tableData.length == 0 && this.structure[this.index]['form_field_empty'] == 1) this.structure[this.index]['id_functional_status_general'] = 2;

    for (let e in this.structure[this.index]['child']) {
      if (this.structure[this.index]['child'][e]['type'] == 'expansion') this.expandedCol = this.structure[this.index]['child'][e];
    }

    this.dataSource = new MatTableDataSource<any>([]);

    // Sirve para filtrar sin tener en cuenta los acentos
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return (currentTerm + (data as { [key: string]: any })[key] + '◬');
      }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    }


    if(!this.genericService.staticHTML) {
      let paramControl = JSON.parse(localStorage.getItem('paramControl'));
      paramControl = paramControl[this.authService.getIdCompany(true)]
      for (let param of paramControl) {
        if (param['id_pantalla'] == this.structure[this.index]['id_functional_parent_initial']) {
          if (param['params']['output'])
            if (param['params']['output'][0]) {
              this.oldValue = param['params']['output'][0]['value']
              this.oldBdField = param['params']['output'][0]['bd_field'];
            }
          break;
        }
      }
    }

    this.aux = [];
    let delCol: string[] = [];
    for (let col in this.displayedColumns) {
      let found = false;
      if (this.displayedColumns[col].split('-')[1] == this.oldBdField) this.oldBdField = this.displayedColumns[col]
      let i = 0;
      for (let ch in this.structure[this.index]['child']) {
        let bd_field
        this.structure[this.index]['child'][ch]['bd_field'] = this.structure[this.index]['child'][ch]['bd_field'] !== null ? this.structure[this.index]['child'][ch]['bd_field'] : ""
        if (!this.structure[this.index]['child'][ch]['bd_field'].includes(',')) bd_field = this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field'].toLowerCase()
        else bd_field = this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field'].split(',')[0].toLowerCase()
        if (this.structure[this.index]['child'][ch]['id_functional_type'] == 9 && bd_field == this.displayedColumns[col] && this.checkIfHide(ch)) {
          console.log("Table Init - Found 1", this.structure[this.index]['child'][ch]);
          found = true;
          this.aux.push({
            Name: bd_field,
            Order: this.structure[this.index]['child'][ch]['order_auto_cron'],
            Sort: !(this.structure[this.index]['child'][ch]['sort'] == 0),
            Id: i,
            icon: this.structure[this.index]['child'][ch]['icon'],
            class: this.structure[this.index]['child'][ch]['icon_type'] == 'Outlined' ? "material-icons-outlined" :
              this.structure[this.index]['child'][ch]['icon_type'] == 'Filled' ? "material-icons" :
                this.structure[this.index]['child'][ch]['icon_type'] == 'Round' ? "material-icons-round" :
                  this.structure[this.index]['child'][ch]['icon_type'] == 'Two-tone' ? "material-icons-two-tone" :
                    this.structure[this.index]['child'][ch]['icon_type'] == 'Sharp' ? "material-icons-sharp" : "material-icons",
            Tooltipp: this.structure[this.index]['child'][ch]['tooltip']
          })
          break;
        } else if (!this.structure[this.index]['child'][ch]['bd_table'] && !this.structure[this.index]['child'][ch]['bd_field'] && !this.aux.some(e => e.Name === bd_field)) {
          console.log("Table Init - Found 2", this.structure[this.index]['child'][ch]);
          found = true;
          this.aux.push({
            Name: bd_field,
            Order: this.structure[this.index]['child'][ch]['order_auto_cron'],
            Sort: !(this.structure[this.index]['child'][ch]['sort'] == 1),
            Id: i,
            icon: this.structure[this.index]['child'][ch]['icon'],
            class: this.structure[this.index]['child'][ch]['icon_type'] == 'Outlined' ? "material-icons-outlined" :
              this.structure[this.index]['child'][ch]['icon_type'] == 'Filled' ? "material-icons" :
                this.structure[this.index]['child'][ch]['icon_type'] == 'Round' ? "material-icons-round" :
                  this.structure[this.index]['child'][ch]['icon_type'] == 'Two-tone' ? "material-icons-two-tone" :
                    this.structure[this.index]['child'][ch]['icon_type'] == 'Sharp' ? "material-icons-sharp" : "material-icons",
            Tooltipp: this.structure[this.index]['child'][ch]['tooltip']
          })
        }
        i++
      }
      if (!found) {
        delCol.push(col)
      }
    }
    for (let col of delCol) {
      this.displayedColumns.splice(this.displayedColumns.indexOf(delCol[col]), 1);
    }

    this.aux.sort((a, b) => {
      if (a.Order === null || b.order === null) return 1;
      return (a.Order > b.Order) ? 1 : -1;
    })

    for (let e in this.aux) {
      this.displayedColumns[e] = this.aux[e].Name
    }
    if (this.paginatorOptions !== null) {
      let indexRow = this.tableData.findIndex(row => row[this.oldBdField] === this.oldValue)
      this.page = Math.trunc((indexRow - 1) / this.paginatorOptions[0])
    }

    this.loaded = true;

    for(let i in this.tableData){
    let eachData = Object.entries(this.tableData[i]);
      for(let j in eachData) {
        let objectName = eachData[j][0];
        let objectValue = eachData[j][1];
        if(objectName == "tmp_param" || objectName == "param_intern") continue;
        if(this.arrayFilters[objectName] === undefined) this.arrayFilters[objectName] = [];
        if(!Array.isArray(objectValue)) {
          if(objectValue !== undefined && objectValue != null && !this.containsValue(objectValue, this.arrayFilters[objectName])) this.arrayFilters[objectName].push(objectValue);
        } else {
          for(let k in objectValue){
            if(objectValue[k] !== undefined && objectValue[k] != null && !this.containsValue(objectValue[k], this.arrayFilters[objectName])) {
              this.arrayFilters[objectName].push(objectValue[k]);
            }
          }
        }
      }
    }

    for(let i in this.arrayFilters){
      let splitedIdFa = i.split("-");
      let orden = 0;
      if(!(splitedIdFa.length > 1)) continue; 
      let idFa = splitedIdFa[0];
      let expSplit = i.split("exp*");
      if(expSplit.length > 1) {
        idFa = splitedIdFa[1];
        orden = 1;
      }
      let functionalArea = 0;
      let functionalAreaNew = this.genericService.findElementWithId(idFa);
      if(functionalAreaNew !== undefined) functionalArea = functionalAreaNew;
      if(functionalArea === 0) continue;
      functionalArea = functionalArea['child'];
      let label = "";
      let tooltip = "";
      let type = functionalArea['id_functional_type'];
      if(type == 124) {
        label = functionalArea['description'];
        let newValues124 = [];
        for(let j in this.arrayFilters[i]) {
          let myNewValue124 = 0;
          if(this.arrayFilters[i][j] > 0) myNewValue124 = 1;
          if(!this.containsValue(myNewValue124, newValues124)) newValues124.push(myNewValue124);
        }
        this.arrayFilters[i] = newValues124;
      } else {
        label = functionalArea['label'];
        tooltip = functionalArea['tooltip'];
        if(label === null || label === "") label = tooltip;
        if(((label === null || label === "") && (tooltip === null || tooltip === "")) || functionalArea['id_condition'] == 11) continue;
        if(this.arrayFilters[i][0] === "isbooleantrue" || this.arrayFilters[i][0] === "isbooleanfalse") type = 9999999;
        if(functionalArea['bd_field'].includes('fecha') || functionalArea['bd_field'].includes('date')) type = 1999999;

        if(type == 9 || type == 101) {
          let labelUnder = label.toLowerCase();
          if(!(labelUnder.includes("telefono") || labelUnder.includes("teléfono") || labelUnder.includes("phone"))) {
            let isAllNumeric = true; // Saber si todos son numeros
            let isSomeNumeric = false; // Saber si hay algun numero: Para el caso que sean todos vacíos
            for(let j in this.arrayFilters[i]) {
              let valueJ = this.prepareAsNumber(this.arrayFilters[i][j]);
              if(!valueJ || valueJ == null || valueJ == "") continue;
              isSomeNumeric = true;
              if(isNaN(valueJ)) {
                isAllNumeric = false;
                break;
              }
            }
            if(isAllNumeric && isSomeNumeric) type = 191;
          }
        }
      }

      if(functionalArea['id_functional_status_general'] == 2 || functionalArea['hide'] == 1 || ((type == 124 || type == 9999999 || type == 9 || type == 101) && this.arrayFilters[i].length < 2) || label === undefined || label.replace(/\s+/g, '') == "") continue;
      let value = {value: this.arrayFilters[i], nameOriginal: i, idFa: idFa, functionalArea: functionalArea, label: label, filter: [], sort: orden, type: type};
      this.arrayFiltersFields.push(value);
    }
    this.arrayFiltersFields.sort(function (a, b) {
      if (a.sort > b.sort) return 1;
      if (a.sort < b.sort) return -1;
      return 0;
    });
    for(let i in this.arrayFiltersFields) {
      if(this.arrayFiltersFields[i]['type'] == 191) {
        this.arrayFiltersFields[i]['value'].sort(function (a,b) {return a - b;});
      } else {
        this.arrayFiltersFields[i]['value'].sort();
      }
      if(this.arrayFiltersFields[i]['type'] == 124 || this.arrayFiltersFields[i]['type'] == 9999999) {
        this.arrayFiltersFields[i]['value'].reverse();
      }
    }

    this.loadedFilters = true;
  }

  doFilter(value : string) {
    value = value.trim().toLocaleLowerCase();
    this.dataSource.filter = value;
    this.filterSearch = value;
    this.saveFilter();
    this.calculateHeights();
  }

  saveFilter() {
    if(!this.genericService.staticHTML) {
      let paramControl = JSON.parse(localStorage.getItem('paramControl'));
      let paramControlUser = paramControl[this.authService.getIdCompany(true)]
      let actual_id_functional_area = 0;
      if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) {
        actual_id_functional_area = this.structure[this.index]['id_functional_parent_initial_dsb'];
      } else {
        actual_id_functional_area = this.structure[this.index]['id_functional_parent_initial'];
      }
      console.log(this.arrayFiltersFields, "YEEEEE");
      for(let param of paramControlUser) {
        if(param['id_pantalla'] == actual_id_functional_area) {
          param.tableFilter = this.filterSearch
          param.indexActualPage = this.paginator.pageIndex;
          param.tableMultipleFilters = this.arrayFiltersFields;
          break;
        }
      }
      console.log(paramControl, "paramControl");
      localStorage.setItem("paramControl", JSON.stringify(paramControl));
      console.log(JSON.parse(localStorage.getItem('paramControl')), "paramControl 2");
    }
  }
  
  getRowId(rowID: any, originalRowID: any) {
    if(originalRowID !== undefined) rowID = originalRowID;
    return rowID;
  }

  rowClick(event : any, row : any, rowID: any) : void {
    if(this.oldBdField !== undefined && this.oldBdField !== null && this.oldBdField !== '') {
      this.oldValue = row[this.oldBdField]
    }
    this.genericService.tablesActualRows.push({"id_functional_area": this.structure[this.index]['id_functional_area'], "rowID": rowID})
    this.functionsService.getFunction(this.structure[this.index], this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data'][rowID]);
  }

  rowClickImage(event : any, row : any, rowID: any, id: any) : void {
    if(this.oldBdField !== undefined && this.oldBdField !== null && this.oldBdField !== '') {
      this.oldValue = row[this.oldBdField]
    }
    this.genericService.tablesActualRows.push({"id_functional_area": this.structure[this.index]['id_functional_area'], "rowID": rowID})
    this.functionsService.getFunction(this.structure[this.index]['child'][id], this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data'][rowID]);
  }

  public isHighlightRow(oldBdField, rowID) {
    let actualRow = null;

    for(let x in this.genericService.tablesActualRows) {
      if(this.genericService.tablesActualRows[x]['id_functional_area'] == this.structure[this.index]['id_functional_area']) actualRow = this.genericService.tablesActualRows[x]['rowID'];
    }

    if(actualRow !== null) return rowID == actualRow;
    else return oldBdField == this.oldValue;
  }

  checkArray(element: any) {
    return Array.isArray(element) && element.length > 1;
  }

  private isValidDate(str: string) {
    // Regular expression to check if string is valid date
    const regexExp = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/gi;
    //const regexExp = /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/gi;

    return regexExp.test(str);
  }

  ngAfterViewInit() {
    //this.sort.sortChange.subscribe(v=> console.log(v)); // Mostra els canvis fets en el sort

    this.dataSource.sort = this.sort;

    // Joan: Codi per ordenar nombres i dates - Caldrà revisar quan es localitzi per algtres països
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (typeof item[property] == "string"
          && !isNaN(item[property].replaceAll(".", "").replace(",", ".")) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          && !isNaN(parseFloat(item[property].replaceAll(".", "").replace(",", ".")))) { // ...and ensure strings of whitespace fail
        //console.log("sortingDataAccessor", item[property]);
        return parseFloat(item[property].replaceAll(".", "").replace(",", "."));
      }
      else if (typeof item[property] == "string"
               && this.isValidDate(item[property].substring(0, 10))) {
        return item[property].substring(6, 10) + item[property].substring(3, 5) + item[property].substring(0, 2) + item[property].substr(10);
      }
      else {
        return item[property];
      }
    }

    if(this.paginatorOptions !== null) this.dataSource.paginator = this.paginator;
    if(!this.genericService.staticHTML) this.saveOldInput();
    this.dataSource.data = this.tableData;
    if(!this.genericService.staticHTML) {
      if(this.newParam()) {
        let paramControl2 = JSON.parse(localStorage.getItem('paramControl'));
        paramControl2 = paramControl2[this.authService.getIdCompany(true)]
        let actual_id_functional_area = 0;
        if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) {
          actual_id_functional_area = this.structure[this.index]['id_functional_parent_initial_dsb'];
        } else {
          actual_id_functional_area = this.structure[this.index]['id_functional_parent_initial'];
        }
        for (let param of paramControl2) {
          if (param['id_pantalla'] == actual_id_functional_area) {
            if (param['tableFilter']) this.dataSource.filter = param['tableFilter'];
            this.prevFilter = param['tableFilter'];
            if(this.prevFilter !== null && this.prevFilter !== undefined) this.doFilter(param['tableFilter']);
            if(param['indexActualPage']) this.paginator.pageIndex = param['indexActualPage'];
            if(param['tableMultipleFilters']) {
              let paramTableFilters = param['tableMultipleFilters'];
              for(let i in paramTableFilters) {
                let valueOldFilter = paramTableFilters[i]['filter'];
                let actualValues = this.arrayFiltersFields[i]['value'];
                if(this.arrayFiltersFields[i]['type'] == 1999999 || this.arrayFiltersFields[i]['type'] == 191 || this.arrayFiltersFields[i]['type'] == 124) {
                  if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                  this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                } else {
                  if(!Array.isArray(valueOldFilter)) {
                    for(let j in actualValues) {
                      if(actualValues[j] == valueOldFilter) {
                        if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                        this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                        break;
                      }
                    }
                  } else {
                    for(let j in valueOldFilter) {
                      let founded = false;
                      for(let k in actualValues) {
                        if(actualValues[k] == valueOldFilter[j]) {
                          founded = true;
                          break;
                        }
                      }
                      if(founded) {
                        if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                        this.arrayFiltersFields[i]['filter'].push(valueOldFilter[j]);
                      }
                    }
                  }
                }
              }
              this.applyFilters();
            }
            break;
          }
        }
      }
    }
    this.structure[this.index]['expandedElement'] = this.tableData.find(row => row[this.oldBdField] === this.oldValue)
    if(this.expandedCol !== null && this.oldValue !== null && this.oldValue !== undefined && this.oldValue != -1 && this.oldBdField !== null && this.oldBdField !== undefined && this.oldBdField != '') {
      let from = 0;
      if(this.structure[this.index]['id_functional_parent_initial'] == this.genericService.headerId || this.structure[this.index]['id_functional_parent_initial'] == this.genericService.headerNoLoguedId) from = 1;
      if(this.structure[this.index]['id_functional_parent_initial'] == this.genericService.footerId || this.structure[this.index]['id_functional_parent_initial'] == this.genericService.footerNoLoguedId) from = 2;
      this.genericService.loadExpansion(this.tableData.find(row => row[this.oldBdField] === this.oldValue), from)
    }
    
    //let row = this.tableData.find(row => row[this.oldBdField] === this.oldValue)
    //row.element.nativeElement.scrollIntoView( {behavior: 'smooth', block: 'center', inline : 'center'});
    this.loadedAfterView = true;
    this.calculateHeights();
  }

  calculateHeights() {
    this.genericService.heightTableChange = false;
    setTimeout(() => {
      this.getHeightV = this.getHeight();
      this.getHeightTable2V = this.getHeightTable2();
      this.getHeightTableV = this.getHeightTable();
    }, 0);
  }

  saveOldInput() {
    if(!this.genericService.staticHTML) {
      let paramControl = JSON.parse(localStorage.getItem('paramControl'));
      let paramControlUser = paramControl[this.authService.getIdCompany(true)]
      for (let param of paramControlUser) {
        if (param['id_pantalla'] == this.structure[this.index]['id_functional_parent_initial']) {
          param.oldInputs = param['params']['input'];
          break;
        }
      }
      console.log(paramControl, "paramControl aa");
      localStorage.setItem("paramControl", JSON.stringify(paramControl));
    }
  }

  checkIfHide(ch) {
    if(this.structure[this.index]['child'][ch]['id_functional_status_general'] === 2 || this.structure[this.index]['child'][ch]['hide']  == true || this.structure[this.index]['child'][ch]['hide']  == 'true' || this.structure[this.index]['child'][ch]['hide'] === 1) return false;
    else return true
  }

  iconClick(id: any) {
    this.functionsService.getFunction(this.structure[this.index]['child'][id]);
  }

  newParam() {
    if(!this.genericService.staticHTML) {
      let paramControl3 = JSON.parse(localStorage.getItem('paramControl'));
      paramControl3 = paramControl3[this.authService.getIdCompany(true)]
      for (let param of paramControl3) {
        if (param['id_pantalla'] == this.structure[this.index]['id_functional_parent_initial']) {
          if(param['oldInputs']) {
            if(this.arraysEqual(param['oldInputs'], param['params']['input'])) return true;
          }
        }
      }
    }
    return false;
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false;
    }
    return true;
  }

  public expansionChange(row) {
    this.functionsService.getFunction(this.structure[this.index], row, null, 47)
  }

  public getHeight() {
    let boxTable0 = document.getElementsByClassName('mat-elevation-z3-' + this.structure[this.index]['id_functional_area'])[0];
    let boxTableFinal = 0;
    if(boxTable0 !== undefined) {
      let boxTable = boxTable0.getBoundingClientRect();
      boxTableFinal = boxTable.height;
    }

    let boxNoRows0 = document.getElementsByClassName('noRowsGeneric-' + this.structure[this.index]['id_functional_area'])[0];
    let boxNoRowsFinal = 0;
    if(boxNoRows0 !== undefined) {
      let boxNoRows = boxNoRows0.getBoundingClientRect();
      boxNoRowsFinal = boxNoRows.height;
    }
    
    //console.log(boxTableFinal , boxNoRowsFinal);
    boxTableFinal = boxTableFinal + boxNoRowsFinal;
    return boxTableFinal + 8;
  }

  public getHeightTable() {
    return this.getHeightFilter() + this.getHeightBarTools();
  }

  public getHeightTable2() {
    let box0 = document.getElementsByClassName('mat-dialog-content')[0];
    let boxFinal = 0;
    if(box0 !== undefined) {
      let box = box0.getBoundingClientRect();
      boxFinal = box.height;
    }
    boxFinal = boxFinal - this.getHeightFilter();
    let fullHeight = +this.getHeight() - this.getHeightFilter();

    if(fullHeight > boxFinal) boxFinal = fullHeight;
    return boxFinal;
  }

  public getHeightBarTools() {
    let barTools0 = document.getElementsByClassName('bar-tools')[0];
    let barToolsFinal = 0;
    if(barTools0 !== undefined) {
      let barTools = barTools0.getBoundingClientRect();
      barToolsFinal = barTools.height;
    }
    return barToolsFinal;
  }

  public getHeightFilter() {
    let boxFilter0 = document.getElementsByClassName('bottom-table-generic-' + this.structure[this.index]['id_functional_area'])[0];
    let boxFilterFinal = 0;
    if(boxFilter0 !== undefined) {
      let boxFilter = boxFilter0.getBoundingClientRect();
      boxFilterFinal = boxFilter.height;
    }
    return boxFilterFinal;
  }

  public appearProfilePermission(idFa, rowKey) {
    let e = "profile-permissions-appear-" + idFa;
    if(rowKey[e] !== undefined && rowKey[e] == 0) return false;
    return true;
  }

  public openFilterTable() {
    //this.arrayFiltersFieldsOld = this.arrayFiltersFields;
    this.bottomSheetRef = this._bottomSheet.open(BottomSheetFilterTableComponent, {
      panelClass: "filter-table-generic-bottomsheet",
      data: {
        arrayFiltersFields: this.arrayFiltersFields
      },
    });
    this.bottomSheetRef.afterDismissed().subscribe(data => {
      if(data !== undefined) {
        if(data['reset'] === 1) {
          this.arrayFiltersFields = data['filters'];
          this.dataSource.data = this.tableData;
          this.filtersCount = 0;
          this.saveFilter();
        } else {
          this.arrayFiltersFields = data['filters'];
          this.applyFilters();
        }
      }
      this.calculateHeights();
    });
  }

  private containsValue(value, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i] === value) return true;
    }
    return false;
  }

  private applyFilters() {
    this.filtersCount = 0;
    this.loadedFilter = false;
    let newData = this.tableData;
    let filters = this.arrayFiltersFields;
    console.log(this.arrayFiltersFields , "arrayFiltersFields")
    for(let i in filters) {
      if(filters[i]['type'] === 124 && (filters[i]['filter'] === 0 || filters[i]['filter'] === 1)) {
        ++this.filtersCount;
        let newData2 = [];
        for(let j in newData) {
          if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
          let originValue = newData[j][filters[i]['nameOriginal']];
          let filterValue = filters[i]['filter'];
          if(filters[i]['filter'] === 0) {
            if(!Array.isArray(originValue)) {
              if(filterValue == originValue) newData2.push(newData[j]);
            } else {
              for(let l in originValue) {
                if(filterValue == originValue[l]) {
                  newData2.push(newData[j]);
                  break;
                }
              }
            }
          } else {
            if(!Array.isArray(originValue)) {
              if(0 < originValue) newData2.push(newData[j]);
            } else {
              for(let l in originValue) {
                if(0 < originValue[l]) {
                  newData2.push(newData[j]);
                  break;
                }
              }
            }
          }
        }
        newData = newData2;
      } else if(filters[i]['type'] === 191) {
        let max = null;
        if(filters[i]['filter'].max) max = filters[i]['filter'].max;
        let min = null;
        if(filters[i]['filter'].min) min = filters[i]['filter'].min;
        if(min == null) min = 0;
        if(max == null) max = 0;
        if(min > 0 || max > 0) {
          ++this.filtersCount;
          let newData2 = [];
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = this.prepareAsNumber(newData[j][filters[i]['nameOriginal']]);
            if((min == 0 || originValue >= min) && (max == 0 || originValue <= max)) {
              newData2.push(newData[j]);
            }
          }
          newData = newData2;
        }
      } else if(filters[i]['filter'].fechaInicio || filters[i]['filter'].fechaFin) {
        ++this.filtersCount;
        let newData2 = [];
        let fechaInicio = null;
        if(filters[i]['filter'].fechaInicio) fechaInicio = filters[i]['filter'].fechaInicio.setHours(2,0,0,0);
        let fechaFin = null;
        if(filters[i]['filter'].fechaFin) fechaFin = filters[i]['filter'].fechaFin.setHours(2,0,0,0);
        for(let j in newData) {
          if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
          let originValue = newData[j][filters[i]['nameOriginal']];
          if(!Array.isArray(originValue)) {
            originValue = this.tratarDate(originValue, fechaInicio);
            if((fechaInicio == null || fechaInicio <= originValue) && (fechaFin == null || originValue <= fechaFin)) newData2.push(newData[j]);
          } else {
            for(let l in originValue) {
              originValue = this.tratarDate(originValue, fechaInicio);
              if((fechaInicio == null || fechaInicio <= originValue) && (fechaFin == null || originValue <= fechaFin)) {
                newData2.push(newData[j]);
                break;
              }
            }
          }
        }
        newData = newData2;
      } else if(filters[i]['filter'].length > 0) {
        ++this.filtersCount;
        let newData2 = [];
        for(let j in newData) {
          if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
          let originValue = newData[j][filters[i]['nameOriginal']];
          let filterValue = filters[i]['filter'];
          nextRowToFilter:
          if(!Array.isArray(filterValue)) {
            if(!Array.isArray(originValue)) {
              if(filterValue == originValue) {
                newData2.push(newData[j]);
                break nextRowToFilter;
              }
            } else {
              for(let l in originValue) {
                if(filterValue == originValue[l]) {
                  newData2.push(newData[j]);
                  break nextRowToFilter;
                }
              }
            }
          } else {
            for(let k in filterValue) {
              if(!Array.isArray(originValue)) {
                if(filterValue[k] == originValue) {
                  newData2.push(newData[j]);
                  break nextRowToFilter;
                }
              } else {
                for(let l in originValue) {
                  if(filterValue[k] == originValue[l]) {
                    newData2.push(newData[j]);
                    break nextRowToFilter;
                  }
                }
              }
            }
          }
        }
        newData = newData2;
      }
    }
    newData.sort(function (a, b) {
      if (a.originalRowID > b.originalRowID) return 1;
      if (a.originalRowID < b.originalRowID) return -1;
      return 0;
    });
    this.dataSource.data = newData;
    this.saveFilter();
    this.loadedFilter = true;
  }

  private tratarDate(originValue, fechaInicio) {
    if(originValue == null || originValue === undefined) {
      if(fechaInicio != null) originValue = 0;
      else originValue = 9999999999999999999999999999999;
    } else {
      originValue = originValue.split(" ")[0];
      originValue = originValue.split(",")[0];
      let values = originValue.split("-");
      if(values[2].length == 4) originValue = values[2] + "-" + values[1] + "-" + values[0];
      originValue = new Date(originValue);
      originValue = originValue.getTime();
    }
    return originValue;
  }

  private prepareAsNumber(value) {
    if(isNaN(value)) {
      value = value.split(',')[0];
      value = value.replaceAll('.', '');
      value = value.replaceAll(' ', '');
      value = value.replaceAll('€', '');
      value = value.replaceAll('$', '');
    }
    return value;
  }

  public expandedColumn() {
    if(this.genericService.heightTableChange) this.calculateHeights();
    return this.expandedCol !== null;
  }
  
  public viewLogInfo(row) {
    console.log(row, this.structure[this.index]['expandedElement']);
  }
}