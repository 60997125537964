import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs-compat';
import { EndpointService } from '../endpoint/endpoint.service';
import { FunctionsService } from '../functions/functions.service';
import { GenericService } from '../generic/generic.service';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class JourneyService {
  emailTemplates: Observable<any[]>;
  journeyAction: Observable<any[]>;
  journeys: Observable<any[]>;
  smsTemplates: Observable<any[]>;
  tagsTemplates: Observable<any[]>;
  whatappTemplates: Observable<any[]>;
  actionTypes: Observable<any[]>;
  triggerTypes: Observable<any[]>;
  journeyActionDefault: Observable<any[]>;
  temporalities: Observable<any[]>;
  public actualElement: any;
  public optionAutocompleteSelected = false;
  public idJourney = 0;
  public applyChanges = true;
  public isLastElement = false;
  public copyElement: Observable<any[]>;

  constructor( public genericService: GenericService,
    public formBuilder: FormBuilder,
    public functionsService: FunctionsService,
    public endpointService: EndpointService) {
  }

  formBuilderCustom(element) {
    console.log('=====', element)
    element = _.cloneDeep(element);
    this.adaptAutocompletes(element);
    return this.formBuilder.group({
      name: [element.name, Validators.required],
      notes: [element.notes],
      id_type_action: [element.id_type_action, Validators.required],
      id_template: [element.id_template],
      id_temporality: [element.id_temporality],
      num_temporality: [element.num_temporality],
      id_tag: [element.id_tag],
      id_action_new: [element.id_action_new],
    })
  }

  adaptAutocompletes(value) {
    if(value['id_type_action'] == 1) {
      for(let i in this.emailTemplates) {
        console.log("adsdeewdwedw", this.emailTemplates[i]['id'] , value['id_template'], this.emailTemplates[i]['id'] == value['id_template']);
        if(this.emailTemplates[i]['id'] == value['id_template']) {
          value['id_template'] = this.emailTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 2) {
      for(let i in this.smsTemplates) {
        if(this.smsTemplates[i]['id'] == value['id_template']) {
          value['id_template'] = this.smsTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 3) {
      for(let i in this.whatappTemplates) {
        if(this.whatappTemplates[i]['id'] == value['id_template']) {
          value['id_template'] = this.whatappTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 4 || value['id_type_action'] == 8 || value['id_type_action'] == 9) {
      for(let i in this.tagsTemplates) {
        if(this.tagsTemplates[i]['id'] == value['id_tag']) {
          value['id_tag'] = this.tagsTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 6) {
      for(let i in this.journeys) {
        if(this.journeys[i]['id'] == value['id_action_new']) {
          value['id_action_new'] = this.journeys[i]['name'];
          break;
        }
      }
    } else if(value['id_type_action'] == 7) {
      for(let i in this.journeyAction) {
        if(this.journeyAction[i]['id'] == value['id_action_new']) {
          value['id_action_new'] = this.journeyAction[i]['name'];
          break;
        }
      }
    }
  }

  _filterEmailTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.emailTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

  _filterJourneyAction(value: any): any {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
    return this.journeyAction.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  _filterJourneys(value: any): any {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
    return this.journeys.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  _filterSmsTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.smsTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

  _filterTagsTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.tagsTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

  _filterWhatappTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.whatappTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

}
