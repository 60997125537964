import {Values} from "../../../values/values";
import {SimpleProperty} from "../../activities/objects/simple-property";

export class ColaborationBody {

  // CAMPOS COMUNES ENTRE INMO Y GRUPO
  public tipoPerfil: number;
  public basic: boolean;
  public silver: boolean;
  public golden: boolean;
  public mensajes: number;

  // CAMPOS ÚNICOS PARA INMOS
  public colaborarConAgrupaciones: number;
  public flatSharing: number;
  public flatLending: number;
  public listaPersonalizadaFS: SimpleProperty[];
  public listaPersonalizadaFL: SimpleProperty[];
  public grupos: [];

  // CAMPOS ÚNICOS PARA GRUPOS
  public verViviendas: number;
  public verInmos: number;

  constructor() {
    this.clearBody();
  }

  public clearBody() {
  this.tipoPerfil = 1;
  this.basic = true;
  this.silver = true;
  this.golden = true;
  this.mensajes = 1;
  // Inmo
  this.colaborarConAgrupaciones = 1;
  this.flatSharing = 1;
  this.flatLending = 0;
  this.listaPersonalizadaFS = [];
  this.listaPersonalizadaFL = [];
  // Grupo
  this.verInmos = 1;
  this.verViviendas = 1;
  }

  public handleResponse(data: any, isGrupo = false) {

    this.tipoPerfil = data[0]?.tipo_perfil;
    this.basic = data[0]?.colaborar_con_basic === 1 ? true : false;
    this.silver = data[0]?.colaborar_con_silver === 1 ? true : false;
    this.golden = data[0]?.colaborar_con_golden === 1 ? true : false;
    this.mensajes = data[0]?.mensajes;

    if (!isGrupo) {
      this.colaborarConAgrupaciones = data[0]?.colaborar_con_agrupaciones;
      this.flatSharing = data[0]?.gestionar_flatsharing;
      this.flatLending = data[0]?.gestionar_flatlending;
      let auxListaFS = data?.listaFS;
      let auxListaFL = data?.listaFL;
      auxListaFS.forEach(d => {
        let aux =  new SimpleProperty(d.id, d.referencia_inmo);
        this.listaPersonalizadaFS.push(aux);
      });
      auxListaFL.forEach(d => {
        let aux =  new SimpleProperty(d.id, d.referencia_inmo);
        this.listaPersonalizadaFL.push(aux);
      });
      // Reset Values Groups
      this.verInmos = null;
      this.verViviendas = null;
    } else {
      this.verInmos = data[0]?.ver_mis_inmos;
      this.verViviendas = data[0]?.ver_mis_viviendas;
      // Reset Values Inmos
      this.colaborarConAgrupaciones = null;
      this.flatSharing = 0;
      this.flatLending = 0;
      this.listaPersonalizadaFS = [];
      this.listaPersonalizadaFL = [];
    }
  }
}
